import EStyleSheet from 'react-native-extended-stylesheet';
import { Dimensions } from 'react-native';
import { MODE } from '../config';
import { COLORS } from './colors';

const {
    width,
    height,
} = Dimensions.get('window');

// noinspection JSUnusedGlobalSymbols
EStyleSheet.build({

    /* Viewport size */
    $deviceWidth: width,
    $deviceHeight: height,

    /* Colors */
    $appHeaderBgColor: '#5C85DD',
    $appNavBarColor: () => {
        switch (MODE) {
            case 'dev':
                return '#18B797';
            case 'qa':
                return '#C9DB48';
            case 'stage':
                return '#74167C';
            case 'uat':
                return '#34EBDE';
            default:
                return COLORS.MAIN;
        }
    },
    $appTitleColor: COLORS.MAIN,
    $headerScreenTitleColor: '#555555',
    $drawerBgColor: 'transparent',
    $drawerTitleColor: 'white',

    $modalOverlayBg: 'rgba(0,0,0,0.7)',

    $blueTxt: '#6AC0EB',
    $blue: '#59CBDD',
    $lightBlue: '#5EB3E3',

    $accordionBg: '#506073',
    $titleGray: '#212121',
    $gray: '#A2AEBD',
    $ultraLightGray: '#E9E9E9',
    $lightGrayBorder: '#E0E0E0',
    $lightGray: '#919191',
    $lightGrayBg: '#F9F9F9',
    $darkGray: '#787878',
    $grayForButton: '#B6BEDA',
    $grayTextColor: '#999999',
    $darkGrayTextColor: '#555',
    $blueGrayLighten: '#EEF0F6',
    $blueGrayLight: '#E3E5ED',
    $blueGray: '#979DB4',

    $brown: '#A09D8D',

    $white: '#fff',
    $black: '#000',

    $yellowLight: '#FDF7E4',
    $yellow: '#F2DCB5',

    $lightPink: '#F8E6E5',
    $pink: '#F2BEB5',

    $green: '#A3C644',
    $emerald: '#18B797',
    $emerald20: '#18B79750',
    $emerald25: '#18B79730',
    $emerald80: '#3D9382',

    $red: COLORS.ACTION,

    /* new */
    $primaryTextColor: '#212121',
    $lightGrey: '#696969',
    $darkBlue: '#5990D1',

    $primaryTextFontFamily: 'Raleway-Regular',
    $sourceSansPropFontFamily: 'SourceSansPro-Regular',
    $sourceSansPropFontFamily_semibold: 'SourceSansPro-Semibold',
    $sourceSansPropFontFamily_bold: 'SourceSansPro-Bold',
    $primaryBoldTextFontFamily: 'Raleway-Bold',
    $secondaryTextFontFamily: 'FontAwesome',
    $defaultFontFamily: 'system font', // Roboto
    $defaultFontFamilyAndroid: 'system font', // Roboto
    $defaultFontFamilyIos: 'system font', // San Francisco
    /* ADding drugs screen */
    $blueTitle: '#2D9CDB',
    $greySubTitle: '#828282',
    $tabletkaBoxBg: '#E9EEF2',
    $tabletkaTxt: '#212121',
    $firstAidCount: '#D0021B',
});
