import {
    Icon, Platform, React, Subtitle, TouchableOpacity, View,
} from '../../../common';
import { COLORS } from '../../../styles/colors';
import { footer } from './style';

export const FooterItem = ({
    count,
    name,
    onPress,
    children,
}) => (count ? (
    <TouchableOpacity onPress={onPress} style={footer.item}>
        {children}
        <Subtitle style={footer.text}>{Object.R(name, { qty: count ?? 0 })}</Subtitle>
    </TouchableOpacity>
) : null);

export const PastVisitFooter = ({
    reportsCnt,
    drugsCnt,
    attachmentsCnt,
    onReadyClick,
    onAttachClick,
    onDrugsClick,
}) => {
    return (
        <View style={footer.container}>
            <FooterItem count={reportsCnt} name="titles.visitsReportsQty" onPress={onReadyClick}>
                <Icon.OpenVector color={COLORS.MAIN} style={footer.iconReport} size={14}/>
            </FooterItem>
            {Platform.OS === 'web' ? null
                : <FooterItem count={attachmentsCnt} name="titles.visitsAttachmentQty" onPress={onAttachClick}>
                    <Icon.Attachment style={footer.iconAttach}/>
                </FooterItem>
            }
            <FooterItem count={drugsCnt} name="titles.visitsDrugsQty" onPress={onDrugsClick}>
                <Icon.Pill style={footer.iconDrugs}/>
            </FooterItem>
        </View>
    );
};
