import {
    BigButton, Link, List, Modal, Page, React, ScrollView, Subtitle, Title, View,
} from '../../common';
import { formatDateLong } from '../../utils';
import actions from '../../actions';
import { visitModalContent as styles } from './style';

export const RegistryInformation = ({
    errorTitle, errorHint, mdInfo = {}, startDate, time,
}) => {
    return (
        <Modal>
            <Title bold centered style={{ fontSize: 16 }} numberOfLines={null} id={errorTitle} />
            <Subtitle
                centered
                style={{
                    fontSize: 12,
                    marginTop: 12,
                    paddingHorizontal: 8,
                }}
                numberOfLines={null}
                id={errorHint}
            />
            <Subtitle
                centered
                style={{
                    fontSize: 12,
                    marginBottom: 12,
                    paddingHorizontal: 8,
                }}
                numberOfLines={null}>{`${formatDateLong(startDate)}${time ? `, ${time}` : ''}`}</Subtitle>
            {mdInfo && mdInfo.phones ? (
                <View>
                    <Subtitle
                        style={styles.registryNumbersSubtitle}
                        id="titles.registry"
                        params={{ name: mdInfo.name }}
                    />
                    <ScrollView style={{ maxHeight: '85%' }}>
                        <View
                            style={{
                                paddingHorizontal: 16,
                                marginVertical: 12,
                            }}>
                            <List
                                type="contacts"
                                data={mdInfo.phones}
                                renderItem={({ item, index }) => (
                                    <View
                                        style={{
                                            borderTopWidth: 1,
                                            borderColor: '#E9E9E9',
                                            borderBottomWidth: index === mdInfo.phones.length - 1 ? 1 : 0,
                                        }}>
                                        <Link
                                            style={{ paddingVertical: 14 }}
                                            href={() => actions.dial(item)}
                                            trackingAlias="tel_registry">
                                            {item}
                                        </Link>
                                    </View>
                                )}
                            />
                        </View>
                    </ScrollView>
                </View>
            ) : null}
            <View style={{ paddingHorizontal: 14 }}>
                <BigButton title="button.understand" action={Page.closeModal} />
            </View>
        </Modal>
    );
};
