// noinspection JSUnusedGlobalSymbols

// import FingerprintScanner from 'react-native-fingerprint-scanner';
import ReactNativeBiometrics from 'react-native-biometrics';

// export const USE_BIO = true;
export const NO_BIO = 'NO_BIO';
export const NO_ENROLL = 'NO_ENROLL';
export const BIO_DEFAULT_ERROR = NO_ENROLL;

export const isSensorAvailable = () => {
    // if (USE_BIO) {
    ReactNativeBiometrics.isSensorAvailable()
    // eslint-disable-next-line no-console
        .then(biometryType => console.log('biometryType: ', biometryType))
    // eslint-disable-next-line no-console
        .catch(error => console.log('error: ', error));
    // } else {
    //     FingerprintScanner.isSensorAvailable()
    //         // eslint-disable-next-line no-console
    //         .then(biometryType => console.log('biometryType: ', biometryType))
    //         // eslint-disable-next-line no-console
    //         .catch(error => console.log('error: ', error));
    // }
};

export const isBioProvided = async () => {
    const errorTranslator = {
        'No identities are enrolled.': NO_ENROLL,
        'Biometry is not enrolled.': NO_ENROLL,
        BIOMETRIC_ERROR_NONE_ENROLLED: NO_ENROLL,
        BIOMETRIC_ERROR_HW_UNAVAILABLE: NO_BIO,
        BIOMETRIC_ERROR_NO_HARDWARE: NO_BIO,
        BIOMETRIC_HARDWARE_NOT_FOUND: NO_BIO,
    };

    const getError = (e) => {
        if (!e) {
            return undefined;
        }
        const error = String(e);
        let errorText = error;
        if (error.indexOf('{') >= 0) {
            errorText = error.match(/\{([^}]+)}/)?.[1]?.split?.(',')?.[0]?.trim?.()
                ?.split
                ?.('=')?.[1];
        }

        // eslint-disable-next-line consistent-return
        return errorTranslator[errorText] ? errorTranslator[errorText] : BIO_DEFAULT_ERROR;
    };

    try {
        // if (USE_BIO) {
        const bio = await ReactNativeBiometrics.isSensorAvailable();
        const error = getError(bio?.error);
        return {
            ...bio,
            error,
        };
        // }
        //
        // const bio = await FingerprintScanner.isSensorAvailable();
        // return {
        //     available: !!bio,
        //     biometryType: bio,
        // };
    } catch (e) {
        return { available: false };
    }
};

export const isFingerprintMatch = () => (
    // USE_BIO ?
    ReactNativeBiometrics.simplePrompt({
        promptMessage: Object.R('titles.scanFingerprint'),
        cancelButtonText: Object.R('titles.cancel'),
    }));
// : FingerprintScanner.authenticate({ description: Object.R('titles.scanFingerprint') }));

export const FingerprintScannerRelease = () => {}; //! USE_BIO && FingerprintScanner.release();
// showNotification('Сканер отпечатков пальцев не имеет зарегистрированных пальцев');

export const getLocalizedError = (error) => {
    switch (error) {
        case 'UserCancel':
        case 'UserFallback':
            return Object.R('fingerprintScannerError.UserCancel');
        default:
            return Object.R(`fingerprintScannerError.${error}`);
    }
};
