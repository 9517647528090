import { personId1, personId2 } from '../const.stub';

export const profile = {
    _id: personId2,
    fullNameRu: 'Борменталь Иван Арнольдович(2)',
    phones: [{ number:  '375291234567' }],
    emails: [],
    birthday: '1992-05-08',
    sex: 'MALE',
    keys: [{ tag: 'FULL_NAME_RU', value: 'Борменталь Иван Арнольдович(2)' }],
    external: {
        'horizont-minsk': {
            id: '81901',
        },
        'lode-minsk': {
            id: '122801',
            validFrom: '2018-09-06T19:39:53.217Z',
        },
    },
    hashKey: personId2,
    storeKey: personId2,
    _updated: { time: '2000-01-01T00:00:00.000Z' }
};

export const coverage = {
    people: [
        {
            profile: {
                _id: personId2,
                primaryPhone: { number: '375291234567' },
                phones: [{ number: '375291234567' }],
                birthday: '1992-05-08',
                sex: 'MALE',
                emails: [],
                hashKey: personId2,
                storeKey: personId2,
                _updated: { time: '2000-01-01T00:00:00.000Z' }
            },
            profileId: personId2,
            relKind: 'OWNER'
        },
        {
            profile: {
                _id: '5bd1bd53cff47e000d5d7429',
                fullNameRu: 'Ша́риков Полигра́ф Полигра́фович',
                birthday: '2018-10-04',
                relKind: 'CHILD',
                eligible: true,
                programs: [{ id: '5c2e74ad91aca54130b0c80e', covererCode: 'epam' }],
            },
            profileId: '5bd1bd53cff47e000d5d7429',
            relKind: 'CHILD',
        },
        {
            profile: {
                _id: '5bd1bd53cff47e000d5d7428',
                fullNameRu: 'Бунина Зинаида Проковьевна',
                birthday: '2016-10-04',
                relKind: 'CHILD',
                eligible: true,
                programs: [{ id: '5c2e74ad91aca54130b0c80e', covererCode: 'epam' }],
            },
            profileId: '5bd1bd53cff47e000d5d7428',
            relKind: 'CHILD',
        },
        {
            profile: {
                _id: personId1,
                fullNameRu: 'Преображе́нский Фили́пп Фили́ппович',
                birthday: '1990-06-06',
                relKind: 'CHILD',
                eligible: true,
                programs: [{ id: '5c2e74ad91aca54130b0c80d', covererCode: 'epam' }],
            },
            profileId: personId1,
            relKind: 'SPOUSE',
        }
    ],
    programs: {
        '5c2e74ad91aca54130b0c80e': {
            _id: '5c2e74ad91aca54130b0c80e',
            name: 'EPAM Дети (Минск)',
            shortName: 'EPAM Healthcare',
            status: 'OK',
            branchIds: ['5b46096c302c5a36f'],
            eligible: true,
            covererCode: 'epam',
            insurerCode: 'epam',
        },
        '5c2e74ad91aca54130b0c80d': {
            _id: '5c2e74ad91aca54130b0c80d',
            name: 'EPAM Взрослые (Минск)',
            shortName: 'EPAM Healthcare',
            status: 'OK',
            branchIds: ['5b46096c302c5a36f'],
            eligible: true,
            covererCode: 'epam',
            insurerCode: 'epam',
        },
    },
};