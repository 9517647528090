/* eslint-disable no-underscore-dangle */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Page, React, View } from '../../common';
import { Map } from '../../combo';
import { getClinicsDoctorsMap, getMapType } from '../../selectors';
import { averageLocation } from '../Tabletka/DrugstoreDetails.utils';
import { sortBy } from '../../utils';
import { doctorFilterParams } from '../Doctors/DoctorsSpecialtiesSearch';
import { MedcenterDetailsBunner } from './MedcenterDetailsBunner';
import { doctorsMap } from './styles';

export const DoctorsMap = Page.register(
    ({
        // data = [],
        item: initialItem,
        mapType,
        route: { params },
    }) => {
        const from = params?.from;
        const filter = doctorFilterParams[from] ?? doctorFilterParams.default;
        const data = useSelector(filter.doctorMap) ?? [];
        const [item, setItem] = useState(initialItem);
        const { id: itemId } = item || {};
        const initialRegion = useMemo(
            () => (initialItem && initialItem.latitude
                ? {
                    latitude: initialItem.latitude,
                    longitude: initialItem.longitude,
                }
                : averageLocation(data)),
            [initialItem, data],
        );

        const actualData = useMemo(() => {
            const d = sortBy(
                data.map(e => (!e.isSelected === !(e.id === itemId)
                    ? e
                    : {
                        ...e,
                        isSelected: !e.isSelected,
                    })),
                '-latitude',
            );
            setItem(data.find(i => (i.id === itemId ? itemId : data?.[0]?.id)));
            return d;
        }, [data]);
        return (
            <Page noscroll name="drugstore_details_screen">
                <View accessibilityLabel="drugstore_details_screen_map" style={doctorsMap.flex1}>
                    <View style={doctorsMap.flex1}>
                        {!initialRegion ? null : (
                            <Map
                                mapType={mapType}
                                data={actualData}
                                onItem={(i) => {
                                    setItem(i);
                                }}
                                initialRegion={initialRegion}
                                scaleKey="daysCount"
                                toolTipKey="nearestDate"
                                centerOnClick
                                item={item ? { id: item?.id } : null}
                            />
                        )}
                    </View>
                    {!itemId ? null : <MedcenterDetailsBunner item={item} from={from} />}
                </View>
            </Page>
        );
    },
    {
        mapType: { from: getMapType },
        data: { from: getClinicsDoctorsMap },
    },
);
