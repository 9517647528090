/* eslint-disable no-underscore-dangle */
import {
    Button,
    ButtonsGroup,
    Component,
    Page,
    Platform,
    React,
    Section,
    SingleNavigation,
    Switch,
} from '../../common';
import { putIntoStore, SCHEME } from '../../store';
import {
    getCurrentOrFirstProgram,
    getUmsPrefilterMedcenters,
    getUmsPrefilterMedcentersGroup,
    getUmsPrefilterWithOnline,
    getUmsPrefilterWithPrograms,
} from '../../selectors';
import actions, { back } from '../../actions';
import { MedcentersFilterModal } from '../../combo';
import { UMSSwitch } from './UMSListHeader';

const {
    UMS_FILTER,
    UMS_PREFILTER_MEDCENTERS,
    UMS_PREFILTER_WITH_PROGRAMS,
    UMS_PREFILTER_MEDCENTERS_GROUP,
    UMS_PREFILTER_WITH_ONLINE,
} = SCHEME;

class UmsFilterImpl extends Component {
    get selectedVisitorName() {
        return this.props.medcenters?.filter(e => e.selected)
            .map(({ name }) => name)
            .join(',\n') || Object.R('titles.anyMedcenter');
    }

    _resetFilter = () => {
        putIntoStore(UMS_FILTER, {
            medcenters: [],
            withPrograms: false,
        });
        const { medcenters = [] } = this.props;
        putIntoStore(UMS_PREFILTER_MEDCENTERS, medcenters.map(e => ({
            ...e,
            selected: false,
        })));
        putIntoStore(UMS_PREFILTER_WITH_PROGRAMS, false);
        putIntoStore(UMS_PREFILTER_WITH_ONLINE, false);
        actions.setOnlineFilter(false);
    };

    _applyFilter = () => {
        const {
            medcenters,
            withPrograms,
            group = 'any',
            withOnline,
        } = this.props;
        putIntoStore(UMS_FILTER, {
            medcenters: medcenters.filter(e => e.selected)
                .map(e => e.id),
            withPrograms,
            group,
        });
        actions.setOnlineFilter(withOnline);
        back();
    };

    render() {
        const {
            withPrograms,
            currentProgram,
            withOnline,
        } = this.props;
        const onOnlineSwitch = value => putIntoStore(UMS_PREFILTER_WITH_ONLINE, value);
        return (
            <Page
                name="ums-filter"
                footer={(
                    <ButtonsGroup style={{
                        backgroundColor: 'white',
                        paddingBottom: 12,
                        borderTopWidth: 1,
                        borderTopColor: '#F0F0F0',
                    }}>
                        <Button transparent action={this._resetFilter} title="buttons.reset_filter" titleStyles={{
                            color: '#555555',
                            fontSize: 16,
                            fontFamily: 'SourceSansPro-Semibold',
                        }} />
                        <Button primary action={this._applyFilter} title="buttons.find" />
                    </ButtonsGroup>
                )}
            >
                <Section title={Object.R('titles.ums_filter_section_medcenters')} capitalize>
                    <SingleNavigation
                        title={this.selectedVisitorName}
                        onPress={() => Page.showModal(<MedcentersFilterModal
                            groupFrom={getUmsPrefilterMedcentersGroup}
                            prefilterDataFrom={getUmsPrefilterMedcenters}
                            prefilterPath={UMS_PREFILTER_MEDCENTERS}
                            groupPath={UMS_PREFILTER_MEDCENTERS_GROUP}
                        />)}
                    />
                </Section>
                <Section title={Object.R('titles.ums_filter_section_services')} capitalize>
                    <SingleNavigation
                        title={Object.R('titles.ums_filter_only_with_online')}
                        style={{ paddingVertical: Platform.OS === 'ios' ? 8 : 10 }}
                        rightItem={<UMSSwitch value={withOnline} onValueChange={onOnlineSwitch} />}
                    />
                    {currentProgram?.id && currentProgram?.id !== '*'
                        ? <SingleNavigation
                            title={Object.R('titles.ums_filter_only_with_program')}
                            style={{ paddingVertical: Platform.OS === 'ios' ? 8 : 10, marginTop: 16 }}
                            rightItem={<Switch
                                    accessibilityLabel="switch:with-programs"
                                    style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                                    onTintColor="#6987D6"
                                    thumbTintColor="#5880D0"
                                    value={withPrograms}
                                    onValueChange={value => putIntoStore(UMS_PREFILTER_WITH_PROGRAMS, value)}
                            />}
                        /> : null}

                </Section>
            </Page>
        );
    }
}

export const UmsFilter = Page.register(UmsFilterImpl, {
    medcenters: { from: getUmsPrefilterMedcenters },
    withPrograms: { from: getUmsPrefilterWithPrograms },
    withOnline: { from: getUmsPrefilterWithOnline },
    group: { from: getUmsPrefilterMedcentersGroup },
    currentProgram: { from: getCurrentOrFirstProgram },
});
