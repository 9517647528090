import { Platform, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
import { ProgramSelector } from '../../combo';
import {
    Icon,
    List,
    OnMapButton,
    Page,
    React,
    Section,
    selectOptions,
    Stub,
    TopNotification,
    View,
} from '../../common';
import { MedCentersListItem } from '../MedCenters/snippets';
import api from '../../services/api';
import {
    actualNotificationsByTargetList,
    coveredMedcentersSort,
    getCurrentLocation,
    getCurrentOrFirstProgram,
    getIsOnline,
    getRelatedPrograms,
    getUmsFilter,
    medcentersSort,
} from '../../selectors';
import { serviceMedcentersSortOptions } from '../../selectors/utils';
import {
    arrayToHash, curry, histogram, sortBy, sortByNonNull,
} from '../../utils';
import tracking from '../../services/tracking';
import { getFromStoreDb, putIntoStore, SCHEME } from '../../store';
import actns from '../../actions';
import { Log } from '../../common/Log';
import { hasClinicsCoords } from '../Tabletka/DrugstoreDetails.utils';
import {
    BumbaSection, OptionsSection, ServiceNotification, UmsServiceCard,
} from './snippets';

const SHOW_SORT_LOG = false;

const idFn = e => e;

const onSort = curry(putIntoStore, SCHEME.UMS_MEDCENTERS_SORT_BY);
const onSortCovered = curry(putIntoStore, SCHEME.UMS_MEDCENTERS_COVERED_SORT_BY);
const renderItem =
    (info, from) => ({ item }) => (
        <>
            {!!SHOW_SORT_LOG && (
                <Log
                    value={{
                        onlineBooking: item?.onlineBooking,
                        bumbaTests: item?.bumbaTestsSupport,
                        bumbaReports: item?.bumbaReportsSupport,
                        onlineConnectivity: item?.onlineConnectivity,
                        doctorsCounter: item?.doctorsCounter,
                        branchDoctorsCounter: item?.branchDoctorsCounter,
                        id: item?._id ?? item?.id,
                    }}
                />
            )}
            <MedCentersListItem
                mdInfo={item}
                info={info}
                hideCommentsCount
                noHighlighter
                byOnlineBooking
                from={from}
            />
        </>
    );

const showSortModal = () => {
    const selectedSort = getFromStoreDb(SCHEME.UMS_MEDCENTERS_SORT_BY);
    const medcenterSort = serviceMedcentersSortOptions();
    const selectedSortId = selectedSort ? selectedSort.id : medcenterSort[0].id;
    selectOptions({
        title: 'titles.sort_list_of_medcenters',
        selected: selectedSortId,
        data: medcenterSort,
        onSelect: e => Page.closeModal(() => {
            tracking.logEvent(`ai_ums_medcenters_sort_${e.id}`);
            onSort(e);
        }),
    });
};

const showCoveredSortModal = () => {
    const selectedSort = getFromStoreDb(SCHEME.UMS_MEDCENTERS_COVERED_SORT_BY);
    const medcenterSort = serviceMedcentersSortOptions();
    const selectedSortId = selectedSort ? selectedSort.id : medcenterSort[0].id;
    selectOptions({
        title: 'titles.sort_list_of_medcenters',
        selected: selectedSortId,
        data: medcenterSort,
        onSelect: e => Page.closeModal(() => {
            tracking.logEvent(`ai_ums_medcenters_sort_${e.id}`);
            onSortCovered(e);
        }),
    });
};

const getSortedData = (data, sortedBy = '') => {
    if (sortedBy === '') {
        return data.sort((a, b) => {
            const aHasBumba = a.bumbaReportsSupport || a.bumbaTestsSupport;
            const bHasBumba = b.bumbaReportsSupport || b.bumbaTestsSupport;
            const diff = (b?.doctorsCounter ?? 0) - (a?.doctorsCounter ?? 0);

            if (aHasBumba !== bHasBumba) {
                if (aHasBumba) {
                    return -1;
                }
                return 1;
            }

            if (a.onlineConnectivity !== b.onlineConnectivity) {
                if (a.onlineConnectivity) {
                    return -1;
                }
                return 1;
            }

            if (a.onlineBooking !== b.onlineBooking) {
                if (a.onlineBooking) {
                    return -1;
                }
                return 1;
            }

            if (diff === 0) {
                return (b.branchDoctorsCounter ?? 0) - (a.branchDoctorsCounter ?? 0);
            }

            return diff;
        });
    }
    if (sortedBy === 'minPrice') {
        return [
            ...sortBy(
                data
                    .filter(e => e.prices.length && e.showPrices)
                    .map(e => ({
                        ...e,
                        minPrice: Math.min(...e.prices),
                    })),
                sortedBy,
            ),
            ...data.filter(e => !(e.prices.length && e.showPrices)),
        ];
    }
    if (sortedBy === '-doctorsCounter') {
        return data?.sort?.((a, b) => {
            const diff = (b?.doctorsCounter ?? 0) - (a?.doctorsCounter ?? 0);
            if (diff === 0) {
                return (b.branchDoctorsCounter ?? 0) - (a.branchDoctorsCounter ?? 0);
            }
            return diff;
        });
    }
    if (sortedBy) {
        return sortBy(data, sortedBy);
    }
    return [
        ...data.filter(e => e.prices.length && e.showPrices),
        ...data.filter(e => !(e.prices.length && e.showPrices)),
    ];
};

const ClinicsList = ({
    sectionTitle,
    currentCity,
    data,
    sortedData,
    isLoading,
    logEventNamePostfix,
    capitalize,
    covered,
    info,
    serviceName,
    from = 'default',
}) => {
    const onPressSortIcon = covered
        ? () => {
            showCoveredSortModal();
        }
        : () => {
            showSortModal();
        };
    const servInfo = {
        ...info,
        serviceName,
    };
    return (
        <Section
            title={sectionTitle}
            params={{ currentCity }}
            capitalize={capitalize}
            right={
                <View
                    style={{
                        paddingRight: 16,
                        flexDirection: 'row',
                    }}>
                    {!!sortedData?.length && hasClinicsCoords(sortedData) && (
                        <OnMapButton
                            logEventName={`ai_btn_on_ums_mc_map${logEventNamePostfix}`}
                            route="UMSMedCentersMap"
                            routeParams={{ data }}
                            containerStyle={{ marginRight: 12 }}
                        />
                    )}
                    <Icon.Sort onPress={onPressSortIcon} />
                </View>
            }>
            <List
                data={sortedData}
                isLoading={isLoading}
                renderItem={renderItem(servInfo, from)}
                ListEmptyComponent={<Stub title=" " style={{ paddingTop: 36 }} type="empty-clinics" />}
            />
        </Section>
    );
};

const ClinicsSection = ({
    currentProgram = {}, clinicsList = [], currentLocation, isLoading, info, serviceName,
}) => {
    const sortType = useSelector(medcentersSort);
    const coveredSortType = useSelector(coveredMedcentersSort);
    const notifications = useSelector(state => actualNotificationsByTargetList(state))(clinicsList.map(e => e.id));

    const adaptClinicsList = clinicsList.map((e) => {
        let currentConnectivityStatus;
        let currentNotification;
        if (!e.connectivityStatus) {
            currentNotification = notifications?.[e.id];
            if (currentNotification) {
                currentConnectivityStatus = 'offline';
            } else {
                currentConnectivityStatus = e.onlineConnectivity ? 'online' : 'unavailable';
            }
        }
        return {
            ...e,
            workTime: ((e.schedule || '').match(/(\b\d+:\d+\s-\s\d+:\d+)/) || [])[0] || Object.R('titles.no_schedule'),
            connectivityStatus: e.connectivityStatus || currentConnectivityStatus,
            notification: e.notification || currentNotification,
        };
    });

    if (!currentProgram.id || currentProgram.id === '*') {
        return (
            <ClinicsList
                sectionTitle="titles.whereToGo"
                currentCity={currentLocation.name}
                data={adaptClinicsList}
                sortedData={getSortedData(adaptClinicsList, sortType ? sortType.expr : '')}
                isLoading={isLoading}
                info={info}
                serviceName={serviceName}
            />
        );
    }
    const coveredClinics = adaptClinicsList.filter(({ coverer }) => !!coverer);
    const notCoveredClinics = adaptClinicsList.filter(({ coverer }) => !coverer);

    const hasCoveredClinics = coveredClinics.length || notCoveredClinics.length;

    const CoveredClinicsList = () => {
        if (coveredClinics.length) {
            return (
                <ClinicsList
                    sectionTitle="titles.coveredMedcenters"
                    capitalize
                    data={coveredClinics}
                    sortedData={getSortedData(coveredClinics, coveredSortType ? coveredSortType.expr : '')}
                    isLoading={isLoading}
                    logEventNamePostfix="_cover"
                    covered
                    serviceName={serviceName}
                    info={info}
                />
            );
        }
        return null;
    };

    const NotCoveredClinicsList = () => {
        if (notCoveredClinics.length) {
            return (
                <ClinicsList
                    sectionTitle="titles.notCoveredMedcenters"
                    capitalize
                    data={notCoveredClinics}
                    sortedData={getSortedData(notCoveredClinics, sortType ? sortType.expr : '')}
                    isLoading={isLoading}
                    logEventNamePostfix="_ncover"
                    serviceName={serviceName}
                    info={info}
                />
            );
        }
        return null;
    };

    return (
        <View>
            <CoveredClinicsList />
            <NotCoveredClinicsList />
            {!hasCoveredClinics && <Stub title=" " style={{ paddingTop: 36 }} type="empty-clinics" />}
        </View>
    );
};

export const getRefItemInfo = async (serviceId, programId, serviceName) => {
    try {
        const info = await api.getUmsItemInfo(serviceId, programId);
        // console.log('info', info);

        const optionsCombinations = info.mapping.map(
            ({
                priceItemId,
                branchIds,
                // providerId,
                specOptionIds,
                coveragePct,
                coverageAttributes,
                // calc:
                optionIds = specOptionIds || [],
                clinicIds = branchIds || [], // || [providerId],
            }) => ({
                // ...item,
                id: priceItemId,
                clinicIds,
                optionIds,
                coveragePct,
                coverageAttributes,
                clinicIdsHash: arrayToHash(clinicIds, idFn),
                optionIdsHash: arrayToHash(optionIds, idFn),
                optionCode: `${optionIds.sort()}`,
            }),
        );

        // console.log('optionsCombinations', optionsCombinations);
        const clinicsPrices = info.items
            .map(
                ({
                    // providerId,
                    priceFull,
                    _id,
                    branchIds = [],
                    onlineBooking = false,
                }) => ({
                    providerIds: branchIds, // branchIds.length ? branchIds : [providerId],
                    priceFull,
                    priceId: _id,
                    onlineBooking,
                }),
            )
            .filter(e => e.priceFull !== 0);

        const rr = {};
        clinicsPrices.forEach((e) => {
            e.providerIds.forEach((providerId) => {
                rr[providerId] = {
                    prices: [
                        ...((rr[providerId] || {}).prices || []),
                        {
                            price: e.priceFull,
                            specOptionIds:
                                (info.mapping.find(el => el.priceItemId === e.priceId) || {}).specOptionIds || [],
                        },
                    ],
                    onlineBooking: rr?.[providerId]?.onlineBooking ? true : e.onlineBooking,
                };
            });
        });

        actns.setCurrentService({
            items: info.items,
            serviceName,
        });
        return {
            optionsCombinations,
            clinicIds: histogram(optionsCombinations, 'clinicIds'),
            optionIds: histogram(optionsCombinations, 'optionIds'),
            clinicsPrices: rr,
            isRefInfoLoaded: true,
            info,
        };
    } catch (error) {
        return {
            isRefInfoLoaded: true,
            error,
        };
    }
};
/* eslint-disable complexity, no-param-reassign */
/**
 * Service Details page.
 */
export const ServiceDetails = Page.register(
    // Controller
    ({
        setState,
        actions,
        error,
        isOnlineFilter,
        details: {
            id: serviceId, name: serviceName, options, clinics, isLoading, programs, preSelectedOption,
        },
        info = {},
        currentLocation,
        clinicFilter: { medcenters = [] } = {},
        // programs
        relatedPrograms,
        relatedProgramsList = Object.values(relatedPrograms),
        currentProgram = {},

        // options
        isRefInfoLoaded = false,
        optionIds = {},
        // optionCodes = {},
        clinicsList = Object.values(clinics).filter(
            ({ areaCode, hidden, id }) => areaCode === Number(currentLocation.code) &&
                !hidden &&
                (medcenters.length ? medcenters.some(e => e === id) : true),
        ),
        selectedOptions = preSelectedOption ? { [preSelectedOption.id]: preSelectedOption } : {},
        selectedOptionsIds = (setState.selectedOptionsIds = Object.keys(selectedOptions).filter(
            id => selectedOptions[id],
        )),
        // selectedOptionsCode = `${selectedOptionsIds.sort()}`,
        optionsCombinations = [],
        isValidOptionsCombination = !selectedOptionsIds.length ||
            optionsCombinations.some(
                ({ optionIdsHash }) => selectedOptionsIds.filter(id => optionIdsHash[id]).length === selectedOptionsIds.length,
            ),
        localOptions = options.filter(({ id: optionId }) => optionsCombinations.some(
            ({ optionIdsHash, clinicIdsHash }) => optionIdsHash[optionId] && clinicsList.some(({ id: clinicId }) => clinicIdsHash[clinicId]),
        )),
        optionsGrouped = sortBy(
            Object.entries(histogram(localOptions, 'groupName')).map(([n, { subs }]) => ({
                get haveCompatibleOptions() {
                    const selIds = setState.selectedOptionsIds;
                    return (
                        !subs.some(v => !selIds.includes(v.id)) ||
                        subs
                            .filter(
                                v => !selIds.length ||
                                    selIds.includes(v.id) ||
                                    optionsCombinations.some(
                                        ({ optionIdsHash }) => optionIdsHash[v.id] &&
                                            selIds.filter(id => optionIdsHash[id]).length === selIds.length,
                                    ),
                            )
                            .some(v => v.groupName === n && !selIds.includes(v.id))
                    );
                },
                get name() {
                    return `${n}${this.haveCompatibleOptions ? '' : ' *'}`;
                },
                values: subs.map(v => ({
                    id: v.id,
                    coverer:
                        optionIds[v.id] &&
                        optionIds[v.id].subs.some(
                            ({ coveragePct, clinicIdsHash }) => coveragePct && clinicsList.some(({ id: clinicId }) => clinicIdsHash[clinicId]),
                        )
                            ? currentProgram.coverer
                            : null,
                    isLimited:
                        optionIds[v.id] &&
                        optionIds[v.id].subs.some(
                            ({ coverageAttributes }) => coverageAttributes && coverageAttributes.LIMIT,
                        ),
                    get compatibleWithAll() {
                        const selIds = setState.selectedOptionsIds;
                        return (
                            !selIds.length ||
                            selIds.includes(v.id) ||
                            optionsCombinations.some(
                                ({ optionIdsHash }) => optionIdsHash[v.id] &&
                                    selIds.filter(id => optionIdsHash[id]).length === selIds.length,
                            )
                        );
                    },
                    get name() {
                        return `${v.name}${this.compatibleWithAll ? '' : ' *'}`;
                    },
                })),
            })),
        ),
        hasOptions = optionsGrouped.length,

        // clinics list
        clinicIds,
        clinicsPrices = {},
        clinicIdsForSelectedOptions = !selectedOptionsIds.length || !optionsCombinations.length
            ? null
            : optionsCombinations
                .filter(
                    ({ optionIdsHash }) => selectedOptionsIds.filter(id => optionIdsHash[id]).length === selectedOptionsIds.length,
                )
                .reduce((r, { clinicIdsHash }) => ({ ...r, ...clinicIdsHash }), {}),

        clinicsListFiltered = clinicsList.filter(({ id }) => (clinicIdsForSelectedOptions ? clinicIdsForSelectedOptions[id] : clinicIds && clinicIds[id])),
        clinicsListCovered = clinicsListFiltered.map(e => ({
            ...e,
            coverer: (
                clinicIdsForSelectedOptions
                    ? clinicIdsForSelectedOptions[e.id] &&
                      clinicIds &&
                      clinicIds[e.id] &&
                      clinicIds[e.id].subs.some(
                          ({ coveragePct, optionIdsHash }) => coveragePct && !selectedOptionsIds.filter(id => !optionIdsHash[id]).length,
                      )
                    : currentProgram &&
                      programs &&
                      programs[currentProgram.id] &&
                      programs[currentProgram.id].coverage[e.id]
            )
                ? currentProgram.coverer
                : null,
            limited:
                clinicIds &&
                clinicIds[e.id] &&
                clinicIds[e.id].subs.some(({ coverageAttributes = {} }) => coverageAttributes.LIMIT),
        })),
        clinicsListSorted = sortByNonNull(clinicsListCovered, 'coverer')
            .map(e => ({
                ...e,
                prices: ((clinicsPrices[e.id] || {}).prices || [])
                    .filter(el => (selectedOptionsIds.length
                        ? selectedOptionsIds.filter(so => (el.specOptionIds || []).some(soi => soi === so))
                            .length === selectedOptionsIds.length
                        : true))
                    .map(pItem => pItem.price),
                onlineBooking: clinicsPrices?.[e.id]?.onlineBooking,
            }))
            // .filter(({ id }) => info?.mapping?.some?.(({ branchIds }) => branchIds?.includes?.(id)))
            .filter(
                ({
                    onlineBooking,
                    // branchIds,
                }) => (isOnlineFilter ? onlineBooking : true),
            ),
        onDidMount = async () => {
            setState(await getRefItemInfo(serviceId, currentProgram.id, serviceName));
        },
        onWillUnmount = async () => {
            actns.setCurrentService();
        },
        onSelectProgram = async (program) => {
            setState({ isRefInfoLoaded: false });
            setState(await getRefItemInfo(serviceId, program.id, serviceName));
            actions.setProgram(program);
        },
        onSelectOption = (e) => {
            const newOptions = { ...Object.assign(selectedOptions, { [e.id]: !e.selected ? null : e }) };
            setState({ selectedOptions: newOptions });
        },
        // View
    }) => (
        <Page
            name="umsService"
            onDidMount={onDidMount}
            onWillUnmount={onWillUnmount}
            notification={error}
            noscroll
            isLoading={!isRefInfoLoaded}>
            <ProgramSelector onSelect={onSelectProgram} withInfo />
            <ScrollView
                style={{
                    height: Platform.OS === 'web' ? 0 : undefined,
                    flex: 1,
                }}>
                <View onStartShouldSetResponder={() => true}>
                    <UmsServiceCard name={serviceName}>
                        {!hasOptions ? null : (
                            <ServiceNotification
                                key="$note"
                                isValidOptions={isValidOptionsCombination}
                                hasProgramm={
                                    relatedProgramsList.length > 1 || relatedProgramsList.some(({ id }) => id !== '*')
                                }
                            />
                        )}
                    </UmsServiceCard>
                    {hasOptions ? (
                        <OptionsSection
                            isRefInfoLoaded={isRefInfoLoaded}
                            optionsGrouped={optionsGrouped}
                            onSelectOption={onSelectOption}
                            selectedOptions={selectedOptions}
                            onClearPress={() => setState({ selectedOptions: {} })}
                        />
                    ) : null}
                    <TopNotification hint="hints.ums_prices" />
                    {clinicsListSorted.some(e => e.bumbaReportsSupport || e.bumbaTestsSupport) ? (
                        <BumbaSection />
                    ) : null}
                    {!isRefInfoLoaded || !isValidOptionsCombination ? null : (
                        <>
                            <ClinicsSection
                                currentProgram={currentProgram}
                                clinicsList={clinicsListSorted}
                                currentLocation={currentLocation}
                                isLoading={isLoading}
                                info={info}
                                serviceName={serviceName}
                                serviceId={serviceId}
                            />
                        </>
                    )}
                </View>
            </ScrollView>
        </Page>
    ),
    // Model
    {
        relatedPrograms: { from: getRelatedPrograms },
        currentProgram: { from: getCurrentOrFirstProgram },
        currentLocation: { from: getCurrentLocation },
        clinicFilter: { from: getUmsFilter },
        isOnlineFilter: { from: getIsOnline },
    },
);
