import { useCallback, useEffect, useState } from 'react';
// import { NestableDraggableFlatList, NestableScrollContainer, ScaleDecorator } from 'react-native-draggable-flatlist';
import { useSelector } from 'react-redux';
import actions, { navigate } from '../../../actions';
import {
    Avatar,
    FlatList,
    Icon,
    Page,
    Platform,
    Prompt,
    React,
    RText,
    Section,
    Text,
    TouchableOpacity,
    View,
} from '../../../common';
import {
    getFavoritesFull,
    getIsEmailUnconfirmed,
    getIsPersonalized,
    getisPrefsLoading,
    getUser,
    isFavoritesLoading,
    isProfileEmpty,
} from '../../../selectors';
import tracking from '../../../services/tracking';
import { myCard as styles } from '../styles';

const promptFavDelete = (onSubmit, favName) => Page.showModal(
    <Prompt
        title={Object.R('titles.favDelete', { name: favName })}
        submitTitle="buttons.yes"
        cancelTitle="buttons.no"
        onSubmit={onSubmit}
        cancelOptions={{ primary: false }}
        titleNumberOfLines={null}
    />,
);

const FavsEmpty = () => {
    const isPersonalized = useSelector(getIsPersonalized);
    return (
        <RText
            numberOfLines={null}
            style={styles.emptyListText}
            id={isPersonalized ? 'my-card.emptyFavoriteList' : 'my-card.needToSignIn'}
        />
    );
};

const EmptyItem = () => {
    return <View style={[styles.listItem, {}]} />;
};

const FavoriteItem = React.memo(({
    displayName, photoUrl, id, type, coverer, drag, isActive,
}) => {
    const onPress = useCallback(() => {
        const isClinic = type === 'clinics';
        if (!isClinic) {
            actions.setFormValue('umsServices', []);
        }
        const eventParams = isClinic ? ['open_mc_bookmark', { clinicId: id }] : ['open_doc_bookmark', { docId: id }];
        tracking.logEvent(...eventParams);

        const navAargs = isClinic
            ? [
                'MedCenterDetails',
                {
                    mdInfo: { _id: id },
                    from: 'default',
                },
            ]
            : [
                'DoctorDetails',
                {
                    doctorInfo: {
                        _id: id,
                        id,
                    },
                },
            ];
        navigate(...navAargs);
    }, [displayName, photoUrl, id]);
    return (
        // <ScaleDecorator>
        //     <TouchableOpacity onLongPress={drag} disabled={isActive} style={styles.listItem} onPress={onPress}>
        //         {isActive ? (
        //             <Icon.Left
        //                 style={{
        //                     position: 'absolute',
        //                     left: 0,
        //                 }}
        //                 color="#787878"
        //             />
        //         ) : null}
        //         <Avatar of={type} imageUrl={photoUrl} coverer={coverer} webK={1} />
        //         {isActive ? (
        //             <Icon.Right
        //                 style={{
        //                     position: 'absolute',
        //                     right: 0,
        //                 }}
        //                 color="#787878"
        //             />
        //         ) : null}
        //         <Text
        //             numberOfLines={2}
        //             textBreakStrategy={'simple'}
        //             style={[styles.listItemTitle, Platform.OS === 'web' && styles.listItemTitleWeb, { width: '100%' }]}>
        //             {displayName}
        //         </Text>
        //     </TouchableOpacity>
        // </ScaleDecorator>
        null
    );
});

const SimpleFavoriteItem = React.memo(
    ({
        displayName,
        photoUrl,
        id,
        type,
        coverer,
        changeOrder = () => {},
        index,
        isLast,
        isDraggable,
        setIsDraggableState,
        onDelete,
    }) => {
        const [isArrowsVisible, setIsArrowsVisible] = useState(false);
        const onDeletePress = () => onDelete(id);
        const onPress = useCallback(() => {
            const isClinic = type === 'clinics';
            const eventParams = isClinic
                ? ['open_mc_bookmark', { clinicId: id }]
                : ['open_doc_bookmark', { docId: id }];
            tracking.logEvent(...eventParams);

            const navAargs = isClinic
                ? [
                    'MedCenterDetails',
                    {
                        mdInfo: { _id: id },
                        from: 'default',
                    },
                ]
                : [
                    'DoctorDetails',
                    {
                        doctorInfo: {
                            _id: id,
                            id,
                        },
                    },
                ];
            navigate(...navAargs);
        }, [displayName, photoUrl, id]);

        const onLongPress = () => {
            setIsArrowsVisible(!isDraggable);
            setIsDraggableState(setIsArrowsVisible, !isDraggable);
        };
        const moveRight = () => {
            changeOrder(index, true, setIsArrowsVisible);
        };
        const moveLeft = () => {
            changeOrder(index, false, setIsArrowsVisible);
        };
        const emptyFn = () => {};

        useEffect(() => {
            if (!isDraggable && isArrowsVisible) {
                setIsArrowsVisible(false);
            }
        }, [isDraggable]);

        return (
            <TouchableOpacity
                style={styles.listItem}
                onPress={isArrowsVisible ? emptyFn : onPress}
                onLongPress={onLongPress}>
                {isArrowsVisible && index !== 0 ? (
                    <TouchableOpacity
                        style={{
                            position: 'absolute',
                            left: 0,
                            paddingHorizontal: 5,
                            paddingVertical: 10,
                        }}
                        onPress={moveLeft}>
                        <Icon.Left color="#919191" />
                    </TouchableOpacity>
                ) : null}
                <Avatar of={type} imageUrl={photoUrl} coverer={coverer} webK={1} />
                {isArrowsVisible && !isLast ? (
                    <TouchableOpacity
                        style={{
                            position: 'absolute',
                            right: 0,
                            paddingHorizontal: 5,
                            paddingVertical: 10,
                        }}
                        onPress={moveRight}>
                        <Icon.Right color="#919191" />
                    </TouchableOpacity>
                ) : null}
                {isDraggable ? (
                    <TouchableOpacity
                        style={{
                            position: 'absolute',
                            right: 0,
                            top: 0,
                            paddingHorizontal: 5,
                            paddingVertical: 5,
                        }}
                        onPress={() => {
                            promptFavDelete(onDeletePress, displayName);
                        }}>
                        <Icon.Cross color="#919191" />
                    </TouchableOpacity>
                ) : null}
                <Text
                    numberOfLines={2}
                    style={[styles.listItemTitle, Platform.OS === 'web' && styles.listItemTitleWeb]}>
                    {displayName}
                </Text>
            </TouchableOpacity>
        );
    },
);

const renderFavItem = ({
    item: {
        displayName, photoUrl, type, coverer, id,
    }, drag, isActive,
}) => (
    <FavoriteItem
        displayName={displayName?.replace(/\n/g, ' ')}
        photoUrl={photoUrl}
        type={type}
        id={id}
        coverer={coverer}
        drag={drag}
        isActive={isActive}
    />
);

const renderSimpleFavItem = ({
    item: {
        displayName, photoUrl, type, coverer, id,
    },
    changeOrder,
    index,
    isLast,
    setIsDraggableState,
    isDraggable,
    onDelete,
}) => (
    <SimpleFavoriteItem
        displayName={displayName}
        photoUrl={photoUrl}
        type={type}
        id={id}
        coverer={coverer}
        changeOrder={changeOrder}
        index={index}
        isLast={isLast}
        isDraggable={isDraggable}
        setIsDraggableState={setIsDraggableState}
        onDelete={onDelete}
    />
);

// const updatedFullFavorites = async (favoritesIds) => {
//     actions.onFavoritesLoadFromCollections(true);
//     const phData = await firebaseService.getItemsFromCollection('physicians', favoritesIds);
//     const clData = await firebaseService.getItemsFromCollection('clinics', favoritesIds);
//     const data = [...(Object.values(clData || {})
//         .map(item => adaptClinicToFav(item))), ...(Object.values(phData || {})
//         .map(item => adaptDoctorToFav(item)))];
//     actions.updateFullFavoritesList(data);
//     actions.onFavoritesLoadFromCollections(false);
// };

// export const useFavoritesFetch = () => {
//     const isFavsNeedUpdate = useSelector(getIsFavoritesNeedsUpdate);
//     const favoritesIds = useSelector(getFavorites);
//     useEffect(() => {
//         if (isFavsNeedUpdate) {
//             // eslint-disable-next-line no-console
//             console.log('WARNING_FAV');
//             updatedFullFavorites(favoritesIds);
//         }
//     }, isFavsNeedUpdate);
// };
let timeout;
const FavoritesList = React.memo(
    ({ data }) => {
        const [isDraggable, setIsDraggable] = useState(false);
        const changeOrder = (index, isMovingRight, cb) => {
            const item = { ...data[index] };
            const newData = [...data];
            if (isMovingRight) {
                newData[index] = { ...newData[index + 1] };
                newData[index + 1] = item;
            } else {
                newData[index] = { ...newData[index - 1] };
                newData[index - 1] = item;
            }
            actions.updateFullFavoritesList(newData);
            if (timeout) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    setIsDraggable(false);
                    cb(false);
                    timeout = undefined;
                }, 5000);
            }
        };

        const onDelete = (id) => {
            const newData = data?.filter(e => e?.id !== id);
            actions.updateFullFavoritesList(newData);
        };

        const setIsDraggableState = (cb, isDr) => {
            setIsDraggable(isDr);
            if (!isDr && timeout) {
                clearTimeout(timeout);
                return;
            }
            timeout = setTimeout(() => {
                setIsDraggable(false);
                cb(false);
                timeout = undefined;
            }, 5000);
        };

        return (
            <FlatList
                data={data}
                accessibilityLabel="favorites"
                keyExtractor={e => e.id}
                horizontal={true}
                ListEmptyComponent={EmptyItem}
                renderItem={({ item, index }) => renderSimpleFavItem({
                    item,
                    changeOrder,
                    index,
                    isLast: data?.length - 1 === index,
                    isDraggable,
                    setIsDraggableState,
                    onDelete,
                })
                }
                style={{ paddingBottom: 6 }}
                showsHorizontalScrollIndicator={Platform.OS !== 'android'}
            />
        );
    },
    (data1, data2) => JSON.stringify(data1) === JSON.stringify(data2),
);

const DraggableFavoritesList = React.memo(
    ({ data }) => {
        const [actualData, setActualData] = useState(data || []);
        // const [isScrollIndicatorVisiable, setIsScrollIndicatorVisiable] = useState(false);

        const onDragEnd = useCallback(({ data: _data }) => {
            setActualData(_data);
            actions.updateFullFavoritesList(_data);
        }, []);

        // const onContentSizeChange = () => {
        //     // eslint-disable-next-line no-console
        //     console.log('isScrollIndicatorVisiable: ', isScrollIndicatorVisiable);
        //     if (!isScrollIndicatorVisiable) {
        //         setIsScrollIndicatorVisiable(true);
        //     }
        // };

        useEffect(() => {
            if (data && JSON.stringify(actualData) !== JSON.stringify(data)) {
                setActualData(data);
            }
        }, [data]);

        return (
            // <NestableScrollContainer
            //     style={actualData.length < 3 ? { width: favItemWidth * actualData.length + 10 } : null}>
            //     <NestableDraggableFlatList
            //         data={actualData}
            //         onDragEnd={onDragEnd}
            //         accessibilityLabel="favorites"
            //         keyExtractor={e => e.id}
            //         horizontal={true}
            //         ListEmptyComponent={FavsEmpty}
            //         renderItem={renderFavItem}
            //         style={{ paddingVertical: 6 }}
            //         scrollEnabled
            //         // onContentSizeChange={onContentSizeChange}
            //         // showsHorizontalScrollIndicator={isScrollIndicatorVisiable}
            //         showsHorizontalScrollIndicator={false}
            //     />
            // </NestableScrollContainer>
            null
        );
    },
    (data1, data2) => JSON.stringify(data1) === JSON.stringify(data2),
);

export const FavoritesListSection = () => {
    const data = useSelector(getFavoritesFull);
    const isPrefsLoading = useSelector(getisPrefsLoading);
    const isLoading = useSelector(isFavoritesLoading);
    const { isLoading: isUserLoading } = useSelector(getUser);
    const emptyProfile = useSelector(isProfileEmpty);
    const unconfirmed = useSelector(getIsEmailUnconfirmed);
    const [load, setLoad] = useState(isUserLoading);

    useEffect(() => {
        if (isUserLoading) {
            setLoad(isUserLoading);
        } else {
            setTimeout(() => setLoad(isUserLoading), isUserLoading ? 0 : 500);
        }
    }, [isUserLoading]);

    return (
        <Section
            title={'my-card.favHeaderTitle'}
            // isLoading={isLoading || isPrefsLoading}
        >
            {/* <Log value={{ */}
            {/*     // isUserLoading, */}
            {/*     isLoading, */}
            {/*     isPrefsLoading, */}
            {/*     isFetch, */}
            {/*     emptyProfile, */}
            {/*     unconfirmed, */}
            {/*     load, */}
            {/* }} text="Loading"/> */}
            {
                // eslint-disable-next-line no-nested-ternary
                data?.length ? (
                    // Platform.OS === 'web' ? (
                    <>
                        <FavoritesList data={data} />
                    </>
                ) // ) : (
                //     <DraggableFavoritesList data={data} />
                // )
                    : (!load && (isLoading === false || isPrefsLoading === false)) ||
                  (Platform.OS === 'web' && (unconfirmed === true || emptyProfile === true)) ? (
                            <FavsEmpty />
                        ) : (
                            <></>
                        )
            }
        </Section>
    );
};
