import { useSelector } from 'react-redux';
import { getFocusedRouteNameFromRoute, useNavigation, useRoute } from '@react-navigation/native';
import Entypo from 'react-native-vector-icons/Entypo';
import { useCallback, useEffect } from 'react';
import { BackHandler } from 'react-native';
import { navbar as styles } from '../styles/components';
import { appHeaderBgColor } from '../common/style';
import {
    getIsFetching, getUserInfo, getUserLoadingState, hasUnfilledRelatives,
} from '../selectors';
import { back, navigate } from '../actions';
import {
    Box,
    Button,
    dataFrom,
    dataTrigger,
    Icon,
    Image,
    Img,
    LoadingIndicator,
    Platform,
    React,
    RText,
    S,
    SafeAreaView,
    TouchableOpacity,
    View,
    withIsAuthenticated,
} from '../common';
import { BadgePendings } from '../common/elements';
import { getFormData } from '../selectors/forms';

const DEBUG_BACK_HANDLER = false;

const UserLoadingIndicator = dataTrigger(getUserLoadingState, ({ style, size = 44 }) => (
    <LoadingIndicator
        style={[
            {
                position: 'absolute',
                right: 0,
                left: 0,
            },
            style,
        ]}
        indicatorSize={size}
        color="white"
    />
));

const UserIcon = dataFrom(
    getUserInfo,
    ({
        data: {
            photoUrl,
            emails = [],
            pendingEmails = emails.filter(({ confirmed }) => !confirmed),
            accessNotAuthorized,
            isPerson,
        },
        havePendings = !isPerson || accessNotAuthorized || pendingEmails.length,
    }) => {
        const isFetching = useSelector(getIsFetching);
        const email = emails.find(e => e.usage === 'PRIMARY' && e.confirmed);
        const unfilledRelatives = useSelector(hasUnfilledRelatives);
        return (
            <Button
                styles={[styles.icon, { marginRight: 12 }]}
                action={() => {
                    navigate('Account');
                }}
                ns="user-icon"
                trackingAlias="user_icon">
                {accessNotAuthorized ? null : (
                    <UserLoadingIndicator style={photoUrl ? null : { right: 3 }} size={photoUrl ? 44 : 40} />
                )}
                {photoUrl ? (
                    <Image style={styles.userPhoto} source={{ uri: photoUrl }} />
                ) : (
                    <Img.UserAvatar
                        style={{
                            width: 27,
                            height: 27,
                        }}
                    />
                )}
                {(havePendings || !email || unfilledRelatives) && !isFetching ? (
                    <BadgePendings
                        badge=""
                        right={photoUrl ? -2 : 0}
                        top={0}
                        style={{
                            borderColor: '#EA1A28',
                            backgroundColor: '#EA1A28',
                            width: 10,
                            height: 10,
                        }}
                        textStyle={{ marginLeft: Platform.OS === 'ios' ? 1 : 0 }}
                    />
                ) : null}
            </Button>
        );
    },
);

const SignInIcon = () => (
    <Button
        title="buttons.sign_in"
        action={() => {
            Object.trackNavigation('SignIn');
            navigate('Account');
        }}
        titleStyles={styles.link}
        ns="signin-link"
    />
);

const NavbarRight = withIsAuthenticated(({ isAuthenticated, children, noFlex }) => (
    <View
        style={[
            {
                flex: noFlex ? 0.3 : 1,
                marginLeft: 10,
                alignItems: 'flex-end',
            },
        ]}>
        <Box centered ns="navRight" gap={1}>
            {children}
            {isAuthenticated ? <UserIcon /> : <SignInIcon />}
            {/* {isAuthenticated === false ? <SignInIcon /> : null} */}
            {/* {isAuthenticated === undefined ? <View style={{ width: 30, height: 30, marginRight: 12 }} /> : null} */}
        </Box>
    </View>
));

export const BackNav = ({ title, backPress = back, textColor = 'white' }) => {
    const route = useRoute();
    const routeName = getFocusedRouteNameFromRoute(route) || route?.name;
    return (
        <View accessibilityLabel="back-navigation" style={styles.navBarBackWrapper}>
            <TouchableOpacity style={styles.navBarBackButton} onPress={backPress}>
                <Entypo accessibilityLabel="back-navigation_icon" name="chevron-left" size={25} color={textColor} />
                <RText
                    style={[
                        styles.navbarTitle,
                        {
                            paddingLeft: 6,
                            color: textColor,
                            flex: 1,
                            width: '100%',
                        },
                    ]}
                    numberOfLines={1}
                    id={title || `screenTitles.${routeName}`}
                />
            </TouchableOpacity>
        </View>
    );
};
/**
 * AppHeader combonent.
 */

const disableBackHandler = [];

export const AppHeader = ({
    name,
    modal,
    root,
    right,
    children,
    title,
    backPress,
    textColor = 'white',
    noFlex = false,
}) => {
    const route = useRoute();
    const routeName = name || getFocusedRouteNameFromRoute(route) || route?.name;
    const forms = useSelector(getFormData);
    // const formTitle = formParams?.title ?? '';
    const formTitle = forms?.[routeName]?.title ?? '';
    const navigation = useNavigation();

    const backHandler = useCallback(
        (hardware = false) => {
            const r = routeName;
            const formParams = forms?.[routeName];
            const logName = forms?.[routeName]?.logName;
            const formBack = forms?.[routeName]?.back;

            DEBUG_BACK_HANDLER &&
                // eslint-disable-next-line no-console
                console.log(`AppHeader backHandler HW=${hardware} ${logName} ${r}`, {
                    formParams,
                    formBack,
                    backPress,
                    routeName,
                    hardware,
                    forms,
                    r,
                });
            let backRes = true;
            if (formBack) {
                backRes = formBack?.(hardware, r);
                if (backRes && backPress) {
                    // eslint-disable-next-line no-console
                    DEBUG_BACK_HANDLER && console.log('call backPress from formBack', !!hardware);
                    return backPress(hardware, !!r);
                }
            } else if (backPress) {
                // eslint-disable-next-line no-console
                DEBUG_BACK_HANDLER && console.log('call backPress', !!hardware);
                return backPress(hardware, r);
            }
            if (hardware && !formBack) {
                if (disableBackHandler.includes(r)) {
                    return true;
                }
                // eslint-disable-next-line no-console
                DEBUG_BACK_HANDLER && console.log('call navigation.goBack() hardware !fromBack');
                navigation.goBack();
            }
            return true;
        },
        [backPress, routeName, forms],
    );

    useEffect(() => {
        const logName = forms?.[routeName]?.logName;
        DEBUG_BACK_HANDLER &&
            !!logName &&
            // eslint-disable-next-line no-console
            console.log(`AppHeader useEffect hardware button subscribed ${logName}`);
        const hardwareListener = BackHandler.addEventListener('hardwareBackPress', () => {
            // eslint-disable-next-line no-console
            DEBUG_BACK_HANDLER && !!logName && console.log(`hardwareBackPress ${logName} ${routeName}`);
            return backHandler?.(true, routeName);
        });
        // }
        const gestureEndListener = navigation.addListener('gestureEnd', () => backHandler(true, routeName));
        return () => {
            hardwareListener?.remove?.();
            gestureEndListener?.remove && gestureEndListener.remove();
        };
    }, [forms, routeName]);

    return (
        <SafeAreaView style={[styles.safeArea]} forceInset={{ top: 'always' }}>
            <View
                accessibilityLabel={`appHeader:${routeName}`}
                style={[S.padding, S.flexRow__center, S.spaceBetween, modal ? styles.navbarModal : styles.navbar]}>
                {root && !backPress ? (
                    <RText
                        style={[styles.navbarTitle, { color: textColor }]}
                        id={formTitle || title || `screenTitles.${routeName}`}
                    />
                ) : (
                    <BackNav
                        title={formTitle || title}
                        textColor={textColor}
                        backPress={() => {
                            if (disableBackHandler.find(i => i === routeName)) {
                                return false;
                            }
                            if (backPress || forms?.[routeName]?.back) {
                                return backHandler(false, routeName);
                            }
                            navigation.goBack();
                            return false;
                            // return false;
                        }}
                    />
                )}
                {right ? <NavbarRight noFlex={noFlex}>{children}</NavbarRight> : children}
            </View>
        </SafeAreaView>
    );
};

export const MainHeader = ({ modal, children, right }) => {
    const route = useRoute();
    return (
        <SafeAreaView style={[styles.safeArea]} forceInset={{ top: 'always' }}>
            <View
                accessibilityLabel={`appHeader:${route?.name}`}
                style={[S.padding, S.flexRow__center, S.spaceBetween, modal ? styles.navbarModal : styles.navbar]}>
                {children}
                {Platform.OS === 'web' ? (
                    <Img.LogoBeta style={{ marginLeft: 4 }} />
                ) : (
                    <Icon.Logo style={{ marginLeft: 4 }} />
                )}
                <NavbarRight>{right}</NavbarRight>
            </View>
        </SafeAreaView>
    );
};
export const AppHeaderExt = ({ style, children }) => (
    <View
        style={[
            {
                paddingHorizontal: 14,
                backgroundColor: appHeaderBgColor,
                paddingBottom: 15,
            },
            style,
        ]}>
        {children}
    </View>
);
