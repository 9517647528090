import { useNavigation } from '@react-navigation/native';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    List, Page, React, Section,
} from '../../../common';
import { searchDrugs } from '../../../services/tabletka';
import { renderDrugItem } from '../snippets';
import actions from '../../../actions';
import { getIncludeAnalogs, getTabletkaLocation } from '../../../selectors';
import { AppHeader } from '../../../combo';
import { drugsAnaloguesFilter, FirstAidKitIcon } from '..';
import { useNavHistory } from '../../../utils/getRouteHistory';
import { setFormData } from '../../../actions/forms';

export const DrugsList = Page.register(
    ({
        location,
        // navigation,
        includeAnalogs,
        route: { params: { drugsList, keyword, from } },
    }) => {
        const h = useNavHistory();
        const navigation = useNavigation();
        const dispatch = useDispatch();
        const backHandler = useCallback(() => {
            if (h?.[1] === 'SearchDrugs') {
                navigation.replace('SearchDrugs', { keyword: '' });
                return false;
            }
            navigation.goBack();

            return true;
        }, [h, from]);

        useEffect(() => {
            dispatch(
                setFormData({
                    DrugsList: {
                        back: backHandler,
                        logName: 'DrugList',
                    },
                }),
            );
        }, []);

        // useBackHandler(backHandler);
        return (
            <Page name="drugs-list">
                <Section title="drugs" right={drugsAnaloguesFilter} flex>
                    <List
                        type="drugstores"
                        data={
                            drugsList ||
                            (() => searchDrugs({
                                keyword,
                                location,
                                includeAnalogs,
                            }))
                        }
                        renderItem={renderDrugItem(undefined, (item) => {
                            actions.addDrugInHistory({
                                ...item,
                                name: item.name.toUpperCase(),
                            });
                            navigation.navigate('DrugDetails', {
                                drug: item,
                                from: from ?? 'drugDetails',
                            });
                        })}
                    />
                </Section>
            </Page>
        );
    },
    {
        location: { from: getTabletkaLocation },
        includeAnalogs: { from: getIncludeAnalogs },
    },
    ({ navigation }) => ({
        header: () => (
            <AppHeader right navigation={navigation}>
                <FirstAidKitIcon />
            </AppHeader>
        ),
    }),
);
