export default {
    '5bbd02c57cf5cf2c1cc9bbc8': {
        title:
            'Использование услуги: Консультация аллерголога *** [Аллергология / Консультация специалиста]',
        providers: [],
        priceLists: [
            {
                title:
                    'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                _id: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                sourceFileId: '5bb346241966cb52609f3393',
                sourceFileName: 'Прейскурант - Элеос (01.08.2018).xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2018-08-01',
                groupCount: 26,
                priceCount: 798,
            },
            {
                title: 'Раздел 13. АЛЛЕРГОЛОГИЯ',
                _id: '5bedb84560fb744b843b108d',
                providerId: '5b3f5a696cadf395a',
                sourceFileId: '5bedb84560fb744b843b1068',
                sourceFileName: 'price_nordin_v2018-07-31_all.xlsx',
                sourceTabIndex: 3,
                sourceTabName: 'Аллер.',
                effFromDate: '2018-08-01',
                groupCount: 4,
                priceCount: 37,
            },
            {
                title: 'ЛДО',
                _id: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                sourceFileName: 'price_ecomed_v2018-08-23_ter.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2018-08-23',
                groupCount: 19,
                priceCount: 160,
            },
            {
                title:
                    'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                _id: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                sourceFileName: 'price_eleos_v2019-01-29.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2019-01-29',
                groupCount: 31,
                priceCount: 972,
            },
            {
                title: 'Прием врачей-специалистов',
                _id: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                sourceFileName: 'price_lode_minsk_v2019-03-29_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 71,
            },
            {
                title: 'Врачи-специалисты',
                _id: '5ca4e2300a295f74506435fa',
                providerId: '5b3f5cf17c5d77cc4',
                sourceFileId: '5ca4e2300a295f74506435f6',
                sourceFileName: 'price_lode_grodno_v2019-04-03_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 26,
            },
            {
                title: 'Прием врачей-специалистов',
                _id: '5ca6074f07ccff53f01362ba',
                providerId: '5b3f5ac1f2075180c',
                sourceFileId: '5ca6074f07ccff53f013626e',
                sourceFileName: 'price_lode_brest_v2019-04-04_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 16,
            },
            {
                title: 'Прием врачей-специалистов (иностр.)',
                _id: '5ca6074f07ccff53f01362bc',
                providerId: '5b3f5ac1f2075180c',
                sourceFileId: '5ca6074f07ccff53f013626e',
                sourceFileName: 'price_lode_brest_v2019-04-04_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 16,
            },
        ],
        files: [],
        groups: [
            {
                codeDepth: -1,
                nameAsIs: 'Услуги',
                _id: '5bb346241966cb52609f3395',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs:
                    'Оказание медицинской помощи в отделении круглосуточного и дневного пребывания',
                _id: '5bb346241966cb52609f3396',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
        ],
        items: [
            {
                codeDepth: 1,
                codeAsIs: '2.25',
                nameAsIs:
                    'Консультация врача-аллерголога, кандидата медицинских наук',
                _id: '5bb346261966cb52609f33cf',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.26',
                nameAsIs:
                    'Повторная консультация врача-аллерголога, кандидата медицинских наук',
                _id: '5bb346261966cb52609f33d0',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
        ],
        depItems: [],
        mapping: [
            {
                _id: '5bbf53b7213a0475077ba4c4',
                _updated: 1554387211614,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33cf',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bbf55e8213a0475077ba50a',
                _updated: 1546591744691,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33d0',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c9e3388fbd3f4793026ac6d',
                _updated: 1546591777724,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b950501933',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                    '5bbd02c57cf5cf2c1cc9bc29',
                ],
            },
            {
                _id: '5c9e338dfbd3f4793026b524',
                _updated: 1546591773408,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b950501934',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc2b',
                    '5bbd02c57cf5cf2c1cc9bc25',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5ca4e2850a295f836cd6fa2a',
                _updated: 1551892942187,
                priceListId: '5ca4e2300a295f74506435fa',
                providerId: '5b3f5cf17c5d77cc4',
                branchIds: ['5b3f5cf17c5d77cc4', '5b3f5fa4529813245'],
                priceItemId: '5ca4e2320a295f7450643670',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                ],
                coveragePct: 100,
            },
            {
                _id: '5ca6078807ccff4a58d52df5',
                _updated: 1551981438803,
                priceListId: '5ca6074f07ccff53f01362ba',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceItemId: '5ca6075207ccff53f01366fb',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
        ],
    },
    '5bcf3c70213a045efd0cddab': {
        title: 'Использование услуги: Беруши [Рентген, КТ, МРТ / Материалы]',
        providers: [],
        priceLists: [
            {
                title: 'Магнитно-резонансная томография (МРТ)',
                _id: '5c9e335cfbd3f4b9505011a2',
                providerId: '5b3f5fa4529813245',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                sourceFileName: 'price_lode_minsk_v2019-03-29_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 82,
            },
        ],
        files: [
            {
                _id: '5c9e335cfbd3f4b95050117e',
                filename: 'Лодэ-Минск (2019-03-29).xlsx',
                uploadDate: 1553871710665,
                length: 322226,
                md5: '12e2cd45593b51322a0289118854eaa6',
                meta: {
                    providerId: '5b3f5fa4529813245',
                    sourceFileName: 'price_lode_minsk_v2019-03-29_all.xlsx',
                    priceLists: [],
                },
            },
        ],
        groups: [
            {
                codeDepth: -1,
                nameAsIs: 'Магнитно-резонансная томография (МРТ)',
                _id: '5c9e335cfbd3f4b9505011a3',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                providerId: '5b3f5fa4529813245',
                priceListId: '5c9e335cfbd3f4b9505011a2',
            },
        ],
        items: [
            {
                codeDepth: 1,
                codeAsIs: '43.6м',
                nameAsIs: 'беруши, пара',
                _id: '5c9e335ffbd3f4b950501668',
                providerId: '5b3f5fa4529813245',
                branchIds: ['5b3f5fa4529813245'],
                priceListId: '5c9e335cfbd3f4b9505011a2',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011a3',
                groupName: 'Магнитно-резонансная томография (МРТ)',
                complex: false,
                hasExtDeps: false,
            },
        ],
        depItems: [],
        mapping: [
            {
                _id: '5c9e338dfbd3f4793026b507',
                _updated: 1548859510587,
                priceListId: '5c9e335cfbd3f4b9505011a2',
                providerId: '5b3f5fa4529813245',
                branchIds: ['5b3f5fa4529813245'],
                priceItemId: '5c9e335ffbd3f4b950501668',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bcf3c70213a045efd0cddab',
                specOptionIds: ['5c500b9bce00ee33424e0665'],
            },
        ],
    },
    '5bbd02c57cf5cf2c1cc9bbcb': {
        title: 'Использование услуги: Консультация гинеколога [Гинекология / Консультация специалиста]',
        providers: [],
        priceLists: [],
        files: [],
        groups: [],
        depItems: [],
        mapping: [{
            _id: '5d68d5f70bc320a228a9a00a',
            _updated: 1546592289236,
            priceListId: '5d68d5ec0bc320a228a98e9f',
            providerId: '5b3f5fa4529813245',
            branchIds: ['5b3f5fa4529813245', '5b3f5faa72b310212'],
            priceItemId: '5d68d5ee0bc320a228a99009',
            specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
            specItemId: '5bbd02c57cf5cf2c1cc9bbcb',
            specOptionIds: ['5bbd02c57cf5cf2c1cc9bc21', '5c2f1157ce00ee33424563fa'],
            coveragePct: 100.0,
        }, {
            _id: '5d68d5fd0bc320a228a9a97e',
            _updated: 1546592438728,
            priceListId: '5d68d5ec0bc320a228a98e9f',
            providerId: '5b3f5fa4529813245',
            branchIds: ['5b3f5fa4529813245', '5b3f5faa72b310212'],
            priceItemId: '5d68d5ee0bc320a228a9900a',
            specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
            specItemId: '5bbd02c57cf5cf2c1cc9bbcb',
            specOptionIds: ['5bbd02c57cf5cf2c1cc9bc22', '5c2f1157ce00ee33424563fa'],
            coveragePct: 100.0,
        }],
    },
    '5bbd02c57cf5cf2c1cc9bae6': {
        title: 'Использование услуги: Пунктурная гирудотерапия [Физиотерапия / Гирудотерапия]',
        providers: [],
        priceLists: [],
        files: [],
        groups: [],
        depItems: [],
        mapping: [{
            _id: '5c9e338dfbd3f4793026b507',
            _updated: 1548859510587,
            priceListId: '5c9e335cfbd3f4b9505011a2',
            providerId: '5b3f5fa4529813245',
            branchIds: ['5b3f5fa4529813245'],
            priceItemId: '5c9e335ffbd3f4b950501668',
            specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
            specItemId: '5bcf3c70213a045efd0cddab',
            specOptionIds: ['5c500b9bce00ee33424e0665'],
            coveragePct: 100.0,
        }],
    },
};