/* eslint-disable max-statements */
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { React } from '../../../common';
import {
    getActualFeedbacks,
    getClinics,
    getCurrentDoctorFeedbacks,
    getDisableUnconfirmedFeedback,
    getDisableUnconfirmedFeedbackOnlineOnly,
    getPastVisits,
    getStatusesOfHappenedVisits,
    getStatusesOfHappenedVisitsDoctor,
    getUser,
    historyVisits,
} from '../../../selectors';
import { isAdult, isEmpty } from '../../../utils';
import { setFeedbackData } from '../../../actions/feedbacks';
import actions from '../../../actions';
import { mergeFeedbacksToDoctor } from '../../../selectors/utils';
import { useReduxStoreCollection } from './DoctorFeedbacks';

const getEmptyData = () => ({
    doctorId: '',
    doctorInfo: null,
    hasVisits: false,
    doctorHaveOffline: false,
    isAdult: false,
    isAuthenticated: false,
    profileId: '',
    fullName: '',
    feedbacks: [
        {
            _id: '',
            feedbackText: '',
            anonymous: undefined,
            vote: undefined,
            forItemId: '',
        },
    ],
    hasMyFeedbacks: false,
    textFeedbackCount: null,
    neutralCount: null,
    recommendCount: null,
    notRecommendCount: null,
    recommendationIndex: null,
    feedbacksCount: null,
    isLoading: false,
    error: null,
    completed: [],
    visits: [],
});

export const isHaveOfflineMedcenters = (doctorInfo, medcenters = {}) => doctorInfo?.worksAt?.some((item) => {
    if (isEmpty(medcenters)) {
        return item.onlineBookingAllowed !== true;
    }
    const mc = medcenters[item.clinicId || item?.id];
    const isOnline = mc?.onlineConnectivity === true || mc?.hidden === true;
    return !isOnline;
});
export const isMe = (id, info) => info?.me?.profileId === id;
export const isChild = (id, info) => (info?.relatives ?? []).find(({ profileId: pid }) => pid === id)?.isChild;

export const getHasVisits = (id, visits = [], stats = [], info) => visits?.some(
    item => stats.includes(item?.status) &&
            item.physicianId === id &&
            (isMe(item?.profileId, info) || isChild(item?.profileId, info)),
);

// eslint-disable-next-line max-statements
export const DoctorFeedbackContainer = ({
    doctorId,
    doctorPage = true,
    children,
    fetchFeedbacks = true,
    doctorInfo: currentDoctor,
}) => {
    const clinics = useSelector(getClinics);
    const pastsVisits = useSelector(getPastVisits);
    const feedbacksInfo = useSelector(getCurrentDoctorFeedbacks);
    const doctor = useRef();
    const { isAuthenticated, info, isLoading } = useSelector(getUser);
    const completeData = useRef(getEmptyData());
    const statuses = {
        true: useSelector(getStatusesOfHappenedVisitsDoctor),
        false: useSelector(getStatusesOfHappenedVisits),
    };
    // const statuses = useSelector(doctorPage ? getStatusesOfHappenedVisitsDoctor : getStatusesOfHappenedVisits);
    const dispatch = useDispatch();
    const disableUnconfirmed = useSelector(getDisableUnconfirmedFeedback);
    const disableUnconfirmedOnline = useSelector(getDisableUnconfirmedFeedbackOnlineOnly);
    const { feedbacks, feedbacksSums } = useSelector(getActualFeedbacks);

    const history = useSelector(historyVisits);
    const { visits, profileId } = history;

    const isCanGiveFeedback = () => {
        if (!isAuthenticated || !info?.birthday || !isAdult(info?.birthday)) {
            return false;
        }
        const hasVisit = getHasVisits(doctorId, pastsVisits, statuses[doctorPage], info);
        const hasFeedback = doctorId && completeData.current?.completed.includes(doctorId);
        if (disableUnconfirmedOnline) {
            const hasOffline = isEmpty(currentDoctor) ? false : isHaveOfflineMedcenters(currentDoctor, clinics);
            return hasOffline || hasVisit || hasFeedback;
        }
        if (disableUnconfirmed) {
            return hasVisit || hasFeedback;
        }
        return true;
    };

    useReduxStoreCollection(profileId, visits);
    useEffect(() => {
        // if (doctors) {
        //     doctor.current = doctors?.find(({ id }) => id === doctorId);
        // }
        doctor.current = feedbacksInfo ? mergeFeedbacksToDoctor(currentDoctor, feedbacksInfo) : currentDoctor;
        const physicians = (feedbacks ?? []).physicians?.filter(({ forItemId }) => forItemId === doctorId);

        const fb = (feedbacks ?? []).physicians
            ?.filter(
                ({ userProfileId, forItemId }) => userProfileId && userProfileId === info?.userId && forItemId === doctorId,
            )
            .map(
                ({
                    _id,
                    feedbackText,
                    anonymous,
                    vote,
                    status,
                    createdAt,
                    updatedAt,
                    userName,
                    userCoverers,
                    usefulCount,
                    usefulForMe,
                }) => ({
                    _id,
                    feedbackText,
                    anonymous,
                    vote,
                    status,
                    createdAt,
                    updatedAt,
                    userName,
                    forItemId: doctorId,
                    userCoverers,
                    usefulCount,
                    usefulForMe,
                }),
            );

        const data = {
            doctorId,
            myFeedbacks: fb?.length
                ? fb
                : [
                    {
                        _id: '',
                        feedbackText: '',
                        anonymous: false,
                        vote: -2,
                        status: '',
                        createdAt: new Date(),
                        updatedAt: new Date(),
                        userName: '',
                        forItemId: doctorId,
                        userCoverers: [],
                    },
                ],
            hasMyFeedbacks: !!fb?.length,
            feedbacks: physicians,
            doctorHaveOffline: isEmpty(doctor.current) ? false : isHaveOfflineMedcenters(doctor.current, clinics),
            textFeedbackCount: doctor.current?.textFeedbackCount,
            recommendationIndex: doctor.current?.textFeedbackCount,
            feedbacksCount: doctor.current?.feedbacksCount,
            recommendCount: doctor.current?.recommendCount,
            notRecommendCount: doctor.current?.notRecommendCount,
            neutralCount: doctor.current?.neutralCount,
            fullName: doctor.current?.fullName,
            isAuthenticated,
            isAdult: isAdult(info?.birthday),
            hasVisits: getHasVisits(doctorId, pastsVisits, statuses[doctorPage], info),
            isLoading: !!feedbacks?.isLoading || !!history?.isLoading || !!isLoading,
            doctor: doctor.current,
            canGiveFeedback: isCanGiveFeedback(),
            error: feedbacks?.error,
            completed: feedbacks?.completedphysicians ?? [],
            visits: (visits ?? [])
                .filter(item => item.physicianId === doctorId && doctorId)
                .map(item => ({
                    ...item,
                    isMe: isMe(item?.profileId),
                    isChild: isChild(item?.profileId),
                })),
        };
        if (JSON.stringify(data) !== JSON.stringify(completeData.current)) {
            completeData.current = data;
            dispatch(setFeedbackData(data));
        }
    }, [doctorId, isAuthenticated, info, currentDoctor, pastsVisits, feedbacks.physicians, clinics, doctorPage]);
    // }, [doctorId, isAuthenticated, info, currentDoctor, pastsVisits, statuses, feedbacks.physicians, clinics, doctorPage, feedbacksInfo, completeData]);

    const needFetch = () => {
        return Object.entries(feedbacksSums).some(([k, v]) => {
            return completeData.current[k] !== v;
        });
    };

    useEffect(() => {
        if (fetchFeedbacks) {
            console.log('fetch from container', need);
            const need = needFetch();
            need && actions.fetchFeedbacks(doctorId, 'physicians');
        }
    }, [
        feedbacksSums.feedbacksCount,
        feedbacksSums.textFeedbackCount,
        feedbacksSums.recommendCount,
        feedbacksSums.notRecommendCount,
        feedbacksSums.neutralCount,
        fetchFeedbacks,
    ]);

    return doctorId ? <>{children}</> : null;
};

export const Logger = ({ text, data }) => {
    const getLog = () => {
        // eslint-disable-next-line no-console
        console.log(text, data);
        return null;
    };
    return getLog();
};
