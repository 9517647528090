/* eslint-disable camelcase */
import Store, { getFromStoreDb, putIntoStore, SCHEME } from '../../store';
import { navigate } from '../../actions';
import userService from '../../services/user';
import { formatDate, urlStringify } from '../../utils';
import { shareItemNew } from '../../services/device';
import tracking from '../../services/tracking';

const {
    VISITS_PREFILTER, VISITS_FILTER, TESTS_PREFILTER, TESTS_FILTER,
} = SCHEME;

export const goToVisitsFilter = async (c, d, v, s) => {
    const {
        visitors = [],
        byAttachments = false,
        byReports = false,
        clinics = [],
        doctors = [],
        speciality = [],
    } = getFromStoreDb(VISITS_FILTER) || {};
    putIntoStore(VISITS_PREFILTER, {
        visitors: v.map(e => ({
            id: e.id,
            name: e.name,
            selected: visitors.some(id => id === e.id),
        })),
        clinics: c.map(e => ({
            ...e,
            selected: clinics.some(id => id === e.id),
        })),
        doctors: d.map(e => ({
            ...e,
            selected: doctors.some(id => id === e.id),
        })),
        speciality: s.map(e => ({
            ...e,
            selected: speciality.some(id => id === e.id),
        })),
        byAttachments,
        byReports,
    });
    navigate('VisitsFilter');
};

export const goToTestsFilter = async (c, s, v) => {
    const {
        visitors: _visitors = [],
        clinics: _clinics = [],
        statuses: _statuses = [],
    } = getFromStoreDb(TESTS_FILTER) || {};
    const { me, relatives } = await userService.getUserInfo();
    const persons = [
        {
            id: me.profileId,
            name: Object.R('titles.for_me'),
        },
        ...relatives.map(({ profileId, name }) => ({
            id: profileId,
            name: name?.split?.(' ')?.[1] ?? name ? name : '',
        })),
    ];

    const visitors = v.map(e => ({
        id: e.id,
        name: persons.find(p => p.id === e.id)?.name,
        selected: _visitors.some(id => id === e.id),
    }));
    const clinics = c.map(e => ({
        ...e,
        selected: _clinics.some(id => id === e.id),
    }));
    const statuses = s.map(e => ({
        ...e,
        selected: _statuses.some(id => id === e.id),
    }));
    putIntoStore(TESTS_PREFILTER, {
        // visitors: persons.map(e => ({ id: e.id, name: e.name, selected: visitors.some(id => id === e.id) })),
        visitors,
        clinics,
        statuses,
    });
    navigate('TestsFilter');
};

export const shareVisit = (visit) => {
    const ve = Store?.getState()?.visitEdit;
    if (ve?.id === visit?.id) {
        visit = ve;
    }

    const url = urlStringify({ target: Object.R('shareDoctor.sourceLink') });
    const store = Store.getState()?.user?.info;
    const getVisitor = () => {
        if (visit?.visitor?.isMe) {
            return `${visit?.visitor?.subName} (${formatDate(store?.birthday) ?? ''})`;
        }
        return visit?.visitor?.name
            ? `${visit?.visitor?.name} (${formatDate(visit?.visitor?.birthday ?? '-')})`
            : visit?.customerFirstName;
    };

    const getDrugs = () => {
        // eslint-disable-next-line camelcase
        return (
            visit?.drugs?.map(({ ls_name = '', tar_name = '' }) => `${ls_name} ${tar_name ? `(${tar_name})` : ''}`.trim()) ?? []
        ).join(', ');
    };

    const messageObject = {
        'shareDoctor.date': visit?.visitItemDate ?? '',
        'shareDoctor.service': visit?.serviceName ?? '',
        'shareDoctor.doctor': `${visit?.physicianName ?? ''}${
            visit?.specialization ? ` (${visit?.specialization})` : ''
        }`,
        'shareDoctor.clinic': visit?.branchName
            ? `${visit?.branchName}${visit?.address ? ` (${visit?.address})` : ''}`
            : '',
        'shareDoctor.profile': getVisitor(),
        'shareDoctor.comment': visit?.comment ?? '',
        'shareDoctor.drugs': getDrugs(),
        'shareDoctor.source': url,
    };
    const message = Object.entries(messageObject)
        .filter(([key, value]) => value?.trim?.()?.length && Object.R(key) !== key && Object.R(key)?.trim?.()?.length)
        .map(([key, value]) => `${Object.R(key)} ${value}`)
        .join(
            Object.R('shareDoctor.separator', { lineFeed: '\n' }) !== 'shareDoctor.separator'
                ? Object.R('shareDoctor.separator', { lineFeed: '\n' })
                : ', ',
        );
    shareItemNew(Object.R('titles.shareDoctorTitle'), message, () => {
        tracking.logEvent('shareVisit');
    });
};
