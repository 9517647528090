/* eslint-disable no-use-before-define */
import { useState } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    FlatList,
    Icon,
    ListItem,
    Modal,
    Page,
    Prompt,
    React,
    S,
    showNotification,
    Subtitle,
    Text,
    Title,
    TouchableOpacity,
    VBox,
    View,
    withState,
} from '../../../common';
import { capitalize, formatDate, trimExtraSpaces } from '../../../utils';
import { listItem as styles } from '../style';
import { modal as modalStyles } from '../../../styles/common';
import actions, { navigate } from '../../../actions';
import { getUserInfo } from '../../../selectors';
import { autoGenUserName } from '../../Account/utils';
import { getShortUserNameWithChecks } from '../../../utils/users';

const menuItems = feedback => [
    {
        id: 'edit',
        handler: () => navigate('EditFeedback', {
            feedback,
            isEditFeedback: true,
        }),
    },
    {
        id: 'delete',
        handler: () => {
            deleteModal(feedback, 'clinics');
        },
    },
].filter(Boolean);

const menuItemsDoctors = feedback => [
    {
        id: 'edit',
        handler: () => {
            navigate('EditDoctorFeedback', {
                feedback,
                isEditFeedback: true,
                doctorId: feedback?.forItemId,
            });
        },
    },
    {
        id: 'delete',
        handler: () => {
            deleteModal(feedback, 'physicians');
        },
    },
].filter(Boolean);

const deleteModal = (feedback, kind) => Page.showModal((
    <Prompt
        title="titles.delete_review"
        cancelTitle="buttons.no"
        submitOptions={{ danger: false }}
        onSubmit={async () => {
            try {
                await actions.deleteFeedback(feedback, kind, true);
                // await actions.deleteFeedback(feedback, kind, kind !== 'physicians');
                // .then(() => actions.fetchCompleted(kind));
                // await actions.fetchVotes(kind);
                showNotification('titles.comment_has_been_deleted');
            } catch {
                //
            }
        }}
    >
        <Text numberOfLines={2}>{feedback.feedbackText}</Text>
    </Prompt>
));

export const showMenu = (feedback, needEdit = true, forDoctors) => Page.showModal((
    <Modal
        title="titles.comments"
        titleParams={{ date: formatDate(feedback.updatedAt) }}
    >
        <Subtitle numberOfLines={1} style={styles.feedbackSubtitle}>{feedback.feedbackText || ''}</Subtitle>
        <FlatList
            data={
                // eslint-disable-next-line no-nested-ternary
                needEdit
                    ? (forDoctors ? menuItemsDoctors(feedback) : menuItems(feedback))
                    : (forDoctors ? menuItemsDoctors(feedback)
                        .filter(menuItem => menuItem.id !== 'edit') : menuItems(feedback)
                        .filter(menuItem => menuItem.id !== 'edit'))}
            renderItem={({
                item: {
                    id,
                    handler,
                },
            }) => (
                <Button
                    title={capitalize(Object.R(`buttons.${id}`)
                        .toLowerCase())}
                    styles={[modalStyles.modalButton, { paddingRight: 20 }]}
                    titleStyles={[modalStyles.selectModalTitle]}
                    action={() => Page.closeModal(handler)}
                />
            )}
        />
    </Modal>
));

const MenuButton = ({ pressHandler }) => (
    <View style={[S.absolute_right, styles.iconContainer]}>
        <Icon.Menu onPress={pressHandler}/>
    </View>
);

const ResponseToFeedback = ({
    from,
    createdAt,
    updatedAt,
    text,
    date = formatDate(updatedAt || createdAt),
}) => {
    const [isFull, setIsFull] = useState(false);
    const onPress = () => setIsFull(!isFull);
    return (
        <TouchableOpacity style={styles.responseContainer} onPress={onPress}>
            <View style={styles.responseHeaderContainer}>
                <Subtitle numberOfLines={1} style={styles.responseUserNameText}>{from}</Subtitle>
                <Subtitle style={styles.responseDateText}>{date}</Subtitle>
            </View>
            <Subtitle numberOfLines={isFull ? 0 : 3} style={styles.responseText}>{text}</Subtitle>
        </TouchableOpacity>
    );
};

export const ResponseToFeedbackList = ({ data }) => data.map(e => <ResponseToFeedback {...e} />);

export const FeedbacksListItemTMPL = withState(
    ({
        data,
        user,
        isExpand,
        handlePress,
        isMyFeedback = user.userId && data.userProfileId === user.userId,
        name = data.userName || data.authorName || (data.userProfileId ? autoGenUserName(data.userProfileId) : Object.R(`feedback.anonumous`)),
        text = data.feedbackText || data.text || '',
        // rate = data.rate ?? 0,
        date = formatDate(data.createdAt),
        responses = data.responses,
        lastItem = false,
    }) => {
        let author = Object.R(`feedback.anonumous`);
        if (!data.anonymous) {
            const nameParts = trimExtraSpaces(name)
                .split(' ');
            if ((data.userName || data.authorName) && nameParts.length > 1) {
                // In russian notation, first name goes before last name
                author = getShortUserNameWithChecks(name);
                // author = `${nameParts[1]} ${nameParts[0][0]}.`;
            } else {
                author = name;
            }
        }
        return (
            <ListItem
                key={Math.random()}
                onPress={() => handlePress(!isExpand)}
                Component={() => (
                    <View style={[styles.feedbackContainer, lastItem ? { borderBottomWidth: 0 } : {}]}>
                        <VBox gap={0.7} ns="list-data">
                            <View style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                paddingRight: 12,
                            }}>
                                {author ? (<Title.Bigger numberOfLines={2}>{author}</Title.Bigger>) : null}
                                <Subtitle.Bigger style={{
                                    color: '#A0A0A0',
                                    marginRight: 20,
                                }}>{date}</Subtitle.Bigger>
                            </View>
                            <TouchableOpacity onPress={() => handlePress(!isExpand)}>
                                <Subtitle.Bigger numberOfLines={!isExpand ? 3 : null}>{text}</Subtitle.Bigger>
                            </TouchableOpacity>
                        </VBox>
                        {isMyFeedback ? <MenuButton pressHandler={() => showMenu(data, date)}/> : null}
                        {responses?.length ? <ResponseToFeedbackList data={responses}/> : null}
                    </View>
                )}
            />
        );
    },
    ({
        isExpand = false,
        setState,
        handlePress = v => setState({ isExpand: v }),
    }) => ({
        isExpand,
        handlePress,
        setState,
    }),
);

export const FeedbacksListItem = connect(store => ({ user: getUserInfo(store) }))(FeedbacksListItemTMPL);
