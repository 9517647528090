export const autoGenUserName = userId => userId && `${Object.R('titles.user')} ${1000000000 % userId.split('')
    .reduce((acc, c) => Number(acc) + Number(c.charCodeAt(0)), 0)}`;
export const getRelativesLimitsErrorTitle = ({
    spouseError,
    parentError,
    relKind,
}) => {
    if (relKind === 'SPOUSE' && spouseError) {
        return 'titles.notAllowedAnotherSpouse';
    }
    if (relKind === 'PARENT' && parentError) {
        return 'titles.notAllowedAnotherParent';
    }
    // eslint-disable-next-line consistent-return
    return undefined;
};
