export default {
    '5b4c5eb620ae653c1': {
        _id: '5b4c5eb620ae653c1',
        _lastModifiedAt: 1533890879000,
        _lastModifiedBy: null,
        address: 'Минск, ул. Ф. Скорины, д. 11',
        contacts: '+375 (17) 269-25-33;Velcom +375 (29) 397-03-67;МТС +375 (29) 777-24-42;Life +375 (25) 501-01-01',
        deleted: false,
        doctorsCounter: 3,
        email: '',
        areaCode: 17,
        externalId: null,
        homePage: null,
        name: 'РЦ "Элеос"',
        overview: '',
        pathWay: null,
        phone: '+375 (17) 269-25-33;Velcom +375 (29) 397-03-67;МТС +375 (29) 777-24-42;Life +375 (25) 501-01-01',
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/clinic-images%2F%2F%D0%A0%D0%A6%20%22%D0%AD%D0%BB%D0%B5%D0%BE%D1%81%22%205b4c5eb620ae653c1?generation=1539797561427414&alt=media',
        schedule: 'Пн. - пт.: 08:00 - 20:00, Сб.: 09:00 - 16:00. Воскресенье - выходной.',
        _coll: 'clinics',
        onlineConnectivity: true,
    },
    '5b3f5fa4529813245': {
        _id: '5b3f5fa4529813245',
        _lastModifiedAt: 1533890958000,
        _lastModifiedBy: null,
        address: 'Минск, пр. Независимости, д. 58',
        contacts: '111 - единый номер;+375 (29) 638-30-03;+375 (29) 270-10-03;+375 (17) 293-98-00',
        deleted: false,
        doctorsCounter: 24,
        email: '',
        areaCode: 17,
        externalId: '4782',
        homePage: null,
        name: 'Лодэ на Независимости',
        onlineBookingAllowed: true,
        overview: '',
        pathWay: null,
        phone: '111 - единый номер;+375 (29) 638-30-03;+375 (29) 270-10-03;+375 (17) 293-98-00',
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/clinic-images%2F%2F%D0%9B%D0%BE%D0%B4%D1%8D%20%D0%BD%D0%B0%20%D0%9D%D0%B5%D0%B7%D0%B0%D0%B2%D0%B8%D1%81%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D0%B8%205b3f5fa4529813245?generation=1539797560670674&alt=media',
        schedule: 'Пн.-Пт.: 7:30 - 21:00. Cб.: 8:00 - 20:00. Bс.: 9:00 - 16:00.',
        _coll: 'clinics',
        onlineConnectivity: true,
    },
    '5b4c5e876e354dbfb': {
        _id: '5b4c5e876e354dbfb',
        _lastModifiedAt: 1536313556000,
        _lastModifiedBy: null,
        address: 'Минск, ул. Макаенка, д. 17',
        contacts: 'Cправка: +375 (17) 267-65-10;Предварительная запись для физических лиц:  +375 (17) 266-27-70;VELCOM: +375 (29) 686-27-70;МТС: +375 (29) 249-97-70',
        deleted: false,
        doctorsCounter: 1,
        email: 'center@makaenka17med.by',
        areaCode: 17,
        externalId: null,
        homePage: null,
        name: 'РЦМРиБ',
        overview: 'Республиканский центр медицинской реабилитации и бальнеолечения',
        pathWay: null,
        phone: 'Cправка: +375 (17) 267-65-10;Предварительная запись для физических лиц:  +375 (17) 266-27-70;VELCOM: +375 (29) 686-27-70;МТС: +375 (29) 249-97-70',
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/clinic-images%2F%2F%D0%A0%D0%A6%D0%9C%D0%A0%D0%B8%D0%91%205b4c5e876e354dbfb?generation=1539797564702487&alt=media',
        schedule: 'Пн. - пт.: 08:00 - 21:00;  Сб.: 08:00 -14:30. Воскресенье - выходной.',
        _coll: 'clinics',
        onlineConnectivity: true,
    },
    '5b4c5eceaf981f5f0': {
        _id: '5b4c5eceaf981f5f0',
        _lastModifiedAt: 1536923096000,
        _lastModifiedBy: null,
        address: 'Минск, ул. Никифорова, д. 3',
        contacts: 'Справка: +375 (17) 265-98-00;Приемная (тел./факс): +375 (17) 264-22-92;Регистратура стоматологии: +375 (17) 264-38-36;Вызов врача на дом: +375 (17) 285-82-62;Телефон справочной информационной службы: 131 (звонок бесплатный).',
        deleted: false,
        doctorsCounter: 0,
        email: 'lpu8gp@mail.belpak.by',
        areaCode: 17,
        externalId: '',
        homePage: null,
        name: 'УЗ "8 городская поликлиника"',
        overview: '',
        pathWay: null,
        phone: 'Справка: +375 (17) 265-98-00;Приемная (тел./факс): +375 (17) 264-22-92;Регистратура стоматологии: +375 (17) 264-38-36;Вызов врача на дом: +375 (17) 285-82-62;Телефон справочной информационной службы: 131 (звонок бесплатный).',
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/clinic-images%2F%2F%D0%A3%D0%97%20%228%20%D0%B3%D0%BE%D1%80%D0%BE%D0%B4%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BF%D0%BE%D0%BB%D0%B8%D0%BA%D0%BB%D0%B8%D0%BD%D0%B8%D0%BA%D0%B0%22%205b4c5eceaf981f5f0?generation=1539797562214131&alt=media',
        schedule: 'Ежедневно: 7:00 - 20:00',
        _coll: 'clinics',
        onlineConnectivity: true,
    },
    '5b3f5faa72b310212': {
        _id: '5b3f5faa72b310212',
        _lastModifiedAt: 1537252773000,
        _lastModifiedBy: null,
        address: 'Минск, ул. Притыцкого, д. 140',
        contacts: '111 - единый номер;+375 (29) 638-30-03;+375 (29) 270-10-03;+375 (17) 293-98-00 (Звонить с 9:00 до 21:00)',
        deleted: false,
        doctorsCounter: 12,
        email: '',
        areaCode: 17,
        externalId: '3528',
        homePage: null,
        name: 'Лодэ на Притыцкого',
        overview: '',
        onlineBookingAllowed: true,
        pathWay: null,
        phone: '111 - единый номер;+375 (29) 638-30-03;+375 (29) 270-10-03;+375 (17) 293-98-00 (Звонить с 9:00 до 21:00)',
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/clinic-images%2F%2F%D0%9B%D0%BE%D0%B4%D1%8D%20%D0%BD%D0%B0%20%D0%9F%D1%80%D0%B8%D1%82%D1%8B%D1%86%D0%BA%D0%BE%D0%B3%D0%BE%205b3f5faa72b310212?generation=1539797562984150&alt=media',
        schedule: 'Пн.-Пт.: 7:30 - 21:00. Cб.: 8:00 - 21:00. Bс.: 9:00 - 16:00.',
        _coll: 'clinics',
        onlineConnectivity: true,
    },
    '5b3f5fa7eaf998e58': {
        _id: '5b3f5fa7eaf998e58',
        _lastModifiedAt: 1537252793000,
        _lastModifiedBy: null,
        address: 'Минск, ул. Гикало, д. 1',
        contacts: '111 - единый номер;+375 (29) 638-30-03;+375 (29) 270-10-03;+375 (17) 293-98-00',
        deleted: false,
        doctorsCounter: 14,
        email: '',
        areaCode: 17,
        externalId: '2562',
        homePage: null,
        name: 'Лодэ на Гикало',
        overview: '',
        pathWay: null,
        phone: '111 - единый номер;+375 (29) 638-30-03;+375 (29) 270-10-03;+375 (17) 293-98-00',
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/clinic-images%2F%2F%D0%9B%D0%BE%D0%B4%D1%8D%20%D0%BD%D0%B0%20%D0%93%D0%B8%D0%BA%D0%B0%D0%BB%D0%BE%205b3f5fa7eaf998e58?generation=1539797563808489&alt=media',
        schedule: 'Пн.-Пт.: 7:30 - 21:00. Cб.: 8:00 - 21:00. Bс.: 9:00 - 16:00.',
        _coll: 'clinics',
        onlineConnectivity: true,
    },
    '5b46096c302c5a36f': {
        _id: '5b46096c302c5a36f',
        _lastModifiedAt: 1538999071000,
        _lastModifiedBy: null,
        address: 'Минск, ул. Кисилева, д. 12',
        contacts: '+375 (17) 288-11-41;+375 (17) 288-14-05;+375 (29) 116-85-00',
        deleted: false,
        doctorsCounter: 14,
        email: 'horizont-med@tut.by',
        areaCode: 17,
        externalId: '',
        homePage: null,
        name: 'МЦ "Горизонт"',
        overview: '',
        pathWay: null,
        phone: '+375 (17) 288-11-41;+375 (17) 288-14-05;+375 (29) 116-85-00',
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/clinic-images%2F%2F%D0%9C%D0%A6%20%22%D0%93%D0%BE%D1%80%D0%B8%D0%B7%D0%BE%D0%BD%D1%82%22%205b46096c302c5a36f?generation=1539000138993817&alt=media',
        schedule: 'Пн. - пт.: 08:00 - 20:00, Сб.: 09:00 - 15:00. Воскресенье - выходной.',
        _coll: 'clinics',
        onlineConnectivity: true,
    },
    '5b3f5ac1f2075180c': {
        schedule: null,
        name: 'МЦ "ЛОДЭ" на Пионерской EPAM',
        photoUrl: null,
        contacts: null,
        email: null,
        address: 'Брест, ул. Пионерская 50',
        overview: null,
        timezoneName: 'Europe/Minsk',
        homePage: null,
        _id: '5b3f5ac1f2075180c',
        phone: null,
        pathWay: null,
        _lastModifiedAt: 1553061869000,
        deleted: false,
        doctorsCounter: 0,
        _lastModifiedBy: null,
        areaCode: 162,
        _coll: 'clinics',
        id: '5b3f5ac1f2075180c',
        services: [],
        emails: [],
        phones: [],
        feedbacksCount: 0,
        workTime: 'Нет расписания',
        timezone: 180,
    },
    '5bfbf3ffeee022cd3': {
        schedule: '',
        name: 'МЦ "ЛОДЭ" на Полиграфистов EPAM',
        photoUrl: null,
        contacts: '111 - единый номер (+375 152) 73-02-61 (+375 29) 33-57-111 (+375 29) 58-28-003',
        email: '',
        address: 'Гродно, ул. Полиграфистов, д. 2',
        overview: '',
        timezoneName: 'Europe/Minsk',
        homePage: null,
        _id: '5bfbf3ffeee022cd3',
        phone: '111 - единый номер (+375 152) 73-02-61 (+375 29) 33-57-111 (+375 29) 58-28-003',
        pathWay: null,
        _lastModifiedAt: 1553520699000,
        deleted: false,
        doctorsCounter: 0,
        _lastModifiedBy: null,
        areaCode: 152,
        _coll: 'clinics',
        id: '5bfbf3ffeee022cd3',
        coverer: 'epam',
        services: [],
        emails: [],
        phones: [
            '111 - единый номер (+375 152) 73-02-61 (+375 29) 33-57-111 (+375 29) 58-28-003',
        ],
        feedbacksCount: 0,
        workTime: 'Нет расписания',
        timezone: 180,
    },
    '5bfbf6d1679408696': {
        schedule: null,
        name: 'МЦ "ЛОДЭ" на Гоголя',
        photoUrl: null,
        contacts: null,
        email: null,
        address: 'Брест, ул. Гоголя 54',
        overview: null,
        timezoneName: 'Europe/Minsk',
        homePage: null,
        _id: '5bfbf6d1679408696',
        phone: null,
        pathWay: null,
        _lastModifiedAt: 1553520999000,
        deleted: false,
        doctorsCounter: 0,
        _lastModifiedBy: null,
        areaCode: 162,
        _coll: 'clinics',
        id: '5bfbf6d1679408696',
        services: [],
        emails: [],
        phones: [],
        feedbacksCount: 0,
        workTime: 'Нет расписания',
        timezone: 180,
    },
    '5c87b8f2ace5bbed8': {
        schedule: '',
        name: 'МЦ "Лодэ" Гродно / Выезд на дом',
        photoUrl: null,
        contacts: '',
        email: '',
        address: 'Гродно, ',
        overview: '',
        timezoneName: 'Europe/Minsk',
        homePage: null,
        _id: '5c87b8f2ace5bbed8',
        phone: '',
        pathWay: null,
        _lastModifiedAt: 1554718636000,
        deleted: false,
        doctorsCounter: 0,
        _lastModifiedBy: null,
        areaCode: 152,
        _coll: 'clinics',
        id: '5c87b8f2ace5bbed8',
        services: [],
        emails: [],
        phones: [],
        feedbacksCount: 0,
        workTime: 'Нет расписания',
        timezone: 180,
    },
    '5b3f5cf17c5d77cc4': {
        schedule: '',
        name: 'МЦ "ЛОДЭ" на Замковой EPAM',
        photoUrl: null,
        contacts: '111; +375 (152) 73-02-61; +375 (29) 33-57-111; +375 (29) 58-28-003',
        email: '',
        address: 'Гродно, ул. Замковая, д. 4',
        overview: '',
        timezoneName: 'Europe/Minsk',
        homePage: null,
        _id: '5b3f5cf17c5d77cc4',
        phone: '111; +375 (152) 73-02-61; +375 (29) 33-57-111; +375 (29) 58-28-003',
        pathWay: null,
        _lastModifiedAt: 1553774044000,
        onlineBookingAllowed: true,
        onlineConnectivity: true,
        deleted: false,
        doctorsCounter: 1,
        _lastModifiedBy: null,
        areaCode: 152,
        _coll: 'clinics',
        id: '5b3f5cf17c5d77cc4',
        coverer: 'epam',
        services: [],
        emails: [],
        phones: [
            '111',
            ' +375 (152) 73-02-61',
            ' +375 (29) 33-57-111',
            ' +375 (29) 58-28-003',
        ],
        feedbacksCount: 0,
        workTime: 'Нет расписания',
        timezone: 180,
    },
    '5c0ead627a292a60f': {
        schedule: '',
        name: 'МЦ "Лодэ" Минск / Выезд на дом',
        photoUrl: null,
        contacts: '',
        email: '',
        address: ', ',
        overview: '',
        timezoneName: 'Europe/Minsk',
        homePage: null,
        _id: '5c0ead627a292a60f',
        phone: '',
        pathWay: null,
        _lastModifiedAt: 1554217503000,
        deleted: false,
        doctorsCounter: 0,
        _lastModifiedBy: null,
        areaCode: 17,
        _coll: 'clinics',
        id: '5c0ead627a292a60f',
        services: [],
        emails: [],
        phones: [],
        feedbacksCount: 0,
        workTime: 'Нет расписания',
        timezone: 180,
    },
};
