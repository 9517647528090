/* eslint-disable no-underscore-dangle,no-param-reassign,prefer-destructuring */
import { useNavigation, useNavigationState } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import {
    BigButton, dataFrom, NavigationList, Page, Panel, React, Section, Text, TopNotification, VBox, View,
} from '../../common';
import {
    actualVisits, doctorsFeedbacks, doctorsOnlyWithPartnershipAndSums, doctorsWithClinics, getAdaptedClinics, getCurrentDoctorFeedbacks, getCurrentLocation, getFavoritesFull, getIsTimetableSumLoading, getMyVotes, getStatusesOfHappenedVisits, getStatusesOfHappenedVisitsDoctor, getUseLocationNta, getUser, historyVisits,
} from '../../selectors';
import actions, { navigate } from '../../actions';
import firebase from '../../services/firebase';
import { VisitsList } from '../Visits/VisitsList';
import { getRouteHistory } from '../../utils/getRouteHistory';
import { COLORS } from '../../styles/colors';
import { DoctorFeedbackChart, FeedbackBadge } from '../Feedbacks/Doctors/snippets';
import { DoctorFeedbackForm, useReduxStoreCollection } from '../Feedbacks/Doctors/DoctorFeedbacks';
import {
    dbGettr, getDoctorProfile, getPriceRangeString, getPrices, mergeFeedbacksToDoctor,
} from '../../selectors/utils';
import { PageSpinner } from '../../common/PageSpinner';
import { getHasVisits, isChild, isMe } from '../Feedbacks/Doctors/DoctorFeedbackContainer';
import { emptyFeedback } from '../../hooks/useFeedbacks';
import { cloneObject, mergeObjects } from '../../utils/objects';
import { useDoctorCoverage } from '../../hooks/doctorCovarage';
import { DoctorBanner, DoctorDetailedInfoColumns } from './snippets';
import {
    getDoctorWithNTA, getHandledAgesFromWorkPlace, toVisit, useUpdateDoctorFeedback,
} from './utils';

const DoctorDetailsVisits = dataFrom(actualVisits, VisitsList);

export const updateFavPhotoUrlAndName = (f, fav, photoUrl, name) => {
    fav.photoUrl = photoUrl;
    if (name) {
        fav.displayName = name;
    }
    actions.updateFullFavoritesList(f);
};

export const useMyVisits = (doctorId) => {
    const [hasVisits, setHasVisits] = React.useState(false);
    const [hasVisitFeedbackPage, setHasVisitFeedbackPage] = useState(false);
    const [hasVisitDoctorPage, setHasVisitDoctorPage] = useState(false);
    const [myVisits, setMyVisits] = React.useState([]);

    const history = useSelector(historyVisits);
    const { visits, profileId } = history;

    const {
        isAuthenticated,
        info,
        // isLoading,
    } = useSelector(getUser);

    const statuses = {
        true: useSelector(getStatusesOfHappenedVisitsDoctor),
        false: useSelector(getStatusesOfHappenedVisits),
    };

    useEffect(() => {
        const v = (visits ?? [])
            .filter(item => item.physicianId === doctorId && doctorId)
            .map(item => ({
                ...item,
                isMe: isMe(item?.profileId),
                isChild: isChild(item?.profileId),
            }));
        const _hasVisitFeedbackPage = getHasVisits(doctorId, v, statuses.false, info);
        setHasVisitFeedbackPage(_hasVisitFeedbackPage);
        const _hasVisitDoctorPage = getHasVisits(doctorId, v, statuses.true, info);
        setHasVisitDoctorPage(_hasVisitDoctorPage);
        setMyVisits(v);
        setHasVisits(!!v?.length);
    }, [visits, doctorId]);

    useReduxStoreCollection(profileId, visits);

    return {
        myVisits,
        hasVisits,
        hasVisitFeedbackPage,
        hasVisitDoctorPage,
        profileId,
        isAuthenticated,
        info,
    };
};

export const useController = (doctorId, needUnsubscribe = true, defaultDoctor) => {
    const doctors = useSelector(doctorsOnlyWithPartnershipAndSums);
    const timetablesum = useSelector(getIsTimetableSumLoading);
    const feedBacks = useSelector(doctorsFeedbacks);
    const richWorksAt = useSelector(getAdaptedClinics); // seSelector(state => selectMedCenterDetails(state, clinicId));
    const myVotes = useSelector(getMyVotes);
    const feedbacksInfo = useSelector(getCurrentDoctorFeedbacks);
    const richDoctor = doctors && doctors?.find(e => e.id === doctorId);
    const [doctor, setDoctor] = React.useState(Object.assign(defaultDoctor ?? {}, richDoctor));
    const [votes, setVotes] = React.useState(emptyFeedback(doctorId));
    const [doctorInfo, setDoctorInfo] = useState();
    const isLoadingDoctors = doctor === undefined; // || !timetablesum; //  && !bareDoctor;
    const navigation = useNavigation();
    const favorites = useSelector(getFavoritesFull);

    useUpdateDoctorFeedback(richDoctor ? null : doctorId, needUnsubscribe);

    useEffect(() => {
        if (feedbacksInfo && doctor) {
            const info = mergeFeedbacksToDoctor(doctor, feedbacksInfo);
            setDoctor(info);
            setVotes(info ?? emptyFeedback(doctorId));
        }
    }, [feedbacksInfo, doctor]);

    React.useEffect(() => {
        const doctorFeedbacks = feedBacks ? feedBacks?.[doctorId] : null;
        // eslint-disable-next-line max-statements
        (async () => {
            if (!richDoctor && !doctor) {
                const info = await firebase.getById('physicians', doctorId);
                if (info && info._id) {
                    info.id = doctorId;
                    setDoctorInfo(info);
                    const infoTimetable = await firebase.getById('physicians_timetable_sum', doctorId);
                    // const feedbacksInfo = await firebase.getById('physicians_feedback_sum', doctorId);
                    const pricesInfo = await firebase.getById('physicians_prices_sum', doctorId);
                    if (pricesInfo) {
                        info.priceRange = getPrices(pricesInfo) || {};
                    }
                    if (richWorksAt) {
                        info.worksAt?.forEach((el) => {
                            return Object.assign(el, {
                                ...richWorksAt[el.id],
                                phones: richWorksAt[el.id]?.phones ?? '',
                                patientLowerAge: el?.patientLowerAge,
                                patientUpperAge: el?.patientUpperAge,
                                handledAges: getHandledAgesFromWorkPlace(el),
                            });
                        });
                    }
                    info.profile = getDoctorProfile(info.worksAt);
                    const doc = getDoctorWithNTA(infoTimetable, info);
                    const currentDoctor = info && !info?.deleted ? doc : null;
                    setDoctor(currentDoctor);
                    navigation.setOptions({ title: info.fullName });
                } else {
                    setDoctor(null);
                }
            } else if (timetablesum) {
                if (richDoctor) {
                    setDoctor(richDoctor);
                    richDoctor.id = doctorId;
                }
                setDoctorInfo(richDoctor);
            }
            setVotes(doctorFeedbacks ?? emptyFeedback(doctorId));
        })();
    }, [richDoctor, feedBacks, doctorId]);

    return {
        doctorId,
        doctor,
        isLoadingDoctors,
        votes,
        myVotes: myVotes?.physicians ?? {},
        textFeedbackCount: feedbacksInfo?.textFeedbackCount ?? votes?.textFeedbackCount ?? 0,
        recommendationIndex: feedbacksInfo?.recommendationIndex ?? votes?.recommendationIndex ?? 0,
        feedbacksCount: feedbacksInfo?.feedbacksCount ?? votes?.feedbacksCount ?? 0,
        recommendCount: feedbacksInfo?.recommendCount ?? votes?.recommendCount ?? 0,
        notRecommendCount: feedbacksInfo?.notRecommendCount ?? votes?.notRecommendCount ?? 0,
        neutralCount: feedbacksInfo?.neutralCount ?? votes?.neutralCount ?? 0,
        feedbacksInfo,
        doctorInfo,
        favorites,
    };
};

/**
 * Doctor Details Page.
 */
// eslint-disable-next-line max-statements
export const DoctorDetails = Page.register(({
    doctorInfo: { _id: doctorId }, doctorInfo, from, navigation,
}) => {
    // TODO: use getFeedbacksForData
    const navState = useNavigationState(s => s);
    const {
        doctor,
        isLoadingDoctors,
        votes,
        myVotes,
        favorites,
        recommendCount: recommendCount_,
        notRecommendCount: notRecommendCount_,
        neutralCount: neutralCount_,
        textFeedbackCount: textFeedbackCount_,
    } = useController(doctorId, true, doctorInfo);
    const v = useMyVisits(doctorId);
    const [docInfo, setDocInfo] = useState(false);
    const [isDocInfoLoading, setIsDocInfoLoading] = useState(false);
    const [textFeedbackCount, setTextFeedbackCount] = useState(0);
    const [recommendCount, setRecommendCount] = useState(0);
    const [neutralCount, setNeutralCount] = useState(0);
    const [notRecommendCount, setNotRecommendCount] = useState(0);
    const [nearestDate, setNearestDate] = useState(null);
    const location = useSelector(getCurrentLocation)?.code;
    const availableDate = useRef({});
    const allClinics = useSelector(getAdaptedClinics);
    const allDoctors = useSelector(doctorsWithClinics);
    const allNotifications = useSelector(dbGettr('notifications.data'));
    const useLocationNta = useSelector(getUseLocationNta);

    const docPart = useDoctorCoverage(docInfo);

    const getClinicId = c => c?.id ?? c?._id ?? c?.clinicId ?? c?.clinic_id;
    const getClinicCoverer = useCallback(
        (clinicId) => {
            if (!docInfo) {
                return null;
            }
            if (docPart) {
                const clinic = docPart?.worksAt?.find?.(e => getClinicId(e) === clinicId);
                if (clinic) {
                    return clinic?.coverer;
                }
            } else {
                const clinic = docInfo?.worksAt?.find?.(e => getClinicId(e) === clinicId);
                if (clinic) {
                    return clinic?.coverer;
                }
            }
            return null;
        },
        [docPart, docInfo],
    );

    // eslint-disable-next-line max-statements
    const withAvailableDate = React.useCallback(() => {
        // const d = deepCloneObject(docInfo);
        const d = cloneObject(docInfo);
        if (!d?.nearestDate) {
            d.nearestDate = nearestDate;
        }
        const doctorWithClinic = allDoctors?.find?.(e => e?.id === doctorId);
        for (let i = 0; i < d?.worksAt?.length; i++) {
            let el = d?.worksAt?.[i];
            const doctorClinic = doctorWithClinic?.worksAt?.find?.(
                e => e?.clinicId === el?.clinicId || e?.id === el?.clinicId || e?.id === el?.id,
            );

            el.assignmentId =
                doctorInfo?.worksAt?.find?.(
                    ({ clinicId: cId, id: cId_ }) => (cId === el?.clinicId && cId && el?.clinicId) || cId_ === el?.id || cId === el?.id,
                )?.assignmentId ?? el.assignmentId;

            if (availableDate.current[el?.assignmentId]) {
                if (availableDate.current[el?.assignmentId]) {
                    el.nearestAvailableDate = new Date(availableDate.current[el?.assignmentId]);
                }
            }
            // eslint-disable-next-line no-nested-ternary
            const status = allNotifications?.[el?.id] ? 'offline' : el?.onlineConnectivity ? 'online' : 'unavailable';
            const coverer = el?.coverer ?? getClinicCoverer(getClinicId(el));
            const priceRange =
                el?.priceRange ??
                d?.priceRange?.[getClinicId(el)] ??
                d?.priceRange?.clinics_with_prices?.find(c => getClinicId(c) === getClinicId(el));
            if (priceRange?.price_max && !priceRange?.max) {
                priceRange.max = priceRange.price_max;
            }
            if (priceRange?.price_min && !priceRange?.min) {
                priceRange.min = priceRange.price_min;
            }
            try {
                if (priceRange?.max && priceRange?.min) {
                    priceRange.priceRangeString = getPriceRangeString(priceRange.min, priceRange.max);
                }
            } catch (e) {
                //
            }
            el = {
                ...el,
                ...(allClinics[el.id] ?? {}),
                coverer,
                priceRange,
                connectivityStatus: status,
            };
            if (doctorClinic) {
                if (doctorClinic.patientLowerAge !== undefined) {
                    el.patientLowerAge = doctorClinic.patientLowerAge;
                }
                if (doctorClinic.patientUpperAge !== undefined) {
                    el.patientUpperAge = doctorClinic.patientUpperAge;
                }
            }
            d.worksAt[i] = el;
        }
        delete d?.doctor;
        d.coverer = d?.worksAt?.find?.(({ coverer }) => coverer)?.coverer;
        try {
            if (d?.priceRange && !d?.priceRange?.min && !d?.priceRange?.max) {
                let min = 0;
                let max = 0;
                d?.worksAt?.forEach?.((clinic) => {
                    const clinicMin = clinic?.priceRange?.min;
                    if (clinicMin !== undefined && clinicMin > 0) {
                        if (min === 0) {
                            min = clinicMin;
                        } else {
                            min = Math.min(min, clinicMin);
                        }
                    }
                    if (clinic?.priceRange?.max !== undefined) {
                        max = Math.max(max, clinic?.priceRange?.max);
                    }
                });
                d.priceRange.min = min;
                d.priceRange.max = max;
                d.priceRange.priceRangeString = getPriceRangeString(d?.priceRange?.min, d?.priceRange?.max);
            }
        } catch (e) {
            //
        }
        return d;
    }, [docInfo, availableDate, nearestDate, allClinics, doctor, allDoctors]);

    const action = React.useCallback(() => {
        toVisit(withAvailableDate());
    }, [docInfo]);

    const loadDoctorInfo = async (id) => {
        setTimeout(async () => {
            try {
                const actualDoctor = await firebase.getById('physicians', id);
                const info = actualDoctor?.deleted ? {} : actualDoctor;
                const fb = await firebase.getById('physicians_feedback_sum', id);
                const pricesInfo = await firebase.getById('physicians_prices_sum', doctorId);
                if (pricesInfo) {
                    info.priceRange = getPrices(pricesInfo) || {};
                }
                if (fb) {
                    setTextFeedbackCount(fb?.textFeedbackCount ?? 0);
                    setRecommendCount(fb?.recommendCount ?? 0);
                    setNeutralCount(fb?.neutralCount ?? 0);
                    setNotRecommendCount(fb?.notRecommendCount ?? 0);
                }
                info.worksAt?.forEach?.((el) => {
                    return Object.assign(el, {
                        patientLowerAge: el?.patientLowerAge,
                        patientUpperAge: el?.patientUpperAge,
                        handledAges: getHandledAgesFromWorkPlace(el),
                    });
                });
                if (!info?.profile) {
                    info.profile = getDoctorProfile(info.worksAt);
                }
                let experienceYears = info?.experienceYears;
                if (experienceYears && typeof experienceYears === 'number') {
                    experienceYears = String(experienceYears);
                }

                let category = info?.category;
                try {
                    category = JSON.parse(category);
                    if (!Array.isArray(category) && category) {
                        category = [category];
                    } else if (!category?.length) {
                        category = null;
                    }
                } catch (_) {
                    //
                }

                let degree = info?.degree;
                try {
                    degree = JSON.parse(degree);
                    if (!Array.isArray(degree) && degree) {
                        degree = [degree];
                    } else if (!degree?.length) {
                        degree = null;
                    }
                } catch (_) {
                    //
                }

                let academicTitle = info?.academicTitle;
                try {
                    academicTitle = JSON.parse(academicTitle);
                    if (!Array.isArray(academicTitle) && academicTitle) {
                        academicTitle = [academicTitle];
                    } else if (!academicTitle?.length) {
                        academicTitle = null;
                    }
                } catch (_) {
                    //
                }

                if (academicTitle) {
                    info.academicTitle = academicTitle;
                }

                if (degree) {
                    info.degree = degree;
                }

                if (category) {
                    info.category = category;
                }

                if (experienceYears) {
                    info.experienceYears = experienceYears;
                }

                setDocInfo(info);
                setIsDocInfoLoading(false);
            } catch (e) {
                setIsDocInfoLoading(false);
            }
        }, 1200);
    };

    // eslint-disable-next-line max-statements
    const loadNearestDate = useCallback(async () => {
        if (!doctorInfo?.nearestDate || doctorInfo?.worksAt?.some(clinic => !clinic?.nearestAvailableDate)) {
            if (docInfo?.specialization && location && docInfo?.worksAt?.length) {
                if (!useLocationNta) {
                    try {
                        const infoTimetable = await firebase.getById('physicians_timetable_sum', doctorId);
                        const nearestDates = [];
                        if (!infoTimetable) {
                            return;
                        }
                        availableDate.current = {};
                        docInfo?.worksAt?.forEach((el) => {
                            const time = infoTimetable?.nearest_available_dates?.find(
                                item => item?.clinic_id === el?.id,
                            )?.nearest_available_date; // nta?.[el?.assignmentId];
                            if (time) {
                                nearestDates.push(time);
                                availableDate.current[el?.assignmentId] = time;
                            }
                        });
                        let minNta;
                        if (nearestDates.length) {
                            const nearestDateTime = nearestDates?.sort?.()?.[0];
                            if (nearestDateTime) {
                                minNta = new Date(nearestDateTime);
                                setNearestDate(minNta);
                            }
                        }
                    } catch (e) {
                        // console.log('Something wrong with NTA: e');
                    }
                } else {
                    const locations = docInfo?.worksAt?.map?.(e => e?.areaCode).filter(Boolean);
                    const collectionId = `physicians_nta_sum/${locations?.[0] ?? location}/${docInfo?.specialization}`;
                    firebase
                        .getById(collectionId, '0')
                        .then((doc) => {
                            const nearest = [];
                            const nta = doc?.ntaByAssignment;
                            if (!nta) {
                                return;
                            }
                            availableDate.current = {};
                            docInfo?.worksAt?.forEach((el) => {
                                const time = nta?.[el?.assignmentId];
                                if (time) {
                                    nearest.push(time);
                                    availableDate.current[el?.assignmentId] = time;
                                }
                            });
                            let d;
                            if (nearest.length) {
                                const nearestDateTime = nearest?.sort?.()?.[0];
                                if (nearestDateTime) {
                                    d = new Date(nearestDateTime);
                                    setNearestDate(d);
                                }
                            }
                        })
                        .catch(() => {
                            // console.log('Error get collection', e);
                        });
                }
            }
        }
    }, [docInfo, location]);

    useEffect(() => {
        if (
            doctor === null ||
            doctor === undefined ||
            !doctor?.fullName ||
            !doctor?.worksAt ||
            from === 'DoctorsFavorites'
        ) {
            const id = doctorId ?? doctorInfo?._id ?? 'n/a';
            setIsDocInfoLoading(true);
            loadDoctorInfo(id);
        } else {
            setRecommendCount(recommendCount_);
            setNeutralCount(neutralCount_);
            setNotRecommendCount(notRecommendCount_);
            setTextFeedbackCount(textFeedbackCount_);
            setDocInfo(mergeObjects(doctor, docInfo));
        }
    }, [doctor, recommendCount_, textFeedbackCount_, notRecommendCount_, neutralCount_]);

    useEffect(() => {
        if (!doctorInfo?.fullName && docInfo?.fullName) {
            navigation.setParams({ doctorInfo: docInfo });
        }
        loadNearestDate();
    }, [docInfo]);

    useEffect(() => {
        const favFound = favorites?.find?.(fav => fav?.id === doctorId);
        if (favFound) {
            const name = docInfo?.fullName?.replace(' ', '\n');
            if (favFound?.photoUrl !== docInfo?.photoUrl || favFound?.dispalyName !== name) {
                updateFavPhotoUrlAndName(favorites, favFound, docInfo?.photoUrl, name);
            }
        }
    }, [favorites, docInfo]);
    const editFeedback = () => {
        const feedback = {
            forItemId: doctorId,
            anonymous: false,
        };
        const goToPage = 'AddDoctorFeedbacks';
        const goToParams = {
            feedback,
            doctorInfo: docInfo,
            needFetch: false,
            // needFetch: true,
        };
        navigate(goToPage, goToParams);
    };

    const navTo = React.useCallback(
        ({ id }) => {
            if (id === 'myFeedback') {
                editFeedback();
                return;
            }
            navigate(id, { doctorInfo: docInfo });
        },
        [docInfo],
    );

    return docInfo ? (
        <Page name="doctor-details" hint={docInfo?.notification}>
            {docInfo?.worksAt
                ?.filter?.(e => e.notification)
                ?.map?.(({ notification }) => (
                    <TopNotification hint={notification} />
                ))}
            <Panel
                style={{
                    paddingBottom: 24,
                    paddingTop: 12,
                    marginBottom: 8,
                    paddingHorizontal: 0,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    borderTopWidth: 0,
                }}>
                <VBox flex>
                    <DoctorBanner
                        doctor={withAvailableDate()}
                        feedback={{
                            recommendCount,
                            notRecommendCount,
                            neutralCount,
                            textFeedbackCount,
                        }}
                    />
                    <View style={{ paddingHorizontal: 12 }}>
                        <BigButton
                            action={action}
                            title="buttons.make_appointment_doctor"
                            trackingParams={{
                                docId: doctorId,
                                journey: getRouteHistory(navState),
                            }}
                        />
                    </View>
                </VBox>
            </Panel>
            <DoctorDetailedInfoColumns doctor={withAvailableDate()} feedbacks={votes} />
            <NavigationList
                dataEnum={['doctorsDetailsNavigation', 'doctorsDetailsNavigationWithFeedbacks']}
                onItem={navTo}
                filtredFields={[
                    'DoctorFeedbacks',
                    (!myVotes[doctorId] || myVotes[doctorId] === 'NONE') && 'myFeedback',
                ]}
                onTitle={(e) => {
                    if (e.id === 'myFeedback') {
                        return (
                            <>
                                <Text
                                    style={{
                                        color: COLORS.STANDARD_GRAY,
                                        fontSize: 18,
                                    }}>
                                    {Object.R(e?.name)}
                                </Text>
                                <FeedbackBadge
                                    style={{
                                        marginBottom: -4,
                                        marginLeft: 12,
                                    }}
                                    vote={myVotes[doctorId]}
                                    onPress={editFeedback}
                                />
                            </>
                        );
                    }
                    return e?.name;
                }}
            />
            {!!v?.hasVisitDoctorPage && (
                <DoctorFeedbackForm
                    doctorId={doctorId}
                    showMyReview={false}
                    onlyVisited
                    doctorData={doctor}
                    withCard
                    cancelButton
                    doctorPage
                />
            )}

            <Panel
                style={{
                    marginTop: 8,
                    borderRadius: 0,
                    borderLeftWidth: 0,
                    borderRightWidth: 0,
                    paddingVertical: 15,
                    paddingHorizontal: 15,
                }}>
                <VBox flex>
                    <DoctorFeedbackChart
                        positive={recommendCount}
                        negative={notRecommendCount}
                        neutral={neutralCount}
                    />
                </VBox>
            </Panel>
            <Section title="titles.my_visits" textStyles={{ color: COLORS.STANDARD_GRAY }} style={{ marginBottom: 20 }}>
                <DoctorDetailsVisits doctorId={doctorId} />
            </Section>
        </Page>
    ) : (
        <Page hint={!isLoadingDoctors && !isDocInfoLoading && 'titles.doctorNotFound'}>
            {(isLoadingDoctors || isDocInfoLoading || doctorInfo === false) && <PageSpinner />}
        </Page>
    );
});
