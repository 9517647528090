import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
    Avatar, CustomSectionList, React, Stub, TextHighliter, Title, TouchableOpacity,
} from '../../../common';
import { elementsStyles } from '../../../styles/elements';
import { getPhysiciansAdvancedSearchResults } from '../../../selectors';
import { capitalize } from '../../../utils';
import tracking from '../../../services/tracking';
import { navigate } from '../../../actions';
import { onSpecialty } from '../../../common/Specialties';
import { getLenghtObj, getMaxCountOfSections, priorityObj } from './utils';
import { sectionStyle as style } from './style';

const isItemsEqual = (prop, propNext) => prop.keyword === propNext.keyword && prop.isLast === propNext.isLast;

export const DoctorSectionItem = React.memo(({ item, isLast, keyword }) => {
    const onPress = useCallback(() => {
        const eventParams = ['open_doc_from_asearch', { docId: item.id }];
        tracking.logEvent(...eventParams);
        navigate('DoctorDetails', {
            doctorInfo: {
                _id: item.id,
                id: item.id,
            },
        });
    }, [item.id]);

    return (
        <TouchableOpacity style={style.item} onPress={onPress}>
            <View style={[style.itemDocContainer, { borderBottomWidth: isLast ? 0 : 1 }]}>
                <View style={{ flex: 1 }}>
                    {keyword ? (
                        <TextHighliter bold value={item?.fullName} keyword={keyword} style={elementsStyles.text_bold} />
                    ) : (
                        <Title bold>{item?.fullName}</Title>
                    )}
                    <Title style={{ fontSize: 12 }}>{item?.specialty}</Title>
                </View>
                <Avatar of="physicians" imageUrl={item.photoUrl} size={45} />
            </View>
        </TouchableOpacity>
    );
}, isItemsEqual);

export const SectionItem = React.memo(({ item, isLast, keyword }) => {
    const onPress = useCallback(() => {
        const eventParams = ['open_spec_from_asearch', { spec: item.title }];
        tracking.logEvent(...eventParams);
        onSpecialty(item?.title);
    }, [item.title]);

    return (
        <TouchableOpacity style={style.item} onPress={onPress}>
            <View style={[style.itemContainer, { borderBottomWidth: isLast ? 0 : 1 }]}>
                {keyword ? (
                    <TextHighliter numberOfLines={1} value={capitalize(item?.title)} keyword={keyword} />
                ) : (
                    <Title numberOfLines={1}>{capitalize(item?.title)}</Title>
                )}
            </View>
        </TouchableOpacity>
    );
}, isItemsEqual);

const getRenderItem = (key) => {
    switch (key) {
        case 'doctors':
            return DoctorSectionItem;
        default:
            return SectionItem;
    }
};

export const DoctorsSearchResults = ({ keyword, searchResultSource = getPhysiciansAdvancedSearchResults }) => {
    const data = useSelector(searchResultSource);
    const lenghtObj = getLenghtObj(data);
    const countsObj = getMaxCountOfSections(lenghtObj);
    const keys = Object.keys(data).sort((key1, key2) => priorityObj[key1] - priorityObj[key2]);
    const nothingFound = !Object.values(data)?.filter(e => e?.length)?.length;
    const limit = 50;
    return keys?.length && !nothingFound ? (
        <>
            {keys?.map(key => (
                <CustomSectionList
                    maxNumToRender={countsObj?.[key]}
                    sectionTitle={Object.R(`titles.search${capitalize(key)}Section`, { count: data[key]?.length > limit ? `${limit}+` : data[key]?.length })}
                    data={data[key]}
                    renderItem={getRenderItem(key)}
                    keyword={keyword}
                    limit={limit}
                />
            ))}
        </>
    ) : nothingFound ? <Stub.DoctorsNotFound /> : null;
};
