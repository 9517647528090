/**
 * Universal async action creator.
 *
 * @param {*} type type of target reducer
 * @param {*} key key to store result into action payload under
 * @param initial
 * @param {*} fn functional
 */
export const asyncActionCreator = (type, key, initial, fn) => (...params) => async (dispatch) => {
    const dataInitializator = typeof initial === 'function' ? initial : (() => initial);
    let error = null;
    let data = dataInitializator();
    try {
        if (initial === undefined) {
            dispatch(({
                type,
                payload: {
                    error,
                    isLoading: true,
                },
            }));
        } else {
            dispatch(({
                type,
                payload: {
                    error,
                    [key]: data,
                    isLoading: true,
                },
            }));
        }
        data = await fn(...params);
        return data;
    } catch (ex) {
        error = ex;
        const networkErrorMessage = Object.R('error.network_connection');
        if (typeof ex === 'object') {
            if (ex?.nativeErrorCode === -1009 && ex?.message) {
                error = {
                    message: networkErrorMessage,
                    // code: -1009,
                };
            }
        }
        return false;
    } finally {
        dispatch(({
            type,
            payload: {
                error,
                [key]: data,
                isLoading: false,
            },
        }));
    }
};

/**
 * Universal reducer bound to given type.
 * It just assign payload to the current state
 * @param {string} TYPE to bind to
 * @param {object} initial initial state
 */
export const createReducerFor = (TYPE, initial = {}) => (state = initial, {
    type,
    payload,
}) => ((type === TYPE) ? { ...state, ...payload } : state);
