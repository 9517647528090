/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
    dataFrom, dataTrigger, Icon, React, S, showNotification, View,
} from '../common';
import { getFavoritesFull, getIsPersonalized } from '../selectors';
import actions from '../actions';
import { adaptClinicToFav, adaptDoctorToFav } from '../screens/App/snippets/utils';
import { putIntoStore } from '../store';
import { SCHEME } from '../scheme';

export const FavIcon = React.memo(
    dataTrigger(
        getIsPersonalized,
        dataFrom(getFavoritesFull)(({ item = {}, data, type }) => {
            const onPress = useCallback(() => {
                const adaptedItem = type === 'physicians' ? adaptDoctorToFav(item) : adaptClinicToFav(item);
                const op = data?.some(e => e.id === item?.id || e?.id === item?._id) ? 'remove' : 'add';
                const message = `titles.${op}${type}Bookmark`;
                const localizedMessage = Object.R(message);
                actions.toggleFullFavorites(adaptedItem);
                setTimeout(() => {
                    showNotification(localizedMessage, false);
                }, 0);
            }, [item, data]);
            const name = `bookmark-${
                data?.some(e => e.id === item?.id || e?.id === item?._id) ? 'active' : 'normal'
            }`;
            return (
                <View styles={S.absoluteTopRight}>
                    <Icon name={name} color="white" onPress={onPress} />
                </View>
            );
        }),
    ),
);

export const FavoritesIcon = ({ color, cb, isActive }) => {
    const isPersonalized = useSelector(getIsPersonalized);
    return isPersonalized ? (
        <Icon
            name={`bookmark-${isActive ? 'active' : 'normal'}`}
            color={color}
            onPress={() => {
                putIntoStore(SCHEME.PHYSICIANS_FILTER_FAVORITES, !isActive);
                actions.updateDocFilters({ filterFavoriteDoctors: !isActive });
                cb && cb();
            }}
        />
    ) : null;
};
