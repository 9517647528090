import {
    Image, Page, React, View,
} from '../../common';
import { AppHeader } from '../../combo';
import { imageViewer as styles } from './styles';

export const ViewImage = Page.register(
    ({
        actionIcon,
        uri,
    }) => (
        <View style={styles.view}>
            <Image source={{ uri }} style={styles.image}/>
            <View style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
            }}>
                {actionIcon}
            </View>
        </View>
    ),
    null,
    () => ({ header: () => <AppHeader/> }),
);
