/* eslint-disable no-bitwise */
import { isNull } from 'underscore';
import { USE_SPACE_IN_NAME } from '../config';

const FUZZY_SEARCH_K = 1;
// eslint-disable-next-line no-use-before-define
export const DEFAULT_SEARCH_FUNCTION = strongSearch; // default function for use search doctor and highlight text
export const MIN_WORD_LENGTH = 1;
export const AUTO_SWITCH_TO_FUZZY_LENGTH = 0; // 0 always use DEFAULT_SEARCH_FUNCTION
export const AUTO_SWITCH_TO_FUZZY_LENGTH_DOCTOR = 0; // 0 always use DEFAULT_SEARCH_FUNCTION

/**
 * Formats given string template with params.
 *
 * Template should contain placeholders like `{someKey}`,
 * which will be replaced with value by key from params.
 *
 * @param {string} template string template
 * @param {object} params hash with parameters
 */
export const formatString = (template, params) => {
    if (!template) {
        return '';
    }
    return `${template}`.replace(/\{(\S+)}/gi, (_, key) => ((params && params[key]) != null ? params[key] : ''));
};

export const prepareNameForEdit = (name) => (USE_SPACE_IN_NAME ? name?.replace(/\u00A0/g, ' ') : name)?.trim();


// export const normalizeSpaces = (str) => {
//     // return str.replace(/[\s\u00A0]+/g, '\u00A0').trim();
//     return str; // .replace(/(\s){2,}/g, ' ').replace(/(\u00A0){2,}/g, '\u00A0')?.trim();
// };

export const prepareNameForSave = (name) =>
    USE_SPACE_IN_NAME ? name?.trim?.()?.replace(/(\s){2,}/g, ' ').replace(/ /g, '\u00A0') || '\u00A0' : name?.trim?.();

export const capitalize = (x) => {
    if (!x) {
        return '';
    }
    const s = `${x}`;

    return s[0].toUpperCase() + s.slice(1);
};

export const tail = (x, sep = '.') => {
    if (!x) {
        return '';
    }
    const pos = x.lastIndexOf(sep);

    return pos === -1 ? x : x.slice(pos + sep.length);
};

export const head = (x, sep = '.') => {
    if (!x) {
        return '';
    }
    const pos = x.indexOf(sep);

    return pos === -1 ? x : x.slice(0, pos);
};

export const trimExtraSpaces = (x) =>
    x
        ?.trim?.()
        ?.replace?.(/\s+/g, ' ')
        ?.replace?.(/\s*([-.,:()/])\s*/g, '$1');

export const normalizeString = (str) => trimExtraSpaces(str?.toLowerCase?.()?.replace?.(/ё/g, 'е'));

export const getLastSubName = (name) => (!name ? '' : name?.split?.(' ')?.slice?.(1, -1)?.join?.(' '));

export const getFirstLastName = (name) => (!name ? '' : name?.split?.(' ')?.slice?.(0, 2)?.reverse?.().join(' '));

export const getSplittedName = (name) => {
    if (name) {
        const [surname, firstName, middleName] = name?.split?.(' ') ?? [];
        return {
            surname,
            firstName,
            middleName,
        };
    }
    return {};
};

/**
 * Inserts symbol into provided string at specified position.
 *
 * @param {string} string string to modify
 * @param {string} subitem substring or character to insert
 * @param {number} index a position at which to insert substring
 */
export const insertIntoString = (string, subitem, index) =>
    `${string?.slice?.(0, index)}${subitem}${string?.slice?.(index)}`;

export const testEmailRegex = (email) =>
    /^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/i.test(email);

export function fuzzySearch(text, pattern, startIndex = 0) {
    const patternLength = pattern.length;
    const textLen = text.length;

    const R = new Array(FUZZY_SEARCH_K + 1).fill(~1);
    const patternMask = new Array(2048).fill(~0);

    for (let index = 0; index < patternLength; ++index) {
        patternMask[pattern?.charCodeAt?.(index)] &= ~(1 << index);
    }

    for (let index = startIndex; index < textLen; ++index) {
        let oldRd1 = R[0];
        const charCode = text?.charCodeAt?.(index);
        R[0] |= patternMask[charCode];
        R[0] <<= 1;

        for (let d = 1; d <= FUZZY_SEARCH_K; ++d) {
            const temp = R[d];
            R[d] = (oldRd1 & (R[d] | patternMask[charCode])) << 1;
            oldRd1 = temp;
        }

        if ((R[FUZZY_SEARCH_K] & (1 << patternLength)) === 0) {
            return index - patternLength + 1;
        }
    }
    return -1;
}

export function strongSearch(text, pattern, startIndex = 0) {
    return text?.split?.('ё')?.join?.('е')?.indexOf?.(pattern?.split?.('ё')?.join?.('е'), startIndex);
}

// noinspection JSUnusedGlobalSymbols
export const isStringIncludeWord = (text, word) => {
    if (word.length < MIN_WORD_LENGTH) {
        return true;
    }
    if (AUTO_SWITCH_TO_FUZZY_LENGTH && AUTO_SWITCH_TO_FUZZY_LENGTH <= word.length) {
        return fuzzySearch(text, word) !== -1;
    }
    return DEFAULT_SEARCH_FUNCTION(text, word) !== -1;
};

export const searchWord = (
    text,
    word,
    searchFunction = DEFAULT_SEARCH_FUNCTION,
    switchToFuzzy = AUTO_SWITCH_TO_FUZZY_LENGTH,
) => {
    let usedSearchFunction = searchFunction;
    const result = [];
    const lastSearchText = text;
    const wordToSearch = word;
    if (wordToSearch?.length < MIN_WORD_LENGTH) {
        return result;
    }
    if (switchToFuzzy && wordToSearch?.length >= switchToFuzzy) {
        usedSearchFunction = fuzzySearch;
    }
    let prevPosition = 0;
    let res = usedSearchFunction(lastSearchText, wordToSearch, prevPosition);
    while (lastSearchText) {
        if (res < 0) {
            return result;
        }
        result.push(res);
        prevPosition = res + word.length;
        res = usedSearchFunction(lastSearchText, wordToSearch, prevPosition);
    }
    return result;
};

export const searchAllWords = (
    text,
    words,
    searchFunction = DEFAULT_SEARCH_FUNCTION,
    switchToFuzzy = AUTO_SWITCH_TO_FUZZY_LENGTH,
) => {
    const result = [];
    if (!Array.isArray(words)) {
        return result;
    }
    const textToSearch = text?.toLowerCase?.();
    const wordList = Array.from(new Set(words?.map?.((word) => word?.toLowerCase?.()))).sort(
        (a, b) => b.length - a.length,
    );
    wordList?.forEach?.((word) => {
        const positions = searchWord(textToSearch, word?.toLowerCase?.(), searchFunction, switchToFuzzy);
        positions?.forEach((position) => {
            result.push({
                word,
                position,
                flag: true,
            });
        });
    });
    return result
        ?.filter?.(
            (value, index, arr) =>
                arr?.findIndex?.((checkValue) =>
                    ['word', 'position']?.every((key) => checkValue[key] === value[key]),
                ) === index,
        )
        ?.sort((a, b) => a?.position - b?.position);
};

export const wordsList = (
    text,
    words,
    searchFunction = DEFAULT_SEARCH_FUNCTION,
    switchToFuzzy = AUTO_SWITCH_TO_FUZZY_LENGTH,
) => {
    const result = [];
    if (!Array.isArray(words)) {
        return result;
    }
    const textToSearch = text?.toLowerCase();
    const wordList = Array.from(new Set(words.map((word) => word?.toLowerCase()))).sort((a, b) => b.length - a.length);
    wordList.forEach((word) => {
        const positions = searchWord(textToSearch, word?.toLowerCase(), searchFunction, switchToFuzzy);
        if (word.length >= MIN_WORD_LENGTH && positions.length) {
            result.push(word);
        }
    });
    return Array.from(new Set(result));
};

const removeDuplicatePositions = (positions) => {
    positions?.forEach?.((item) => {
        if (item.flag) {
            const found = positions?.filter?.(
                (position) =>
                    position?.flag &&
                    position?.word !== item?.word &&
                    position?.position >= item?.position &&
                    position?.position <= item?.position + (item?.word?.length - 1),
            );
            // eslint-disable-next-line no-return-assign,no-param-reassign
            found?.forEach?.((i) => (i.flag = false));
        }
    });
    return positions?.filter?.((item) => item?.flag);
};

export const searchTextSplitter = (
    text,
    words,
    searchFunction = DEFAULT_SEARCH_FUNCTION,
    switchToFuzzy = AUTO_SWITCH_TO_FUZZY_LENGTH,
) => {
    const positions = removeDuplicatePositions(
        searchAllWords(
            text,
            words?.filter?.((word) => word?.trim?.()),
            searchFunction,
            switchToFuzzy,
        ),
    );
    const result = [];
    if (!positions.length) {
        return [
            {
                text,
                selected: false,
            },
        ];
    }
    let lastLength = 0;
    let firstPosition = 0;
    positions.forEach((position) => {
        if (position.position !== firstPosition) {
            result.push({
                text: text?.substring?.(firstPosition, position.position),
                selected: false,
            });
        }
        firstPosition = position.position;
        result.push({
            text: text?.substring?.(firstPosition, firstPosition + position.word.length),
            selected: true,
        });
        lastLength = position.word.length;
        firstPosition += lastLength;
    });

    if (firstPosition < text.length) {
        result.push({
            text: text?.substring?.(firstPosition, text.length),
            selected: false,
        });
    }
    return result;
};

const getFirstPosition = (str, except = '0123456789') => {
    const a = str?.split?.('');
    for (let i = 0; i < a.length; i += 1) {
        if (!except?.includes?.(a[i])) {
            return i;
        }
    }
    return -1;
};

export const parseVersion = (version) => {
    const parsedVersion = {
        major: 0,
        minor: 0,
        build: 0,
        info: '',
    };

    if (!version) {
        return parsedVersion;
    }

    const parts = version?.toString?.()?.split?.('.');
    parsedVersion.major = Number(parts[0] ?? 0);
    parsedVersion.minor = Number(parts[1] ?? 0);
    const pos = getFirstPosition(parts[2] ?? '');
    if (pos >= 0) {
        parsedVersion.build = Number(parts?.[2]?.substring?.(0, pos));
        parsedVersion.info = `${parts?.[2]?.substring(pos)}${parts?.length > 2 ? '.' : ''}${parts
            ?.slice?.(3)
            .join?.('.')}`;
    } else {
        parsedVersion.build = 0;
        parsedVersion.info = parts?.slice?.(2)?.toString?.();
    }
    return parsedVersion;
};

const emptyVersion = {
    major: 0,
    minor: 0,
    build: 0,
};

const getDifference = (num1, num2) => {
    const n1 = Number(num1);
    const n2 = Number(num2);
    if (n1 > n2) {
        return 1;
    } else if (n1 < n2) {
        return -1;
    }
    return 0;
};

export const compareVersion = (v1 = emptyVersion, v2 = emptyVersion) => {
    const major = getDifference(v1?.major, v2?.major);
    const minor = getDifference(v1?.minor, v2?.minor);
    const build = getDifference(v1?.build, v2?.build);
    if (major !== 0) {
        return major;
    }
    if (minor !== 0) {
        return minor;
    }
    return build;
};

export const extractPhoneNumber = (text, defaultPhone = '') => {
    return String(text).match(/((\+375)?[0-9]{9})/gm)?.[0] ?? defaultPhone;
};

export const trimObjectFields = (obj) => {
    if (typeof obj === 'undefined') {
        return obj;
    }

    if (isNull(obj)) {
        return obj;
    }
    return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value]),
    );
};

// eslint-disable-next-line no-undef
export const isSafari = () => /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
// eslint-disable-next-line no-undef
export const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);

export const prepareStringToCompare = (str) => str?.trim?.()?.toLowerCase?.() ?? '';
