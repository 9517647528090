import { useRoute } from '@react-navigation/native';
import { Page, React, View } from '../../common';
import { getIsResultOrReportLoading } from '../../selectors';
import { useIsOffine } from '../../services/http';
import { dbGettr, gettr } from '../../selectors/utils';
import { fetchLocalDoctors } from '../Doctors/utils';
import { HIDE_TEST_TAB } from '../../config';
import { HistoryVisits, Tests } from './snippets';

/**
 * My Card page.
 */

export const MyCard = Page.register(
    ({ enableTests, isResultOrReportLoading, visitError }) => {
        // fetchDoctors();
        fetchLocalDoctors();
        const { params } = useRoute();
        const filter = params?.filter ?? 'visits';
        const listFilter = params?.listFilter ?? 'all';
        const isOffline = useIsOffine();
        return (
            <Page
                name="my-card"
                backgroundColor="#f2f2f2"
                notification={isOffline ? Object.R('error.network_connection') : visitError}
                hideOnBackground
                noscroll
                isLoading={isResultOrReportLoading}>
                {enableTests && HIDE_TEST_TAB !== '1' ? (
                    <View style={{ flex: 1 }}>
                        {filter === 'visits' && <HistoryVisits withHeader />}
                        {filter === 'tests' && <Tests selectedFilter={listFilter} />}
                    </View>
                ) : (
                    <HistoryVisits />
                )}
            </Page>
        );
    },
    {
        enableTests: { from: dbGettr('enableTests') },
        isResultOrReportLoading: { from: getIsResultOrReportLoading },
        visitError: { from: gettr('visits.error') },
    },
);
