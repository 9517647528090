import {
    Keyboard, KeyboardAvoidingView, Platform, ScrollView,
} from 'react-native';
import WebView from 'react-native-webview';
import {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { useFocusEffect } from '@react-navigation/native';
import actions from '../actions';
import { storage } from '../services/localstorage';
import { getEnums } from '../i18n';
import { getSupportBody } from '../utils/supportInfo';
import { captureImage, getFromImageLibrary } from '../services/imagePicker';
import { isBioProvided, USE_BIO } from '../services/fingerprintScanner';
import store from '../store';
import { openURL } from '../services/device';
import {
    Dimensions, ModalImpl, React, Text, TouchableOpacity, TouchableWithoutFeedback, View,
} from './react';
import { Icon } from './Icon';
import {
    COLORS, getBottomObject, getMarginHorizontalObject, getWindowHeight, modal as styles, popupMessage as popupStyles,
} from './style';
import { Page, TopNotification } from './Page';
import { Button, ButtonsGroup } from './Button';
import { Img } from './Img';
import { Subtitle, Title, VBox } from './elements';
import { MultiSelector, Selector } from './Selector';
import { SearchBar } from './SearchBar';
import { Stub } from './Stub';
import { Agreements } from './Agreemants';

/**
 * Modal2 component - includes buttons.
 */

export const ModalAutoSize = ({
    title,
    subtitle,
    titleNumberOfLines,
    subtitleNumberOfLines,
    children = null,
    onRequestClose = Page.closeModal,
    buttonTitle,
    buttonCloseTitle,
    // wrapped to prevent parameters leaking into
    _onClose = () => onRequestClose(),
    // eslint-disable-next-line no-undef
    withOverlay = true,
    containerStyle,
    titleParams,
    hint,
    onSubmit,
    hideClose = false,
    showCross = false,
    noScroll = false,
    subtitleStyle,
}) => {
    const [cs, setCs] = useState([]);
    useEffect(() => {
        const containerStyles = [
            styles.container_for_filter,
            // eslint-disable-next-line no-undef
            // window.isDesktop ?
            { ...getMarginHorizontalObject(), ...getBottomObject() },
            containerStyle,
        ];
        setCs(containerStyles);
    }, [containerStyle, styles.container_for_filter, getMarginHorizontalObject, getBottomObject]);
    return (
        <ModalImpl
            transparent
            animationType="fade"
            visible
            onRequestClose={_onClose}
            backdropColor="transparent"
            backdropOpacity={0}>
            <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                <View style={[styles.overlay, withOverlay ? null : styles.overlay_transparent]}>
                    <TouchableOpacity style={styles.stub} onPress={_onClose} />
                    <View style={cs} accessibilityLabel="modal">
                        {hint && <TopNotification hint={hint} ns="modal.hint" />}
                        {title ? (
                            <View centered style={{ marginVertical: 20 }}>
                                <Title
                                    bold
                                    centered
                                    numberOfLines={titleNumberOfLines}
                                    id={title}
                                    ns="modal_title"
                                    params={titleParams}
                                />
                                {subtitle && (
                                    <Subtitle
                                        numberOfLines={subtitleNumberOfLines || null}
                                        style={[
                                            {
                                                paddingHorizontal: 12,
                                                paddingTop: 14,
                                            },
                                            subtitleStyle,
                                        ]}
                                        id={subtitle}
                                        ns="modal_subtitle"
                                    />
                                )}
                            </View>
                        ) : null}
                        {noScroll ? children : <ScrollView>{children}</ScrollView>}
                        {showCross && (
                            <Icon
                                name="cross"
                                onPress={_onClose}
                                style={{
                                    position: 'absolute',
                                    right: 12,
                                    top: 12,
                                }}
                            />
                        )}
                        <ButtonsGroup gap={0}>
                            {!hideClose && (
                                <Button
                                    ns="close"
                                    styles={[
                                        styles.button,
                                        onSubmit && {
                                            borderRightWidth: 1,
                                            borderColor: '#e0e0e0',
                                        },
                                    ]}
                                    titleStyles={{ color: '#5C85DD' }}
                                    title={buttonCloseTitle ?? 'buttons.close'}
                                    action={_onClose}
                                />
                            )}
                            {onSubmit && (
                                <Button
                                    ns="further"
                                    styles={styles.button}
                                    titleStyles={{ color: '#5C85DD' }}
                                    title={buttonTitle ?? 'buttons.further'}
                                    action={onSubmit}
                                />
                            )}
                        </ButtonsGroup>
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </ModalImpl>
    );
};

export const Modal2 = ({
    title,
    subtitle,
    titleNumberOfLines,
    subtitleNumberOfLines,
    children = null,
    onRequestClose = Page.closeModal,
    buttonTitle,
    buttonCloseTitle,
    // wrapped to prevent parameters leaking into
    _onClose = () => onRequestClose(),
    // eslint-disable-next-line no-undef
    withOverlay = true,
    containerStyle,
    containerStyles = [
        styles.container_for_filter,
        // eslint-disable-next-line no-undef
        // window.isDesktop ?
        { ...getMarginHorizontalObject(), ...getBottomObject() },
        containerStyle,
    ],
    titleParams,
    hint,
    onSubmit,
    hideClose = false,
    showCross = false,
    noScroll = false,
    subtitleStyle,
}) => (
    <ModalImpl
        transparent
        animationType="fade"
        visible
        onRequestClose={_onClose}
        backdropColor="transparent"
        backdropOpacity={0}>
        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <View style={[styles.overlay, withOverlay ? null : styles.overlay_transparent]}>
                <TouchableOpacity style={styles.stub} onPress={_onClose} />
                <View style={containerStyles} accessibilityLabel="modal">
                    {hint && <TopNotification hint={hint} ns="modal.hint" />}
                    {title ? (
                        <View centered style={{ marginVertical: 20 }}>
                            <Title
                                bold
                                centered
                                numberOfLines={titleNumberOfLines}
                                id={title}
                                ns="modal_title"
                                params={titleParams}
                            />
                            {subtitle && (
                                <Subtitle
                                    numberOfLines={subtitleNumberOfLines || null}
                                    style={[
                                        {
                                            paddingHorizontal: 12,
                                            paddingTop: 14,
                                        },
                                        subtitleStyle,
                                    ]}
                                    id={subtitle}
                                    ns="modal_subtitle"
                                />
                            )}
                        </View>
                    ) : null}
                    {noScroll ? children : <ScrollView>{children}</ScrollView>}
                    {showCross && (
                        <Icon
                            name="cross"
                            onPress={_onClose}
                            style={{
                                position: 'absolute',
                                right: 12,
                                top: 12,
                            }}
                        />
                    )}
                    <ButtonsGroup gap={0}>
                        {!hideClose && (
                            <Button
                                ns="close"
                                styles={[
                                    styles.button,
                                    onSubmit && {
                                        borderRightWidth: 1,
                                        borderColor: '#e0e0e0',
                                    },
                                ]}
                                titleStyles={{ color: '#5C85DD' }}
                                title={buttonCloseTitle ?? 'buttons.close'}
                                action={_onClose}
                            />
                        )}
                        {onSubmit && (
                            <Button
                                ns="further"
                                styles={styles.button}
                                titleStyles={{ color: '#5C85DD' }}
                                title={buttonTitle ?? 'buttons.further'}
                                action={onSubmit}
                            />
                        )}
                    </ButtonsGroup>
                </View>
            </View>
        </TouchableWithoutFeedback>
    </ModalImpl>
);

export const Modal3 = ({
    title,
    subtitle,
    titleNumberOfLines,
    subtitleNumberOfLines,
    children = null,
    onRequestClose = Page.closeModal,
    // wrapped to prevent parameters leaking into
    _onClose = () => onRequestClose(),
    // eslint-disable-next-line no-undef
    withOverlay = true,
    containerStyles = [
        styles.container_for_filter,
        // eslint-disable-next-line no-undef
        // window.isDesktop ?
        {
            // marginHorizontal: getMarginHorizontal(),
            ...getMarginHorizontalObject(),
            ...getBottomObject(),
        },
        // : {},
    ],
    titleParams,
    hint,
    onSubmit,
    hideClose = false,
    showCross = false,
    noScroll = false,
    subtitleStyle,
    buttonTitle,
    buttonCloseTitle,
}) => (
    <ModalImpl
        transparent
        animationType="fade"
        visible
        onRequestClose={_onClose}
        backdropColor="transparent"
        backdropOpacity={0}>
        <View style={[styles.overlay, withOverlay ? null : styles.overlay_transparent]}>
            <TouchableOpacity style={styles.stub} onPress={_onClose} />
            <View style={containerStyles} accessibilityLabel="modal">
                {hint && <TopNotification hint={hint} ns="modal.hint" />}
                {title ? (
                    <View
                        centered
                        style={{
                            margin: 0,
                            paddingTop: 20,
                            paddingBottom: 12,
                        }}>
                        <Title
                            bold
                            centered
                            numberOfLines={titleNumberOfLines}
                            id={title}
                            ns="modal_title"
                            params={titleParams}
                        />
                        {subtitle && (
                            <Subtitle
                                numberOfLines={subtitleNumberOfLines || null}
                                style={[
                                    {
                                        paddingHorizontal: 12,
                                        paddingTop: 0,
                                    },
                                    subtitleStyle,
                                ]}
                                id={subtitle}
                                ns="modal_subtitle"
                            />
                        )}
                    </View>
                ) : null}
                {noScroll ? children : <ScrollView>{children}</ScrollView>}
                {showCross && (
                    <Icon
                        name="cross"
                        onPress={_onClose}
                        style={{
                            position: 'absolute',
                            right: 12,
                            top: 12,
                        }}
                    />
                )}
                <ButtonsGroup
                    gap={0}
                    style={{
                        borderTopWidth: 1,
                        borderTopColor: COLORS.LIGHT_GRAY,
                    }}>
                    {!hideClose && (
                        <Button
                            ns="close"
                            styles={[
                                styles.button,
                                onSubmit && {
                                    borderRightWidth: 1,
                                    borderColor: '#e0e0e0',
                                },
                            ]}
                            titleStyles={{ color: '#5C85DD' }}
                            title={buttonCloseTitle ?? 'buttons.close'}
                            action={_onClose}
                        />
                    )}
                    {onSubmit && (
                        <Button
                            ns="further"
                            styles={styles.button}
                            titleStyles={{ color: '#5C85DD' }}
                            title={buttonTitle ?? 'buttons.further'}
                            action={onSubmit}
                        />
                    )}
                </ButtonsGroup>
            </View>
        </View>
    </ModalImpl>
);

export const infoModal = (header, text) => Page.showModal(
    <Modal3
        title={header}
        noScroll
        onRequestClose={() => Page.closeModal()}
        withOverlay
        buttonCloseTitle="button.understand">
        <Subtitle
            id={text}
            style={{
                margin: 0,
                paddingHorizontal: 20,
                paddingTop: 0,
                paddingBottom: 20,
                fontSize: 14,
            }}
            numberOfLines={0}
        />
    </Modal3>,
);

/**
 * Modal component.
 */
export const Modal = ({
    title,
    titleNumberOfLines,
    textInfo,
    textInfoNumberOfLines,
    children = null,
    onRequestClose = Page.closeModal,
    onClose = () => {},
    onOutsidePress = () => {
        onClose?.();
        onRequestClose();
    },
    // wrapped to prevent parameters leaking into
    _onClose = () => {
        onClose?.();
        onRequestClose();
    },
    subtitle,
    subtitleNumberOfLines,
    subtitleStyle = {},
    withOverlay = true,
    containerStyles = [],
    containerStyle,
    titleParams,
    keyboardAvoidingProps = Platform.OS === 'ios' ? { behavior: 'position' } : {},
    hideClose = false,
}) => (
    <ModalImpl
        transparent
        animationType="fade"
        visible
        onRequestClose={_onClose}
        backdropColor="transparent"
        backdropOpacity={0}>
        <View style={[styles.overlay, withOverlay ? null : styles.overlay_transparent, { justifyContent: 'flex-end' }]}>
            <TouchableOpacity style={styles.stub} onPress={onOutsidePress} />
            <KeyboardAvoidingView {...keyboardAvoidingProps}>
                {/* eslint-disable-next-line no-undef */}
                <View
                    style={[
                        containerStyles.length === 0
                            ? [
                                styles.container,
                                { ...getMarginHorizontalObject(), ...getBottomObject(-24) },
                                containerStyle ?? {},
                            ]
                            : containerStyles,
                    ]}
                    accessibilityLabel="modal">
                    {title || subtitle ? (
                        <View
                            style={{
                                marginTop: 12,
                                marginBottom: 20,
                            }}>
                            {title && (
                                <Title
                                    bold
                                    centered
                                    style={{ marginBottom: subtitle ? 12 : 0 }}
                                    numberOfLines={titleNumberOfLines}
                                    id={title}
                                    ns="modal_title"
                                    params={titleParams}
                                />
                            )}
                            {textInfo && (
                                <Subtitle
                                    numberOfLines={textInfoNumberOfLines || null}
                                    centered
                                    style={styles.textInfo}
                                    id={textInfo}
                                    ns="modal_textinfo"
                                />
                            )}
                            {subtitle && (
                                <Subtitle
                                    numberOfLines={subtitleNumberOfLines || null}
                                    style={[{ paddingLeft: 12 }, subtitleStyle]}
                                    id={subtitle}
                                    ns="modal_subtitle"
                                />
                            )}
                        </View>
                    ) : null}
                    {children}
                    {hideClose ? null : (
                        <Icon
                            name="cross"
                            onPress={_onClose}
                            style={{
                                position: 'absolute',
                                right: 12,
                                top: 12,
                            }}
                        />
                    )}
                </View>
            </KeyboardAvoidingView>
        </View>
    </ModalImpl>
);
Modal.Congrats = ({ onDone, children, onRequestClose = () => Page.closeModal(onDone) }) => (
    <Modal title="titles.congratulations" onRequestClose={onRequestClose}>
        <VBox gap={2} style={{ paddingHorizontal: 16 }}>
            {children}
            <Button primary title="button.understand" action={onRequestClose} />
        </VBox>
    </Modal>
);

export const PopupMessage = ({
    message,
    localizeMessage,
    onClose,
    closeModal = () => {
        if (onClose) {
            onClose();
        }
        Page.closeModal();
    },
}) => (
    <ModalImpl
        transparent
        animationType="fade"
        visible
        onRequestClose={closeModal}
        backdropColor="transparent"
        backdropOpacity={0}>
        <View style={[styles.overlay, styles.overlay_transparent]}>
            <TouchableOpacity style={styles.stub} onPress={closeModal} />
            {/* eslint-disable-next-line no-undef */}
            <View
                style={[
                    popupStyles.container,
                    // eslint-disable-next-line no-undef
                    // window.isDesktop ?
                    { ...getMarginHorizontalObject(), ...getBottomObject() },
                    // : {},
                ]}
                accessibilityLabel="modal">
                <Text accessibilityLabel="popup_title" style={popupStyles.title}>
                    {localizeMessage ? Object.R(message) : message}
                </Text>
                <Button action={closeModal}>
                    <Icon name="cross" color="#fff" />
                </Button>
            </View>
        </View>
    </ModalImpl>
);

export const showNotification = (message, localize = true) => {
    setTimeout(() => {
        const autocloseTimeout = setTimeout(() => {
            Page.closeModal();
        }, 5000);
        Page.showModal(
            <PopupMessage
                message={message}
                localizeMessage={localize}
                onClose={() => {
                    clearTimeout(autocloseTimeout);
                }}
            />,
        );
    }, 0);
};

export const Prompt = ({
    title,
    subtitle,
    textInfo,
    children = null,
    submitOptions,
    cancelOptions,
    submitTitle = 'titles.submit',
    cancelTitle = 'titles.cancel',
    hideClose = false,
    onCancel = () => 0,
    onSubmit = () => 1,
    submitDelay = 0,
    numberOfLines = 1,
    titleNumberOfLines,
}) => {
    const timerRef = useRef(0);
    const [timer, setTimer] = useState(submitDelay ?? 0);

    const timerHandler = () => {
        setTimer((prevState) => {
            if (prevState <= 1 && timerRef.current) {
                clearTimeout(timerRef.current);
            }
            return prevState - 1;
        });
    };

    useEffect(() => {
        if (submitDelay) {
            timerRef.current = setInterval(timerHandler, 1000);
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current);
            }
        };
    }, []);

    return (
        <Modal
            title={title}
            textInfo={textInfo}
            subtitle={subtitle}
            hideClose={hideClose}
            titleNumberOfLines={titleNumberOfLines}>
            {children}
            <ButtonsGroup>
                <Button
                    ns="submitButton"
                    danger
                    transparent
                    title={timer || submitTitle}
                    disabled={timer > 0}
                    action={() => {
                        Page.closeModal();
                        onSubmit();
                    }}
                    {...submitOptions}
                    numberOfLines={numberOfLines}
                />
                <Button
                    ns="cancelButton"
                    normal
                    primary
                    title={cancelTitle}
                    action={() => {
                        Page.closeModal();
                        typeof onCancel === 'function' && onCancel?.();
                    }}
                    {...cancelOptions}
                    numberOfLines={numberOfLines}
                />
            </ButtonsGroup>
        </Modal>
    );
};

export const ModalAgreements = ({
    onConfirm, onCancel, userAgreement = true, personalDataAgreement = true,
}) => {
    const [confirmed, setConfirmed] = useState(false);
    const key = 'titles.agreementsComment';
    const title = Object.R(key);
    return (
        <Modal hideClose style={{ height: 200 }}>
            {title && title !== key && (
                <Title
                    style={{
                        paddingBottom: 10,
                        paddingHorizontal: 10,
                    }}>
                    {title}
                </Title>
            )}
            <Agreements
                onChange={value => setConfirmed(value)}
                userAgreement={userAgreement}
                personalDataAgreement={personalDataAgreement}
            />
            <ButtonsGroup>
                <Button normal title="buttons.cancel" action={() => onCancel && onCancel()} capitalize />
                <Button
                    primary
                    title="buttons.confirmAgreements"
                    disabled={!confirmed}
                    action={() => onConfirm && onConfirm()}
                    capitalize
                />
            </ButtonsGroup>
        </Modal>
    );
};

export const useKeyboardHeight = (platforms = ['ios']) => {
    if (Array.isArray(platforms) && !platforms.includes(Platform.OS)) {
        return 0;
    }
    if (typeof platforms === 'string' && platforms.length && !platforms !== Platform.OS) {
        return 0;
    }
    const [keyboardHeight, setKeyboardHeight] = useState(0);
    const keyboardWillShow = (e) => {
        setKeyboardHeight(e?.endCoordinates?.height ?? 0 - e?.startCoordinates?.height ?? 0);
    };
    const keyboardWillHide = () => {
        setKeyboardHeight(0);
    };
    useFocusEffect(() => {
        const showListener = Keyboard.addListener('keyboardDidShow', keyboardWillShow);
        const hideListener = Keyboard.addListener('keyboardDidHide', keyboardWillHide);
        return () => {
            showListener.remove();
            hideListener.remove();
        };
    });
    return keyboardHeight;
};

export const ModalSelector = (props) => {
    const [value, setValue] = useState('');
    const keyboardHeight = useKeyboardHeight(['ios']);

    const filterByValue = useCallback(
        (d, val) => {
            try {
                const words = (val ?? '').toLowerCase().trim().split(' ');
                if (words.length) {
                    return d.filter(({ name }) => words.every(w => name.toLowerCase().includes(w)));
                }
                return d;
            } catch (e) {
                return d;
            }
        },
        [props.data, value],
    );

    const data = filterByValue(props.data, value);
    const modal = props.useSystemModal ? Page.closeSystemModal : Page.closeModal;

    return (
        <Modal2
            title={props.title}
            onSubmit={props.onSubmit}
            subtitle={props.subtitle}
            subtitleStyle={props.subtitleStyle}
            hint={props.hint}
            hideClose={props.hideClose}
            noScroll={props.isWithSearch}
            _onClose={() => {
                modal();
                props.onClose?.();
            }}
            {...(props.isWithSearch
                ? {
                    containerStyles: [
                        styles.citySelectorContainer,
                        {
                            ...getMarginHorizontalObject(),
                            maxHeight:
                                  (Platform.OS === 'web'
                                      ? getWindowHeight()
                                      : Dimensions.get('window').height - keyboardHeight) - 50,
                        },
                    ],
                }
                : {})}>
            {props.isWithSearch ? <SearchBar autoCorrect={false} onSearch={setValue} initialValue={value} /> : null}
            <View
                style={{
                    borderBottomWidth: props?.noLastLine ? 1 : 0,
                    flex: 1,
                    borderBottomColor: COLORS.LIGHT_GRAY,
                }}>
                <ScrollView
                    enableOnAndroid
                    enableAutomaticScroll
                    extraScrollHeight={Platform.OS === 'ios' ? 60 : 10}
                    style={[
                        {
                            ...(props.isWithSearch ? styles.citySelectorList : {}),
                            marginTop: 0,
                        },
                    ]}>
                    {props.multi ? (
                        <MultiSelector radio {...props} />
                    ) : (
                        <Selector
                            radio
                            {...props}
                            keyword={value}
                            data={data}
                            onSelect={(item, isSelected) => {
                                props.onSelect(item, isSelected);
                                modal();
                            }}
                            noLastLine={props?.noLastLine}
                        />
                    )}
                    {props.isWithSearch && value && !(data && data.length) ? <Stub type="empty-default" /> : null}
                </ScrollView>
            </View>
        </Modal2>
    );
};

/**
 * Select options modal.
 *
 * @param props
 */
export function selectOptions(props) {
    const modal = props.useSystemModal ? Page.showSystemModal : Page.showModal;
    modal(<ModalSelector {...props} />);
}

export const SupportModal = title => Page.showModal(
    <Prompt
        title="titles.error"
        subtitle={title}
        submitTitle="buttons.later"
        cancelTitle="buttons.write"
        onCancel={() => openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`)}
        submitOptions={{
            danger: false,
            titleStyles: {
                fontFamily: 'SourceSansPro-Semibold',
                color: '#575757',
            },
        }}
        cancelOptions={{ transparent: false }}
    />,
);

const wnLocalesation = {
    'titles.wnRelatives': 'У нас обновился раздел Моя Семья!',
    'titles.wnRelativesHint':
        'Теперь вы можете добавлять родителей и супругов, чтобы записывать их на прием к врачу, просматривать медицинскую информацию, редактировать и переносить визиты.',
    'buttons.excellent': 'ОТЛИЧНО!',
};

// noinspection JSUnusedGlobalSymbols
export const WNModal = () => (
    <Modal hideClose title={wnLocalesation['titles.wnRelatives']}>
        <Img.WNRelatives />
        <Title
            id={wnLocalesation['titles.wnRelativesHint']}
            style={{
                marginTop: 12,
                marginBottom: 20,
            }}
            centered
            numberOfLines={null}
        />
        <Button
            primary
            title={wnLocalesation['buttons.excellent']}
            action={() => {
                storage.set('didOpenWNModal_relatives', true);
                Page.closeModal();
            }}
        />
    </Modal>
);

export const NotificationsPermissionsModal = ({ onConfirm, onClose }) => {
    const onConfirmPress = () => {
        Page.closeSystemModal();
        onConfirm?.();
    };
    const onCancelPress = () => {
        Page.closeSystemModal();
        onClose?.();
    };
    return (
        <Modal
            title={'titles.notif_permissins_modal'}
            onClose={() => {
                Page.closeSystemModal();
                onClose?.();
            }}>
            <Title
                id={'titles.notif_permissins_modal_message'}
                style={{
                    marginTop: 12,
                    marginBottom: 20,
                }}
                centered
                numberOfLines={null}
            />
            <ButtonsGroup>
                <Button transparent title={'buttons.notif_permissins_modal_cancel'} action={onCancelPress} capitalize />
                <Button primary title={'buttons.notif_permissins_modal_confirm'} action={onConfirmPress} capitalize />
            </ButtonsGroup>
        </Modal>
    );
};

export const ProfileInstructionsModal = () => (
    <Modal hideClose>
        <View>
            <ScrollView style={{ maxHeight: '90%' }}>
                <View style={{ paddingHorizontal: 20 }}>
                    <Title bold centered numberOfLines={null} id="titles.congratulations" />
                    <Title style={{ paddingTop: 16 }} numberOfLines={null}>
                        {Object.R('titles.nowCanManageVisits')}
                    </Title>
                    <Title style={{ paddingBottom: 22 }} numberOfLines={null}>
                        {Object.R('titles.activateProfile')}
                    </Title>
                </View>
                <View
                    style={{
                        paddingHorizontal: 12,
                        justifyContent: 'center',
                    }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 22,
                        }}>
                        <Img.EnterSignIn />
                        <Title
                            numberOfLines={null}
                            style={{
                                flex: 2,
                                paddingLeft: 20,
                            }}>{`1. ${Object.R('titles.enterSignIn')}`}</Title>
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 22,
                        }}>
                        <Img.FillOutProfile />
                        <Title
                            numberOfLines={null}
                            style={{
                                flex: 2,
                                paddingLeft: 20,
                            }}>{`2. ${Object.R('titles.fillOutProfile')}`}</Title>
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginBottom: 22,
                        }}>
                        <Img.AddKids />
                        <Title
                            numberOfLines={null}
                            style={{
                                flex: 2,
                                paddingLeft: 20,
                            }}>{`3. ${Object.R('titles.addKids')}`}</Title>
                    </View>
                </View>
            </ScrollView>
            <ButtonsGroup style={{ paddingHorizontal: 8 }}>
                <Button
                    transparent
                    capitalize
                    title={Object.R('titles.doNotRemind')}
                    action={() => {
                        storage.set('doNotShowProfileInstructionsModal', true);
                        Page.closeModal();
                    }}
                />
                <Button
                    normal
                    primary
                    title={Object.R('button.understand')}
                    action={() => {
                        Page.closeModal();
                    }}
                />
            </ButtonsGroup>
        </View>
    </Modal>
);

export const showImagePickerModal = (pickPhotoCallback, onClose = () => {}) => Page.showModal(
    <Modal
        title={Object.R('titles.choose_photo_from')}
        onRequestClose={() => {
            Page.closeModal();
            onClose();
        }}>
        <Button
            title="buttons.from_gallery"
            styles={[styles.modalButton, { paddingHorizontal: 20 }]}
            textStyles={styles.selectModalTitle}
            action={() => Page.closeModal(() => setTimeout(() => getFromImageLibrary(pickPhotoCallback, onClose), 0))
            }
        />
        <Button
            title="buttons.from_camera"
            styles={[styles.modalButton, { paddingHorizontal: 20 }]}
            textStyles={styles.selectModalTitle}
            action={() => Page.closeModal(() => setTimeout(() => captureImage(pickPhotoCallback, onClose), 0))}
        />
    </Modal>,
);

// noinspection JSUnusedGlobalSymbols
export const showPopupMessage = ({ title = '', message, secondTitle = true }) => {
    Page.showModal(
        <Modal style={{ height: 200 }} title={title}>
            {title && secondTitle ? <Text>{title}</Text> : null}
            <Text>{message}</Text>
        </Modal>,
    );
};

export const showHtmlModal = (props) => {
    Page.showModal(
        <Modal2 title={props.title}>
            <WebView
                source={{ html: props.html }}
                style={{
                    height: 300,
                    paddingVertical: 30,
                    backgroundColor: '#F9F9F9',
                }}
                scalesPageToFit
                textZoom={250}
            />
        </Modal2>,
    );
};

const SettingsModal = ({
    data, onClose, acodeConfirmation, bio,
}) => {
    const enumType = `confirmSettings${USE_BIO ? bio?.biometryType ?? 'Bio' : ''}`;
    let bioData = data ?? getEnums(enumType);
    if (!bio?.available) {
        bioData = bioData.map((item, idx) => (!idx
            ? item
            : {
                ...item,
                subtitle: Object.R(`bio${bio?.biometryType ?? ''}.${bio?.error ?? ''}`),
                disabled: !!bio?.error,
            }));
    }

    const [itemId, setItemId] = useState(acodeConfirmation || bioData?.[0].id);
    const onConfirm = () => {
        Page.closeModal();
        actions.setAcodeConfirmation(itemId);
        // eslint-disable-next-line no-unused-expressions
        onClose && onClose();
    };
    return (
        <Modal2
            title={Object.R('titles.settings')}
            subtitle={Object.R('titles.chooseConfirmationMethod')}
            hideClose
            showCross>
            <View
                style={{
                    flexDirection: 'column',
                    paddingHorizontal: 10,
                    paddingBottom: 20,
                }}>
                <Selector
                    radio
                    selected={itemId}
                    data={bioData}
                    onSelect={item => setItemId(item.id)}
                    style={{ backgroundColor: 'transparent' }}
                />
                <Button ns="submitButton" primary capitalize title={Object.R('buttons.confirm')} action={onConfirm} />
                {/* {!!bio?.error && Platform.OS !== 'web' && <Button */}
                {/*    capitalize */}
                {/*    primary */}
                {/*    title={Object.R('titles.toBioSettings')} */}
                {/*    action={() => { */}
                {/*        if (Platform.OS === 'ios') { */}
                {/*            Linking.openURL('App-Prefs:root=TOUCHID_PASSCODE'); */}
                {/*        } else if (Platform.OS === 'android') { */}
                {/*            Linking.sendIntent('android.settings.SECURITY_SETTINGS'); */}
                {/*        } */}
                {/*    }} */}
                {/* />} */}
            </View>
        </Modal2>
    );
};

export const showSettingsModal = async (onClose) => {
    const { acodeConfirmation } = await storage.getObject('selections');
    let confirmEnroll = true;
    try {
        confirmEnroll = !!store.getState()?.db?.biometryNoEnrollConfirm;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Error get state');
    }

    const bio = await isBioProvided();
    if (!bio?.available && (bio?.error === 'NO_BIO' || !confirmEnroll)) {
        onClose();
        return;
    }

    Page.showModal(<SettingsModal onClose={onClose} acodeConfirmation={acodeConfirmation} bio={bio} />);
};
