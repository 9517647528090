/* eslint-disable no-underscore-dangle */
import api from './api';
import { removePendingPrograms } from './programs';

/**
 * Service around BNS Insurance company.
 */
export default {

    // register an user with his BNS program
    register: async (code, relativeId, cb) => {
        try {
            cb({
                error: null,
                isLoading: true,
            });
            const result = relativeId ? await api.bnsRelativeRegister(code, relativeId) : await api.bnsRegister(code);
            if (!result._id) {
                // noinspection ExceptionCaughtLocallyJS
                throw result;
            }
            removePendingPrograms();
            cb({
                success: result,
                isLoading: false,
            });
        } catch (err) {
            cb({
                error: {
                    ...err,
                    message: err.body?.message || err.body?.detail || err.message || err.detail,
                },
                isLoading: false,
            });
        }
    },

    // get all my contracts
    info: () => api.bnsInfo(),

};
