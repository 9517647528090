import EStyleSheet from 'react-native-extended-stylesheet';
import {
    decor, fullScreen, margin, mh, ml, mr, mv, padding, pb, pbHalf, ph, pr, pv,
} from './atomic';

export default EStyleSheet.create({
    fullScreen,
    // layouts
    padding,
    pb,
    pr,
    pv,
    ph,
    pbHalf,

    margin,
    mh,
    mv,
    ml,
    mr,

    // typography
    ...decor,
    text: { color: '#6D6D6D' },
    link: { color: '#6A90E0' },
    absolute: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    absolute_right: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    absoluteTopRight: {
        position: 'absolute',
        right: 12,
        top: 12,
    },
    flex: {
        flex: 1,
        width: null,
        height: null,
    },
    title: {
        marginLeft: 8,
        color: '#555555',
        fontSize: 12,
        paddingBottom: 10,
        fontWeight: '600',
    },
    subTitle: {
        alignSelf: 'center',
        paddingBottom: 8,
        color: '#C5C5C5',
        fontSize: 10,
        fontWeight: '600',
    },

    flex1: { flex: 1 },
    flexRow__center: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
    },
    flexRow: { flexDirection: 'row' },
    alignItemsEnd: { alignItems: 'flex-end' },
    spaceBetween: { justifyContent: 'space-between' },
    alignSelfEnd: { alignSelf: 'flex-end' },

    borderTop: {
        borderTopWidth: 1,
        borderTopColor: '#E0E0E0',
    },
});

