// import IPppp from 'react-native-image-picker';

import { Platform } from 'react-native';
import { guid } from '../utils';
import actions from '../actions';
import { MODE } from '../config';
import { requestCameraPermission, requestExternalReadPermission } from './device';

const WEB_HOOK =
    'https://epam.webhook.office.com/webhookb2/08a5e3c3-3d83-4ec5-b6e6-fad749ca0f06@b41b72d0-4e9f-4c26-8a69-f949f367c91d/IncomingWebhook/3161bd072a364adf9cca5f6bf1a76857/07abf814-5b5b-48f1-ba3b-489e87957b78';

const sendTeamsMessage = async (payload) => {
    try {
        const response = await fetch(WEB_HOOK, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }
    } catch (error) {
        // console.error('Error sending message to Teams channel:', error);
    }
};

export const sendProfileMessage = ({
    text, data, title, type = 'message',
}) => {
    let out = 'Error in JSON.stringify';
    try {
        out = JSON.stringify(
            {
                text,
                data: {
                    ...data,
                    e: null,
                },
                // type,
            },
            null,
            2,
        ).replaceAll('"', '');
    } catch (e) {
        console.log('Error in JSON.stringify', e);
        out = `Error in JSON.stringify ${e.toString()}`;
    }

    const payload = {
        '@type': type ?? 'MessageCard',
        '@context': 'http://schema.org/extensions',
        text: title ?? 'No title',
        sections: [
            {
                facts: [
                    {
                        name: text ?? 'data',
                        // eslint-disable-next-line prefer-template
                        value: '```\n' + out + '\n```',
                    },
                ],
            },
        ],
    };
    MODE === 'qa' && sendTeamsMessage(payload).catch(e => console.log('sendProfileMessage', JSON.stringify(e)));
};

const adaptPhotos = (arr, errorMessage) => arr.map(({
    fileName, uri, type, origURL,
}) => ({
    path: uri,
    type,
    uri,
    id: guid(),
    error: errorMessage,
    // origURL,
    origURL: uri,
}));

export const captureImage = async (cb, onDidCancel) => {
    // eslint-disable-next-line global-require
    // const { launchCamera } = require('react-native-image-picker');

    import('react-native-image-picker').then(async ({ launchCamera }) => {
        const isCameraPermitted = await requestCameraPermission();
        const isStoragePermitted = await requestExternalReadPermission();

        if (isCameraPermitted && (Platform.OS !== 'android' || Platform.Version > 28 || isStoragePermitted)) {
            setTimeout(() => {
                launchCamera(
                    {
                        mediaType: 'photo',
                        saveToPhotos: true,
                    },
                    ({
                        didCancel, errorCode, errorMessage, assets, uri,
                    }) => {
                        sendProfileMessage({
                            text: 'Camera',
                            data: {
                                uri,
                                errorMessage,
                                errorCode,
                                assets,
                            },
                        });
                        actions.addLog('camera_action', {
                            didCancel,
                            errorCode,
                            errorMessage,
                            assets,
                            uri,
                        });
                        if (didCancel && onDidCancel) {
                            onDidCancel();
                            return;
                        } else if (errorCode === 'camera_unavailable') {
                            // eslint-disable-next-line no-undef,no-alert
                            alert('Camera not available on device');
                            onDidCancel();
                            return;
                        } else if (errorCode === 'permission') {
                            // eslint-disable-next-line no-undef,no-alert
                            alert('Permission not satisfied');
                            onDidCancel();
                            return;
                        } else if (errorCode === 'others') {
                            // eslint-disable-next-line no-undef,no-alert
                            alert(errorMessage);
                            onDidCancel();
                            return;
                        }
                        const newPhotos = adaptPhotos(assets, errorMessage);
                        sendProfileMessage({
                            text: 'Camera adopt photos',
                            data: {
                                uri,
                                errorMessage,
                                errorCode,
                                assets,
                                newPhotos,
                            },
                        });
                        actions.addLog('camera', {
                            assets,
                            errorMessage,
                            newPhotos,
                        });
                        cb({
                            error: errorMessage,
                            assets: newPhotos,
                        });
                    },
                );
            }, 1000);
        } else {
            // eslint-disable-next-line no-undef,no-alert
            alert('Permission not satisfied');
            onDidCancel();
        }
    });
};

export const getFromImageLibrary = async (cb, onDidCancel) => {
    // eslint-disable-next-line global-require
    // const { launchImageLibrary } = require('react-native-image-picker');
    import('react-native-image-picker').then(({ launchImageLibrary }) => {
        setTimeout(() => {
            launchImageLibrary({}, ({
                didCancel, errorCode, errorMessage, assets, uri,
            }) => {
                sendProfileMessage({
                    text: 'Gallery',
                    data: {
                        uri,
                        errorMessage,
                        errorCode,
                        assets,
                    },
                });
                actions.addLog('gallery_action', {
                    didCancel,
                    errorCode,
                    errorMessage,
                    assets,
                    uri,
                });
                if (didCancel && onDidCancel) {
                    onDidCancel();
                    return;
                } else if (errorCode === 'permission' || errorCode === 'camera_unavailable') {
                    // eslint-disable-next-line no-undef,no-alert
                    alert(Object.R('titles.errorAddDocuments'));
                    onDidCancel();
                    return;
                } else if (errorCode === 'others') {
                    // eslint-disable-next-line no-undef,no-alert
                    alert(response.errorMessage);
                    onDidCancel();
                    return;
                }
                const newPhotos = adaptPhotos(assets, errorMessage);
                sendProfileMessage({
                    text: 'Gallery adopt photos',
                    data: {
                        uri,
                        errorMessage,
                        errorCode,
                        assets,
                        newPhotos,
                    },
                });
                actions.addLog('gallery', {
                    assets,
                    errorMessage,
                    newPhotos,
                });
                cb({
                    error: errorMessage,
                    assets: newPhotos,
                });
            });
        }, 1000);
        onDidCancel();
    });
};
