import { AES, enc, MD5 } from 'crypto-js';

export const md5 = s => MD5(s)
    .toString();

export const decrypt = (data, key, def = null) => {
    if (data) {
        if (typeof data === 'object') {
            return data;
        }
        try {
            const dec = AES.decrypt(data, key)
                .toString(enc.Utf8);
            return JSON.parse(dec);
        } catch (e) {
            try {
                // fallback
                return JSON.parse(data);
            } catch (_) {
                return def;
            }
        }
    }
    return def;
};

export const encrypt = (data, key) => AES.encrypt(JSON.stringify(data), key)
    .toString();


const cache = new Map();
// eslint-disable-next-line no-return-assign
export const decryptCached = (s, key) => {
    if (cache.has(s)) {
        return cache.get(s);
    }
    const r = decrypt(s, key);
    cache.set(s, r);
    return r;
};
