import { Platform } from 'react-native';
import { getGlobalLocations, getLanguage } from '../i18n';
import { ALLOWED_LANGUAGES, DEFAULT_LOCATION } from '../config';
import {
    getCurrentLanguage, getGlobalLocation, getPopularQueries, getSupportedlanguages,
} from './getters';
import { createSelector, dbGettr } from './utils';

export const getCurrentLocation = createSelector(
    [getGlobalLocation, getGlobalLocations],
    (currentLocationCode = DEFAULT_LOCATION, locations = []) => locations.find(item => item.code === String(currentLocationCode) || item.code === currentLocationCode) || {},
);

export const getActualPopularQueries = createSelector(
    [getPopularQueries, getCurrentLocation],
    (queries = [], { banners } = {}) => queries.filter(item => item.type === 'drugs' || banners?.includes('doctors')) || {},
);

export const getActualLanguagesData = createSelector(
    [getCurrentLanguage, getSupportedlanguages],
    (lang = getLanguage(), languages = []) => {
        return {
            languages: ALLOWED_LANGUAGES?.length
                ? languages?.filter?.(({ id }) => ALLOWED_LANGUAGES?.find(({ id: f }) => f === id))
                : languages,
            selected: lang,
        };
    },
);

export const getMapsAppsConfig = createSelector([dbGettr('mapsAppsConfig')], config => (config && Object.keys(config).some(key => key === 'ios' || key === 'android')
    ? Platform.select({
        ios: config.ios,
        android: config.android,
    })
    : config));
