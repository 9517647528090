/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo, useState } from 'react';
import { Page, React, View } from '../../common';
import { Map } from '../../combo';
import { getMapType } from '../../selectors';
import { averageLocation } from '../Tabletka/DrugstoreDetails.utils';
import { sortBy } from '../../utils';
import { useBackHandler } from '../../utils/routing';
import { MedCenterDetailsBunner } from '../MedCentersMap/MedCenterDetailsBunner';

export const UMSMedCentersMap = Page.register(
    ({ data = [], item: initialItem, mapType }) => {
        const [item, setItem] = useState(initialItem);
        const { id: itemId } = item || {};
        const adaptData = data.map(e => ({
            ...e,
            latitude: +e.latitude,
            longitude: +e.longitude,
            onlineConnectivity: e.onlineConnectivity ? 1 : null,
            phones: e.phones,
            message: e.onlineConnectivity ? Object.R('titles.onlineConnectivityMessage') : '',
        }));
        const initialRegion = useMemo(
            () => (initialItem
                ? // && initialItem.latitude
                {
                    latitude: initialItem?.latitude ?? 0,
                    longitude: initialItem?.longitude ?? 0,
                }
                : averageLocation(adaptData)),
            [initialItem, data],
        );
        useBackHandler(
            useCallback(() => {
                // eslint-disable-next-line no-undef
                if (itemId && window._currentRouteName === 'UMSMedCentersMap') {
                    setItem(null);
                    return true;
                }
                return false;
            }, [!!itemId]),
        );

        const actualData = useMemo(
            () => sortBy(
                adaptData.map(e => (!e.isSelected === !(e.id === itemId)
                    ? e
                    : {
                        ...e,
                        isSelected: !e.isSelected,
                    })),
                '-latitude',
            ),
            [itemId, data],
        );
        return (
            <Page noscroll name="ums_medcenters_map_screen">
                <View accessibilityLabel="ums_medcenters_screen_map" style={{ flex: 1 }}>
                    <View style={{ flex: 1 }}>
                        {!initialRegion ? null : (
                            <Map
                                mapType={mapType}
                                data={actualData}
                                onItem={setItem}
                                initialRegion={initialRegion}
                                scaleKey="onlineConnectivity"
                                item={{ id: itemId }}
                            />
                        )}
                    </View>
                    {!itemId ? null : <MedCenterDetailsBunner item={item} noServices />}
                </View>
            </Page>
        );
    },
    { mapType: { from: getMapType } },
);
