import {
    React, Text, TouchableOpacity, View,
} from './react';
import { tabSwitch as style } from './style';

const applyActiveStyle = (isActive, styl) => (isActive ? styl : {});

export const TabSwitch = ({
    data,
    onChange,
    containerStyle,
}) => (
    <View style={[style.wrapper, containerStyle]}>
        {
            data.map(({
                id,
                name,
                isActive,
            }, index) => (
                <TouchableOpacity
                    onPress={() => onChange(id)}
                    style={[style.button,
                        index === 0 ? style.buttonLeft : null,
                        index === data.length - 1 ? style.buttonRight : null,
                        applyActiveStyle(isActive, style.buttonActive),
                    ]}
                >
                    <Text style={[style.buttonText, applyActiveStyle(isActive, style.buttonActiveText)]}>{name}</Text>
                </TouchableOpacity>
            ))
        }
    </View>
);
