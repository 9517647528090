import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { useEffect } from 'react';
import {
    BigButton,
    Box,
    entityData,
    ErrorStub,
    List,
    LoadingIndicator,
    Page,
    React,
    Section,
    Stub,
    TabSelector,
    View,
} from '../../../common';
import actions from '../../../actions';
import { getActualFeedbacks } from '../../../selectors';
import { FeedbackListItem } from '../../Doctors/snippets';
import { FeedbacksListItem as FeedbackMedListItem } from './FeedbacksListItem';

const FeedbacksList = ({
    data = [],
    isLoading,
    isAuthenticated,
    userId,
    forItemKind = '',
}) => {
    const { setParams } = useNavigation();
    const { params: { filter = forItemKind === 'physicians' ? 'all' : 'allFeedbacks' } } = useRoute();
    const tabItems = [
        {
            id: 'all',
            name: Object.R('titles.all')
                .toUpperCase(),
        },
        {
            id: 'recommend',
            name: Object.R('titles.positiveFeedbackShort')
                .toUpperCase(),
        },
        {
            id: 'not_recommend',
            name: Object.R('titles.negativeFeedbackShort')
                .toUpperCase(),
        },
    ];
    let items;
    if (forItemKind === 'physicians') {
        items = filter === 'all'
            ? data
            : data?.filter(item => item?.vote?.toLowerCase() === filter);
    } else {
        items = filter === 'myFeedbacks'
            ? data.filter(({ userProfileId }) => userProfileId && `${userId}` === `${userProfileId}`)
            : data;
    }

    return isLoading ? (<View style={{
        flex: 1,
        justifyContent: 'center',
        alignContent: 'center',
    }}><LoadingIndicator/></View>) : (
        <Section.White style={{
            flex: 1,
            marginBottom: 0,
            borderTopWidth: 1,
            borderColor: '#E9E9E9',
            marginTop: (forItemKind === 'physicians' ? 2 : 10),
            paddingTop: 0,
        }}>
            {isAuthenticated && userId && !!data?.length &&
                <TabSelector
                    items={forItemKind === 'physicians' ? tabItems : 'feedbacksSortItems'}
                    selected={filter}
                    onItem={e => setParams({ filter: e.id })}
                />}
            {
                data?.length ? (
                    <List
                        type="feedbacks"
                        white
                        data={items.map(item => ({
                            ...item,
                            confirmed: item?.status === 'CONFIRMED',
                        }))
                            .sort()}
                        isLoading={isLoading}
                        ListEmptyComponent={data?.length ? (
                            <View style={{ paddingBottom: 50 }}>
                                <Stub.FeedbacksTab/>
                            </View>
                        ) : (
                            <View style={{ paddingBottom: 50 }}>
                                <Stub.Feedbacks/>
                            </View>
                        )}
                        renderItem={({
                            item,
                            index,
                        }) => {
                            if (forItemKind === 'physicians') {
                                try {
                                    const anonymousText = Object.R('feedback.anonumous');
                                    const {
                                        feedbackText = '',
                                        userName = anonymousText,
                                        createdAt = '',
                                        vote = 'NOT_CONFIRMED',
                                        confirmed,
                                        userCoverers,
                                        anonymous,
                                        userProfileId,
                                        responses,
                                    } = item;
                                    return (
                                        <View style={{
                                            paddingTop: 2,
                                            paddingHorizontal: 20,
                                        }}>
                                            <FeedbackListItem
                                                bold={userId === userProfileId}
                                                feedbackText={feedbackText}
                                                vote={vote}
                                                createdAt={createdAt}
                                                userName={anonymous ? anonymousText : userName}
                                                showBorder
                                                confirmed={confirmed}
                                                userCoverers={userCoverers || []}
                                                interaction
                                                responses={responses}
                                            />
                                        </View>
                                    );
                                } catch {
                                    return null;
                                }
                            } else {
                                return (<FeedbackMedListItem data={item} lastItem={index === data.length - 1}/>);
                            }
                        }}
                    />
                ) : (<View style={{ paddingBottom: 50 }}><Stub.Feedbacks/></View>)
            }

        </Section.White>
    );
};

const ErrorPage = ({
    error,
    isLoading,
    action,
}) => (
    <ErrorStub error={error} support>
        <Box centered style={{ paddingHorizontal: 12 }}>
            <BigButton primary action={action} title="buttons.try_again" busy={isLoading}/>
        </Box>
    </ErrorStub>
);

const FeedbacksPageImpl = ({
    isAuthenticated,
    userInfo: { userId },
    feedbacks: {
        error,
        clinics: data,
        isLoading,
        isFeedbacksLoading,
    },
    isAccessNotAuthorizedError = (error && error.code === 'USER_NOT_REGISTERED'),
    forItemId,
    forItemKind,
    children,
}) => {
    // useFocusEffect(React.useCallback(() => {
    //     actions.resetFeedbacksError();
    //     // setTimeout(() => actions.fetchFeedbacks(forItemId, forItemKind), 10);
    //     actions.fetchFeedbacks(forItemId, forItemKind);
    //     // return actions.clearFeedbacks;
    // }, [forItemId, forItemKind]));
    useFocusEffect(React.useCallback(() => {
        actions.resetFeedbacksError();
        actions.fetchFeedbacks(forItemId, forItemKind);
    }, [forItemId, forItemKind]));
    return (
        <Page name="feedbacks">
            {error && !isAccessNotAuthorizedError
                ? (
                    <ErrorPage
                        error={error}
                        action={
                            () => {
                                console.log('Error and fetch 2', error);
                                actions.fetchFeedbacks(forItemId, forItemKind, false);
                            }
                        }
                        isLoading={isLoading || isFeedbacksLoading}
                    />
                )
                : (
                    <View style={{ flex: 1 }}>
                        <View accessibilityLabel="feedbacks_inner-top-block">
                            {children}
                        </View>
                        <FeedbacksList
                            data={data}
                            isLoading={isLoading || isFeedbacksLoading}
                            userId={userId}
                            isAuthenticated={isAuthenticated}
                            forItemKind={forItemKind}
                        />
                    </View>
                )
            }
        </Page>
    );
};

/**
 * List of feedback items for given entity.
 */
export const FeedbacksPage = entityData(getActualFeedbacks, FeedbacksPageImpl);
