// Animated Flatlist with header that has ability to hide smothy

import { Animated, Keyboard, Platform } from 'react-native';
import {
    FlatList, PureComponent, React, Stub, View,
} from '../../common';
import { searchByKeywordList as styles } from './styles';

const AnimatedFlatListCore = Animated.createAnimatedComponent(FlatList);

export class AnimatedFlatlist extends PureComponent {
    constructor(props) {
        super(props);
        const {
            itemVisiblePercentThreshold = 1,
            headerSummary = 0,
        } = this.props;
        this.state = {
            animatedValue: new Animated.Value(0),
            headerHeight: headerSummary,
        };
        this.onViewableItemsChanged = this.onViewableItemsChanged.bind(this);
        this.viewabilityConfig = { itemVisiblePercentThreshold };
    }

    componentWillReceiveProps(props) {
        const { headerSummary = 0 } = props;
        if (headerSummary > 0) {
            this.setState({
                ...this.state,
                headerHeight: headerSummary,
            });
        }
    }

    onViewableItemsChanged = ({ viewableItems }) => {
        const { onViewableItemsChangedProcessor } = this.props;
        if (onViewableItemsChangedProcessor) {
            onViewableItemsChangedProcessor(viewableItems);
        }
    };

    render() {
        const {
            data,
            renderItem,
            extraData,
            style,
            headerComponents,
            buttonLeft,
            keyExtractor,
            type,
        } = this.props;

        const { headerHeight } = this.state;
        const translateY = this.state.animatedValue.interpolate({
            inputRange: [0, headerHeight],
            outputRange: [0, -headerHeight],
            extrapolate: 'clamp',
        });
        return (
            <View style={styles.container}>
                <View style={{
                    flexDirection: 'row',
                    flex: 1,
                }}>
                    {buttonLeft}
                    <AnimatedFlatListCore
                        accessibilityLabel="list"
                        data={data}
                        extraData={extraData}
                        onViewableItemsChanged={this.onViewableItemsChanged}
                        viewabilityConfig={this.viewabilityConfig}
                        onMomentumScrollBegin={() => {
                            Keyboard.dismiss();
                        }}
                        contentContainerStyle={{
                            marginTop: headerHeight,
                            paddingBottom: headerHeight - 1,
                        }}
                        scrollEventThrottle={16}
                        onScroll={Animated.event(
                            [{ nativeEvent: { contentOffset: { y: this.state.animatedValue } } }],
                            Platform.OS === 'web' ? {} : { useNativeDriver: true },
                        )}
                        renderItem={renderItem}
                        keyExtractor={keyExtractor}
                        style={[{ flex: 1 }, style]}
                        ListEmptyComponent={(<Stub type={`empty-${type || 'default'}`}/>)}
                    />
                </View>
                <Animated.View
                    style={[styles.headerWrapper, { transform: [{ translateY }] }]}
                    onLayout={(event) => {
                        const { height } = event.nativeEvent.layout;
                        if (height > 0) {
                            this.setState({
                                ...this.state,
                                headerHeight: height,
                            });
                        }
                    }}
                >
                    {headerComponents}
                </Animated.View>
            </View>
        );
    }
}
