import LogoFull from '../assets/images/logo-full.png';
import { COLORS } from '../styles/colors';
import {
    ActivityIndicator, Image, React, Text, View,
} from './react';

export const PageSpinner = ({ withLogo = true }) => {
    return (
        <View style={{
            flex: 1,
            flexDirection: 'column',
        }}>
            <View style={{
                flex: 3,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {withLogo ? <Image source={LogoFull} style={{
                    width: 200,
                    height: 200,
                }}/> : <View style={{
                    width: 200,
                    height: 200,
                }}/>}

            </View>
            <View style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <ActivityIndicator size="large" color={COLORS.DARK_GRAY} animating={true}/>
                <Text style={{
                    marginTop: 16,
                    color: COLORS.DARK_GRAY,
                }}>{Object.R('titles.loading')}</Text>
            </View>
        </View>
    );
};
