import { arrayToHash, isEmpty } from '../utils';
import { getUserMyPrograms, getUserPrograms, getUserRelatives } from './getters';
import { createKeyedSelector, createSelector, prefGettr } from './utils';
import { EMPTY_OBJECT } from './const';

const DEFAULT_PROGRAM = () => ({
    id: '*',
    name: Object.R('corporatePrograms.no'),
    branches: [],
});
export const getRelatedPrograms = createKeyedSelector(
    getUserPrograms,
    getUserMyPrograms,
    getUserRelatives,
    (programs, myPrograms, relatives = []) => {
        if (isEmpty(programs)) {
            return EMPTY_OBJECT;
        }

        const filterFn = program => myPrograms?.some(e => e.id === program.id) ||
            relatives.some(e => e.programId === program.id);
        const filteredPrograms = arrayToHash(Object.values(programs)
            .filter(filterFn));
        const defaultProgram = (myPrograms?.length > 0 && (!relatives.some(({ isVisitsManageable }) => isVisitsManageable) ||
            !relatives.some(({
                programId,
                isVisitsManageable,
            }) => isVisitsManageable && !programId)))
            ? undefined
            : { default: DEFAULT_PROGRAM() };
        return { ...defaultProgram, ...filteredPrograms };
    },
);

export const getCurrentProgram = createSelector(
    getRelatedPrograms,
    prefGettr('program'),
    (programs, current) =>
        // have to actualize currently saved program with the list of related ones.
        // eslint-disable-next-line implicit-arrow-linebreak
        (programs && current ? programs[current.id === '*' ? 'default' : current.id] : undefined),
);

export const getHasOnlyChildProgram = createSelector(
    getRelatedPrograms,
    getUserMyPrograms,
    (programs, myPrograms) => !(myPrograms?.length > 0) && Object.values(programs).length === 2,
);

export const getCurrentOrFirstProgram = createSelector(
    getRelatedPrograms,
    prefGettr('program'),
    getHasOnlyChildProgram,
    (programs, current, hasOnlyChildProgram) => {
        // have to actualize currently saved program with the list of related ones.
        if (!programs) {
            return undefined;
        }
        // eslint-disable-next-line consistent-return
        return (current && programs[current.id === '*' ? 'default' : current.id]) || (hasOnlyChildProgram ? Object.values(programs)
            .find(e => e.id !== '*') : Object.values(programs)[0]);
    },
);

export const getCurrentProgramId = createSelector(getCurrentProgram, (program = {}) => program?.id);

