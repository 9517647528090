import { personId1, personId2, personId3, personId4, lastModified } from '../const.stub';

export const defaultStorage = {
    firebase_ts: lastModified,
    db_version: 2, // skip dump
    prefs: {},
    userVisitsList: [
        { drugs: [], attachments: ['image_path'], id: 4569681, profileId: personId1, timeslotId: 5184239, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: [], id: 4569682, profileId: personId1, timeslotId: 5184239, startDate: '2019-01-02T06:30:00Z', endDate: '2019-01-02T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API', covererCode: 'epam' },
        { drugs: [], attachments: ['image_path'], id: 4569685, profileId: personId1, timeslotId: 51420057, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: ['image_path'], id: 4569681, profileId: personId2, timeslotId: 5184239, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: [], id: 4569682, profileId: personId2, timeslotId: 5184239, startDate: '2019-01-02T06:30:00Z', endDate: '2019-01-02T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: ['image_path'], id: 4569685, profileId: personId2, timeslotId: 51420057, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: ['image_path'], id: 4569681, profileId: personId3, timeslotId: 5184239, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: [], id: 4569682, profileId: personId3, timeslotId: 5184239, startDate: '2019-01-02T06:30:00Z', endDate: '2019-01-02T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: ['image_path'], id: 4569685, profileId: personId3, timeslotId: 51420057, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: ['image_path'], id: 4569681, profileId: personId4, timeslotId: 5184239, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
        { drugs: [], attachments: [], id: 4569682, profileId: personId4, timeslotId: 5184239, startDate: '2019-01-02T06:30:00Z', endDate: '2019-01-02T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API', covererCode: 'bns' },
        { drugs: [], attachments: ['image_path'], id: 4569685, profileId: personId4, timeslotId: 51420057, startDate: '2019-01-01T06:30:00Z', endDate: '2019-01-01T07:00:00Z', physicianId: '5b51794c7abf0bb3b', assignmentId: '5b51794ce0f569ad5', branchId: '5b3f5fa7eaf998e58', notes: 'Кабинет 237, этаж 1, № 237', status: 'reserved', policyId: '118047', providerId: 'lode-minsk', physicianExternalId: '1081', clinicExternalId: '2562', sourceName: 'LODE API' },
    ],
    selections: {},
    didOpenWNModal_relatives: true,
};

export const filledPrefsStorage = {
    ...defaultStorage,
    prefs: {
        drugsSearch: [
            {
                key: 'АСПАРАГИНАЗА',
                count: 2,
                subs: [{ ls_num: '1074', ls_name: 'АСПАРАГИНАЗА МЕДАК', tar_name: 'пор инъекц 10000МЕ N5', firm_name: 'Медак', country_name: 'Япония', otc_rx: 'RX', nlec_name: 'Аспарагиназа', price_min: '992.40', price_max: '1022.50', apt_cnt: '2', id: '1074', key: 'аспарагиназа медак', name: 'АСПАРАГИНАЗА МЕДАК', requirePrescript: 'По рецепту', producer: 'Медак', country: 'Япония', components: 'Аспарагиназа', priceMin: 992.4, priceMax: 1022.5, pricingText: 'от 992.40 до 1022.50 руб', tar: 'пор инъекц 10000МЕ N5' },
                    { ls_num: '1076', ls_name: 'АСПАРАГИНАЗА МЕДАК', tar_name: 'пор инъекц 5000МЕ N5', firm_name: 'Медак', country_name: 'Япония', otc_rx: 'RX', nlec_name: 'Аспарагиназа', price_min: '793.90', price_max: '818.00', apt_cnt: '3', id: '1076', key: 'аспарагиназа медак', name: 'АСПАРАГИНАЗА МЕДАК', requirePrescript: 'По рецепту', producer: 'Медак', country: 'Япония', components: 'Аспарагиназа', priceMin: 793.9, priceMax: 818, pricingText: 'от 793.90 до 818.00 руб', tar: 'пор инъекц 5000МЕ N5' }],
                id: 'АСПАРАГИНАЗА',
                name: 'АСПАРАГИНАЗА (2)',
            },
            {
                key: 'АСПИРИН',
                count: 3,
                subs: [{ ls_num: '16104', ls_name: 'АСПИРИН КАРДИО', tar_name: 'таб п/о 100мг N56', firm_name: 'Байер Консьюмер', country_name: 'Швейцария', otc_rx: 'OTC', nlec_name: 'Ацетилсалициловая к-та', price_min: '7.86', price_max: '11.56', apt_cnt: '614', id: '16104', key: 'аспирин кардио', name: 'АСПИРИН КАРДИО', requirePrescript: 'Без рецепта', producer: 'Байер Консьюмер', country: 'Швейцария', components: 'Ацетилсалициловая к-та', priceMin: 7.86, priceMax: 11.56, pricingText: 'от 7.86 до 11.56 руб', tar: 'таб п/о 100мг N56' },
                    { ls_num: '16271', ls_name: 'АСПИРИН КАРДИО', tar_name: 'таб п/о 100мг N28', firm_name: 'Байер Консьюмер', country_name: 'Швейцария', otc_rx: 'OTC', nlec_name: 'Ацетилсалициловая к-та', price_min: '4.11', price_max: '6.47', apt_cnt: '600', id: '16271', key: 'аспирин кардио', name: 'АСПИРИН КАРДИО', requirePrescript: 'Без рецепта', producer: 'Байер Консьюмер', country: 'Швейцария', components: 'Ацетилсалициловая к-та', priceMin: 4.11, priceMax: 6.47, pricingText: 'от 4.11 до 6.47 руб', tar: 'таб п/о 100мг N28' },
                    { ls_num: '1111', ls_name: 'АСПИРИН С', tar_name: 'таб шип 400мг/240мг N10', firm_name: 'Байер Консьюмер', country_name: 'Швейцария', otc_rx: 'OTC', nlec_name: 'Ацетилсалициловая к-та+аскорбиновая к-та', price_min: '6.92', price_max: '10.38', apt_cnt: '679', id: '1111', key: 'аспирин с', name: 'АСПИРИН С', requirePrescript: 'Без рецепта', producer: 'Байер Консьюмер', country: 'Швейцария', components: 'Ацетилсалициловая к-та+аскорбиновая к-та', priceMin: 6.92, priceMax: 10.38, pricingText: 'от 6.92 до 10.38 руб', tar: 'таб шип 400мг/240мг N10' }],
                id: 'АСПИРИН',
                name: 'АСПИРИН (3)',
            },
        ],
        basket: [
            { ls_num: '1074', ls_name: 'АСПАРАГИНАЗА МЕДАК', tar_name: 'пор инъекц 10000МЕ N5', firm_name: 'Медак', country_name: 'Япония', otc_rx: 'RX', nlec_name: 'Аспарагиназа', price_min: '992.40', price_max: '1022.50', apt_cnt: '2', id: '1074', key: 'аспарагиназа медак', name: 'АСПАРАГИНАЗА МЕДАК', requirePrescript: 'По рецепту', producer: 'Медак', country: 'Япония', components: 'Аспарагиназа', priceMin: 992.4, priceMax: 1022.5, pricingText: 'от 992.40 до 1022.50 руб', tar: 'пор инъекц 10000МЕ N5' },
            { ls_num: '17794', ls_name: 'АСПИКАРД', tar_name: 'таб п/о 75мг N30', firm_name: 'Борисовский ЗМП', country_name: 'Беларусь', otc_rx: 'OTC', nlec_name: 'Ацетилсалициловая к-та', price_min: '0.99', price_max: '1.37', apt_cnt: '413', id: '17794', key: 'аспикард', name: 'АСПИКАРД', requirePrescript: 'Без рецепта', producer: 'Борисовский ЗМП', country: 'Беларусь', components: 'Ацетилсалициловая к-та', priceMin: 0.99, priceMax: 1.37, pricingText: 'от 0.99 до 1.37 руб', tar: 'таб п/о 75мг N30' },
            { ls_num: '17413', ls_name: 'РЕНЮ МПС', tar_name: 'р-р 240мл N1 + конт. N1', firm_name: 'Бауш энд Ломб Инк', country_name: 'США', otc_rx: 'OTC', nlec_name: '~', price_min: '11.79', price_max: '17.51', apt_cnt: '469', id: '17413', key: 'реню мпс', name: 'РЕНЮ МПС', requirePrescript: 'Без рецепта', producer: 'Бауш энд Ломб Инк', country: 'США', components: '~', priceMin: 11.79, priceMax: 17.51, pricingText: 'от 11.79 до 17.51 руб', tar: 'р-р 240мл N1 + конт. N1' }
        ],
        documents: [
            { id: 1, path: 'image_path', uri: 'image_path' }
        ],
    },
};
