import WebView from 'react-native-webview';
import { Page, React, View } from '../../common';
import { imageViewer as styles } from './styles';

export const WebPage = Page.register(({
    source,
    navigation,
}) => {
    React.useEffect(() => {
        navigation.setOptions({ title: navigation.getParam('title') });
    }, []);
    return (
        <View style={styles.view}>
            <WebView
                originWhitelist={['*']}
                source={source}
                style={styles.image}
                onError={(syntheticEvent) => {
                    const { nativeEvent } = syntheticEvent;
                    // eslint-disable-next-line no-console
                    console.error('WebView error: ', nativeEvent);
                }}
                onLoad={(syntheticEvent) => {
                    const { nativeEvent } = syntheticEvent;
                    // eslint-disable-next-line no-console
                    console.log(nativeEvent.url);
                }}
            />
        </View>
    );
});
