import createRoutes from './remote.routes';
import mockFetchBlob from './fetchBlob.mock';
import { filledPrefsStorage } from './data/localstorage.dump';
import { firebase } from './firebase.mock';

/* eslint-disable no-return-assign */
export const setupMock = (profile) => {
    Object.fetchBlob = mockFetchBlob(filledPrefsStorage, createRoutes());
    Object.firebase = firebase;
    Object.inTesting = true;

    firebase.__setAuthUserToken(profile);
};