import posed from 'react-native-pose';
import { React } from '../../common';

const PosedOpacitier = posed.View({
    visible: { opacity: 1 },
    hidden: { opacity: 0.35 },
});

export const Opacitier = ({
    children,
    poseController,
}) => (
    <PosedOpacitier
        pose={poseController ? 'visible' : 'hidden'}
    >
        {children}
    </PosedOpacitier>
);
