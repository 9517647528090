import { prepareNameForEdit } from '../utils/strings';
import {
    getEndOfLife, getInAppUpdateAndroid, getInAppUpdateIOS, getInAppUpdatePeriod, getUser, getUserError, getUserInfo, getUserInfoError, getUserLoadingState, getUserPersonCards,
} from './getters';
import { createSelector } from './utils';
import { EMPTY_ARRAY } from './const';
import {
    getIsAuthenticated, getIsReportsEnable, getIsTestsEnable, getMainScreenSettings,
} from '.';

/**
 * User and account.
 */

export const getUserInfoFormData = createSelector(
    [getUserInfo],
    ({
        name = '',
        birthday = '',
        sex = '',
        n: [lastName = '', firstName = '', middleName = ''] = name.split(' '),
        emails = [],
    }) => ({
        birthday,
        sex,
        lastName: prepareNameForEdit(lastName),
        firstName: prepareNameForEdit(firstName),
        middleName: prepareNameForEdit(middleName),
        name,
        email: emails.find(e => e.usage === 'PRIMARY')?.address,
    }),
);

export const getUserMainInfo = createSelector([getUserInfo], ({
    emails = [], birthday, name, me,
}) => ({
    userEmail: emails.find(e => e.usage === 'PRIMARY')?.address,
    userBirthday: birthday,
    userFullNameRu: name,
    userProfileId: me?.profileId,
    userHasProgram: !!me?.programs?.length,
}));

export const getUserInteraction = createSelector(
    [getUserInfoError, getUserLoadingState, getUserError],
    /* eslint-disable complexity */
    (userError, isLoading, error) => ({
        isLoading,
        error: userError || error,
    }),
);

export const getUserInteractionError = createSelector([getUserInteraction], ({ error }) => error);

export const getRelativesSpouse = createSelector([getUserInfo], ({ relatives = EMPTY_ARRAY }) => relatives.find(e => e.isOwner || e.isSpouse));

export const getIsPersonalized = createSelector([getUserInfo], ({ isPerson }) => isPerson);

export const getNeedInAppUpdates = createSelector(
    [getInAppUpdateAndroid, getInAppUpdateIOS, getInAppUpdatePeriod],
    (android, ios, period) => ({
        android,
        ios,
        period,
    }),
);

export const getEndOfLifeApp = createSelector([getEndOfLife], state => state);

export const getIsUserFetching = store => store.user && store.user.isFetching;

export const isProfileEmpty = createSelector([getUser], (user) => {
    if (!user || user?.isAuthenticated !== true || !user?.info || user?.isLoading || user?.isLoading === undefined) {
        return null;
    }
    const { info } = user;
    return !(!!info?.emails?.length && !!info?.birthday);
});

export const isProfileHaveRelatives = createSelector(getUser, (user) => {
    return !!user?.info?.relatives?.length;
});

export const getIsEmailUnconfirmed = createSelector([getUser], (user) => {
    if (!user || user?.isAuthenticated !== true || !user?.info || user?.isLoading || user?.isLoading === undefined) {
        return null;
    }
    const { info } = user;
    return (info?.emails || [{ confirmed: false }]).some(email => !email.confirmed);
});

export const getUserCards = createSelector([getUserPersonCards], state => state ?? []);

export const getIsReportsOrTestsEnable = createSelector(
    [getIsTestsEnable, getIsReportsEnable],
    (tests, reports) => tests || reports,
);

export const getMainScreenOptions = createSelector([getIsAuthenticated, getMainScreenSettings], (isAuth, settings) => ({
    shouldDisplayFavorites: !isAuth || !settings || settings.some(e => e === 'favorites'),
    shouldDisplayPopularQueries: !isAuth || !settings || settings.some(e => e === 'popularQueries'),
}));

export const isShowEmailReconfirm = createSelector([getUser], u => u.showEmailReconfirm);

export const hasUnfilledRelatives = createSelector([getUserInfo], (info) => {
    return !!info?.relatives?.some?.(r => !r?.name);
});
