/* eslint-disable no-use-before-define */
import React from 'react';
import { getFormFields } from '../i18n';
import { arrayToHash } from '../utils';
import { COUNTRY_CODE, DEFAULT_PHONE_VALUE } from '../config';
import { resolveFieldType, validateByFieldRequired, validateByFieldType } from './fields';
import { Field, Text } from '.';

const renderFormField = (props) => (
    <Field
        title={props.title}
        obj={{ code: COUNTRY_CODE }}
        key={props.id}
        required={props.required}
        titleStyle={{
            fontSize: 10,
            color: '#C8C8C8',
        }}
        headerHint={props.headerHint}>
        {React.createElement(resolveFieldType(props.type), props)}
        {props.error && <Text style={{ color: '#DF655C' }}>{props.error}</Text>}
    </Field>
);
const onFieldChange = (data, meta, fieldId, onChange) => (newValue) => {
    const newData = {
        ...data,
        [fieldId]: newValue,
    };
    const notEmpty = meta
        .map((e) => ({
            ...e,
            required: validateByFieldRequired(e, newData[e.id]),
        }))
        .filter((e) => e.required);
    onChange({
        data: newData,
        isFormTouched: true,
        isFormValid: !notEmpty.length,
    });
};

const onCheckValidation = (data, meta, fieldId, checkValidation) => (newValue) => {
    const newData = {
        ...data,
        [fieldId]: newValue,
    };
    const nd = Object.entries(newData).reduce((acc, [key, value]) => {
        const md = meta.find((e) => e.id === key);
        if (md?.type === 'phone' && (value === DEFAULT_PHONE_VALUE || value === '')) {
            return acc;
        }
        return {
            ...acc,
            [key]: value,
        };
    }, {});
    checkFormFields(nd, meta, checkValidation);
};

export const checkFormFields = (data, meta, checkValidation) => {
    let metaObj = meta;
    if (typeof meta === 'string') {
        metaObj = getFormFields(meta);
    }
    const errors = metaObj
        .map((e) => ({
            ...e,
            error: validateByFieldType(e, data[e.id]),
            required: validateByFieldRequired(e, data[e.id]),
        }))
        .filter((e) => e.error);
    const notEmpty = metaObj
        .map((e) => ({
            ...e,
            required: validateByFieldRequired(e, data[e.id]),
        }))
        .filter((e) => e.required);
    checkValidation({
        isFormValid: !errors.length && !notEmpty.length,
        errors: arrayToHash(errors, 'id', 'error'),
    });
};

export const Form = ({
    data = {},
    errors,
    meta,
    onChange,
    checkValidation,
    filteredFields = [],
    readOnlyFields = [],
    metaObject = getFormFields(meta).filter(({ id }) => !filteredFields.some((filteredId) => filteredId === id)),
    hints,
}) =>
    metaObject.map((e) =>
        renderFormField({
            ...e,
            value: data[e.id],
            onChange: onFieldChange(data, metaObject, e.id, onChange, meta),
            isFormValid: false,
            error: errors && errors[e.id],
            checkValidation: onCheckValidation(data, metaObject, e.id, checkValidation, meta),
            headerHint: hints[e.id],
            editable: !readOnlyFields.find((f) => f === e.id),
        }),
    );
