/* eslint-disable no-underscore-dangle */
import { useSelector } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { useRef } from 'react';
import {
    Page, React, SearchBar, Section,
} from '../../common';
import { getFromStoreDb, putIntoStore } from '../../store';
import {
    actualSplitDoctors,
    actualSplitDoctorsForClinics,
    actualSplitDoctorsForVisits,
    getActualPhysiciansAdvancedSearch,
    getActualPhysiciansClinicsAdvancedSearch,
    getActualPhysiciansVisitsAdvancedSearch,
    getClinicsDoctorsMap,
    getDoctorsMap,
    getDoctorsWithActualCollections,
    getDoctorsWithActualCollectionsClinic,
    getDoctorsWithActualCollectionsVisit,
    getFiltersAdditionalInfo,
    getFiltersAdditionalInfoClinic,
    getFiltersAdditionalInfoVisit,
    getHasFilterByClinic,
    getHasFilterByClinicClinic,
    getHasFilterByClinicVisit,
    getHasSpecialityFilter,
    getHasSpecialityFilterClinic,
    getHasSpecialityFilterVisit,
    getIsPhysiciansNotSet,
    getLocationAndSpec,
    getLocationAndSpecClinic,
    getLocationAndSpecVisit,
    getNumberOfActiveDoctorsClinicFilters,
    getNumberOfActiveDoctorsFilters,
    getNumberOfActiveDoctorsVisitFilters,
    getPhysiciansAdvancedSearch,
    getPhysiciansAdvancedSearchResults,
    getPhysiciansFilter,
    getPhysiciansFilterClinic,
    getPhysiciansFilterVisit,
    getPhysiciansForClinicAdvancedSearchResults,
    getPhysiciansForVisitAdvancedSearchResults,
    getPhysiciansIsLoading,
    getPhysiciansSearch,
    getPhysiciansSearchFromClinics,
    getPhysiciansSearchFromVisits,
    getSpecialitysDictionary,
    getSpecialitysDictionaryAllClinics,
    getSpecialitysDictionaryForClinic,
    getSpecialitysDictionaryForVisit,
    getSpecialitysDictionaryForVisitAllClinics,
    getUsePreviousDoctorsList,
    getVisitDoctorsMap,
    isHasTimetable,
    isHasTimetableClinic,
    isHasTimetableVisit,
    physiciansPrefilterDates,
    physiciansPrefilterDatesClinic,
    physiciansPrefilterDatesVisit,
    physiciansPrefilterFeedbacks,
    physiciansPrefilterFeedbacksClinic,
    physiciansPrefilterFeedbacksVisit,
    physiciansPrefilterProfiles,
    physiciansPrefilterProfilesClinic,
    physiciansPrefilterProfilesVisit,
    physiciansPrefilterSpecialties,
    physiciansPrefilterSpecialtiesClinic,
    physiciansPrefilterSpecialtiesVisit,
    physiciansPrefilterSpecialty,
    physiciansPrefilterSpecialtyClinic,
    physiciansPrefilterSpecialtyVisit,
    physiciansPrefilterTimes,
    physiciansPrefilterTimesClinic,
    physiciansPrefilterTimesVisit,
    physiciansPrefilterWorksAt,
    physiciansPrefilterWorksAtClinic,
    physiciansPrefilterWorksAtGroup,
    physiciansPrefilterWorksAtGroupClinic,
    physiciansPrefilterWorksAtGroupVisit,
    physiciansPrefilterWorksAtVisit,
    physiciansRecentSpecialtyRequests,
    physiciansRecentSpecialtyRequestsClinic,
    physiciansRecentSpecialtyRequestsVisit,
} from '../../selectors';
import { curry } from '../../utils';
import { SpecialtiesList } from '../../common/Specialties';
import { PageSpinner } from '../../common/PageSpinner';
import { SCHEME } from '../../scheme.js';
import firebaseService from '../../services/firebase';
import { fetchOnlyPhysicians } from './utils';
import { DoctorsSearchResults } from './DoctorsSearch';
import { OldDoctorsList } from './DoctorsList';

const NS = 'doctors';

const onSearch = (scheme = SCHEME.PHYSICIANS_ADVANCED_SEARCH) => curry(putIntoStore, scheme);

const TheSearchBar = ({
    searchData, initialValue = '', onSearch: _onSearch, placeholder, style,
}) => (
    <SearchBar
        autoCorrect={false}
        initialValue={searchData || initialValue}
        searchAfter={2}
        onSearch={_onSearch}
        placeholder={placeholder}
        borderRadius={3}
        style={style}
    />
);

const DoctorsListHeader = ({
    source = getPhysiciansAdvancedSearch,
    searchScheme = SCHEME.PHYSICIANS_ADVANCED_SEARCH,
}) => {
    const searchData = useSelector(source);
    return (
        <TheSearchBar
            initialValue={''}
            searchData={searchData}
            onSearch={onSearch(searchScheme)}
            placeholder={Object.R('titles.doctorsSearchSpecialtyPlaceholder')}
        />
    );
};

export const doctorFilterParams = {
    MedCenterDetails: {
        name: 'MedCenterDetails',
        postfix: 'Clinic',
        hasFilter: getHasSpecialityFilterClinic,
        hasClinicFilter: getHasFilterByClinicClinic,
        scheme: SCHEME.PHYSICIANS_FILTER_FOR_CLINIC,
        sortScheme: SCHEME.PHYSICIANS_SORT_BY_CLINIC,
        searchTextScheme: SCHEME.PHYSICIANS_FROM_CLINICS_SEARCH,
        keyword: getActualPhysiciansClinicsAdvancedSearch,
        advancedSearch: getActualPhysiciansClinicsAdvancedSearch,
        advancedSearchResults: getPhysiciansForClinicAdvancedSearchResults,
        searchScheme: SCHEME.PHYSICIANS_CLINICS_ADVANCED_SEARCH,
        physiciansSearchScheme: SCHEME.PHYSICIANS_CLINICS_FROM_SEARCH,
        physicianSearch: getPhysiciansSearchFromClinics,
        doctorDataSource: actualSplitDoctorsForClinics,
        filter: getPhysiciansFilterClinic,
        specializationForApi: 'specializationForApiForClinic',
        getFiltersAddInfo: getFiltersAdditionalInfoClinic,
        getLocationAndSpec: getLocationAndSpecClinic,
        hasTimetableSource: isHasTimetableClinic,
        numberOfActiveFilters: getNumberOfActiveDoctorsClinicFilters,
        doctorsWithActualCollections: getDoctorsWithActualCollectionsClinic,
        specialitysDictionary: getSpecialitysDictionaryForClinic,
        specialitysDictionaryAllClinics: getSpecialitysDictionaryForClinic,
        // specialitysDictionaryAllClinics: getSpecialitysDictionaryForClinicAllClinics,
        doctorMap: getClinicsDoctorsMap,
        prefilter: {
            prefilter: SCHEME.PHYSICIANS_PREFILTER_CLINIC,
            dates: SCHEME.PHYSICIANS_PREFILTER_DATES_CLINIC,
            recentSpecRequest: SCHEME.PHYSICIANS_RECENT_SPECIALTY_REQUESTS_CLINIC,
            speciality: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY_CLINIC,
            worksAt: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_CLINIC,
            worksAtGroup: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_GROUP_CLINIC,
            profiles: SCHEME.PHYSICIANS_PREFILTER_PROFILES_CLINIC,
            feedback: SCHEME.PHYSICIANS_PREFILTER_FEEDBACKS_CLINIC,
            times: SCHEME.PHYSICIANS_PREFILTER_TIMES_CLINIC,
            getWorksAt: physiciansPrefilterWorksAtClinic,
            getTimes: physiciansPrefilterTimesClinic,
            getSpeciality: physiciansPrefilterSpecialtyClinic,
            getSpecialities: physiciansPrefilterSpecialtiesClinic,
            getWorksAtGroup: physiciansPrefilterWorksAtGroupClinic,
            getProfile: physiciansPrefilterProfilesClinic,
            getRecentSpecRequest: physiciansRecentSpecialtyRequestsClinic,
            getFeedback: physiciansPrefilterFeedbacksClinic,
            getDates: physiciansPrefilterDatesClinic,
        },
    },
    Visit: {
        name: 'Visit',
        postfix: 'Visit',
        hasFilter: getHasSpecialityFilterVisit,
        hasClinicFilter: getHasFilterByClinicVisit,
        scheme: SCHEME.PHYSICIANS_FILTER_FOR_VISIT,
        sortScheme: SCHEME.PHYSICIANS_SORT_BY_VISIT,
        searchTextScheme: SCHEME.PHYSICIANS_FROM_VISITS_SEARCH,
        keyword: getActualPhysiciansVisitsAdvancedSearch,
        advancedSearch: getActualPhysiciansVisitsAdvancedSearch,
        advancedSearchResults: getPhysiciansForVisitAdvancedSearchResults,
        searchScheme: SCHEME.PHYSICIANS_VISITS_ADVANCED_SEARCH,
        physiciansSearchScheme: SCHEME.PHYSICIANS_VISITS_FROM_SEARCH,
        physicianSearch: getPhysiciansSearchFromVisits,
        doctorDataSource: actualSplitDoctorsForVisits,
        filter: getPhysiciansFilterVisit,
        specializationForApi: 'specializationForApiForVisit',
        getFiltersAddInfo: getFiltersAdditionalInfoVisit,
        getLocationAndSpec: getLocationAndSpecVisit,
        hasTimetableSource: isHasTimetableVisit,
        numberOfActiveFilters: getNumberOfActiveDoctorsVisitFilters,
        doctorsWithActualCollections: getDoctorsWithActualCollectionsVisit,
        specialitysDictionary: getSpecialitysDictionaryForVisit,
        specialitysDictionaryAllClinics: getSpecialitysDictionaryForVisitAllClinics,
        doctorMap: getVisitDoctorsMap,
        prefilter: {
            prefilter: SCHEME.PHYSICIANS_PREFILTER_VISIT,
            dates: SCHEME.PHYSICIANS_PREFILTER_DATES_VISIT,
            recentSpecRequest: SCHEME.PHYSICIANS_RECENT_SPECIALTY_REQUESTS_VISIT,
            speciality: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY_VISIT,
            worksAt: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_VISIT,
            worksAtGroup: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_GROUP_VISIT,
            profiles: SCHEME.PHYSICIANS_PREFILTER_PROFILES_VISIT,
            feedback: SCHEME.PHYSICIANS_PREFILTER_FEEDBACKS_VISIT,
            times: SCHEME.PHYSICIANS_PREFILTER_TIMES_VISIT,
            getWorksAt: physiciansPrefilterWorksAtVisit,
            getTimes: physiciansPrefilterTimesVisit,
            getSpeciality: physiciansPrefilterSpecialtyVisit,
            getSpecialities: physiciansPrefilterSpecialtiesVisit,
            getWorksAtGroup: physiciansPrefilterWorksAtGroupVisit,
            getProfile: physiciansPrefilterProfilesVisit,
            getRecentSpecRequest: physiciansRecentSpecialtyRequestsVisit,
            getFeedback: physiciansPrefilterFeedbacksVisit,
            getDates: physiciansPrefilterDatesVisit,
        },
    },
    default: {
        name: 'default',
        postfix: '',
        hasFilter: getHasSpecialityFilter,
        hasClinicFilter: getHasFilterByClinic,
        scheme: SCHEME.PHYSICIANS_FILTER,
        sortScheme: SCHEME.PHYSICIANS_SORT_BY,
        searchTextScheme: SCHEME.PHYSICIANS_SEARCH,
        keyword: getActualPhysiciansAdvancedSearch,
        advancedSearch: getPhysiciansAdvancedSearch,
        advancedSearchResults: getPhysiciansAdvancedSearchResults,
        searchScheme: SCHEME.PHYSICIANS_ADVANCED_SEARCH,
        physiciansSearchScheme: SCHEME.PHYSICIANS_FROM_SERARCH,
        physicianSearch: getPhysiciansSearch,
        doctorDataSource: actualSplitDoctors,
        filter: getPhysiciansFilter,
        specializationForApi: 'specializationForApi',
        getFiltersAddInfo: getFiltersAdditionalInfo,
        getLocationAndSpec,
        hasTimetableSource: isHasTimetable,
        numberOfActiveFilters: getNumberOfActiveDoctorsFilters,
        doctorsWithActualCollections: getDoctorsWithActualCollections,
        specialitysDictionary: getSpecialitysDictionary,
        specialitysDictionaryAllClinics: getSpecialitysDictionaryAllClinics,
        doctorMap: getDoctorsMap,
        prefilter: {
            prefilter: SCHEME.PHYSICIANS_PREFILTER,
            dates: SCHEME.PHYSICIANS_PREFILTER_DATES,
            recentSpecRequest: SCHEME.PHYSICIANS_RECENT_SPECIALTY_REQUESTS,
            speciality: SCHEME.PHYSICIANS_PREFILTER_SPECIALTY,
            worksAt: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT,
            profiles: SCHEME.PHYSICIANS_PREFILTER_PROFILES,
            feedback: SCHEME.PHYSICIANS_PREFILTER_FEEDBACKS,
            times: SCHEME.PHYSICIANS_PREFILTER_TIMES,
            worksAtGroup: SCHEME.PHYSICIANS_PREFILTER_WORKS_AT_GROUP,
            getWorksAt: physiciansPrefilterWorksAt,
            getTimes: physiciansPrefilterTimes,
            getSpeciality: physiciansPrefilterSpecialty,
            getSpecialities: physiciansPrefilterSpecialties,
            getWorksAtGroup: physiciansPrefilterWorksAtGroup,
            getProfile: physiciansPrefilterProfiles,
            getRecentSpecRequest: physiciansRecentSpecialtyRequests,
            getFeedback: physiciansPrefilterFeedbacks,
            getDates: physiciansPrefilterDates,
        },
    },
};

const resetSpecialtyFilter = (filters, from = 'default') => {
    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    putIntoStore(filterParams.scheme, {
        ...filters,
        time: '0',
        dates: {},
        specialty: '',
    });
    putIntoStore(filterParams.physiciansSearchScheme, undefined);
    putIntoStore(filterParams.specializationForApi, undefined);
    // putIntoStore('specializationForApi', undefined);
};

const resetClinicFilter = (filters, from = 'default') => {
    const filterParams = doctorFilterParams[from] ?? doctorFilterParams.default;
    putIntoStore(filterParams.scheme, {
        ...filters,
        time: '0',
        dates: {},
        // specialty: '',
        // worksAt: [],
    });
    putIntoStore(filterParams.physiciansSearchScheme, undefined);
    putIntoStore(filterParams.specializationForApi, undefined);
    // putIntoStore('specializationForApi', undefined);
};

export const DoctorsListWithSpecialties = Page.register(({ params, navigation }) => {
    const isAuth = firebaseService.auth.currentUser;
    fetchOnlyPhysicians(isAuth);
    const filterParams = doctorFilterParams[params?.from] ?? doctorFilterParams.default;
    const hasSpecialtyFilter = useSelector(filterParams?.hasFilter);
    const hasClinicFilter = useSelector(filterParams?.hasClinicFilter);
    const isResetStarted = useRef(false);

    useFocusEffect(() => {
        if (params?.keyword) {
            putIntoStore(SCHEME.PHYSICIANS_ADVANCED_SEARCH, params?.keyword);
            navigation.setParams({ keyword: '' });
        }
    }, []);

    useFocusEffect(() => {
        if (hasSpecialtyFilter) {
            setTimeout(() => {
                const filters = getFromStoreDb(filterParams.scheme) || {};
                resetSpecialtyFilter(filters, params?.from);
            }, 0);
            // firebaseService?.unsubscribeFromSplitCollectionsChanges();
            // firebaseService?.resetSplitCollections();
        }
        if (hasClinicFilter && !params?.mdInfo && isResetStarted.current === false) {
            isResetStarted.current = true;
            setTimeout(() => {
                const filters = getFromStoreDb(filterParams.scheme) || {};
                resetClinicFilter(filters, params?.from);
                setTimeout(() => {
                    isResetStarted.current = false;
                }, 100);
            }, 0);
        }
    }, [hasSpecialtyFilter, hasClinicFilter]);
    const dictionary = useSelector(filterParams?.specialitysDictionaryAllClinics);
    const isLoading = useSelector(getPhysiciansIsLoading);
    const isPhysiciansNotSet = useSelector(getIsPhysiciansNotSet);
    const keyword = useSelector(filterParams?.keyword);
    return (
        <Page name={NS}>
            {
                // eslint-disable-next-line no-nested-ternary
                isLoading || isPhysiciansNotSet ? (
                    <PageSpinner />
                ) : (
                    <>
                        <DoctorsListHeader
                            source={filterParams.advancedSearch}
                            searchScheme={filterParams.searchScheme}
                        />
                        {keyword?.length > 2 ? (
                            <DoctorsSearchResults
                                keyword={keyword}
                                searchResultSource={filterParams.advancedSearchResults}
                            />
                        ) : (
                            <>
                                <Section
                                    title={Object.R('titles.specialtiesListTitle ')}
                                    capitalize
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                    }}
                                />
                                {dictionary?.length ? <SpecialtiesList data={dictionary} from={params?.from} /> : null}
                            </>
                        )}
                    </>
                )
            }
        </Page>
    );
});

export const DoctorsSpecialtiesSearch = ({ route: { params }, ...rest }) => {
    const usePreviosScreen = useSelector(getUsePreviousDoctorsList);
    return usePreviosScreen ? (
        <OldDoctorsList params={params} />
    ) : (
        <DoctorsListWithSpecialties params={{ ...params, ...rest }} />
    );
};
