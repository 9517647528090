/* eslint-disable no-underscore-dangle */
import { StackActions, useFocusEffect } from '@react-navigation/native';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    BigButton, Button, ButtonsGroup, DatePicker, List, Modal, Modal2, Page, PopupMessage, React, S, Section, Selector, TimePicker, TopNotification, View,
} from '../../common';
import {
    actualTimeslots, getIsAuthenticated, getUserInfo, getUserPersonId, getVisitInfoLoading, joinedClinics, memoizedFormatTimeslots,
} from '../../selectors';
import actions, { navigate } from '../../actions';
import {
    formatTime, formatTimezone, getTimeZoneDiffMinutes, isAdult, isEmpty,
} from '../../utils';
import { AppHeader, PhoneItem } from '../../combo';
import { getEnums } from '../../i18n';
import tracking from '../../services/tracking';
import { screenOptions } from '../../utils/routing';
import { getSelectedService, getServicesList } from '../../selectors/services';
import { ServiceDescription, ServiceSection } from '../../combo/ServiceSelector';
import { useServiceListSelector } from '../../hooks/services';
import { memoizeLastResult } from '../../selectors/utils';
import { fetchTimeslots, resetTimetable, updateVisit } from '../../actions/visitInfo';
import { PageSpinner } from '../../common/PageSpinner';

export const showNotification = (message, localize = true) => {
    const autocloseTimeout = setTimeout(() => {
        Page.closeModal();
    }, 10000);
    Page.showModal(
        <PopupMessage
            message={message}
            localizeMessage={localize}
            onClose={() => {
                clearTimeout(autocloseTimeout);
            }}
        />,
    );
};

const { setTimetable } = actions;

const getVisitTimeByTimetableId = (timetableId, timeslots) => {
    const el = timetableId && timeslots && timeslots.find(({ id }) => id === timetableId);
    return el ? formatTime(el.start, getTimeZoneDiffMinutes(el.start)) : null;
};
const memoizedGetVisitTimeByTimetableId = memoizeLastResult(getVisitTimeByTimetableId);

const FillUserInfo = ({
    mdInfo, replace, onNavigateToEdit, enroll,
}) => (
    <Modal title="titles.enter_info" subtitle="titles.nothing_about_you">
        <ButtonsGroup>
            <Button transparent title="buttons.later" action={() => replace('MedCenterRecord', { mdInfo })} />
            <Button
                primary
                title="buttons.fill"
                action={() => Page.closeModal(() => {
                    navigate('EditAccount', {
                        // eslint-disable-next-line no-undef
                        fromScreen: window._currentRouteName,
                        params: enroll,
                        mdInfo,
                        enroll,
                    });
                    onNavigateToEdit();
                })
                }
            />
        </ButtonsGroup>
    </Modal>
);

const PleaseSignInModal = ({
    navigation,
    visitInfo,
    canReassign,
    isEditingVisit,
    selectedTimeslot,
    canChangeDate,
    data = getEnums('preEnrollVisit'),
    onShowNumbers,
    doctorProfile,
}) => {
    let actualData = data;
    const forChild = doctorProfile?.some(({ isForChildren }) => isForChildren);
    const forAdult = doctorProfile?.some(({ isForChildren }) => !isForChildren);
    if (!forChild) {
        actualData = actualData.filter(e => e.id !== 'forChild');
    }
    if (!forAdult) {
        actualData = actualData.filter(e => e.id !== 'forRelative' && e.id !== 'forMe');
    }
    const [visiterId, setVisiterId] = useState(actualData?.length > 0 ? actualData[0].id : null);
    return (
        <Modal2
            title={Object.R('titles.attention')}
            subtitle={Object.R('titles.finish_enroll_visit_hint')}
            hideClose
            showCross>
            <View
                style={{
                    flexDirection: 'column',
                    paddingHorizontal: 10,
                    paddingBottom: 20,
                }}>
                <Selector
                    radio
                    selected={visiterId}
                    data={actualData}
                    onSelect={item => setVisiterId(item.id)}
                    style={{ backgroundColor: 'transparent' }}
                />
                <Button
                    ns="submitButton"
                    primary
                    capitalize
                    title={Object.R('titles.registration')}
                    action={() => {
                        Page.closeModal();
                        tracking.logEvent(`ai_select_regflow_${visiterId}`);
                        Object.callbackOnSignIn = userId => navigation.replace('EnrollVisit', {
                            visitInfo: {
                                ...visitInfo,
                                profileId: userId,
                            },
                            canReassign,
                            isEditingVisit,
                            startDate: Date.parse(selectedTimeslot.start),
                            endDate: Date.parse(selectedTimeslot.end),
                            canChangeDate,
                            visiterId,
                        });
                        Object.preEnrollVisitRoute = data.find(e => visiterId === e.id)?.routeTo;
                        navigate('Account');
                    }}
                />
                <Button
                    transparent
                    capitalize
                    title={Object.R('buttons.registry_numbers')}
                    action={() => {
                        Page.closeModal();
                        onShowNumbers();
                    }}
                />
            </View>
        </Modal2>
    );
};

const PleaseConfirmDeviceModal = () => {
    const onSubmit = () => Page.closeModal(() => {
        // eslint-disable-next-line no-undef
        navigate('Account', { fromScreen: window._currentRouteName });
    });
    return (
        <Modal title="titles.personalDataConfirmTitle" subtitle="titles.personalDataConfirmSubtitle">
            <BigButton primary title="buttons.openProfile" action={onSubmit} />
        </Modal>
    );
};

/**
 * Visit DatePick page.
 */

const getSelectedTimeslots = (haveTimeslots, timeslots, timetableId) => {
    return haveTimeslots ? timeslots.find(({ id }) => id === timetableId) || {} : {};
};

const getSelectedTimeslotsMemoized = memoizeLastResult(getSelectedTimeslots);
const getHaveTimeslots = timeslots => Object.keys(timeslots).length;
const getHaveTimeslotsMemoized = memoizeLastResult(getHaveTimeslots);
const getVisitDate = (formattedTimeslots, date) => (formattedTimeslots[date] ? date : Object.keys(formattedTimeslots)[0]);
const getVisitDateMemoized = memoizeLastResult(getVisitDate);

export const DatePick = Page.register(
    // eslint-disable-next-line complexity
    ({
        actualTimeslots: {
            timeslots,
            date,
            timetableId,
            error,
            initState,
            onlineBookingAllowed,
            declineReason,
            declineReasonCode,
        },
        isLoading,
        isAccessNotAuthorizedError = error && error.code === 'ACCESS_NOT_AUTHORIZED',
        isAuthenticated,
        userPersonId,
        userInfo: { birthday, accessNotAuthorized, waitingConfirmEmail },
        isServiceWarning = false,
        orderId,
        doctorInfo,
        mdInfo,
        doctorInfo: { _id: doctorId, notification: doctorNotification, profile },
        mdInfo: {
            id: clinicId,
            name,
            phones = [],
            assignmentId,
            // timezone = currentTimezone,
            notification: mcNotification,
            connectivityStatus,
            bookForServiceNativeSupport,
            phone = '',
        },
        formattedTimeslots = memoizedFormatTimeslots(timeslots),
        haveTimeslots = getHaveTimeslotsMemoized(formattedTimeslots), // Object.keys(formattedTimeslots).length,
        visitDate = getVisitDateMemoized(formattedTimeslots, date), // formattedTimeslots[date] ? date : Object.keys(formattedTimeslots)[0],
        covererCode,
        profileId = userPersonId,
        navigation,
        visitInfo = {
            timetableId,
            time: memoizedGetVisitTimeByTimetableId(timetableId, timeslots),
            doctorInfo,
            mdInfo,
            profileId,
            orderId,
            date: visitDate,
            assignmentId,
            covererCode,
        },
        selectedTimeslot = getSelectedTimeslotsMemoized(haveTimeslots, timeslots, timetableId), // haveTimeslots ? timeslots.find(({ id }) => id === timetableId) || {} : {},
        assignmentId: visitAssignmentId,
        canReassign,
        isEditingVisit,
        canChangeDate = true,
        titleTimePicker = `${Object.R('titles.pick_visit_time')}${
            getTimeZoneDiffMinutes(visitDate) ? ` ${formatTimezone(visitDate)}` : ''
        }`,
        setState: setStateLog,
        setState = (...stateParams) => {
            setStateLog(...stateParams);
        },
        shouldSkipRefreshOnFocus,
        isFocused,
        currentHint,
        route,
        showRegistryNumbers = false,
        clinics,
        selectedService,
        serviceId,
        servicesList,
        userTouch,
    }) => {
        const getDoctorClinic = useCallback(() => clinics[clinicId], [clinics, clinicId]);

        const getHint = useCallback(() => {
            if (birthday && !isAdult(birthday)) {
                return `error.onlineBooking/Forbidden7`;
            }
            if (declineReasonCode === 8) {
                return `error.onlineBooking/Forbidden8`;
            }
            if (connectivityStatus === 'unavailable' && !clinics[clinicId].onlineConnectivity) {
                return 'hint.online_booking_is_not_available';
            }
            if (!haveTimeslots || connectivityStatus === 'offline') {
                return 'hints.medcenter-details-record';
            }
            // Deсline reasons 9 and 10 will be handled on 'next' button press
            if (onlineBookingAllowed || declineReasonCode === 9 || declineReasonCode === 10) {
                return null;
            }
            return declineReason || `error.onlineBooking/Forbidden3`;
        }, [birthday, declineReason, connectivityStatus, onlineBookingAllowed, haveTimeslots, clinicId]);

        const dispatch = useDispatch();

        const onDate = d => dispatch(
            updateVisit({
                date: d,
                timetableId: undefined,
            }),
        );
        const onTime = setTimetable;

        const onSubmit = useCallback(() => {
            const visit = { ...visitInfo };
            if (!assignmentId) {
                visit.assignmentId = visitAssignmentId;
            }
            const enrollData = {
                visitInfo: visit,
                canReassign,
                isEditingVisit,
                startDate: Date.parse(selectedTimeslot.start),
                endDate: Date.parse(selectedTimeslot.end),
                canChangeDate,
                // eslint-disable-next-line no-undef
                fromScreen: window._currentRouteName,
                service: selectedService,
            };
            if (isAuthenticated) {
                if (declineReasonCode === 10 && !onlineBookingAllowed) {
                    Page.showModal(
                        <FillUserInfo
                            navigation={route?.params}
                            mdInfo={mdInfo}
                            enroll={enrollData}
                            replace={navigation.replace}
                            onNavigateToEdit={() => {
                                setState({ shouldSkipRefreshOnFocus: declineReasonCode !== 9 && declineReasonCode !== 10 });
                            }}
                        />,
                    );
                    return;
                }
                if (accessNotAuthorized) {
                    waitingConfirmEmail
                        ? showNotification(Object.R('titles.personalDataConfirmHint', { email: waitingConfirmEmail }))
                        : Page.showModal(<PleaseConfirmDeviceModal />);
                    return;
                }
                navigation.navigate('EnrollVisit', enrollData);
            } else {
                Page.showModal(
                    <PleaseSignInModal
                        navigation={navigation}
                        visitInfo={visitInfo}
                        canReassign={canReassign}
                        isEditingVisit={isEditingVisit}
                        selectedTimeslot={selectedTimeslot}
                        canChangeDate={canChangeDate}
                        onShowNumbers={() => setState({ showRegistryNumbers: true })}
                        doctorProfile={profile}
                    />,
                );
            }
        }, [
            visitInfo,
            canReassign,
            isEditingVisit,
            selectedTimeslot,
            canChangeDate,
            isAuthenticated,
            accessNotAuthorized,
            waitingConfirmEmail,
        ]);

        const footer = useCallback(
            !haveTimeslots ? null : (
                <View>
                    <View
                        style={[
                            S.padding,
                            {
                                backgroundColor: 'white',
                                borderTopColor: '#F0F0F0',
                                borderTopWidth: 1,
                                paddingTop: 0,
                            },
                        ]}>
                        {(onlineBookingAllowed || declineReasonCode === 9 || declineReasonCode === 10) &&
                        !doctorNotification &&
                        (!birthday || isAdult(birthday)) ? (
                            <BigButton
                                    title="buttons.further"
                                    disabled={
                                        !(timetableId && date) ||
                                    (!!bookForServiceNativeSupport &&
                                        !!servicesList?.[assignmentId || visitAssignmentId]?.length &&
                                        isEmpty(selectedService))
                                    }
                                    action={onSubmit}
                                />
                            ) : (
                                <BigButton
                                    title="buttons.registry_numbers"
                                    trackingParams={{
                                        docId: doctorId,
                                        journey: '',
                                    }}
                                    action={() => navigation.replace('MedCenterRecord', { mdInfo })}
                                />
                            )}
                    </View>
                </View>
            ),
            [
                haveTimeslots,
                onlineBookingAllowed,
                declineReasonCode,
                doctorNotification,
                birthday,
                timetableId,
                date,
                bookForServiceNativeSupport,
                servicesList,
                selectedService,
            ],
        );

        const reloadTimeslots = useCallback(
            (assignmentId_, physicianId) => {
                dispatch(
                    fetchTimeslots({
                        assignmentId: assignmentId_,
                        physicianId,
                    }),
                );
                dispatch(resetTimetable());
            },
            [dispatch, fetchTimeslots, resetTimetable],
        );

        useFocusEffect(
            React.useCallback(async () => {
                if (connectivityStatus !== 'online') {
                    if (!getDoctorClinic()?.onlineConnectivity) {
                        return () => false;
                    }
                }
                if (shouldSkipRefreshOnFocus) {
                    setState({
                        shouldSkipRefreshOnFocus: false,
                        isFocused: true,
                    });
                } else {
                    reloadTimeslots(
                        assignmentId ?? visitAssignmentId,
                        doctorId,
                        selectedService && selectedService?.duration,
                    );
                    setState({ isFocused: true });
                }
                return () => setState({ isFocused: false });
            }, [
                isAuthenticated,
                connectivityStatus,
                shouldSkipRefreshOnFocus,
                assignmentId,
                doctorId,
                birthday,
                setState,
            ]),
        );

        try {
            navigation?.dangerouslyGetParent?.()?.setOptions?.({
                ...screenOptions,
                header: ({ navigation: n }) => (
                    <AppHeader
                        right
                        backPress={() => {
                            if (showRegistryNumbers) {
                                setState({ showRegistryNumbers: false });
                                return;
                            }
                            n.dispatch(StackActions.pop());
                        }}
                    />
                ),
            });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error handle back press {DatePick}', e);
        }
        const hasServiceId = useCallback(() => serviceId && serviceId !== '0', [serviceId]);
        const list = useServiceListSelector(
            bookForServiceNativeSupport ? assignmentId || visitAssignmentId : undefined,
        );
        useEffect(() => {
            return () => {
                // eslint-disable-next-line no-undef
                if (window._currentRouteName !== 'EnrollVisit' && window._currentRouteName !== 'Account') {
                    actions.setSelectedService();
                }
            };
        }, []);
        useEffect(() => {
            if (hasServiceId() && !selectedService) {
                const service = servicesList?.[assignmentId || visitAssignmentId]?.find(e => e.id === serviceId);
                if (service) {
                    actions.setSelectedService(service);
                }
            }
        }, [serviceId, servicesList]);

        useEffect(() => {
            if (selectedService?.id) {
                dispatch(updateVisit({ timetableId: undefined }));
            }
        }, [selectedService?.id]);

        return (connectivityStatus !== 'online' && !getDoctorClinic()?.onlineConnectivity) || showRegistryNumbers ? (
            <Page
                name="medcenter-record"
                hint={!mcNotification && getHint()}
                noscroll
                onDidUpdate={() => {
                    if (getHint() !== currentHint) {
                        setState({ currentHint: getHint() });
                    }
                }}>
                {!mcNotification ? null : <TopNotification hint={mcNotification} />}
                <Section flex title="titles.registry" params={{ name }}>
                    <List
                        type="contacts"
                        // eslint-disable-next-line no-nested-ternary
                        data={phones?.length ? phones : phone ? phone?.split?.(';')?.map?.(i => i?.trim()) : []}
                        renderItem={PhoneItem}
                    />
                </Section>
            </Page>
        ) : (
            <Page
                name="doctor-record-date-picker"
                hint={isLoading || haveTimeslots ? null : getHint()}
                // isLoading={isLoading}
                notification={isAccessNotAuthorizedError ? null : error}
                onDidUpdate={() => {
                    if (!isFocused) {
                        return;
                    }
                    if (navigation.isFocused()) {
                        const hint = getHint();
                        if (!isLoading && haveTimeslots && hint !== currentHint) {
                            setState({ currentHint: hint });
                            if (hint && navigation.isFocused()) {
                                showNotification(hint);
                            }
                        }
                    } else {
                        setTimeout(() => {
                            const hint = getHint();
                            if (!isLoading && haveTimeslots && hint !== currentHint) {
                                setState({ currentHint: hint });
                                if (hint) {
                                    showNotification(hint);
                                }
                            }
                        }, 100);
                    }
                }}
                footer={footer}>
                {isLoading ? (
                    <PageSpinner withLogo={false} />
                ) : (
                    <>
                        {!mcNotification ? null : <TopNotification hint={mcNotification} />}
                        {!doctorNotification ? null : <TopNotification hint={doctorNotification} />}
                        {haveTimeslots && ((!!bookForServiceNativeSupport && !!list?.length) || hasServiceId()) ? (
                            <>
                                <Section
                                    title="titles.pick_service"
                                    style={{
                                        paddingHorizontal: 0,
                                        marginHorizontal: 0,
                                    }}>
                                    <View style={{ paddingHorizontal: 12 }}>
                                        <ServiceSection
                                            assignmentId={assignmentId || visitAssignmentId}
                                            onSelect={() => setState({ userTouch: !userTouch })}
                                            readOnly={hasServiceId()}
                                            isWarning={isServiceWarning}
                                        />
                                    </View>
                                </Section>
                                <ServiceDescription numberOfLines={3} />
                            </>
                        ) : null}
                        {!haveTimeslots ? null : (
                            <View>
                                <Section.White title="titles.pick_visit_date">
                                    <DatePicker
                                        timeslots={timeslots}
                                        value={visitDate}
                                        handleChooseDate={(...params) => {
                                            onDate(...params);
                                            if (!selectedService?.id) {
                                                setState({ isServiceWarning: true });
                                            }
                                        }}
                                        initState={initState}
                                        // timezone={timezone}
                                        userTouchEvent={userTouch}
                                    />
                                </Section.White>
                                <Section.White title={titleTimePicker} style={{ marginTop: 0 }}>
                                    <TimePicker
                                        timetableId={timetableId}
                                        // timezone={timezone}
                                        onChoose={(id, time) => {
                                            onTime(id, time);
                                            if (!selectedService?.id) {
                                                setState({ isServiceWarning: true });
                                            }
                                        }}
                                        chosenDate={visitDate}
                                        timeslots={timeslots}
                                        date={visitDate}
                                    />
                                </Section.White>
                            </View>
                        )}
                        {isLoading || haveTimeslots ? null : (
                            <>
                                <Section flex title="titles.registry" params={{ name }}>
                                    <List
                                        type="contacts"
                                        data={
                                            phones?.length
                                                ? phones
                                                : clinics?.[clinicId]?.phone?.split?.(';')?.map?.(i => i?.trim())
                                        }
                                        renderItem={PhoneItem}
                                    />
                                </Section>
                            </>
                        )}
                    </>
                )}
            </Page>
        );
    },
    {
        actualTimeslots: { from: actualTimeslots },
        isLoading: { from: getVisitInfoLoading },
        isAuthenticated: { from: getIsAuthenticated },
        userPersonId: { from: getUserPersonId },
        userInfo: { from: getUserInfo },
        clinics: { from: joinedClinics },
        selectedService: { from: getSelectedService },
        servicesList: { from: getServicesList },
    },
);
