import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    doctorsFeedbacks,
    doctorsOnlyWithPartnershipAndSums,
    getActualFeedbacks,
    getAdaptedClinics,
    getDisableUnconfirmedFeedback,
    getDisableUnconfirmedFeedbackOnlineOnly,
    getDoctorsOnlyWithPartnershipAndSums,
    getMyVotes,
} from '../selectors';
import { React } from '../common';
import { mergeFeedbacksToDoctor } from '../selectors/utils';
import { isAdult, isEmpty } from '../utils';
import { isHaveOfflineMedcenters } from '../screens/Feedbacks/Doctors/DoctorFeedbackContainer';
import actions from '../actions';
import { useMyVisits } from '../screens';
import firebase from '../services/firebase';

export const emptyFeedback = doctorId => ({
    neutralCount: 0,
    recommendCount: 0,
    notRecommendCount: 0,
    id: doctorId,
    _id: doctorId,
    anonFeedbackCount: 0,
    feedbackCount: 0,
    textFeedbackCount: 0,
});

const makeFeedbackInfo = feedbacksInfo => ({
    anonFeedbackCount: feedbacksInfo?.anonFeedbackCount,
    feedbacksCount: feedbacksInfo?.feedbacksCount,
    neutralCount: feedbacksInfo?.neutralCount,
    notRecommendCount: feedbacksInfo?.notRecommendCount,
    recommendCount: feedbacksInfo?.recommendCount,
    textFeedbackCount: feedbacksInfo?.textFeedbackCount,
});

export const usePhysiciansSum = (doctorId) => {
    const [data, setData] = useState(emptyFeedback);

    useEffect(() => {
        let handler;
        if (doctorId) {
            handler = firebase?.subscribeOnDocChanges?.('physicians_feedback_sum', doctorId, (snapshot) => {
                setData(snapshot?.data());
            });
        }
        return () => {
            handler && handler();
        };
    }, [doctorId]);
    return data;
};

// eslint-disable-next-line max-statements
export const useFeedbacks = (doctorId, fetchAllow = true) => {
    const [canGiveFeedback, setCanGiveFeedback] = useState(false);
    const disableUnconfirmed = useSelector(getDisableUnconfirmedFeedback);
    const disableUnconfirmedOnline = useSelector(getDisableUnconfirmedFeedbackOnlineOnly);
    const doctors = useSelector(getDoctorsOnlyWithPartnershipAndSums);
    const feedBacks = useSelector(doctorsFeedbacks);
    const richDoctor = doctors && doctors?.find(e => e.id === doctorId);
    const [doctor, setDoctor] = useState(richDoctor);
    const richWorksAt = useSelector(getAdaptedClinics); // seSelector(state => selectMedCenterDetails(state, clinicId));
    const myVotes = useSelector(getMyVotes);

    const {
        myVisits, hasVisits, hasVisitFeedbackPage, hasVisitDoctorPage, isAuthenticated, info,
    } =
        useMyVisits(doctorId);

    const feedbacks = useSelector(getActualFeedbacks);
    const lastLoadedFeedbacks = useRef('');
    const [myFeedbacks, setMyFeedbacks] = useState([]);

    const [votes, setVotes] = useState(emptyFeedback(doctorId));

    const feedbacksInfo = usePhysiciansSum(doctorId);

    useEffect(() => {
        if (feedbacksInfo && doctor) {
            const info1 = mergeFeedbacksToDoctor(doctor, feedbacksInfo);
            setDoctor(info1);
            setVotes(info1 ?? emptyFeedback(doctorId));
        }
    }, [feedbacksInfo, doctor]);

    const isCanGiveFeedback = (
        myFB,
        // pastsVisits
    ) => {
        if (!isAuthenticated || !info?.birthday || !isAdult(info?.birthday)) {
            return false;
        }
        const hasFeedback = !!myFB?.length;
        if (disableUnconfirmedOnline) {
            const hasOffline = isEmpty(doctor) ? false : isHaveOfflineMedcenters(doctor, richWorksAt);
            return hasOffline || hasVisitFeedbackPage || hasVisitDoctorPage || hasFeedback;
        }
        if (disableUnconfirmed) {
            return hasVisitDoctorPage || hasVisitFeedbackPage || hasFeedback;
        }
        return true;
    };

    useEffect(() => {
        if (!doctorId) {
            return;
        }

        let toString;

        try {
            toString = JSON.stringify(
                feedbacksInfo
                    ? {
                        feedbacksInfo: makeFeedbackInfo(feedbacksInfo),
                        doctorId,
                    }
                    : {
                        feedbacksInfo: makeFeedbackInfo(feedBacks?.[doctorId]),
                        doctorId,
                    },
            );
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Error get to string', e);
        }

        if (lastLoadedFeedbacks.current === '') {
            if (feedbacks?.feedbacks?.physicians && feedbacks?.feedbacks?.forItemId === doctorId) {
                lastLoadedFeedbacks.current = toString;
                return;
            }
        }
        if (lastLoadedFeedbacks.current === toString) {
            return;
        }
        lastLoadedFeedbacks.current = toString;
        fetchAllow &&
            actions.fetchFeedbacks(doctorId, 'physicians', true, (hasError) => {
                if (!hasError) {
                    // actions.fetchVotes();
                }
            });
        fetchAllow && actions.fetchVotes('physicians');

        // }
    }, [
        feedbacksInfo?.anonFeedbackCount,
        feedbacksInfo?.feedbacksCount,
        feedbacksInfo?.neutralCount,
        feedbacksInfo?.notRecommendCount,
        feedbacksInfo?.recommendCount,
        feedbacksInfo?.textFeedbackCount,
        doctorId,
        feedBacks?.[doctorId],
    ]);

    useEffect(() => {
        if (!feedbacks?.userInfo?.me?.profileId) {
            setMyFeedbacks([]);
            return;
        }
        const fbList = feedbacks?.feedbacks?.physicians ?? [];
        const my = fbList?.filter?.(
            ({ userProfileId }) => userProfileId === feedbacks?.userInfo?.me?.profileId ||
                feedbacks?.userInfo?.relatives?.find?.(
                    ({ relKind, profileId: childId }) => relKind === 'CHILD' && userProfileId === childId,
                ),
        );
        setMyFeedbacks(my);
    }, [feedbacks]);

    useEffect(() => {
        const canGiveFB = isCanGiveFeedback(myFeedbacks, myVisits);
        // if (canGiveFeedback !== canGiveFB) {
        setCanGiveFeedback(canGiveFB);
        // }
    }, [myVisits, myFeedbacks]);

    return {
        visits: myVisits,
        hasVisits,
        doctorId,
        hasVisitFeedbackPage,
        hasVisitDoctorPage,
        canGiveFeedback,
        isAuthenticated,
        info,
        isAdult: isAdult(info?.birthday),
        myFeedbacks,
        myVotes: myVotes?.physicians ?? {},
        votes,
        feedbacksInfo,
    };
};
