/* eslint-disable no-underscore-dangle */
import {
    BigButton,
    Checkbox,
    Field,
    Page,
    PureComponent,
    React,
    Section,
    showNotification,
    Stub,
    TextArea,
    Title,
    View,
} from '../../../common';
import { listItem as styles } from '../style';
import { getIsAuthenticated, getUserInfo } from '../../../selectors';
import actions, { back } from '../../../actions';
import { isAdult } from '../../../utils';

// navigation.getParam('name of param', 'default value'); param 'isEditFeedback' set just for EditFeedback route

/**
 * This page is to submit a user feedback for a medician or a medcenter.
 */
export const AddFeedbacks = Page.register(
    class AddFeedbacks extends PureComponent {
        state = {
            isFormValid: true,
            isEditFeedback: this.props.navigation.getParam('isEditFeedback', false),
            isSendingFeedback: false,
            anonymous: this.props.navigation.getParam('isEditFeedback', false) ? this.props.route.params.feedback.anonymous : true,
            text: this.props.navigation.getParam('feedback', {}).feedbackText || '',
        };

        _submit = () => {
            const {
                text,
                anonymous,
                isSendingFeedback,
            } = this.state;
            if (!text.trim()) {
                this.setState({ isFormValid: false });
                return;
            }
            if (isSendingFeedback) {
                return;
            }
            this.setState({ isSendingFeedback: true }, () => {
                this._post({
                    feedbackText: text.trim(),
                    anonymous,
                })
                    .then(() => {
                        showNotification(this.state.isEditFeedback ? 'titles.comment_has_been_edited' : 'titles.comment_has_been_added');
                    })
                    .catch(() => this.setState({ isSendingFeedback: false }));
            });
        };

        _post({
            feedbackText,
            anonymous,
        }) {
            const feedback = this.props.navigation.getParam('feedback', {});
            try {
                delete feedback.confirmed;
            } catch {
                //
            }
            return actions.modifyFeedback({
                ...feedback,
                feedbackText,
                anonymous,
            }, back);
        }

        renderForm() {
            const {
                text,
                anonymous,
                isFormValid,
                isLoading,
                isEditFeedback,
            } = this.state;
            const feedback = this.props.navigation.getParam('feedback', {});
            const { forItemKind } = feedback;
            const placeholderKey = `feedback.${forItemKind}InputPlaceholder`;
            const titleKey = isEditFeedback ? 'feedback.formEditTitle' : 'feedback.formTitle';
            const buttonTitleKey = isEditFeedback ? 'buttons.save_changes' : 'buttons.send_feedback';
            return (
                <Section>
                    <Title bold id={titleKey} style={styles.titleForm}/>
                    <Field ns="title-text">
                        <TextArea
                            invalid={!isFormValid}
                            onChange={(t) => {
                                let finalText = t;
                                if (t.length > 2048) {
                                    finalText = finalText.slice(0, 2048);
                                }
                                this.setState({ text: finalText });
                            }}
                            value={text}
                            placeholder={placeholderKey}
                            style={[styles.feedbackInput, {
                                outlineWidth: 0,
                                outline: 'none',
                            }]}
                        />
                    </Field>
                    <View style={styles.checkboxContainer}>
                        <Checkbox onPress={() => this.setState({ anonymous: !anonymous })} selected={!anonymous}
                            titleId="feedback.publicName"/>
                    </View>
                    <BigButton disabled={!text} busy={isLoading} action={this._submit} title={buttonTitleKey}/>
                </Section>
            );
        }

        render() {
            const {
                props: {
                    isAuthenticated,
                    userInfo,
                },
                state: {
                    isLoading,
                    error,
                    isSendingFeedback,
                },
            } = this;
            const children = isAuthenticated && isAdult(userInfo.birthday)
                ? this.renderForm()
                : <Stub.NotAuthorized/>;
            return (
                <Page
                    name="modify-feedback"
                    isLoading={isLoading || isSendingFeedback}
                    notification={error}
                >
                    {children}
                </Page>
            );
        }
    },
    // properties descriptors
    {
        isAuthenticated: { from: getIsAuthenticated },
        userInfo: { from: getUserInfo },
    },
);

