export default (dateStart, dateStart2 = dateStart) => [{
    id: 51420050,
    start: `${dateStart2}T15:00:00Z`,
    end: `${dateStart2}T15:20:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420051,
    start: `${dateStart2}T15:20:00Z`,
    end: `${dateStart2}T15:40:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420052,
    start: `${dateStart2}T15:40:00Z`,
    end: `${dateStart2}T16:00:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420053,
    start: `${dateStart2}T16:00:00Z`,
    end: `${dateStart2}T16:20:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420054,
    start: `${dateStart2}T16:20:00Z`,
    end: `${dateStart2}T16:40:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420055,
    start: `${dateStart2}T16:40:00Z`,
    end: `${dateStart2}T17:00:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420056,
    start: `${dateStart2}T17:00:00Z`,
    end: `${dateStart2}T17:20:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420057,
    start: `${dateStart2}T17:20:00Z`,
    end: `${dateStart2}T17:40:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420058,
    start: `${dateStart2}T20:40:00Z`,
    end: `${dateStart2}T20:59:00Z`,
    notes: '',
    status: 'available',
}, {
    id: 51420059,
    start: `${dateStart}T20:59:00Z`,
    end: `${dateStart}T21:20:00Z`,
    notes: '',
    status: 'available',
}];
