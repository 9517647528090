/* eslint-disable no-param-reassign,no-underscore-dangle */
// noinspection JSUnusedGlobalSymbols,JSUnusedLocalSymbols
import {
    arrayToHash, isEmpty, someEmpty, sortBy, sortDoctorByBumbaComparator,
} from '../utils';
import { SCHEME } from '../scheme';
import {
    createSelector,
    dbGettr,
    doctorSortOptions,
    getDateForJob,
    getPrices,
    mergeFeedbacksToDoctor,
    normSpecialtyString,
    prefGettr,
} from './utils';
import { getCurrentLocation } from './location';
import { doctorsWithPartnershipList } from './doctors';
import {
    filterBySearch,
    getFetching,
    getGlobalLocation,
    getPhysiciansFilter,
    getPhysiciansFilterClinic,
    getPhysiciansFilterVisit,
    getPhysiciansSearch,
    getPhysiciansSearchFromClinics,
    getPhysiciansSearchFromVisits,
} from '.';

const {
    PHYSICIANS_FILTER_SPECIALTY,
    PHYSICIANS_FILTER_SPECIALTY_CLINICS,
    PHYSICIANS_FILTER_SPECIALTY_VISITS,
    PHYSICIANS_FILTER_WORKS_AT,
    PHYSICIANS_FILTER_WORKS_AT_CLINIC,
    PHYSICIANS_FILTER_WORKS_AT_VISIT,
    PHYSICIANS_FILTER_PERIOD,
    PHYSICIANS_FILTER_PERIOD_VISIT,
    PHYSICIANS_FILTER_PERIOD_CLINIC,
    PHYSICIANS_FILTER_FAVORITES,
    PHYSICIANS_FILTER_PROFILES,
    PHYSICIANS_FILTER_PROFILES_VISIT,
    PHYSICIANS_FILTER_PROFILES_CLINIC,
    PHYSICIANS_FILTER_FEEDBACKS,
    PHYSICIANS_FILTER_FEEDBACKS_CLINIC,
    PHYSICIANS_FILTER_FEEDBACKS_VISIT,
    PHYSICIANS_SORT_BY,
    PHYSICIANS_SORT_BY_CLINIC,
    PHYSICIANS_SORT_BY_VISIT,
    PHYSICIANS_FROM_SERARCH,
    PHYSICIANS_CLINICS_FROM_SEARCH,
    PHYSICIANS_VISITS_FROM_SEARCH,
} = SCHEME;

export const getSpecialitysObj = createSelector([dbGettr('physicians.data')], (physicians = {}) => {
    const spec = arrayToHash(Object.values(physicians), 'specialization');
    const specialtiesKeys = Object.keys(spec);
    const specs = {};
    specialtiesKeys?.forEach((s) => {
        const sTitle = normSpecialtyString(s);
        specs[sTitle] = s;
    });
    return specs;
});

export const getLocationAndSpec = createSelector(
    [getPhysiciansFilter, getCurrentLocation, getSpecialitysObj],
    (filters, location, specObj) => {
        return {
            specialty: specObj[filters?.specialty],
            specialtyTitle: filters?.specialty,
            location: location?.code,
        };
    },
);

export const getLocationAndSpecClinic = createSelector(
    [getPhysiciansFilterClinic, getCurrentLocation, getSpecialitysObj],
    (filters, location, specObj) => {
        return {
            specialty: specObj[filters?.specialty],
            location: location?.code,
            specialtyTitle: filters?.specialty,
        };
    },
);

export const getLocationAndSpecVisit = createSelector(
    [getPhysiciansFilterVisit, getCurrentLocation, getSpecialitysObj],
    (filters, location, specObj) => {
        return {
            specialty: specObj[filters?.specialty],
            location: location?.code,
            specialtyTitle: filters?.specialty,
        };
    },
);

const getCollectionInfoInLocation = (state, collName) => {
    const { location, specialty } = getLocationAndSpec(state);
    return state.db[collName]?.[location]?.[specialty]?.data || {};
};

const getCollectionInfoInLocationClinic = (state, collName) => {
    const { location, specialty } = getLocationAndSpecClinic(state);
    return state.db[collName]?.[location]?.[specialty]?.data || {};
};

const getCollectionInfoInLocationVisit = (state, collName) => {
    const { location, specialty } = getLocationAndSpecVisit(state);
    return state.db[collName]?.[location]?.[specialty]?.data || {};
};

const getTimetableInLocation = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocation(state, 'physicians_nta');
        },
    ],
    (timetable) => {
        return timetable || {};
    },
);

const getTimetableInLocationClinic = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationClinic(state, 'physicians_nta');
        },
    ],
    (timetable) => {
        return timetable || {};
    },
);

const getTimetableInLocationVisit = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationVisit(state, 'physicians_nta');
        },
    ],
    (timetable) => {
        return timetable || {};
    },
);

const getTimetableSumByAssignmentInLocation = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocation(state, 'physicians_nta_sum');
        },
    ],
    (sum) => {
        return sum || {};
    },
);

const getTimetableSumByAssignmentInLocationClinic = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationClinic(state, 'physicians_nta_sum');
        },
    ],
    (sum) => {
        return sum || {};
    },
);

const getTimetableSumByAssignmentInLocationVisit = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationVisit(state, 'physicians_nta_sum');
        },
    ],
    (sum) => {
        return sum || {};
    },
);

const getFeedbackSumsInLocation = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocation(state, 'physicians_feedback_counters');
        },
    ],
    (feedbacks) => {
        return feedbacks || {};
    },
);

const getFeedbackSumsInLocationClinic = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationClinic(state, 'physicians_feedback_counters');
        },
    ],
    (feedbacks) => {
        return feedbacks || {};
    },
);

const getFeedbackSumsInLocationVisit = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationVisit(state, 'physicians_feedback_counters');
        },
    ],
    (feedbacks) => {
        return feedbacks || {};
    },
);

const getPricesSumInLocation = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocation(state, 'physicians_prices');
        },
    ],
    (sum) => {
        return sum || {};
    },
);

const getPricesSumInLocationClinic = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationClinic(state, 'physicians_prices');
        },
    ],
    (sum) => {
        return sum || {};
    },
);

const getPricesSumInLocationVisit = createSelector(
    [
        (state) => {
            return getCollectionInfoInLocationVisit(state, 'physicians_prices');
        },
    ],
    (sum) => {
        return sum || {};
    },
);

const prepareClinicsWithPricesInLocation = (pricesSum) => {
    if (someEmpty(pricesSum)) {
        return pricesSum;
    }
    const clinicsWithPrices = {};
    Object.values(pricesSum || {}).forEach((e) => {
        clinicsWithPrices[e._id] = getPrices(e);
    });
    return clinicsWithPrices;
};

const getClinicsWithPricesInLocation = createSelector([getPricesSumInLocation], pricesSum => prepareClinicsWithPricesInLocation(pricesSum));

const getClinicsWithPricesInLocationClinic = createSelector([getPricesSumInLocationClinic], pricesSum => prepareClinicsWithPricesInLocation(pricesSum));

const getClinicsWithPricesInLocationVisit = createSelector([getPricesSumInLocationVisit], pricesSum => prepareClinicsWithPricesInLocation(pricesSum));

const prepareDoctorsWithFeedbackSumsInLocation = (physicians, feedbackSum) => {
    if (someEmpty(physicians, feedbackSum)) {
        return physicians;
    }
    return physicians.map((e) => {
        return mergeFeedbacksToDoctor(e, feedbackSum?.[e._id], true);
    });
};

const doctorsWithFeedbackSumsInLocation = createSelector(
    [doctorsWithPartnershipList, getFeedbackSumsInLocation],
    (physicians, feedbackSum) => prepareDoctorsWithFeedbackSumsInLocation(physicians, feedbackSum),
);

const doctorsWithFeedbackSumsInLocationClinic = createSelector(
    [doctorsWithPartnershipList, getFeedbackSumsInLocationClinic],
    (physicians, feedbackSum) => prepareDoctorsWithFeedbackSumsInLocation(physicians, feedbackSum),
);

const doctorsWithFeedbackSumsInLocationVisits = createSelector(
    [doctorsWithPartnershipList, getFeedbackSumsInLocationVisit],
    (physicians, feedbackSum) => prepareDoctorsWithFeedbackSumsInLocation(physicians, feedbackSum),
);

const prepareNearestAvailableDatesInLocation = (timetableSum) => {
    if (someEmpty(timetableSum)) {
        return {};
    }
    const nearestAvailableDates = {};
    Object.values(timetableSum).forEach((e) => {
        nearestAvailableDates[e._id] = arrayToHash(e.nearest_available_dates, 'clinic_id', 'nearest_available_date');
    });
    return nearestAvailableDates;
};

const getnNarestAvailableDatesInLocation = createSelector([getTimetableInLocation], timetableSum => prepareNearestAvailableDatesInLocation(timetableSum));

const getnNarestAvailableDatesInLocationClinic = createSelector([getTimetableInLocationClinic], timetableSum => prepareNearestAvailableDatesInLocation(timetableSum));
const getnNarestAvailableDatesInLocationVisit = createSelector([getTimetableInLocationVisit], timetableSum => prepareNearestAvailableDatesInLocation(timetableSum));

const prepareIsHasTimetable = (fetchedCollections, info = {}) => {
    const { location, specialty } = info;
    return (
        fetchedCollections.physicians_timetable_sum ||
        fetchedCollections[`sync_all_physicians_nta_sum.${location}.${specialty}`] ||
        fetchedCollections[`sync_all_physicians_nta.${location}.${specialty}`]
    );
};

export const isHasTimetable = createSelector([getFetching, getLocationAndSpec], (fetchedCollections, info = {}) => prepareIsHasTimetable(fetchedCollections, info));

export const isHasUMSListTimetable = createSelector([getFetching, (_, data) => data], (fetchedCollections, info = {}) => prepareIsHasTimetable(fetchedCollections, info));

export const isHasTimetableClinic = createSelector(
    [getFetching, getLocationAndSpecClinic],
    (fetchedCollections, info = {}) => prepareIsHasTimetable(fetchedCollections, info),
);

export const isHasTimetableVisit = createSelector(
    [getFetching, getLocationAndSpecVisit],
    (fetchedCollections, info = {}) => prepareIsHasTimetable(fetchedCollections, info),
);

const prepareDoctorsWithSplitPartnershipAndSumsForLocal = (
    physicians,
    timetableSum,
    timetableSumByAssignment,
    pricesSum,
    isUpdated,
    locationCode,
    // eslint-disable-next-line max-params
) => {
    if (someEmpty(physicians, timetableSumByAssignment) || !isUpdated) {
        return physicians;
    }
    return physicians.map((e) => {
        const r = Object.create(e);
        r.nearestDate = null;
        r.priceRange = pricesSum[e._id] || {};
        const allWorksAt = [...e.worksAt];
        r.worksAt = allWorksAt.filter(({ areaCode }) => areaCode === locationCode);
        r.worksAt.forEach((job) => {
            const nearestJobDate = getDateForJob(e, job, timetableSum, timetableSumByAssignment);
            Object.assign(job, {
                nearestAvailableDate: nearestJobDate,
                priceRange: r.priceRange[job._id],
            });
            if (nearestJobDate && (!r.nearestDate || nearestJobDate < r.nearestDate)) {
                r.nearestDate = new Date(nearestJobDate.getTime());
            }
        });
        return r;
    });
};

export const doctorsWithSplitPartnershipAndSumsForLocal = createSelector(
    [
        doctorsWithFeedbackSumsInLocation,
        getnNarestAvailableDatesInLocation,
        getTimetableSumByAssignmentInLocation,
        getClinicsWithPricesInLocation,
        isHasTimetable,
        getGlobalLocation,
    ],
    // eslint-disable-next-line max-params
    (physicians, timetableSum, timetableSumByAssignment, pricesSum, isUpdated, locationCode) => prepareDoctorsWithSplitPartnershipAndSumsForLocal(
        physicians,
        timetableSum,
        timetableSumByAssignment,
        pricesSum,
        isUpdated,
        locationCode,
    ),
);

export const doctorsWithSplitPartnershipAndSumsForLocalClinic = createSelector(
    [
        doctorsWithFeedbackSumsInLocationClinic,
        getnNarestAvailableDatesInLocationClinic,
        getTimetableSumByAssignmentInLocationClinic,
        getClinicsWithPricesInLocationClinic,
        isHasTimetableClinic,
        getGlobalLocation,
    ],
    // eslint-disable-next-line max-params
    (physicians, timetableSum, timetableSumByAssignment, pricesSum, isUpdated, locationCode) => prepareDoctorsWithSplitPartnershipAndSumsForLocal(
        physicians,
        timetableSum,
        timetableSumByAssignment,
        pricesSum,
        isUpdated,
        locationCode,
    ),
);

export const doctorsWithSplitPartnershipAndSumsForLocalVisits = createSelector(
    [
        doctorsWithFeedbackSumsInLocationVisits,
        getnNarestAvailableDatesInLocationVisit,
        getTimetableSumByAssignmentInLocationVisit,
        getClinicsWithPricesInLocationVisit,
        isHasTimetableVisit,
        getGlobalLocation,
    ],
    // eslint-disable-next-line max-params
    (physicians, timetableSum, timetableSumByAssignment, pricesSum, isUpdated, locationCode) => prepareDoctorsWithSplitPartnershipAndSumsForLocal(
        physicians,
        timetableSum,
        timetableSumByAssignment,
        pricesSum,
        isUpdated,
        locationCode,
    ),
);

// eslint-disable-next-line complexity,max-statements
const prepareActualSplitDoctors = (
    physicians,
    search,
    specialty,
    worksAt,
    period,
    filterByFavorites,
    profiles,
    feedbacks,
    sort,
    locationCode,
    favorites = [],
    physiciansFromSearch,
    sortByBumba = true,
    // eslint-disable-next-line max-params
) => {
    if (isEmpty(physicians)) {
        return null;
    }
    let rr = physicians.filter(({ hidden } = {}) => !hidden);
    if (physiciansFromSearch && Array.isArray(physiciansFromSearch)) {
        rr = rr
            ?.filter(({ worksAt: pWorksAt = [] } = {}) => pWorksAt?.some(({ assignmentId: pAssignmentId } = {}) => physiciansFromSearch?.some(({ assignmentId }) => assignmentId === pAssignmentId)))
            ?.map((e = {}) => {
                const times = physiciansFromSearch
                    ?.filter(({ assignmentId } = {}) => (e.worksAt || [])?.some(
                        ({ assignmentId: pAssignmentId } = {}) => pAssignmentId === assignmentId,
                    ))
                    ?.map(({ firstTimeSlotAvail, assignmentId } = {}) => ({
                        nta: new Date(firstTimeSlotAvail).valueOf(),
                        assignmentId,
                    }));
                const minNta = times?.reduce((min, cur) => {
                    if (cur.nta < min.nta) {
                        return cur;
                    }
                    return min;
                });
                return Object.assign(Object.create(e), {
                    nearestDate: minNta.nta,
                    assignmentIdForNTA: minNta.assignmentId,
                });
            });
    }
    if (locationCode) {
        rr = rr.filter(e => (e.worksAt || []).some(({ areaCode }) => areaCode === locationCode));
    }
    let sortBySearch;
    if (search) {
        rr = filterBySearch(rr, search);
        sortBySearch = 'foundIn';
    }
    if (worksAt && worksAt.length) {
        rr = rr.filter(e => (e.worksAt || []).some(({ _id: mine }) => worksAt.some(their => mine === their)));
    }
    if (!Array.isArray(physiciansFromSearch) && specialty) {
        rr = rr.filter(({ specializations }) => specializations.some(e => e === specialty));
    }
    // if (period) {
    //     const limit = Date.now() + (period * 24 * 3600 * 1000);
    //     rr = rr.filter(({ nearestDate }) => !nearestDate || (nearestDate.valueOf() < limit));
    // }
    if (!isEmpty(filterByFavorites)) {
        rr = rr.filter(({ id }) => favorites.some(e => e.id === id));
    }
    if (profiles && Number(profiles)) {
        if (profiles === '1') {
            rr = rr.filter(({ profile }) => profile.some(({ isForChildren }) => !isForChildren));
        } else if (profiles === '2') {
            rr = rr.filter(({ profile }) => profile.some(({ isForChildren }) => isForChildren));
        }
    }
    if (feedbacks) {
        // TODO: textFeedbackCount ??
        rr = rr.filter(({ textFeedbackCount }) => Boolean(textFeedbackCount));
    }
    const sortId = sort && !sortBySearch ? sort.id : sortBySearch || doctorSortOptions()[0].id;

    const sortExpr = sort && !sortBySearch ? sort.expr : sortBySearch || doctorSortOptions()[0].expr;
    if (sortId === 'byDate' && sortByBumba) {
        return rr.sort(sortDoctorByBumbaComparator);
    }
    return sortBy(rr, sortExpr);
};

export const actualSplitDoctors = createSelector(
    [
        doctorsWithSplitPartnershipAndSumsForLocal,
        getPhysiciansSearch,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT),
        dbGettr(PHYSICIANS_FILTER_PERIOD),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS),
        dbGettr(PHYSICIANS_SORT_BY),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_FROM_SERARCH),
        dbGettr('sortByBumbaFeature'),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
        // eslint-disable-next-line max-params
    ) => prepareActualSplitDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites,
        physiciansFromSearch,
        sortByBumba,
    ),
);

export const actualSplitDoctorsForClinics = createSelector(
    [
        doctorsWithSplitPartnershipAndSumsForLocalClinic,
        getPhysiciansSearchFromClinics,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_CLINICS),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT_CLINIC),
        dbGettr(PHYSICIANS_FILTER_PERIOD_CLINIC),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES_CLINIC),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS_CLINIC),
        dbGettr(PHYSICIANS_SORT_BY_CLINIC),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_CLINICS_FROM_SEARCH),
        dbGettr('sortByBumbaFeature'),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
        // eslint-disable-next-line max-params
    ) => prepareActualSplitDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites,
        physiciansFromSearch,
        sortByBumba,
    ),
);

export const actualSplitDoctorsForVisits = createSelector(
    [
        doctorsWithSplitPartnershipAndSumsForLocalVisits,
        getPhysiciansSearchFromVisits,
        dbGettr(PHYSICIANS_FILTER_SPECIALTY_VISITS),
        dbGettr(PHYSICIANS_FILTER_WORKS_AT_VISIT),
        dbGettr(PHYSICIANS_FILTER_PERIOD_VISIT),
        dbGettr(PHYSICIANS_FILTER_FAVORITES),
        dbGettr(PHYSICIANS_FILTER_PROFILES_VISIT),
        dbGettr(PHYSICIANS_FILTER_FEEDBACKS_VISIT),
        dbGettr(PHYSICIANS_SORT_BY_VISIT),
        getGlobalLocation,
        prefGettr('fullFavorites'),
        dbGettr(PHYSICIANS_VISITS_FROM_SEARCH),
        dbGettr('sortByBumbaFeature'),
    ],
    (
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites = [],
        physiciansFromSearch,
        sortByBumba = true,
        // eslint-disable-next-line max-params
    ) => prepareActualSplitDoctors(
        physicians,
        search,
        specialty,
        worksAt,
        period,
        filterByFavorites,
        profiles,
        feedbacks,
        sort,
        locationCode,
        favorites,
        physiciansFromSearch,
        sortByBumba,
    ),
);
