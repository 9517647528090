// eslint-disable-next-line
// noinspection ES6PreferShortImport
import { getDynamicLink, shareItem } from '../services/device';
import tracking from '../services/tracking';
import { Icon, React } from '.';

export const shareData = async (title, params, type = 'common') => {
    const link = await getDynamicLink(params);
    shareItem(title, link, () => {
        tracking.logEvent('share', { content_type: type });
    });
};

export const ShareIcon = ({
    shareTitle,
    shareParams,
    type,
}) => <Icon.Share onPress={() => shareData(shareTitle, shareParams, type)}/>;
