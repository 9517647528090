import EStyleSheet from 'react-native-extended-stylesheet';
import { fontSize } from './atomic';

const inputField = {
    backgroundColor: '$white',
    // paddingHorizontal: 12,
    paddingLeft: 12,
    paddingRight: 12,
    paddingVertical: 12,
    borderRadius: 3,
    borderWidth: 1,
    borderColor: '$lightGrayBorder',
};

export const input = EStyleSheet.create({
    field: {
        position: 'relative',
        marginBottom: 8,
    },
    fieldCaption: {
        paddingTop: 12,
        paddingLeft: 12,
        marginBottom: 4,
        fontSize,
        fontWeight: '600',
        color: '#787878',
    },
    inputTextArea: {
        backgroundColor: '$white',
        paddingHorizontal: 12,
        paddingVertical: 12,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: '$grayForButton',
        textAlignVertical: 'top',
        outline: 'none',
    },
    inputTextAreaDisabled: {
        paddingHorizontal: 12,
        paddingVertical: 12,
        borderRadius: 3,
        borderWidth: 1,
        borderColor: '$grayForButton',
        backgroundColor: '$lightGrayBg',
        minHeight: 82,
    },
    input: { ...inputField },
    input__panel: {
        paddingHorizontal: 12,
        paddingVertical: 12,
    },
    invalid: { borderColor: '#E17575' },
    disabled: {
        paddingTop: 16,
        paddingLeft: 16,
        borderColor: '$grayForButton',
        backgroundColor: '$lightGrayBg',
    },
    disabledPhonePrefix: {
        paddingTop: 16,
        paddingLeft: 16,
        backgroundColor: '$lightGrayBg',
    },
    checkboxContainer: {
        margin: 0,
        marginLeft: 3,
        marginRight: 3,
        marginTop: 18,
        padding: 0,
        borderWidth: 0,
        backgroundColor: '$lightGrayBg',
    },
    checkboxTitle: {
        fontFamily: '$secondaryTextFontFamily',
        fontSize: 16,
        fontWeight: 'normal',
        color: '#5E5E5E',
    },
    infoText: {
        fontSize: 14,
        lineHeight: 13.5,
        fontFamily: '$secondaryTextFontFamily',
        color: '#555555',
    },
    hint: { color: '#B8B8B8' },
});

export const searchBar = EStyleSheet.create({
    searchBar: {
        marginTop: 12,
        marginLeft: 12,
        marginRight: 12,
        paddingLeft: 0,
        paddingRight: 0,
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 3,
        backgroundColor: '$white',
        borderColor: '#E9E9E9',
    },
    container: {
        // padding: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        backgroundColor: '$white',
        flex: 1,
        borderColor: '$white',
        borderWidth: 0,
        borderBottomColor: 'transparent',
        borderTopColor: 'transparent',
    },
    input: {
        backgroundColor: '$white',
        borderWidth: 0,
        borderColor: '#E9E9E9',
        color: '#555555',
        fontSize: 18,
        fontFamily: 'SourceSansPro-Regular',
        // margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingVertical: 4,
        marginLeft: 0,
        marginRight: 0,
    },
    loadingIcon: {
        top: 16,
        right: 16,
    },
});

export const autocompleteSearchBar = EStyleSheet.create({
    loadingPanel: {
        height: 24,
        top: 60,
        left: 0,
        right: 0,
        zIndex: 1,
        marginHorizontal: 24,
        borderWidth: 1,
        borderColor: '#E9E9E9',
        backgroundColor: 'white',
    },
    suggestionsList: {
        position: 'absolute',
        top: 68,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
    },
    suggestionsItem: {
        paddingVertical: 14,
        justifyContent: 'center',
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    suggestionsItemTextContainer: { marginHorizontal: 12 },
    suggestionItemText: { fontFamily: 'SourceSansPro-Regular' },
    errorPanel: { paddingHorizontal: 24 },
    loadingIndicatorStyle: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
