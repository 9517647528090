import { useCallback, useEffect, useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import {
    Avatar,
    InfoList,
    Link,
    LoadingIndicator,
    NavigationItem,
    Page,
    Platform,
    Prompt,
    React,
    Section,
    Title,
    View,
} from '../../common';
import { getUserInfo, getUserInteraction, getUserLoadingState } from '../../selectors';
import actions, { navigate } from '../../actions';
import { COLORS } from '../../styles/colors';
import userService from '../../services/user';
import { storage } from '../../services/localstorage';
import { syncUserState } from '../../init';
import { getSupportBody } from '../../utils/supportInfo';
import { openURL } from '../../services/device';
import { accountInfo as styles } from './styles';
import { COUNTRY_CODE } from '../../config';

const promptLogOut = () =>
    Page.showModal(
        <Prompt
            title="titles.are_you_sure"
            subtitle="titles.if_you_logout"
            submitTitle="buttons.exit"
            cancelTitle="button.stay"
            onSubmit={actions.logOut}
            submitOptions={{
                danger: false,
                titleStyles: { fontFamily: 'SourceSansPro-Semibold' },
            }}
            cancelOptions={{ transparent: false }}
        />,
    );

const LoadingView = () => (
    <View style={styles.loadingContainer}>
        <LoadingIndicator style={styles.loadingIndicator} indicatorSize={Platform.OS === 'ios' ? 'large' : 50} />
        <Title.Bigger style={styles.loadingTitle} id="titles.profileUpdatingHint" />
    </View>
);

const useUserUpdatePooling = () => {
    const timer = React.useRef();

    useFocusEffect(
        useCallback(() => {
            const setTimer = (t = null) => {
                // console.warn('timer', t, timer.current);
                // clean the timer:
                if (timer.current) {
                    clearTimeout(timer.current);
                }
                timer.current = t;
            };

            const updateUser = async () => {
                const user = await userService.reloadUserInfo(undefined, actions.updatePrefs, actions.updateSelects);
                if (user.waitingConfirmEmail) {
                    setTimer(setTimeout(updateUser, 5000));
                } else {
                    setTimer();
                    syncUserState(undefined, false, true, false);
                }
            };
            // Need a little timeout to avoid fetch in case we are immediately going to next screen
            setTimeout(updateUser, 500);
            return setTimer;
        }, []),
    );
};

const useSignInPhone = () => {
    const [signInPhone, setSignInPhone] = useState();
    useEffect(() => {
        (async () => {
            const phoneNumber = await storage.getObject('phoneNumber', '');
            setSignInPhone(`${COUNTRY_CODE} ${phoneNumber}`);
        })();
    }, []);
    return signInPhone;
};

export const AccountPending = Page.register(
    ({ userInfo: { waitingConfirmEmail }, interaction: { error }, isLoading }) => {
        useUserUpdatePooling();

        const signInPhone = useSignInPhone();
        const [lastUserAction, setLastUserAction] = useState();

        if (!waitingConfirmEmail) {
            return null;
        }

        const email = waitingConfirmEmail.replace('...', '***');
        return (
            <Page
                name="account"
                notification={
                    error && {
                        ...error,
                        onSubmit: lastUserAction,
                    }
                }>
                {isLoading ? (
                    <LoadingView />
                ) : (
                    <View>
                        <Section>
                            <View
                                style={{
                                    flexDirection: 'row',
                                    paddingVertical: 14,
                                }}>
                                <Avatar size={80} />
                                <View
                                    style={{
                                        flex: 1,
                                        paddingLeft: 12,
                                    }}>
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}>
                                        <Title.Bigger
                                            bold
                                            style={{
                                                fontSize: 18,
                                                flex: 1,
                                                fontFamily: 'SourceSansPro-Semibold',
                                            }}>
                                            {email}
                                        </Title.Bigger>
                                    </View>
                                    <Title
                                        style={{
                                            marginTop: 10,
                                            color: COLORS.ACTION,
                                        }}
                                        id="titles.newDeviceWaitingConfirm"
                                    />
                                </View>
                            </View>
                        </Section>
                        <Section.White title="titles.my_account" headerStyle={styles.userInfoField}>
                            <InfoList
                                fields="userInfoFields"
                                data={{ phone: signInPhone }}
                                itemStyle={styles.userInfoField}
                            />
                        </Section.White>
                        <Section title="titles.account_other" />
                        <NavigationItem
                            title="titles.writeToSupport"
                            onItem={() => openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`)}
                        />
                        <NavigationItem
                            firstItem={false}
                            onItem={() => {
                                setLastUserAction(promptLogOut);
                                promptLogOut();
                            }}
                            titleStyle={{ color: COLORS.ACTION }}
                            title="titles.exit"
                        />
                        <View style={{ padding: 24 }}>
                            <Title style={{ color: '#BBBBBB' }}>
                                {Object.R('titles.canCancelAccountLink', { email })}
                            </Title>
                            <Link
                                style={{
                                    marginTop: 12,
                                    alignSelf: 'center',
                                }}
                                id="titles.createNewAccount"
                                href={() =>
                                    Page.showModal(
                                        <Prompt
                                            title="titles.resetAndCreateNewAccount"
                                            submitTitle="titles.submit"
                                            onSubmit={() =>
                                                navigate('AccountReset', { navigatedFromScreen: 'Account' })
                                            }
                                            cancelTitle="buttons.no"
                                            cancelOptions={{ primary: false }}
                                            showCross>
                                            <Title
                                                style={{
                                                    paddingHorizontal: 12,
                                                    paddingBottom: 12,
                                                }}
                                                numberOfLines={null}>
                                                {Object.R('titles.resetAndCreateNewAccountConfirm', { email })}
                                            </Title>
                                        </Prompt>,
                                    )
                                }
                                underline
                            />
                        </View>
                    </View>
                )}
            </Page>
        );
    },
    {
        userInfo: { from: getUserInfo },
        interaction: { from: getUserInteraction },
        isLoading: { from: getUserLoadingState },
    },
);
