import {
    dataFrom, Icon, Image, Modal2, Page, Platform, React, View, WithBadge, YesNo,
} from '../../common';
import { AppHeader } from '../../combo';
import { getBasket, getIsPersonalized } from '../../selectors';
import { IMAGES } from '../../assets';
import actions, { navigate } from '../../actions';

const backHandler = null;

export const drugsAnaloguesFilter = (
    <Icon.Filter
        onPress={() => Page.showModal(
            <Modal2 title="titles.showAnalogues">
                <YesNo onChange={actions.toggleIncludeAnalogs} />
            </Modal2>,
        )
        }
    />
);

const FirstAidKitForUser = dataFrom(getIsPersonalized, ({ data: isPersonalized, basket }) => (isPersonalized ? (
    <WithBadge
        accessibilityLabel="first_aid_kit_button"
        onPress={() => navigate('FirstAidKit')}
        badge={basket && basket.length}
        top={-3}
        right={-3}>
        <Image
            style={{
                width: 26,
                height: 24,
            }}
            source={IMAGES.FirstAidKitIcon}
        />
    </WithBadge>
) : (
    <View />
)));

export const FirstAidKitIcon = dataFrom(getBasket, ({ data }) => (Platform.OS === 'web' ? null : <FirstAidKitForUser basket={data} />));

export const applyFAKIcon = (target, opts = {}) => Object.assign(target, {
    screenOptions: ({ navigation }) => ({
        header: () => (
            <AppHeader {...opts} right navigation={navigation}>
                <FirstAidKitIcon />
            </AppHeader>
        ),
    }),
});
