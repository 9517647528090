import { Page, React } from '../../common';
import actions from '../../actions';
import { DrugsStoresList } from './DrugstoresList';

/**
 * SearchDrugstores page.
 */
export const SearchDrugstores = ({
    navigation,
    route: {
        params: {
            drugs, sortBy, title, from, aptId,
        },
    },
}) => {
    actions.setFormData({
        SearchDrugstores: {
            back: () => {
                if (from === 'searchFirstAidKit') {
                    navigation.pop();
                    navigation.navigate('FirstAidKit');
                    // navigation.replace('FirstAidKit');
                } else {
                    navigation.goBack();
                }
                return true;
            },
        },
    });

    return (
        <Page name="drugstores">
            <DrugsStoresList
                drugs={drugs}
                navigation={navigation}
                sortBy={sortBy}
                bannerTitle={title}
                from={from}
                firstAidKit={true}
                aptId={aptId}
            />
        </Page>
    );
};
