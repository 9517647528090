// noinspection JSUnusedGlobalSymbols

import EStyleSheet from 'react-native-extended-stylesheet';

const avatarSize = 100;

export const account = EStyleSheet.create({
    save: { backgroundColor: '#B6BEDA' },
    cancel: { backgroundColor: '$emerald' },
    button: {
        flex: 1,
        borderRadius: 3,
        alignItems: 'center',
        paddingVertical: 11,
    },
    text: { color: '$white' },
    formHandlersBlock: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    // --details
    container: {
        position: 'relative',
        alignItems: 'center',
    },
    avatar: {
        marginTop: 10,
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,
        overflow: 'hidden',
    },
    avatarImage: {
        width: avatarSize,
        height: avatarSize,
    },
    rightItem: {
        position: 'absolute',
        top: 0,
        right: 10,
    },
    imageEmpty: { backgroundColor: '$gray' },
    title2: {
        marginTop: 16,
        fontSize: 18,
        fontWeight: '600',
        color: '#555555',
        textAlign: 'center',
    },
    subTitle: {
        marginTop: 8,
        color: '#555555',
    },
});

export const imageViewer = EStyleSheet.create({
    view: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: 'black',
        overflow: 'hidden',
        zIndex: 100,
    },
    image: {
        flex: 1,
        resizeMode: 'contain',
        // fix crash: https://github.com/react-native-webview/react-native-webview/issues/430
        overflow: 'hidden',
        opacity: 0.99,
    },
    deleteButton: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        borderRadius: 30,
        backgroundColor: 'white',
        zIndex: 10010,
    },
    deleteImage: {
        marginHorizontal: 10,
        marginVertical: 10,
        color: 'black',
    },
});

export const fields = EStyleSheet.create({
    epamProgramField: {
        marginTop: 0,
        marginBottom: 8,
        borderColor: '#E0E0E0',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        paddingVertical: 20,
        flexWrap: 'nowrap',
    },
});

export const favItemWidth = 117;

export const myCard = EStyleSheet.create({
    listHeading: {
        marginTop: 20,
        paddingLeft: 8,
        paddingBottom: 12,
        fontSize: 12,
        fontWeight: '600',
        color: '#787878',
    },
    container: {
        marginTop: 20,
        paddingLeft: 12,
        paddingRight: 13,
    },
    emptyListText: {
        paddingLeft: 10,
        paddingRight: 10,
        color: '$lightGray',
        lineHeight: 16,
    },
    listItem: {
        width: favItemWidth,
        height: 130,
        borderRadius: 3,
        marginRight: 4,
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 12,
        paddingBottom: 16,
        paddingHorizontal: 13,
        borderWidth: 1,
        borderColor: '#E9E9E9',
        backgroundColor: '$white',
    },
    titleContainer: { marginTop: 4 },
    listItemTitle: {
        marginTop: 12,
        textAlign: 'center',
        fontFamily: '$secondaryTextFontFamily',
        lineHeight: 14,
        fontSize: 12,
        color: '#787878',
        // paddingTop: 12,
    },
    listItemTitleWeb: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
        whiteSpace: 'normal',
        lineHeight: 14,
        maxHeight: 28,
        height: 28,
        width: 100,
    },
    indicator: {
        position: 'absolute',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: -4,
        left: -10,
    },
});

export const home = EStyleSheet.create({
    navigationItem: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$white',
        borderWidth: 1,
        borderColor: '#E9E9E9',
        borderRadius: 3,
        paddingTop: 18,
    },
    navigationContainer: {
        paddingTop: 17,
        paddingBottom: 7,
        alignItems: 'center',
        marginTop: 0,
        marginRight: 0,
        marginLeft: 0,
    },
    image: {
        width: 55,
        height: 56,
        // marginBottom: 25,
    },
    popularButonText: {
        fontSize: 14,
        color: '$white',
    },
    popularHeading: {
        color: '#555555',
        fontSize: 12,
        paddingBottom: 10,
        fontWeight: '600',
    },
    popularButton: {
        marginTop: 8,
        marginRight: 10,
        borderRadius: 20,
        paddingVertical: 7,
        paddingHorizontal: 20,
        backgroundColor: '#B6BEDA',
    },
});
