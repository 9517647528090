import { useNavigation, useRoute } from '@react-navigation/native';
import { useEffect } from 'react';
import {
    Icon,
    React,
    SearchBar,
    View,
    TouchableOpacity,
    Title,
    BadgeRed,
} from '../common';
import { COLORS } from '../styles/colors';
import { optionsButton } from './style';

const FilterButton = ({
    filtersData,
    isFocused,
    gotoFilters,
}) => (
    <TouchableOpacity style={optionsButton.button} onPress={gotoFilters}>
        <Icon.Filter color={COLORS.MAIN} />
        {isFocused ? null : <Title bold style={optionsButton.text}>{Object.R('titles.params')}</Title>}
        {filtersData ? <BadgeRed badge={filtersData} /> : null}
    </TouchableOpacity>
);

const TheSearchBar = ({
    searchData,
    initialValue = '',
    onFocusChange,
    onSearch,
    placeholder,
}) => (
    <SearchBar
        autoCorrect={false}
        initialValue={searchData || initialValue}
        searchAfter={2}
        onSearch={onSearch}
        placeholder={placeholder}
        additionalStyle={{ flex: 1 }}
        onFocusChange={onFocusChange}
        borderRadius={3}
    />
);

export const ListHeaderWithSearch = ({
    onSearch, searchData, filtersData, gotoFilters, placeholder,
}) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const { params: { keyword } = {} } = useRoute();
    const navigation = useNavigation();

    const onFocusChange = () => {
        if (isFocused) {
            setTimeout(() => setIsFocused(!isFocused), 100);
        } else {
            setIsFocused(!isFocused);
        }
    };

    useEffect(() => {
        navigation.setParams({ keyword: '' });
        if (keyword) {
            onSearch(keyword);
        }
    }, [keyword]);

    return (
        <View style={{ flexDirection: 'row' }}>
            <TheSearchBar initialValue={keyword} searchData={searchData} onSearch={onSearch} onFocusChange={onFocusChange} placeholder={placeholder} />
            <FilterButton isFocused={isFocused} gotoFilters={gotoFilters} filtersData={filtersData} />
        </View>
    );
};

