/* eslint-disable no-underscore-dangle,no-use-before-define */
// noinspection JSUnusedGlobalSymbols

import { createSelectorCreator, defaultMemoize } from 'reselect';
import Fuse from 'fuse.js';
import { getEnums } from '../i18n';
import { getNumberOfDaysBetween, removeArrayDuplicates, toDate } from '../utils';
import { EMPTY_ARRAY, EMPTY_OBJECT } from './const';

/**
 * Utilities.
 */

export { createSelector, createSelectorCreator } from 'reselect';

export const createKeyedSelector = createSelectorCreator(defaultMemoize, (a, b) => {
    if (!a && !b) {
        return true;
    }
    if (a === b) {
        return true;
    }
    return a && b && Object.keys(a).join('-') === Object.keys(b).join('-');
});

const _def = (x, def) => (x == null ? def : x);
const _digger = (steps, def) => o => _def(
    steps.reduce((r, e) => (r ? r[e] : r), o),
    def,
);

export const arrayMap = (list, fn) => (!list || !list.length ? EMPTY_ARRAY : list.map(fn));
export const gettr = (path, def) => _digger(path.split('.'), def);
export const dbGettr = (path, def) => _digger(['db', ...path.split('.')], def);
export const prefGettr = (path, def) => _digger(['prefs', 'data', ...path.split('.')], def);
export const selectGettr = (path, def) => _digger(['selections', 'data', ...path.split('.')], def);

export const getKeywordRe = s => new RegExp(RegExp.escape(s), 'i');

export const clinicsFilterByKeywordFn = (keyword) => {
    const re = getKeywordRe(keyword);
    return e => re.test(e.name) || re.test(e.overview);
};

export const servicesFilterByKeywordFn = (keyword) => {
    const re = getKeywordRe(keyword);
    return (e) => {
        e.searchScore =
            weightMatching(e.name, keyword, re) * 6 +
            weightMatching(e.subType, keyword, re) * 2 +
            weightMatching(e.type, keyword, re);
        return e.searchScore > 0;
    };
};

const expr = {
    byDate: e => (e.nearestDate ? e.nearestDate.valueOf() : Number.MAX_VALUE),
    byName: 'fullName',
    byFeedbacks: '-textFeedbackCount', // TODO: textFeedbackCount
    byFeedbacksMedcenter: '-feedbackCount',
    // byDoctorsCount: '-branchDoctorsCounter',
    byDoctorsCount: '-doctorsCounter',
    byRecommend: '-recommendCount',
    byRecommendIndex: '-recommendationIndex',
    byMedcenterName: 'name',
    byPrice: 'minPrice',
};

const feedbackSortExpression = {
    byDateConfirmed: ['CONFIRMED', 'updatedAt'],
    byDate: 'updatedAt',
    byUsefulCount: 'usefulCount',
};
export const doctorSortOptions = () => getEnums('doctorSort').map(item => Object.assign(item, { expr: expr[item.id] }));

export const feedbackSortOptions = () => getEnums('doctorFeedbackSort').map(item => Object.assign(item, { expr: feedbackSortExpression[item.id] }));

export const medcenterSortOptions = () => getEnums('medcenterSort').map(item => Object.assign(item, { expr: expr[item.id] }));

export const serviceMedcentersSortOptions = () => getEnums('serviceMedcentersSort')
    .map(item => Object.assign(item, { expr: expr[item.id] }))
    .filter(e => e.id !== 'byFeedbacks');

export const medcenterFilterOptions = () => getEnums('medcenterFilter');

export const isLode = name => new RegExp('лодэ', '').test(String(name).toLowerCase());

export function normSpecialty(e) {
    return (e.specialization || '').split(',').map(s => s
        .trim()
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .replace(/\s*([-.,:()/])\s*/g, '$1')
        .replace(/врач-/, '')
        .replace(/акушер-/, ''));
}

export function normSpecialtyString(e) {
    return e.split(',').map(s => s
        .trim()
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .replace(/\s*([-.,:()/])\s*/g, '$1')
        .replace(/врач-/, '')
        .replace(/акушер-/, ''));
}

export const getDateForJob = (e, job, timetableSum, timetableSumByAssignment = {}) => {
    const val = (timetableSum[e._id] || {})[job._id] || timetableSumByAssignment[job?.assignmentId];
    return toDate(val);
};

export const getLastSubName = name => (!name ? '' : name.split(' ').slice(1, -1).join(' '));

export const weightMatching = (src, keyword, re) => (re.test(src) ? keyword.length / src.length : 0);

export const getDoctorProfile = (worksAt) => {
    if (!worksAt.length) {
        return [];
    }
    const ageIntervals = removeArrayDuplicates(
        worksAt.map(({ patientLowerAge, patientUpperAge }) => ({
            lowerAge: patientLowerAge,
            upperAge: patientUpperAge,
        })),
    );
    let profile = [];
    const incluesAdults = ageIntervals.some(({ lowerAge, upperAge }) => lowerAge >= 18 || !upperAge || upperAge > 18);
    if (incluesAdults) {
        profile = [{ isForChildren: false }];
    }
    const minChildAge = ageIntervals.reduce(
        (min, cur) => (cur.lowerAge < min ? cur.lowerAge : min),
        ageIntervals[0].lowerAge,
    );
    if (minChildAge < 18) {
        const maxChildAge = ageIntervals.reduce(
            (max, cur) => (max !== null && cur.lowerAge < 18 && (cur.upperAge > max || !cur.upperAge) ? cur.upperAge : max),
            ageIntervals[0].lowerAge,
        );
        const profileForChildren = {
            isForChildren: true,
            minAge: minChildAge || 0,
        };
        if (maxChildAge !== null && maxChildAge !== 18) {
            profileForChildren.maxAge = maxChildAge;
        }
        profile.push(profileForChildren);
    }
    return profile;
};

export const fullTextSearch = (array, fields, query) => {
    const opts = {
        keys: fields,
        tokenize: true,
        threshold: 0.21,
    };
    const fuse = new Fuse(array, opts);
    return fuse.search(query.trim());
};

export const getHandledAgesFromProfile = (profile = []) => {
    let ages = [];
    if (profile.some(({ isForChildren }) => !isForChildren)) {
        ages = [
            {
                id: 0,
                badge: '18+',
                isForChild: false,
            },
        ];
    }
    const profileForChildren = profile.find(({ isForChildren }) => isForChildren);
    if (profileForChildren) {
        const { minAge, maxAge } = profileForChildren;
        ages.push({
            id: 1,
            badge: maxAge
                ? Object.R('titles.doctorAgeHandlingInterval', {
                    from: `${minAge}+`,
                    to: maxAge,
                })
                : `${minAge}+`,
            isForChild: true,
            // eslint-disable-next-line no-nested-ternary
            childText: maxAge
                ? Object.R('handeledAges.childsFromTo', { minAge, maxAge })
                : minAge === 0
                    ? Object.R('handeledAges.childsContinue')
                    : Object.R('handeledAges.childsFrom', { minAge }),
        });
    }

    // eslint-disable-next-line no-nested-ternary
    return {
        ages,
        // eslint-disable-next-line no-nested-ternary
        title:
            ages.length === 1 && ages[0].isForChild
                ? `${Object.R('handeledAges.childsStart')}${ages[0].childText}`
                : ages.length === 2
                    ? `${Object.R('handeledAges.adult')}${ages[1].childText}`
                    : null,
    };
};

export const getPriceRangeString = (min, max) => {
    if (!min && !max) {
        return '';
    }
    if (min === max || (!min && max) || (min && !max)) {
        return Object.R('price', { value: min || max });
    }
    return Object.R('titles.priceFromTo', {
        firstValue: min,
        secondValue: max,
    });
};

export function assignClinic(obj, actualNta, clinicId, doc) {
    Object.assign(obj, {
        [clinicId]: Object.assign(obj[clinicId] || {}, {
            [doc.id]: {
                fullName: doc.fullName,
                specialization: doc.specialization,
                nearestDate: actualNta,
                id: doc.id,
                _id: doc._id,
                photoUrl: doc.photoUrl,
                worksAt: doc.worksAt,
                feedbacksCount: doc.feedbacksCount,
                recommendCount: doc.recommendCount,
                notRecommendCount: doc.notRecommendCount,
                textFeedbackCount: doc.textFeedbackCount,
                neutralCount: doc.neutralCount,
                recommendationIndex: doc?.recommendationIndex ?? 0,
                handledAges: doc.handledAges,
                coverer: doc.coverer,
                notification: doc.notification,
                limited: doc.limited,
                priceRange: doc.priceRange,
                daysCount: getNumberOfDaysBetween(new Date(), actualNta),
            },
        }),
    });
}

export const mergeFeedbacksToDoctor = (doc, feedbackSum, withCreate) => {
    return Object.assign(withCreate ? Object.create(doc) : doc, {
        feedbacksCount: (feedbackSum || EMPTY_OBJECT).feedbackCount || 0,
        recommendCount: (feedbackSum || EMPTY_OBJECT)?.recommendCount || 0,
        notRecommendCount: (feedbackSum || EMPTY_OBJECT)?.notRecommendCount || 0,
        neutralCount: (feedbackSum || EMPTY_OBJECT)?.neutralCount || 0,
        recommendationIndex: (feedbackSum || EMPTY_OBJECT)?.recommendationIndex || 0,
        textFeedbackCount: (feedbackSum || EMPTY_OBJECT)?.textFeedbackCount || 0,
    });
};

export const getPrices = ({ clinics_with_prices: clinicsData } = {}) => {
    const hash = {};
    for (let i = 0; i < clinicsData?.length; i++) {
        const roundedMin = Math.round(clinicsData[i].price_min);
        const roundedMax = Math.round(clinicsData[i].price_max);

        hash[clinicsData[i].clinic_id] = {
            min: roundedMin,
            max: roundedMax,
            get priceRangeString() {
                return getPriceRangeString(roundedMin, roundedMax);
            },
        };
        if (hash.min > roundedMin || !hash.min) {
            if (roundedMin !== 0) {
                hash.min = roundedMin;
            }
        }
        if (hash.max < roundedMax || !hash.max) {
            hash.max = roundedMax;
        }
    }
    hash.priceRangeString = getPriceRangeString(hash.min, hash.max);
    return hash;
};

/* Timeslots(services) */

export const isFutureTimeslot = (startTime) => {
    // if (startTime?.toDateString() === '2024-07-30') {
    //     console.log('startTime: ', startTime);
    //     console.log('startTime?.toDateString(): ', startTime?.toDateString());

    //     console.log('new Date(startTime) :', new Date(startTime));
    //     console.log('new Date() :', new Date());
    //     console.log('diff: ', st?.valueOf() > dateNow?.valueOf());
    // }
    let st = startTime;
    if (typeof startTime === 'string') {
        st = new Date(startTime);
    }
    const dateNow = new Date();
    return st?.valueOf() > dateNow?.valueOf();
};

export const getTimeslotDurationInMinutes = (start, end) => {
    const endDate = new Date(end);
    const startDate = new Date(start);
    const duration = (endDate - startDate) / 60000;
    return duration;
};
const getAmountOfTimeslotsForDuration = (timeslotDuration, duration) => {
    return Math.ceil(duration / timeslotDuration); // for 3.1 returns 4
};

const isGoInSequence = (prevTimeslot, nextTimeslot) => prevTimeslot.end === nextTimeslot.start;

const getCombinedTimeslots = (timeslosts, count) => {
    if (!timeslosts.length || timeslosts.length < count) {
        return [];
    }
    const combinedTimeslots = [];
    let currentCount = 1;
    for (let i = 1; i < timeslosts.length; i++) {
        if (isGoInSequence(timeslosts[i - 1], timeslosts[i])) {
            currentCount++;
            if (currentCount === count) {
                combinedTimeslots.push(timeslosts.slice(i + 1 - count, i + 1));
                currentCount = 1;
                i++;
            }
        } else {
            currentCount = 1;
        }
    }
    return combinedTimeslots;
};

const getMergedCombinedTimeslots = (timeslosts) => {
    return timeslosts.map(timeslotsGroup => ({
        ...timeslotsGroup[0],
        endDate: timeslotsGroup[timeslotsGroup.length - 1].endDate,
        end: timeslotsGroup[timeslotsGroup.length - 1].end,
    }));
};

const getMergedTimeslots = (timeslosts, count) => {
    const combinedTimeslots = getCombinedTimeslots(timeslosts, count);
    if (!combinedTimeslots.length) {
        return [];
    }
    const freeTimeslots = getMergedCombinedTimeslots(combinedTimeslots);
    return freeTimeslots;
};

const getMergedTimeslotsObj = (timeslots, count) => {
    const actualFreeTimeslots = getMergedTimeslots(timeslots, count);
    return actualFreeTimeslots;
};

const sortTimeslots = (timeslots = []) => {
    const sortedTimeslots = [...timeslots];
    const getValue = e => (e.startDate ? new Date(e.startDate).valueOf() : Number.MAX_VALUE);
    sortedTimeslots?.sort((e1, e2) => getValue(e1) - getValue(e2));
    return sortedTimeslots;
};

export const getActualTimeslotsObj = (originalTimeslots = [], duration) => {
    const timeslots = sortTimeslots(originalTimeslots.filter(e => isFutureTimeslot(e.startDate)));
    const timeslotDuration =
        timeslots.length && getTimeslotDurationInMinutes(timeslots[0].startDate, timeslots[0].endDate);
    if (!timeslots.length || timeslotDuration >= +duration) {
        return timeslots;
    }
    const count = getAmountOfTimeslotsForDuration(timeslotDuration, duration);
    return getMergedTimeslotsObj(timeslots, count);
};

export const getActualTimeslots = (data, duration) => (data?.length ? getActualTimeslotsObj(data, duration) : []);

export const memoize = (fn) => {
    const cache = {};
    return (...args) => {
        const key = JSON.stringify(args);
        if (key in cache) {
            return cache[key];
        }
        const result = fn(...args);
        cache[key] = result;
        return result;
    };
};

export const memoizeLastResult = (fn) => {
    let lastResult;
    let lastArgs;
    // let realCall = 0;

    return (...args) => {
        // const m1 = performance.now();
        const argsString = JSON.stringify(args);
        if (argsString === JSON.stringify(lastArgs)) {
            // const m2 = performance.now();
            // console.log(`Memoized call, check: ${m2 - m1} ms(${fn?.name})`, realCall);
            return lastResult;
        }
        // const m2 = performance.now();
        // const start = performance.now();
        lastResult = fn(...args);
        lastArgs = args;
        const finish = performance.now();
        // realCall = finish - start;
        // console.log(`REAL call, (${fn?.name}) - check: ${m2 - m1}`, realCall);
        return lastResult;
    };
};

export const memoizedGetActualTimeslots = memoizeLastResult(getActualTimeslots);
