import { createSelector, prefGettr } from './utils';

/**
 * Basket
 */

export const getBasket = s => prefGettr('basket', [])(s) || [];

export const getAddedDrugsIds = createSelector(
    [getBasket],
    drugs => (drugs ? drugs.map(({ id: n }) => n) : []),
);
export const getMinPrice = createSelector(
    [getBasket],
    drugs => (drugs ? (Math.round(drugs.reduce((acc, {
        price_min: min,
        ls_price: price,
    }) => acc + Number(min || price), 0) * 100) / 100) : 0),
);
