import { Alert, Platform } from 'react-native';
import { useEffect, useState } from 'react';
import JSZip from 'jszip';
import { MODE } from '../config';

const ENCODING = 'utf8';

const pack = buf => (new JSZip()).file('content.txt', buf)
    .generateAsync({
        type: 'base64',
        compression: 'DEFLATE',
        compressionOptions: { level: 1 },
    });
const unpack = buf => JSZip.loadAsync(buf, { base64: true })
    .then(zip => zip.file('content.txt')
        .async('string'));

/**
 * Local storage.
 *
 * This implementation based on `Object.fetchBlob.fs`.
 */
export class LocalStorage {
    cache = {};

    // eslint-disable-next-line class-methods-use-this
    get fs() {
        return Object.fetchBlob.fs;
    }

    pathForKey(key) {
        return `${`${this.fs.dirs.DocumentDir}/storage`}/${key}`;
    }

    // noinspection JSUnusedGlobalSymbols
    getList(key, def = []) {
        return this.get(key)
            .then(r => r || def);
    }

    getObject(key, def = {}) {
        return this.get(key)
            .then(r => r || def);
    }

    async has(key) {
        if (key in this.cache) {
            return true;
        }
        const list = await this.fs.ls(`${this.fs.dirs.DocumentDir}/storage`);
        return !!list.some(key);
    }

    async get(key) {
        if (key in this.cache) {
            // console.log('get cached', key, this.cache[key]);
            return this.cache[key];
        }
        try {
            const path = this.pathForKey(key);
            const exists = await this.fs.exists(path);
            if (!exists) {
                return null;
            }
            const val = await this.fs.readFile(path, ENCODING);
            // console.log('get', key, val);
            if (val) {
                const unpacked = val.slice(0, 3) === '$$$' ? await unpack(val.slice(3)) : val;
                this.cache[key] = JSON.parse(unpacked);
            }
            return this.cache[key];
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.log('!!! LocalStorage get ex', ex);
        }
        return null;
    }

    async set(key, val) {
        this.cache[key] = val;
        try {
            const str = JSON.stringify(val);
            const packed = str;// (MODE === 'dev' || (key === 'ums' && Platform.OS !== 'web')) ? str : `$$$${await pack(str)}`;
            await this.fs.writeFile(this.pathForKey(key), packed, ENCODING);
            return val;
        } catch (ex) {
            // eslint-disable-next-line no-console
            console.log('!!! LocalStorage set ex', ex);
        }
        return null;
    }

    async assign(key, val) {
        const prevVal = await this.get(key, val);
        // noinspection UnnecessaryLocalVariableJS
        const nextVal = await this.set(key, prevVal ? ({ ...prevVal, ...val }) : ({ ...val }));
        return nextVal;
    }

    remove(...keys) {
        const { cache } = this;
        keys.forEach((k) => {
            delete cache[k];
        });
        const filterFn = keys ? p => keys.includes(p) : () => true;
        return new Promise((resolve, reject) => this.fs.ls(`${this.fs.dirs.DocumentDir}/storage`)
            .then(files => Promise.all(files.filter(filterFn)
                .map(k => this.fs.writeFile(this.pathForKey(k), '', ENCODING))))
            .then(() => this.fs.ls(`${this.fs.dirs.DocumentDir}/storage`))
            // eslint-disable-next-line no-console
            .then(files => console.log('removed', files))
            .then(resolve, reject));
    }
}

export const storage = new LocalStorage();

export const useStoredValue = (key, def) => {
    const [value, setValue] = useState(def);
    useEffect(
        () => {
            (async () => {
                const d = await storage.get(key);
                if (d != null) {
                    setValue(d);
                }
            })();
        }
        , [],
    );
    return value;
};
