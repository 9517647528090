// noinspection NonAsciiCharacters

const MAP = {
    а: 'a',
    е: 'e',
    э: 'e',
    о: 'o',
    ы: 'y',
    i: 'i',
    і: 'i',
    и: 'i',
    й: 'j',
    ь: 'j',
    ю: 'ju',
    я: 'ja',
    ё: 'jo',
    ъ: '-',
    ж: 'zh',
    ш: 'sh',
    щ: 'sq',
    б: 'b',
    в: 'v',
    г: 'g',
    д: 'd',
    з: 'z',
    к: 'k',
    л: 'l',
    м: 'm',
    н: 'n',
    п: 'p',
    р: 'r',
    с: 's',
    т: 't',
    у: 'u',
    ў: 'w',
    ф: 'f',
    х: 'x',
    ц: 'c',
    ч: 'q',
    А: 'A',
    Б: 'B',
    В: 'V',
    Г: 'G',
    Д: 'D',
    Е: 'Je',
    Ё: 'Jo',
    Ж: 'Zh',
    З: 'Z',
    И: 'I',
    Й: 'J',
    К: 'K',
    Л: 'L',
    М: 'M',
    Н: 'N',
    О: 'O',
    П: 'P',
    Р: 'R',
    С: 'S',
    Т: 'T',
    У: 'U',
    Ў: 'W',
    Ф: 'F',
    Х: 'X',
    Ц: 'C',
    Ч: 'Q',
    Ш: 'Sh',
    Э: 'E',
    Ы: 'Y',
    Ь: 'j',
    Ю: 'Ju',
    Я: 'Ja',
};

/**
 * Transliterates from cyrillic to latin using `new belarusian latinka` rules.
 *
 * @param {*} s input string.
 */
export function cyrlat(s) {
    if (!s) {
        return '';
    }
    let r = '';
    for (let i = 0, l = s.length, p, x; i < l; i++) {
        x = s[i];
        p = MAP[x];
        r += (typeof p === 'undefined') ? x : p;
    }
    return r;
}
