import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Page, TabSelector, View } from '../../common';
import { HistoryVisits, Tests } from '../App/snippets';
import { setFormData } from '../../actions/forms';
import actions, { back } from '../../actions';
import { EditPage } from '../Account/EditPage';
import { getShowNewUserAgreementModal } from '../../selectors';
import { saveRelativeToStorage } from '../../services/user.utils';
import api from '../../services/api';
import { DISABLE_SERVICES } from '../../config';

export const TabsForEditUserPD = ({ onChange }) => {
    const [selected, setSelected] = useState('form');
    const items = [
        {
            id: 'form',
            name: 'titles.editUserPD',
        },
        {
            id: 'visits',
            name: 'titles.editUserVisits',
        },
        {
            id: 'reports',
            name: 'titles.editUserReports',
        },
    ];
    return (
        <TabSelector
            items={DISABLE_SERVICES ? items?.filter(({ id }) => id !== 'reports') : items}
            // item={items}
            onItem={(item) => {
                setSelected(item.id);
                onChange?.(item.id);
            }}
            selected={selected}
        />
    );
};

export const EditChildrenForm = Page.register(
    EditPage(
        // eslint-disable-next-line max-params
        (_, navigation, isChildEdit, __, needSendConsent, ___, user) => async ({
            name, date: birthday, sex, profileId,
        }) => {
            const bd = user?.relatives?.find(r => r.profileId === profileId)?.birthday;
            if (isChildEdit) {
                await api.updateChild(
                    {
                        _id: profileId,
                        fullNameRu: name,
                        birthday,
                        sex,
                    },
                    bd !== birthday,
                );
            } else {
                await api.updateRelative(
                    {
                        _id: profileId,
                        fullNameRu: name,
                        birthday,
                        sex,
                    },
                    bd !== birthday,
                );
            }
            saveRelativeToStorage({
                name,
                birthday,
                sex,
                profileId,
                hashKey: user?.hashKey,
            }).then(() => {
                actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);
                back();
            });
        },
        'addChild',
        'hints.editUserPD',
        Object.R('titles.fillProfilePD', null, false),
        {
            withPage: false,
            buttonTitle: 'button.saveUserPD',
        },
    ),
    { showNewUserAgreementModal: { from: getShowNewUserAgreementModal } },
);

export const EditChildren = () => {
    return <EditChildrenForm />;
};

export const EditUserPD = Page.register((params) => {
    const [selected, setSelected] = useState('form');
    const [visitsFetched, setVisitsFetched] = useState(false);
    const [reportsFetched, setReportsFetched] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selected === 'visits' && !visitsFetched) {
            setVisitsFetched(true);
            // fetchVisits();
        }
    }, [selected, visitsFetched]);

    useEffect(() => {
        if (selected === 'reports' && !reportsFetched) {
            setReportsFetched(true);
            // fetchReports();
        }
    }, [selected, reportsFetched]);

    useEffect(() => {
        dispatch(
            setFormData({
                EditUserPD: {
                    back: () => {
                        back();
                    },
                },
                VisitRelative: {
                    back: () => {
                        back();
                    },
                },
            }),
        );
    }, []);

    return (
        <Page>
            <TabsForEditUserPD onChange={setSelected} />
            {selected === 'visits' && (
                <HistoryVisits
                    needHeader={false}
                    profileId={params?.relative?.profileId}
                    clickable={false}
                    detailScreen={'VisitRelative'}
                />
            )}
            {selected === 'reports' && <Tests needHeader={false} profileId={params?.relative.profileId} />}
            {selected === 'form' && (
                <View>
                    <EditChildren />
                </View>
            )}
        </Page>
    );
});
