import { useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { KeyboardAvoidingView, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import {
    BigButton,
    Box,
    entityData,
    ErrorStub,
    Icon,
    List,
    LoadingIndicator,
    ModalImpl,
    Page,
    Prompt,
    React,
    Section,
    selectOptions,
    showNotification,
    Stub,
    Subtitle,
    TabSelector,
    Text,
    TextInput,
    Title,
    TouchableOpacity,
    View,
} from '../../../common';
import actions, { navigate } from '../../../actions';
import { getActualFeedbacks, getFeedbackSort } from '../../../selectors';
import { elementsStyles } from '../../../styles/elements';
import { COLORS } from '../../../styles/colors';
import { getBottomObject, getMarginHorizontalObject, modal as styles } from '../../../styles/common';
import { TransparentButton } from '../../../common/Button';
import api from '../../../services/api';
import { getFromStore, putIntoStore, SCHEME } from '../../../store';
import { feedbackSortOptions } from '../../../selectors/utils';
import tracking from '../../../services/tracking';
import { curry } from '../../../utils';
import { FeedbackListItem } from './snippets';

export const unauthorizedForUseful = (doctor) => {
    Page.showModal(
        <Prompt
            title="titles.warningForAuthorizedOnlyTitle"
            subtitle="titles.warningForAuthorizedOnlyText"
            onCancel={() => navigate('Account', {
                backTo: 'DoctorFeedbacks',
                doctorInfo: doctor,
            })
            }
            // onSubmit={}
            submitTitle="buttons.warningForAuthorizedOnlyOk"
            cancelTitle="buttons.warningForAuthorizedOnlyCancel"
            submitOptions={{ danger: false }}
        />,
    );
};

const onSort = curry(putIntoStore, SCHEME.PHYSICIANS_FEEDBACK_SORT_BY);
const showSortModal = () => {
    const selectedSort = getFromStore(`db.${SCHEME.PHYSICIANS_FEEDBACK_SORT_BY}`);
    const feedbackSort = feedbackSortOptions();
    const selectedSortId = selectedSort ? selectedSort.id : feedbackSort[0].id;
    selectOptions({
        title: 'titles.sort_list_of_doctor_feedbacks',
        selected: selectedSortId,
        data: feedbackSort,
        onSelect: (e) => {
            onSort(e);
            tracking.logEvent(`ai_feedback_doctor_ls_${e?.id}`);
            Page.closeModal();
        },
    });
};

const sortByDate = (a, b, order = 1) => {
    const aDate = new Date(a?.updatedAt).getTime();
    const bDate = new Date(b?.updatedAt).getTime();
    if (aDate > bDate) {
        return -1 * order;
    } else if (aDate < bDate) {
        return order;
    }
    return 0;
};

const sortFn = (a, b) => {
    const aStatus = a?.status;
    const bStatus = b?.status;
    if (aStatus === 'CONFIRMED' && bStatus !== 'CONFIRMED') {
        return -1;
    } else if (bStatus === 'CONFIRMED' && aStatus !== 'CONFIRMED') {
        return 1;
    } else if (
        (aStatus === 'CONFIRMED' && bStatus === 'CONFIRMED') ||
        (aStatus !== 'CONFIRMED' && bStatus !== 'CONFIRMED')
    ) {
        return sortByDate(a, b);
    }
    return sortByDate(a, b);
};

const byUseful = (a, b) => {
    if (a?.confirmed !== b?.confirmed) {
        if (a?.confirmed) {
            return -1;
        }
        return 1;
    }
    if (a?.usefulCount > b?.usefulCount) {
        return -1;
    }
    if (a?.usefulCount < b?.usefulCount) {
        return 1;
    }
    return sortByDate(a, b);
};

const sortByOption = (arr, sortOption) => {
    if (sortOption?.id === 'byDate') {
        return arr.sort(sortByDate);
    } else if (sortOption?.id === 'byUsefulCount') {
        return arr.sort(byUseful);
    }
    return arr.sort(sortFn);
};

const DoctorFeedbacksList = ({
    data = [], isLoading, isAuthenticated, userId, doctorInfo,
}) => {
    const { setParams } = useNavigation();
    const sorting = useSelector(getFeedbackSort) ?? feedbackSortOptions()[0];
    const { params: { filter = 'all' } } = useRoute();

    const items = filter === 'all' ? data : data?.filter(item => item?.vote?.toLowerCase() === filter);
    const getQty = f => (f === 'all'
        ? data.filter(item => item?.feedbackText?.trim().length)?.length
        : data?.filter(item => item?.feedbackText?.trim()?.length && item?.vote?.toLowerCase() === f).length);

    const tabItems = [
        {
            id: 'all',
            name: Object.R('titles.allQty', { Qty: getQty('all') }).toUpperCase(),
            flex: 1,
        },
        {
            id: 'recommend',
            name: Object.R('titles.positiveFeedbackShortQty', { Qty: getQty('recommend') }).toUpperCase(),
            flex: 2,
        },
        {
            id: 'not_recommend',
            name: Object.R('titles.negativeFeedbackShortQty', { Qty: getQty('not_recommend') }).toUpperCase(),
            flex: 2,
        },
    ];

    if (isLoading) {
        return (
            <View
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignContent: 'center',
                }}>
                <LoadingIndicator />
            </View>
        );
    }

    return (
        <Section.White
            style={{
                flex: 1,
                marginBottom: 0,
                borderTopWidth: 1,
                borderColor: '#E9E9E9',
                marginTop: 2,
                paddingTop: 0,
            }}>
            {!!data?.length && (
                <View
                    style={{
                        // alignItems: 'space-between',
                        justifyContent: 'space-between',
                        // justifyItems: 'space-between',
                        flexDirection: 'row',
                        paddingHorizontal: 16,
                        paddingTop: 24,
                    }}>
                    <Text
                        style={{
                            fontSize: 16,
                            lineHeight: 17,
                            fontWeight: '600',
                            ...elementsStyles.text_bold,
                            color: COLORS.STANDARD_GRAY,
                        }}>
                        {Object.R('titles.allFeedbacks')}
                    </Text>
                    <TouchableOpacity>
                        <Icon.Sort
                            onPress={() => {
                                showSortModal();
                            }}
                            style={{
                                paddingTop: 0,
                                marginTop: 0,
                            }}
                        />
                    </TouchableOpacity>
                </View>
            )}
            {
                // isAuthenticated && userId &&
                !!data?.length && (
                    <TabSelector items={tabItems} selected={filter} onItem={e => setParams({ filter: e.id })} />
                )
            }
            {data?.length ? (
                <List
                    type="feedbacks"
                    white
                    data={sortByOption(
                        items
                            .filter(item => item.feedbackText.trim().length)
                            .map(item => ({
                                ...item,
                                confirmed: item?.status === 'CONFIRMED',
                            })),
                        sorting,
                    )}
                    isLoading={isLoading && items?.length === 0}
                    ListEmptyComponent={
                        data?.length ? (
                            <View style={{ paddingBottom: 50 }}>
                                <Stub.FeedbacksTab />
                            </View>
                        ) : (
                            <View style={{ paddingBottom: 50 }}>
                                <Stub.Feedbacks />
                            </View>
                        )
                    }
                    renderItem={({ item, index }) => {
                        try {
                            const anonymousText = Object.R('feedback.anonumous');
                            const {
                                feedbackText = '',
                                userName = anonymousText,
                                vote,
                                confirmed,
                                userCoverers,
                                anonymous,
                                responses,
                                updatedAt = '',
                                createdAt = '',
                                usefulCount,
                                _id,
                                usefulForMe,
                            } = item;
                            return (
                                <View
                                    style={{
                                        paddingTop: 2,
                                        paddingHorizontal: 16,
                                    }}>
                                    <FeedbackListItem
                                        fromList
                                        feedbackText={feedbackText}
                                        vote={vote}
                                        date={updatedAt}
                                        created={createdAt}
                                        userName={anonymous ? anonymousText : userName}
                                        showBorder={
                                            index < items.filter(i => i.feedbackText.trim().length)?.length - 1
                                        }
                                        confirmed={confirmed}
                                        userCoverers={anonymous ? null : userCoverers}
                                        interaction
                                        my={item?.userProfileId}
                                        usefulCount={usefulCount}
                                        usefulForMe={usefulForMe}
                                        responses={responses}
                                        onSetUseful={() => {
                                            if (isAuthenticated) {
                                                actions.modifyUseful({
                                                    id: _id,
                                                    value: !usefulForMe,
                                                });
                                                return;
                                            }
                                            unauthorizedForUseful(doctorInfo);
                                        }}
                                        onComplain={() => {
                                            Page.showModal(
                                                <PromptComplain
                                                    onSubmit={(text) => {
                                                        // eslint-disable-next-line no-console
                                                        try {
                                                            const postAbuse = isAuthenticated
                                                                ? api.postFeedbackAbuse
                                                                : api.postFeedbackAbuseAnonymous;
                                                            postAbuse(_id, text).then(() => showNotification('title.abusePosted'));
                                                        } catch (e) {
                                                            // eslint-disable-next-line no-console
                                                            console.log('Error', e);
                                                        }
                                                    }}
                                                />,
                                            );
                                        }}
                                    />
                                </View>
                            );
                        } catch (e) {
                            // eslint-disable-next-line no-console
                            console.log('Error', e);
                            return null;
                        }
                    }}
                />
            ) : (
                <View style={{ paddingBottom: 50 }}>
                    <Stub.Feedbacks />
                </View>
            )}
        </Section.White>
    );
};

const ErrorPage = ({ error, isLoading, action }) => (
    <ErrorStub error={error} support>
        <Box centered style={{ paddingHorizontal: 12 }}>
            <BigButton primary action={action} title="buttons.try_again" busy={isLoading} />
        </Box>
    </ErrorStub>
);

const DoctorsFeedbacksPageImpl = ({
    isAuthenticated,
    userInfo: { userId },
    feedbacks: { error, physicians: data, isLoading },
    isAccessNotAuthorizedError = error && error.code === 'USER_NOT_REGISTERED',
    forItemId,
    children,
    feedbacksSums,
    doctorInfo,
}) => {
    // const firstLoading = useRef(true);
    // const fetchFeedbacks = useRef(false);
    // const load = React.useCallback(() => {
    //     firstLoading.current = false;
    //     fetchFeedbacks.current = true;
    //     actions.fetchFeedbacks(forItemId, 'physicians')
    //         .finally(() => {
    //             fetchFeedbacks.current = false;
    //         });
    // }, [forItemId, feedbacksSums.feedbacksCount, feedbacksSums.textFeedbackCount, feedbacksSums.recommendCount, feedbacksSums.notRecommendCount, feedbacksSums.neutralCount]);
    // useFocusEffect(() => {
    //     if (firstLoading.current) {
    //         load();
    //     }
    // });
    //
    // useEffect(() => {
    //     if (!firstLoading.current && !fetchFeedbacks.current) {
    //         load();
    //     }
    // }, [feedbacksSums.feedbacksCount, feedbacksSums.textFeedbackCount, feedbacksSums.recommendCount, feedbacksSums.notRecommendCount, feedbacksSums.neutralCount, userId]);
    return (
        <Page name="feedbacks">
            {error && !isAccessNotAuthorizedError ? (
                <ErrorPage
                    error={error}
                    action={() => {
                        actions.fetchFeedbacks(forItemId, 'physicians', false);
                    }}
                    isLoading={isLoading}
                />
            ) : (
                <View style={{ flex: 1 }}>
                    <View accessibilityLabel="feedbacks_inner-top-block" style={{ paddingBottom: 6 }}>
                        {children}
                    </View>
                    <DoctorFeedbacksList
                        data={data}
                        isLoading={isLoading}
                        userId={userId}
                        isAuthenticated={isAuthenticated}
                        doctorInfo={doctorInfo}
                    />
                </View>
            )}
        </Page>
    );
};
export const PromptComplain = ({
    onCancel = () => {},
    onSubmit = () => {},
    keyboardAvoidingProps = Platform.OS === 'ios' ? { behavior: 'position' } : {},
}) => {
    const [text, setText] = useState('');
    return (
        <ModalImpl
            transparent
            animationType="fade"
            visible
            onRequestClose={() => {}}
            backdropColor="transparent"
            backdropOpacity={0}>
            <View style={[styles.overlay, { justifyContent: 'flex-end' }]}>
                <TouchableOpacity
                    style={styles.stub}
                    onPress={() => {
                        Page.closeModal();
                        // outside press
                    }}
                />
                <KeyboardAvoidingView {...keyboardAvoidingProps}>
                    {/* eslint-disable-next-line no-undef */}
                    <View
                        style={[
                            styles.container,
                            {
                                ...getMarginHorizontalObject(),
                                ...getBottomObject(-24),
                                paddingBottom: 0,
                                paddingHorizontal: 0,
                            },
                        ]}
                        accessibilityLabel="modal">
                        <View
                            style={{
                                marginTop: 12,
                                marginBottom: 20,
                            }}>
                            <Title
                                bold
                                centered
                                style={{
                                    marginBottom: 12,
                                    fontSize: 16,
                                    fontWeight: '600',
                                }}
                                numberOfLines={3}
                                id="title.complainTitle"
                                ns="modal_title"
                            />
                            <Subtitle
                                numberOfLines={3 || null}
                                style={[
                                    {
                                        paddingLeft: 12,
                                        fontSize: 14,
                                    },
                                ]}
                                id="title.complainSubtitle"
                                ns="modal_subtitle"
                            />
                            <View style={{ marginHorizontal: 12 }}>
                                <TextInput
                                    ns="abuse_input"
                                    style={{
                                        height: 84,
                                        paddingTop: 16,
                                        // marginHorizontal: 12,
                                        marginTop: 8,
                                        textAlignVertical: 'top',
                                    }}
                                    placeholder="placeholders.yourAbuse"
                                    multiline
                                    value={text}
                                    onChange={v => setText(v)}
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                margin: 0,
                                padding: 0,
                                flexDirection: 'column',
                            }}>
                            <View style={{ flexDirection: 'row' }}>
                                <View
                                    style={{
                                        flex: 1,
                                        borderColor: COLORS.BORDER_GRAY,
                                        borderWidth: 1,
                                        justifyContent: 'center',
                                        borderRightWidth: 0,
                                    }}>
                                    <TransparentButton
                                        danger
                                        title={Object.R('title.complainSubmit')}
                                        titleStyles={{ fontSize: 14 }}
                                        styles={{ marginBottom: 12 }}
                                        action={() => Page.closeModal(() => onSubmit(text))}
                                    />
                                </View>
                                <View
                                    style={{
                                        flex: 1,
                                        borderColor: COLORS.BORDER_GRAY,
                                        borderWidth: 1,
                                        justifyContent: 'center',
                                    }}>
                                    <TransparentButton
                                        blue
                                        title={Object.R('title.complainCancel')}
                                        titleStyles={{ fontSize: 14 }}
                                        styles={{ marginBottom: 12 }}
                                        action={() => {
                                            Page.closeModal(onCancel);
                                        }}
                                    />
                                </View>
                            </View>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </View>
        </ModalImpl>
    );
};

/**
 * List of feedback items for given entity.
 */
export const DoctorsFeedbacksPage = entityData(getActualFeedbacks, DoctorsFeedbacksPageImpl);
