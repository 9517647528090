/* eslint-disable no-underscore-dangle */
import {
    BigButton,
    Button,
    ButtonsGroup,
    Component,
    List,
    Page,
    React,
    RText,
    Subtitle,
    VBox,
    View,
} from '../../common';
import api from '../../services/api';
import actions, { navigate } from '../../actions';
import { InvoiceRejectModal } from './InvoiceRejectModal';
import { InvoiceCard } from './InvoiceCard';
import { InvoiceSingleService } from './InvoiceSingleService';
import { InvoiceNotification } from './InvoiceNotification';
import { invoiceItem as s } from './style';

// item:
// clinicId: "5b3f5fa4529813245"
// date: "2018-11-30"
// personId: "5bf51137b64f1ac417d889df"
// records: [{…}]
// totalCost: 12.57
// umsPath: "Лабораторная диагностика / Анализ крови"
// _id: "5c2e03de2a33cb31bc901b23"

// records: Array(1)
// 0:
// amount: 1
// rawData: [{…}]
// serviceName: "Общий анализ крови"
// status: "NEW"
// totalCost: 12.57
// _id: "5c2e03de2a33cb31bc901b24"

export const InvoicesButtonGroup = (props) => {
    const {
        pressedState,
        processingInvoice,
        id,
        recordsCount,
        item,
    } = props;
    return (
        <View>
            {recordsCount === 1 && pressedState === '' &&
                <ButtonsGroup gap={0} style={[s.border, {
                    marginBottom: 24,
                    paddingHorizontal: 0,
                }]}>
                    <Button
                        title="buttons.reject"
                        styles={[s.invoiceButton, s.borderRight]}
                        titleStyles={{
                            color: '#DF655C',
                            fontWeight: '600',
                        }}
                        action={() => {
                            Page.showModal(<InvoiceRejectModal
                                {...props}
                                closeModal={Page.closeModal}
                                updateRejectedInfo={props.updateRejectedInfo}
                            />);
                        }
                        }
                    />
                    <Button
                        title="buttons.confirm"
                        styles={[s.invoiceButton]}
                        titleStyles={{
                            color: '#18B797',
                            fontWeight: '600',
                        }}
                        action={() => processingInvoice([id], [], 'approved')}
                    />
                </ButtonsGroup>
            }
            {recordsCount > 1 && pressedState === '' &&
                <BigButton
                    styles={[s.invoiceButton, s.border, {
                        marginTop: 0,
                        marginBottom: 24,
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    }]}
                    action={() => {
                        navigate('InvoiceMultiService', {
                            ...item,
                            processingInvoice,
                            pressedState,
                        });
                    }}
                >
                    <RText color="#5C85DD" id="positions" bold count={recordsCount}/>
                </BigButton>
            }
            <InvoiceNotification pressedState={pressedState}/>
        </View>
    );
};
// TODO: it will be refactor using Page state instead local component state.
// Don't have ability to doi it now, because current dev version can block my work.
export class InvoiceItem extends Component {
    state = {
        pressedState: '',
        rejectedInfo: {},
    };

    updatePressedState = (type) => {
        this.setState({ pressedState: type });
    };

    resetRejectedInfo() {
        this.setState({ rejectedInfo: {} });
    }

    updateRejectedInfo = (info) => {
        const isEmpty = Object.keys(info).length === 0;
        isEmpty ? this.resetRejectedInfo() : this.processingInvoice([], info, 'rejected');
    };

    processingInvoice = (id, rejectInfo, type, delay = 1000) => {
        this.updatePressedState(type);
        setTimeout(async () => {
            this.resetRejectedInfo();
            await api.processingInvoices(id, rejectInfo);
            await actions.fetchInvoices(this.props.item.invoices);
            this.updatePressedState('');
        }, delay);
    };

    render() {
        const {
            item,
            item: { records },
        } = this.props;
        const { pressedState } = this.state;
        const recordsCount = records.length;
        return (
            <View>
                <InvoiceCard
                    forNewList
                    onPress={() => {
                        navigate('InvoiceMultiService', {
                            ...item,
                            processingInvoice: this.processingInvoice,
                            pressedState,
                        });
                    }}
                    {...this.props}
                />
                {recordsCount !== 1 ? null
                    : <InvoiceSingleService {...records[0]} forNewList/>
                }
                <InvoicesButtonGroup
                    item={item}
                    id={records[0]._id}
                    pressedState={pressedState}
                    updateRejectedInfo={this.updateRejectedInfo}
                    processingInvoice={this.processingInvoice}
                    recordsCount={recordsCount}
                    {...this.props}
                />
            </View>
        );
    }
}

// TODO: make components for lists
// noinspection JSUnusedGlobalSymbols
export const InvoicesListsSection = (props) => {
    const {
        invoices,
        invoices: { newServicesForReview: { items: itemsForReview } },
        clinics,
        userInfo,
        relatives,
        me,
    } = props;
    const { invoices: { reviewedServices: { items: itemsReviewed } } } = props;
    return (
        <View>
            <VBox>
                <Subtitle id="titles.expecting_confirmations" bold style={{ marginBottom: 20 }}/>
                <List
                    accessibilityLabel="invoices_for_review_list"
                    data={itemsForReview}
                    renderItem={({ item }) => <InvoiceItem item={{
                        ...item,
                        clinicInfo: { ...clinics[item.clinicId] },
                        userInfo,
                        invoices,
                        visitor: { ...relatives.find(({ personId }) => personId === item.personId) || me },
                    }}/>}
                />
            </VBox>
            <VBox>
                <Subtitle id="titles.all_services" bold style={{ marginBottom: 20 }}/>
                <List
                    accessibilityLabel="invoices_reviewed_list"
                    data={itemsReviewed}
                    renderItem={({ item }) => (
                        <InvoiceCard
                            item={{
                                ...item,
                                clinicInfo: { ...clinics[item.clinicId] },
                                visitor: { ...relatives.find(({ personId }) => personId === item.personId) || me },
                            }}
                            onPress={() => {
                                navigate('InvoiceReviewedInfo', {
                                    ...item,
                                    clinicInfo: { ...clinics[item.clinicId] },
                                    visitor: { ...relatives.find(({ personId }) => personId === item.personId) || me },
                                });
                            }}
                            style={{ marginBottom: 12 }}
                            forReviwedList
                        />
                    )}
                />
            </VBox>
        </View>
    );
};
