export const IS_FOR_ME = 'is_for_me';
export const IS_FOR_CHILD = 'is_for_child';
export const IS_OTHER = 'is_other';

export const visitForArr1 = [
    {
        id: IS_FOR_ME,
        name: 'titles.forMe',
    },
    {
        id: IS_FOR_CHILD,
        name: 'titles.forMyChild',
    },
];
export const visitForArr2 = [
    {
        id: IS_OTHER,
        name: 'titles.other',
    },
];
