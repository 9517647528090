
export default {
    '5bbd02c57cf5cf2c1cc9bbc8': {
        title:
            'Использование услуги: Консультация аллерголога *** [Аллергология / Консультация специалиста]',
        providers: [],
        priceLists: [
            {
                title:
                    'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                _id: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                sourceFileId: '5bb346241966cb52609f3393',
                sourceFileName: 'Прейскурант - Элеос (01.08.2018).xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2018-08-01',
                groupCount: 26,
                priceCount: 798,
            },
            {
                title: 'Раздел 13. АЛЛЕРГОЛОГИЯ',
                _id: '5bedb84560fb744b843b108d',
                providerId: '5b3f5a696cadf395a',
                sourceFileId: '5bedb84560fb744b843b1068',
                sourceFileName: 'price_nordin_v2018-07-31_all.xlsx',
                sourceTabIndex: 3,
                sourceTabName: 'Аллер.',
                effFromDate: '2018-08-01',
                groupCount: 4,
                priceCount: 37,
            },
            {
                title: 'ЛДО',
                _id: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                sourceFileName: 'price_ecomed_v2018-08-23_ter.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2018-08-23',
                groupCount: 19,
                priceCount: 160,
            },
            {
                title:
                    'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                _id: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                sourceFileName: 'price_eleos_v2019-01-29.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2019-01-29',
                groupCount: 31,
                priceCount: 972,
            },
            {
                title: 'Прием врачей-специалистов',
                _id: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                sourceFileName: 'price_lode_minsk_v2019-03-29_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 71,
            },
            {
                title: 'Врачи-специалисты',
                _id: '5ca4e2300a295f74506435fa',
                providerId: '5b3f5cf17c5d77cc4',
                sourceFileId: '5ca4e2300a295f74506435f6',
                sourceFileName: 'price_lode_grodno_v2019-04-03_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 26,
            },
            {
                title: 'Прием врачей-специалистов',
                _id: '5ca6074f07ccff53f01362ba',
                providerId: '5b3f5ac1f2075180c',
                sourceFileId: '5ca6074f07ccff53f013626e',
                sourceFileName: 'price_lode_brest_v2019-04-04_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 16,
            },
            {
                title: 'Прием врачей-специалистов (иностр.)',
                _id: '5ca6074f07ccff53f01362bc',
                providerId: '5b3f5ac1f2075180c',
                sourceFileId: '5ca6074f07ccff53f013626e',
                sourceFileName: 'price_lode_brest_v2019-04-04_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 16,
            },
        ],
        files: [
            {
                _id: '5bb346241966cb52609f3393',
                filename: 'Элеос (2018-08-01).xlsx',
                uploadDate: 1538475558678,
                length: 75109,
                md5: 'd4499480ebec1e83eae63da7593af13a',
                meta: {
                    sourceFileName: 'Прейскурант - Элеос (01.08.2018).xlsx',
                    minPlDate: '2018-08-01',
                    maxPlDate: '2018-08-01',
                    priceLists: [
                        {
                            id: '5bb346241966cb52609f3394',
                            title:
                                'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                            effDate: '2018-08-01',
                        },
                    ],
                },
            },
            {
                _id: '5bedb84560fb744b843b1068',
                filename: 'Нордин (2018-08-01).xlsx',
                uploadDate: 1542305864216,
                length: 820892,
                md5: '19ddf79e934ec2e7ce6340e0f7a05f56',
                meta: {
                    providerId: '5b3f5a696cadf395a',
                    sourceFileName: 'price_nordin_v2018-07-31_all.xlsx',
                    minPlDate: '2018-08-01',
                    maxPlDate: '2018-08-01',
                    priceLists: [
                        {
                            id: '5bedb84560fb744b843b1069',
                            title: 'Раздел 1. СТОМАТОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84560fb744b843b1087',
                            title: 'Раздел 20. ЛУЧЕВАЯ ДИАГНОСТИКА (2 этаж)',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84560fb744b843b108a',
                            title: 'Раздел 27: АНЕСТЕЗИОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84560fb744b843b108d',
                            title: 'Раздел 13. АЛЛЕРГОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84560fb744b843b1092',
                            title: 'Раздел 2. АКУШЕРСТВО И ГИНЕКОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84560fb744b843b1099',
                            title: 'Раздел 15. ДЕРМАТОВЕНЕРОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84560fb744b843b10a1',
                            title: 'Раздел 8. ЛАБОРАТОРНАЯ ДИАГНОСТИКА',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b10f4',
                            title: 'Раздел 31. ЛОГОПЕДИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b10f7',
                            title: 'Раздел 6. ОТОРИНОЛАРИНГОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b10fe',
                            title: 'Раздел 9. МАССАЖ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b1101',
                            title: 'Раздел 4. ОФТАЛЬМОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b1109',
                            title: 'Раздел 10. ПРИЕМ ВРАЧАМИ-СПЕЦИАЛИСТАМИ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b110f',
                            title: 'Раздел 17. ПРОКТОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b1116',
                            title: 'Раздел 11. ПРОЦЕДУРНЫЙ КАБИНЕТ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b111e',
                            title: 'Раздел 12. ПСИХОТЕРАПИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b1124',
                            title: 'Раздел 20. ЛУЧЕВАЯ ДИАГНОСТИКА 1 этаж',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b1134',
                            title: 'Раздел 7. УЛЬТРАЗВУКОВАЯ ДИАГНОСТИКА',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b113d',
                            title: 'Раздел 3. УРОЛОГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b1144',
                            title: 'Раздел 14. ФИЗИОТЕРАПИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b114c',
                            title: 'Раздел 22. ФУНКЦИОНАЛЬНАЯ ДИАГНОСТИКА',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b1157',
                            title: 'Раздел 5. ХИРУРГИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b115d',
                            title: 'Раздел 16. ЭНДОСКОПИЯ',
                            effDate: '2018-08-01',
                        },
                        {
                            id: '5bedb84660fb744b843b116c',
                            title:
                                'Раздел 33. Клинико-морфологическая (гистологическая) лаборатория',
                            effDate: '2018-08-01',
                        },
                    ],
                },
            },
            {
                _id: '5bedbf0e60fb7419e8f2d248',
                filename: 'Экомедсервис (2018-08-23).xlsx',
                uploadDate: 1542307598271,
                length: 46909,
                md5: '2b033d82a5134e10832f54ee1e07939f',
                meta: {
                    providerId: '5b3f59191860bcdde',
                    sourceFileName: 'price_ecomed_v2018-08-23_ter.xlsx',
                    minPlDate: '2018-08-23',
                    maxPlDate: '2018-08-23',
                    priceLists: [
                        {
                            id: '5bedbf0e60fb7419e8f2d249',
                            title: 'ЛДО',
                            effDate: '2018-08-23',
                        },
                    ],
                },
            },
            {
                _id: '5c59a32f198e5d1108315a6e',
                filename: 'Элеос (2019-01-29).xlsx',
                uploadDate: 1549378353602,
                length: 86695,
                md5: 'a26ee6fe7b5a47550a183b5790d0493c',
                meta: {
                    providerId: '5b4c5eb620ae653c1',
                    sourceFileName: 'price_eleos_v2019-01-29.xlsx',
                    minPlDate: '2019-01-29',
                    maxPlDate: '2019-01-29',
                    priceLists: [
                        {
                            id: '5c59a32f198e5d1108315a6f',
                            title:
                                'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                            effDate: '2019-01-29',
                        },
                    ],
                },
            },
            {
                _id: '5c9e335cfbd3f4b95050117e',
                filename: 'Лодэ-Минск (2019-03-29).xlsx',
                uploadDate: 1553871710665,
                length: 322226,
                md5: '12e2cd45593b51322a0289118854eaa6',
                meta: {
                    providerId: '5b3f5fa4529813245',
                    sourceFileName: 'price_lode_minsk_v2019-03-29_all.xlsx',
                    priceLists: [
                        {
                            id: '5c9e335cfbd3f4b95050117f',
                            title: 'Аллергология',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501182',
                            title: 'Анестезиология',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501184',
                            title: 'Анестезиология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501186',
                            title: 'Выезд врача на дом',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501188',
                            title: 'Гинекология',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050118a',
                            title:
                                'Годовое комплексное обслуживание и обследование',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050118c',
                            title: 'Дерматовенерология',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050118e',
                            title: 'Дополнительно',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501190',
                            title: 'Зуботехнические работы',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501192',
                            title: 'Иммунопрофилактика',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501194',
                            title: 'Консультация врачей-специалистов',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501196',
                            title: 'Косметические (бытовые) услуги',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501198',
                            title: 'Косметология хирургическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050119a',
                            title: 'Косметология хирургическая (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050119c',
                            title: 'Лабораторная диагностика',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050119e',
                            title: 'Лазерная косметология',
                        },
                        { id: '5c9e335cfbd3f4b9505011a0', title: 'Логопедия' },
                        {
                            id: '5c9e335cfbd3f4b9505011a2',
                            title: 'Магнитно-резонансная томография (МРТ)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011a4',
                            title:
                                'Магнитно-резонансная томография (МРТ) (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011a6',
                            title: 'Манипуляции общего назначения',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011a8',
                            title: 'Манипуляции общего назначения (иностр.)',
                        },
                        { id: '5c9e335cfbd3f4b9505011aa', title: 'Массаж' },
                        {
                            id: '5c9e335cfbd3f4b9505011ac',
                            title: 'Медицинские осмотры и обследования',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ae',
                            title: 'Общие стоматологические мероприятия',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b0',
                            title:
                                'Оказание медицинской помощи в стационаре в послеоперационный период',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b2',
                            title:
                                'Оказание медицинской помощи в стационаре в послеоперационный период (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b4',
                            title: 'Оперативная гинекология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b6',
                            title: 'Оперативная гинекология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b8',
                            title: 'Оперативная оториноларингология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ba',
                            title: 'Оперативная оториноларингология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011bc',
                            title: 'Оперативная проктология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011be',
                            title: 'Оперативная проктология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c0',
                            title: 'Оперативная урология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c2',
                            title: 'Оперативная урология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c4',
                            title: 'Оперативная хирургия',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c6',
                            title: 'Оперативная хирургия (иностр.)',
                        },
                        { id: '5c9e335cfbd3f4b9505011c8', title: 'Ортопедия' },
                        {
                            id: '5c9e335cfbd3f4b9505011ca',
                            title: 'Оториноларингология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011cc',
                            title: 'Офтальмология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ce',
                            title: 'Офтальмология хирургическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d0',
                            title: 'Офтальмология хирургическая (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d2',
                            title: 'Прием врача-педиатра',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d4',
                            title: 'Прием врачей-специалистов',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d6',
                            title: 'Проктология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d8',
                            title: 'Психотерапия',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011da',
                            title: 'реестр материалов (ВРТ)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011dc',
                            title: 'реестр материалов (наборы для оперблока)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011de',
                            title: 'реестр материалов (оперблок)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e0',
                            title: 'реестр материалов (стоматология)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e2',
                            title: 'Рентгеновская компьютерная томография (РКТ)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e4',
                            title:
                                'Рентгеновская компьютерная томография (РКТ) (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e6',
                            title: 'Рентгенологическая диагностика',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e8',
                            title: 'Рентгенологические исследования',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ea',
                            title: 'Стоматология ортодонтическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ec',
                            title: 'Стоматология ортопедическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ee',
                            title: 'Стоматология терапевтическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011f0',
                            title: 'Стоматология хирургическая',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011f2',
                            title: 'сторонние орган. (лаборатория)',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011f4',
                            title: 'сторонние орган. (стоматология)',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011f6',
                            title: 'Ультразвуковое исследование',
                        },
                        { id: '5c9e335dfbd3f4b9505011f8', title: 'Урология' },
                        {
                            id: '5c9e335dfbd3f4b9505011fa',
                            title: 'Физиотерапия',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011fc',
                            title: 'Функциональная диагностика',
                        },
                        { id: '5c9e335dfbd3f4b9505011fe', title: 'Хирургия' },
                        {
                            id: '5c9e335dfbd3f4b950501200',
                            title: 'Цифровые рентгенологические исследования',
                        },
                        { id: '5c9e335dfbd3f4b950501202', title: 'ЭКО' },
                        {
                            id: '5c9e335dfbd3f4b950501204',
                            title: 'Эндоскопические исследования',
                        },
                    ],
                },
            },
            {
                _id: '5ca4e2300a295f74506435f6',
                filename: 'Лодэ-Гродно (2019-04-03).xlsx',
                uploadDate: 1554309682294,
                length: 155251,
                md5: '51bdb44c24dedcef336859684a0c8991',
                meta: {
                    providerId: '5b3f5cf17c5d77cc4',
                    sourceFileName: 'price_lode_grodno_v2019-04-03_all.xlsx',
                    priceLists: [
                        {
                            id: '5ca4e2300a295f74506435f7',
                            title: 'Аллергология',
                        },
                        {
                            id: '5ca4e2300a295f74506435fa',
                            title: 'Врачи-специалисты',
                        },
                        {
                            id: '5ca4e2300a295f74506435fc',
                            title: 'Выезд на дом',
                        },
                        {
                            id: '5ca4e2300a295f74506435fe',
                            title: 'Гинекология',
                        },
                        {
                            id: '5ca4e2300a295f7450643600',
                            title: 'Дерматовенерология',
                        },
                        {
                            id: '5ca4e2300a295f7450643602',
                            title: 'Дополнительно',
                        },
                        {
                            id: '5ca4e2300a295f7450643604',
                            title: 'Иммунопрофилактика',
                        },
                        {
                            id: '5ca4e2300a295f7450643606',
                            title: 'Консультации',
                        },
                        {
                            id: '5ca4e2300a295f7450643608',
                            title: 'Лаборатория ЛОДЭ',
                        },
                        {
                            id: '5ca4e2300a295f745064360a',
                            title: 'Лабораторная диагностика (Минск)',
                        },
                        { id: '5ca4e2300a295f745064360c', title: 'Логопедия' },
                        {
                            id: '5ca4e2300a295f745064360e',
                            title: 'Манипуляции',
                        },
                        { id: '5ca4e2300a295f7450643610', title: 'Массаж' },
                        {
                            id: '5ca4e2300a295f7450643612',
                            title: 'Озонотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643614',
                            title: 'Оперативная гинекология',
                        },
                        {
                            id: '5ca4e2300a295f7450643616',
                            title: 'Оперативная оториноларингология',
                        },
                        {
                            id: '5ca4e2300a295f7450643618',
                            title: 'Оперативная проктология',
                        },
                        {
                            id: '5ca4e2300a295f745064361a',
                            title: 'Оперативная хирургия',
                        },
                        {
                            id: '5ca4e2300a295f745064361c',
                            title: 'Оториноларингология',
                        },
                        {
                            id: '5ca4e2300a295f745064361e',
                            title: 'Офтальмология',
                        },
                        { id: '5ca4e2300a295f7450643620', title: 'Педиатрия' },
                        {
                            id: '5ca4e2300a295f7450643622',
                            title: 'Проктология',
                        },
                        {
                            id: '5ca4e2300a295f7450643624',
                            title: 'Психотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643626',
                            title: 'Рефлексотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643628',
                            title: 'Стоматология',
                        },
                        {
                            id: '5ca4e2300a295f745064362a',
                            title: 'Сторонние организации',
                        },
                        {
                            id: '5ca4e2300a295f745064362c',
                            title: 'Ультразвуковое исследование',
                        },
                        { id: '5ca4e2300a295f745064362e', title: 'Урология' },
                        {
                            id: '5ca4e2300a295f7450643630',
                            title: 'Физиотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643632',
                            title: 'Функциональная диагностика',
                        },
                        { id: '5ca4e2300a295f7450643634', title: 'Хирургия' },
                    ],
                },
            },
            {
                _id: '5ca6074f07ccff53f013626e',
                filename: 'Лодэ-Брест (2019-04-04).xlsx',
                uploadDate: 1554384721156,
                length: 2889592,
                md5: 'f1e6bf12ff5f69fe9c37b9682f2d5080',
                meta: {
                    providerId: '5b3f5ac1f2075180c',
                    sourceFileName: 'price_lode_brest_v2019-04-04_all.xlsx',
                    priceLists: [
                        {
                            id: '5ca6074f07ccff53f013626f',
                            title: 'Аллергология',
                        },
                        {
                            id: '5ca6074f07ccff53f0136272',
                            title: 'Анестезиология',
                        },
                        {
                            id: '5ca6074f07ccff53f0136274',
                            title: 'Анестезиология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f0136276',
                            title: 'Выезд врача на дом',
                        },
                        {
                            id: '5ca6074f07ccff53f0136278',
                            title: 'Гинекология',
                        },
                        {
                            id: '5ca6074f07ccff53f013627a',
                            title: 'Гинекология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f013627c',
                            title: 'Дерматовенерология',
                        },
                        {
                            id: '5ca6074f07ccff53f013627e',
                            title: 'Дерматовенерология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f0136280',
                            title: 'Дополнительно',
                        },
                        {
                            id: '5ca6074f07ccff53f0136282',
                            title: 'Иммунопрофилактика',
                        },
                        {
                            id: '5ca6074f07ccff53f0136284',
                            title:
                                'Косметология хирургическая (пластическая эстетическая хирургия)',
                        },
                        {
                            id: '5ca6074f07ccff53f0136286',
                            title:
                                'Косметология хирургическая (пластическая эстетическая хирургия)(иностр)',
                        },
                        {
                            id: '5ca6074f07ccff53f0136288',
                            title: 'Лабораторная диагностика',
                        },
                        {
                            id: '5ca6074f07ccff53f013628a',
                            title: 'Лабораторная диагностика (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f013628c',
                            title: 'Лабораторная диагностика (Минск)',
                        },
                        {
                            id: '5ca6074f07ccff53f013628e',
                            title: 'Лазерная хирургия',
                        },
                        {
                            id: '5ca6074f07ccff53f0136290',
                            title: 'Лазерная хирургия (иност)',
                        },
                        { id: '5ca6074f07ccff53f0136292', title: 'Логопедия' },
                        {
                            id: '5ca6074f07ccff53f0136294',
                            title: 'Манипуляции общего назначения',
                        },
                        {
                            id: '5ca6074f07ccff53f0136296',
                            title: 'Манипуляции общего назначения (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f0136298',
                            title: 'Медосмотр сотрудников БПС-банка',
                        },
                        {
                            id: '5ca6074f07ccff53f013629a',
                            title: 'Общие стоматологические мероприятия',
                        },
                        {
                            id: '5ca6074f07ccff53f013629c',
                            title:
                                'Общие стоматологические мероприятия (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f013629e',
                            title:
                                'Оказание медицинской помощи в стационаре в послеоперационный период',
                        },
                        {
                            id: '5ca6074f07ccff53f01362a0',
                            title:
                                'Оказание медицинской помощи в стационаре в послеоперационный период (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362a2',
                            title: 'Оперативная гинекология',
                        },
                        {
                            id: '5ca6074f07ccff53f01362a4',
                            title: 'Оперативная гинекология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362a6',
                            title: 'Оперативная урология',
                        },
                        {
                            id: '5ca6074f07ccff53f01362a8',
                            title: 'Оперативная урология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362aa',
                            title: 'Оперативная хирургия',
                        },
                        {
                            id: '5ca6074f07ccff53f01362ac',
                            title: 'Оперативная хирургия (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362ae',
                            title: 'Оториноларингология',
                        },
                        {
                            id: '5ca6074f07ccff53f01362b0',
                            title: 'Оториноларингология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362b2',
                            title: 'Офтальмология',
                        },
                        {
                            id: '5ca6074f07ccff53f01362b4',
                            title: 'Офтальмология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362b6',
                            title: 'Прием врача-педиатра',
                        },
                        {
                            id: '5ca6074f07ccff53f01362b8',
                            title: 'Прием врача-педиатра (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362ba',
                            title: 'Прием врачей-специалистов',
                        },
                        {
                            id: '5ca6074f07ccff53f01362bc',
                            title: 'Прием врачей-специалистов (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362be',
                            title: 'Проктология',
                        },
                        {
                            id: '5ca6074f07ccff53f01362c0',
                            title: 'Проктология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362c2',
                            title: 'реестр материалов (общий)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362c4',
                            title: 'реестр материалов (стоматология)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362c6',
                            title: 'Рентгенологическая диагностика',
                        },
                        {
                            id: '5ca6074f07ccff53f01362c8',
                            title: 'Рентгенологическая диагностика (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362ca',
                            title: 'Стоматология ортопедическая',
                        },
                        {
                            id: '5ca6074f07ccff53f01362cc',
                            title: 'Стоматология ортопедическая (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362ce',
                            title: 'Стоматология терапевтическая',
                        },
                        {
                            id: '5ca6074f07ccff53f01362d0',
                            title: 'Стоматология терапевтическая (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362d2',
                            title: 'Стоматология хирургическая',
                        },
                        {
                            id: '5ca6074f07ccff53f01362d4',
                            title: 'Стоматология хирургическая (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362d6',
                            title: 'Сторонние организации',
                        },
                        {
                            id: '5ca6074f07ccff53f01362d8',
                            title: 'Ультразвуковое исследование',
                        },
                        {
                            id: '5ca6074f07ccff53f01362da',
                            title: 'Ультразвуковое исследование (иностр.)',
                        },
                        { id: '5ca6074f07ccff53f01362dc', title: 'Урология' },
                        {
                            id: '5ca6074f07ccff53f01362de',
                            title: 'Урология (иностр.)',
                        },
                        {
                            id: '5ca6074f07ccff53f01362e0',
                            title: 'Функциональная диагностика',
                        },
                        {
                            id: '5ca6074f07ccff53f01362e2',
                            title: 'Функциональная диагностика (иностр.)',
                        },
                        { id: '5ca6074f07ccff53f01362e4', title: 'Хирургия' },
                        {
                            id: '5ca6074f07ccff53f01362e6',
                            title: 'Хирургия (иностр.)',
                        },
                        { id: '5ca6074f07ccff53f01362e8', title: '' },
                    ],
                },
            },
        ],
        groups: [
            {
                codeDepth: -1,
                nameAsIs: 'Услуги',
                _id: '5bb346241966cb52609f3395',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs:
                    'Оказание медицинской помощи в отделении круглосуточного и дневного пребывания',
                _id: '5bb346241966cb52609f3396',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Консультация врачей-специалистов',
                _id: '5bb346241966cb52609f3397',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs: 'Лечебная физкультура',
                _id: '5bb346241966cb52609f3398',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '4',
                nameAsIs: 'Манипуляции общего назначения',
                _id: '5bb346241966cb52609f3399',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '5',
                nameAsIs: 'Ультразвуковые исследования',
                _id: '5bb346241966cb52609f339a',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '6',
                nameAsIs: 'Функциональная диагностика',
                _id: '5bb346241966cb52609f339b',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '7',
                nameAsIs: 'Стоматология',
                _id: '5bb346241966cb52609f339c',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.1',
                nameAsIs: 'Общие мероприятия',
                _id: '5bb346241966cb52609f339d',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f339c',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.2',
                nameAsIs: 'Терапия',
                _id: '5bb346241966cb52609f339e',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f339c',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.3',
                nameAsIs: 'Хирургия',
                _id: '5bb346241966cb52609f339f',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f339c',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.4',
                nameAsIs: 'Ортопедия',
                _id: '5bb346241966cb52609f33a0',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f339c',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.8',
                nameAsIs: 'Рентген',
                _id: '5bb346241966cb52609f33a1',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f339c',
            },
            {
                codeDepth: 0,
                codeAsIs: '9',
                nameAsIs: 'Физиотерапия',
                _id: '5bb346241966cb52609f33a2',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 1,
                codeAsIs: '9.1',
                nameAsIs: 'Озонотерапия',
                _id: '5bb346241966cb52609f33a3',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f33a2',
            },
            {
                codeDepth: 0,
                codeAsIs: '10',
                nameAsIs: 'Массаж',
                _id: '5bb346241966cb52609f33a4',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '11',
                nameAsIs: 'Оториноларингология',
                _id: '5bb346241966cb52609f33a5',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '12',
                nameAsIs: 'Дерматовенерология',
                _id: '5bb346241966cb52609f33a6',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '14',
                nameAsIs: 'Общая хирургия, хирургические манипуляции',
                _id: '5bb346241966cb52609f33a7',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '15',
                nameAsIs: 'Офтальмология',
                _id: '5bb346241966cb52609f33a8',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '17',
                nameAsIs: 'Гинекология',
                _id: '5bb346241966cb52609f33a9',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '18',
                nameAsIs: 'Рефлексотерапия',
                _id: '5bb346241966cb52609f33aa',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '21',
                nameAsIs: 'Психиатрическая помощь',
                _id: '5bb346241966cb52609f33ab',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '23',
                nameAsIs: 'Взрослая психотерапия',
                _id: '5bb346241966cb52609f33ac',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '28',
                nameAsIs: 'Аллергология',
                _id: '5bb346241966cb52609f33ad',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: 0,
                codeAsIs: '8',
                nameAsIs: 'Клинические лабораторные исследования',
                _id: '5bb346241966cb52609f33ae',
                sourceFileId: '5bb346241966cb52609f3393',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                parentGroupId: '5bb346241966cb52609f3395',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Раздел 13. АЛЛЕРГОЛОГИЯ',
                _id: '5bedb84560fb744b843b108e',
                sourceFileId: '5bedb84560fb744b843b1068',
                providerId: '5b3f5a696cadf395a',
                priceListId: '5bedb84560fb744b843b108d',
            },
            {
                codeDepth: 0,
                codeAsIs: '13',
                nameAsIs: 'АЛЛЕРГОЛОГИЯ',
                _id: '5bedb84560fb744b843b108f',
                sourceFileId: '5bedb84560fb744b843b1068',
                providerId: '5b3f5a696cadf395a',
                priceListId: '5bedb84560fb744b843b108d',
                parentGroupId: '5bedb84560fb744b843b108e',
            },
            {
                codeDepth: 1,
                codeAsIs: '13.1',
                nameAsIs: 'Прием лечебно-диагностический первичный и повторный',
                _id: '5bedb84560fb744b843b1090',
                sourceFileId: '5bedb84560fb744b843b1068',
                providerId: '5b3f5a696cadf395a',
                priceListId: '5bedb84560fb744b843b108d',
                parentGroupId: '5bedb84560fb744b843b108f',
            },
            {
                codeDepth: 1,
                codeAsIs: '13.2',
                nameAsIs: 'Прочие услуги',
                _id: '5bedb84560fb744b843b1091',
                sourceFileId: '5bedb84560fb744b843b1068',
                providerId: '5b3f5a696cadf395a',
                priceListId: '5bedb84560fb744b843b108d',
                parentGroupId: '5bedb84560fb744b843b108f',
            },
            {
                codeDepth: 0,
                codeAsIs: '200000',
                nameAsIs: 'ЛДО',
                _id: '5bedbf0e60fb7419e8f2d24a',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
            },
            {
                codeDepth: 1,
                codeAsIs: '201000',
                nameAsIs: 'Психотерапия',
                _id: '5bedbf0e60fb7419e8f2d24b',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '210000',
                nameAsIs: 'Эндокринология',
                _id: '5bedbf0e60fb7419e8f2d24c',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '211000',
                nameAsIs: 'гастроэнтерология',
                _id: '5bedbf0e60fb7419e8f2d24d',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '213000',
                nameAsIs: 'Терапия',
                _id: '5bedbf0e60fb7419e8f2d24e',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '214000',
                nameAsIs: 'Кардиология',
                _id: '5bedbf0e60fb7419e8f2d24f',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '215000',
                nameAsIs: 'Вакцинация',
                _id: '5bedbf0e60fb7419e8f2d250',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '225000',
                nameAsIs: 'Неврология',
                _id: '5bedbf0e60fb7419e8f2d251',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '226000',
                nameAsIs: 'Ревматология',
                _id: '5bedbf0e60fb7419e8f2d252',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '227000',
                nameAsIs: 'Ортопедия',
                _id: '5bedbf0e60fb7419e8f2d253',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '228000',
                nameAsIs: 'Аллергология и иммунология',
                _id: '5bedbf0e60fb7419e8f2d254',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '230000',
                nameAsIs: 'Логопедия',
                _id: '5bedbf0e60fb7419e8f2d255',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '233000',
                nameAsIs: 'Педиатрия',
                _id: '5bedbf0e60fb7419e8f2d256',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '240000',
                nameAsIs: 'Рефлексотерапия',
                _id: '5bedbf0e60fb7419e8f2d257',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '241000',
                nameAsIs: 'Физиотерапия',
                _id: '5bedbf0e60fb7419e8f2d258',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '242000',
                nameAsIs: 'Массаж',
                _id: '5bedbf0e60fb7419e8f2d259',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '244000',
                nameAsIs: 'Дерматология',
                _id: '5bedbf0e60fb7419e8f2d25a',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '251000',
                nameAsIs: 'Манипуляции общего назначения',
                _id: '5bedbf0e60fb7419e8f2d25b',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '252000',
                nameAsIs: 'Диетология',
                _id: '5bedbf0e60fb7419e8f2d25c',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Услуги',
                _id: '5c59a32f198e5d1108315a71',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs:
                    'Оказание медицинской помощи в отделении круглосуточного и дневного пребывания',
                _id: '5c59a32f198e5d1108315a72',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Консультация врачей-специалистов',
                _id: '5c59a32f198e5d1108315a73',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs: 'Лечебная физкультура',
                _id: '5c59a32f198e5d1108315a74',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '4',
                nameAsIs: 'Манипуляции общего назначения',
                _id: '5c59a32f198e5d1108315a75',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '5',
                nameAsIs: 'Ультразвуковые исследования',
                _id: '5c59a32f198e5d1108315a76',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '6',
                nameAsIs: 'Функциональная диагностика',
                _id: '5c59a32f198e5d1108315a77',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '7',
                nameAsIs: 'Стоматология',
                _id: '5c59a32f198e5d1108315a78',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.1',
                nameAsIs: 'Общие мероприятия',
                _id: '5c59a32f198e5d1108315a79',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.2',
                nameAsIs: 'Терапия',
                _id: '5c59a32f198e5d1108315a7a',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.3',
                nameAsIs: 'Хирургия',
                _id: '5c59a32f198e5d1108315a7b',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.4',
                nameAsIs: 'Ортопедия',
                _id: '5c59a32f198e5d1108315a7c',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.8',
                nameAsIs: 'Рентген',
                _id: '5c59a32f198e5d1108315a7d',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 0,
                codeAsIs: '8',
                nameAsIs: 'Клинические лабораторные исследования',
                _id: '5c59a32f198e5d1108315a7e',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '9',
                nameAsIs: 'Физиотерапия',
                _id: '5c59a32f198e5d1108315a7f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 1,
                codeAsIs: '9.1',
                nameAsIs: 'Озонотерапия',
                _id: '5c59a32f198e5d1108315a80',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a7f',
            },
            {
                codeDepth: 0,
                codeAsIs: '10',
                nameAsIs: 'Массаж',
                _id: '5c59a32f198e5d1108315a81',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '11',
                nameAsIs: 'Оториноларингология',
                _id: '5c59a32f198e5d1108315a82',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '12',
                nameAsIs: 'Дерматовенерология',
                _id: '5c59a32f198e5d1108315a83',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '14',
                nameAsIs: 'Общая хирургия, хирургические манипуляции',
                _id: '5c59a32f198e5d1108315a84',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '15',
                nameAsIs: 'Офтальмология',
                _id: '5c59a32f198e5d1108315a85',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '17',
                nameAsIs: 'Гинекология',
                _id: '5c59a32f198e5d1108315a86',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '18',
                nameAsIs: 'Рефлексотерапия',
                _id: '5c59a32f198e5d1108315a87',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '19',
                nameAsIs: 'Гемосорбция, гемодиализ',
                _id: '5c59a32f198e5d1108315a88',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '20',
                nameAsIs: 'Услуги учителя-логопеда',
                _id: '5c59a32f198e5d1108315a89',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '21',
                nameAsIs: 'Психиатрическая помощь',
                _id: '5c59a32f198e5d1108315a8a',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '23',
                nameAsIs: 'Взрослая психотерапия',
                _id: '5c59a32f198e5d1108315a8b',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '26',
                nameAsIs: 'Перечень дополнительных расходных материалов',
                _id: '5c59a32f198e5d1108315a8c',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '28',
                nameAsIs: 'Аллергология',
                _id: '5c59a32f198e5d1108315a8d',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '29',
                nameAsIs: 'SPA услуги',
                _id: '5c59a32f198e5d1108315a8e',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: -1,
                nameAsIs:
                    '*-В цене прейскуранта не учтены медикаменты и расходные материалы, которые оплачиваются дополнительно',
                _id: '5c59a32f198e5d1108315a8f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Прием врачей-специалистов',
                _id: '5c9e335cfbd3f4b9505011d5',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                providerId: '5b3f5fa4529813245',
                priceListId: '5c9e335cfbd3f4b9505011d4',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Врачи-специалисты',
                _id: '5ca4e2300a295f74506435fb',
                sourceFileId: '5ca4e2300a295f74506435f6',
                providerId: '5b3f5cf17c5d77cc4',
                priceListId: '5ca4e2300a295f74506435fa',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Прием врачей-специалистов',
                _id: '5ca6074f07ccff53f01362bb',
                sourceFileId: '5ca6074f07ccff53f013626e',
                providerId: '5b3f5ac1f2075180c',
                priceListId: '5ca6074f07ccff53f01362ba',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Прием врачей-специалистов (иностр.)',
                _id: '5ca6074f07ccff53f01362bd',
                sourceFileId: '5ca6074f07ccff53f013626e',
                providerId: '5b3f5ac1f2075180c',
                priceListId: '5ca6074f07ccff53f01362bc',
            },
        ],
        items: [
            {
                codeDepth: 1,
                codeAsIs: '2.25',
                nameAsIs:
                    'Консультация врача-аллерголога, кандидата медицинских наук',
                _id: '5bb346261966cb52609f33cf',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.26',
                nameAsIs:
                    'Повторная консультация врача-аллерголога, кандидата медицинских наук',
                _id: '5bb346261966cb52609f33d0',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.27',
                nameAsIs:
                    'Консультация врача-аллерголога, высшей квалификационной категории',
                _id: '5bb346261966cb52609f33d1',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.28',
                nameAsIs:
                    'Повторная консультация врача-аллерголога, высшей квалификационной категории',
                _id: '5bb346261966cb52609f33d2',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.29',
                nameAsIs:
                    'Консультация врача-аллерголога, первой квалификационной категории',
                _id: '5bb346261966cb52609f33d3',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.30',
                nameAsIs:
                    'Повторная консультация врача-аллерголога, первой квалификационной категории',
                _id: '5bb346261966cb52609f33d4',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5bb346241966cb52609f3394',
                sourceFileId: '5bb346241966cb52609f3393',
                effFromDate: '2018-08-01',
                groupId: '5bb346241966cb52609f3397',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '13.1.1',
                nameAsIs: 'Первичный прием врачом-аллергологом',
                _id: '5bedb84860fb744b843b1434',
                providerId: '5b3f5a696cadf395a',
                priceListId: '5bedb84560fb744b843b108d',
                sourceFileId: '5bedb84560fb744b843b1068',
                effFromDate: '2018-08-01',
                groupId: '5bedb84560fb744b843b1090',
                groupName:
                    'Прием лечебно-диагностический первичный и повторный',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '13.1.2',
                nameAsIs: 'Повторный прием врачом-аллергологом',
                _id: '5bedb84860fb744b843b1435',
                providerId: '5b3f5a696cadf395a',
                priceListId: '5bedb84560fb744b843b108d',
                sourceFileId: '5bedb84560fb744b843b1068',
                effFromDate: '2018-08-01',
                groupId: '5bedb84560fb744b843b1090',
                groupName:
                    'Прием лечебно-диагностический первичный и повторный',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '228051',
                nameAsIs: 'Первичный прием врача-аллерголога',
                _id: '5bedbf0e60fb7419e8f2d292',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d254',
                groupName: 'Аллергология и иммунология',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '228052',
                nameAsIs: 'Повторный прием врача-аллерголога',
                _id: '5bedbf0e60fb7419e8f2d293',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d254',
                groupName: 'Аллергология и иммунология',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.25',
                nameAsIs:
                    'Консультация врача-аллерголога, кандидата медицинских наук',
                _id: '5c59a331198e5d1108315ab0',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                effFromDate: '2019-01-29',
                groupId: '5c59a32f198e5d1108315a73',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.26',
                nameAsIs:
                    'Повторная консультация врача-аллерголога, кандидата медицинских наук',
                _id: '5c59a331198e5d1108315ab1',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                effFromDate: '2019-01-29',
                groupId: '5c59a32f198e5d1108315a73',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.27',
                nameAsIs:
                    'Консультация врача-аллерголога, высшей квалификационной категории',
                _id: '5c59a331198e5d1108315ab2',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                effFromDate: '2019-01-29',
                groupId: '5c59a32f198e5d1108315a73',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.28',
                nameAsIs:
                    'Повторная консультация врача-аллерголога, высшей квалификационной категории',
                _id: '5c59a331198e5d1108315ab3',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                effFromDate: '2019-01-29',
                groupId: '5c59a32f198e5d1108315a73',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.29',
                nameAsIs:
                    'Консультация врача-аллерголога, первой квалификационной категории',
                _id: '5c59a331198e5d1108315ab4',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                effFromDate: '2019-01-29',
                groupId: '5c59a32f198e5d1108315a73',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '2.30',
                nameAsIs:
                    'Повторная консультация врача-аллерголога, первой квалификационной категории',
                _id: '5c59a331198e5d1108315ab5',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                effFromDate: '2019-01-29',
                groupId: '5c59a32f198e5d1108315a73',
                groupName: 'Консультация врачей-специалистов',
                priceUnits: 'консультация',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 3,
                codeAsIs: '26.1.1.1',
                nameAsIs:
                    'Первичный прием врачом-аллергологом (1 и высш. кат.)',
                _id: '5c9e3360fbd3f4b95050190f',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceListId: '5c9e335cfbd3f4b9505011d4',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011d5',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 3,
                codeAsIs: '26.1.1.2',
                nameAsIs:
                    'Первичный прием врачом-аллергологом (к.м.н., доцент)',
                _id: '5c9e3360fbd3f4b950501910',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceListId: '5c9e335cfbd3f4b9505011d4',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011d5',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 3,
                codeAsIs: '26.1.1.3',
                nameAsIs:
                    'Первичный прием врачом-аллергологом (д.м.н., профессор)',
                _id: '5c9e3360fbd3f4b950501911',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceListId: '5c9e335cfbd3f4b9505011d4',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011d5',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 3,
                codeAsIs: '26.2.1.1',
                nameAsIs:
                    'Повторный прием врачом-аллергологом (1 и высш. кат.)',
                _id: '5c9e3360fbd3f4b950501932',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceListId: '5c9e335cfbd3f4b9505011d4',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011d5',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 3,
                codeAsIs: '26.2.1.2',
                nameAsIs:
                    'Повторный прием врачом-аллергологом (к.м.н., доцент)',
                _id: '5c9e3360fbd3f4b950501933',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceListId: '5c9e335cfbd3f4b9505011d4',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011d5',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 3,
                codeAsIs: '26.2.1.3',
                nameAsIs:
                    'Повторный прием врачом-аллергологом (д.м.н., профессор)',
                _id: '5c9e3360fbd3f4b950501934',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceListId: '5c9e335cfbd3f4b9505011d4',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011d5',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '26.1.1',
                nameAsIs: 'Первичный прием врачом-аллергологом(1 и высш.кат.)',
                _id: '5ca4e2320a295f7450643670',
                providerId: '5b3f5cf17c5d77cc4',
                branchIds: ['5b3f5cf17c5d77cc4'],
                priceListId: '5ca4e2300a295f74506435fa',
                sourceFileId: '5ca4e2300a295f74506435f6',
                effFromDate: '2019-03-12',
                groupId: '5ca4e2300a295f74506435fb',
                groupName: 'Врачи-специалисты',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '26.2.1',
                nameAsIs: 'Повторный прием врачом-аллергологом(1 и высш.кат.)',
                _id: '5ca4e2320a295f745064367a',
                providerId: '5b3f5cf17c5d77cc4',
                branchIds: ['5b3f5cf17c5d77cc4'],
                priceListId: '5ca4e2300a295f74506435fa',
                sourceFileId: '5ca4e2300a295f74506435f6',
                effFromDate: '2019-03-12',
                groupId: '5ca4e2300a295f74506435fb',
                groupName: 'Врачи-специалисты',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '26.1.1',
                nameAsIs: 'Первичный прием врачом-аллергологом',
                _id: '5ca6075207ccff53f01366fb',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceListId: '5ca6074f07ccff53f01362ba',
                sourceFileId: '5ca6074f07ccff53f013626e',
                effFromDate: '2019-03-12',
                groupId: '5ca6074f07ccff53f01362bb',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '26.2.1',
                nameAsIs: 'Повторный прием врачом-аллергологом',
                _id: '5ca6075207ccff53f0136702',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceListId: '5ca6074f07ccff53f01362ba',
                sourceFileId: '5ca6074f07ccff53f013626e',
                effFromDate: '2019-03-12',
                groupId: '5ca6074f07ccff53f01362bb',
                groupName: 'Прием врачей-специалистов',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '26.1.1и',
                nameAsIs: 'Первичный прием врачом-аллергологом',
                _id: '5ca6075207ccff53f013670b',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceListId: '5ca6074f07ccff53f01362bc',
                sourceFileId: '5ca6074f07ccff53f013626e',
                effFromDate: '2019-03-12',
                groupId: '5ca6074f07ccff53f01362bd',
                groupName: 'Прием врачей-специалистов (иностр.)',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '26.2.1и',
                nameAsIs: 'Повторный прием врачом-аллергологом',
                _id: '5ca6075207ccff53f0136714',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceListId: '5ca6074f07ccff53f01362bc',
                sourceFileId: '5ca6074f07ccff53f013626e',
                effFromDate: '2019-03-12',
                groupId: '5ca6074f07ccff53f01362bd',
                groupName: 'Прием врачей-специалистов (иностр.)',
                complex: false,
                hasExtDeps: false,
            },
        ],
        depItems: [],
        mapping: [
            {
                _id: '5bbf53b7213a0475077ba4c4',
                _updated: 1554387211614,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33cf',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bbf55e8213a0475077ba50a',
                _updated: 1546591744691,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33d0',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bbf53a5213a0475077ba4c3',
                _updated: 1554387219198,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33d1',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bbf55e8213a0475077ba50c',
                _updated: 1546591740096,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33d2',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bbf53c5213a0475077ba4c5',
                _updated: 1554387224906,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33d3',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bbf55e8213a0475077ba50b',
                _updated: 1546591749756,
                priceListId: '5bb346241966cb52609f3394',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5bb346261966cb52609f33d4',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bedbf3260fb743da4d218ed',
                _updated: 1546591714531,
                priceListId: '5bedb84560fb744b843b108d',
                providerId: '5b3f5a696cadf395a',
                priceItemId: '5bedb84860fb744b843b1434',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bedbf3160fb743da4d215ae',
                _updated: 1546591761357,
                priceListId: '5bedb84560fb744b843b108d',
                providerId: '5b3f5a696cadf395a',
                priceItemId: '5bedb84860fb744b843b1435',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bee766cce00ee27d0a7655b',
                _updated: 1546591710684,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d292',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5bee7672ce00ee27d0a7655c',
                _updated: 1546591754204,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d293',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c59a411198e5d60cceeb618',
                _updated: 1554271349072,
                priceListId: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5c59a331198e5d1108315ab0',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c59a411198e5d60cceeb675',
                _updated: 1546591744691,
                priceListId: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5c59a331198e5d1108315ab1',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c59a411198e5d60cceeb41e',
                _updated: 1554271357893,
                priceListId: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5c59a331198e5d1108315ab2',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c59a411198e5d60cceeb482',
                _updated: 1546591740096,
                priceListId: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5c59a331198e5d1108315ab3',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c59a411198e5d60cceeb4fa',
                _updated: 1554271366897,
                priceListId: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5c59a331198e5d1108315ab4',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c59a411198e5d60cceeb551',
                _updated: 1546591749756,
                priceListId: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5c59a331198e5d1108315ab5',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c9e338bfbd3f4793026b16f',
                _updated: 1546591721327,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b95050190f',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c9e338afbd3f4793026b05b',
                _updated: 1546591735820,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b950501910',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                    '5bbd02c57cf5cf2c1cc9bc29',
                ],
            },
            {
                _id: '5c9e3389fbd3f4793026ad23',
                _updated: 1546591730702,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b950501911',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc2b',
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc25',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c9e338bfbd3f4793026b079',
                _updated: 1546591766112,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b950501932',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5c9e3388fbd3f4793026ac6d',
                _updated: 1546591777724,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b950501933',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc26',
                    '5c2f19a9ce00ee3342456437',
                    '5bbd02c57cf5cf2c1cc9bc29',
                ],
            },
            {
                _id: '5c9e338dfbd3f4793026b524',
                _updated: 1546591773408,
                priceListId: '5c9e335cfbd3f4b9505011d4',
                providerId: '5b3f5fa4529813245',
                branchIds: [
                    '5b3f5fa7eaf998e58',
                    '5b3f5fa4529813245',
                    '5b3f5faa72b310212',
                ],
                priceItemId: '5c9e3360fbd3f4b950501934',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc2b',
                    '5bbd02c57cf5cf2c1cc9bc25',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5ca4e2850a295f836cd6fa2a',
                _updated: 1551892942187,
                priceListId: '5ca4e2300a295f74506435fa',
                providerId: '5b3f5cf17c5d77cc4',
                branchIds: ['5b3f5cf17c5d77cc4'],
                priceItemId: '5ca4e2320a295f7450643670',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                ],
            },
            {
                _id: '5ca4e2850a295f836cd6f966',
                _updated: 1551893170728,
                priceListId: '5ca4e2300a295f74506435fa',
                providerId: '5b3f5cf17c5d77cc4',
                branchIds: ['5b3f5cf17c5d77cc4'],
                priceItemId: '5ca4e2320a295f745064367a',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5bbd02c57cf5cf2c1cc9bc1e',
                    '5bbd02c57cf5cf2c1cc9bc1f',
                ],
            },
            {
                _id: '5ca6078807ccff4a58d52df5',
                _updated: 1551981438803,
                priceListId: '5ca6074f07ccff53f01362ba',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceItemId: '5ca6075207ccff53f01366fb',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5ca6078707ccff4a58d52af5',
                _updated: 1551982081895,
                priceListId: '5ca6074f07ccff53f01362ba',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceItemId: '5ca6075207ccff53f0136702',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5ca6078907ccff4a58d53203',
                _updated: 1551982449724,
                priceListId: '5ca6074f07ccff53f01362bc',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceItemId: '5ca6075207ccff53f013670b',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc21',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
            {
                _id: '5ca6078a07ccff4a58d53404',
                _updated: 1551982562500,
                priceListId: '5ca6074f07ccff53f01362bc',
                providerId: '5b3f5ac1f2075180c',
                branchIds: ['5bfbf6d1679408696', '5b3f5ac1f2075180c'],
                priceItemId: '5ca6075207ccff53f0136714',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bbc8',
                specOptionIds: [
                    '5bbd02c57cf5cf2c1cc9bc22',
                    '5c2f19a9ce00ee3342456437',
                ],
            },
        ],
    },
    '5bbd02c57cf5cf2c1cc9bae6': {
        title:
            'Использование услуги: Пунктурная гирудотерапия [Физиотерапия / Гирудотерапия]',
        providers: [],
        priceLists: [
            {
                title: 'Прейскурант на платные медицинские услуги',
                _id: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                sourceFileId: '5bb346271966cb52609f370d',
                sourceFileName: 'Прейскурант - 8ГП (14.08.2018).xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Лист1',
                effFromDate: '2018-08-14',
                groupCount: 138,
                priceCount: 714,
            },
            {
                title: 'ЛДО',
                _id: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                sourceFileName: 'price_ecomed_v2018-08-23_ter.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2018-08-23',
                groupCount: 19,
                priceCount: 160,
            },
            {
                title:
                    'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                _id: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                sourceFileName: 'price_eleos_v2019-01-29.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'TDSheet',
                effFromDate: '2019-01-29',
                groupCount: 31,
                priceCount: 972,
            },
            {
                title: 'прейск.на 02.07.18',
                _id: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                sourceFileName: 'makaenka_v2018-07_all_fixed.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'прейск.на 02.07.18',
                effFromDate: '2018-07-02',
                groupCount: 103,
                priceCount: 1298,
            },
            {
                title: 'Рефлексотерапия',
                _id: '5ca4e2300a295f7450643626',
                providerId: '5b3f5cf17c5d77cc4',
                sourceFileId: '5ca4e2300a295f74506435f6',
                sourceFileName: 'price_lode_grodno_v2019-04-03_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 8,
            },
        ],
        files: [
            {
                _id: '5bb346271966cb52609f370d',
                filename: '8гп-Минск (2018-08-14).xlsx',
                uploadDate: 1538475560031,
                length: 138133,
                md5: '7afbb5ece91c6b72492909280ad441a0',
                meta: {
                    sourceFileName: 'Прейскурант - 8ГП (14.08.2018).xlsx',
                    minPlDate: '2018-08-14',
                    maxPlDate: '2018-08-14',
                    priceLists: [
                        {
                            id: '5bb346271966cb52609f370e',
                            title: 'Прейскурант на платные медицинские услуги',
                            effDate: '2018-08-14',
                        },
                    ],
                },
            },
            {
                _id: '5bedbf0e60fb7419e8f2d248',
                filename: 'Экомедсервис (2018-08-23).xlsx',
                uploadDate: 1542307598271,
                length: 46909,
                md5: '2b033d82a5134e10832f54ee1e07939f',
                meta: {
                    providerId: '5b3f59191860bcdde',
                    sourceFileName: 'price_ecomed_v2018-08-23_ter.xlsx',
                    minPlDate: '2018-08-23',
                    maxPlDate: '2018-08-23',
                    priceLists: [
                        {
                            id: '5bedbf0e60fb7419e8f2d249',
                            title: 'ЛДО',
                            effDate: '2018-08-23',
                        },
                    ],
                },
            },
            {
                _id: '5c59a32f198e5d1108315a6e',
                filename: 'Элеос (2019-01-29).xlsx',
                uploadDate: 1549378353602,
                length: 86695,
                md5: 'a26ee6fe7b5a47550a183b5790d0493c',
                meta: {
                    providerId: '5b4c5eb620ae653c1',
                    sourceFileName: 'price_eleos_v2019-01-29.xlsx',
                    minPlDate: '2019-01-29',
                    maxPlDate: '2019-01-29',
                    priceLists: [
                        {
                            id: '5c59a32f198e5d1108315a6f',
                            title:
                                'ПРЕЙСКУРАНТ ЦЕН на платные медицинские услуги Реабилитационного центра "Элеос"',
                            effDate: '2019-01-29',
                        },
                    ],
                },
            },
            {
                _id: '5ca4e2230a295ffa34f69f1b',
                filename: 'РЦМРиБ (2018-07-02).xlsx',
                uploadDate: 1554309669451,
                length: 160991,
                md5: 'fb20bc1befbaa14e2baec89c5a4be685',
                meta: {
                    providerId: '5b4c5e876e354dbfb',
                    sourceFileName: 'makaenka_v2018-07_all_fixed.xlsx',
                    minPlDate: '2018-07-02',
                    maxPlDate: '2018-07-02',
                    priceLists: [
                        {
                            id: '5ca4e2230a295ffa34f69f1c',
                            title: 'прейск.на 02.07.18',
                            effDate: '2018-07-02',
                        },
                    ],
                },
            },
            {
                _id: '5ca4e2300a295f74506435f6',
                filename: 'Лодэ-Гродно (2019-04-03).xlsx',
                uploadDate: 1554309682294,
                length: 155251,
                md5: '51bdb44c24dedcef336859684a0c8991',
                meta: {
                    providerId: '5b3f5cf17c5d77cc4',
                    sourceFileName: 'price_lode_grodno_v2019-04-03_all.xlsx',
                    priceLists: [
                        {
                            id: '5ca4e2300a295f74506435f7',
                            title: 'Аллергология',
                        },
                        {
                            id: '5ca4e2300a295f74506435fa',
                            title: 'Врачи-специалисты',
                        },
                        {
                            id: '5ca4e2300a295f74506435fc',
                            title: 'Выезд на дом',
                        },
                        {
                            id: '5ca4e2300a295f74506435fe',
                            title: 'Гинекология',
                        },
                        {
                            id: '5ca4e2300a295f7450643600',
                            title: 'Дерматовенерология',
                        },
                        {
                            id: '5ca4e2300a295f7450643602',
                            title: 'Дополнительно',
                        },
                        {
                            id: '5ca4e2300a295f7450643604',
                            title: 'Иммунопрофилактика',
                        },
                        {
                            id: '5ca4e2300a295f7450643606',
                            title: 'Консультации',
                        },
                        {
                            id: '5ca4e2300a295f7450643608',
                            title: 'Лаборатория ЛОДЭ',
                        },
                        {
                            id: '5ca4e2300a295f745064360a',
                            title: 'Лабораторная диагностика (Минск)',
                        },
                        { id: '5ca4e2300a295f745064360c', title: 'Логопедия' },
                        {
                            id: '5ca4e2300a295f745064360e',
                            title: 'Манипуляции',
                        },
                        { id: '5ca4e2300a295f7450643610', title: 'Массаж' },
                        {
                            id: '5ca4e2300a295f7450643612',
                            title: 'Озонотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643614',
                            title: 'Оперативная гинекология',
                        },
                        {
                            id: '5ca4e2300a295f7450643616',
                            title: 'Оперативная оториноларингология',
                        },
                        {
                            id: '5ca4e2300a295f7450643618',
                            title: 'Оперативная проктология',
                        },
                        {
                            id: '5ca4e2300a295f745064361a',
                            title: 'Оперативная хирургия',
                        },
                        {
                            id: '5ca4e2300a295f745064361c',
                            title: 'Оториноларингология',
                        },
                        {
                            id: '5ca4e2300a295f745064361e',
                            title: 'Офтальмология',
                        },
                        { id: '5ca4e2300a295f7450643620', title: 'Педиатрия' },
                        {
                            id: '5ca4e2300a295f7450643622',
                            title: 'Проктология',
                        },
                        {
                            id: '5ca4e2300a295f7450643624',
                            title: 'Психотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643626',
                            title: 'Рефлексотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643628',
                            title: 'Стоматология',
                        },
                        {
                            id: '5ca4e2300a295f745064362a',
                            title: 'Сторонние организации',
                        },
                        {
                            id: '5ca4e2300a295f745064362c',
                            title: 'Ультразвуковое исследование',
                        },
                        { id: '5ca4e2300a295f745064362e', title: 'Урология' },
                        {
                            id: '5ca4e2300a295f7450643630',
                            title: 'Физиотерапия',
                        },
                        {
                            id: '5ca4e2300a295f7450643632',
                            title: 'Функциональная диагностика',
                        },
                        { id: '5ca4e2300a295f7450643634', title: 'Хирургия' },
                    ],
                },
            },
        ],
        groups: [
            {
                codeDepth: -1,
                nameAsIs: 'Выезд за рубеж',
                _id: '5bb346271966cb52609f370f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Осмотры специалистами',
                _id: '5bb346271966cb52609f3710',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f370f',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Гинекология',
                _id: '5bb346271966cb52609f3711',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Гинекологические манипуляции и процедуры',
                _id: '5bb346271966cb52609f3712',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3711',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs: 'Гинекологические операции',
                _id: '5bb346271966cb52609f3713',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3711',
            },
            {
                codeDepth: 0,
                codeAsIs: '4',
                nameAsIs:
                    'УЗ "Городское клиническое патологоанатомическое бюро"',
                _id: '5bb346271966cb52609f3714',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3711',
            },
            {
                codeDepth: 0,
                codeAsIs: '5',
                nameAsIs: 'УЗ "1-я городская клиническая больница"',
                _id: '5bb346271966cb52609f3715',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3711',
            },
            {
                codeDepth: 0,
                codeAsIs: '6',
                nameAsIs:
                    'УЗ "Городской клинический кожно- венерологический диспансер"',
                _id: '5bb346271966cb52609f3716',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3711',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Иммунопрофилактика',
                _id: '5bb346271966cb52609f3717',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Проведение процедуры вакцинации',
                _id: '5bb346271966cb52609f3718',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3717',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Консультации врачей',
                _id: '5bb346271966cb52609f3719',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1',
                nameAsIs:
                    'Врач-специалист II квалификационной категории терапевтического профиля',
                _id: '5bb346271966cb52609f371a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3719',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.2',
                nameAsIs:
                    'Врач специалист II квалификационной категории хирургического профиля',
                _id: '5bb346271966cb52609f371b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3719',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.3',
                nameAsIs:
                    'Врач-специалист I квалификационной категории терапевтического профиля',
                _id: '5bb346271966cb52609f371c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3719',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.4',
                nameAsIs:
                    'Врач-специалист I квалификационной категории хирургического профиля',
                _id: '5bb346271966cb52609f371d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3719',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.5',
                nameAsIs:
                    'Врач-специалист высшей квалификационной категории терапевтического профиля',
                _id: '5bb346271966cb52609f371e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3719',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Ксерокопирование',
                _id: '5bb346271966cb52609f371f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Лабораторная диагностика',
                _id: '5bb346271966cb52609f3720',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Общеклинические исследования',
                _id: '5bb346271966cb52609f3721',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3720',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.1',
                nameAsIs: 'Общий анализ мочи',
                _id: '5bb346271966cb52609f3722',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3721',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.8',
                nameAsIs: 'Исследование кала',
                _id: '5bb346271966cb52609f3723',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3721',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.1',
                nameAsIs:
                    'Обнаружение трихомонад и гонококков в препаратах отделяемого мочеполовых органов, окрашенных метиленовым синим по Граму',
                _id: '5bb346271966cb52609f3724',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3721',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs: 'Гематологические исследования',
                _id: '5bb346271966cb52609f3725',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3720',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.1',
                nameAsIs: 'Общий анализ крови',
                _id: '5bb346271966cb52609f3726',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3725',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.6',
                nameAsIs: 'Подсчет тромбоцитов',
                _id: '5bb346271966cb52609f3727',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3725',
            },
            {
                codeDepth: 0,
                codeAsIs: '5',
                nameAsIs: 'Биохимические исследования',
                _id: '5bb346271966cb52609f3728',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3720',
            },
            {
                codeDepth: 1,
                codeAsIs: '5.2',
                nameAsIs:
                    'Исследования с использованием фотоэлектроколориметров и одноканальных биохимических автоматических фотометров',
                _id: '5bb346271966cb52609f3729',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3728',
            },
            {
                codeDepth: 1,
                codeAsIs: '5.13',
                nameAsIs:
                    'Проведение исследований с помощью многоканальных биохимических автоанализаторов',
                _id: '5bb346271966cb52609f372a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3728',
            },
            {
                codeDepth: 0,
                codeAsIs: '6',
                nameAsIs: 'Исследования состояния гемостаза',
                _id: '5bb346271966cb52609f372b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3720',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.2',
                nameAsIs:
                    'Определение протромбированного (тромбопластинового) времени',
                _id: '5bb346271966cb52609f372c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f372b',
            },
            {
                codeDepth: 0,
                codeAsIs: '7',
                nameAsIs: 'Экспресс-анализ',
                _id: '5bb346271966cb52609f372d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3720',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Лечебная физкультура',
                _id: '5bb346271966cb52609f372e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Лечебная физкультура',
                _id: '5bb346271966cb52609f372f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f372e',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs:
                    'Восстановление навыков самообслуживания (эрготерапия)',
                _id: '5bb346271966cb52609f3730',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f372e',
            },
            {
                codeDepth: 0,
                codeAsIs: '4',
                nameAsIs: 'Механотерапия',
                _id: '5bb346271966cb52609f3731',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f372e',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Массаж',
                _id: '5bb346271966cb52609f3732',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Выполнение массажных процедур руками',
                _id: '5bb346271966cb52609f3733',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3732',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Медикаментозное прерывание беременности',
                _id: '5bb346271966cb52609f3734',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Оториноларингология',
                _id: '5bb346271966cb52609f3735',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Манипуляции',
                _id: '5bb346271966cb52609f3736',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3735',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Приобретение, ношени оружия',
                _id: '5bb346271966cb52609f3737',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Осмотры специалистами',
                _id: '5bb346271966cb52609f3738',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3737',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Прокат предметов по уходу за больными (костыли)',
                _id: '5bb346271966cb52609f3739',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Профосмотры',
                _id: '5bb346271966cb52609f373a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Осмотры специалистами',
                _id: '5bb346271966cb52609f373b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f373a',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Рентгенологические исследования',
                _id: '5bb346271966cb52609f373c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Лучевая диагностика',
                _id: '5bb346271966cb52609f373d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f373c',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1',
                nameAsIs: 'Рентгенологические исследования:',
                _id: '5bb346271966cb52609f373e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f373d',
            },
            {
                codeDepth: 2,
                codeAsIs: '1.1.1',
                nameAsIs:
                    'Рентгенологические исследования органов грудной полости:',
                _id: '5bb346271966cb52609f373f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f373e',
            },
            {
                codeDepth: 3,
                codeAsIs: '1.1.1.2',
                nameAsIs: 'Рентгенография (обзорная) грудной полости:',
                _id: '5bb346271966cb52609f3740',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f373f',
            },
            {
                codeDepth: 2,
                codeAsIs: '1.1.2',
                nameAsIs:
                    'Рентгенологические исследования органов брюшной полости (органов пищеварения):',
                _id: '5bb346271966cb52609f3741',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f373e',
            },
            {
                codeDepth: 2,
                codeAsIs: '1.1.3',
                nameAsIs:
                    'Рентгенологические исследования костно-суставной системы:',
                _id: '5bb346271966cb52609f3742',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f373e',
            },
            {
                codeDepth: 3,
                codeAsIs: '1.1.3.1',
                nameAsIs: 'Рентгенография отдела позвоночника',
                _id: '5bb346271966cb52609f3743',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3742',
            },
            {
                codeDepth: 3,
                codeAsIs: '1.1.3.2',
                nameAsIs: 'Рентгенография периферических отделов скелета:',
                _id: '5bb346271966cb52609f3744',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3742',
            },
            {
                codeDepth: 3,
                codeAsIs: '1.1.3.3',
                nameAsIs: 'Рентгенография черепа:',
                _id: '5bb346271966cb52609f3745',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3742',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Рефлексотерапия',
                _id: '5bb346271966cb52609f3746',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs: 'Методы рефлексотерапии',
                _id: '5bb346271966cb52609f3747',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3746',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.1',
                nameAsIs: 'Классическое иглоукалывание (акупунктура)',
                _id: '5bb346271966cb52609f3748',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3747',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.4',
                nameAsIs: 'Пунктурная гирудотерапия',
                _id: '5bb346271966cb52609f3749',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3747',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.5',
                nameAsIs: 'Микроиглоукалывание',
                _id: '5bb346271966cb52609f374a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3747',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.6',
                nameAsIs:
                    'Восточный массаж медицинскими изделиями для механического массажа',
                _id: '5bb346271966cb52609f374b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3747',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Солярий',
                _id: '5bb346271966cb52609f374c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Ультрафиолетовое облучение общее в солярии с НДС',
                _id: '5bb346271966cb52609f374d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f374c',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Ультрафиолетовое облучение в солярии без НДС',
                _id: '5bb346271966cb52609f374e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f374c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Стоматологические услуги',
                _id: '5bb346271966cb52609f374f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs:
                    'Общие стоматологические мероприятия (терапевтические, амбулаторно-хирургические, ортопедические, ортодонтические)',
                _id: '5bb346271966cb52609f3750',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f374f',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.2',
                nameAsIs: 'Профессиональная гигиена',
                _id: '5bb346271966cb52609f3751',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3750',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.3',
                nameAsIs: 'Анестезиологическая помощь',
                _id: '5bb346271966cb52609f3752',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3750',
            },
            {
                codeDepth: 0,
                codeAsIs: '8',
                nameAsIs: 'Рентгенологическая диагностика (стоматологическая)',
                _id: '5bb346271966cb52609f3753',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f374f',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Стоматология',
                _id: '5bb346271966cb52609f3754',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs:
                    'Общие стоматологические мероприятия (терапевтические, амбулаторно-хирургические, ортопедические, ортодонтические)',
                _id: '5bb346271966cb52609f3755',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3754',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1',
                nameAsIs: 'Стоматологические обследования и процедуры',
                _id: '5bb346271966cb52609f3756',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3755',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.2',
                nameAsIs: 'Профессиональная гигиена',
                _id: '5bb346271966cb52609f3757',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3755',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.3',
                nameAsIs: 'Анестезиологическая помощь',
                _id: '5bb346271966cb52609f3758',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3755',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.4',
                nameAsIs: 'Прочие общие стоматологические мероприятия',
                _id: '5bb346271966cb52609f3759',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3755',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.5',
                nameAsIs:
                    'Применение инструментов, изделий и средств медицинского назначения, используемых при посещении пациента всех видов стоматологического лечения',
                _id: '5bb346271966cb52609f375a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3755',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs:
                    'Стоматология терапевтическая (терапевтическое стоматологическое лечение)',
                _id: '5bb346271966cb52609f375b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3754',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.2',
                nameAsIs:
                    'Препарирование твердых тканей одного зуба при лечении кариеса (I, II, III, IV, V классы по Блэку) и некариозных заболеваний, возникших после прорезывания зубов с локализацией полостей независимо от поверхности',
                _id: '5bb346271966cb52609f375c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f375b',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.3',
                nameAsIs: 'Изготовление изолирующей прокладки',
                _id: '5bb346271966cb52609f375d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f375b',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.4',
                nameAsIs:
                    'Эндодонтическое лечение одного зуба при пульпите и апикальном периодонтите',
                _id: '5bb346271966cb52609f375e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f375b',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.5',
                nameAsIs:
                    'Реставрация коронковой части одного зуба при лечении кариозной полости (I, II, III, IV, V классы по Блэку) с локализацией полостей независимо от поверхности',
                _id: '5bb346271966cb52609f375f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f375b',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.7',
                nameAsIs: 'Лечение заболеваний периодонта',
                _id: '5bb346271966cb52609f3760',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f375b',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs:
                    'Стоматология хирургическая (амбулаторно-хирургическое стоматологическое лечение)',
                _id: '5bb346271966cb52609f3761',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3754',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.1',
                nameAsIs: 'Общие хирургические мероприятия',
                _id: '5bb346271966cb52609f3762',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3761',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.2',
                nameAsIs:
                    'Хирургическая подготовка полости рта к протезированию',
                _id: '5bb346271966cb52609f3763',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3761',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.3',
                nameAsIs:
                    'Хирургические методы лечения заболеваний маргинального периодонта',
                _id: '5bb346271966cb52609f3764',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3761',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.4',
                nameAsIs:
                    'Лечение пациентов с доброкачественными опухолями и опухолеподобными образованиями в полости рта',
                _id: '5bb346271966cb52609f3765',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3761',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.5',
                nameAsIs: 'Воспалительные процессы челюстно-лицевой области',
                _id: '5bb346271966cb52609f3766',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3761',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.6',
                nameAsIs: 'Прочие хирургические мероприятия',
                _id: '5bb346271966cb52609f3767',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3761',
            },
            {
                codeDepth: 1,
                codeAsIs: '3.8',
                nameAsIs:
                    'Применение инструментов, изделий и средств медицинского назначения, используемых на хирургическом приеме',
                _id: '5bb346271966cb52609f3768',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3761',
            },
            {
                codeDepth: 0,
                codeAsIs: '8',
                nameAsIs: 'Рентгенологическая диагностика (стоматологическая)',
                _id: '5bb346271966cb52609f3769',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3754',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Стоматология_',
                _id: '5bb346271966cb52609f376a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs:
                    'Общие стоматологические мероприятия (терапевтические, амбулаторно-хирургические, ортопедические, ортодонтические)',
                _id: '5bb346271966cb52609f376b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376a',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1',
                nameAsIs: 'Стоматологические обследования и процедуры',
                _id: '5bb346271966cb52609f376c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376b',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.2',
                nameAsIs: 'Профессиональная гигиена',
                _id: '5bb346271966cb52609f376d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376b',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.3',
                nameAsIs: 'Анестезиологическая помощь',
                _id: '5bb346271966cb52609f376e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376b',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.4',
                nameAsIs: 'Прочие общие стоматологические мероприятия',
                _id: '5bb346271966cb52609f376f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376b',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.5',
                nameAsIs:
                    'Применение инструментов, изделий и средств медицинского назначения, используемых при посещении пациента всех видов стоматологического лечения',
                _id: '5bb346271966cb52609f3770',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376b',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs:
                    'Стоматология терапевтическая (терапевтическое стоматологическое лечение)',
                _id: '5bb346271966cb52609f3771',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376a',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.2',
                nameAsIs:
                    'Препарирование твердых тканей одного зуба при лечении кариеса (I, II, III, IV, V классы по Блэку) и некариозных заболеваний, возникших после прорезывания зубов с локализацией полостей независимо от поверхности',
                _id: '5bb346271966cb52609f3772',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3771',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.3',
                nameAsIs: 'Изготовление изолирующей прокладки',
                _id: '5bb346271966cb52609f3773',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3771',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.4',
                nameAsIs:
                    'Эндодонтическое лечение одного зуба при пульпите и апикальном периодонтите',
                _id: '5bb346271966cb52609f3774',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3771',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.5',
                nameAsIs:
                    'Реставрация коронковой части одного зуба при лечении кариозной полости (I, II, III, IV, V классы по Блэку) с локализацией полостей независимо от поверхности',
                _id: '5bb346271966cb52609f3775',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3771',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.7',
                nameAsIs: 'Лечение заболеваний периодонта',
                _id: '5bb346271966cb52609f3776',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3771',
            },
            {
                codeDepth: 0,
                codeAsIs: '8',
                nameAsIs: 'Рентгенологическая диагностика (стоматологическая)',
                _id: '5bb346271966cb52609f3777',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f376a',
            },
            {
                codeDepth: -1,
                nameAsIs: 'УЗИ-диагностика',
                _id: '5bb346271966cb52609f3778',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.1.1',
                nameAsIs: 'Печень, желчный пузырь без определения функции',
                _id: '5bb346271966cb52609f3779',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.1.3',
                nameAsIs: 'Поджелудочная железа',
                _id: '5bb346271966cb52609f377a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.1.5',
                nameAsIs: 'Селезенка',
                _id: '5bb346271966cb52609f377b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.1',
                nameAsIs: 'Почки и надпочечники',
                _id: '5bb346271966cb52609f377c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.2',
                nameAsIs: 'Мочевой пузырь',
                _id: '5bb346271966cb52609f377d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.3',
                nameAsIs: 'Мочевой пузырь с определением остаточной мочи',
                _id: '5bb346271966cb52609f377e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.6',
                nameAsIs:
                    'Предстательная железа с мочевым пузырем и определением остаточной мочи (транабдоминально)',
                _id: '5bb346271966cb52609f377f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.10',
                nameAsIs:
                    'Матка и придатки с мочевым пузырем (трансабдоминально)',
                _id: '5bb346271966cb52609f3780',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.11',
                nameAsIs: 'Матка и придатки (трансвагинально)',
                _id: '5bb346271966cb52609f3781',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.12',
                nameAsIs: 'Плод в 1 триместре до 11 недель беременности',
                _id: '5bb346271966cb52609f3782',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.2.16',
                nameAsIs:
                    'Органы брюшной полости и почки (печень и желчный пузырь без определения функции, поджелудочная железа, селезенка, почки и надпочечники, кишечник без заполнения жидкостью)',
                _id: '5bb346271966cb52609f3783',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.3.1',
                nameAsIs:
                    'Щитовидная железа с лимфотическими поверхностными узлами',
                _id: '5bb346271966cb52609f3784',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.3.2',
                nameAsIs:
                    'Молочные железы с лимфатическими поверхностными узлами',
                _id: '5bb346271966cb52609f3785',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.3.3',
                nameAsIs: 'Слюнные железы (или подчелюстные или околоушные)',
                _id: '5bb346271966cb52609f3786',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: 2,
                codeAsIs: '3.3.11',
                nameAsIs: 'Лимфатические узлы (одна область с обеих сторон)',
                _id: '5bb346271966cb52609f3787',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3778',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Физиотерапия',
                _id: '5bb346271966cb52609f3788',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Электролечение',
                _id: '5bb346271966cb52609f3789',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3788',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.2',
                nameAsIs: 'Электрофорез',
                _id: '5bb346271966cb52609f378a',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3789',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Светолечение',
                _id: '5bb346271966cb52609f378b',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3788',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs: 'Воздействие факторами механической природы',
                _id: '5bb346271966cb52609f378c',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3788',
            },
            {
                codeDepth: 0,
                codeAsIs: '4',
                nameAsIs: 'Ингаляционная терапия',
                _id: '5bb346271966cb52609f378d',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3788',
            },
            {
                codeDepth: 1,
                codeAsIs: '4.6',
                nameAsIs: 'Коктейль кислородный " Витаминный"',
                _id: '5bb346271966cb52609f378e',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f378d',
            },
            {
                codeDepth: 0,
                codeAsIs: '5',
                nameAsIs: 'Гидротерапия',
                _id: '5bb346271966cb52609f378f',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3788',
            },
            {
                codeDepth: 0,
                codeAsIs: '7',
                nameAsIs: 'Термолечение',
                _id: '5bb346271966cb52609f3790',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3788',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Функциональная диагностика',
                _id: '5bb346271966cb52609f3791',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '5',
                nameAsIs: 'Функциональная диагностика',
                _id: '5bb346271966cb52609f3792',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3791',
            },
            {
                codeDepth: 1,
                codeAsIs: '5.1',
                nameAsIs: 'Электрокардиографические исследования',
                _id: '5bb346271966cb52609f3793',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3792',
            },
            {
                codeDepth: 1,
                codeAsIs: '5.3',
                nameAsIs:
                    'Исследование функции внешнего дыхания (на автоматизированном оборудовании):',
                _id: '5bb346271966cb52609f3794',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3792',
            },
            {
                codeDepth: 1,
                codeAsIs: '5.6',
                nameAsIs:
                    'Динамическое исследование артериального давления при непрерывной суточной регистрации суточное мониторирование артериального давления - СМАД)',
                _id: '5bb346271966cb52609f3795',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3792',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Хирургия',
                _id: '5bb346271966cb52609f3796',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs: 'Общая хирургия',
                _id: '5bb346271966cb52609f3797',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3796',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Хирургические манипуляции',
                _id: '5bb346271966cb52609f3798',
                sourceFileId: '5bb346271966cb52609f370d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                parentGroupId: '5bb346271966cb52609f3796',
            },
            {
                codeDepth: 0,
                codeAsIs: '200000',
                nameAsIs: 'ЛДО',
                _id: '5bedbf0e60fb7419e8f2d24a',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
            },
            {
                codeDepth: 1,
                codeAsIs: '201000',
                nameAsIs: 'Психотерапия',
                _id: '5bedbf0e60fb7419e8f2d24b',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '210000',
                nameAsIs: 'Эндокринология',
                _id: '5bedbf0e60fb7419e8f2d24c',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '211000',
                nameAsIs: 'Гастроэнтерология',
                _id: '5bedbf0e60fb7419e8f2d24d',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '213000',
                nameAsIs: 'Терапия',
                _id: '5bedbf0e60fb7419e8f2d24e',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '214000',
                nameAsIs: 'Кардиология',
                _id: '5bedbf0e60fb7419e8f2d24f',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '215000',
                nameAsIs: 'Вакцинация',
                _id: '5bedbf0e60fb7419e8f2d250',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '225000',
                nameAsIs: 'Неврология',
                _id: '5bedbf0e60fb7419e8f2d251',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '226000',
                nameAsIs: 'Ревматология',
                _id: '5bedbf0e60fb7419e8f2d252',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '227000',
                nameAsIs: 'Ортопедия',
                _id: '5bedbf0e60fb7419e8f2d253',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '228000',
                nameAsIs: 'Аллергология и иммунология',
                _id: '5bedbf0e60fb7419e8f2d254',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '230000',
                nameAsIs: 'Логопедия',
                _id: '5bedbf0e60fb7419e8f2d255',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '233000',
                nameAsIs: 'Педиатрия',
                _id: '5bedbf0e60fb7419e8f2d256',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '240000',
                nameAsIs: 'Рефлексотерапия',
                _id: '5bedbf0e60fb7419e8f2d257',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '241000',
                nameAsIs: 'Физиотерапия',
                _id: '5bedbf0e60fb7419e8f2d258',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '242000',
                nameAsIs: 'Массаж',
                _id: '5bedbf0e60fb7419e8f2d259',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '244000',
                nameAsIs: 'Дерматология',
                _id: '5bedbf0e60fb7419e8f2d25a',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '251000',
                nameAsIs: 'Манипуляции общего назначения',
                _id: '5bedbf0e60fb7419e8f2d25b',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: 1,
                codeAsIs: '252000',
                nameAsIs: 'Диетология',
                _id: '5bedbf0e60fb7419e8f2d25c',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                parentGroupId: '5bedbf0e60fb7419e8f2d24a',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Услуги',
                _id: '5c59a32f198e5d1108315a71',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
            },
            {
                codeDepth: 0,
                codeAsIs: '1',
                nameAsIs:
                    'Оказание медицинской помощи в отделении круглосуточного и дневного пребывания',
                _id: '5c59a32f198e5d1108315a72',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'Консультация врачей-специалистов',
                _id: '5c59a32f198e5d1108315a73',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '3',
                nameAsIs: 'Лечебная физкультура',
                _id: '5c59a32f198e5d1108315a74',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '4',
                nameAsIs: 'Манипуляции общего назначения',
                _id: '5c59a32f198e5d1108315a75',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '5',
                nameAsIs: 'Ультразвуковые исследования',
                _id: '5c59a32f198e5d1108315a76',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '6',
                nameAsIs: 'Функциональная диагностика',
                _id: '5c59a32f198e5d1108315a77',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '7',
                nameAsIs: 'Стоматология',
                _id: '5c59a32f198e5d1108315a78',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.1',
                nameAsIs: 'Общие мероприятия',
                _id: '5c59a32f198e5d1108315a79',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.2',
                nameAsIs: 'Терапия',
                _id: '5c59a32f198e5d1108315a7a',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.3',
                nameAsIs: 'Хирургия',
                _id: '5c59a32f198e5d1108315a7b',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.4',
                nameAsIs: 'Ортопедия',
                _id: '5c59a32f198e5d1108315a7c',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 1,
                codeAsIs: '7.8',
                nameAsIs: 'Рентген',
                _id: '5c59a32f198e5d1108315a7d',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a78',
            },
            {
                codeDepth: 0,
                codeAsIs: '8',
                nameAsIs: 'Клинические лабораторные исследования',
                _id: '5c59a32f198e5d1108315a7e',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '9',
                nameAsIs: 'Физиотерапия',
                _id: '5c59a32f198e5d1108315a7f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 1,
                codeAsIs: '9.1',
                nameAsIs: 'Озонотерапия',
                _id: '5c59a32f198e5d1108315a80',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a7f',
            },
            {
                codeDepth: 0,
                codeAsIs: '10',
                nameAsIs: 'Массаж',
                _id: '5c59a32f198e5d1108315a81',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '11',
                nameAsIs: 'Оториноларингология',
                _id: '5c59a32f198e5d1108315a82',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '12',
                nameAsIs: 'Дерматовенерология',
                _id: '5c59a32f198e5d1108315a83',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '14',
                nameAsIs: 'Общая хирургия, хирургические манипуляции',
                _id: '5c59a32f198e5d1108315a84',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '15',
                nameAsIs: 'Офтальмология',
                _id: '5c59a32f198e5d1108315a85',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '17',
                nameAsIs: 'Гинекология',
                _id: '5c59a32f198e5d1108315a86',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '18',
                nameAsIs: 'Рефлексотерапия',
                _id: '5c59a32f198e5d1108315a87',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '19',
                nameAsIs: 'Гемосорбция, гемодиализ',
                _id: '5c59a32f198e5d1108315a88',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '20',
                nameAsIs: 'Услуги учителя-логопеда',
                _id: '5c59a32f198e5d1108315a89',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '21',
                nameAsIs: 'Психиатрическая помощь',
                _id: '5c59a32f198e5d1108315a8a',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '23',
                nameAsIs: 'Взрослая психотерапия',
                _id: '5c59a32f198e5d1108315a8b',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '26',
                nameAsIs: 'Перечень дополнительных расходных материалов',
                _id: '5c59a32f198e5d1108315a8c',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '28',
                nameAsIs: 'Аллергология',
                _id: '5c59a32f198e5d1108315a8d',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: 0,
                codeAsIs: '29',
                nameAsIs: 'SPA услуги',
                _id: '5c59a32f198e5d1108315a8e',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                parentGroupId: '5c59a32f198e5d1108315a71',
            },
            {
                codeDepth: -1,
                nameAsIs:
                    '*-В цене прейскуранта не учтены медикаменты и расходные материалы, которые оплачиваются дополнительно',
                _id: '5c59a32f198e5d1108315a8f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
            },
            {
                codeDepth: -1,
                nameAsIs:
                    'ВЫПОЛНЕНИЕ МАССАЖНЫХ ПРОЦЕДУР МЕХАНИЧЕСКИМ ВОЗДЕЙСТВИЕМ РУК',
                _id: '5ca4e2230a295ffa34f69f1e',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ГИДРОТЕРАПИЯ',
                _id: '5ca4e2230a295ffa34f69f1f',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ЭЛЕКТРОЛЕЧЕНИЕ',
                _id: '5ca4e2230a295ffa34f69f20',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'СВЕТОЛЕЧЕНИЕ',
                _id: '5ca4e2230a295ffa34f69f21',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ВОЗДЕЙСТВИЕ ФАКТОРАМИ МЕХАНИЧЕСКОЙ ПРИРОДЫ',
                _id: '5ca4e2230a295ffa34f69f22',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ИНГАЛЯЦИОННАЯ ТЕРАПИЯ',
                _id: '5ca4e2230a295ffa34f69f23',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'БАЛЬНЕОТЕРАПИЯ',
                _id: '5ca4e2230a295ffa34f69f24',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Ванны минеральные',
                _id: '5ca4e2230a295ffa34f69f25',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f24',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ТЕРМОЛЕЧЕНИЕ',
                _id: '5ca4e2230a295ffa34f69f26',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ЛЕЧЕБНАЯ ФИЗКУЛЬТУРА',
                _id: '5ca4e2230a295ffa34f69f27',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Лечебная физкультура д/неврологических больных',
                _id: '5ca4e2230a295ffa34f69f28',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f27',
            },
            {
                codeDepth: 0,
                codeAsIs: '2',
                nameAsIs: 'ГИДРОКИНЕЗОТЕРАПИЯ',
                _id: '5ca4e2230a295ffa34f69f29',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Лечебное плавание в воде',
                _id: '5ca4e2230a295ffa34f69f2a',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f29',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Гидроаэробика в бассейне с минеральной водой',
                _id: '5ca4e2230a295ffa34f69f2b',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f29',
            },
            {
                codeDepth: -1,
                nameAsIs: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                _id: '5ca4e2230a295ffa34f69f2c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'МАНУАЛЬНАЯ ТЕРАПИЯ И ДИАГНОСТИКА',
                _id: '5ca4e2230a295ffa34f69f2d',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ЛУЧЕВАЯ ДИАГНОСТИКА',
                _id: '5ca4e2230a295ffa34f69f2e',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Рентгенологические исследования',
                _id: '5ca4e2230a295ffa34f69f2f',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.1.2',
                nameAsIs:
                    'Рентгенография (обзорная) грудной полости без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f30',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.1.2.1',
                nameAsIs:
                    'Рентгенография (обзорная) грудной полости с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f31',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.1.1',
                nameAsIs:
                    'Рентгенография шейного отдела позвоночника без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f32',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.1',
                nameAsIs:
                    'Рентгенография шейного отдела позвоночника с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f33',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.2',
                nameAsIs:
                    'Рентгенография грудного отдела позвоночника без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f34',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.2.1',
                nameAsIs:
                    'Рентгенография грудного отдела позвоночника с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f35',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.3',
                nameAsIs:
                    'Рентгенография пояснично-крестцового отдела позвоночника без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f36',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.3.1',
                nameAsIs:
                    'Рентгенография пояснично-крестцового отдела позвоночника с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f37',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.4',
                nameAsIs:
                    'Рентгенография крестца и копчика без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f38',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.1.4.1',
                nameAsIs:
                    'Рентгенография крестца и копчика с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f39',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.2.1',
                nameAsIs:
                    'Рентгенография плечевого сустава без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f3a',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.2.1.1',
                nameAsIs:
                    'Рентгенография плечевого сустава с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f3b',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.2.2',
                nameAsIs:
                    'Рентгенография коленного сустава или локтевого сустава, или кисти, или стопы, или голеностопного сустава без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f3c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.2.2.1',
                nameAsIs:
                    'Рентгенография коленного сустава или локтевого сустава, или кисти, или стопы, или голеностопного сустава с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f3d',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.2.3',
                nameAsIs:
                    'Рентгенография тазобедренного сустава без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f3e',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.2.3.1',
                nameAsIs:
                    'Рентгенография тазобедренного сустава с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f3f',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.3',
                nameAsIs: 'Рентгенография черепа без цифровой печати:',
                _id: '5ca4e2230a295ffa34f69f40',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                codeAsIs: '1.1.3.3.1',
                nameAsIs: 'Рентгенография черепа с цифровой печатью:',
                _id: '5ca4e2230a295ffa34f69f41',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Денситометрия',
                _id: '5ca4e2230a295ffa34f69f42',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f2e',
            },
            {
                codeDepth: -1,
                nameAsIs: 'УЛЬТРАЗВУКОВАЯ ДИАГНОСТИКА',
                _id: '5ca4e2230a295ffa34f69f43',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Ультразвуковое исследование:',
                _id: '5ca4e2230a295ffa34f69f44',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f43',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ФУНКЦИОНАЛЬНАЯ ДИАГНОСТИКА',
                _id: '5ca4e2230a295ffa34f69f45',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Электрокардиографические исследования',
                _id: '5ca4e2230a295ffa34f69f46',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f45',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ЭНДОСКОПИЧЕСКИЕ ИССЛЕДОВАНИЯ',
                _id: '5ca4e2230a295ffa34f69f47',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.1.3',
                nameAsIs: 'Эзофагогастродуоденоскопия:',
                _id: '5ca4e2230a295ffa34f69f48',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f47',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.1.10',
                nameAsIs: 'Ректоскопия:',
                _id: '5ca4e2230a295ffa34f69f49',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f47',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.1.11',
                nameAsIs: 'Ректосигмоскопия:',
                _id: '5ca4e2230a295ffa34f69f4a',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f47',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.1.12',
                nameAsIs: 'Ректосигмоколоноскопия:',
                _id: '5ca4e2230a295ffa34f69f4b',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f47',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.3.1',
                nameAsIs:
                    'Взятие биопсийного материала на гистологическое исследование:',
                _id: '5ca4e2230a295ffa34f69f4c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f47',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.3.2',
                nameAsIs:
                    'Взятие биопсийного материала на цитологическое исследование:',
                _id: '5ca4e2230a295ffa34f69f4d',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f47',
            },
            {
                codeDepth: 1,
                codeAsIs: '6.3.3',
                nameAsIs: 'Выполнение уреазного теста:',
                _id: '5ca4e2230a295ffa34f69f4e',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f47',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ПРОКТОЛОГИЯ',
                _id: '5ca4e2230a295ffa34f69f4f',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'КОНСУЛЬТАЦИИ СПЕЦИАЛИСТОВ',
                _id: '5ca4e2230a295ffa34f69f50',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Консультация первичная:',
                _id: '5ca4e2230a295ffa34f69f51',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f50',
            },
            {
                codeDepth: -1,
                nameAsIs: 'КОНСУЛЬТАЦИЯ ПОВТОРНАЯ',
                _id: '5ca4e2230a295ffa34f69f52',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs:
                    'ПРОФИЛАКТИЧЕСКИЕ ОСМОТРЫ И МЕДИЦИНСКОЕ ОСВИДЕТЕЛЬСТВОВАНИЕ',
                _id: '5ca4e2230a295ffa34f69f53',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Осмотры специалистами:',
                _id: '5ca4e2230a295ffa34f69f54',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f53',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Функциональные исследования:',
                _id: '5ca4e2230a295ffa34f69f55',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f53',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ОФТАЛЬМОЛОГИЯ',
                _id: '5ca4e2230a295ffa34f69f56',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ХИРУРГИЯ',
                _id: '5ca4e2230a295ffa34f69f57',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'АКУШЕРСТВО И ГИНЕКОЛОГИЯ',
                _id: '5ca4e2230a295ffa34f69f58',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'УРОЛОГИЯ',
                _id: '5ca4e2230a295ffa34f69f59',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ОТОРИНОЛАРИНГОЛОГИЯ',
                _id: '5ca4e2230a295ffa34f69f5a',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ЛАБОРАТОРНЫЕ ИССЛЕДОВАНИЯ',
                _id: '5ca4e2230a295ffa34f69f5b',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Отдельные операции',
                _id: '5ca4e2230a295ffa34f69f5c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Общеклинические исследования',
                _id: '5ca4e2230a295ffa34f69f5d',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Гематологические исследования',
                _id: '5ca4e2230a295ffa34f69f5e',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '17',
                nameAsIs: 'Цитологические исследования',
                _id: '5ca4e2230a295ffa34f69f5f',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '18',
                nameAsIs: 'Биохимические исследования',
                _id: '5ca4e2230a295ffa34f69f60',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '26',
                nameAsIs:
                    'Определение гормонов методом иммуноферментного анализа на иммуноферментном анализаторе ARCHITECT',
                _id: '5ca4e2230a295ffa34f69f61',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '27',
                nameAsIs:
                    'Определение гормонов методом иммуноферментного анализа на иммуноферментном анализаторе BRIO',
                _id: '5ca4e2230a295ffa34f69f62',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '28',
                nameAsIs:
                    'Определение канцеромаркеров методом иммуноферментного анализа на иммуноферментном анализаторе ARCHITECT',
                _id: '5ca4e2230a295ffa34f69f63',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '29',
                nameAsIs:
                    'Определение гормонов методом радиоиммунного анализа (РИА)',
                _id: '5ca4e2230a295ffa34f69f64',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Исследование состояния гемостаза',
                _id: '5ca4e2230a295ffa34f69f65',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Бактериологические исследования',
                _id: '5ca4e2230a295ffa34f69f66',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '41',
                nameAsIs:
                    'Определение инфекций, передающихся половым путем методом иммуноферментного анализа на иммуноферментном анализаторе BRIO',
                _id: '5ca4e2230a295ffa34f69f67',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                codeAsIs: '47',
                nameAsIs:
                    'Определение гормонов методом иммуноферментного анализа на иммуноферментном анализаторе Cobas 6000 (cito!)',
                _id: '5ca4e2230a295ffa34f69f68',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Гистологические исследования',
                _id: '5ca4e2230a295ffa34f69f69',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Определение ИППП методом ПЦР',
                _id: '5ca4e2230a295ffa34f69f6a',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f5b',
            },
            {
                codeDepth: 0,
                codeAsIs: '29',
                nameAsIs: 'КОСМЕТОЛОГИЯ',
                _id: '5ca4e2230a295ffa34f69f6b',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Отдельные косметологические процедуры и манипуляции',
                _id: '5ca4e2230a295ffa34f69f6c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f6b',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Маникюр и педикюр',
                _id: '5ca4e2230a295ffa34f69f6d',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f6b',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ОЗОНОТЕРАПИЯ',
                _id: '5ca4e2230a295ffa34f69f6e',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Склеротерапия',
                _id: '5ca4e2230a295ffa34f69f6f',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f6e',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ДЕРМАТОВЕНЕРОЛОГИЯ (АНОНИМНО)',
                _id: '5ca4e2230a295ffa34f69f70',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ИММУНОПРОФИЛАКТИКА',
                _id: '5ca4e2230a295ffa34f69f71',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs:
                    'ПОДБОР КОНТАКТНЫХ ЛИНЗ (о наличии контактных линз уточнять в справке по тел: 267-65-10)',
                _id: '5ca4e2230a295ffa34f69f72',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f71',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.2',
                nameAsIs: 'Первичный подбор мягких контактных линз (2 линзы)',
                _id: '5ca4e2230a295ffa34f69f73',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f71',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.2.п',
                nameAsIs:
                    'Повторный подбор контактных линз (2 линзы) с раствором',
                _id: '5ca4e2230a295ffa34f69f74',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f71',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.5.п',
                nameAsIs:
                    'Повторный подбор контактных линз (2 линзы) без раствора',
                _id: '5ca4e2230a295ffa34f69f75',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f71',
            },
            {
                codeDepth: 1,
                codeAsIs: '2.6.п',
                nameAsIs:
                    'Повторный подбор контактных линз (1 линза) без раствора',
                _id: '5ca4e2230a295ffa34f69f76',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f71',
            },
            {
                codeDepth: -1,
                nameAsIs: 'СТОМАТОЛОГИЯ',
                _id: '5ca4e2230a295ffa34f69f77',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Общие стоматологические мероприятия',
                _id: '5ca4e2230a295ffa34f69f78',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Стоматология терапевтическая',
                _id: '5ca4e2230a295ffa34f69f79',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs:
                    'Препарирование твердых тканей одного зуба при лечении кариеса (I, II, III, IV, V классов по Блэку) и некариозных заболеваний, возникших после прорезывания зубов с локализацией полостей независимо от поверхности',
                _id: '5ca4e2230a295ffa34f69f7a',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs:
                    'Эндотическое лечение одного зуба при пульпите и апикальном периодонтите',
                _id: '5ca4e2230a295ffa34f69f7b',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs:
                    'Реставрация коронковой части одного зуба фотополимерным композиционным материалом при лечении кариозной полости I, II, III, IV, V классов по Блэку с локализацией полостей независимо от поверхности',
                _id: '5ca4e2230a295ffa34f69f7c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs:
                    'Реставрация коронковой части одного зуба компомером при лечении кариозной полости I, II, III, IV, V классов по Блэку с локализацией полостей независимо от поверхности',
                _id: '5ca4e2230a295ffa34f69f7d',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Использование матрицы',
                _id: '5ca4e2230a295ffa34f69f7e',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs:
                    'Шлифовка, полировка пломбы из композиционного материала:',
                _id: '5ca4e2230a295ffa34f69f7f',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Стоматология хирургическая',
                _id: '5ca4e2230a295ffa34f69f80',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Стоматологические комплексы',
                _id: '5ca4e2230a295ffa34f69f81',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: 1,
                nameAsIs:
                    'Стоматология ортопедическакя (клиническая часть ортопедического стоматологического лечения)',
                _id: '5ca4e2230a295ffa34f69f82',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f77',
            },
            {
                codeDepth: -1,
                nameAsIs: 'ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ',
                _id: '5ca4e2230a295ffa34f69f83',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
            },
            {
                codeDepth: 1,
                nameAsIs: 'Услуги проката',
                _id: '5ca4e2230a295ffa34f69f84',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                parentGroupId: '5ca4e2230a295ffa34f69f83',
            },
            {
                codeDepth: -1,
                nameAsIs: 'Рефлексотерапия',
                _id: '5ca4e2300a295f7450643627',
                sourceFileId: '5ca4e2300a295f74506435f6',
                providerId: '5b3f5cf17c5d77cc4',
                priceListId: '5ca4e2300a295f7450643626',
            },
        ],
        items: [
            {
                codeDepth: 2,
                codeAsIs: '3.4.1',
                nameAsIs: 'Пунктурная гирудотерапия (1 пиявка)',
                _id: '5bb346281966cb52609f384b',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.2',
                nameAsIs: 'Пунктурная гирудотерапия (2 пиявки)',
                _id: '5bb346281966cb52609f384c',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.3',
                nameAsIs: 'Пунктурная гирудотерапия (3 пиявки)',
                _id: '5bb346281966cb52609f384d',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.4',
                nameAsIs: 'Пунктурная гирудотерапия (4 пиявки)',
                _id: '5bb346281966cb52609f384e',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.5',
                nameAsIs: 'Пунктурная гирудотерапия (5 пиявок)',
                _id: '5bb346281966cb52609f384f',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.6',
                nameAsIs: 'Пунктурная гирудотерапия (6 пиявок)',
                _id: '5bb346281966cb52609f3850',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.7',
                nameAsIs: 'Пунктурная гирудотерапия (7 пиявок)',
                _id: '5bb346281966cb52609f3851',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.8',
                nameAsIs: 'Пунктурная гирудотерапия (8 пиявок)',
                _id: '5bb346281966cb52609f3852',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.9',
                nameAsIs: 'Пунктурная гирудотерапия (9 пиявок)',
                _id: '5bb346281966cb52609f3853',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.10',
                nameAsIs: 'Пунктурная гирудотерапия (10 пиявок)',
                _id: '5bb346281966cb52609f3854',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.4.11',
                nameAsIs: 'Пунктурная гирудотерапия (11 пиявок)',
                _id: '5bb346281966cb52609f3855',
                providerId: '5b4c5eceaf981f5f0',
                priceListId: '5bb346271966cb52609f370e',
                sourceFileId: '5bb346271966cb52609f370d',
                effFromDate: '2018-08-14',
                groupId: '5bb346271966cb52609f3749',
                groupName: 'Пунктурная гирудотерапия',
                priceUnits: 'проц',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '240017-1',
                nameAsIs: 'Пунктурная гирудотерапия (1 пиявка)',
                _id: '5bedbf0e60fb7419e8f2d2b3',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d257',
                groupName: 'Рефлексотерапия',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '240017-2',
                nameAsIs: 'Пунктурная гирудотерапия (2 пиявки)',
                _id: '5bedbf0e60fb7419e8f2d2b4',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d257',
                groupName: 'Рефлексотерапия',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '240017-3',
                nameAsIs: 'Пунктурная гирудотерапия (3 пиявки)',
                _id: '5bedbf0e60fb7419e8f2d2b5',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d257',
                groupName: 'Рефлексотерапия',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '240017-4',
                nameAsIs: 'Пунктурная гирудотерапия (4 пиявки)',
                _id: '5bedbf0e60fb7419e8f2d2b6',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d257',
                groupName: 'Рефлексотерапия',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '240017-5',
                nameAsIs: 'Пунктурная гирудотерапия (5 пиявок)',
                _id: '5bedbf0e60fb7419e8f2d2b7',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d257',
                groupName: 'Рефлексотерапия',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '240017-6',
                nameAsIs: 'Пунктурная гирудотерапия (6 пиявок)',
                _id: '5bedbf0e60fb7419e8f2d2b8',
                providerId: '5b3f59191860bcdde',
                priceListId: '5bedbf0e60fb7419e8f2d249',
                sourceFileId: '5bedbf0e60fb7419e8f2d248',
                effFromDate: '2018-08-23',
                groupId: '5bedbf0e60fb7419e8f2d257',
                groupName: 'Рефлексотерапия',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 1,
                codeAsIs: '18.25',
                nameAsIs: 'Пунктурная гирудотерапия (без стоимости пиявки)',
                _id: '5c59a332198e5d1108315dfb',
                providerId: '5b4c5eb620ae653c1',
                priceListId: '5c59a32f198e5d1108315a6f',
                sourceFileId: '5c59a32f198e5d1108315a6e',
                effFromDate: '2019-01-29',
                groupId: '5c59a32f198e5d1108315a87',
                groupName: 'Рефлексотерапия',
                priceUnits: 'процедура',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.30.1',
                nameAsIs: 'Пунктурная гирудотерапия с 2-мя пиявками',
                _id: '5ca4e2250a295ffa34f6a049',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                effFromDate: '2018-07-02',
                groupId: '5ca4e2230a295ffa34f69f2c',
                groupName: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.30.2',
                nameAsIs: 'Пунктурная гирудотерапия с 3-мя пиявками',
                _id: '5ca4e2250a295ffa34f6a04a',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                effFromDate: '2018-07-02',
                groupId: '5ca4e2230a295ffa34f69f2c',
                groupName: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.30.3',
                nameAsIs: 'Пунктурная гирудотерапия с 4-мя пиявками',
                _id: '5ca4e2250a295ffa34f6a04b',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                effFromDate: '2018-07-02',
                groupId: '5ca4e2230a295ffa34f69f2c',
                groupName: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.30.4',
                nameAsIs: 'Пунктурная гирудотерапия с 5-ю пиявками',
                _id: '5ca4e2250a295ffa34f6a04c',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                effFromDate: '2018-07-02',
                groupId: '5ca4e2230a295ffa34f69f2c',
                groupName: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.30.5',
                nameAsIs: 'Пунктурная гирудотерапия с 6-ю пиявками',
                _id: '5ca4e2250a295ffa34f6a04d',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                effFromDate: '2018-07-02',
                groupId: '5ca4e2230a295ffa34f69f2c',
                groupName: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.30.6',
                nameAsIs: 'Пунктурная гирудотерапия с 7-ю пиявками',
                _id: '5ca4e2250a295ffa34f6a04e',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                effFromDate: '2018-07-02',
                groupId: '5ca4e2230a295ffa34f69f2c',
                groupName: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 2,
                codeAsIs: '3.30.7',
                nameAsIs: 'Пунктурная гирудотерапия с 8-ю пиявками',
                _id: '5ca4e2250a295ffa34f6a04f',
                providerId: '5b4c5e876e354dbfb',
                priceListId: '5ca4e2230a295ffa34f69f1c',
                sourceFileId: '5ca4e2230a295ffa34f69f1b',
                effFromDate: '2018-07-02',
                groupId: '5ca4e2230a295ffa34f69f2c',
                groupName: 'НЕТРАДИЦИОННЫЕ МЕТОДЫ ЛЕЧЕНИЯ',
                complex: false,
                hasExtDeps: false,
            },
            {
                codeDepth: 3,
                codeAsIs: '16.3.30.1',
                nameAsIs: 'Пунктурная гирудотерапия (1 пиявка)',
                _id: '5ca4e2330a295f74506438ee',
                providerId: '5b3f5cf17c5d77cc4',
                branchIds: ['5bfbf3ffeee022cd3'],
                priceListId: '5ca4e2300a295f7450643626',
                sourceFileId: '5ca4e2300a295f74506435f6',
                effFromDate: '2019-03-12',
                groupId: '5ca4e2300a295f7450643627',
                groupName: 'Рефлексотерапия',
                complex: false,
                hasExtDeps: false,
            },
        ],
        depItems: [],
        mapping: [
            {
                _id: '5bbf5b0d213a0475077ba554',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f384b',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf27'],
            },
            {
                _id: '5bbf5b0d213a0475077ba54c',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f384c',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf28'],
            },
            {
                _id: '5bbf5b0d213a0475077ba54f',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f384d',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf29'],
            },
            {
                _id: '5bbf5b0d213a0475077ba54d',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f384e',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2a'],
            },
            {
                _id: '5bbf5b0d213a0475077ba54e',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f384f',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2b'],
            },
            {
                _id: '5bbf5b0d213a0475077ba550',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f3850',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2c'],
            },
            {
                _id: '5bbf5b0d213a0475077ba551',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f3851',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2d'],
            },
            {
                _id: '5bbf5b0d213a0475077ba552',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f3852',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2e'],
            },
            {
                _id: '5bbf5b0d213a0475077ba553',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f3853',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2f'],
            },
            {
                _id: '5bbf5b0d213a0475077ba54a',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f3854',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf30'],
            },
            {
                _id: '5bbf5b0d213a0475077ba54b',
                _updated: 0,
                priceListId: '5bb346271966cb52609f370e',
                providerId: '5b4c5eceaf981f5f0',
                priceItemId: '5bb346281966cb52609f3855',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf31'],
            },
            {
                _id: '5bee8831ce00ee27d0a765d6',
                _updated: 1542359089739,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d2b3',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf27'],
            },
            {
                _id: '5bee8839ce00ee27d0a765da',
                _updated: 1542359103259,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d2b4',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf28'],
            },
            {
                _id: '5bee8839ce00ee27d0a765d8',
                _updated: 1542359107285,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d2b5',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf29'],
            },
            {
                _id: '5bee8839ce00ee27d0a765d7',
                _updated: 1542359112715,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d2b6',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2a'],
            },
            {
                _id: '5bee8839ce00ee27d0a765d9',
                _updated: 1542359117872,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d2b7',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2b'],
            },
            {
                _id: '5bee8839ce00ee27d0a765db',
                _updated: 1542359122262,
                priceListId: '5bedbf0e60fb7419e8f2d249',
                providerId: '5b3f59191860bcdde',
                priceItemId: '5bedbf0e60fb7419e8f2d2b8',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2c'],
            },
            {
                _id: '5c5d75f4be95841639a15075',
                _updated: 1549628916886,
                priceListId: '5c59a32f198e5d1108315a6f',
                providerId: '5b4c5eb620ae653c1',
                priceItemId: '5c59a332198e5d1108315dfb',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5c5d75dcbe95841639a15073'],
            },
            {
                _id: '5ca4e2700a295f836cd6f27b',
                _updated: 0,
                priceListId: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                priceItemId: '5ca4e2250a295ffa34f6a049',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf28'],
            },
            {
                _id: '5ca4e2700a295f836cd6f0dd',
                _updated: 0,
                priceListId: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                priceItemId: '5ca4e2250a295ffa34f6a04a',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf29'],
            },
            {
                _id: '5ca4e2710a295f836cd6f552',
                _updated: 0,
                priceListId: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                priceItemId: '5ca4e2250a295ffa34f6a04b',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf29'],
            },
            {
                _id: '5ca4e2700a295f836cd6f206',
                _updated: 0,
                priceListId: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                priceItemId: '5ca4e2250a295ffa34f6a04c',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2b'],
            },
            {
                _id: '5ca4e2710a295f836cd6f489',
                _updated: 0,
                priceListId: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                priceItemId: '5ca4e2250a295ffa34f6a04d',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2c'],
            },
            {
                _id: '5ca4e2700a295f836cd6f1ef',
                _updated: 0,
                priceListId: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                priceItemId: '5ca4e2250a295ffa34f6a04e',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2d'],
            },
            {
                _id: '5ca4e2710a295f836cd6f43e',
                _updated: 0,
                priceListId: '5ca4e2230a295ffa34f69f1c',
                providerId: '5b4c5e876e354dbfb',
                priceItemId: '5ca4e2250a295ffa34f6a04f',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf2e'],
            },
            {
                _id: '5ca4e2850a295f836cd6f862',
                _updated: 1552479042393,
                priceListId: '5ca4e2300a295f7450643626',
                providerId: '5b3f5cf17c5d77cc4',
                branchIds: ['5bfbf3ffeee022cd3'],
                priceItemId: '5ca4e2330a295f74506438ee',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bbd02c57cf5cf2c1cc9bae6',
                specOptionIds: ['5bbd02c57cf5cf2c1cc9bf27'],
            },
        ],
    },
    '5bcf3c70213a045efd0cddab': {
        title: 'Использование услуги: Беруши [Рентген, КТ, МРТ / Материалы]',
        providers: [],
        priceLists: [
            {
                title: 'Магнитно-резонансная томография (МРТ)',
                _id: '5c9e335cfbd3f4b9505011a2',
                providerId: '5b3f5fa4529813245',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                sourceFileName: 'price_lode_minsk_v2019-03-29_all.xlsx',
                sourceTabIndex: 0,
                sourceTabName: 'Page 1',
                groupCount: 1,
                priceCount: 82,
            },
        ],
        files: [
            {
                _id: '5c9e335cfbd3f4b95050117e',
                filename: 'Лодэ-Минск (2019-03-29).xlsx',
                uploadDate: 1553871710665,
                length: 322226,
                md5: '12e2cd45593b51322a0289118854eaa6',
                meta: {
                    providerId: '5b3f5fa4529813245',
                    sourceFileName: 'price_lode_minsk_v2019-03-29_all.xlsx',
                    priceLists: [
                        {
                            id: '5c9e335cfbd3f4b95050117f',
                            title: 'Аллергология',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501182',
                            title: 'Анестезиология',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501184',
                            title: 'Анестезиология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501186',
                            title: 'Выезд врача на дом',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501188',
                            title: 'Гинекология',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050118a',
                            title:
                                'Годовое комплексное обслуживание и обследование',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050118c',
                            title: 'Дерматовенерология',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050118e',
                            title: 'Дополнительно',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501190',
                            title: 'Зуботехнические работы',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501192',
                            title: 'Иммунопрофилактика',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501194',
                            title: 'Консультация врачей-специалистов',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501196',
                            title: 'Косметические (бытовые) услуги',
                        },
                        {
                            id: '5c9e335cfbd3f4b950501198',
                            title: 'Косметология хирургическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050119a',
                            title: 'Косметология хирургическая (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050119c',
                            title: 'Лабораторная диагностика',
                        },
                        {
                            id: '5c9e335cfbd3f4b95050119e',
                            title: 'Лазерная косметология',
                        },
                        { id: '5c9e335cfbd3f4b9505011a0', title: 'Логопедия' },
                        {
                            id: '5c9e335cfbd3f4b9505011a2',
                            title: 'Магнитно-резонансная томография (МРТ)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011a4',
                            title:
                                'Магнитно-резонансная томография (МРТ) (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011a6',
                            title: 'Манипуляции общего назначения',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011a8',
                            title: 'Манипуляции общего назначения (иностр.)',
                        },
                        { id: '5c9e335cfbd3f4b9505011aa', title: 'Массаж' },
                        {
                            id: '5c9e335cfbd3f4b9505011ac',
                            title: 'Медицинские осмотры и обследования',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ae',
                            title: 'Общие стоматологические мероприятия',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b0',
                            title:
                                'Оказание медицинской помощи в стационаре в послеоперационный период',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b2',
                            title:
                                'Оказание медицинской помощи в стационаре в послеоперационный период (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b4',
                            title: 'Оперативная гинекология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b6',
                            title: 'Оперативная гинекология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011b8',
                            title: 'Оперативная оториноларингология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ba',
                            title: 'Оперативная оториноларингология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011bc',
                            title: 'Оперативная проктология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011be',
                            title: 'Оперативная проктология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c0',
                            title: 'Оперативная урология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c2',
                            title: 'Оперативная урология (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c4',
                            title: 'Оперативная хирургия',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011c6',
                            title: 'Оперативная хирургия (иностр.)',
                        },
                        { id: '5c9e335cfbd3f4b9505011c8', title: 'Ортопедия' },
                        {
                            id: '5c9e335cfbd3f4b9505011ca',
                            title: 'Оториноларингология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011cc',
                            title: 'Офтальмология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ce',
                            title: 'Офтальмология хирургическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d0',
                            title: 'Офтальмология хирургическая (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d2',
                            title: 'Прием врача-педиатра',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d4',
                            title: 'Прием врачей-специалистов',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d6',
                            title: 'Проктология',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011d8',
                            title: 'Психотерапия',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011da',
                            title: 'реестр материалов (ВРТ)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011dc',
                            title: 'реестр материалов (наборы для оперблока)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011de',
                            title: 'реестр материалов (оперблок)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e0',
                            title: 'реестр материалов (стоматология)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e2',
                            title:
                                'Рентгеновская компьютерная томография (РКТ)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e4',
                            title:
                                'Рентгеновская компьютерная томография (РКТ) (иностр.)',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e6',
                            title: 'Рентгенологическая диагностика',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011e8',
                            title: 'Рентгенологические исследования',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ea',
                            title: 'Стоматология ортодонтическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ec',
                            title: 'Стоматология ортопедическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011ee',
                            title: 'Стоматология терапевтическая',
                        },
                        {
                            id: '5c9e335cfbd3f4b9505011f0',
                            title: 'Стоматология хирургическая',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011f2',
                            title: 'сторонние орган. (лаборатория)',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011f4',
                            title: 'сторонние орган. (стоматология)',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011f6',
                            title: 'Ультразвуковое исследование',
                        },
                        { id: '5c9e335dfbd3f4b9505011f8', title: 'Урология' },
                        {
                            id: '5c9e335dfbd3f4b9505011fa',
                            title: 'Физиотерапия',
                        },
                        {
                            id: '5c9e335dfbd3f4b9505011fc',
                            title: 'Функциональная диагностика',
                        },
                        { id: '5c9e335dfbd3f4b9505011fe', title: 'Хирургия' },
                        {
                            id: '5c9e335dfbd3f4b950501200',
                            title: 'Цифровые рентгенологические исследования',
                        },
                        { id: '5c9e335dfbd3f4b950501202', title: 'ЭКО' },
                        {
                            id: '5c9e335dfbd3f4b950501204',
                            title: 'Эндоскопические исследования',
                        },
                    ],
                },
            },
        ],
        groups: [
            {
                codeDepth: -1,
                nameAsIs: 'Магнитно-резонансная томография (МРТ)',
                _id: '5c9e335cfbd3f4b9505011a3',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                providerId: '5b3f5fa4529813245',
                priceListId: '5c9e335cfbd3f4b9505011a2',
            },
        ],
        items: [
            {
                codeDepth: 1,
                codeAsIs: '43.6м',
                nameAsIs: 'беруши, пара',
                _id: '5c9e335ffbd3f4b950501668',
                providerId: '5b3f5fa4529813245',
                branchIds: ['5b3f5fa4529813245'],
                priceListId: '5c9e335cfbd3f4b9505011a2',
                sourceFileId: '5c9e335cfbd3f4b95050117e',
                effFromDate: '2019-03-12',
                groupId: '5c9e335cfbd3f4b9505011a3',
                groupName: 'Магнитно-резонансная томография (МРТ)',
                complex: false,
                hasExtDeps: false,
            },
        ],
        depItems: [],
        mapping: [
            {
                _id: '5c9e338dfbd3f4793026b507',
                _updated: 1548859510587,
                priceListId: '5c9e335cfbd3f4b9505011a2',
                providerId: '5b3f5fa4529813245',
                branchIds: ['5b3f5fa4529813245'],
                priceItemId: '5c9e335ffbd3f4b950501668',
                specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
                specItemId: '5bcf3c70213a045efd0cddab',
                specOptionIds: ['5c500b9bce00ee33424e0665'],
            },
        ],
    },
    '5bbd02c57cf5cf2c1cc9bbcb': {
        title: 'Использование услуги: Консультация гинеколога [Гинекология / Консультация специалиста]',
        providers: [],
        priceLists: [],
        files: [],
        groups: [],
        depItems: [],
        mapping: [{
            _id: '5d68d5f70bc320a228a9a00a',
            _updated: 1546592289236,
            priceListId: '5d68d5ec0bc320a228a98e9f',
            providerId: '5b3f5fa4529813245',
            branchIds: ['5b3f5fa4529813245', '5b3f5faa72b310212'],
            priceItemId: '5d68d5ee0bc320a228a99009',
            specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
            specItemId: '5bbd02c57cf5cf2c1cc9bbcb',
            specOptionIds: ['5bbd02c57cf5cf2c1cc9bc21', '5c2f1157ce00ee33424563fa'],
        }, {
            _id: '5d68d5fd0bc320a228a9a97e',
            _updated: 1546592438728,
            priceListId: '5d68d5ec0bc320a228a98e9f',
            providerId: '5b3f5fa4529813245',
            branchIds: ['5b3f5fa4529813245', '5b3f5faa72b310212'],
            priceItemId: '5d68d5ee0bc320a228a9900a',
            specVersionId: '5bbd02c57cf5cf2c1cc9b64c',
            specItemId: '5bbd02c57cf5cf2c1cc9bbcb',
            specOptionIds: ['5bbd02c57cf5cf2c1cc9bc22', '5c2f1157ce00ee33424563fa'],
        }],
    },
};
