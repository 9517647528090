import EStyleSheet from 'react-native-extended-stylesheet';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import {
    List, React, Text, TouchableWithoutFeedback, withIsAuthenticated,
} from '../../common';
import { TextWithAuth } from '../../combo';
import { isAdult, isFutureVisit, sortBy } from '../../utils';
import { fnDotId } from '../../utils/fn';
import { getEnableLoadPastsVisits, getUser, getUserCards } from '../../selectors';
import { CREATED_MANUALLY } from '../../actions/visits';
import { ActualVisitListItem } from './VisitListItem';

export const getVisitsData = ({ data, doctorId }) => {
    const futureVisits = data.filter(
        ({ targetDate, status }) => (isFutureVisit(targetDate) && status === 'reserved') ||
            isFutureVisit(targetDate) ||
            status === CREATED_MANUALLY,
    );
    const otherVisits = data.filter(({ id }) => !futureVisits.some(({ id: futureId }) => futureId === id));
    // const otherVisits = data.filter(({ targetDate, status }) => !((isFutureVisit(targetDate)) && status === 'reserved'));
    const sortedVisits = [...sortBy(futureVisits, 'startDate'), ...sortBy(otherVisits, '-startDate')];
    return !doctorId ? sortedVisits : sortedVisits.filter(visitInfo => `${visitInfo.physicianId}` === `${doctorId}`);
};

const EmptyPlannedVisits = ({ paddingLeft }) => {
    const enableLoadPastVisits = useSelector(getEnableLoadPastsVisits);
    const { isAuthenticated, info, isLoading } = useSelector(getUser);
    const isUserAdult = i => i?.birthday && isAdult(i?.birthday);

    if (isLoading !== false) {
        return null;
    }
    if (!isUserAdult(info) && isAuthenticated && isLoading === false) {
        return (
            <>
                <TextWithAuth paddingLeft={paddingLeft} id="titles.visitsForAdultsOnly" />
            </>
        );
    }

    if (!enableLoadPastVisits || !isAuthenticated) {
        return <TextWithAuth paddingLeft={paddingLeft} id="notes.you_have_no_visits" />;
    }

    const navigation = useNavigation();

    const updateVisitsHandler = () => {
        navigation.navigate('MyCard', { screen: 'LoadVisits' });
    };

    const style = EStyleSheet.create({
        link: {
            color: '$appTitleColor',
            fontSize: 14,
        },

        text: {
            color: '$lightGray',
            fontSize: 14,
        },
    });

    return (
        <Text style={{ paddingHorizontal: 10 }} numberOfLines={0}>
            <Text style={style.text}>{Object.R('titles.loadVisitsHint')}</Text>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <TouchableWithoutFeedback
                onPress={updateVisitsHandler}
                style={{
                    padding: 0,
                    margin: 0,
                }}>
                <Text style={style.link}>{Object.R('titles.loadVisitsLink')}</Text>
            </TouchableWithoutFeedback>
        </Text>
    );
};

export const VisitsList = withIsAuthenticated(
    React.memo(
        ({
            isAuthenticated,
            data,
            doctorId,
            ListItem = ActualVisitListItem,
            hasDoctorName,
            ListEmptyComponent,
            paddingLeft = 12,
            enableLoadFromClinic = false,
            onGetRef,
            refId,
            isInPastVisitScreen,
        }) => {
            const cards = useSelector(getUserCards);
            if (!isAuthenticated) {
                return isAuthenticated === undefined ? null : (
                    <TextWithAuth id="notes.you_have_no_visits" paddingLeft={10} />
                );
            }
            if (data == null) {
                return null;
            }
            return (
                <List
                    accessibilityLabel="list"
                    data={getVisitsData({
                        data,
                        doctorId,
                    })}
                    keyExtractor={fnDotId}
                    renderItem={({ item }) => (
                        <>
                            <ListItem
                                visitInfo={item}
                                hasDoctorName={hasDoctorName}
                                onGetRef={(r) => {
                                    if (refId && item.id === refId) {
                                        onGetRef(r);
                                    }
                                }}
                                isInPastVisitScreen={isInPastVisitScreen}
                            />
                        </>
                    )}
                    ListEmptyComponent={
                        ListEmptyComponent ||
                        (enableLoadFromClinic && !cards?.length ? (
                            <EmptyPlannedVisits paddingLeft={paddingLeft} />
                        ) : (
                            <TextWithAuth paddingLeft={paddingLeft} id="notes.you_have_no_visits" />
                        ))
                    }
                />
            );
        },
    ),
);
