/* eslint-disable no-undef */
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Checkbox, CoverableBy, List, Modal2, React, SearchBar, TouchableWithoutFeedback, View,
} from '../common';
import { TabSwitch } from '../common/TabSwitch';
import { getMarginHorizontalObject, selector as styles } from '../styles/common';
import { sortBy } from '../utils';
import { putIntoStore } from '../store';
import { getCurrentOrFirstProgram, localFavoriteClinics } from '../selectors';
import { useKeyboardHeight } from '../common/Modal';
import { medcenterFilter as style } from './style';

const listData = (data, favorites, group, currentProgram, prefilterPath) => {
    const selectIf = fn => putIntoStore(
        prefilterPath,
        data.map(e => ({
            ...e,
            selected: fn(e),
        })),
    );
    let finalMedcenters = data.map(e => ({
        ...e,
        toggle: () => selectIf(ee => (ee.id === e.id ? !ee.selected : ee.selected)),
    }));
    let selectAllOption;
    if (group === 'byFavorite') {
        finalMedcenters = finalMedcenters.filter(({ id }) => favorites.some(({ id: favoriteId }) => id === favoriteId));
        const hasUnselectedFavorite = data.some(e => !e.selected && favorites.some(clinic => clinic.id === e.id));
        selectAllOption = {
            id: 0,
            name: Object.R('titles.allMedcentersFromFavorites'),
            selected: !hasUnselectedFavorite,
            toggle: hasUnselectedFavorite
                ? () => selectIf(e => favorites.some(clinic => clinic.id === e.id) || e.selected)
                : () => selectIf(e => (favorites.some(clinic => clinic.id === e.id) ? false : e.selected)),
        };
    } else if (group === 'byProgram') {
        finalMedcenters = finalMedcenters.filter(({ coverer }) => !!coverer);
        const hasUnselectedPartner = data.find(e => !e.selected && e.coverer);
        selectAllOption = {
            id: 0,
            name: Object.R('titles.allMedcentersFromPartner', { programName: currentProgram.name }),
            selected: !hasUnselectedPartner,
            coverer: currentProgram.coverer,
            toggle: hasUnselectedPartner
                ? () => selectIf(e => e.coverer || e.selected)
                : () => selectIf(e => (e.coverer ? false : e.selected)),
        };
    } else {
        const hasUnselected = finalMedcenters.find(e => !e.selected);
        selectAllOption = {
            id: 0,
            name: Object.R('titles.allMedicalCenters'),
            selected: !hasUnselected,
            toggle: () => selectIf(() => hasUnselected),
        };
    }
    finalMedcenters = sortBy(finalMedcenters, 'doctorsCounter', -1);
    finalMedcenters.splice(0, 0, selectAllOption);
    return finalMedcenters;
};

const MedcenterItem = ({
    item: {
        selected,
        coverer,
        toggle,
        name,
        id,
        limited,
        isLast,
    },
}) => (
    <TouchableWithoutFeedback>
        <View key={id} style={[style.listItemWrapper, isLast && [{ borderBottomWidth: 0 }]]}>
            <Checkbox
                onPress={toggle}
                selected={selected}
                title={name}
                badge={<CoverableBy coverer={coverer} isLimited={limited}/>}
            />
        </View>
    </TouchableWithoutFeedback>
);

const MedcentersGroupFilter = ({
    haveFavoriteMedcenters,
    haveProgramMedcenters,
    group,
    groupPath,
}) => {
    const medcenterFilterOptions = [
        {
            id: 'any',
            isActive: false,
            name: Object.R('titles.any'),
        },
    ];
    if (haveFavoriteMedcenters) {
        medcenterFilterOptions.splice(0, 0, {
            id: 'byFavorite',
            isActive: false,
            name: Object.R('titles.fromFavorites'),
        });
    }
    if (haveProgramMedcenters) {
        medcenterFilterOptions.push({
            id: 'byProgram',
            isActive: false,
            name: Object.R('title.by_program'),
        });
    }
    const currentFilter = medcenterFilterOptions.find(({ id }) => id === group);
    if (currentFilter) {
        currentFilter.isActive = true;
    } else {
        medcenterFilterOptions.find(({ id }) => id === 'any').isActive = true;
        putIntoStore(groupPath, 'any');
    }
    return medcenterFilterOptions.length > 1 ? (
        <TabSwitch
            containerStyle={style.tabSwitch}
            data={medcenterFilterOptions}
            onChange={id => putIntoStore(groupPath, id)}
        />
    ) : null;
};

const MedcentersFiltersList = ({
    medcenters,
    isWithSwitch,
}) => (
    <View
        style={[{ flex: 1 }, styles.listWrapper, isWithSwitch ? style.listFilterWithSwitch : style.listFilter, { borderBottomWidth: 1 }]}
        accessibilityLabel="list-wrapper">
        <List data={medcenters} renderItem={MedcenterItem}/>
    </View>
);

export const MedcentersFilterModal = ({
    groupFrom,
    prefilterDataFrom,
    prefilterPath,
    groupPath,
}) => {
    const favorites = useSelector(localFavoriteClinics);
    const currentProgram = useSelector(getCurrentOrFirstProgram);
    const prefilterData = useSelector(prefilterDataFrom);
    const group = useSelector(groupFrom);

    const finalMedcenters = listData(prefilterData, favorites, group, currentProgram, prefilterPath);
    if (Array.isArray(finalMedcenters)) {
        finalMedcenters[finalMedcenters?.length - 1].isLast = true;
    }
    if (!finalMedcenters.length && group !== 'any') {
        putIntoStore(groupPath, 'any');
    }

    const haveFavoriteMedcenters =
        favorites.length && favorites.some(({ id: favoriteId }) => prefilterData.some(({ id }) => id === favoriteId));
    const haveProgramMedcenters = prefilterData.some(e => e.coverer);
    const isWithSwitch = haveProgramMedcenters || haveFavoriteMedcenters;
    const [keyword, setKeyword] = useState();
    const normKeyword = x => x
        .toLowerCase()
        .replace(/["'«»]/, '')
        .trim();

    const keyboardHeight = useKeyboardHeight(['ios']);

    return (
        <Modal2
            noScroll
            title="doctorsInfoFields.worksAt"
            containerStyles={[
                style.modalContainer,
                window.isDesktop ? { ...getMarginHorizontalObject() } : { ...getMarginHorizontalObject() },
                { marginBottom: keyboardHeight + 8 },
            ]}>
            <View style={style.medcentersFilter}>
                <MedcentersGroupFilter
                    haveFavoriteMedcenters={haveFavoriteMedcenters}
                    haveProgramMedcenters={haveProgramMedcenters}
                    group={group}
                    groupPath={groupPath}
                />
                <SearchBar autoCorrect={false} onSearch={setKeyword} initialValue={keyword}/>
                <MedcentersFiltersList
                    isWithSwitch={isWithSwitch}
                    medcenters={
                        keyword
                            ? finalMedcenters.filter(e => normKeyword(e?.name || '')
                                .includes(normKeyword(keyword)))
                            : finalMedcenters
                    }
                />
            </View>
        </Modal2>
    );
};
