import { useSelector } from 'react-redux';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useNavigation, useRoute } from '@react-navigation/native';
import actions, { navigate } from '../../../../actions';
import { InitialsAvatar } from '../../../../combo';
import {
    Box,
    FlatList,
    Icon,
    Modal,
    Page,
    Platform,
    React,
    showNotification,
    Subtitle,
    TabSelector,
    Text,
    TouchableOpacity,
    View,
} from '../../../../common';
import { LoadingIndicator, SubtitleHighlight, TitleHighlight } from '../../../../common/elements';
import { downloadFile, openFileOnNewTab } from '../../../../services/device';
import userServices from '../../../../services/user';
import { COLORS } from '../../../../styles/colors';
import { isCurrentYear } from '../../../../utils/dates';
import { formatDateShort } from '../../../../utils';
import { home as styles } from '../../styles';
import { listItem } from '../../../Visits/style';
import { dbGettr } from '../../../../selectors/utils';
import { getActualTests } from '../../../../selectors/tests';
import { getIsPastVisitsLoading, getIsSyncLoading } from '../../../../selectors';
import { aCodeSecurity } from '../../../../utils/aCodeSecurity';
import tracking from '../../../../services/tracking';
import { HIDE_TEST_TAB } from '../../../../config';

export const onActualResult = async (item, isVisit) => {
    const acode = await aCodeSecurity.getValidCode(item?.providerCode);
    let errorMessage;
    try {
        actions.startResultOrReportLoading();
        await userServices.tryToOpenResult(
            item,
            acode,
            (error) => {
                actions.stopResultOrReportLoading();
                if (typeof error === 'string') {
                    showNotification(error);
                } else if (error?.code === 'REENTER_ACODE' || error?.code === 'INVALID_ACODE') {
                    navigate('ACodeConfirmation', {
                        item,
                        isVisit,
                    });
                } else if (error || error === 0) {
                    const messageId = `titles.${error?.code}`;
                    errorMessage =
                        Object.R(messageId) === messageId
                            ? Object.R(error?.message || 'error.auth/network-error')
                            : Object.R(messageId);
                    // showNotification(error);
                }
            },
            (sourceData, contentType) => {
                actions.stopResultOrReportLoading();
                tracking.logEvent(`ai_${isVisit ? 'report' : 'test'}_open`);
                if (Platform.OS === 'web') {
                    openFileOnNewTab(sourceData, item, isVisit);
                } else {
                    contentType && contentType !== 'application/pdf'
                        ? downloadFile(sourceData, showNotification, contentType)
                        : navigate('PDFViewer', {
                              sourceData,
                              name: item?.name,
                          });
                }
            },
            isVisit,
            () => {
                actions.stopResultOrReportLoading();
            },
        );
        actions.setResultOrReportError(errorMessage);
    } catch (e) {
        actions.setResultOrReportError(e);
        // showNotification(Object.R('error.auth/internal-error'));
    } finally {
        actions.stopResultOrReportLoading();
    }
};

export const onResult = (originalItem, key = 'testResults', isVisit) => {
    const resultKey = isVisit ? 'report' : 'testResult';
    const results = originalItem[key];
    const titleName = isVisit ? 'titles.severalReports' : 'titles.severalResults';
    const subtitleName = isVisit ? 'titles.severalReportsHint' : 'titles.severalResultsHint';
    const itemName = isVisit ? 'titles.reportNumber' : 'titles.resultNumber';
    if (results.length === 1) {
        onActualResult(
            {
                ...originalItem,
                [resultKey]: results[0],
            },
            isVisit,
        );
    } else if (results.length > 1) {
        Page.showModal(
            <Modal
                title={titleName}
                subtitle={subtitleName}
                subtitleStyle={{
                    paddingRight: 12,
                    paddingHorizontal: 0,
                    marginHorizontal: 10,
                }}>
                <FlatList
                    data={results}
                    keyExtractor={(e) => (typeof e === 'string' ? e : e.id)}
                    renderItem={({
                        item,
                        index,
                        onItem = () => {
                            Page.closeModal();
                            onActualResult(
                                {
                                    ...originalItem,
                                    [resultKey]: item,
                                },
                                isVisit,
                            );
                        },
                    }) => (
                        <TouchableOpacity
                            key={String(item)}
                            onPress={onItem}
                            style={{
                                paddingVertical: 14,
                                paddingLeft: 20,
                                borderTopWidth: 1,
                                borderRadius: 3,
                                borderTopColor: '#E9E9E9',
                                borderColor: null,
                            }}>
                            <Box
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    marginBottom: 8,
                                    marginRight: 12,
                                }}>
                                <Text style={styles.selectModalTitle}>{Object.R(itemName, { index: index + 1 })}</Text>
                            </Box>
                        </TouchableOpacity>
                    )}
                />
            </Modal>,
        );
    }
};

const TestsLoading = () => {
    const { isLoading } = useSelector(getActualTests);
    const isSyncLoading = useSelector(getIsSyncLoading);
    const spinner =
        isLoading || isSyncLoading ? (
            <LoadingIndicator
                indicatorSize={15}
                style={{
                    paddingVertical: 0,
                    paddingLeft: 5,
                }}
            />
        ) : null;
    return (
        <View
            style={{
                width: 20,
                height: 15,
            }}>
            {spinner}
        </View>
    );
};
const VisitsLoading = () => {
    const isLoading = useSelector(getIsPastVisitsLoading);
    const spinner = isLoading ? (
        <LoadingIndicator
            indicatorSize={15}
            style={{
                paddingVertical: 0,
                paddingLeft: 5,
            }}
        />
    ) : null;
    return (
        <View
            style={{
                width: 20,
                height: 15,
            }}>
            {spinner}
        </View>
    );
};

export const MyCardTabSelector = () => {
    const enableTests = useSelector(dbGettr('enableTests'));
    const { setParams } = useNavigation();
    const { params } = useRoute();
    const tabItems = [
        {
            id: 'visits',
            name: Object.R('my-card.visitsTab'),
            child: <VisitsLoading />,
        },
    ];
    if (enableTests && HIDE_TEST_TAB !== '1') {
        tabItems.push({
            id: 'tests',
            name: 'my-card.testsTab',
            child: <TestsLoading />,
        });
    }

    const filter = params?.filter ?? tabItems[0].id;
    return enableTests && HIDE_TEST_TAB !== '1' ? (
        <TabSelector
            items={tabItems}
            selected={filter}
            onItem={(item) => {
                setParams({ filter: item.id });
            }}
            outerStyles={{
                marginBottom: 5,
                backgroundColor: '#f2f2f2',
                paddingHorizontal: 0,
            }}
        />
    ) : null;
};

// Tests snippets

const testStyles = EStyleSheet.create({
    text: {
        fontSize: 12,
        fontWeight: 'normal',
        fontFamily: '$sourceSansPropFontFamily',
    },
});

export const Results = ({ results, onReadyClick }) => (
    <TouchableOpacity onPress={results?.length ? onReadyClick : () => {}}>
        <View
            style={{
                borderRadius: 0,
                width: '100%',
                flexDirection: 'row',
            }}>
            <Icon.OpenVector
                color={COLORS.MAIN}
                style={{
                    paddingTop: 7,
                    paddingHorizontal: 2,
                }}
                size={14}
            />
            <Subtitle
                style={{
                    padding: 2,
                    color: COLORS.STANDARD_GRAY,
                    paddingLeft: 5,
                    paddingTop: 6,
                }}>
                {Object.R('titles.testsResultsQty', { qty: results?.length ?? 0 })}
            </Subtitle>
        </View>
    </TouchableOpacity>
);

export const StatusTest = ({ statusTest = '', bold = false, keywords }) => {
    const boldStyle = bold ? { fontFamily: 'SourceSansPro-Semibold' } : {};
    const statuses = {
        'in progress': Object.R('titles.testsStatusInProgress'),
        ready: Object.R('titles.testsStatusReady'),
    };

    return (
        <View
            style={{
                flex: 1,
                minWidth: 90,
                alignItems: 'flex-end',
                paddingTop: 0,
            }}>
            <SubtitleHighlight
                style={{ ...boldStyle }}
                textStyle={[{ textAlign: 'right' }, testStyles.text]}
                keyword={keywords.join(' ')}>
                {statuses[statusTest]}
            </SubtitleHighlight>
        </View>
    );
};

export const BranchTest = ({ clinic = '', keywords }) => (
    <View
        style={{
            flex: 5,
            paddingTop: 0,
        }}>
        <SubtitleHighlight keyword={keywords.join(' ')} textStyle={[testStyles.text]}>
            {clinic}
        </SubtitleHighlight>
    </View>
);

export const VisitorTest = ({ visit, myVisit = false, keywords }) => {
    const myVisitTitle = Object.R('titles.my_test');
    // eslint-disable-next-line no-unsafe-optional-chaining
    const visitName = (myVisit ? myVisitTitle : visit?.firstLastName).trim();
    return (
        <View
            style={{
                flex: 4,
                flexDirection: 'row',
                alignItems: 'center',
            }}>
            <InitialsAvatar coverer={null} size={25} {...visit} isMe={myVisit} />
            <SubtitleHighlight keyword={keywords.join(' ')} textStyle={[{ paddingLeft: 5 }, testStyles.text]}>
                {visitName}
            </SubtitleHighlight>
        </View>
    );
};

export const NameTest = ({ testName = '', keywords }) => (
    <View style={{ flex: 6 }}>
        <TitleHighlight keyword={keywords.join(' ')} bold style={[listItem.titleText]} numberOfLines={0}>
            {testName}
        </TitleHighlight>
    </View>
);

export const DateTest = ({ dateVisit = '', keywords }) => {
    const thisYear = isCurrentYear(dateVisit);
    const dateWidth = 130;
    const formattedDate = formatDateShort(dateVisit, !thisYear);
    return (
        <View
            style={{
                minWidth: thisYear ? dateWidth - 30 : dateWidth,
                alignItems: 'flex-end',
                paddingTop: 4,
            }}>
            <SubtitleHighlight textStyle={[{ textAlign: 'right' }, testStyles.text]} keyword={keywords.join(' ')}>
                {formattedDate}
            </SubtitleHighlight>
        </View>
    );
};
