/* eslint-disable class-methods-use-this */
import PushNotificationIOS from '@react-native-community/push-notification-ios';
import PushNotification, { Importance } from 'react-native-push-notification';
import { openDoctorFeedbackFromNotify, wasNotifPermAsked } from '../init';
import actions from '../actions';
import { Platform } from './react';

const CHANNEL_ID = 'visits_reminder_id';
const CHANNEL_NAME = 'Напоминания о визитах';
const CONSOLE_COLOR = 'red';

const colorConsole = (str, obj) => {
    // eslint-disable-next-line no-console
    console.log(`%c${str}`, `color: ${CONSOLE_COLOR}`, obj);
};

class Notifications {
    static instance = null;
    customRegisterHandler;
    customNotificationHandler;
    customActionHandler;
    customRegistrationErrorHandler;

    constructor(channelId, channelName) {
        if (Notifications.instance) {
            return Notifications.instance;
        }
        Notifications.instance = this;
        if (Platform.OS === 'web') {
            return this;
        }
        // this.createChannel(channelId, channelName);
        PushNotification.checkPermissions(({ alert }) => {
            if (alert) {
                this.createChannel(channelId, channelName);
                this.createChannel('fcm_fallback_notification_channel', 'Общие оповещения');
            }
        });
        // noinspection JSUnusedGlobalSymbols
        this.notifyId = 0;
        this.init();
    }

    registerHandler(token) {
        colorConsole('Register', token);
        if (this.customRegisterHandler) {
            this.customRegisterHandler(token);
        }
    }

    notificationHandler(notify) {
        colorConsole('Notify', notify);
        openDoctorFeedbackFromNotify(notify);
        if (this.customNotificationHandler) {
            this.customNotificationHandler(notify);
        }
    }

    actionHandler(action) {
        colorConsole('Action', action);
        if (this.customActionHandler) {
            this.customActionHandler(action);
        }
    }

    registrationErrorHandler(error) {
        colorConsole('Error', error);
        if (this.customRegistrationErrorHandler) {
            this.customRegistrationErrorHandler(error);
        }
    }

    finish(notify) {
        try {
            notify.finish(PushNotificationIOS.FetchResult.NoData);
        } catch (e) {
            colorConsole('Error finish notify', notify);
        }
    }

    init() {
        PushNotification.configure({
            onRegister: (token) => {
                this.registerHandler(token);
            },
            onNotification: (notify) => {
                this.notificationHandler(notify);
            },
            onAction: (action) => {
                this.actionHandler(action);
            },
            onRegistrationError: (error) => {
                this.registrationErrorHandler(error);
            },
            permissions: {
                alert: true,
                badge: true,
                sound: true,
            },
            popInitialNotification: true,
            requestPermissions: true,
        });
    }

    createChannel(id, name) {
        if (Platform.OS === 'web') {
            return;
        }
        PushNotification.createChannel(
            {
                channelId: id ?? CHANNEL_ID,
                channelName: name ?? CHANNEL_NAME,
                channelDescription: 'default channel',
                soundName: 'default',
                importance: Importance.HIGH,
                vibrate: true,
            },
            isCreated => colorConsole(`Channel ${id ?? CHANNEL_ID}`, isCreated ? 'created' : 'NOT CREATED'),
        );
    }

    prepareParams(params) {
        this.notifyId += 1;
        return {
            channelId: CHANNEL_ID,
            ticker: 'Visit ticker',
            foreground: true,
            autoCancel: true,
            largeIcon: 'ic_launcher', // (optional) default: "ic_launcher"
            smallIcon: 'ic_notification', // (optional) default: "ic_notification" with fallback for "ic_launcher"
            bigText: params.message,
            subText: params.should ?? params.title ?? '',
            color: 'blue', // (optional) default: system default
            vibrate: true, // (optional) default: true
            vibration: 300, // vibration length in milliseconds, ignored if vibrate=false, default: 1000
            tag: 'some_tag', // (optional) add tag to message
            group: 'group', // (optional) add group to message
            groupSummary: false, // (optional) set this notification to be the group summary for a group of notifications, default: false
            ongoing: false, // (optional) set whether this is an "ongoing" notification
            actions: [], // (Android only) See the doc for notification actions to know more
            invokeApp: true, // (optional) This enable click on actions to bring back the application to foreground or stay in background, default: true
            when: null, // (optionnal) Add a timestamp pertaining to the notification (usually the time the event occurred). For apps targeting Build.VERSION_CODES.N and above, this time is not shown anymore by default and must be opted into by using `showWhen`, default: null.
            usesChronometer: false, // (optional) Show the `when` field as a stopwatch. Instead of presenting `when` as a timestamp, the notification will show an automatically updating display of the minutes and seconds since when. Useful when showing an elapsed time (like an ongoing phone call), default: false.
            timeoutAfter: null, // (optional) Specifies a duration in milliseconds after which this notification should be canceled, if it is not already canceled, default: null

            /* iOS only properties */
            category: '', // (optional) default: empty string
            subtitle: params.subtitle || '', // ?? params.title ?? '-', // (optional) smaller title below notification title

            /* iOS and Android properties */
            // id: this.notifyId, // (optional) Valid unique 32 bit integer specified as string. default: Autogenerated Unique ID
            title: 'Title Notification', // (optional)
            message: 'Message', // (required)
            // userInfo: Platform.OS === 'ios' ? params.iOSId : params.androidId, // (optional) default: {} (using null throws a JSON value '<null>' error)
            userInfo: {},
            playSound: true,
            soundName: params.soundName ?? 'default', // (optional) Sound to play when the notification is shown. Value of 'default' plays the default sound. It can be set to a custom sound such as 'android.resource://com.xyz/raw/my_sound'. It will look for the 'my_sound' audio file in 'res/raw' directory and play it. default: 'default' (default sound is played)
            // number: Platform.OS === 'ios' ? 10 : '10', // (optional) Valid 32 bit integer specified as string. default: none (Cannot be zero)
            ...params,
        };
    }

    notification(params) {
        if (Platform.OS === 'web') {
            return;
        }

        const parameters = this.prepareParams(params);
        colorConsole('Notification: ', parameters);
        PushNotification.localNotification(parameters);
    }

    schedule(params) {
        if (Platform.OS === 'web') {
            return;
        }
        const parameters = this.prepareParams(params);
        colorConsole('Schedule Notification: ', parameters);
        PushNotification.localNotificationSchedule(parameters);
    }

    getScheduleList(call) {
        if (Platform.OS === 'web') {
            return;
        }
        PushNotification.getScheduledLocalNotifications((c, b) => {
            // eslint-disable-next-line no-console
            // console.log('List notifications schedule', {
            //     c,
            //     b,
            // });
            // eslint-disable-next-line no-unused-expressions
            call && call(c);
        });
    }

    cancelAll() {
        if (Platform.OS === 'web') {
            return;
        }
        colorConsole('Cancel all Notification: ');
        PushNotification.cancelAllLocalNotifications();
    }

    cancelNotification(id) {
        if (Platform.OS === 'web') {
            return;
        }
        colorConsole('Cancel Notification: ', id);
        PushNotification.cancelLocalNotification(id.toString());
    }

    cancelNotificationsByVisitId(visitId) {
        return new Promise(resolve => this.getScheduleList((list) => {
            colorConsole('Cancel visits notifications by id', visitId);
            for (let i = 0; i < list.length; i++) {
                const { data, id } = list[i];
                const { id: vId } = data;
                if (String(vId ?? '') === String(visitId)) {
                    this.cancelNotification(id);
                }
            }
            resolve();
        }));
    }

    cancelAllNotificationsForVisits() {
        return new Promise(resolve => this.getScheduleList((list) => {
            for (let i = 0; i < list.length; i++) {
                const { data, id } = list[i];
                const { id: vId } = data;
                if (vId) {
                    this.cancelNotification(id);
                }
            }
            resolve();
        }));
    }

    checkPermissions(onFailed, onGranted) {
        try {
            PushNotification?.checkPermissions?.(({ alert }) => {
                if (!alert) {
                    onFailed?.();
                } else {
                    wasNotifPermAsked.requestOK = true;
                    onGranted?.();
                }
                wasNotifPermAsked.value = true;
            });
        } catch (e) {
            actions.addLog('notifications', JSON.stringify(e?.message));
        }
    }
}

export const notifications = new Notifications();
