import { AppState } from 'react-native';
import { Platform } from '../common';


let appStateChangeHandler;
let appStateBlur;
let appStateFocus;

export const subscribeOnAppStateChanged = ({
    onActive,
    onInactive,
    onFocus,
    onBlur,
}) => {
    onActive();
    const onAppStateChange = (nextAppState) => {
        if (nextAppState === 'active') {
            onActive();
        } else {
            onInactive();
        }
    };

    const onAppStateFocus = () => {
        if (onFocus) {
            onFocus();
        }
    };

    const onAppStateBlur = () => {
        if (onBlur) {
            onBlur();
        }
    };

    const changeSub = AppState.addEventListener('change', onAppStateChange);
    if (appStateChangeHandler) {
        appStateChangeHandler();
    }
    appStateChangeHandler = () => {
        // AppState.removeEventListener('change', onAppStateChange);
        changeSub.remove();
    };

    if (Platform.OS === 'android') {
        const focusListener = AppState.addEventListener('focus', onAppStateFocus);
        const blurListener = AppState.addEventListener('blur', onAppStateBlur);
        if (appStateBlur) {
            appStateBlur();
        }
        appStateBlur = () => {
            // AppState.removeEventListener('blur', onAppStateChange);
            blurListener.remove();
        };
        if (appStateFocus) {
            appStateFocus();
        }
        appStateFocus = () => {
            // AppState.removeEventListener('focus', onAppStateChange);
            focusListener.remove();
        };
    }
};

// noinspection JSUnusedGlobalSymbols
export const unsubscribeOnAppStateChanged = () => {
    if (appStateChangeHandler) {
        appStateChangeHandler();
    }
};
