/* eslint-disable no-underscore-dangle */
// noinspection JSUnusedGlobalSymbols

import { getDrugsInfoByNameAndId, searchDrugs } from '../services/tabletka';
import { getSearchDrugsError, getTabletkaLocation } from '../selectors';
import { asyncActionCreator, createReducerFor } from './utils';

const TYPE = 'drugs';

const _fetch = asyncActionCreator(TYPE, 'data', [], opts => searchDrugs(opts));

export const resetSearchKeyword = asyncActionCreator(TYPE, 'keyword', '', () => {});

export const setSearchKeyword = keyword => async (dispatch) => {
    dispatch({
        type: TYPE,
        payload: { keyword },
    });
};

export const setRemoteKeyword = remoteKeyword => async (dispatch) => {
    dispatch({
        type: TYPE,
        payload: { remoteKeyword },
    });
};

export const setSearchShouldReload = shouldReload => async (dispatch) => {
    dispatch({
        type: TYPE,
        payload: { shouldReload },
    });
};

export const setSearchSuggestionsKeyword = suggestionsKeyword => async (dispatch) => {
    dispatch({
        type: TYPE,
        payload: { suggestionsKeyword },
    });
};

export const fetchDrugsByKeyword =
    (_keyword = '') => async (dispatch, getState) => {
        const keyword = _keyword.trim().toLowerCase();
        dispatch({
            type: TYPE,
            payload: { keyword },
        });
        const store = getState();
        const wasError = getSearchDrugsError(store);
        const prefix = keyword.slice(0, 6);
        if (keyword.length > 2 || wasError) {
            dispatch({
                type: TYPE,
                payload: { remoteKeyword: prefix },
            });
            const location = getTabletkaLocation(store);
            try {
                _fetch({
                    keyword,
                    location,
                    includeAnalogs: true,
                })(dispatch);
            } catch (error) {
                dispatch({
                    type: TYPE,
                    payload: { error: 'titles.errorSearchDrugs' },
                });
            }
        }
    };

export const setDrugInfo = info => async (dispatch, getState) => {
    const drugInfo = getState()?.drugs?.drugInfo ?? {};
    drugInfo[info?.id ?? info?.ls_num] = info;
    dispatch({
        type: TYPE,
        payload: { drugInfo },
    });
};

export const setDrugsInfo = info => async (dispatch, getState) => {
    const drugInfo = getState()?.drugs?.drugInfo ?? {};
    info?.forEach?.((item) => {
        if (!item?.tar_name && item?.tar) {
            // eslint-disable-next-line no-param-reassign
            item.tar_name = item.tar;
        }
        if (!item?.tar && item?.tar_name) {
            // eslint-disable-next-line no-param-reassign
            item.tar = item.tar_name;
        }
        drugInfo[item?.id ?? item?.ls_num] = item;
    });
    dispatch({
        type: TYPE,
        payload: { drugInfo },
    });
};

export const loadDrugInfo = drugs => async (dispatch, getState) => {
    const state = getState();
    const store = state?.drugs;
    const drugInfo = { ...store?.drugInfo };
    const needToLoad = drugs.filter((drug) => {
        const found = store.drugInfo[Number(drug?.id ?? drug?.ls_num)];
        return !found;
    });
    if (needToLoad.length) {
        drugInfo.isLoading = true;
        dispatch({
            type: TYPE,
            payload: { isLoading: true },
        });
        const loaded = await getDrugsInfoByNameAndId(needToLoad, getTabletkaLocation(store));
        if (loaded && loaded?.length) {
            loaded.forEach((drug) => {
                drugInfo[drug.ls_num] = drug;
            });
            dispatch({
                type: TYPE,
                payload: {
                    drugInfo,
                    isLoading: false,
                },
            });
            return;
        }
    }
    dispatch({
        type: TYPE,
        payload: {
            drugInfo,
            isLoading: false,
        },
    });
};

export const setSearchList = searchList => async (dispatch) => {
    dispatch({
        type: TYPE,
        payload: { searchList },
    });
};

export default createReducerFor(TYPE, {
    drugInfo: { isLoading: false },
    searchList: [],
});
