export const drugs = {
    123: {
        ls_num: '123',
        ls_name: 'цитрамон-боримед',
        price_min: '2.3',
        price_max: '3.3',
    },
    124: {
        ls_num: '124',
        ls_name: 'аспирин',
        price_min: '2.3',
        price_max: '3.3',
    },
    125: {
        ls_num: '125',
        ls_name: 'аспикард',
        price_min: '2.3',
        price_max: '3.3',
    },
};
export const drugstores = {
    123: {
        USR_NUM: 71,
        USR_NAME: 'name',
        PHONES: '1',
        USR_ADR: 'address',
        GEO_X: '23',
        GEO_Y: '54',
        LS_NUM: 123,
        LS_PRICE: 100.3,
        LS_CNT: 3,
    },
    124: {
        USR_NUM: 71,
        USR_NAME: 'name',
        PHONES: '1',
        USR_ADR: 'address',
        GEO_X: '23',
        GEO_Y: '54',
        LS_NUM: 124,
        LS_PRICE: 100.3,
        LS_CNT: 3,
    },
    125: {
        USR_NUM: 71,
        USR_NAME: 'name',
        PHONES: '1',
        USR_ADR: 'address',
        GEO_X: '23',
        GEO_Y: '54',
        LS_NUM: 125,
        LS_PRICE: 100.3,
        LS_CNT: 3,
    },
};