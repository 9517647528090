/* eslint-disable no-underscore-dangle */
import { useDispatch, useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';
import { KeyboardAvoidingView } from 'react-native';
import EStyleSheet from 'react-native-extended-stylesheet';
import { useEffect, useMemo, useState } from 'react';
import {
    Alert, Box, Button, dataFrom, Field, Icon, InfoList, List, Page, Platform, React, RText, ScrollView, Section, Text, TextArea, TextInput, TopNotification, TouchableOpacity, TouchableWithoutFeedback, View,
} from '../../common';
import actions, { back, navigate } from '../../actions';
import { renderDrugItem } from '../Tabletka/snippets';
import {
    getEditedVisit, getIsReportsEnable, getRelatedPrograms, getUserInfo, getViewedVisit, getVisitsError,
} from '../../selectors';
import { addVisitStats } from '../../services/stats';
import api from '../../services/api';
import { showImagePickerModal } from '../../common/Modal';
import { capitalize, isFutureVisit } from '../../utils';
import { COLORS } from '../../styles/colors';
import { fontSize } from '../../styles/atomic';
import { CREATED_MANUALLY } from '../../actions/visits';
import { setFormData } from '../../actions/forms';
import { USE_MEDICINE_SEARCH } from '../../config';
import { Attachments } from './Attachments';
import { VisitReports } from './VisitReports';
import { getDeleteManualVisitAction, getDeleteVisitAction } from './VisitListItem';

const drugsListMenu = ({ drugs = [], manualVisit }) => {
    const gotoSearch = () => navigate('SearchDrugstoresForVisit', {
        drugs,
        manualVisit,
    });
    return (
        <Box centered gap={1.5}>
            {drugs.length ? (
                <TouchableOpacity onPress={gotoSearch}>
                    <View style={{ flexDirection: 'row' }}>
                        <Icon.Search />
                        <Text
                            style={{
                                color: COLORS.MAIN,
                                fontSize: 13,
                                textDecorationLine: 'underline',
                            }}>
                            {capitalize(Object.R('titles.findInDrugStores').toLowerCase())}
                        </Text>
                    </View>
                </TouchableOpacity>
            ) : (
                <View />
            )}
            <Icon.Add
                size={19}
                style={{
                    width: 22,
                    height: 22,
                    paddingTop: 0,
                    marginBottom: 0,
                    marginRight: 10,
                }}
                onPress={() => navigate('SearchDrugsForVisit', {
                    drugs,
                    manualVisit,
                })
                }
            />
        </Box>
    );
};

const attachmentsListMenu = ({ attachments, manualVisit }) => {
    let isAddingPhoto = false;
    return (
        <Icon
            name="photo"
            size={25}
            color="#666666"
            style={{ marginRight: 10 }}
            onPress={() => {
                if (!isAddingPhoto) {
                    isAddingPhoto = true;
                    const showImgPicker = (item) => {
                        if (item.error) {
                            Alert.alert('Error', item.error);
                        } else {
                            const newAttachments = [
                                ...(attachments || []),
                                ...(item?.assets?.map(e => ({
                                    ...e,
                                    exists: true,
                                })) ?? []),
                            ];
                            if (manualVisit) {
                                actions.setFormValue('attachmentsForVisit', newAttachments);
                            } else {
                                actions.updateEditedVisit({ attachments: newAttachments });
                            }
                        }
                        isAddingPhoto = false;
                    };
                    const onCancel = () => {
                        isAddingPhoto = false;
                    };
                    showImagePickerModal(showImgPicker, onCancel);
                }
            }}
        />
    );
};

const saveVisit = async (visit) => {
    if (!visit.visitEdited) {
        return;
    }
    actions.startLoadingPastVisits();
    await api.updateOrder(visit);
    addVisitStats(visit);
    actions.stopLoadingPastVisits();
};

const VisitPageContainer = ({
    children, visitInfo, visitInfo: { errorCode } = {}, isFuture, readOnly,
}) => {
    const isFocused = useIsFocused();
    const doSave = React.useCallback(() => saveVisit(visitInfo), [visitInfo]);
    if (!isFocused) {
        doSave();
    }
    const error = useSelector(getVisitsError);
    return (
        <Page
            name="visit"
            hint={
                // eslint-disable-next-line no-nested-ternary
                errorCode
                    ? {
                        level: 'warn',
                        message: Object.R(`error.${errorCode}`),
                    }
                    : readOnly && isFuture
                        ? {
                            message: 'titles.addVisitManuallyWarning',
                            level: 'warn',
                        }
                        : null
            }
            onWillUnmount={Platform.OS === 'web' ? doSave : null}
            hideOnBackground
            notification={error}
            noscroll={Platform.OS !== 'web'}>
            {children}
        </Page>
    );
};

export const Visit = (edit = true) => Page.register(
    dataFrom(
        edit ? getEditedVisit : getViewedVisit,
        // eslint-disable-next-line complexity
        ({
            clickable = true,
            data: visitInfo,
            navigation,
            data: {
                // drugs = [],
                // attachments,
                name = '',
                comment = '',
                visitor: { programName } = {},
                covererCode,
                isBns,
                // service = '',
                physicianName,
                // physicianId,
                fullName,
                doctorInfo,
                medcenterName,
                reports,
                isReportsManageable,
                status,
                targetDate,
                startDate,
                canDelete,
                // endDate,
                readOnly = status === false,
                isFuture = (isFutureVisit(new Date(startDate)) && status === 'reserved') ||
                        (isFutureVisit(new Date(targetDate)) && status === CREATED_MANUALLY),
                // readOnly = status === 'created_manually',
            } = {},
        }) => {
            const [visitIsDeleted, setVisitIsDeleted] = useState(false);
            const reportsIsEnable = useSelector(getIsReportsEnable);
            // const doctors = useSelector(getPhysicians);
            // const doctor = cloneObject(doctors?.[physicianId]) ?? {};
            const user = useSelector(getUserInfo);
            const relatedPrograms = useSelector(getRelatedPrograms);
            const styles = EStyleSheet.create({
                headerSection: {
                    fontSize,
                    fontFamily: '$sourceSansPropFontFamily_semibold',
                    color: '#787878',
                    paddingTop: 20,
                    paddingBottom: 12,
                    marginBottom: 0,
                },
            });
            const [selection, setSelection] = useState({ start: 0 });
            const onFocus = () => {
                if (selection) {
                    setSelection({ start: name?.length });
                }
            };

            useEffect(() => {
                if (selection?.start === 0) {
                    return;
                }
                if (selection) {
                    setSelection(undefined);
                }
            }, [selection]);

            const onDeleteVisitHandler = () => {
                const onDeleteComplete = () => {
                    navigation.pop();
                };
                const onDeleteFailed = () => setVisitIsDeleted(false);
                const onDeleteStarted = () => setVisitIsDeleted(true);
                const visitForCancel = {
                    ...visitInfo,
                    onDeleteComplete,
                    onDeleteFailed,
                    onDeleteStarted,
                };
                const action =
                        status === CREATED_MANUALLY
                            ? getDeleteManualVisitAction(visitForCancel)
                            : getDeleteVisitAction(visitForCancel);
                action?.();
            };

            const hasProgram = useMemo(
                () => (Array.isArray(user?.programs) && user?.programs?.length > 0) ||
                        (typeof user?.programs === 'object' && Object.keys(user?.programs).length > 0) ||
                        Object.keys(relatedPrograms ?? {}).length > 1,
                [user.programs, relatedPrograms],
            );

            const dispatch = useDispatch();

            useEffect(() => {
                if (clickable) {
                    dispatch(
                        setFormData({
                            EditUserPD: {
                                back: () => {
                                    back();
                                },
                            },
                        }),
                    );
                } else {
                    dispatch(
                        setFormData({
                            Visit: {
                                back: () => {
                                    back();
                                },
                            },
                        }),
                    );
                }
            }, [clickable]);

            visitInfo.mdInfo.noLink = !clickable;

            return (
                <VisitPageContainer
                    visitInfo={visitInfo}
                    readOnly={readOnly}
                    isFuture={isFutureVisit(new Date(visitInfo?.startDate))}>
                    <KeyboardAvoidingView
                        behavior={Platform.OS === 'android' ? 'padding' : 'position'}
                        keyboardVerticalOffset={Platform.OS === 'android' ? 0 : 60}>
                        <ScrollView keyboardDismissMode="on-drag" keyboardShouldPersistTaps="never">
                            {visitInfo?.status === CREATED_MANUALLY && isFuture ? (
                                <TopNotification
                                    hint={{
                                        message: 'titles.addVisitManuallyWarning',
                                        level: 'warn',
                                    }}
                                />
                            ) : null}
                            <TouchableWithoutFeedback>
                                <View>
                                    <Section title="titles.details_of_visit_header" style={{ marginBottom: 0 }}>
                                        <View
                                            style={{
                                                flex: 1,
                                                paddingHorizontal: 8,
                                            }}>
                                            <InfoList
                                                fields="visitInfoFields"
                                                filteredFields={[
                                                    isBns && !physicianName ? 'doctorInfo' : null,
                                                    medcenterName ? null : 'mdInfo',
                                                    hasProgram ? null : 'paymentMethod',
                                                ]}
                                                data={{
                                                    ...visitInfo,
                                                    from: isFuture ? 'default' : 'Visit',
                                                    paymentMethod: {
                                                        programName: covererCode
                                                            ? programName || Object.R('title.by_program')
                                                            : Object.R('title.visit_own_expenses'),
                                                        coverer: covererCode,
                                                    },
                                                    service: visitInfo.serviceName,
                                                    doctorInfo: {
                                                        // ...deepCloneObject(doctorInfo),
                                                        fullName: isBns ? physicianName : fullName,
                                                        // id: doctor?.id, // doctorInfo.id || visitInfo.physicianId,
                                                        // _id: doctor?.id, // || visitInfo.physicianId,
                                                        id: clickable
                                                            ? doctorInfo.id || visitInfo.physicianId
                                                            : null,
                                                        _id: clickable
                                                            ? doctorInfo.id || visitInfo.physicianId
                                                            : null,
                                                        specialization:
                                                                visitInfo?.status === CREATED_MANUALLY
                                                                    ? visitInfo?.speciality || visitInfo.specialization
                                                                    : visitInfo?.speciality ||
                                                                      doctorInfo.specialization ||
                                                                      visitInfo.specialization,
                                                        // doctor,
                                                    },
                                                }}
                                                style={{
                                                    paddingLeft: 0,
                                                    paddingRight: 0,
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                }}
                                                titleStyle={{
                                                    marginLeft: 0,
                                                    marginRight: 0,
                                                }}
                                                onItemClick={(i, v, it) => {
                                                    if (it?.id === 'doctorInfo') {
                                                        actions.setFormValue('umsServices', []);
                                                    }
                                                }}
                                            />
                                        </View>
                                        {!isBns && (
                                            <Field
                                                title="titles.visit_name"
                                                titleStyle={styles.headerSection}
                                                style={{ marginBottom: 0 }}>
                                                <TextInput
                                                    onChange={(v) => {
                                                        actions.updateEditedVisit({
                                                            name: v,
                                                            titleChanged: true,
                                                        });
                                                    }}
                                                    onFocus={onFocus}
                                                    selection={selection}
                                                    value={name}
                                                    placeholder="titles.for_example_consultation"
                                                    disabled={readOnly || !clickable}
                                                />
                                            </Field>
                                        )}
                                        <Field
                                            title="titles.comment"
                                            titleStyle={styles.headerSection}
                                            style={{ marginBottom: 0 }}>
                                            <TextArea
                                                onChange={(v) => {
                                                    actions.updateEditedVisit({ comment: v });
                                                }}
                                                value={comment}
                                                placeholder="titles.additional_information_about_visit"
                                                style={{
                                                    flexGrow: 1,
                                                    minHeight: 70,
                                                    outline: 'none',
                                                    outlineWidth: 0,
                                                }}
                                                disabled={readOnly || !clickable}
                                            />
                                        </Field>
                                    </Section>

                                    {reportsIsEnable && isReportsManageable && reports && reports.length ? (
                                        <Section
                                            title="titles.visitReports"
                                            style={{
                                                marginTop: 12,
                                                marginBottom: 0,
                                            }}>
                                            <RText
                                                id="titles.visitReportsHint"
                                                numberOfLines={null}
                                                style={{ paddingHorizontal: 12 }}
                                            />
                                            <VisitReports data={reports} visitInfo={visitInfo} />
                                        </Section>
                                    ) : null}

                                    <AttachmentsForVisit
                                        attachments={visitInfo?.attachments}
                                        readOnly={readOnly}
                                        clickable={clickable}
                                    />

                                    {USE_MEDICINE_SEARCH !== '0' && (
                                        <DrugsForVisit
                                            drugs={visitInfo?.drugs}
                                            readOnly={readOnly || !clickable}
                                            clickable={clickable}
                                        />
                                    )}
                                    {!!isFuture && canDelete && (
                                        <Button
                                            disabled={visitIsDeleted}
                                            action={onDeleteVisitHandler}
                                            capitalize
                                            title={`buttons.cancel${
                                                    status === CREATED_MANUALLY ? '_manual_' : '_'
                                                }visit`}
                                            danger
                                            styles={[{ margin: 12 }]}
                                        />
                                    )}
                                </View>
                            </TouchableWithoutFeedback>
                        </ScrollView>
                    </KeyboardAvoidingView>
                </VisitPageContainer>
            );
        },
    ),
);

export const AttachmentsForVisit = ({
    attachments, manualVisit, style = {}, readOnly, clickable = true,
}) => {
    return Platform.OS === 'web' ? null : (
        <Section
            title="titles.attachments"
            right={
                readOnly || !clickable
                    ? null
                    : attachmentsListMenu({
                        attachments,
                        manualVisit,
                    })
            }
            style={[
                {
                    marginTop: 12,
                    marginBottom: 0,
                },
                style,
            ]}>
            <RText id="titles.attachmentsStoredLocallyHint" numberOfLines={null} style={{ paddingHorizontal: 12 }} />
            <Attachments data={attachments} manualVisit={manualVisit} readOnly={readOnly} clickable={clickable} />
        </Section>
    );
};

export const DrugsForVisit = ({
    drugs = [],
    manualVisit = false,
    onDelete,
    style = {},
    readOnly,
    clickable = true,
}) => {
    return (
        <>
            <Section
                title="drugs"
                count={drugs ? drugs.length : NaN}
                right={
                    readOnly
                        ? null
                        : drugsListMenu({
                            drugs,
                            manualVisit,
                        })
                }
                style={[{ marginTop: 12 }, style]}
                textStyles={{
                    paddingBottom: 0,
                    marginBottom: 0,
                }}
                containerStyles={{ height: 'auto' }}>
                <List
                    data={drugs}
                    renderItem={renderDrugItem(
                        drug => (readOnly ? null : (
                            <Icon.Delete
                                onPress={() => (manualVisit
                                    ? onDelete?.(drug)
                                    : actions.updateEditedVisit({ drugs: drugs.filter(e => e.id !== drug.id) }))
                                }
                            />
                        )),
                        item => (clickable
                            ? navigate('DrugDetailsForVisit', {
                                drug: item,
                                from: 'drugDetails',
                                manualVisit,
                            })
                            : null),
                        true,
                        false,
                    )}
                    emptyStyle={{
                        paddingTop: 0,
                        marginTop: 0,
                    }}
                    type="visit-drugs"
                />
            </Section>
        </>
    );
};
