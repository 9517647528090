// noinspection JSUnusedGlobalSymbols

import { MODE } from '../config';
import { createReducerFor } from './utils';

const TYPE = 'LOGS';
export const LOG_TO_RELEASE = false;

const initialState = { items: {} };

export const addLog = (section, data) => (dispatch, getState) => {
    if (MODE !== 'qa' && !LOG_TO_RELEASE) {
        return;
    }
    const currentData = getState()?.log?.items;
    const currentInSection = currentData?.[section] ?? [];
    dispatch({
        type: TYPE,
        payload: {
            items: {
                ...currentData,
                [section]: [...currentInSection, data],
            },
        },
    });
};

export const clearSection = section => dispatch => dispatch({
    type: TYPE,
    payload: { items: { [section]: [] } },
});

export const clearLog = () => (dispatch) => {
    const payload = { items: {} };
    dispatch({
        type: TYPE,
        payload,
    });
};

export default createReducerFor(TYPE, initialState);
