/* eslint-disable camelcase */
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import { Platform, TouchableWithoutFeedback } from 'react-native';
import {
    BigButton, Button, Icon, React, Text, View,
} from '../../../common';
import { styles } from '../../../common/InAppUpdater';
import { Card } from '../../../common/react';
import { isMobile } from '../../../utils/strings';
import { COLORS } from '../../../styles/colors';
import { setFormValue } from '../../../actions/forms';
import { getFormValues } from '../../../selectors/forms';
import { openURL } from '../About';
import { DISABLE_INSTALL_MOBILE_APP_MESSAGE } from '../../../config';

export const InstallMobilePanel = () => {
    const formValues = useSelector(getFormValues);
    const dispatch = useDispatch();
    const [fullView, setFullView] = useState(false);

    const closeWindowHandler = () => {
        dispatch(setFormValue('hideInstallMobileWindow', true));
        Object.trackAction('close_mobile_link_main', {
            utm_source: 'mwa',
            utm_medium: 'mma_link_from_main',
        });
    };

    const installHandler = () => {
        // nav.navigate('Account', { screen: 'Relatives' });
        const docId = formValues?.deeplink?.docId;
        const utm_campaign = 'setup_from_main_screen';
        const dId = docId ? `?docId=${docId}&` : '?';
        const domain = Object.R('titles.installMobileVersionLinkDomain') ?? 'https://link.aibolit.md';
        const url = `${domain}/l/?link=${domain}/d${dId}apn=md.aibolit.member.prod&isi=1441433446&ibi=md.aibolit.member.prod&efr=1&utm_source=mwa&utm_medium=setup_from_main_screen&utm_campaign=${utm_campaign}`;
        openURL({ routeTo: url, blank: false });
        Object.trackAction('click_mobile_link_main', {
            utm_source: 'mwa',
            utm_medium: 'setup_from_main_screen',
            utm_campaign,
        });
    };

    const changeFullView = () => setFullView(prev => !prev);

    const outMessage = useMemo(
        () => (
            <Text
                style={[
                    // styles.textNormal,
                    {
                        color: COLORS.STANDARD_GRAY,
                        lineHeight: 20,
                        fontSize: 14,
                    },
                ]}
                numberOfLines={fullView ? 0 : 2}>
                {Object.R('titles.installMobileVersionText')}
            </Text>
        ),
        [fullView],
    );

    return Platform.OS === 'web' &&
        isMobile() &&
        !formValues?.hideInstallMobileWindow &&
        !DISABLE_INSTALL_MOBILE_APP_MESSAGE ? (
            <Card containerStyle={[styles.wrapper, { position: 'relative' }]}>
                <View style={styles.lineWrapper}>
                    <TouchableWithoutFeedback onPress={changeFullView}>
                        {Platform.OS === 'web' ? outMessage : <View>{outMessage}</View>}
                    </TouchableWithoutFeedback>
                    <Button
                        action={closeWindowHandler}
                        styles={[
                            styles.closeButton,
                            {
                                position: 'absolute',
                                right: -14,
                                top: -14,
                            },
                        ]}>
                        <Icon name="cross" color="#787878" style={styles.closeIcon} />
                    </Button>
                </View>
                <BigButton
                    styles={styles.button}
                    titleStyles={styles.buttonTitle}
                    title={Object.R('titles.installMobileVersionButton')}
                    action={installHandler}
                />
                <View></View>
            </Card>
        ) : null;
};
