import { useDispatch } from 'react-redux';
import {
    AutocompleteSearchBar, dataFrom, Icon, List, Page, React, RText, Section, SectionList,
} from '../../common';
import {
    getEditedVisit,
    getIncludeAnalogs,
    getSearchDrugsKeyword,
    getSearchHistory,
    getSearchKeywordDrugs,
    getTabletkaLocation,
} from '../../selectors';
import actions, { navigate } from '../../actions';
import { renderDrugItem } from '../Tabletka/snippets';
import { getDrugsSuggestions, searchDrugs } from '../../services/tabletka';
// import style from '../../styles';
import { drugsAnaloguesFilter } from '../Tabletka';
import { DrugsStoresList } from '../Tabletka/DrugstoresList';
import { getFormValues } from '../../selectors/forms';
import { fetchDrugsByKeyword } from '../../actions/drugs';

const DrugItemRightIcon = dataFrom(getEditedVisit, ({
    data: { drugs = [] }, drug, manualVisit, manualDrugs = [],
}) => ((manualVisit ? manualDrugs : drugs)?.some(({ id }) => id === drug.id) ? (
    <RText primary id="notes.added" />
) : (
    <Icon.Add
        onPress={() => {
            if (manualVisit) {
                actions.setFormValue('drugsForVisit', [...manualDrugs, drug]);
            } else {
                actions.updateEditedVisit({ drugs: [...drugs, drug] });
            }
        }}
    />
)));

/**
 * Drugs for visit search page.
 */
const onHistoryItem =
    manualVisit => ({ name }) => navigate('DrugsListForVisit', {
        keyword: name,
        drugsList: null,
        manualVisit,
    });
const SearchDrugsHistory = dataFrom(getSearchHistory, ({ data = [], manualVisit, manualDrugs }) => (
    <SectionList
        white
        title="titles.history_search"
        type="drugs-history"
        data={data.map(e => ({
            ...e,
            right: <Icon.Exc onPress={() => actions.deleteSearchHistory(e)} />,
        }))}
        onItem={onHistoryItem(manualVisit, manualDrugs)}
    />
));

const SearchDrugsResults = dataFrom(getSearchKeywordDrugs, ({ data, manualVisit, manualDrugs }) => (
    <>
        <SectionList
            sectionStyle={{
                borderTopWidth: 0,
                paddingHorizontal: 12,
            }}
            listContainerStyle={{ borderTopWidth: 0 }}
            title="drugs"
            type="drugs"
            count={data && data.length}
            right={drugsAnaloguesFilter}
            isLoading={data.isLoading}
            data={data.data}
            error={data.error}
            renderItem={renderDrugItem(
                item => (
                    <DrugItemRightIcon drug={item} manualVisit={manualVisit} manualDrugs={manualDrugs} />
                ),
                (item) => {
                    actions.addDrugInHistory(Object.assign(item));
                    navigate('DrugDetailsForVisit', {
                        drug: item,
                        from: 'drugDetails',
                        manualVisit,
                    });
                },
            )}
        />
    </>
));

export const SearchDrugsForVisit = Page.register(
    ({ keyword = '', manualVisit, values }) => {
        const dispatch = useDispatch();
        return (
            <Page name="search-drugs" noscroll>
                <AutocompleteSearchBar
                    autoCorrect={false}
                    ref={(ref) => {
                        if (ref && keyword === '') {
                            ref.state.onClear();
                        }
                    }}
                    searchAfter={2}
                    suggestionsRequest={enteredKeyword => getDrugsSuggestions(enteredKeyword)}
                    onSuggestionSelected={(suggestion) => {
                        dispatch(fetchDrugsByKeyword(suggestion));
                        // actions.fetchDrugsByKeyword(suggestion);
                    }}
                    value={keyword}
                    placeholder="placeholders.search_drugs"
                    routeToNav="DrugsListForVisit"
                    manualVisit={manualVisit}
                />
                {keyword.length < 3 ? (
                    <SearchDrugsHistory manualVisit={manualVisit} manualDrugs={values?.drugsForVisit} />
                ) : (
                    <SearchDrugsResults manualVisit={manualVisit} manualDrugs={values?.drugsForVisit} />
                )}
            </Page>
        );
    },
    {
        keyword: { from: getSearchDrugsKeyword },
        values: { from: getFormValues },
    },
);
/**
 * DrugsList page.
 */
export const DrugsListForVisit = Page.register(
    ({
        route: { params: { drugsList, keyword, manualVisit } },
        location,
        includeAnalogs,
        values,
    }) => (
        <Page name="drugs-list">
            <Section title="drugs" countOf={drugsList} right={drugsAnaloguesFilter}>
                <List
                    data={
                        drugsList ||
                        (() => searchDrugs({
                            keyword,
                            location,
                            includeAnalogs,
                        }))
                    }
                    renderItem={renderDrugItem(
                        item => (
                            <DrugItemRightIcon
                                drug={item}
                                manualVisit={manualVisit}
                                manualDrugs={values?.drugsForVisit ?? []}
                            />
                        ),
                        item => navigate('DrugDetailsForVisit', {
                            drug: item,
                            from: 'drugDetails',
                            manualDrugs: values?.drugsForVisit ?? [],
                            manualVisit,
                        }),
                    )}
                />
            </Section>
        </Page>
    ),
    {
        location: { from: getTabletkaLocation },
        includeAnalogs: { from: getIncludeAnalogs },
        values: { from: getFormValues },
    },
);

/**
 * DrugDetails page.
 */
export const DrugDetailsForVisit = ({
    navigation,
    route: {
        params: {
            drug, sortBy, manualVisit, from,
        },
    },
}) => {
    return (
        <Page name="drug">
            {/* <Section.White> */}
            {/*     <View style={[style.padding, style.flexRow]}> */}
            {/*         <VBox flex style={style.mr}> */}
            {/*             <Title bold>{drug.name}</Title> */}
            {/*             <DrugDescription drug={drug} /> */}
            {/*         </VBox> */}
            {/*         <VBox justify="space-between" style={style.alignItemsEnd}> */}
            {/*             <DrugItemRightIcon drug={drug} /> */}
            {/*             <Subtitle primary>{drug.pricingText}</Subtitle> */}
            {/*         </VBox> */}
            {/*     </View> */}
            {/* </Section.White> */}
            <DrugsStoresList
                drugs={[drug]}
                navigation={navigation}
                sortBy={sortBy}
                detailsName="DrugstoreDetailsForVisit"
                removeFromFirstAidKit={false}
                addToFirstAidKit={false}
                manualVisit={manualVisit}
                from={from}
                fromVisit
            />
        </Page>
    );
};

export const SearchDrugstoresForVisit = ({
    navigation,
    route: { params: { drugs, sortBy, from = 'searchFirstAidKit' } },
}) => (
    <Page name="drugstore_search">
        {/* <DrugsSummary drugs={drugs} price={Math.round(drugs.reduce((acc, { priceMin: min }) => acc + Number(min), 0) * 100) / 100} /> */}
        {/* <FirstAidKitBanner data={drugs} /> */}
        <DrugsStoresList
            drugs={drugs}
            navigation={navigation}
            sortBy={sortBy}
            detailsName="DrugstoreDetailsForVisit"
            from={from}
            firstAidKit={true}
            bannerTitle={'bannerTitle'}
            // removeFromFirstAidKit={false}
            // addToFirstAidKit={false}
        />
    </Page>
);
