import { personId3 } from '../const.stub';

export const profile = {
    _id: personId3,
    fullNameRu: 'Орлов Игорь Валерьевич',
    phones: [{ number: '375291234567'}],
    emails: [{ address: 'Auto_EPM-AIMD_Test3@epam.com', usage: 'PRIMARY', confirmed: true }],
    birthday: '1992-05-08',
    sex: 'MALE',
    keys: [{ tag: 'FULL_NAME_RU', value: 'Орлов Игорь Валерьевич' }],
    external: {
        'horizont-minsk': {
            id: '81901',
        },
        'lode-minsk': {
            id: '122801',
            validFrom: '2018-09-06T19:39:53.217Z',
        },
    },
    hashKey: personId3,
    storeKey: personId3,
    _updated: { time: '2000-01-01T00:00:00.000Z' }
};

export const coverage = {
    people: [{
        profile: {
            _id: personId3,
            primaryPhone: { number: '375291234567' },
            phones: [{ number: '375291234567' }],
            birthday: '1992-05-08',
            sex: 'MALE',
            emails: [{ address: 'Auto_EPM-AIMD_Test3@epam.com', usage: 'PRIMARY', confirmed: true }],
            hashKey: personId3,
            storeKey: personId3,
            _updated: { time: '2000-01-01T00:00:00.000Z' }
        },
        profileId: personId3,
        relKind: 'OWNER'
    }],
    programs: {},
};
