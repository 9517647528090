/**
 * Application Configuration.
 * Auto-generated file. Do not edit.
 * @vendor https://epam.com
 */
export const ID = 'aimd';
export const MODE = 'uat';
export const VERSION = '2.2.738-uat.dev-mexico.a6b0074f7';
export const DEFAULT_LOCALE = 'en-US';
export const GOOGLE_WEB_CLIENT_ID = '137792746040-uqajlhfupn54c36o9qif29perc268ah7.apps.googleusercontent.com';
export const API_HOST = 'pub-uat.aibolit.md';
export const MY_API_HOST = 'my-uat.aibolit.md';
export const UMS_API_HOST = 'ums-uat.aibolit.md';
export const FIRESTORE = 'aibolitmd-member-bns';
export const SUPPORT_EMAIL = 'support@aibolit.md';
export const USE_MEDICINE_SEARCH = '0';
export const HIDE_CITY_SELECTOR = '0';
export const HIDE_TEST_TAB = '1';
export const DISABLE_ADD_MANUAL_VISIT = '1';
export const DEFAULT_LOCATION = 33;
export const DISABLE_SERVICES = '1';
export const DEFAULT_PHONE_VALUE = '+';
export const COUNTRY_CODE = '+52';
export const COUNTRY_OPERATORS = ['1', '3'];
export const COUNTRY_TEST_OPERATORS = ['5'];
export const COUNTRY_PHONE_LENGTH = 12;
export const SIGNIN_PLACEHOLDER = '_ ____ ___ __';
export const SIGNIN_MASK = '9 9999 999 99';
export const USE_SMS_ASSISTANT = false;
export const DEFAULT_LANGUAGE = 'en';
export const ALLOWED_LANGUAGES = [{ id: 'en' }, { id: 'ru' }, { id: 'be' }];
export const ALLOWED_CITIES = [33];
export const DISABLE_PROGRAM_CHOOSE = true;
export const DISABLE_INSTALL_MOBILE_APP_MESSAGE = true;
export const USE_SPACE_IN_NAME = true;
export const TIMEZONE = 'America/Mexico_City';
export const QTY_MONTHS_FOR_TIMETABLE = 1;
