import {
    Box, React, Section, SubtitleDark, Title, TouchableOpacity, VBox, View,
} from '../../common';
import { InitialsAvatar } from '../../combo';
import { formatDateLong, toDate } from '../../utils';
import { invoiceItem as s } from './style';

const ActionResolver = ({
    children,
    onPress,
}) => (onPress ? (<TouchableOpacity onPress={onPress}>{children}</TouchableOpacity>) : (<View>{children}</View>));

export const InvoiceCard = (props) => {
    const {
        item: {
            umsPath,
            date,
            totalCost,
            records,
            clinicInfo: { name: clinicsName },
            status,
            visitor,
        },
        forReviwedList,
        forNewList,
        forMultiService,
        onPress = null,
        withComment,
    } = props;
    const styleLeftBorder = invoiceStatus => [
        invoiceStatus === 'APPROVED' ? s.greenLeftBorder : null,
        invoiceStatus === 'REJECTED' ? s.redLeftBoredr : null,
        invoiceStatus === 'PARTIALLY_APPROVED' ? s.yellowLeftBoredr : null,
    ];
    const styles = [
        forReviwedList ? [styleLeftBorder(status), s.forReviwedList] : null,
        forNewList ? s.forNewList : null,
        forMultiService ? s.forMultiService : null,
    ];
    const rejectionComment = withComment ? records.find(item => item.status === 'REJECTED' || item.status === 'PARTIALLY_APPROVED').rejectionComment : null;
    return (
        <ActionResolver onPress={onPress}>
            <View>
                <Section style={[s.invoiceCard, styles]}>
                    <VBox>
                        {umsPath.length === 0 ? null
                            : <Title bold style={{ marginBottom: 16 }}>{umsPath.split('/')[0].trim()}</Title>
                        }
                        <View style={{ marginBottom: 16 }}>
                            <Box centered>
                                <SubtitleDark id="titles.medical_center" bold/>
                                <SubtitleDark id={clinicsName}/>
                            </Box>
                            <Box centered>
                                <SubtitleDark id="titles.price" bold/>
                                <SubtitleDark>{`${totalCost.toFixed(2)} руб.`}</SubtitleDark>
                            </Box>
                        </View>
                        <Box centered justify="space-between">
                            <Box centered gap={0.67}>
                                <InitialsAvatar
                                    {...visitor}
                                    size={20}
                                />
                                <SubtitleDark id={visitor.isMe ? 'titles.my_visit' : visitor.firstLastName}/>
                            </Box>
                            <SubtitleDark id={formatDateLong(toDate(date))}/>
                        </Box>
                    </VBox>
                </Section>
                {
                    !withComment ? null
                        : <Section style={[s.invoiceCard, s.forMultiService]}>
                            <VBox>
                                <Title id="titles.comment_to_rejected"/>
                                <SubtitleDark id={rejectionComment}/>
                            </VBox>
                        </Section>
                }
            </View>
        </ActionResolver>
    );
};
