import { personId5 } from '../const.stub';

export const profile = {
    _id: personId5,
    primaryPhone: { number: '375291234567' },
    phones: [{ number: '375291234567' }],
    hasCompanyLogin: false,
    hashKey: personId5,
    storeKey: personId5,
    _updated: { time: '2000-01-01T00:00:00.000Z' }
};

export const coverage = {
    people: [{
        profile: {
            _id: personId5,
            primaryPhone: { number: '375291234567' },
            phones: [{ number: '375291234567' }],
            hashKey: personId5,
            storeKey: personId5,
            _updated: { time: '2000-01-01T00:00:00.000Z' }
        },
        profileId: personId5,
        relKind: 'OWNER'
    }],
    programs: {},
};