/* eslint-disable no-undef */
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import {
    Button, dataFrom, FlatList, Icon, Img, List, LoadingIndicator, Modal, Modal2, Page, Platform, Prompt, React, Section, shareData, showNotification, Stub, Subtitle, SupportModal, View,
} from '../../common';
import { getUserInfo, getUserLoadingState, hasUnfilledRelatives } from '../../selectors';
import { renderRelativePanel } from '../Account/snippets';
import { visitModalContent as styles } from '../Visits/style';
import { getBottom, getMarginHorizontalObject, modal } from '../../styles/common';
import actions, { navigate } from '../../actions';
import { InitialsAvatar } from '../../combo';
import { RelativeHelpText } from '../../common/Stub';
import { relativeHintStyles, reltivesStyles } from './styles';

const InvitePermission = () => Page.showModal(
    <Prompt
        title="titles.pay_attention"
        subtitle="titles.permission_to_relatives"
        onSubmit={() => showNotification('titles.without_permission')}
        onCancel={() => navigate('FamilyInviteConfirm')}
        submitTitle="button.not_agree"
        cancelTitle="button.agree"
        submitOptions={{ danger: false }}
    />,
);

const menuItems = (hasFamily, numChildren, numSpouse, numParent, navigation) => [
    {
        id: 'create_relative_card',
        handler: () => (numChildren < 5 ? navigation.navigate('EditRelative') : SupportModal('titles.notAllowedAnotherChild')),
    },
    {
        id: 'add_relative',
        handler: () => (numSpouse > 0 && numParent > 3
            ? SupportModal('titles.notAllowedAnotherRelative')
            : navigation.navigate('AddAdultRelative', {
                spouseError: numSpouse > 0,
                parentError: numParent > 3,
            })),
    },
    Platform.OS === 'web'
        ? null
        : {
            id: 'share_app',
            handler: () => Page.closeModal(() => setTimeout(() => shareData('App Link'), 200)),
        },
    {
        id: 'my_linking_code',
        handler: () => (!hasFamily ? InvitePermission() : SupportModal('titles.notAllowedAnotherFamily')),
    },
    {
        id: 'enter_spouse_code',
        handler: () => (!hasFamily ? navigation.navigate('FamilyJoin') : SupportModal('titles.notAllowedAnotherFamily')),
    },
];

const renderMenuItem = ({ item: { id, handler } }) => (
    <Button title={`buttons.${id}`} styles={styles.modalButton} textStyles={styles.selectModalTitle} action={handler} />
);

const addButtonModal = (hasFamily, numChildren, numSpouse, numParent, navigation) => Page.showModal(
    <Modal
        containerStyles={[
            modal.container_no_padding,
            window.isDesktop
                ? {
                    ...getMarginHorizontalObject(),
                    bottom: getBottom(),
                }
                : {
                    ...getMarginHorizontalObject(),
                    bottom: getBottom(),
                },
        ]}
        title="titles.addRelative"
        textInfo={Object.R('titles.addRelativesHint')}>
        <FlatList
            data={menuItems(hasFamily, numChildren, numSpouse, numParent, navigation).filter(e => e !== null)}
            renderItem={renderMenuItem}
        />
    </Modal>,
);

const DefaultAvatar = ({ grey }) => (
    <View style={relativeHintStyles.defaultAvatar}>
        <Img.UserAvatar style={relativeHintStyles.defaultAvatarImg} />
        <Icon.EditCircle grey={grey} />
    </View>
);

const DefaultAvatarUnfilledPD = ({ grey }) => (
    <View style={relativeHintStyles.defaultAvatar}>
        <InitialsAvatar style={relativeHintStyles.defaultAvatarImg} index={1} />
        <Icon.EditCircle grey={grey} />
    </View>
);

const HintItem = ({ titleId, icon: HintIcon, iconProps = {} }) => (
    <View style={relativeHintStyles.hintItem}>
        <HintIcon {...iconProps} />
        <Subtitle id={titleId} numberOfLines={null} style={relativeHintStyles.hintText} />
    </View>
);

const HintsSection = () => (
    <View>
        <HintItem titleId="titles.RelativeBadgeHintAllow" icon={DefaultAvatar} />
        <HintItem titleId="titles.RelativeBadgeHintForbid" icon={DefaultAvatar} iconProps={{ grey: true }} />
        <HintItem titleId="titles.RelativeBadgePD" icon={DefaultAvatarUnfilledPD} />
    </View>
);

const Footer = dataFrom(
    getUserInfo,
    ({
        data: {
            me: {
                hasFamily, numChildren, numSpouse, numParent,
            } = {}, isPerson,
        },
    }) => {
        const navigation = useNavigation();
        const onInfoPress = () => Page.showModal(
            <Modal2 title="titles.designations">
                <HintsSection />
            </Modal2>,
        );
        const onUpddatePress = () => actions.reloadUserInfo(Object.handleNewUserItemsFound, actions.updatePrefs, actions.updateSelects);
        const onAddPress = () => addButtonModal(hasFamily, numChildren, numSpouse, numParent, navigation);
        return (
            <View style={reltivesStyles.footer}>
                <Icon.Info onPress={onInfoPress} iconStyle={reltivesStyles.footerButtonStyle} />
                <Icon.UpdateRelatives onPress={onUpddatePress} iconStyle={reltivesStyles.footerButtonStyle} />
                {isPerson ? <Icon.AddRelative onPress={onAddPress} /> : null}
            </View>
        );
    },
);

const RelativesSection = ({ relatives, familyCoveredByProgram }) => {
    const unfilledRelatives = useSelector(hasUnfilledRelatives);
    return relatives.length ? (
        <Section title="titles.myFamily" style={reltivesStyles.relativesSection}>
            <List
                type="relatives"
                data={relatives}
                renderItem={renderRelativePanel(familyCoveredByProgram)}
                ListFooterComponent={unfilledRelatives ? <RelativeHelpText /> : null}
            />
        </Section>
    ) : (
        <Stub.Family />
    );
};

export const Relatives = Page.register(
    ({ userInfo: { familyCoveredByProgram }, userInfo: { relatives = [] }, isLoading }) => (
        <Page name="relatives" footer={<Footer />}>
            {isLoading ? (
                <LoadingIndicator style={reltivesStyles.relatives} />
            ) : (
                <RelativesSection relatives={relatives} familyCoveredByProgram={familyCoveredByProgram} />
            )}
        </Page>
    ),
    {
        userInfo: { from: getUserInfo },
        isLoading: { from: getUserLoadingState },
    },
);
