import { getFinalMessage } from '../../services/http';
import { formatTime, parseToDdMonYyyy } from '../../utils/dates';
import { extractPhoneNumber } from '../../utils/strings';

export const adaptErrorMessage = (err) => {
    const phone = extractPhoneNumber(err?.body?.detail ?? '');
    switch (err.code) {
        case 'WAIT_TILL': {
            // noinspection JSUnresolvedVariable
            const tryAfter = err?.body?.attributes?.waitTillIso;
            return {
                ...err,
                message: Object.R('error.relativeWaitTilError', { time: `${parseToDdMonYyyy(new Date(tryAfter))}, ${formatTime(tryAfter)}` }),
            };
        }
        case 'TRY_COUNT':
            return {
                ...err,
                message: Object.R('error.relativeTryCountError'),
            };
        case 'VALIDATION_ERROR':
            return {
                ...err,
                message: Object.R('error.relativeCodeValidationError'),
            };
        case 'EXPIRED_OPERATION':
            return {
                ...err,
                message: Object.R('error.auth/code-expired'),
            };
        case 'INVALID_OBJECT_ID':
            return {
                ...err,
                message: Object.R('error.auth/invalid-verification-code'),
            };
        case 'INVALID_DATA':{
            let msg = Object.R('error.relativeInvalidData', { phone });
            msg = msg.split('{phone}').join('');
            return {
                ...err,
                message: msg,
            };}
        default:
        {
            const errorKey = `error.code/${err.code}`;
            const errorMessage = Object.R(errorKey, { phone });
            if (errorKey !== errorMessage) {
                return {
                    ...err,
                    message: errorMessage,
                };
            }
            return { ...err, message: getFinalMessage(err) };
        }
    }
};
