export default {
    '5b3f5fa83884cda40': {
        _id: '5b3f5fa83884cda40',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '1767',
        fullName: 'Кедич Валентина Петровна',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%9A%D0%B5%D0%B4%D0%B8%D1%87%20%D0%92%D0%B0%D0%BB%D0%B5%D0%BD%D1%82%D0%B8%D0%BD%D0%B0%20%D0%9F%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D0%BD%D0%B0%205b3f5fa83884cda40-thumb?generation=1539243071598418&alt=media',
        qualification: null,
        specialization: 'врач-травматолог-ортопед',
        worksAt: [{
            assignmentId: '5b3f5fa8749b4869f',
            clinicId: '5b3f5fa7eaf998e58',
            id: '5b3f5fa7eaf998e58',
            name: 'Лодэ на Гикало',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5fd716a7c57fe': {
        _id: '5b3f5fd716a7c57fe',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '4',
        fullName: 'Лютикова Ольга Вячеславовна',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%9B%D1%8E%D1%82%D0%B8%D0%BA%D0%BE%D0%B2%D0%B0%20%D0%9E%D0%BB%D1%8C%D0%B3%D0%B0%20%D0%92%D1%8F%D1%87%D0%B5%D1%81%D0%BB%D0%B0%D0%B2%D0%BE%D0%B2%D0%BD%D0%B0%205b3f5fd716a7c57fe-thumb?generation=1538051666822519&alt=media',
        qualification: null,
        specialization: 'врач-гастроэнтеролог',
        worksAt: [{
            assignmentId: '5b3f5fd74ca3f0f13',
            clinicId: '5b3f5fa4529813245',
            id: '5b3f5fa4529813245',
            name: 'Лодэ на Независимости',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5fde9849da7bf': {
        _id: '5b3f5fde9849da7bf',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '2052',
        fullName: 'Руммо Дмитрий Владимирович',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%A0%D1%83%D0%BC%D0%BC%D0%BE%20%D0%94%D0%BC%D0%B8%D1%82%D1%80%D0%B8%D0%B9%20%D0%92%D0%BB%D0%B0%D0%B4%D0%B8%D0%BC%D0%B8%D1%80%D0%BE%D0%B2%D0%B8%D1%87%205b3f5fde9849da7bf-thumb?generation=1538051687727415&alt=media',
        qualification: null,
        specialization: 'врач-травматолог-ортопед',
        worksAt: [{
            assignmentId: '5b3f5fdedaf289009',
            clinicId: '5b3f5fa4529813245',
            id: '5b3f5fa4529813245',
            name: 'Лодэ на Независимости',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5fe0426103ddd': {
        _id: '5b3f5fe0426103ddd',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '2115',
        fullName: 'Степанькова Инна Леонидовна',
        overview: null,
        photoUrl: null,
        qualification: null,
        specialization: 'врач-стоматолог-терапевт',
        worksAt: [{
            assignmentId: '5b3f5fe07976adba9',
            clinicId: '5b3f5faa72b310212',
            id: '5b3f5faa72b310212',
            name: 'Лодэ на Притыцкого',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5fe0e8f53bf04': {
        _id: '5b3f5fe0e8f53bf04',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '69',
        fullName: 'Загорский Сергей Эверович',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%97%D0%B0%D0%B3%D0%BE%D1%80%D1%81%D0%BA%D0%B8%D0%B9%20%D0%A1%D0%B5%D1%80%D0%B3%D0%B5%D0%B9%20%D0%AD%D0%B2%D0%B5%D1%80%D0%BE%D0%B2%D0%B8%D1%87%205b3f5fe0e8f53bf04-thumb?generation=1538051677793881&alt=media',
        qualification: null,
        specialization: 'врач-гастроэнтеролог',
        worksAt: [{
            assignmentId: '5b3f5fe138ad12521',
            clinicId: '5b3f5fa7eaf998e58',
            id: '5b3f5fa7eaf998e58',
            name: 'Лодэ на Гикало',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5feb8a89854d5': {
        _id: '5b3f5feb8a89854d5',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '1315',
        fullName: 'Васильева Жанна Ивановна',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%9A%D0%BE%D0%B7%D0%B5%D0%B9%D0%BA%D0%BE%20%D0%96%D0%B0%D0%BD%D0%BD%D0%B0%20%D0%92%D0%B0%D1%81%D0%B8%D0%BB%D1%8C%D0%B5%D0%B2%D0%BD%D0%B0%205b3f5feb8a89854d5-thumb?generation=1538051691954205&alt=media',
        qualification: null,
        specialization: 'врач-терапевт',
        worksAt: [{
            assignmentId: '5b3f5fed07e4b147b',
            clinicId: '5b3f5fa4529813245',
            id: '5b3f5fa4529813245',
            name: 'Лодэ на Независимости',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5ff389bb91501': {
        _id: '5b3f5ff389bb91501',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '2051',
        fullName: 'Мойсеюк Михаил Валентинович',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%9C%D0%BE%D0%B9%D1%81%D0%B5%D1%8E%D0%BA%20%D0%9C%D0%B8%D1%85%D0%B0%D0%B8%D0%BB%20%D0%92%D0%B0%D0%BB%D0%B5%D0%BD%D1%82%D0%B8%D0%BD%D0%BE%D0%B2%D0%B8%D1%87%205b3f5ff389bb91501-thumb?generation=1538051662607021&alt=media',
        qualification: null,
        specialization: 'врач-физиотерапевт',
        worksAt: [{
            assignmentId: '5b3f5ff3c08f0a667',
            clinicId: '5b3f5fa7eaf998e58',
            id: '5b3f5fa7eaf998e58',
            name: 'Лодэ на Гикало',
            onlineBookingAllowed: false,
        }, {
            assignmentId: '5b3f5ff4674ed636b',
            clinicId: '5b3f5fa4529813245',
            id: '5b3f5fa4529813245',
            name: 'Лодэ на Независимости',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5ff6f2fdd529a': {
        _id: '5b3f5ff6f2fdd529a',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '1265',
        fullName: 'Мозжухина Татьяна Геннадьевна',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%9C%D0%BE%D0%B7%D0%B6%D1%83%D1%85%D0%B8%D0%BD%D0%B0%20%D0%A2%D0%B0%D1%82%D1%8C%D1%8F%D0%BD%D0%B0%20%D0%93%D0%B5%D0%BD%D0%BD%D0%B0%D0%B4%D1%8C%D0%B5%D0%B2%D0%BD%D0%B0%205b3f5ff6f2fdd529a-thumb?generation=1538051659530662&alt=media',
        qualification: null,
        specialization: 'врач ультразвуковой диагностики',
        worksAt: [{
            assignmentId: '5b3f5ff735e9b2310',
            clinicId: '5b3f5fa7eaf998e58',
            id: '5b3f5fa7eaf998e58',
            name: 'Лодэ на Гикало',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },
    '5b3f5ffc42272ce6e': {
        _id: '5b3f5ffc42272ce6e',
        _lastModifiedAt: 1539241827000,
        _lastModifiedBy: null,
        academicTitle: null,
        category: null,
        degree: null,
        deleted: false,
        externalId: '440',
        fullName: 'Гресь Аркадий Александрович',
        overview: null,
        photoUrl: 'https://www.googleapis.com/download/storage/v1/b/aibolitmd-docpages-poc.appspot.com/o/doctor-images%2F%2F%D0%93%D1%80%D0%B5%D1%81%D1%8C%20%D0%90%D1%80%D0%BA%D0%B0%D0%B4%D0%B8%D0%B9%20%D0%90%D0%BB%D0%B5%D0%BA%D1%81%D0%B0%D0%BD%D0%B4%D1%80%D0%BE%D0%B2%D0%B8%D1%87%205b3f5ffc42272ce6e-thumb?generation=1539243077037323&alt=media',
        qualification: null,
        specialization: 'врач-уролог',
        worksAt: [{
            assignmentId: '5b3f5ffd2266a9b3f',
            clinicId: '5b3f5fa4529813245',
            id: '5b3f5fa4529813245',
            name: 'Лодэ на Независимости',
            onlineBookingAllowed: false,
        }],
        _coll: 'physicians',
    },

};
