import { Platform } from 'react-native';
import defaultHospitalPhoto from './images/hospital-without-photo.png';
import defaultDoctorPhoto from './images/doctor-without-photo.png';
import EpamLogo from './images/epam_logo.png';
import EpamOnboarding from './images/epam_onboarding.png';
import BnsLogo from './images/bns_logo.png';
import BnsOnboarding from './images/bns_onboarding.png';
import BnsLimitedOnboarding from './images/bnslimited_onboarding.png';
import FirstAidKitIcon from './images/first-aid-kit.png';
import EmptyFak from './images/empty-first-aid-kid.png';
import ErrorImg from './images/pills-not-found.png';
import NotAuthorized from './images/comments-denied.png';
import Logo from './images/logo-full.png';
import ChildBadge from './images/baby.png';
import UserAvatar from './images/user_avatar.png';
import Device from './images/Illustration-Device.png';
import BigEpamLogo from './images/epam_logo_big.png';
import BigBnsLogo from './images/bns_logo_big.png';
import BnsLimLogo from './images/bns-limited.png';
import EpamLimLogo from './images/epam-limited.png';
import EpamMarker from './images/epam-full-cover.png';
import EpamMarkerLimited from './images/epam-partly-cover.png';
import BnsMarker from './images/bns-full-cover.png';
import BnsMarkerLimited from './images/bns-partly-cover.png';
import IllustrationEnterSignIn from './images/Illustration-enter.png';
import IllustrationFillOutProfile from './images/Illustration-profile.png';
import IllustrationAddKids from './images/Illustration-myfamily.png';
import infoIcon from './images/info-icon.png';
import WNRelatives from './images/wn_relatives.png';
import Dot1 from './images/Dots/_01.png';
import Dot2 from './images/Dots/_02.png';
import Dot3 from './images/Dots/_03.png';
import Dot4 from './images/Dots/_04.png';
import Dot5 from './images/Dots/_05.png';
import Dot6 from './images/Dots/_06.png';
import Dot7 from './images/Dots/_07.png';
import Dot8 from './images/Dots/_08.png';
import Dot1Ios from './images/Dots_ios/_01.png';
import Dot2Ios from './images/Dots_ios/_02.png';
import Dot3Ios from './images/Dots_ios/_03.png';
import Dot4Ios from './images/Dots_ios/_04.png';
import Dot5Ios from './images/Dots_ios/_05.png';
import Dot6Ios from './images/Dots_ios/_06.png';
import Dot7Ios from './images/Dots_ios/_07.png';
import Dot8Ios from './images/Dots_ios/_08.png';
import MapCover from './images/map_img.png';
import MapsUpdatedPopUp from './images/maps_updated_image.png';

export const RANDOM_COLORS = [
    {
        backgroundColor: '#F4A257',
        borderColor: '#FEE1C1',
    },
    {
        backgroundColor: '#EF7272',
        borderColor: '#F8C4C4',
    },
    {
        backgroundColor: '#7F67D8',
        borderColor: '#CDC5F9',
    },
    {
        backgroundColor: '#5B84DC',
        borderColor: '#B0C9FF',
    },
    {
        backgroundColor: '#41ABD3',
        borderColor: '#AAE3F9',
    },
    {
        backgroundColor: '#18B797',
        borderColor: '#9AE4D5',
    },
    {
        backgroundColor: '#52BA4E',
        borderColor: '#ABEAA9',
    },
];

export const IMAGES = {
    EpamLogo,
    BnsLogo,
    EpamMarker,
    EpamMarkerLimited,
    BnsMarker,
    BnsMarkerLimited,
    infoIcon,
    FirstAidKitIcon,
    EmptyFak,
    ErrorImg,
    NotAuthorized,
    Logo,
    ChildBadge,
    UserAvatar,
    Device,
    MapCover,
    MapsUpdatedPopUp,
    IllustrationEnterSignIn,
    IllustrationFillOutProfile,
    IllustrationAddKids,
    WNRelatives,
    Dot1: Platform.select({
        ios: Dot1Ios,
        default: Dot1,
    }),
    Dot2: Platform.select({
        ios: Dot2Ios,
        default: Dot2,
    }),
    Dot3: Platform.select({
        ios: Dot3Ios,
        default: Dot3,
    }),
    Dot4: Platform.select({
        ios: Dot4Ios,
        default: Dot4,
    }),
    Dot5: Platform.select({
        ios: Dot5Ios,
        default: Dot5,
    }),
    Dot6: Platform.select({
        ios: Dot6Ios,
        default: Dot6,
    }),
    Dot7: Platform.select({
        ios: Dot7Ios,
        default: Dot7,
    }),
    Dot8: Platform.select({
        ios: Dot8Ios,
        default: Dot8,
    }),
};

export const AVATAR_TYPES_IMAGES = {
    users: Platform.OS === 'ios' ? defaultDoctorPhoto : UserAvatar,
    physicians: defaultDoctorPhoto,
    clinics: defaultHospitalPhoto,
};

export const COVERERS = {
    epam: {
        logo: EpamLogo,
        big: BigEpamLogo,
        color: '#39C2D7',
        onboardingSrc: EpamOnboarding,
        limLogo: EpamLimLogo,
    },
    bns: {
        logo: BnsLogo,
        big: BigBnsLogo,
        color: '#0F4895',
        onboardingSrc: BnsOnboarding,
        limLogo: BnsLimLogo,
    },
    'bns-limited': {
        logo: BnsLogo,
        big: BigBnsLogo,
        color: '#0F4895',
        onboardingSrc: BnsLimitedOnboarding,
        limLogo: BnsLimLogo,
    },
    unknown: {
        logo: FirstAidKitIcon,
        big: FirstAidKitIcon,
        color: '#BABECC80',
    },
};

