/* eslint-disable no-underscore-dangle,no-undef */
import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
    Button,
    ButtonsGroup,
    Checkbox,
    Component,
    dataFrom,
    List,
    Modal2,
    Page,
    Platform,
    React,
    SearchBar,
    Section,
    SingleNavigation,
    Switch,
    Text,
    TouchableWithoutFeedback,
    View,
} from '../../common';
import { getMarginHorizontalObject, selector as styles } from '../../styles/common';
import { putIntoStore, SCHEME } from '../../store';
import {
    getIsReportsEnable,
    getUmsPrefilterMedcentersGroup,
    historyVisitsList,
    visitsPrefilterAttachments,
    visitsPrefilterClinics,
    visitsPrefilterDoctors,
    visitsPrefilterReports,
    visitsPrefilterSpeciality,
    visitsPrefilterVisitors,
} from '../../selectors';
import { back } from '../../actions';
import { MedcentersFilterModal } from '../../combo';
import { medcenterFilter as modalStyle } from '../../combo/style';
import { COLORS } from '../../styles/colors';
import { useKeyboardHeight } from '../../common/Modal';
import { prepareStringToCompare } from '../../utils/strings';
import { visitsFilter as style } from './style';

const {
    VISITS_FILTER,
    VISITS_PREFILTER_VISITORS,
    VISITS_PREFILTER_CLINICS,
    VISITS_PREFILTER_DOCTORS,
    VISITS_PREFILTER_SPECIALITY,
    VISITS_PREFILTER_ATTACHMENTS,
    VISITS_PREFILTER_REPORTS,
    UMS_PREFILTER_MEDCENTERS_GROUP,
} = SCHEME;

const listData = (data) => {
    const selectIf = fn => putIntoStore(
        SCHEME.VISITS_PREFILTER_VISITORS,
        data.map(e => ({
            ...e,
            selected: fn(e),
        })),
    );
    return data.map(e => ({
        ...e,
        toggle: () => selectIf(ee => (ee.id === e.id ? !ee.selected : ee.selected)),
    }));
};

const listDoctorData = (data) => {
    const selectIf = (fn) => {
        putIntoStore(
            SCHEME.VISITS_PREFILTER_DOCTORS,
            data.map(e => ({
                ...e,
                selected: fn(e),
            })),
        );
    };
    return data.map(e => ({
        ...e,
        toggle: () => {
            selectIf((ee) => {
                // const res = e?.manual
                //     ? prepareStringToCompare(ee?.id) === prepareStringToCompare(e?.id) && ee?.manual === e?.manual
                //     : prepareStringToCompare(ee.name) === prepareStringToCompare(e.name) && e.manual === ee.manual;
                // console.log(`ee ${res} [${res ? !ee?.selected : ee.selected}]`, ee, e);
                // return res ? !ee.selected : ee.selected;
                return prepareStringToCompare(ee?.id) === prepareStringToCompare(e?.id) ? !ee.selected : ee.selected;
            });
        },
    }));
};

const listSpecialityData = (data) => {
    const selectIf = fn => putIntoStore(
        SCHEME.VISITS_PREFILTER_SPECIALITY,
        data.map(e => ({
            ...e,
            selected: fn(e),
        })),
    );
    return data.map(e => ({
        ...e,
        toggle: () => {
            selectIf(ee => (ee.name === e.name ? !ee.selected : ee.selected));
        },
    }));
};

export const VisitorItem = ({
    item: {
        selected, name, toggle, id,
    },
}) => (
    <View key={id} style={style.listItemWrapper} onStartShouldSetResponder={() => true}>
        <Checkbox onPress={toggle} selected={selected} title={name} />
    </View>
);

const VisitorsFilterList = ({ visitors }) => (
    <View style={styles.listWrapper} accessibilityLabel="list">
        <List style={style.list} data={listData(visitors)} renderItem={VisitorItem} />
    </View>
);

const DoctorItem = ({
    item: {
        selected, name, toggle, id, speciality,
    },
}) => (
    <TouchableWithoutFeedback>
        <View
            key={id}
            style={{
                ...style.listItemWrapper,
                flexDirection: 'column',
                flex: 1,
                paddingHorizontal: 20,
                paddingVertical: 10,
                alignItems: 'flex-start',
            }}
            onStartShouldSetResponder={() => true}>
            <Checkbox onPress={toggle} selected={selected} title={<></>} style={{ borderWidth: 1 }}>
                <View
                    style={{
                        alignSelf: 'flex-start',
                        flex: 1,
                    }}>
                    <Text
                        style={{
                            fontSize: 14,
                            color: COLORS.STANDARD_GRAY,
                        }}>
                        {name}
                    </Text>
                    <Text
                        style={{
                            fontSize: 10,
                            color: COLORS.STANDARD_GRAY,
                        }}>
                        {speciality}
                    </Text>
                </View>
            </Checkbox>
        </View>
    </TouchableWithoutFeedback>
);

const SpecialityItem = ({
    item: {
        selected, name, toggle, id,
    },
}) => (
    <TouchableWithoutFeedback>
        <View
            key={id}
            style={{
                ...style.listItemWrapper,
                flexDirection: 'column',
                flex: 1,
                paddingHorizontal: 20,
                paddingVertical: 10,
                alignItems: 'flex-start',
            }}
            onStartShouldSetResponder={() => true}>
            <Checkbox onPress={toggle} selected={selected} title={<></>} style={{ borderWidth: 1 }}>
                <View
                    style={{
                        alignSelf: 'flex-start',
                        flex: 1,
                    }}>
                    <Text
                        style={{
                            fontSize: 14,
                            color: COLORS.STANDARD_GRAY,
                        }}>
                        {name}
                    </Text>
                </View>
            </Checkbox>
        </View>
    </TouchableWithoutFeedback>
);

const withoutDuplicates = arr => arr.reduce(
    (unique, index) => (unique.find(item => item?.name?.trim() === index?.name?.trim() && item?.id?.trim() === index?.id?.trim())
        ? unique
        : [...unique, index]),
    [],
);

const DoctorsFilterList = ({ doctors = [], clinics, speciality }) => {
    const [filter, setFilter] = useState('');
    const list = listDoctorData(doctors).filter(
        item => prepareStringToCompare(item.name).includes(prepareStringToCompare(filter)) || !filter,
    );
    let filteredList = clinics?.length
        ? [
            ...list.filter(item => clinics.some(clinic => item.clinics.some(c => prepareStringToCompare(c) === prepareStringToCompare(clinic.name)))),
        ]
        : [...list];

    if (speciality?.length) {
        filteredList = [
            ...filteredList.filter((item) => {
                const visitSpec1 = prepareStringToCompare(item?.speciality) ?? '';
                const visitSpec2 = prepareStringToCompare(item?.specialization) ?? '';

                return (
                    speciality.some((spec) => {
                        const s = prepareStringToCompare(spec?.id);
                        return visitSpec1 === s || visitSpec2 === s;
                        // return visitSpec1.includes(s) || visitSpec2.includes(s);
                    }) || item?.selected
                );
            }),
        ];
    }
    // console.log('doctor filteredList', {
    //     filteredList,
    //     list,
    //     doctors,
    //     clinics,
    //     speciality,
    // });
    return (
        <>
            <SearchBar initialValue={filter} onSearch={setFilter} />
            <List data={withoutDuplicates(filteredList)} renderItem={DoctorItem} />
        </>
    );
};

const SpecialityFilterList = ({
    speciality = [], filtered = [], doctors, clinics,
}) => {
    const [filter, setFilter] = useState('');
    let filteredList = listSpecialityData(speciality)
        .filter(
            item => (filtered ?? []).some(
                ({ id }) => id?.trim?.()?.toLowerCase?.() === item.name?.toLowerCase?.()?.trim?.(),
            ) || item?.selected,
            // item => (filtered?.length
            //     ? filtered.some(({ id }) => id?.trim?.()?.toLowerCase?.() === item.name?.toLowerCase?.()?.trim?.())
            //     : true) || item?.selected,
        )
        .filter(item => item.name.toLowerCase().includes(filter.toLowerCase()) || !filter)
        .map(item => ({
            ...item,
            id: item?.name?.trim?.()?.toLowerCase?.(),
            name: item?.name?.trim?.()?.toLowerCase?.(),
        }));
    if (doctors?.length && doctors?.some(({ selected }) => selected)) {
        filteredList = filteredList.filter(
            ({ id }) => doctors.filter(({ speciality: spec }) => spec?.trim?.()?.toLowerCase?.() === id)?.length,
        );
    }
    // console.log('Filtered list', {
    //     speciality,
    //     filteredList,
    //     filtered,
    //     doctors,
    // });

    return (
        <>
            <SearchBar initialValue={filter} onSearch={setFilter} />
            <List data={withoutDuplicates(filteredList)} renderItem={SpecialityItem} />
        </>
    );
};

const VisitorsFilter = dataFrom(visitsPrefilterVisitors, ({ data }) => <VisitorsFilterList visitors={data} />);
// const DoctorsFilter = dataFrom(visitsPrefilterDoctors, ({ data }) => <DoctorsFilterList doctors={data} />);
const DoctorsFilter = () => {
    const doctors = useSelector(visitsPrefilterDoctors);
    const clinics = useSelector(visitsPrefilterClinics) ?? [];
    const speciality = useSelector(visitsPrefilterSpeciality) ?? [];
    const selectedClinics = clinics?.filter(item => item?.selected);
    const selectedSpeciality = speciality?.filter(item => item?.selected);
    return (
        <DoctorsFilterList
            doctors={doctors}
            clinics={selectedClinics?.length ? selectedClinics : []}
            speciality={selectedSpeciality?.length ? selectedSpeciality : []}
        />
    );
};

const DoctorsFilterModal = () => {
    const keyboardHeight = useKeyboardHeight();
    return (
        <Modal2
            noScroll
            title="titles.forDoctorVisits"
            containerStyles={[
                modalStyle.modalContainer,
                {
                    ...getMarginHorizontalObject(),
                    marginBottom: keyboardHeight + 8,
                },
            ]}>
            <View
                style={[
                    {
                        flex: 1,
                        borderBottomWidth: 1,
                        borderBottomColor: COLORS.BORDER_GRAY,
                    },
                ]}
                accessibilityLabel="list-wrapper">
                <DoctorsFilter />
            </View>
        </Modal2>
    );
};

const filterVisits = ({
    visits, clinics, doctors, speciality,
}) => {
    // console.log('filterVisits', {
    //     visits,
    //     clinics,
    //     doctors,
    //     speciality,
    // });
    let filteredVisits = Array.isArray(visits) ? [...visits] : [];
    if (clinics?.length && clinics?.some(({ selected }) => selected)) {
        // filteredVisits = filteredVisits.filter(({ branchId }) => clinics?.some(({ id }) => id === branchId));
        filteredVisits = filteredVisits.filter(({ branchName }) => clinics?.some(({ name }) => name?.trim?.().toLowerCase?.() === branchName?.trim?.()?.toLowerCase?.()));
    }
    if (doctors?.length && doctors?.some(({ selected }) => selected)) {
        filteredVisits = filteredVisits.filter((item) => {
            const doc =
                prepareStringToCompare(item?.physicianName) || prepareStringToCompare(item?.doctorInfo?.fullName);
            if (!doc) {
                return true;
            }
            return doctors.some(({ name }) => prepareStringToCompare(name).includes(doc));
        });
    }

    if (speciality?.length && speciality?.some(({ selected }) => selected)) {
        filteredVisits = filteredVisits.filter((item) => {
            const spec = prepareStringToCompare(item?.speciality ?? item?.specialization ?? '');
            if (!spec) {
                return true;
            }
            return speciality.some(({ id }) => prepareStringToCompare(id)?.includes?.(spec));
        });
    }
    return filteredVisits;
};

const extractSpeciality = visits => (
    Array.from(
        new Set(
            visits?.map?.(({ speciality, specialization, selected }) => ({
                id: prepareStringToCompare(speciality ?? specialization ?? ''),
                name: prepareStringToCompare(speciality ?? specialization ?? ''),
                selected,
            })),
        ),
    ) ?? []
).filter(({ id, name }) => !(!id && !name));

const SpecialityFilter = () => {
    const doctors = useSelector(visitsPrefilterDoctors);
    const clinics = useSelector(visitsPrefilterClinics) ?? [];
    const selectedClinics = clinics?.filter(item => item.selected);
    const filteredClinics = selectedClinics?.length ? selectedClinics : clinics;
    // const filteredClinics = selectedClinics?.length ? selectedClinics : clinics;
    const speciality = useSelector(visitsPrefilterSpeciality);
    const selectedDoctors = doctors?.filter(item => item?.selected);
    const filteredDoctors = selectedDoctors?.length
        ? selectedDoctors
        : doctors?.filter(({ clinics: cl = [] }) => cl?.some?.(c => filteredClinics?.some?.(f => prepareStringToCompare(f?.name) === prepareStringToCompare(c))));
    const visits = useSelector(historyVisitsList);
    const f = filterVisits({
        visits,
        clinics: filteredClinics,
        doctors: filteredDoctors,
    });
    const spec = extractSpeciality(f);
    // console.log('SpecialityFilter', {
    //     speciality,
    //     doctors,
    //     selectedDoctors,
    //     f,
    //     spec,
    //     filteredClinics,
    //     filteredDoctors,
    //     visits,
    // });
    return (
        <SpecialityFilterList
            speciality={speciality}
            filtered={speciality.filter(({ id }) => spec.some(s => s?.id === prepareStringToCompare(id)))}
            doctors={selectedDoctors?.length ? selectedDoctors : doctors}
        />
    );
};

const SpecialityFilterModal = () => {
    const keyboardHeight = useKeyboardHeight();
    return (
        <Modal2
            noScroll
            title="titles.forDoctorSpeciality"
            containerStyles={[
                modalStyle.modalContainer,
                {
                    ...getMarginHorizontalObject(),
                    marginBottom: 8 + keyboardHeight,
                },
            ]}>
            <View
                style={[
                    {
                        flex: 1,
                        borderBottomWidth: 1,
                        borderBottomColor: COLORS.BORDER_GRAY,
                    },
                ]}
                accessibilityLabel="list-wrapper">
                <SpecialityFilter />
            </View>
        </Modal2>
    );
};

class VisitsFilterImpl extends Component {
    get selectedVisitorName() {
        return (
            this.props.visitors
                .filter(e => e.selected)
                .map(({ name }) => name)
                .join(', ') || Object.R('titles.anyVisitor')
        );
    }

    get selectedClinicName() {
        return this.selectedClinics.map(({ name }) => name).join(', ') || Object.R('titles.anyClinics');
    }

    get selectedClinics() {
        return this.props.clinics.filter(e => e.selected) ?? [];
    }

    get selectedDoctorName() {
        const selectedDoctors = this.props.doctors.filter(e => e.selected) ?? [];
        const { selectedClinics } = this;
        if (selectedClinics.length) {
            selectedDoctors.forEach((d) => {
                if (!selectedClinics.some(c => d.clinics.includes(c.name))) {
                    // eslint-disable-next-line no-param-reassign
                    d.selected = false;
                }
            });
        }
        const filteredDoctors = selectedClinics.length
            ? selectedDoctors.filter(e => selectedClinics.some(f => e.clinics.includes(f.name)))
            : selectedDoctors;
        return (
            withoutDuplicates(filteredDoctors)
                .map(({ name }) => name)
                .join(', ') || Object.R('titles.anyDoctors')
        );
    }

    get selectedSpecialityName() {
        const selectedSpeciality = this.props.speciality.filter(e => e.selected) ?? [];
        return selectedSpeciality.map(({ name }) => name).join(', ') || Object.R('titles.anySpeciality');
    }

    _resetFilter = () => {
        putIntoStore(VISITS_FILTER, {
            visitors: [],
            byAttachments: false,
            byReports: false,
            clinics: [],
            doctors: [],
            speciality: [],
        });
        const {
            visitors, clinics, doctors, speciality,
        } = this.props;
        putIntoStore(
            VISITS_PREFILTER_VISITORS,
            visitors.map(e => ({
                ...e,
                selected: false,
            })),
        );
        putIntoStore(
            VISITS_PREFILTER_CLINICS,
            clinics.map(e => ({
                ...e,
                selected: false,
            })),
        );
        putIntoStore(
            VISITS_PREFILTER_DOCTORS,
            doctors.map(e => ({
                ...e,
                selected: false,
            })),
        );
        putIntoStore(
            VISITS_PREFILTER_SPECIALITY,
            speciality.map(e => ({
                ...e,
                selected: false,
            })),
        );
        putIntoStore(VISITS_PREFILTER_ATTACHMENTS, false);
        putIntoStore(VISITS_PREFILTER_REPORTS, false);
    };

    _applyFilter = () => {
        const {
            visitors, byAttachments, clinics, doctors, speciality, byReports,
        } = this.props;
        // console.log('Flt', speciality.filter(e => e.selected)
        //     .map(e => e?.id));
        putIntoStore(VISITS_FILTER, {
            visitors: visitors.filter(e => e.selected).map(e => e.id),
            clinics: clinics.filter(e => e.selected).map(e => e.id),
            doctors: doctors.filter(e => e.selected).map(e => e.id),
            speciality: speciality.filter(e => e.selected).map(e => e?.id),
            byAttachments,
            byReports,
        });
        back();
    };

    render() {
        const { byAttachments, byReports, reportsEnable } = this.props;
        return (
            <Page
                name="visits-filter"
                footer={
                    <ButtonsGroup
                        style={{
                            backgroundColor: 'white',
                            paddingBottom: 12,
                            borderTopWidth: 1,
                            borderTopColor: '#F0F0F0',
                        }}>
                        <Button
                            transparent
                            action={this._resetFilter}
                            title="buttons.reset_filter"
                            titleStyles={{
                                color: '#555555',
                                fontSize: 16,
                                fontFamily: 'SourceSansPro-Semibold',
                            }}
                        />
                        <Button primary action={this._applyFilter} title="buttons.find" />
                    </ButtonsGroup>
                }>
                <Section title="titles.forWhomVisitsSection">
                    <SingleNavigation
                        title={this.selectedVisitorName}
                        onPress={() => Page.showModal(
                            <Modal2 noScroll title="titles.forWhomVisits">
                                <VisitorsFilter />
                            </Modal2>,
                        )
                        }
                    />
                </Section>
                <Section title="titles.forClinicVisits" capitalize>
                    <SingleNavigation
                        title={this.selectedClinicName}
                        onPress={() => Page.showModal(
                            <MedcentersFilterModal
                                groupFrom={getUmsPrefilterMedcentersGroup}
                                prefilterDataFrom={visitsPrefilterClinics}
                                prefilterPath={VISITS_PREFILTER_CLINICS}
                                groupPath={UMS_PREFILTER_MEDCENTERS_GROUP}
                            />,
                        )
                        }
                    />
                </Section>

                <Section title="titles.forDoctorVisits" capitalize>
                    <SingleNavigation
                        title={this.selectedDoctorName}
                        onPress={() => Page.showModal(<DoctorsFilterModal />)}
                    />
                </Section>

                <Section title="titles.forDoctorSpeciality" capitalize>
                    <SingleNavigation
                        title={this.selectedSpecialityName}
                        onPress={() => Page.showModal(<SpecialityFilterModal />)}
                    />
                </Section>

                {Platform.OS === 'web' ? null : (
                    <Section>
                        <SingleNavigation
                            title={Object.R('titles.attachmentsPresence')}
                            style={{ paddingVertical: Platform.OS === 'ios' ? 8 : 10 }}
                            rightItem={
                                <Switch
                                    accessibilityLabel="switch:attachments-presence"
                                    style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                                    activeThumbColor="#4770C0"
                                    activeTrackColor="#9FB9FF"
                                    onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                                    thumbTintColor="#5C85DD"
                                    trackColor={{
                                        false: '#CFCFCF',
                                        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                                    }}
                                    value={byAttachments}
                                    onValueChange={value => putIntoStore(VISITS_PREFILTER_ATTACHMENTS, value)}
                                />
                            }
                        />
                    </Section>
                )}
                {reportsEnable && (
                    <Section>
                        <SingleNavigation
                            title={Object.R('titles.reportsPresence')}
                            style={{ paddingVertical: Platform.OS === 'ios' ? 8 : 10 }}
                            rightItem={
                                <Switch
                                    accessibilityLabel="switch:reports-presence"
                                    style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                                    activeThumbColor="#4770C0"
                                    activeTrackColor="#9FB9FF"
                                    onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                                    thumbTintColor="#5C85DD"
                                    trackColor={{
                                        false: '#CFCFCF',
                                        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                                    }}
                                    value={byReports}
                                    onValueChange={value => putIntoStore(VISITS_PREFILTER_REPORTS, value)}
                                />
                            }
                        />
                    </Section>
                )}
            </Page>
        );
    }
}

export const VisitsFilter = Page.register(VisitsFilterImpl, {
    visitors: { from: visitsPrefilterVisitors },
    clinics: { from: visitsPrefilterClinics },
    doctors: { from: visitsPrefilterDoctors },
    speciality: { from: visitsPrefilterSpeciality },
    byAttachments: { from: visitsPrefilterAttachments },
    byReports: { from: visitsPrefilterReports },
    reportsEnable: { from: getIsReportsEnable },
});
