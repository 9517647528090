import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    React, Subtitle, Title, View,
} from '../../common';
import { capitalize } from '../../utils';
import { getDrugInfo } from '../../selectors';
import actions from '../../actions';
import { drugstoreDetails as style } from './style';
import { extendDrugData } from './util';

export const AvailableDrugs = ({ drugs: drugsData, lackOf }) => {
    const drugsInfo = useSelector(getDrugInfo);
    const [drugs, setDrugs] = useState(drugsData.map(i => extendDrugData(i)));
    useEffect(() => {
        if (
            !drugs?.every?.((i) => {
                return !!drugsInfo[i?.ls_num];
            })
        ) {
            actions.loadDrugInfo(drugs);
        }
    }, [drugsData]);

    useEffect(() => {
        setDrugs(drugsData.map(i => extendDrugData(drugsInfo[i?.ls_num], i)));
    }, [drugsInfo, drugsData]);
    return (
        <View style={[{ flex: 5 }]}>
            <View>
                {drugs && drugs.length > 1 ? (
                    <View>
                        {drugs?.map((e) => {
                            const qty = e.qty || 1;
                            return (
                                <View
                                    style={[
                                        style.borderBottom,
                                        {
                                            flexDirection: 'row',
                                            justifyContent: 'flex-start',
                                            paddingVertical: 10,
                                            alignItems: 'flex-start',
                                        },
                                    ]}>
                                    <View
                                        style={[
                                            {
                                                fontSize: 18,
                                                marginBottom: 5,
                                                flexWrap: 'wrap',
                                                flex: 6,
                                                paddingLeft: 30,
                                            },
                                        ]}>
                                        <Title bold style={[]}>
                                            {e.ls_name}
                                        </Title>
                                        <Subtitle style={{ fontSize: 14 }}>{e?.tar_name}</Subtitle>
                                    </View>
                                    <Title
                                        bold
                                        numberOfLines={1}
                                        style={{
                                            fontSize: 14,
                                            color: '#19b798',
                                            paddingLeft: 10,
                                            paddingRight: 30,
                                        }}>
                                        {qty === 1
                                            ? `${e.price} p.`
                                            : `${e.price} x ${qty} = ${(e.price * qty).toFixed(2)} p.`}
                                    </Title>
                                </View>
                            );
                        })}
                        <View
                            style={[
                                {
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingVertical: 10,
                                    alignItems: 'center',
                                },
                            ]}>
                            <Title
                                style={[
                                    {
                                        fontSize: 18,
                                        marginBottom: 5,
                                        flexWrap: 'wrap',
                                        flex: 6,
                                        paddingLeft: 30,
                                    },
                                ]}>
                                {Object.R('titles.TotalAmount')}
                            </Title>
                            <Title
                                bold
                                numberOfLines={1}
                                style={{
                                    fontSize: 18,
                                    paddingLeft: 10,
                                    paddingRight: 30,
                                }}>
                                {(drugs.reduce((sum, { price, qty = 1 }) => sum + price * qty, 0) || 0).toFixed(2)} p.
                            </Title>
                        </View>
                    </View>
                ) : (
                    !!drugs && (
                        <View
                            style={{
                                paddingVertical: 10,
                                paddingHorizontal: 30,
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                            <View>
                                <Title
                                    bold
                                    style={[
                                        {
                                            fontSize: 18,
                                            marginBottom: 5,
                                        },
                                    ]}>
                                    {drugs[0]?.ls_name ?? ''}
                                </Title>
                                <Subtitle style={{ fontSize: 14 }}>{drugs[0]?.tar_name ?? ''}</Subtitle>
                            </View>
                            {/* <Subtitle style={{ fontSize: 14 }}>{drugs[0].country_name}</Subtitle> */}
                            <View
                                style={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}>
                                {/* <Subtitle style={{ fontSize: 14 }}>{drugs[0].requirePrescript}</Subtitle> */}
                                <Title
                                    bold
                                    style={{
                                        fontSize: 14,
                                        color: '#19b798',
                                    }}>
                                    {drugs?.[0]?.qty && drugs?.[0]?.qty > 1
                                        ? `${drugs[0]?.price ?? 0} x ${drugs[0]?.qty ?? 1} = ${(
                                            (drugs[0]?.price ?? 0) * (drugs[0]?.qty ?? 1)
                                        ).toFixed(2)} p.`
                                        : `${drugs[0]?.price ?? 0} p.`}
                                </Title>
                            </View>
                        </View>
                    )
                )}
                <Subtitle
                    style={{
                        color: '#9C9C9C',
                        paddingHorizontal: 30,
                        marginBottom: 10,
                    }}
                    strikethrough
                    numberOfLines={5}>
                    {lackOf ? lackOf.map(e => capitalize(e.name)).join(', ') : null}
                </Subtitle>
            </View>
        </View>
    );
};
