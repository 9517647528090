/* eslint-disable no-underscore-dangle */
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Page, React, View } from '../../common';
import { Map } from '../../combo';
import { getClinicsMedcentersMap, getMapType, selectMedCenterMapDetails } from '../../selectors';
import { averageLocation } from '../Tabletka/DrugstoreDetails.utils';
import { sortBy } from '../../utils';
import { useBackHandler } from '../../utils/routing';
import { MedCenterDetailsBunner } from './MedCenterDetailsBunner';

export const MedCentersMap = Page.register(
    ({
        data = [], item: initialItem, mapType, id,
    }) => {
        const initialItemFromData = useSelector(state => selectMedCenterMapDetails(state, id));
        const [item, setItem] = useState(initialItem || initialItemFromData);
        const { id: itemId } = item || {};
        const initialRegion = useMemo(
            () => ((initialItem && initialItem.latitude) || initialItemFromData?.latitude
                ? {
                    latitude: initialItem?.latitude || initialItemFromData?.latitude,
                    longitude: initialItem?.longitude || initialItemFromData?.longitude,
                }
                : averageLocation([{}, {}])),
            [initialItem, data, id],
        );
        useBackHandler(
            useCallback(() => {
                // eslint-disable-next-line no-undef
                if (itemId && window._currentRouteName === 'MedcentersMap') {
                    setItem(null);
                    return true;
                }
                return false;
            }, [!!itemId]),
        );

        const actualData = useMemo(
            () => sortBy(
                data.map(e => (!e.isSelected === !(e.id === itemId)
                    ? e
                    : {
                        ...e,
                        isSelected: !e.isSelected,
                    })),
                '-latitude',
            ),
            [itemId, data],
        );
        return (
            <Page noscroll name="medcenters_map_screen">
                <View accessibilityLabel="medcenters_screen_map" style={{ flex: 1 }}>
                    <View style={{ flex: 1 }}>
                        {!initialRegion || !actualData?.length ? null : (
                            <>
                                <Map
                                    mapType={mapType}
                                    data={actualData}
                                    onItem={setItem}
                                    initialRegion={initialRegion}
                                    scaleKey="onlineConnectivity"
                                    centerOnClick
                                    item={{ id: itemId }}
                                />
                            </>
                        )}
                    </View>
                    {!itemId ? null : <MedCenterDetailsBunner item={item} />}
                </View>
            </Page>
        );
    },
    {
        mapType: { from: getMapType },
        data: { from: getClinicsMedcentersMap },
    },
);
