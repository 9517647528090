// noinspection JSUnusedGlobalSymbols

const initialState = {
    id: null,
    attachmentsList: [],
    date: '',
    timetableId: null,
    isLoading: false,
    timeslots: [],
    onlineBookingAllowed: false,
    time: null,
};

const RESET_VISIT = 'RESET_VIEWED_VISIT';
const UPDATE_VISIT = 'UPDATE_VIEWED_VISIT';

export const resetViewedVisit = payload => dispatch => dispatch({
    type: RESET_VISIT,
    payload,
});
export const updateViewedVisit = payload => dispatch => dispatch({
    type: UPDATE_VISIT,
    payload: { visitViewed: true, ...payload },
});

export const visitViewReducerCreator = () => {
    const actionHandlers = new Map([
        [UPDATE_VISIT, (state, { payload }) => ({ ...state, ...payload })],
        [RESET_VISIT, (state, { payload = initialState }) => ({ ...payload })],
    ]);

    return (state = initialState, action) => {
        if (actionHandlers.has(action.type)) {
            return actionHandlers.get(action.type)(state, action);
        }
        return state;
    };
};

export default visitViewReducerCreator();
