// noinspection JSIgnoredPromiseFromCall

import { putIntoStore, putRCItemIntoStore } from '../store';
import { isEmpty, tail } from '../utils';
import { getEnums } from '../i18n';
import { memoryStorage } from '../utils/memoryStorage';
import { ALLOWED_LANGUAGES, DEFAULT_LANGUAGE } from '../config';
import firebase from './firebase';
import { storage } from './localstorage';

const defaultAccessLevelConfig = {
    VIEW_NAME: ['VIEW_NAME'],
    VIEW_COVERAGE: ['VIEW_NAME', 'VIEW_COVERAGE'],
    VIEW_VISITS: ['VIEW_NAME', 'VIEW_COVERAGE', 'VIEW_VISITS'],
    BOOK_VISITS: ['VIEW_NAME', 'VIEW_COVERAGE', 'VIEW_VISITS', 'BOOK_VISITS'],
    GRANT: ['VIEW_NAME', 'VIEW_COVERAGE', 'VIEW_VISITS', 'BOOK_VISITS', 'GRANT'],
    EDIT_PROFILE: ['VIEW_NAME', 'VIEW_COVERAGE', 'VIEW_VISITS', 'BOOK_VISITS', 'GRANT', 'EDIT_PROFILE'],
    VIEW_REPORTS_TESTS: [
        'VIEW_NAME',
        'VIEW_COVERAGE',
        'VIEW_VISITS',
        'BOOK_VISITS',
        'GRANT',
        'EDIT_PROFILE',
        'VIEW_REPORTS_TESTS',
    ],
    ALL: ['VIEW_NAME', 'VIEW_COVERAGE', 'VIEW_VISITS', 'BOOK_VISITS', 'GRANT', 'EDIT_PROFILE', 'VIEW_REPORTS_TESTS'],
};
// eslint-disable-next-line import/no-mutable-exports
export let currentAccessLevelConfig = defaultAccessLevelConfig;

const setRemoteConfig = (remoteConfigObject = {}, defaultQueries) => {
    const {
        popularQueries,
        rateResendPeriods,
        hideTimePeriodSearch = false,
        supportedLanguages,
        disabledCoverers,
        mapsAppsConfig,
        // enableLoadingDoctorsImmediately,
        showNewUserAgreementModal = false,
        inAppUpdateAndroid = false,
        inAppUpdateIOS = false,
        endOfLife = JSON.stringify({
            version: '1.1.360-qa.aaa.bbb',
            popupTitle: 'titles.outdatedVersionTitle',
            popupMessage: 'titles.outdatedVersionMessage',
            linkToStore: {
                android: 'market://details?id=md.aibolit.member.prod',
                ios: '',
            },
        }),
        enableTests = false,
        enableReports = false,
        inAppUpdateNotifyPeriod = 5,
        disableUnconfirmedFeedback = 'yes',
        statusesOfHappenedVisits = [],
        statusesOfHappenedVisitsDoctor = [],
        acodeExpirationTime = 120000,
        enableLoadPastsVisits = false,
        accessLevelsConfig = defaultAccessLevelConfig,
        memoryStoragePdf = 1,
        biometryNoEnrollConfirm = true,
        sortByBumbaFeature = true,
        revalidateUnexpiredACodes = false,
        defaultSmsProvider = 'assist',
        enableAddPersonOnBooking = false,
        usePreviousDoctorsList = false,
        useLocationNta = false,
    } = remoteConfigObject;
    currentAccessLevelConfig =
        typeof accessLevelsConfig === 'string' ? JSON.parse(accessLevelsConfig) : accessLevelsConfig;
    const languages = getEnums('language');
    storage.set('remoteConfigObject', remoteConfigObject);
    storage.set('defaultSmsProvider', defaultSmsProvider);
    storage.set('acodeExpirationTime', +acodeExpirationTime);
    // storage.set('enableLoadingDoctorsImmediately', Platform.OS === 'web' || enableLoadingDoctorsImmediately); // needs to be 'true' for web, otherwise it crashes when opening via deeplink with docId
    const endOfLifeObject = JSON.parse(endOfLife);
    const parsedSupportedLanguages = supportedLanguages ? JSON.parse(supportedLanguages) : ALLOWED_LANGUAGES;
    storage.set('supportedLanguages', parsedSupportedLanguages);
    storage.set('ratePeriods', rateResendPeriods ? JSON.parse(rateResendPeriods) : null);
    memoryStorage.setSize(memoryStoragePdf);

    const result = popularQueries
        ? JSON.parse(popularQueries)
            .map(
                querie => defaultQueries.find(q => q.id === querie.id || q.id === querie.id?.replace(/ /gi, '_')) || {
                    ...querie,
                    name: tail(
                        Object.R(`popularQueries.${querie.type}.${querie.id?.replace(/ /gi, '_')}`),
                    ).replace(/_/gi, ' '),
                },
            )
            .filter(Boolean)
        : defaultQueries;
    putIntoStore(`popularQueries`, result);
    const valuesObj = {
        acodeExpirationTime: +acodeExpirationTime,
        hideTimePeriodSearch,
        showNewUserAgreementModal,
        inAppUpdateAndroid,
        inAppUpdateIOS,
        endOfLife: endOfLifeObject,
        inAppUpdateNotifyPeriod,
        mapsAppsConfig: mapsAppsConfig ? JSON.parse(mapsAppsConfig) : {},
        disableUnconfirmedFeedback:
            disableUnconfirmedFeedback === 'yes' ||
            (typeof disableUnconfirmedFeedback === 'boolean' && disableUnconfirmedFeedback) ||
            disableUnconfirmedFeedback === 'onlineClinicsOnly',
        disableUnconfirmedFeedbackForOnlineClinicsOnly:
            disableUnconfirmedFeedback === 'onlineClinicsOnly' || typeof disableUnconfirmedFeedback === 'boolean',
        enableTests,
        enableReports,
        statusesOfHappenedVisits: !isEmpty(statusesOfHappenedVisits) ? JSON.parse(statusesOfHappenedVisits) : [],
        statusesOfHappenedVisitsDoctor: !isEmpty(statusesOfHappenedVisitsDoctor)
            ? JSON.parse(statusesOfHappenedVisitsDoctor)
            : [],
        supportedLanguages: [
            languages.find(({ id }) => id === DEFAULT_LANGUAGE),
            ...parsedSupportedLanguages
                .map(e => languages.find(({ id }) => id === e.id))
                .filter(({ id }) => id !== DEFAULT_LANGUAGE),
        ],
        enableLoadPastsVisits,
        biometryNoEnrollConfirm,
        sortByBumbaFeature,
        revalidateUnexpiredACodes,
        defaultSmsProvider,
        enableAddPersonOnBooking,
        usePreviousDoctorsList,
        useLocationNta,
    };
    if (disabledCoverers) {
        const parsedDisabledCoverers = JSON.parse(disabledCoverers);
        putIntoStore(`disabledCoverers`, parsedDisabledCoverers);
    }
    putRCItemIntoStore(valuesObj);
};

export const initRemoteConfig = (defaults) => {
    const defaultQueries = [
        ...getEnums('popularQueries.doctors').map(el => ({
            ...el,
            type: 'doctors',
        })),
        ...getEnums('popularQueries.drugs').map(el => ({
            ...el,
            type: 'drugs',
        })),
    ].map(el => ({
        ...el,
        name: tail(Object.R(`popularQueries.${el.type}.${el.id}`)),
    }));
    // eslint-disable-next-line max-statements
    firebase
        .initRemoteConfig(defaults)
        .then((remoteConfigObject = {}) => {
            setRemoteConfig(remoteConfigObject, defaultQueries);
        })
        .catch(async () => {
            const remoteConfigObject = await storage.get('remoteConfigObject');
            setRemoteConfig(remoteConfigObject, defaultQueries);
        });
};
