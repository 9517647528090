// noinspection JSUnusedGlobalSymbols

import api from '../services/api';
import { asyncActionCreator, createReducerFor } from './utils';

const TYPE = 'INVOICES';

export const fetchInvoices = (initialState = {}) => asyncActionCreator(TYPE, 'invoices', initialState, () => api.getAllInvoices())();

export default createReducerFor(TYPE);
