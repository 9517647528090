// noinspection JSUnusedGlobalSymbols

import service from '../services/user';
import { createReducerFor } from './utils';

const TYPE = 'prefs';

export const prefAction =
    fn => (...params) => async (dispatch) => {
        let data = null;
        let error = null;
        try {
            dispatch({
                type: TYPE,
                payload: { isLoading: true },
            });
            data = await fn(...params);
        } catch (ex) {
            error = ex;
        } finally {
            dispatch({
                type: TYPE,
                payload: {
                    error,
                    data,
                    isLoading: false,
                },
            });
        }
    };

export const updatePreference = prefAction(service.updatePreference);
export const clearPreferences = prefAction(() => {});

// marks
// export const setMarksOnceProgram = flag => updatePreference(
//     'onceProgram',
//     () => flag
// );

// favorites (for old solution)
// export const toggleFavorites = id => updatePreference(
//     'favorites',
//     (items = []) => (items.includes(id) ? items.filter(e => e !== id) : [...items, id]),
// );

// export const updateFavoritesList = data => updatePreference(
//     'favorites',
//     () => (data),
// );

// export const addToFavorites = id => updatePreference(
//     'favorites',
//     (items = []) => (items.includes(id) ? [...items] : [...items, id]),
// );
// export const removeFromFavorites = id => updatePreference(
//     'favorites',
//     (items = []) => ([...items.filter(e => e !== id)]),
// );

// new favorites (new solution)

export const updateFullFavoritesList = (data) => {
    return updatePreference('fullFavorites', () => data);
    // updateFavoritesList(data.map(e => e.id));
};

export const toggleFullFavorites = (item) => {
    // toggleFavorites(item.id);
    return updatePreference('fullFavorites', (items = []) => (items.some(e => e.id === item.id) ? items.filter(e => e.id !== item.id) : [...items, item]));
};

export const addFullFavorites = (item) => {
    // addToFavorites(item.id);
    return updatePreference('fullFavorites', (items = []) => (items.some(({ id }) => item?.id === id) ? [...items] : [...items, item]));
};

// Basket
export const toggleBasket = item => updatePreference('basket', (items = []) => (items.some(e => e.id === item.id) ? items.filter(({ id }) => id !== item.id) : [...items, item]));

// Drugs
export const deleteSearchHistory = item => updatePreference('drugsSearch', (items = []) => items.filter(({ id }) => id !== item.id));

export const addDrugInHistory = item => updatePreference('drugsSearch', (items = []) => [item, ...items.filter(({ key }) => key !== item.key)].slice(0, 8));

export const setProgram = item => updatePreference('program', () => item);

export const setUserAgreementIsSigned = v => updatePreference('isUserAgreementSigned', () => v);

export const addDocument = doc => updatePreference('documents', (items = []) => [...items, doc]);

export const removeDocument = docId => updatePreference('documents', (items = []) => items.filter(({ id }) => id !== docId));

export const updatePrefs = prefAction(obj => obj);

export const updateDocFilters = (docFilters, from = '') => updatePreference(`docFilters${from}`, oldFilters => ({ ...oldFilters, ...docFilters }));
export const changeBasketQty = (item, qty = 1) => updatePreference('basket', (items = []) => items.map(i => ({
    ...i,
    qty: i?.id === item?.id ? qty : i?.qty,
})));

export const changeSelectInBasket = (item, selected = true) => updatePreference('basket', (items = []) => items.map(i => ({
    ...i,
    selected: i?.id === item?.id ? selected : i?.selected,
})));

export const changeSelectionInBasket = i => updatePreference('basket', (items = []) => [...i]);

export default createReducerFor(TYPE);
