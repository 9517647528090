import EStyleSheet from 'react-native-extended-stylesheet';

export const footer = EStyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'row',
        marginTop: 6,
        flexWrap: 'wrap',
        // justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'flex-end',
    },
    item: {
        borderRadius: 0,
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 4,
        flexShrink: 1,
        marginRight: 16,
        // flexGrow: 1,
    },
    text: { color: '$headerScreenTitleColor' },
    iconReport: { marginRight: 4 },
    iconDrugs: { marginRight: 2 },
});
