/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { KeyboardAvoidingView } from 'react-native';
import {
    Button, ButtonsGroup, Modal, Page, Platform, ScrollView, Section, selectOptions, showNotification, Text, TextArea, TextInput, Title, TopNotification, TouchableWithoutFeedback, View,
} from '../../common';
import { getUserPerson, getUserRelatives, plannedVisits } from '../../selectors';
import {
    DateTimeSelector, getFormat, getNumber, splitDate,
} from '../../combo/DateSelector';
import { SuggestionEdit } from '../../common/SuggestionEdit';
import { getFormValues, isPhysicianCollectionFetched } from '../../selectors/forms';
import { fetchLocalDoctors } from '../Doctors/utils';
import {
    combineDates, isFutureVisit, isPeriodsIntersect, isValidDate, isValidTime, parseToDdMmYyyy,
} from '../../utils/dates';
import actions from '../../actions';
import api from '../../services/api';
import { COLORS } from '../../styles/colors';
import { CREATED_MANUALLY } from '../../actions/visits';
import { sortByNational } from '../../utils';
import { getClinicsList, getDoctorsList, getSpecialitiesList } from '../../selectors/manulaVisit';
import { setFormValue } from '../../actions/forms';
import { consoleLog } from '../../utils/log';
import { VisitInfoSelector } from './snippets/VisitInfoSelector';
import { AttachmentsForVisit, DrugsForVisit } from './Visit';

const ALLOWED_ACCESS = ['ALL', 'BOOK_VISITS', 'GRANT', 'EDIT_PROFILE', 'VIEW_REPORTS_TESTS'];

// eslint-disable-next-line max-params
const checkVisits = (visits = [], startDate, endDate, person, onSubmit, onCancel, withoutTime = false) => {
    if (withoutTime) {
        onSubmit?.();
        return;
    }
    const startDate_ = new Date(startDate).getTime();
    const endDate_ = new Date(endDate ?? startDate).getTime();
    if (
        startDate_ &&
        endDate_ &&
        visits?.some(
            ({
                startDate: visitStartDate,
                endDate: visitEndDate,
                profileId,
                dateStart = Date.parse(visitStartDate),
                dateEnd = Date.parse(visitEndDate),
                status,
                withoutTime: wt,
            }) => {
                if (Number.isNaN(dateEnd) && status === CREATED_MANUALLY) {
                    // eslint-disable-next-line no-param-reassign
                    dateEnd = dateStart + 59000;
                }
                if (profileId !== person) {
                    return false;
                }
                if (status === CREATED_MANUALLY && wt) {
                    return false;
                }

                return isPeriodsIntersect(dateStart, dateEnd - 1, startDate_, endDate_);

                // return (
                //     (startDate_ >= dateStart && startDate_ < dateEnd) || (endDate_ > dateStart && endDate_ <= dateEnd)
                // );
            },
        )
    ) {
        Page.showModal(
            <Modal
                hideClose
                _onClose={() => {
                    typeof onCancel === 'function' && onCancel?.();
                }}
                onClose={() => {
                    typeof onCancel === 'function' && onCancel?.();
                }}>
                <View>
                    <Title
                        id={'titles.sameDateBookingError'}
                        bold
                        style={{
                            textAlign: 'center',
                            marginBottom: 12,
                        }}
                        numberOfLines={null}
                    />
                    <Button
                        primary
                        title="buttons.chooseAnotherDate"
                        action={() => {
                            Page.closeModal();
                            onCancel?.();
                        }}
                    />
                    <Button
                        transparent
                        title="buttons.bookAnyway"
                        action={() => {
                            Page.closeModal();
                            onSubmit?.();
                        }}
                    />
                    <Button
                        transparent
                        title="buttons.close"
                        action={() => {
                            Page.closeModal();
                            onCancel?.();
                        }}
                    />
                </View>
            </Modal>,
        );
    } else {
        onSubmit?.();
    }
};

// eslint-disable-next-line max-statements,complexity
export const AddVisitManually = ({ route: { params }, navigation }) => {
    const isFuture = params?.isFuture;
    const values = useSelector(getFormValues);
    const visits = useSelector(plannedVisits);
    const list = useSelector(getDoctorsList);
    const clinicList = useSelector(getClinicsList);
    const [doctorName, setDoctorName] = React.useState('');
    const specialitiesList = useSelector(getSpecialitiesList);
    const [specialityName, setSpecialityName] = React.useState('');
    const [clinicName, setClinicName] = React.useState('');
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [title, setTitle] = useState('');
    const [comment, setComment] = useState('');
    const userPerson = useSelector(getUserPerson);
    const relatives = useSelector(getUserRelatives);
    const [person, setPerson] = useState({});
    const [visitors, setVisitors] = useState([]);
    const [timeError, setTimeError] = useState('');
    const [dateError, setDateError] = useState('');
    const [savingState, setSavingState] = useState(false);
    const [dateTouched, setDateTouched] = useState(false);

    const [doctorClickEvent, setDoctorClickEvent] = useState(null);
    const [specialityClickEvent, setSpecialityClickEvent] = useState(null);
    const [clinicClickEvent, setClinicClickEvent] = useState(null);

    const closeSuggestions = useCallback(() => {
        const now = Date.now();
        setDoctorClickEvent(now);
        setSpecialityClickEvent(now);
        setClinicClickEvent(now);
    }, [setClinicClickEvent, setSpecialityClickEvent, setDoctorClickEvent]);

    useEffect(() => {
        actions.setFormValue('drugsForVisit', []);
        actions.setFormValue('attachmentsForVisit', []);
    }, []);

    useEffect(() => {
        consoleLog('Relatives', relatives);
        const tempList = [
            {
                id: userPerson?.profileId,
                name: Object.R('titles.for_me'),
                relation: 'Я',
                ...userPerson,
            },
            ...(relatives?.map(({
                profileId, firstLastName, birthday, name, ...rest
            }) => ({
                id: profileId,
                ...rest,
                name: name ? `${firstLastName} (${parseToDdMmYyyy(birthday)})` : '',
            })) ?? []),
        ]?.filter?.(({ name }) => name);
        setVisitors(tempList);
        if (!Object.keys(person).length) {
            setPerson(tempList[0]);
        }
    }, [userPerson, relatives]);

    const chooseVisitor = () => {
        closeSuggestions();
        selectOptions({
            title: 'titles.select_visitor',
            data: visitors?.filter(
                i => !i?.accessLevel ||
                    ALLOWED_ACCESS.includes(i?.accessLevel) ||
                    i?.accessLevels?.some?.(l => ALLOWED_ACCESS?.includes(l)),
            ),
            selected: person.id,
            onSelect: (v) => {
                setPerson(v);
            },
        });
    };
    //
    // const getParams = useCallback(() => {}, []);

    // eslint-disable-next-line max-statements,complexity
    const saveVisitHandler = () => {
        closeSuggestions();
        setSavingState(true);
        const doctorForSave = {};
        const foundDoctor = list.find(({ name }) => name === doctorName);
        const foundClinic = clinicList.find(({ name }) => name === clinicName);
        const physicianId = foundDoctor?.id;
        const physicianName = foundDoctor?.name ?? doctorName;
        const branchId = foundClinic?.id;
        let assignmentId = null;
        if (foundDoctor === undefined) {
            doctorForSave.fullName = doctorName;
            doctorForSave.name = doctorName;
            doctorForSave.id = doctorName;
        } else if (foundClinic) {
            const branch = foundDoctor?.worksAt?.find(({ id }) => id === foundClinic?.id);
            if (branch) {
                assignmentId = branch?.assignmentId;
            }
        }
        const branchName = foundClinic?.name ?? clinicName;
        const profileId = userPerson?.profileId;
        const speciality = specialityName;
        const defaultStartOfDay = true;
        const startDate = combineDates(date, time, defaultStartOfDay);
        const woTimeHour = defaultStartOfDay ? 0 : 23;
        const woTimeMinute = defaultStartOfDay ? 0 : 59;
        const withoutTime = startDate?.getHours() === woTimeHour && startDate?.getMinutes() === woTimeMinute;
        const orderProfileId = person?.id;
        const drugs = values?.drugsForVisit ?? [];
        const attachments = values?.attachmentsForVisit ?? [];

        const param = Object.entries({
            physicianId,
            physicianName,
            branchId,
            branchName,
            profileId: orderProfileId,
            speciality,
            startDate,
            endDate: withoutTime ? new Date(new Date(startDate).getTime() + 3600 * 24 * 1000) : null,
            withoutTime,
            orderProfileId: profileId,
            drugs,
            attachments,
            assignmentId,
            name: title,
            comment,
            // address,
        }).reduce((acc, [key, value]) => {
            if (Array.isArray(value)) {
                if (value.length === 0) {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: value,
                };
            }
            if (value !== undefined && value !== null && value !== '') {
                return {
                    ...acc,
                    [key]: value,
                };
            }
            return acc;
        }, {});

        checkVisits(
            visits,
            param.startDate,
            param.endDate,
            person?.id,
            // eslint-disable-next-line max-statements
            () => {
                const foundSpeciality = specialitiesList.find(({ name }) => name === specialityName);
                if (foundSpeciality === undefined) {
                    if (Object.keys(doctorForSave).length) {
                        doctorForSave.specialization = specialityName;
                    }
                    if (specialityName?.trim?.()) {
                        actions.addSuggestionItem('userSpecialities', {
                            id: specialityName,
                            name: specialityName,
                        });
                        actions.setFormValue('userSpecialization', [
                            ...(values?.userSpecialization ?? []),
                            {
                                id: specialityName,
                                name: specialityName,
                            },
                        ]);
                    }
                } else {
                    actions.addSuggestionItem('userSpecialities', foundSpeciality);
                }

                if (foundClinic === undefined) {
                    if (Object.keys(doctorForSave).length) {
                        doctorForSave.worksAt = [
                            {
                                name: clinicName,
                                id: clinicName,
                            },
                        ];
                    }
                    if (clinicName?.trim()) {
                        actions.addSuggestionItem('userClinics', {
                            id: clinicName,
                            name: clinicName,
                        });
                        actions.setFormValue('userClinics', [
                            ...(values?.userClinics ?? []),
                            {
                                id: clinicName,
                                name: clinicName,
                            },
                        ]);
                    }
                } else {
                    actions.addSuggestionItem('userClinics', foundClinic);
                }
                if (Object.keys(doctorForSave)?.length && doctorForSave?.fullName?.trim?.()) {
                    actions.addSuggestionItem('userDoctors', doctorForSave);
                    actions.setFormValue('userDoctors', [...(values?.userDoctors ?? []), doctorForSave]);
                } else if (foundDoctor) {
                    actions.addSuggestionItem('userDoctors', foundDoctor);
                }
                api.manualVisit(param)
                    // eslint-disable-next-line max-statements
                    .then((a) => {
                        let response;
                        try {
                            response = JSON.parse(a.data);
                            if (response?.code) {
                                let err = Object.R(`error.${response.code}`);
                                if (err === `error.${response.code}`) {
                                    err = 'error.errorSaveVisit';
                                }
                                showNotification(err);
                                return;
                            }
                        } catch {
                            showNotification('error.errorSaveVisit');
                            return;
                        }
                        const combined = combineDates(date, time, true);
                        if (isFutureVisit(combined, typeof time === 'undefined' || !time)) {
                            showNotification('titles.futureVisitAdded');
                            actions.setFormValue('visitForScroll', response);
                            navigation.pop();
                            navigation.navigate('Home', { screen: 'Home' });
                        } else {
                            actions.setFormValue('visitForScroll', response);
                            showNotification('titles.pastVisitAdded');
                            navigation.goBack();
                        }
                    })
                    .catch(() => {
                        showNotification('error.errorSaveVisit');
                    })
                    .finally(() => {
                        setSavingState(false);
                    });
            },
            () => {
                setSavingState(false);
            },
            withoutTime,
        );
    };

    const cancelVisitHandler = () => {
        closeSuggestions();
        navigation.goBack();
    };

    const [textTime, setTextTime] = useState('');
    const [textDate, setTextDate] = useState('');

    const getDateFormatErrors = useCallback(
        (value) => {
            const d = splitDate(value ?? textDate);
            return !isValidDate(d?.day, d?.month, d?.year);
        },
        [textDate],
    );

    const getTimeFormatError = useCallback(
        (value) => {
            const t = value ?? textTime;
            if (t?.length === 0) {
                return false;
            } else if (t?.length < 3) {
                return true;
            }
            const hour = getNumber(t?.substring(0, 2));
            const minute = getNumber(t?.substring(3, 5));
            return !isValidTime(hour, minute);
        },
        [textTime],
    );

    const checkDateTimeChanges = () => {
        const isDateError = getDateFormatErrors() && textDate?.length > 9;
        const isTimeError = getTimeFormatError() && textTime?.length > 4;
        if (isDateError) {
            setDateError('dateError');
        }

        if (isTimeError) {
            setTimeError('timeError');
        }

        if (isTimeError || isDateError) {
            return;
        }
        const combined = combineDates(date, time, true);
        // combined?.setSeconds?.(59);
        if (combined && isFuture && !isFutureVisit(combined, typeof time === 'undefined' || !time)) {
            setDateError('futureError');
        } else {
            setDateError('');
        }
    };

    useEffect(() => {
        checkDateTimeChanges();
    }, [date, time, textDate, textTime]);

    const checkFormatErrors = (d, t) => {
        const dError = getDateFormatErrors(d);
        const tError = getTimeFormatError(t);
        setTimeError(tError ? 'timeError' : '');
        setDateError(dError ? 'dateError' : '');
        return dError || tError;
    };

    const hasErrors = useCallback(() => {
        const dError = getDateFormatErrors();
        const tError = getTimeFormatError();
        return dError || tError;
    }, [textDate, textTime]);

    return (
        <KeyboardAvoidingView
            behavior={Platform.OS === 'android' ? 'padding' : 'position'}
            keyboardVerticalOffset={Platform.OS === 'android' ? 0 : 30}>
            <ScrollView keyboardDismissMode="on-drag" keyboardShouldPersistTaps="handled">
                <TopNotification
                    hint={{
                        message: 'titles.addVisitManuallyWarning',
                        level: 'warn',
                    }}
                />
                <TouchableWithoutFeedback onPress={() => closeSuggestions()}>
                    <View style={{ marginHorizontal: 0 }}>
                        <Section title="titles.visitDate" capitalize mandatory>
                            <DateTimeSelector
                                date={date}
                                onSelectDate={(d) => {
                                    setDateTouched(true);
                                    closeSuggestions();
                                    setDate(d);
                                    !!d && setTextDate(getFormat('date', d));
                                    !!d && checkFormatErrors(getFormat('date', d));
                                }}
                                mode={'date'}
                                edit
                                future={true}
                                past={true}
                                // secondPart={time}
                                error={!!dateError}
                                placeholder="titles.datePlaceholder"
                                onFocus={closeSuggestions}
                                onTextChange={(t) => {
                                    setDateTouched(true);
                                    setTextDate(t);
                                }}
                                onBlur={() => {
                                    checkFormatErrors();
                                    checkDateTimeChanges();
                                }}
                                onClick={closeSuggestions}
                            />
                            {!!dateError && !!dateTouched && dateError !== 'emptyDate' && (
                                <Text style={{ color: COLORS.NEGATIVE }}>{Object.R(`titles.${dateError}`)}</Text>
                            )}
                        </Section>
                        <Section title="titles.visitTime" capitalize>
                            <DateTimeSelector
                                date={time}
                                onSelectDate={(d) => {
                                    setDateTouched(true);
                                    setTime(d);
                                    closeSuggestions();
                                    !!d && setTextTime(getFormat('time', d));
                                    !!d && checkFormatErrors(undefined, getFormat('time', d));
                                }}
                                mode={'time'}
                                edit
                                future={true}
                                past={true}
                                secondPart={date}
                                error={!!timeError || (dateError === 'futureError' && !!time)}
                                onFocus={() => {
                                    closeSuggestions();
                                }}
                                onBlur={() => {
                                    checkFormatErrors();
                                    checkDateTimeChanges();
                                }}
                                onTextChange={(t) => {
                                    setDateTouched(true);
                                    setTextTime(t);
                                }}
                                onClick={closeSuggestions}
                            />
                            {timeError === 'timeError' && (
                                <Text style={{ color: COLORS.NEGATIVE }}>{Object.R(`titles.${timeError}`)}</Text>
                            )}
                        </Section>
                        <Section title="enrollVisitInfoFields.doctorInfo" capitalize>
                            <SuggestionEdit
                                value={doctorName}
                                placeholder={'doctorsInfoFields.fullName'}
                                // list={list}
                                listSelector={getDoctorsList}
                                suggestionKey={'userDoctors'}
                                onChangeText={setDoctorName}
                                onSelect={(item) => {
                                    setDoctorName(item?.name);
                                    specialityName?.trim?.()?.length === 0 && setSpecialityName(item?.specialization);
                                    if (!clinicName && item?.worksAt?.length === 1) {
                                        setClinicName(item?.worksAt?.[0]?.name);
                                    }
                                }}
                                inputStyle={{ fontSize: 16 }}
                                clickEvent={doctorClickEvent}
                                onFocus={() => {
                                    setDoctorClickEvent(null);
                                    setSpecialityClickEvent(Date.now());
                                    setClinicClickEvent(Date.now());
                                }}
                                sortFn={sortByNational}
                                timeoutOnFocus={Platform.OS === 'web' ? 200 : 0}
                            />
                        </Section>
                        <Section title="titles.doctor_speciality" capitalize>
                            <SuggestionEdit
                                value={specialityName}
                                placeholder={'doctorsInfoFieldsWithDescription.specialization'}
                                // list={specialitiesList}
                                listSelector={getSpecialitiesList}
                                suggestionKey={'userSpecialities'}
                                onChangeText={setSpecialityName}
                                onSelect={(item) => {
                                    if (!item) {
                                        return;
                                    }
                                    setSpecialityName(item?.name);
                                }}
                                onClear={() => {
                                    setSpecialityName('');
                                }}
                                inputStyle={{ fontSize: 16 }}
                                timeoutOnFocus={Platform.OS === 'web' ? 200 : 0}
                                onFocus={() => {
                                    setSpecialityClickEvent(null);
                                    setDoctorClickEvent(Date.now());
                                    setClinicClickEvent(Date.now());
                                }}
                                clickEvent={specialityClickEvent}
                                sortFn={sortByNational}
                            />
                        </Section>
                        <Section title="doctorsInfoFieldsWithDescription.worksAt" capitalize>
                            <SuggestionEdit
                                value={clinicName}
                                placeholder={'titles.placeholderForClinicManualVisit'}
                                // list={clinicList}
                                listSelector={getClinicsList}
                                onChangeText={setClinicName}
                                onSelect={(item) => {
                                    if (!item) {
                                        return;
                                    }
                                    setClinicName(item?.name);
                                }}
                                suggestionKey={'userClinics'}
                                inputStyle={{ fontSize: 16 }}
                                onFocus={() => {
                                    setClinicClickEvent(null);
                                    setDoctorClickEvent(Date.now());
                                    setSpecialityClickEvent(Date.now());
                                }}
                                clickEvent={clinicClickEvent}
                                sortFn={sortByNational}
                                timeoutOnFocus={Platform.OS === 'web' ? 200 : 0}
                            />
                        </Section>
                        <Section title="titles.whose_visit" capitalize>
                            <VisitInfoSelector
                                title={person?.firstLastName ?? person?.name ?? ''}
                                sectionStyle={{
                                    marginTop: 0,
                                    marginBottom: 0,
                                    paddingHorizontal: 0,
                                }}
                                enabled
                                onSelect={chooseVisitor}
                            />
                        </Section>
                        <Section title="titles.visit_name" capitalize>
                            <TextInput
                                onChange={(v) => {
                                    setTitle(v);
                                }}
                                value={title}
                                placeholder="titles.for_example_consultation"
                                onFocus={() => {
                                    closeSuggestions();
                                }}
                            />
                        </Section>
                        <Section
                            title="titles.comment"
                            // titleStyle={styles.headerSection}
                            style={{ marginBottom: 0 }}
                            capitalize>
                            <TextArea
                                onChange={(v) => {
                                    // actions.updateEditedVisit({ comment: v });
                                    setComment(v);
                                }}
                                value={comment}
                                placeholder="titles.additional_information_about_visit"
                                style={{
                                    flexGrow: 1,
                                    minHeight: 70,
                                    outline: 'none',
                                    outlineWidth: 0,
                                    borderColor: COLORS.EDIT_BORDER,
                                }}
                                onFocus={() => {
                                    closeSuggestions();
                                }}
                            />
                        </Section>
                        <AttachmentsForVisit
                            manualVisit
                            attachments={values?.attachmentsForVisit ?? []}
                            style={{
                                marginTop: 16,
                                marginBottom: 8,
                                fontSize: 24,
                                fontWeight: 'bold',
                            }}
                        />
                        <DrugsForVisit
                            drugs={values?.drugsForVisit ?? []}
                            style={{
                                marginTop: 16,
                                marginBottom: 8,
                            }}
                            manualVisit
                            onDelete={(drug) => {
                                actions.setFormValue(
                                    'drugsForVisit',
                                    values?.drugsForVisit?.filter(({ id }) => id !== drug?.id),
                                );
                            }}
                        />
                        <View
                            style={{
                                paddingHorizontal: 16,
                                paddingBottom: 16,
                            }}>
                            <ButtonsGroup
                                style={{
                                    marginHorizontal: 0,
                                    paddingHorizontal: 0,
                                }}>
                                <Button
                                    normal
                                    title={'buttons.cancel'}
                                    action={cancelVisitHandler}
                                    disabled={savingState}
                                    trackingAlias={'addVisitManually'}
                                />
                                <Button
                                    primary
                                    title={'buttons.save'}
                                    action={saveVisitHandler}
                                    disabled={
                                        dateError || timeError || savingState || hasErrors() || !dateTouched || !date
                                    }
                                    trackingAlias={'addVisitManually'}
                                />
                            </ButtonsGroup>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </ScrollView>
        </KeyboardAvoidingView>
    );
};

export const AddFutureVisitorsManuallyPage = Page.register(({ navigation }) => {
    const isFetched = useSelector(isPhysicianCollectionFetched);
    fetchLocalDoctors(!isFetched);
    const dispatch = useDispatch();
    if (!isFetched) {
        dispatch(setFormValue('isPhysicianCollectionFetched', true));
    }
    return (
        <Page name="add-visit-manually" noscroll={Platform.OS !== 'web'}>
            <AddVisitManually route={{ params: { isFuture: true } }} navigation={navigation} />
        </Page>
    );
});

export const AddPastVisitorsManuallyPage = Page.register(({ navigation }) => {
    const isFetched = useSelector(isPhysicianCollectionFetched);
    fetchLocalDoctors(!isFetched);
    const dispatch = useDispatch();
    if (!isFetched) {
        dispatch(setFormValue('isPhysicianCollectionFetched', true));
    }
    return (
        <Page name="add-visit-manually" noscroll={Platform.OS !== 'web'}>
            <AddVisitManually route={{ params: { isFuture: false } }} navigation={navigation} />
        </Page>
    );
});
