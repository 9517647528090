import {
    LoadingIndicator, Page, React, View,
} from '../../common';
import { AppHeader } from '../../combo';
import actions, { back } from '../../actions';
import { getDocuments } from '../../selectors';
import { resolveImagePath } from '../../services/device';
import { ImageSlider } from '../../combo/ImageSlider';

export const DocumentsSlider = Page.register(
    ({
        documents: data = [],
        initialId,
        setState,
        isUpdating,
        dataUrls = data.map(item => ({
            ...item,
            url: resolveImagePath(item.uri, item.cachePath || item.path),
        })),
    }) => (
        <Page backgroundColor="black" noscroll>
            <View style={{
                flex: 1,
                backgroundColor: 'black',
            }}>
                {!isUpdating ? <ImageSlider
                    initialId={initialId}
                    dataUrls={dataUrls}
                    onDelete={async (itemId) => {
                        setState({ isUpdating: true });
                        const newData = dataUrls.filter(({ id }) => id !== itemId);
                        await actions.removeDocument(itemId);
                        setState({ dataUrls: newData });
                        if (!newData.length) {
                            back();
                        } else {
                            const currentItemIndex = data.findIndex(({ id }) => id === itemId);
                            const newInitialIndex = currentItemIndex === data.length - 1 ? currentItemIndex - 1 : currentItemIndex;
                            setState({
                                initialId: newData[newInitialIndex].id,
                                currentItemId: newData[newInitialIndex].id,
                                isUpdating: false,
                            });
                        }
                    }
                    }
                /> : <View style={{
                    flex: 1,
                    alignItems: 'center',
                    justifyContent: 'center',
                }}><LoadingIndicator/></View>}
            </View>
        </Page>
    ),
    { documents: { from: getDocuments } },
    { header: () => <AppHeader/> },
);
