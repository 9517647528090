import { createSelector, prefGettr } from './utils';
import { getGlobalLocation } from './getters';
import { getFavoritesFull, getIsAuthenticated, getisPrefsLoading, // getFavorites,
} from '.';

/**
 * Favorites.
 */

// export const favoritesIds = prefGettr('favorites', []);

// export const getPhysiciansForFavorite = createSelector([dbGettr('physicians.data'), dbGettr('favoritesDoctors')], (phs, favPhs) => phs || favPhs);

// export const getIsFavoritesNeedsUpdate = createSelector(
//     [getIsAuthenticated, getFavorites, getFavoritesFull],
//     (isAuth, favs, fullFavs) => isAuth && favs?.length && !fullFavs,
// );

// export const displayedFavorites = createSelector(
//     [favoritesIds, getPhysiciansForFavorite, joinedClinics],
//     (ids = [], physicians = {}, clinics = {}) => ids
//         .map((id) => {
//             let doc = physicians[id];
//             if (doc) {
//                 return {
//                     ...doc,
//                     type: 'physicians',
//                     routeParams: { doctorInfo: doc },
//                     routeName: 'DoctorDetails',
//                     displayName: (doc.fullName || doc.name).replace(' ', '\n'),
//                 };
//             }
//             doc = clinics[id];
//             if (doc) {
//                 return {
//                     ...doc,
//                     type: 'clinics',
//                     routeParams: { mdInfo: doc },
//                     routeName: 'MedCenterDetails',
//                     displayName: (doc.fullName || doc.name).replace(' ', '\n'),
//                 };
//             }
//             return null;
//         })
//         .filter(Boolean),
// );

export const localFavoriteClinics = createSelector(
    [getFavoritesFull, getGlobalLocation],
    (favorites = [], locationCode) => favorites.filter(({ areaCode }) => areaCode === locationCode),
);

export const isFavoritesLoading = createSelector([getIsAuthenticated, getisPrefsLoading], (isAuth, prefLoading) => isAuth && prefLoading);
