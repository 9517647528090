import { Logo, Page, React } from '../../common';

export const EpamAddRelativeHint = Page.register(() => (
    <Page name="epam-add-relative-hint">
        <Logo.Wrapper
            title="titles.cannotAddEpamProgramToRelative"
            subtitle="titles.cannotAddEpamProgramToRelativeHint"
            titleStyle={{
                marginTop: 32,
                marginBottom: 8,
                fontFamily: 'SourceSansPro-Semibold',
            }}
            wrapperStyle={{ margin: 16 }}
        />
    </Page>
));
