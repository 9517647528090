/* eslint-disable no-underscore-dangle */
import { useCallback, useReducer } from 'react';
import { connect, useSelector } from 'react-redux';
import { NativeModules, ScrollView } from 'react-native';
import {
    useFocusEffect, useIsFocused, useNavigation, useRoute,
} from '@react-navigation/native';
// import { ScrollView } from 'react-native-gesture-handler';
// eslint-disable-next-line import/no-unresolved
import actions from '../actions';
import { getErrorMessage } from '../i18n';
import { getAllowScreenshots, getOnBackground } from '../selectors';
import { getSupportBody, getSupportInfo } from '../utils/supportInfo';
import { openURL } from '../services/device';
import {
    appHeaderBgColor, COLORS, elementsStyles, pageNoteStyle, topNoteStyle,
} from './style';
import {
    ActivityIndicator, Keyboard, Platform, PureComponent, React, RefreshControl, S, SafeAreaView, StatusBar, Text, TouchableWithoutFeedback, View,
} from './react';
import { RText } from './elements';
import { Button } from './Button';
import { Icon } from './Icon';

export const forbidScreenShot = async () => {
    try {
        await NativeModules.PreventScreenshotModule.forbid();
    } catch (e) {
        //
    }
};

export const allowScreenShot = async () => {
    try {
        await NativeModules.PreventScreenshotModule.allow();
    } catch (e) {
        //
    }
};

const pageRef = React.createRef();

/* global window */
window._currentRouteName = 'Home';

const showModal = (modal, cb) => {
    if (pageRef.current) {
        pageRef.current.setState({ modal }, cb);
    }
};

const showSystemModal = (modal, cb) => {
    if (pageRef.current) {
        pageRef.current.setState({ systemModal: modal }, cb);
    }
};

const closeModal = (cb) => {
    if (pageRef.current?.state.modal) {
        pageRef.current.setState({ modal: null }, cb);
        return;
    }
    if (cb) {
        cb();
    }
};

const closeSystemModal = (cb) => {
    if (pageRef.current?.state.systemModal) {
        pageRef.current.setState({ systemModal: null }, cb);
        return;
    }
    if (cb) {
        cb();
    }
};

const NotificationButtons = ({ tryAgain, technicalMessage, showTryAgainButton = tryAgain }) => {
    const url = technicalMessage
        ? `mailto:${Object.R('supportEmail')}?subject=Me&body=${technicalMessage}${` ${getSupportInfo()}`}`
        : `mailto:${Object.R('supportEmail')}${getSupportBody()}`;
    return (
        <View
            style={{
                display: 'flex',
                justifyContent: showTryAgainButton ? 'space-around' : 'flex-start',
                paddingBottom: 15,
                flexDirection: 'row',
                backgroundColor: '#F8E6E5',
            }}>
            {showTryAgainButton && (
                <Button
                    titleStyles={{
                        color: COLORS.ERROR,
                        paddingRight: 5,
                    }}
                    capitalize
                    action={tryAgain}
                    title="titles.tryAgain"
                />
            )}
            <Button
                titleStyles={{
                    color: COLORS.ERROR,
                    paddingLeft: showTryAgainButton ? 5 : 56,
                }}
                capitalize
                action={() => openURL(url)}
                title={showTryAgainButton ? 'titles.support' : 'titles.writeToSupport'}
            />
        </View>
    );
};

const Notification = ({ notification }) => {
    const {
        level = 'error',
        code,
        message,
        userInfo,
        onSubmit,
        defaultMessage: _defaultMessage,
    } = typeof notification === 'string' ? { message: notification } : notification;
    const error = (userInfo && userInfo.error_name) || code;
    const defaultMessage = (userInfo && userInfo.NSLocalizedDescription) || message || _defaultMessage;
    const iconColor = level === 'error' ? COLORS.ERROR : COLORS.WARNING;

    return (
        <View>
            <View
                style={[
                    S.pv,
                    {
                        flexDirection: 'row',
                        paddingHorizontal: 20,
                    },
                    pageNoteStyle[level],
                ]}>
                {/* <IonicIcons name="ios-warning-outline" size={30} color={iconColor}/> */}
                <Icon.Alert size={30} color={iconColor} />
                <Text style={pageNoteStyle.text}>{getErrorMessage(error, defaultMessage)}</Text>
            </View>
            {code && <NotificationButtons tryAgain={onSubmit} technicalMessage={message} />}
        </View>
    );
};

export const TopNotification = ({
    hint = {},
    ns = '',
    containerStyle,
    onSubmit,
    submitText,
    submitContainerStyle = {},
}) => {
    const style = topNoteStyle;
    const { level = 'info', message = '', showLoading = false } = typeof hint === 'string' ? { message: hint } : hint;
    const iconColor = level === 'error' ? COLORS.ERROR : COLORS.WARNING;
    return (
        <View
            accessibilityLabel={`${ns}_top-notification`}
            style={[style[level], { borderBottomWidth: 1 }, containerStyle]}>
            <View style={[style.container, { borderBottomWidth: 0 }]}>
                <View>
                    {level === 'info' || level === 'success' ? null : (
                        <>
                            <Icon.Alert size={30} color={iconColor} style={style.icon} />
                            {/* <IonicIcons name="ios-warning-outline" size={30} color={iconColor} style={style.icon}/> */}
                        </>
                    )}
                </View>
                <View
                    style={[
                        {
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                        },
                        { paddingHorizontal: Platform.OS === 'ios' ? 0 : 4 },
                    ]}>
                    <RText
                        ns={`${ns}_top-notification_title`}
                        numberOfLines={null}
                        style={[style.notificationText, style[`${level}Text`]]}
                        id={message}
                    />
                    {showLoading ? <ActivityIndicator size={Platform.OS === 'ios' ? 1 : 25} color="#56CCF2" /> : null}
                </View>
            </View>
            {!!submitText && (
                <View style={[submitContainerStyle]}>
                    <Button
                        titleStyles={{
                            color: COLORS.ERROR,
                            paddingLeft: 56,
                        }}
                        capitalize
                        action={() => onSubmit?.()}
                        title={submitText}
                    />
                </View>
            )}
        </View>
    );
};

const Spinner = () => (Platform.OS === 'web' ? (
    <View accessibilityLabel="spinner" style={elementsStyles.spinnerWeb}>
        <ActivityIndicator size={50} color="#56CCF2" />
    </View>
) : (
    <View accessibilityLabel="spinner" style={elementsStyles.spinner}>
        <ActivityIndicator size={Platform.OS === 'ios' ? 1 : 50} color="#56CCF2" />
    </View>
));

export class Page extends PureComponent {
    state = {
        modal: null,
        systemModal: null,
        isRefreshing: false,
    };

    componentDidMount() {
        if (this.props.onDidMount) {
            this.props.onDidMount();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.onDidUpdate) {
            this.props.onDidUpdate(prevProps);
        }
    }

    componentWillUnmount() {
        if (this.state.modal) {
            this.setState({ modal: null });
        }
        if (this.state.systemModal) {
            this.setState({ systemModal: null });
        }
        if (this.props.onWillUnmount) {
            this.props.onWillUnmount();
        }
    }

    render() {
        const {
            children,
            name,
            isLoading,
            footer,
            notification,
            hint,
            noscroll,
            onPullRefresh,
            persistKeyboard,
            backgroundColor,
            hideOnBackground,
            onGetRef,
        } = this.props;

        return (
            <PageContainer backgroundColor={backgroundColor} hideOnBackground={hideOnBackground}>
                <StatusBar barStyle="light-content" backgroundColor={appHeaderBgColor} />

                {noscroll ? (
                    <>
                        {!isLoading && hint ? <TopNotification hint={hint} ns={name} /> : null}
                        <TouchableWithoutFeedback onPress={Keyboard.dismiss} style={{ flex: 1 }}>
                            <View style={{ flex: 1 }}>{children}</View>
                        </TouchableWithoutFeedback>
                    </>
                ) : (
                    <ScrollView
                        enableOnAndroid
                        enableAutomaticScroll
                        accessibilityLabel="scroll"
                        extraScrollHeight={Platform.OS === 'ios' ? 60 : 10}
                        style={{
                            height: Platform.OS === 'web' ? 0 : undefined,
                            flex: 1,
                        }}
                        contentContainerStyle={{ flexGrow: 1 }}
                        keyboardShouldPersistTaps={persistKeyboard ? 'always' : 'handled'}
                        refreshControl={
                            onPullRefresh ? (
                                <RefreshControl
                                    refreshing={this.state.isRefreshing}
                                    onRefresh={async () => {
                                        this.setState({ isRefreshing: false });
                                        await onPullRefresh();
                                    }}
                                />
                            ) : null
                        }
                        nestedScrollEnabled={true}
                        onScrollBeginDrag={() => Keyboard.dismiss()}
                        ref={onGetRef}>
                        {!isLoading && hint ? <TopNotification hint={hint} ns={name} /> : null}
                        <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                            <View style={{ flex: 1 }}>{children}</View>
                        </TouchableWithoutFeedback>
                    </ScrollView>
                )}
                {isLoading && <Spinner />}
                {footer}
                {notification && (
                    <Notification
                        notification={
                            Platform.OS === 'web'
                                ? typeof notification === 'object'
                                    ? notification
                                    : { message: notification }
                                : notification
                            // Platform.OS === 'web'
                            //     ? notification?.fullObject
                            //         ? notification
                            //         : notification?.message ?? notification
                            //     : notification
                            // notification
                        }
                    />
                )}
                <ModalContainer page={this}>{this.state.modal}</ModalContainer>
                <ModalContainer system page={this}>
                    {this.state.systemModal}
                </ModalContainer>
            </PageContainer>
        );
    }
}

const PageContainer = ({ children, backgroundColor, hideOnBackground }) => {
    const isFocused = useIsFocused();
    const route = useRoute();
    const isRunningOnBackground = useSelector(getOnBackground);
    const isScreenshotsEnabled = useSelector(getAllowScreenshots);
    if (isFocused) {
        // eslint-disable-next-line no-console
        console.log(`%c_currentRouteName${route?.name ? ` "${route.name}"` : ''}: `, 'color: #f2d70c', {
            name: route?.name,
            params: route?.params,
        });
        window._currentRouteName = route.name;
        window._currentRouteParams = route?.params;
    }

    if (Platform.OS === 'android') {
        useFocusEffect(
            React.useCallback(() => {
                if (hideOnBackground && !isScreenshotsEnabled) {
                    forbidScreenShot();
                } else {
                    !isRunningOnBackground && allowScreenShot();
                }
            }, [hideOnBackground, isScreenshotsEnabled, isRunningOnBackground]),
        );
    }
    return (
        <SafeAreaView
            accessibilityLabel={`page:${route.name}`}
            forceInset={{ top: 'never' }}
            style={{
                height: '100%',
                flexGrow: 1,
                padding: 0,
                backgroundColor: backgroundColor || '#F9F9F9',
            }}>
            <>
                {children}
                {(isRunningOnBackground && hideOnBackground && Platform.OS === 'ios' && (
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                            backgroundColor: 'white',
                        }}
                    />
                )) ||
                    null}
            </>
        </SafeAreaView>
    );
};
const ModalContainer = ({ page, children, system = false }) => {
    useFocusEffect(
        React.useCallback(() => {
            if (system) {
                if (pageRef.current?.state.systemModal) {
                    pageRef.current.setState({ systemModal: null });
                }
            } else if (pageRef.current?.state.modal) {
                pageRef.current.setState({ modal: null });
            }
            pageRef.current = page;
            return () => {
                if (pageRef.current === page) {
                    if (system) {
                        if (pageRef.current?.state.systemModal) {
                            pageRef.current.setState({ systemModal: null });
                        }
                    } else if (pageRef.current?.state.modal) {
                        pageRef.current.setState({ modal: null });
                    }
                    pageRef.current = null;
                }
            };
        }, [page]),
    );
    return children;
};

/**
 * Registers page component.
 */
// Note. To avoid any difficult to recognize problems when work with state, use one of methods: setState or setParams.
// It will save you a lot of time.
const register = (clz, props = clz.props, screenOptions = { ...clz.screenOptions, ...clz.props }) => {
    const pp = steps => o => steps.reduce((r, e) => (r ? r[e] : null), o.db);
    const boundProps = !props
        ? null
        : ($) => {
            const rr = {};
            Object.keys(props)
                .filter(key => props[key].from)
                .forEach((key) => {
                    const { from: v } = props[key];
                    const getter = typeof v === 'string' ? pp(v.split('.')) : v;
                    rr[key] = getter($);
                });
            return rr;
        };

    const Connected = connect(boundProps)(clz);

    const R = (props2) => {
        const navigation = useNavigation();
        const route = useRoute();
        const { params } = route;
        const getParam = useCallback((key, def) => (params ? params[key] : null) || def, [params]);
        const setParams = useCallback(s => navigation.setParams(s), [navigation]);
        const [state, setState] = useReducer((prev, delta) => ({ ...prev, ...delta }), {});
        navigation.getParam = getParam;

        return React.createElement(Connected, {
            ...props2,
            ...params,
            ...state,
            actions,
            navigation,
            route,
            setState,
            setParams,
            getParam,
        });
    };

    if (screenOptions) {
        R.screenOptions = screenOptions;
    }
    return R;
};

// Page API
Object.assign(Page, {
    showModal,
    closeModal,
    showSystemModal,
    closeSystemModal,
    register,
});
