import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
    BigButton,
    Button,
    ButtonsGroup,
    checkFormFields,
    dataFrom,
    Form,
    InfoList,
    Keyboard,
    Page,
    React,
    Section,
    SupportModal,
    TopNotification,
    View,
    withState,
} from '../../common';
import { formatFullDate } from '../../utils';
import { getIsPersonalized, getUserInfo } from '../../selectors';
import { Agreements } from '../../common/Agreemants';
import { Modal3 } from '../../common/Modal';
import { setFormData } from '../../actions/forms';
import { prepareNameForEdit, prepareNameForSave } from '../../utils/strings';
import { getRelativesLimitsErrorTitle } from './utils';

const convertFormData = ({
    birthday, lastName, firstName, middleName, sex, email, profileId, phones, relKind,
}) => ({
    date: formatFullDate(birthday),
    name: `${prepareNameForSave(lastName)} ${prepareNameForSave(firstName)} ${prepareNameForSave(middleName)}`,
    sex,
    email,
    profileId,
    phones,
    relKind,
});

const EditConfirmationButtons = withState(
    ({
        onConfirm,
        isUserAgreementSigned,
        isAgreementSelected = isUserAgreementSigned,
        setState,
        showNewUserAgreementModal,
    }) => (
        <View>
            {showNewUserAgreementModal
                ? !isUserAgreementSigned && (
                    <Agreements onChange={value => setState({ isAgreementSelected: value })} />
                )
                : !isUserAgreementSigned && (
                    <Agreements
                        onChange={value => setState({ isAgreementSelected: value })}
                        personalDataAgreement={false}
                    />
                )}
            <ButtonsGroup style={{ paddingHorizontal: 0 }}>
                <Button normal title="buttons.edit" action={Page.closeModal} />
                <Button primary title="buttons.confirmed" action={onConfirm} disabled={!isAgreementSelected} />
            </ButtonsGroup>
        </View>
    ),
);

const EditConfirmationButtonsForAgreement = dataFrom(
    getIsPersonalized,
    ({
        data: isPersonalized, onConfirm, consentReceived, showNewUserAgreementModal,
    }) => (
        <EditConfirmationButtons
            isUserAgreementSigned={
                // (isPersonalized === undefined && consentReceived) ||
                // (isPersonalized && consentReceived) ||
                // (isPersonalized && !showNewUserAgreementModal)
                consentReceived || !showNewUserAgreementModal
            }
            onConfirm={onConfirm}
            showNewUserAgreementModal={showNewUserAgreementModal}
        />
    ),
);

const checkModalAction = (onSend, data, converter, consentReceived, showNewUserAgreementModal) => Page.showModal(
    <Modal3
        title="titles.check_your_details"
        subtitle="titles.check_correctness"
        hideClose
        showCross
        noScroll={false}>
        <View
            style={{
                paddingBottom: 20,
                paddingHorizontal: 12,
            }}>
            <InfoList fields="userFields" data={data} />
            <EditConfirmationButtonsForAgreement
                consentReceived={consentReceived}
                showNewUserAgreementModal={showNewUserAgreementModal}
                onConfirm={() => Page.closeModal(() => onSend(converter(data)))}
            />
        </View>
    </Modal3>,
);
const useKeyboardHideCheck = (meta) => {
    const { params: { formData: lastFormData } = {} } = useRoute();
    const navigation = useNavigation();
    useEffect(() => {
        const onKeyboardHide = () => {
            // handler references values from when it was set - hence need to fetch fresh via 'getParam'
            if (lastFormData) {
                checkFormFields(lastFormData, meta, delta => navigation.setParams({ ...delta }));
            }
        };
        const keyboardListener = Keyboard.addListener('keyboardDidHide', onKeyboardHide);
        return () => {
            if (keyboardListener) {
                keyboardListener.remove();
            }
        };
    }, [meta, lastFormData]);
};
export const EditPage = (
    onSend,
    meta,
    editProfileHint = 'hints.edit_profile',
    formTitle,
    { withPage = true, buttonTitle } = {
        withPage: true,
        buttonTitle: undefined,
    },
) => {
    const sectionProps = formTitle ? { title: formTitle } : {};
    const hints = {};
    return ({ navigation, data, showNewUserAgreementModal }) => {
        const { me, isPerson } = useSelector(state => state?.user?.info) || {};
        const dispatch = useDispatch();
        const route = useRoute();
        const backObject = {
            back: () => {
                navigation.goBack();
                return true;
            },
        };
        const formDataHeader = {
            EditRelative: backObject,
            EditChild: backObject,
            AddAdultRelative: backObject,
            EditAdultRelative: backObject,
        };

        useEffect(() => {
            dispatch(setFormData(formDataHeader));
        }, []);
        const {
            params: {
                fromScreen = 'Account',
                isFormValid,
                errors,
                relative,
                spouseError,
                parentError,
                data: formData = data ||
                    (relative && {
                        birthday:
                            Platform.OS === 'web'
                                ? relative.birthday
                                : relative?.birthday
                                    ? new Date(relative.birthday)
                                    : undefined,
                        firstName: prepareNameForEdit(relative.firstName),
                        lastName: prepareNameForEdit(relative.surname),
                        middleName: prepareNameForEdit(relative.middleName),
                        sex: relative.sex,
                        profileId: relative.profileId,
                        phones: relative.phones,
                        relKind: relative.relKind,
                    }),
            } = {},
        } = useRoute();
        const [error, setError] = useState();
        let errorMessage;
        if (spouseError || parentError) {
            errorMessage = getRelativesLimitsErrorTitle({
                spouseError,
                parentError,
                relKind: formData?.relKind,
            });
        }
        useKeyboardHideCheck(meta);
        const user = useSelector(getUserInfo);
        const footer = (
            <Section>
                <BigButton
                    title={buttonTitle || 'buttons.further'}
                    disabled={!isFormValid}
                    action={() => {
                        if (errorMessage) {
                            SupportModal(errorMessage);
                        } else {
                            checkModalAction(
                                onSend?.(
                                    fromScreen,
                                    navigation,
                                    Boolean(relative),
                                    setError,
                                    showNewUserAgreementModal ? !me?.consentReceived : false,
                                    route?.params?.params,
                                    user,
                                ),
                                {
                                    ...formData,
                                    isPerson,
                                },
                                convertFormData,
                                !!me?.consentReceived,
                                showNewUserAgreementModal,
                            );
                        }
                    }}
                />
            </Section>
        );
        const child = (
            <>
                <TopNotification hint={editProfileHint} />
                <Section
                    {...sectionProps}
                    textStyles={{
                        color: '#575757',
                        paddingLeft: 4,
                    }}>
                    <Form
                        data={formData}
                        meta={meta}
                        errors={errors}
                        checkValidation={delta => navigation.setParams({ ...delta })}
                        onChange={delta => navigation.setParams({
                            ...delta,
                            formData: delta.data,
                        })
                        }
                        hints={hints}
                        filteredFields={relative && ['relKindWithOtherInt']}
                        readOnlyFields={relative && ['phones', 'relKind']}
                    />
                </Section>
            </>
        );
        const result = withPage ? (
            <Page name="edit-account" notification={error} footer={footer}>
                {child}
            </Page>
        ) : (
            <>
                {child}
                {footer}
            </>
        );
        return Platform.OS === 'ios' ? (
            <KeyboardAvoidingView behavior="padding" keyboardVerticalOffset={20}>
                {result}
            </KeyboardAvoidingView>
        ) : (
            result
        );
    };
};
