// noinspection JSUnusedGlobalSymbols

import { I18nManager } from 'react-native';
import I18n from 'i18n-js';
// import { LocaleConfig } from 'react-native-calendars';
import { formatString, head } from '../utils';
import { DEFAULT_LOCALE, DEFAULT_LOCATION } from '../config';
import { storage } from '../services/localstorage';

function expand(obj, id, value) {
    let curStep = obj;
    const keys = id.split('.');
    if (keys.length === 1) {
        curStep[keys] = value;
    } else {
        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            if (!curStep[key]) {
                curStep[key] = {};
            }
            curStep = curStep[key];
        }
        const finalStep = keys[keys.length - 1];
        curStep[finalStep] = value;
    }
    return obj;
}

let locations = [];
let enums = {};
let fields = {};
let formFields = {};
let errors = {};
let synonyms = {};
let globalLocations = null;
/**
 * Localization and I18n support.
 *
 * @see https://github.com/fnando/i18n-js#setting-up
 */

// Should the app fallback to English if user locale doesn't exists
export const getLanguage = () => head(I18n.locale, '-');
export const updateLocalization = (localization) => {
    I18n.translations = {
        [I18n.locale]: localization.localization.reduce(
            (acc, cur) => expand(acc, cur.id, cur[head(I18n.locale, '-')]),
            {},
        ),
    };

    enums = Object.keys(localization.enums).reduce((acc, cur) => {
        acc[cur] = localization.enums[cur].map(item => Object.assign(item, { name: Object.R(`${cur}.${item.id}`) }));
        return acc;
    }, {});

    fields = Object.keys(localization.fields).reduce((acc, cur) => {
        acc[cur] = localization.fields[cur].map(item => ({
            ...item,
            title: Object.R(`${cur}.${item.id}`),
        }));
        return acc;
    }, {});

    formFields = Object.keys(localization.fields).reduce((acc, cur) => {
        acc[cur] = localization.fields[cur].map(item => ({
            ...item,
            title: Object.R(`${cur}.${item.id}`),
            placeholder: Object.R(`${cur}_${item.id}.placeholder`),
            error: Object.R(`${cur}_${item.id}.error`),
        }));
        return acc;
    }, {});

    errors = Object.keys(localization.errors).reduce((acc, cur) => {
        acc[localization.errors[cur].code] = Object.R(`error.${localization.errors[cur].code}`);
        return acc;
    }, {});

    synonyms = Object.keys(localization.synonyms).reduce((acc, cur) => {
        acc[localization.synonyms[cur].name] = localization.synonyms[cur].synonyms;
        return acc;
    }, {});

    locations = localization.locations.map(location => ({
        ...location,
        address: I18n.t(location.id),
    }));
};
/* eslint-disable max-statements */
export const setLocale = (locale = DEFAULT_LOCALE) => {
    // Allow RTL alignment in RTL languages
    I18nManager.allowRTL(false);
    // react-native-calendars:
    // Object.assign(LocaleConfig.locales, dateLocales);
    // LocaleConfig.defaultLocale = locale;

    I18n.missingBehaviour = 'guess';
    I18n.fallbacks = true;
    I18n.defaultLocale = DEFAULT_LOCALE;
    I18n.locale = locale;

    I18n.pluralization[locale] = function plur(count) {
        const c10 = count % 10;
        const c100 = count % 100;
        if (count === 0) {
            return ['zero'];
        }
        if (Number.isNaN(count)) {
            return ['NaN'];
        }
        if (c10 === 1 && c100 !== 11) {
            return ['one'];
        }
        if ([2, 3, 4].indexOf(c10) >= 0 && [12, 13, 14].indexOf(c100) < 0) {
            return ['few'];
        }
        if (c10 === 0 || [5, 6, 7, 8, 9].indexOf(c10) >= 0 || [11, 12, 13, 14].indexOf(c100) >= 0) {
            return ['many'];
        }
        return ['other'];
    };

    // eslint-disable-next-line global-require
    const localizationFile = require('../localization');
    const localization = localizationFile.default;
    const lastMaxLocaleUpdate = localizationFile.lastMax;

    storage.get('settings').then((res) => {
        if (res && res?.data && res?.lastMax > new Date(lastMaxLocaleUpdate).valueOf()) {
            updateLocalization(Object.values(res.data)[0]?.settingsData);
        }
    });
    updateLocalization(localization);
    return I18n.locale;
};

String.plural = (key, countP) => {
    const count = countP === null ? NaN : countP;
    return `${I18n.t(`${key}*`, { count }) || key}`;
};

// Avoids need to import i18n
Object.R = (key, params, defaultAsKey = true) => {
    const r = I18n.t(`${key}`, { defaultValue: defaultAsKey ? key : '' });
    return params ? formatString(r, params) : r;
};

String.i18n = (id, count, params) => (typeof count === 'undefined' ? Object.R(`${id}`, params) : String.plural(`${id}`, count));

export function getEnums(obj, flt) {
    const list = enums[obj] || [];
    return flt ? list.filter(flt) : list;
}

// noinspection JSUnusedGlobalSymbols
export function getTabletkaLocations() {
    return locations.map(({
        tabletkaCodev3, id, address, code, latitude, longitude,
    }) => ({
        id,
        name: Object.R(`locations.${code.toString().replace('.', '/')}`),
        code: parseFloat(tabletkaCodev3),
        address,
        latitude,
        longitude,
    }));
}

export function getGlobalLocations() {
    if (!globalLocations) {
        globalLocations = locations.map(({
            id, code, address, banners,
        }) => ({
            id,
            code,
            address,
            banners,
            name: Object.R(`locations.${code.toString().replace('.', '/')}`),
        }));
        globalLocations.pop();
    }
    return globalLocations;
}

export function getLocationNameForCity(cityName) {
    const location = locations.find(({ id }) => id === cityName);
    return location ? Object.R(`locations.${location.code}`) : cityName;
}

export function resolveTabletkaLoc(global = DEFAULT_LOCATION) {
    return (locations.find(({ code }) => code === global) || {}).tabletkaCodev3 || 1001;
}

export function getFormFields(obj) {
    return formFields[obj];
}

export function resolveEnumName(obj, key, def) {
    return (getEnums(obj).find(({ id }) => id === key) || { name: Object.R(def) }).name;
}

export function getFields(obj) {
    return fields[obj];
}

export function getErrorMessage(obj, def) {
    // eslint-disable-next-line no-nested-ternary
    const code =
        // eslint-disable-next-line no-nested-ternary
        typeof def === 'object'
            ? errors[def?.code]
            : typeof def === 'undefined'
                ? errors['auth/network-request-failed']
                : def;
    return errors[obj] || code || '';
}

export function synonymize(text) {
    return synonyms[text] || '';
}
