export default {
    4: {
        _lastModifiedAt: 1538484101692,
        anonFeedbackCount: 0,
        feedbackCount: 6,
        _id: '4',
        _coll: 'clinics_feedback_sum',
    },
    6: {
        _lastModifiedAt: 1538484101692,
        anonFeedbackCount: 0,
        feedbackCount: 2,
        _id: '6',
        _coll: 'clinics_feedback_sum',
    },
    4782: {
        _lastModifiedAt: 1538484101692,
        anonFeedbackCount: 7,
        feedbackCount: 13,
        _id: '4782',
        _coll: 'clinics_feedback_sum',
    },
    null: {
        _lastModifiedAt: 1538484101692,
        anonFeedbackCount: 6,
        feedbackCount: 35,
        _id: 'null',
        _coll: 'clinics_feedback_sum',
    },
    '5b3f5fa4529813245': {
        _lastModifiedAt: 1540881266226,
        anonFeedbackCount: 12,
        feedbackCount: 27,
        _id: '5b3f5fa4529813245',
        _coll: 'clinics_feedback_sum',
    },
    '5b4c5e876e354dbfb': {
        _lastModifiedAt: 1541066967691,
        anonFeedbackCount: 2,
        feedbackCount: 12,
        _id: '5b4c5e876e354dbfb',
        _coll: 'clinics_feedback_sum',
    },
    '5b4c5eceaf981f5f0': {
        _lastModifiedAt: 1541431563330,
        anonFeedbackCount: 4,
        feedbackCount: 11,
        _id: '5b4c5eceaf981f5f0',
        _coll: 'clinics_feedback_sum',
    },
    '5b4c5eb620ae653c1': {
        _lastModifiedAt: 1541486007416,
        anonFeedbackCount: 8,
        feedbackCount: 18,
        _id: '5b4c5eb620ae653c1',
        _coll: 'clinics_feedback_sum',
    },
    '5b3f5fa7eaf998e58': {
        _lastModifiedAt: 1541761216132,
        anonFeedbackCount: 51,
        feedbackCount: 87,
        _id: '5b3f5fa7eaf998e58',
        _coll: 'clinics_feedback_sum',
    },
    '5b3f5faa72b310212': {
        _lastModifiedAt: 1541517144375,
        anonFeedbackCount: 15,
        feedbackCount: 30,
        _id: '5b3f5faa72b310212',
        _coll: 'clinics_feedback_sum',
    },
    '5b46096c302c5a36f': {
        _lastModifiedAt: 1541667409541,
        anonFeedbackCount: 8,
        feedbackCount: 22,
        _id: '5b46096c302c5a36f',
        _coll: 'clinics_feedback_sum',
    },
};