import { lastModified } from '../const.stub';

export default {
    '5b3f5fa83884cda40': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 5, _id: '5b3f5fa83884cda40', _coll: 'physicians_feedback_sum' },
    '5b3f5fd716a7c57fe': { _lastModifiedAt: lastModified, anonFeedbackCount: 1, feedbackCount: 3, _id: '5b3f5fd716a7c57fe', _coll: 'physicians_feedback_sum' },
    '5b3f5fe0426103ddd': { _lastModifiedAt: lastModified, anonFeedbackCount: 4, feedbackCount: 8, _id: '5b3f5fe0426103ddd', _coll: 'physicians_feedback_sum' },
    '5b3f5fe0e8f53bf04': { _lastModifiedAt: lastModified, anonFeedbackCount: 3, feedbackCount: 4, _id: '5b3f5fe0e8f53bf04', _coll: 'physicians_feedback_sum' },
    '5b3f5ff389bb91501': { _lastModifiedAt: lastModified, anonFeedbackCount: 1, feedbackCount: 4, _id: '5b3f5ff389bb91501', _coll: 'physicians_feedback_sum' },
    '5b3f6010d6ca2b335': { _lastModifiedAt: lastModified, anonFeedbackCount: 2, feedbackCount: 3, _id: '5b3f6010d6ca2b335', _coll: 'physicians_feedback_sum' },
    '5b3f601261996ab60': { _lastModifiedAt: lastModified, anonFeedbackCount: 7, feedbackCount: 12, _id: '5b3f601261996ab60', _coll: 'physicians_feedback_sum' },
    '5b3f6018e26deeb79': { _lastModifiedAt: lastModified, anonFeedbackCount: 1, feedbackCount: 2, _id: '5b3f6018e26deeb79', _coll: 'physicians_feedback_sum' },
    '5b3f60257ef411621': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 3, _id: '5b3f60257ef411621', _coll: 'physicians_feedback_sum' },
    '5b3f6038003a13995': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 1, _id: '5b3f6038003a13995', _coll: 'physicians_feedback_sum' },
    '5b3f603f6af4c321b': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 3, _id: '5b3f603f6af4c321b', _coll: 'physicians_feedback_sum' },
    '5b3f604e2123e147a': { _lastModifiedAt: lastModified, anonFeedbackCount: 3, feedbackCount: 7, _id: '5b3f604e2123e147a', _coll: 'physicians_feedback_sum' },
    '5b3f606537ffe18fc': { _lastModifiedAt: lastModified, anonFeedbackCount: 2, feedbackCount: 3, _id: '5b3f606537ffe18fc', _coll: 'physicians_feedback_sum' },
    '5b3f6078917c09059': { _lastModifiedAt: lastModified, anonFeedbackCount: 1, feedbackCount: 3, _id: '5b3f6078917c09059', _coll: 'physicians_feedback_sum' },
    '5b3f6080e854956b0': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 18, _id: '5b3f6080e854956b0', _coll: 'physicians_feedback_sum' },
    '5b3f60886e3d3d787': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 3, _id: '5b3f60886e3d3d787', _coll: 'physicians_feedback_sum' },
    '5b3f608fa420a29cb': { _lastModifiedAt: lastModified, anonFeedbackCount: 1, feedbackCount: 3, _id: '5b3f608fa420a29cb', _coll: 'physicians_feedback_sum' },
    '5b4c7ecd2fc4f8d4f': { _lastModifiedAt: lastModified, anonFeedbackCount: 17, feedbackCount: 30, _id: '5b4c7ecd2fc4f8d4f', _coll: 'physicians_feedback_sum' },
    '5b4c8218000f7b4a1': { _lastModifiedAt: lastModified, anonFeedbackCount: 8, feedbackCount: 23, _id: '5b4c8218000f7b4a1', _coll: 'physicians_feedback_sum' },
    '5b4c82ef8bec9349f': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 3, _id: '5b4c82ef8bec9349f', _coll: 'physicians_feedback_sum' },
    '5b4c8401a8a7c92ad': { _lastModifiedAt: lastModified, anonFeedbackCount: 7, feedbackCount: 9, _id: '5b4c8401a8a7c92ad', _coll: 'physicians_feedback_sum' },
    '5b4d9f59b8c3f7c34': { _lastModifiedAt: lastModified, anonFeedbackCount: 2, feedbackCount: 5, _id: '5b4d9f59b8c3f7c34', _coll: 'physicians_feedback_sum' },
    '5b4da140771b2fa9b': { _lastModifiedAt: lastModified, anonFeedbackCount: 5, feedbackCount: 12, _id: '5b4da140771b2fa9b', _coll: 'physicians_feedback_sum' },
    '5b3f60091b81e8ec5': { _lastModifiedAt: lastModified, anonFeedbackCount: 0, feedbackCount: 3, _id: '5b3f60091b81e8ec5', _coll: 'physicians_feedback_sum' },
    '5b3f5feb8a89854d5': { _lastModifiedAt: lastModified, anonFeedbackCount: 1, feedbackCount: 3, _id: '5b3f5feb8a89854d5', _coll: 'physicians_feedback_sum' },
    '5b3f603d7c6af74f2': { _lastModifiedAt: lastModified, anonFeedbackCount: 1, feedbackCount: 2, _id: '5b3f603d7c6af74f2', _coll: 'physicians_feedback_sum' },
    '5b3f600e1c88c6d2f': { _lastModifiedAt: lastModified, anonFeedbackCount: 2, feedbackCount: 5, _id: '5b3f600e1c88c6d2f', _coll: 'physicians_feedback_sum' },
    '5b4da2ba80045fb6d': { _lastModifiedAt: lastModified, anonFeedbackCount: 4, feedbackCount: 6, _id: '5b4da2ba80045fb6d', _coll: 'physicians_feedback_sum' },
};