import EStyleSheet from 'react-native-extended-stylesheet';

const avatarSize = 100;

export const account = EStyleSheet.create({
    save: { backgroundColor: '#B6BEDA' },
    cancel: { backgroundColor: '$emerald' },
    button: {
        flex: 1,
        borderRadius: 3,
        alignItems: 'center',
        paddingVertical: 11,
    },
    text: { color: '$white' },
    formHandlersBlock: {
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    // --details
    container: {
        position: 'relative',
        alignItems: 'center',
    },
    avatar: {
        marginTop: 10,
        width: avatarSize,
        height: avatarSize,
        borderRadius: avatarSize / 2,
        overflow: 'hidden',
    },
    avatarImage: {
        width: avatarSize,
        height: avatarSize,
    },
    rightItem: {
        position: 'absolute',
        top: 0,
        right: 10,
    },
    imageEmpty: { backgroundColor: '$gray' },
    title2: {
        marginTop: 16,
        fontSize: 18,
        fontWeight: '600',
        color: '#555555',
        textAlign: 'center',
    },
    subTitle: {
        marginTop: 8,
        color: '#555555',
    },
});


// noinspection JSUnusedGlobalSymbols
export const imageViewer = EStyleSheet.create({
    view: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'stretch',
        backgroundColor: 'black',
        zIndex: 100,
    },
    image: {
        flex: 1,
        resizeMode: 'contain',
    },
    deleteButton: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        borderRadius: 30,
        backgroundColor: 'white',
        zIndex: 10010,
    },
    deleteImage: {
        marginHorizontal: 10,
        marginVertical: 10,
        color: 'black',
    },
});

export const fields = EStyleSheet.create({
    epamProgramField: {
        marginTop: 0,
        marginBottom: 8,
        borderColor: '#E0E0E0',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        backgroundColor: '#fff',
        paddingHorizontal: 16,
        paddingVertical: 20,
        flexWrap: 'nowrap',
    },
});

// noinspection JSUnusedGlobalSymbols
export const myCard = EStyleSheet.create({
    listHeading: {
        marginTop: 20,
        paddingLeft: 8,
        paddingBottom: 12,
        fontSize: 12,
        fontWeight: '600',
        color: '#787878',
    },
    container: {
        marginTop: 20,
        paddingLeft: 12,
        paddingRight: 13,
    },
    emptyListText: {
        paddingLeft: 20,
        paddingRight: 10,
        color: '$lightGray',
        lineHeight: 20,
    },
    listItem: {
        position: 'relative',
        width: 117,
        marginRight: 4,
        alignItems: 'center',
        paddingTop: 12,
        paddingBottom: 16,
        paddingHorizontal: 13,
        borderWidth: 1,
        borderColor: '#E9E9E9',
        backgroundColor: '$white',
    },
    titleContainer: { marginTop: 12 },
    listItemTitle: {
        textAlign: 'center',
        fontFamily: '$secondaryTextFontFamily',
        fontSize: 12,
        color: '#787878',
    },
    indicator: {
        position: 'absolute',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        top: -4,
        left: -10,
    },
});

export const home = EStyleSheet.create({
    navigationItem: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$white',
        borderWidth: 1,
        borderColor: '#E9E9E9',
        borderRadius: 3,
        paddingTop: 18,
    },
    navigationContainer: {
        paddingTop: 17,
        paddingBottom: 7,
        alignItems: 'center',
        marginTop: 0,
        marginRight: 0,
        marginLeft: 0,
    },
    image: {
        width: 55,
        height: 56,
        // marginBottom: 25,
    },
    popularButonText: {
        fontSize: 14,
        color: '$white',
    },
    popularHeading: {
        color: '#555555',
        fontSize: 12,
        paddingBottom: 10,
        fontWeight: '600',
    },
    popularButton: {
        marginTop: 8,
        marginRight: 10,
        borderRadius: 20,
        paddingVertical: 7,
        paddingHorizontal: 20,
        backgroundColor: '#B6BEDA',
    },
});

export const relativeHintStyles = EStyleSheet.create({
    hintItem: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        paddingBottom: 12,
    },
    hintText: { flex: 1 },
    defaultAvatar: { marginRight: 12 },
    defaultAvatarImg: {
        width: 50,
        height: 50,
        marginRight: 12,
    },
});

// noinspection JSUnusedGlobalSymbols
export const footerStyles = EStyleSheet.create({
    footer: {
        position: 'absolute',
        bottom: 20,
        right: 20,
    },
    updateIconStyle: { marginBottom: 12 },
});
export const reltivesStyles = EStyleSheet.create({
    footer: {
        position: 'absolute',
        bottom: 20,
        right: 20,
    },
    footerButtonStyle: { marginBottom: 12 },
    relativesSection: { marginBottom: 180 },
    relatives: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
