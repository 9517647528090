import { capitalize } from '../../utils';
import { averageLocationCountry, pointInCountry } from '../../mapConfig';

export const getNextWorkingDay = (timetable, currentDay) => {
    for (let i = currentDay + 2; i < 7; i++) {
        if (timetable[i]) {
            return i;
        }
    }
    for (let i = 0; i < currentDay; i++) {
        if (timetable[i]) {
            return i;
        }
    }
    return null;
};

// eslint-disable-next-line max-statements
export const getWorkTimeString = (timetable, currentTimetable = null) => {
    if (
        currentTimetable === '1000-1000' ||
        currentTimetable === null ||
        currentTimetable === '1000' ||
        currentTimetable === undefined
    ) {
        return `${Object.R('titles.dayOff')}`;
    }
    if (currentTimetable === '08.00-08.00') {
        return `${Object.R('titles.allDay')}`;
    }
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const currentHours = currentDate.getHours();
    const currentMinutes = currentDate.getMinutes();
    if (currentTimetable) {
        const workingTimeAsString = currentTimetable.split(/[-.]+/);
        const workingTime = workingTimeAsString.map(e => Number(e));
        if (currentHours < workingTime[0] || (currentHours === workingTime[0] && currentMinutes < workingTime[1])) {
            return Object.R('titles.toFromFormat', {
                day: capitalize(Object.R('titles.today')),
                firstTime: `${workingTimeAsString[0]}:${workingTimeAsString[1]}`,
                secondTime: `${workingTimeAsString[2]}:${workingTimeAsString[3]}`,
            });
        }
        if (
            (currentHours === workingTime[0] && currentMinutes >= workingTime[1]) ||
            (currentHours > workingTime[0] &&
                (currentHours < workingTime[2] || (currentHours === workingTime[2] && currentMinutes < workingTime[3])))
        ) {
            return Object.R('titles.todayToFormat', { time: `${workingTimeAsString[2]}:${workingTimeAsString[3]}` });
        }
    }
    if ((currentDay + 1 < 7 && timetable[currentDay + 1]) || (currentDay + 1 === 7 && timetable[0])) {
        const workingTime = (timetable[currentDay + 1] || timetable[0]).split(/[-.]+/);
        return Object.R('titles.toFromFormat', {
            day: capitalize(Object.R('titles.tomorrow')),
            firstTime: `${workingTime[0]}:${workingTime[1]}`,
            secondTime: `${workingTime[2]}:${workingTime[3]}`,
        });
    }
    const nextWorkingDay = getNextWorkingDay(timetable, currentDay);
    if (nextWorkingDay !== null) {
        const workingTime = timetable[nextWorkingDay].split(/[-.]+/);
        const days = [
            Object.R('titles.sunday'),
            Object.R('titles.monday'),
            Object.R('titles.tuesday'),
            Object.R('titles.wednesday'),
            Object.R('titles.thursday'),
            Object.R('titles.friday'),
            Object.R('titles.saturday'),
        ];
        return Object.R('titles.toFromFormat', {
            day: days[nextWorkingDay],
            firstTime: `${workingTime[0]}:${workingTime[1]}`,
            secondTime: `${workingTime[2]}:${workingTime[3]}`,
        });
    }
    return Object.R('titles.no_schedule');
};

export const getTimetableString = (timetable, first, second) => {
    let correctSecond = null;
    if (second === 0) {
        correctSecond = 7;
    }
    const workingTimeAsString = timetable[first] ? timetable[first].split(/[-.]+/) : null;
    const shortDays = [
        Object.R('titles.shortSunday'),
        Object.R('titles.shortMonday'),
        Object.R('titles.shortTuesday'),
        Object.R('titles.shortWednesday'),
        Object.R('titles.shortThursday'),
        Object.R('titles.shortFriday'),
        Object.R('titles.shortSaturday'),
    ];
    if (first === (correctSecond || second) - 1) {
        return workingTimeAsString
            ? Object.R('titles.toFromFormat', {
                day: `${shortDays[first]}`,
                firstTime: `${workingTimeAsString[0]}:${workingTimeAsString[1]}`,
                secondTime: `${workingTimeAsString[2]}:${workingTimeAsString[3]}`,
            })
            : null;
    }
    return workingTimeAsString
        ? Object.R('titles.toFromFormat', {
            day: `${shortDays[first]}-${shortDays[(correctSecond || second) - 1]}`,
            firstTime: `${workingTimeAsString[0]}:${workingTimeAsString[1]}`,
            secondTime: `${workingTimeAsString[2]}:${workingTimeAsString[3]}`,
        })
        : null;
};

export const getTimetable = (timetable) => {
    if (timetable.some(el => el !== null)) {
        const shortTimetable = [1];
        timetable.slice(1).reduce((pr, cur, ind) => {
            if (cur !== pr) {
                shortTimetable.push(ind + 1);
            }
            return cur;
        }, timetable[1]);

        if (shortTimetable && timetable[shortTimetable[shortTimetable.length - 1]] === timetable[0]) {
            shortTimetable.push(1);
        } else if (shortTimetable && timetable[shortTimetable[shortTimetable.length - 1]] !== timetable[0]) {
            shortTimetable.push(0);
        }
        const shortTimetableStrings = [];
        for (let i = 0; i < shortTimetable.length - 1; i++) {
            shortTimetableStrings.push(getTimetableString(timetable, shortTimetable[i], shortTimetable[i + 1]));
        }
        if (shortTimetable[shortTimetable.length - 1] === 0) {
            shortTimetableStrings.push(getTimetableString(timetable, 0, 1));
        }
        return shortTimetableStrings;
    }
    return [Object.R('titles.no_schedule')];
};

export const averageLocation = (locations) => {
    if (!locations.length) {
        return null;
    }
    let count = 0;
    let sumLat = 0;
    let sumLng = 0;
    let minLat = 180;
    let minLng = 180;
    let maxLat = -180;
    let maxLng = -180;
    const delta = 0.01;
    locations.forEach(({ latitude, longitude }) => {
        const lat = latitude ?? 0;
        const lon = longitude ?? 0;
        if (locations?.length > 1 && (lat === 0 || lon === 0)) {
            return;
        }
        if (locations?.length > 1 && !pointInCountry(lat, lon)) {
            return;
        }
        count += 1;
        sumLat += latitude;
        sumLng += longitude;
        minLat = Math.min(minLat, latitude);
        minLng = Math.min(minLng, longitude);
        maxLat = Math.max(maxLat, latitude);
        maxLng = Math.max(maxLng, longitude);
    });
    minLat -= delta;
    minLng -= delta;
    maxLat += delta;
    maxLng += delta;
    if (!count) {
        return averageLocationCountry();
    }
    const AvgLat = sumLat / count;
    const AvgLng = sumLng / count;
    const Delta =
        2 * Math.max(AvgLat - minLat, maxLat - AvgLat, AvgLng - minLng, maxLng - AvgLng) ||
        Math.exp(Math.log(360) - 15 * Math.LN2);
    // console.log('AvgLat', AvgLat, 'AvgLng', AvgLng, 'Delta', Delta);
    return {
        latitude: AvgLat,
        longitude: AvgLng,
        latitudeDelta: Delta,
        longitudeDelta: Delta,
    };
};

export const hasClinicsCoords = list => list.some(({ latitude, longitude }) => (!!latitude && latitude !== '0') || (!!longitude && longitude !== '0'));
