import { Platform } from 'react-native';
import {
    InfoList, Link, Page, React, RText, S, Section, Subtitle, View,
} from '../../common';
import { getUserInfo } from '../../selectors';
import { navigate } from '../../actions';
import { MY_API_HOST } from '../../config';

export const EpamProgram = Page.register(
    ({
        program,
        program: {
            programId = program?.id,
            coverer,
            declineReasonCode,
        },
    }) => (
        <Page
            name="epam-program"
            persistKeyboard
        >
            <Section title="titles.general_info">
                <InfoList
                    fields="epamProgramFields"
                    data={programId ? program : { coverer }}
                />
            </Section>
            <Section style={S.mh}>
                {
                    !programId && declineReasonCode
                        ? (
                            <Subtitle
                                style={{
                                    marginTop: 12,
                                    fontSize: 16,
                                }}
                                numberOfLines={1}
                                color="#DF655C"
                                id={`titles.declineReasonCode_${declineReasonCode}`}
                            />
                        ) : null
                }
                {
                    programId
                        ? <View>
                            <RText id="hint.follow_program_details_link"/>
                            <Link
                                id="link.program_details_link"
                                underline
                                href={
                                    Platform.OS === 'web'
                                        ? `https://${MY_API_HOST}/files/epam-program/index.html#${programId}`
                                        : () => {
                                            navigate('WebPage', {
                                                title: Object.R(`coverer.${coverer}`),
                                                source: { uri: `https://${MY_API_HOST}/files/epam-program/index.html#${programId}` },
                                            });
                                        }
                                }
                                style={{ marginVertical: 12 }}
                            >link.program_details
                            </Link>
                        </View> : null
                }
            </Section>
        </Page>
    ),
    { userInfo: { from: getUserInfo } },
);
