import { today, tomorrow } from '../const.stub';

export default {
    '5b4da073022f016dd': {
        _id: '5b4da073022f016dd',
        _lastModifiedAt: 1541496726058,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b46096c302c5a36f',
            nearest_available_date: today,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b4da140771b2fa9b': {
        _id: '5b4da140771b2fa9b',
        _lastModifiedAt: 1541496726231,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b46096c302c5a36f',
            nearest_available_date: tomorrow,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b4c83afdf39e46c1': {
        _id: '5b4c83afdf39e46c1',
        _lastModifiedAt: 1541691107419,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b46096c302c5a36f',
            nearest_available_date: today,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5bc0559dc9cbea1bb': {
        _id: '5bc0559dc9cbea1bb',
        _lastModifiedAt: 1541755916374,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b46096c302c5a36f',
            nearest_available_date: tomorrow,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b51794c7abf0bb3b': {
        _id: '5b51794c7abf0bb3b',
        _lastModifiedAt: 1541763115963,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b46096c302c5a36f',
            nearest_available_date: tomorrow,
        }, {
            clinic_id: '5b3f5fa7eaf998e58',
            nearest_available_date: tomorrow,
        }, {
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: tomorrow,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5b517954c59cfadcc': {
        _id: '5b517954c59cfadcc',
        _lastModifiedAt: 1541763116208,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5faa72b310212',
            nearest_available_date: today,
        }, {
            clinic_id: '5b4c5eb620ae653c1',
            nearest_available_date: today,
        }, {
            clinic_id: '5b3f5fa4529813245',
            nearest_available_date: today,
        }],
        _coll: 'physicians_timetable_sum',
    },
    '5c2ee3d116817043c': {
        _id: '5c2ee3d116817043c',
        _lastModifiedAt: 1541763115963,
        _lastModifiedBy: null,
        nearest_available_dates: [{
            clinic_id: '5b3f5cf17c5d77cc4',
            nearest_available_date: tomorrow,
        }],
        _coll: 'physicians_timetable_sum',
    },
};
