export const MEM_STORAGE_SIZE = 1; // MB
const DEBUG = true;

class MemoryStorage {
    static instance;
    size = 0;
    length = 0;
    data;
    currentOrder = 0;
    debug = false;

    constructor(size, debug = false) {
        if (MemoryStorage.instance) {
            // eslint-disable-next-line no-constructor-return
            return MemoryStorage.instance;
        }
        this.data = new Map();
        this.size = size;
        this.currentOrder = 0;
        this.length = 0;
        MemoryStorage.instance = this;
        this.debug = debug;
    }

    /**
     * @param s Size in Mb
     */
    setSize(s) {
        const bytes = s * 1024 * 1024;
        this.debugMessage(`Set new size ${s}Mb`, bytes);
        this.size = bytes;
    }

    debugMessage(s, v) {
        if (!this.debug) {
            return;
        }
        // eslint-disable-next-line no-console
        console.log(`%c ${s}`, 'background: #333; color: yellow;', v);
    }

    add(id, info, size) {
        if (size > this.size) {
            this.debugMessage('Item too big', {
                id,
                info,
                size,
                MEM_STORAGE_SIZE,
            });
            return false;
        }
        const idString = this.getId(id);
        this.data.set(idString, {
            info,
            size,
            order: ++this.currentOrder,
        });
        this.length += size;
        this.debugMessage('Add item ', {
            id,
            info,
            size,
            totalLength: this.length,
        });
        for (let i = this.data.size; i > 0; i--) {
            const currentSize = this.currentSize();
            if (currentSize > this.size) {
                const oldest = this.getOldest();
                this.remove(oldest);
            } else {
                break;
            }
        }
        return true;
    }

    currentSize() {
        return Array.from(this.data, ([, value]) => value)
            .reduce((prev, current) => prev + (current?.size ?? 0), 0);
    }

    calculateLength() {
        this.length = this.currentSize();
        return this.length;
    }

    getOldest() {
        let oldest;
        let oldestOrder = -1;
        this.data.forEach((value, key) => {
            if (!oldest) {
                oldest = key;
                oldestOrder = value.order;
            } else if (oldestOrder > value.order) {
                oldestOrder = value.order;
                oldest = key;
            }
        });
        this.debugMessage('Get oldest item', {
            oldestOrder,
            oldest,
        });
        return oldest;
    }

    remove(id) {
        const result = this.data.delete(this.getId(id));
        const newLength = this.calculateLength();
        this.debugMessage('Remove item by id', {
            id,
            result,
            newLength,
        });
        return result;
    }

    get(id) {
        const info = this.data.get(this.getId(id));
        this.debugMessage(`Get by id`, {
            id,
            info,
        });
        return info;
    }

    // eslint-disable-next-line class-methods-use-this
    getId(id) {
        if (typeof id === 'object') {
            return JSON.stringify(id);
        }
        return id;
    }

    decodeId(id) {
        try {
            if (typeof id === 'string') {
                return JSON.parse(id);
            }
        } catch (e) {
            this.debugMessage('Error decode id', { id, e });
        }
        return id;
    }

    filter(cb = () => {
        return false;
    }) {
        const keysForDelete = [];
        this.data.forEach((value, key) => {
            if (cb(this.decodeId(key), value)) {
                keysForDelete.push(key);
            }
        });
        const forDelete = Array.from(new Set([...keysForDelete]));
        forDelete.forEach(key => this.data.delete(key));
    }

    clear() {
        this.data.clear();
        this.length = 0;
        this.debugMessage('Clear mem. storage', '');
    }

    print() {
        this.debugMessage('Items qty', this.data.size);
        this.debugMessage('Max. size', this.size);
        this.debugMessage('Current size', this.currentSize());
        this.data.forEach((value, key) => {
            this.debugMessage(`Item ${this.getId(key)}`, {
                key: this.getId(key),
                size: value.size,
                order: value.order,
            });
        });
    }
}

export const memoryStorage = new MemoryStorage(MEM_STORAGE_SIZE * 1024 * 1024, DEBUG);
