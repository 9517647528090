import { NativeModules, Platform } from 'react-native';
import { consoleLog } from './log';

export const getExactAlarmPermissions = (needToAsk = false, alwaysAsk = false) => {
    if (Platform.OS !== 'android' && Platform.version < 34) {
        return true;
    }
    const permission = NativeModules?.PermissionModule?.checkExactAlarmPermission;
    if (!permission || typeof permission !== 'function') {
        return true;
    }

    return new Promise((resolve) => {
        permission(needToAsk, alwaysAsk).then((res) => {
            consoleLog('PermissionModule', { res });
            resolve(res);
        });
    });
};
