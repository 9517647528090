// noinspection JSUnusedGlobalSymbols

import { Platform } from 'react-native';
import service from '../services/user';
import api from '../services/api';
import firebase from '../services/firebase';
import { getUserInfo as getUserInfoSelector, getUserInteraction } from '../selectors';
import { asyncActionCreator, createReducerFor } from './utils';

const TYPE = 'USER';

export const getDeviceSupportInfo = () => {
    const {
        constants,
        OS,
        Version,
    } = Platform;
    const getParameter = param => (param ? ` ${param}` : '');
    // eslint-disable-next-line no-undef
    return OS === 'web' ? navigator.userAgent : `${OS}${getParameter(constants?.Release)}${getParameter(Version)}${getParameter(constants?.Manufacturer)}${getParameter(constants?.Model)}`;
};

const userInfoDefaults = () => ({
    userType: 'REGULAR',
    me: { name: '' },
    inDeleting: false,
    deviceInfo: getDeviceSupportInfo(),
});

export const initialState = {
    info: userInfoDefaults(),
    deviceInfo: getDeviceSupportInfo(),
    isLoading: true,
    showEmailReconfirm: true,
};

export const setShowEmailReconfirm = value => dispatch => dispatch({
    type: TYPE,
    payload: { showEmailReconfirm: value },
});

export const setUserError = (message, code) => dispatch => dispatch({
    type: TYPE,
    payload: {
        error: message ? {
            message,
            level: 'error',
            code,
        } : null,
    },
});

export const setUserInteractionError = error => async (dispatch) => {
    const userInfo = await service.getUserInfo();
    dispatch({
        type: TYPE,
        payload: {
            error,
            info: {
                ...userInfo,
                error,
            },
        },
    });
};

export const signInWithPhoneNumber = asyncActionCreator(TYPE, 'signIn', false, (phone, cb) => firebase.signInWithPhoneNumber(phone, cb));

export const sendSmsCode = asyncActionCreator(TYPE, 'signIn', false, (code, confirmation, onError) => firebase.signConfirmation(confirmation, code, onError));

export const checkAuthenticated = asyncActionCreator(TYPE, 'isAuthenticated', undefined, () => api.checkAuth());

export const signInWithToken = asyncActionCreator(TYPE, 'signIn', false, (token, onError) => firebase.signInWithToken(token, onError));

export const logOut = asyncActionCreator(TYPE, 'isAuthenticated', {}, () => api.logOut());

export const removeAccount = asyncActionCreator(TYPE, 'isAuthenticated', {}, () => api.removeAccount());

export const reloadUserInfo = asyncActionCreator(TYPE, 'info', undefined, (onNewUserItemsFound, prefsUpdate, updateSelects) => service.reloadUserInfo(onNewUserItemsFound, prefsUpdate, updateSelects));

export const getUserInfo = asyncActionCreator(TYPE, 'info', userInfoDefaults(), () => service.getUserInfo());

export const getUserInteractionError = () => async (dispatch, getState) => {
    const userInteraction = await getUserInteraction(getState());
    return userInteraction.error;
};

export const addCard = data => async (dispatch, getState) => {
    const userInfo = getUserInfoSelector(getState());
    const cards = userInfo?.me?.cards;
    cards.push(data);
    dispatch({
        type: TYPE,
        payload: { info: { ...userInfo } },
    });
};

export const startUserFetch = () => dispatch => dispatch({
    type: TYPE,
    payload: { isFetching: true },
});

export const stopUserFetch = () => dispatch => dispatch({
    type: TYPE,
    payload: { isFetching: false },
});

export const setInDeleting = state => dispatch => dispatch({
    type: TYPE,
    payload: { inDeleting: state },
});

export default createReducerFor(TYPE, initialState);
