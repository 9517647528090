export default {
    apiKey: 'AIzaSyD4RK25wNyPyomyXL8mqtsA-0gwtnFH9iQ',
    authDomain: 'aibolitmd-member-bns.firebaseapp.com',
    databaseURL: 'https://aibolitmd-member-bns.firebaseio.com',
    projectId: 'aibolitmd-member-bns',
    storageBucket: 'aibolitmd-member-bns.appspot.com',
    messagingSenderId: '383149546543',
    appId: '1:383149546543:web:6ee638b42034c6cb36fada',
    measurementId: 'G-270SQV01M8',
};
