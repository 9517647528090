import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigationState } from '@react-navigation/native';
import {
    Button,
    Dimensions,
    FlatList,
    Icon,
    Image,
    Modal,
    Page,
    React,
    showNotification,
    Subtitle,
    Title,
    TouchableOpacity,
    View,
} from '../../common';
import callImg from '../../assets/images/telephone.png';
import turnImg from '../../assets/images/turn-right-sign.png';
import { openIfSupported, openLocation } from '../../services/device';
import { modal } from '../../styles/common';
import { callPhones } from '../../combo';
import { DoctorsListItem } from '..';
import { getMapsAppsConfig } from '../../selectors';
import { getRouteHistory } from '../../utils/getRouteHistory';
import { doctorFilterParams } from '../Doctors/DoctorsSpecialtiesSearch';
import { mapBunner } from './styles';

const getUrlWithCoords = (latitude, longitude, url) => {
    const newUrl = url.replace(/{latitude}/g, latitude);
    return newUrl.replace(/{longitude}/g, longitude);
};

const openLocationOnMap = (latitude, longitude, name, mapsAppsConfig) => {
    if (mapsAppsConfig?.shouldDisplayModal && mapsAppsConfig?.mapsApps?.length) {
        Page.showModal(
            <Modal title="titles.choose_app" containerStyles={modal.itemList}>
                <FlatList
                    data={mapsAppsConfig.mapsApps}
                    renderItem={({ item }) => (
                        <Button
                            title={item.name}
                            styles={[
                                modal.modalButton,
                                {
                                    alignItems: 'center',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                },
                            ]}
                            textStyles={modal.selectModalTitle}
                            action={() => {
                                openIfSupported(getUrlWithCoords(latitude, longitude, item.url), () => {
                                    showNotification(Object.R('titles.open_app_failed', { name: item.name }));
                                });
                                Page.closeModal();
                            }}
                        />
                    )}
                />
            </Modal>,
        );
    } else {
        openLocation(latitude, longitude, name);
    }
};

const { width } = Dimensions.get('window');

const ScheduleInfo = React.memo(({ schedule }) => (
    <View style={mapBunner.scheduleInfo}>
        <Icon.Clock />
        <Subtitle style={mapBunner.scheduleInfoText}>{schedule}</Subtitle>
    </View>
));

export const CallAndMapButtons = ({
    phones, latitude, longitude, name, id,
}) => {
    const mapsAppsConfig = useSelector(getMapsAppsConfig);
    const paramsForTrecking = {
        clinicId: id,
        journey: getRouteHistory(useNavigationState(s => s)),
    };
    const adaptedPhones = typeof phones === 'string' ? phones.split(';') : phones?.map(({ label }) => label);
    return (
        <View style={mapBunner.buttonsContiner}>
            {adaptedPhones?.length ? (
                <TouchableOpacity
                    accessibilityLabel="drugstore_details_screen_call_btn"
                    style={mapBunner.callBtn}
                    onPress={() => callPhones(
                        adaptedPhones.filter(e => e.length),
                        paramsForTrecking,
                    )
                    }>
                    <Image source={callImg} />
                </TouchableOpacity>
            ) : null}
            <TouchableOpacity
                accessibilityLabel="drugstore_details_screen_open_maps_btn"
                onPress={() => openLocationOnMap(latitude, longitude, name, mapsAppsConfig)}
                style={mapBunner.routeBtn}>
                <Image source={turnImg} />
            </TouchableOpacity>
        </View>
    );
};

export const MapBunner = React.memo(
    ({
        info: {
            id, address, name, schedule, phones, latitude, longitude, message,
        },
    }) => (
        <View style={mapBunner.bunner}>
            <View style={mapBunner.infoTitle}>
                <Title>{address}</Title>
                <Subtitle>{name}</Subtitle>
                {message ? <Subtitle primary>{message}</Subtitle> : null}
            </View>
            <View style={mapBunner.infoContiner}>
                <ScheduleInfo schedule={schedule} />
                <CallAndMapButtons phones={phones} latitude={latitude} longitude={longitude} name={address} id={id} />
            </View>
        </View>
    ),
);

const renderItem =
    source => ({ item }) => <DoctorsListItem doctorInfo={item} fromMap hasTimetableSource={source} />;

export const MedcenterDetailsBunner = ({ item, from = 'default' }) => {
    const ListHeader = () => <MapBunner info={item} />;
    let listRef;
    useEffect(() => {
        if (listRef) {
            listRef.scrollToOffset({ offset: 0 });
        }
    }, [item.id]);
    const filters = doctorFilterParams?.[from] ?? doctorFilterParams.default;
    const hasTimetableSource = filters?.hasTimetableSource ?? doctorFilterParams.default.hasTimetableSource;
    return (
        <View style={{ height: width * 0.7 }}>
            <FlatList
                data={item.doctors}
                ListHeaderComponent={ListHeader}
                renderItem={renderItem(hasTimetableSource)}
                ref={(ref) => {
                    listRef = ref;
                }}
            />
        </View>
    );
};
