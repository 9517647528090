import EStyleSheet from 'react-native-extended-stylesheet';
import { COLORS } from '../../styles/colors';

export const doctors = EStyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '$lightGrayBg',
    },
    topBar: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingRight: 13,
        paddingLeft: 12,
    },
    topBarTitle: {
        color: '#8A8A8A',
        fontWeight: '600',
        fontSize: 12,
    },
    filters: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
});

export const details = {
    visitsListHeadetTitle: {
        fontFamily: '$secondaryTextFontFamily',
        fontSize: 12,
        fontWeight: '600',
        color: '#5F5F5F',
    },
    visitsListHeader: {
        paddingTop: 10,
        paddingLeft: 8,
        paddingRight: 17,
        paddingBottom: 16,
        borderBottomWidth: 0,
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        color: '#555555',
        marginBottom: 12,
    },
    modalSubtitle: {
        alignSelf: 'center',
        textAlign: 'center',
        fontSize: 12,
        fontWeight: '600',
        color: '#555555',
        marginBottom: 20,
        maxWidth: 170,
    },
    modalButton: {
        paddingVertical: 14,
        paddingLeft: 20,
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
    },
    modalBtnTxt: {
        color: '#555555',
        fontSize: 16,
        lineHeight: 20,
    },
    selectModalTitle: {
        flex: 1,
        fontFamily: 'Source Sans Pro',
        fontSize: 16,
    },
    selectModalContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    selectModalIcon: {
        paddingLeft: 6,
        fontSize: 14,
        fontFamily: 'Source Sans Pro',
    },
};

export const filters = EStyleSheet.create({
    topBarBtn: {
        backgroundColor: '$green',
        fontSize: 14,
        color: '$white',
        paddingVertical: 8,
        paddingHorizontal: 25,
        borderRadius: 100,
    },
    topBarTitle: {
        fontSize: 18,
        color: '$white',
    },
    title: {
        fontSize: 12,
        fontWeight: '600',
        marginLeft: 8,
        marginBottom: 12,
    },
    btn: {
        position: 'relative',
        padding: 12,
        paddingRight: 40,
        backgroundColor: '$white',
        borderWidth: 1,
        borderColor: '#E9E9E9',
        borderRadius: 3,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    value: {
        fontSize: 18,
        color: '#555555',
    },
    icon: {
        position: 'absolute',
        right: 20,
        top: 12,
    },
    findDocBtn: {
        backgroundColor: '$emerald',
        paddingVertical: 12,
        borderRadius: 4,
        flex: 1,
    },
    findDocBtnTxt: {
        color: '$white',
        fontSize: 14,
        fontWeight: '600',
        textAlign: 'center',
    },
});

export const doctorSpecialization = EStyleSheet.create({
    container: {
        height: '$deviceHeight',
        paddingTop: 8,
        backgroundColor: '#F9F9F9',
        paddingBottom: 20,
    },
    listItemWrapper: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingVertical: 14,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    radioWrapper: {
        position: 'relative',
        width: 16,
        height: 16,
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '#999999',
        marginRight: 12,
    },
    radioWrapperActive: { borderColor: '#5C85DD' },
    circleActive: {
        position: 'absolute',
        top: 3,
        left: 3,
        width: 8,
        height: 8,
        backgroundColor: '#5C85DD',
        borderRadius: 20,
    },
    listItemText: {
        color: '#555555',
        fontSize: 16,
    },
});


// noinspection JSUnusedGlobalSymbols
export const preferredDateFilter = EStyleSheet.create({
    container: { paddingTop: 8 },
    listWrapper: {
        paddingHorizontal: 20,
        paddingVertical: 8,
        backgroundColor: '#F9F9F9',
    },
    listItemWrapper: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        paddingVertical: 14,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    radioWrapper: {
        position: 'relative',
        width: 16,
        height: 16,
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '#999999',
        marginRight: 12,
    },
    radioWrapperActive: { borderColor: '#5C85DD' },
    circleActive: {
        position: 'absolute',
        top: 3,
        left: 3,
        width: 8,
        height: 8,
        backgroundColor: '#5C85DD',
        borderRadius: 20,
    },
    listItemText: {
        color: '#555555',
        fontSize: 16,
    },
});

// noinspection JSUnusedGlobalSymbols
export const doctorCard = EStyleSheet.create({
    container: {
        position: 'relative',
        backgroundColor: '$white',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#e0e0e0',
        paddingBottom: 24,
    },
});

// noinspection JSUnusedGlobalSymbols
export const doctorList = EStyleSheet.create({
    doctorsListContainer: {
        paddingLeft: 12,
        paddingRight: 13,
        paddingVertical: 5,
        paddingTop: 5,
        flex: 1,
    },
    paddedList: { paddingBottom: 0 },
    listItem: {
        marginBottom: 4,
        paddingHorizontal: 16,
        paddingVertical: 16,
        backgroundColor: '$white',
        borderWidth: 1,
        borderColor: '#e0e0e0',
        borderRadius: 3,
    },
    listItemTopRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listItemBottomRow: { paddingTop: 16 },
    bottomRowItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    avatarContainer: { position: 'relative' },
    titleContainer: { flex: 0.9 },
    title: {
        fontSize: 16,
        color: '#6F6F6F',
        fontWeight: '600',
    },
    smallText: {
        fontSize: 12,
        color: '#999999',
        fontWeight: '500',
    },
    bottomRowIcon: { marginRight: 8 },
});

export const searchDrugs = EStyleSheet.create({
    wrapper: {
        height: '$deviceHeight',
        backgroundColor: '#F9F9F9',
    },
    topBar: {
        backgroundColor: '$darkBlue',
        height: 55,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 23,
    },
    drugsListWrapper: {
        flexWrap: 'nowrap',
        flexDirection: 'column',
        position: 'relative',
        flexGrow: 1,
    },
    selectedBar: {
        paddingLeft: 0,
        paddingRight: 0,
        flex: 1,
    },
    noDrugs: {
        paddingVertical: 12,
        color: '#9C9C9C',
    },
    noDrugsWrapper: { paddingHorizontal: 25 },
    noDrugsTitle: {
        fontSize: 12,
        paddingTop: 16,
        paddingBottom: 12,
        marginBottom: 0,
        fontWeight: '600',
        borderBottomWidth: 1,
        borderBottomColor: '#E9E9E9',
        color: '#555555',
        fontFamily: '$primaryTextFontFamily',
    },
});

export const specializationSearchBar = EStyleSheet.create({
    searchBar: {
        padding: 0,
        marginTop: 4,
        justifyContent: 'space-between',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        borderWidth: 1,
        borderRadius: 3,
        backgroundColor: '$white',
        borderColor: '#E9E9E9',
        outline: 'none',

    },
    container: {
        padding: 0,
        backgroundColor: '$white',
        flex: 1,
        borderColor: '$white',
        borderWidth: 0,
        borderBottomColor: 'transparent',
        borderTopColor: 'transparent',
        outline: 'none',
        outlineWidth: 0,
    },
    input: {
        backgroundColor: '$white',
        borderWidth: 0,
        borderColor: '#E9E9E9',
        color: '#555555',
        fontSize: 18,
        fontFamily: 'SourceSansPro-Regular',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingVertical: 4,
        outline: 'none',
        outlineWidth: 0,
    },
    inputDisabled: {
        backgroundColor: '$white',
        borderWidth: 0,
        borderColor: '#E9E9E9',
        color: COLORS.STANDARD_GRAY_DISABLED,
        fontSize: 18,
        fontFamily: 'SourceSansPro-Regular',
        margin: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingVertical: 4,
        outline: 'none',
        outlineWidth: 0,
    },
    suggestionsList: {
        flex: 1,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        paddingHorizontal: 0,
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
        maxHeight: 256,
        backgroundColor: 'white',
    },
    suggestionsItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 14,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    previousSuggestionItemText: {
        fontFamily: '$sourceSansPropFontFamily_semibold',
        marginLeft: 8,
        marginVertical: 12,
    },
    suggestionItemText: { marginVertical: 14 },
});

export const optionsButton = EStyleSheet.create({
    button: {
        borderWidth: 1.5,
        borderColor: '#5C85DD',
        paddingHorizontal: 10,
        marginTop: 14,
        marginRight: 12,
        marginBottom: 2,
        borderRadius: 3,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    text: {
        color: '#5C85DD',
        marginLeft: 6,
    },
});

export const doctorsSnippets = EStyleSheet.create({
    menu: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 4,
    },
});
