import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEndOfLifeApp } from '../selectors';
import { compareVersion, parseVersion } from '../utils/strings';
import { VERSION } from '../config';
import {
    Linking, Platform, React, Text,
} from './react';
import { Modal } from './Modal';
import { BigButton } from './Button';

export const EndOfLifeApp = () => {
    const endOfLife = useSelector(getEndOfLifeApp);
    const [window, setWindow] = useState(true);
    const [outdatedVersion, setOutdatedVersion] = useState(0);
    useEffect(() => {
        if (!endOfLife) {
            return;
        }
        setOutdatedVersion(compareVersion(parseVersion(VERSION), parseVersion(endOfLife.version)));
    }, [endOfLife]);

    const getStoreLink = life => (Platform.OS === 'android' ? life?.linkToStore?.android ?? '' : life?.linkToStore?.ios ?? '');

    const goToStore = life => () => {
        setWindow(false);
        const link = getStoreLink(life);
        if (!link) {
            return;
        }
        Linking.canOpenURL(link)
            .then(canOpen => canOpen && Linking.openURL(link))
            // eslint-disable-next-line no-console
            .catch(e => console.log('Cannot open the link', e));
    };

    return (
        <>
            {
                window && (outdatedVersion < 0) && endOfLife && Platform.OS !== 'web' && getStoreLink(endOfLife) !== '' &&
                <Modal title={endOfLife.popupTitle} _onClose={() => setWindow(false)}>
                    <Text numberOfLines={0}>{Object.R(endOfLife.popupMessage)}</Text>
                    <BigButton action={goToStore(endOfLife)} title={Object.R('buttons.update')}/>
                </Modal>
            }
        </>
    );
};
