export class ErrorManager {
    static setError(error) {
        try {
            const code = error?.statusCode ?? '';
            this.error = error?.message ? `${error?.detail ?? error?.message} ${code}` : JSON.stringify(error);
        } catch (e) {
            this.error = error;
        }
    }

    static getError() {
        try {
            return JSON.stringify(this.error);
        } catch (e) {
            return this.error?.message ?? 'Unknown error';
        }
    }
}
