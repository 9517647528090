// noinspection JSUnusedGlobalSymbols

import {
    BigButton, Component, React, View,
} from '../../common';
// import {
//     Overlay, Item, EasyView, BoxTransitioned,
// } from './snippets';
// import * as Examples from './Examples';
import { Blinker } from './Blinker';

// const viewAdS = { backgroundColor: 'red', width: 50, height: 50 };
// const easyView = { backgroundColor: 'yellow', width: 30, height: 30 };
// const viewAdSGreen = { backgroundColor: 'green', width: 100, height: 100 };

// noinspection JSUnusedLocalSymbols
export class TestAdComponent extends Component {
    state = {
        isOpen: false,
        title: 'Test componentDidUpdate',
    };

    // componentWillMount() {
    //     // this.setState({ isOpen: true });
    //     console.log('-----------------TestAdComponent, componentWillMount, isOpen ', this.state.isOpen);
    // }
    // // componentDidMount() {
    // //     // setTimeout(
    // //     //     this.setState({ isOpen: true }),
    // //     //     1000);
    // //     this.setState({ isOpen: true });
    // //     console.log('-----------------TestAdComponent, componentDidMount, isOpen ', this.state.isOpen);
    // // }
    // componentDidUpdate(prevProps) {
    //     // Typical usage (don't forget to compare props):
    //     if (this.props.title !== prevProps.title) {
    //       this.setState({ isOpen: true });
    //     }
    //   }
    render() {
        const {
            // eslint-disable-next-line no-unused-vars
            isOpen,
            title,
        } = this.state;
        // console.log('-----------------TestAdComponent, render, this.state.title ', this.state.title);
        return (
            <View>
                {/* <Blinker pose={isOpen ? 'visible' : 'hidden'} /> */}
                <Blinker title={title}/>
                <BigButton action={() => {
                    this.setState({ title: 'It is working!!!!!!!!' });
                }} title="Button"/>
            </View>
        );
    }
}


//   {/* <Overlay pose={isOpen ? 'open' : 'closed'}> */}
//             {/* <Item style={viewAdS} withParent={false} pose={isOpen ? 'open' : 'closed'} />
//             <Item style={viewAdS} />
//             <Item style={viewAdS} />
//             <Item style={viewAdS} /> */}

//             {/* <EasyView style={easyView} />
//             <EasyView style={easyView} />
//             <EasyView style={easyView} />
//             <EasyView style={easyView} /> */}

//             {/* <Item style={viewAdS} ><EasyView style={easyView} /></Item>
//             <Item style={viewAdS} ><EasyView style={easyView} /></Item>
//             <Item style={viewAdS} ><EasyView style={easyView} /></Item>
//             <Item style={viewAdS} ><EasyView style={easyView} /></Item> */}

//         {/* </Overlay> */}
//         {/* <Examples.ViewVisible style={viewAdSGreen} pose={isOpen ? 'visible' : 'hidden'} /> */}
//         {/* <Blinker pose={isOpen ? 'visible' : 'hidden'} /> */}
