import {
    React, Section, SingleNavigation, TopNotification,
} from '../../../common';

export const AllDoctorsSection = ({ onPress }) => <>
    <TopNotification containerStyle={{ borderTopWidth: 1 }} hint="hints.medcentersChooseDoc" />
    <Section
        flex
        title="titles.onlineSection"
        containerStyles={{ height: 40 }}
        numberOfLines={2}
    >
        <SingleNavigation
            title={Object.R('titles.mcRecordAllDoc')}
            style={[{ marginHorizontal: 0 }]}
            textStyle={{ color: '#555555' }}
            onPress={onPress} />
    </Section>
</>;
