import { useEffect, useState } from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { buttonsStyles } from '../styles/button';
import { HyperLink } from './Hyperlink';
import { Icon, React, Title, TouchableOpacity, View } from '.';
import { MODE } from '../config';

export const cleanString = (input) => {
    return input.replace(/{.*?::.*?}/g, function (match) {
        return match.split('::')[0].replace(/{|}/g, '');
    });
};

export const checkBoxStyles = EStyleSheet.create({
    iconWrapper: {
        width: 16,
        height: 16,
        borderWidth: 1,
        borderRadius: 2,
        borderColor: '#999999',
        marginRight: 12,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconWrapperActive: { borderColor: '#5C85DD' },
    textWrapper: {
        flex: 1,
        flexDirection: 'row-reverse',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    text: { flex: 1 },
    container: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flex: 1,
    },
});

const Checkbox = ({ onPress, selected, titleId, title, badge, ns = 'checkbox', numberOfLines = 2, style }) => (
    <View style={checkBoxStyles.container}>
        <TouchableOpacity onPress={onPress} accessibilityLabel={ns} onStartShouldSetResponderCapture={() => true}>
            <View style={[checkBoxStyles.iconWrapper, selected && checkBoxStyles.iconWrapperActive, style]}>
                {selected && <Icon name="check" size={14} color="#5C85DD" />}
            </View>
        </TouchableOpacity>
        <View style={checkBoxStyles.textWrapper}>
            {badge}
            <Title id={titleId} numberOfLines={numberOfLines} style={checkBoxStyles.text}>
                {titleId ? '' : title}
            </Title>
        </View>
    </View>
);

export const Agreements = ({ onChange, userAgreement = true, personalDataAgreement = true }) => {
    const [user, setUser] = useState(false);
    const [privateData, setPrivateData] = useState(false);

    useEffect(() => {
        if (onChange) {
            onChange((user || !userAgreement) && (privateData || !personalDataAgreement));
        }
    }, [user, privateData]);

    return (
        <View style={{ marginTop: 10 }}>
            {userAgreement && (
                <View style={{ height: 70 }}>
                    <Checkbox
                        title={
                            <HyperLink
                                title={
                                    MODE === 'uat'
                                        ? cleanString(Object.R('titles.userAgreement'))
                                        : Object.R('titles.userAgreement')
                                }
                                style={{ linkColor: buttonsStyles.link.color }}
                                linkStyle={{ textDecorationLine: 'underline' }}
                                onClickText={() => setUser((prevState) => !prevState)}
                            />
                        }
                        style={{
                            marginHorizontal: 2,
                            marginVertical: 40,
                            marginTop: 3,
                        }}
                        selected={user}
                        numberOfLines={0}
                        onPress={() => setUser((prevState) => !prevState)}
                    />
                </View>
            )}
            {personalDataAgreement && (
                <View style={{ height: 55 }}>
                    <Checkbox
                        title={
                            <HyperLink
                                title={
                                    MODE === 'uat'
                                        ? cleanString(Object.R('titles.personalDataAgreement'))
                                        : Object.R('titles.personalDataAgreement')
                                }
                                style={{ linkColor: buttonsStyles.link.color }}
                                linkStyle={{ textDecorationLine: 'underline' }}
                                onClickText={() => setPrivateData((prevState) => !prevState)}
                            />
                        }
                        style={{
                            marginHorizontal: 2,
                            marginVertical: 40,
                            marginTop: 3,
                        }}
                        selected={privateData}
                        numberOfLines={0}
                        onPress={() => setPrivateData((prevState) => !prevState)}
                    />
                </View>
            )}
        </View>
    );
};
