import { useSelector } from 'react-redux';
import {
    dataFrom, Platform, React, ScrollView, Text, TouchableOpacity, View,
} from '../../../common';
import { navigate } from '../../../actions';
import { getActualPopularQueries, getCurrentLocation } from '../../../selectors';
import { gotoDoctors } from './utils';

const PopularQueryItem = (props) => {
    const buttonStyle = {
        marginRight: 6,
        marginVertical: 6,
        borderRadius: 20,
        paddingVertical: 7,
        paddingHorizontal: 20,
        backgroundColor: '#B6BEDA',
    };
    const textStyle = { color: 'white' };
    const onClickHandler = () => {
        props?.action && props.action();
        props.trackingAlias && Object.trackAction(props.title, { trackingAlias: props.trackingAlias });
    };
    return (
        <TouchableOpacity onPress={onClickHandler}>
            <View style={[buttonStyle, props.style]}>
                <Text style={[textStyle, props?.textStyle]}>{Object.R(props?.title ?? '')}</Text>
            </View>
        </TouchableOpacity>
    );
};

const PopularQueryColumn = ({ item }) => {
    return (
        <View style={{ flexDirection: 'row' }}>
            <Text>
                {item?.data?.map(subItem => (
                    <View
                        style={{
                            padding: 0,
                            margin: 0,
                        }}
                        key={subItem.name}>
                        <PopularQueryItem
                            key={subItem.name}
                            action={
                                subItem.type === 'drugs'
                                    ? () => {
                                        navigate('DrugsList', {
                                            keyword: subItem.name,
                                            drugsList: null,
                                        });
                                    }
                                    : () => gotoDoctors(subItem.id, subItem.useSearch, true, 'popular')
                            }
                            title={subItem.name}
                            style={{
                                backgroundColor: subItem.type === 'drugs' ? '#8EC7D4' : '#9AACDA',
                                marginVertical: 5,
                                paddingVertical: 5,
                            }}
                            trackingAlias="PopularQuery"
                        />
                    </View>
                ))}
            </Text>
        </View>
    );
};

export const PopularQueries = dataFrom(getActualPopularQueries)(({ data = [] }) => {
    const { banners } = useSelector(getCurrentLocation);
    const lines = banners && banners?.split(';')?.includes('doctors') ? 2 : 1;
    return (
        <>
            <ScrollView
                style={{
                    position: 'relative',
                    height: (Platform.OS === 'ios' ? 38 : 39) * lines,
                    overflowX: 'hidden',
                    overflowY: 'hidden',
                }}
                nestedScrollEnabled={true}
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                scrollEnabled={false}
                removeClippedSubviews>
                <PopularQueryColumn item={{ data }} />
            </ScrollView>
        </>
    );
});
