import { personId1, personId3, personId4, tomorrow, 
    AIBOLIT4_ID, KEDIQ_ID,
     lastModified } from '../const.stub';

const orderDefaults ={
    allowedActions: [ "VIEW", "UPDATE", "DELETE" ], 
    comment: 'Кабинет 237, этаж 1, № 237', 
    status: 'reserved',

    "speciality": "врач-оториноларинголог",
    assignmentId: '5b51794ce0f569ad5',
    "customerId": "162",
    "providerCode": "lode-minsk",
    "physicianExternalId": "117",
    clinicExternalId: '2562',
    "sourceName": "LODE API",
    drugs: [], 
    attachments: ['image_path'],
    lastUpdated: lastModified,
}
export const orders = {
    4569681: {
        id: 4569681,
        profileId: personId1, 
        timeslotId: 51420057,
        physicianId: AIBOLIT4_ID,
        branchId: "5b3f5fa7eaf998e58",

        "startDate": tomorrow+"T15:00:00Z",
        "endDate": tomorrow+"T15:40:00Z",
        ...orderDefaults,
        comment: 'Кабинет 1, этаж 1, № 1', 
    
    },
    4567834: {
        id: 4567834,
        profileId: personId1,
        timeslotId: 51420050,
        physicianId: AIBOLIT4_ID,
        branchId: '5b3f5fa7eaf998e58',

        "startDate": tomorrow+"T17:20:00Z",
        "endDate": tomorrow+"T17:40:00Z",
        ...orderDefaults
    },
    4567837: {
        id: 4567837,
        profileId: personId1,
        timeslotId: 51420051,
        physicianId: AIBOLIT4_ID,
        branchId: '5b3f5fa7eaf998e58',

        "startDate": tomorrow+"T18:20:00Z",
        "endDate": tomorrow+"T18:40:00Z",
        ...orderDefaults
    },
    4567847: {
        id: 4567847,
        profileId: personId1,
        timeslotId: 51420052,
        physicianId: AIBOLIT4_ID,
        branchId: '5b3f5fa7eaf998e58',

        "startDate": "2020-10-09T10:20:00Z",
        "endDate": "2020-10-09T11:40:00Z",
        ...orderDefaults
    },
    4567848: {
        id: 4567848,
        profileId: personId1,
        timeslotId: 51420053,
        physicianId: AIBOLIT4_ID,
        branchId: '5b3f5fa7eaf998e58',

        "startDate": "2020-01-01T10:20:00Z",
        "endDate": "2020-01-01T11:40:00Z",
        ...orderDefaults
    },
    8567934: {
        id: 8567934,
        profileId: personId1,
        timeslotId: 51420056,
        physicianId: KEDIQ_ID,
        branchId: '5b3f5fa7eaf998e58',
 
        "startDate": "2020-10-16T16:20:00Z",
        "endDate": "2020-10-16T17:40:00Z",
        ...orderDefaults
    },

    // -------------------------- personId4
    4569686: {
        id: 4569686,
        profileId: personId4,
        timeslotId: 51420057,
        physicianId: AIBOLIT4_ID,
        branchId: '5b46096c302c5a36f',
        
        "startDate": tomorrow+"T17:00:00Z",
        "endDate": tomorrow+"T17:10:00Z",
        ...orderDefaults
    },

    4567935: {
        id: 4567935,
        profileId: personId4,
        timeslotId: 51420056,
        physicianId: '5b3f5fa83884cda40',
        branchId: '5b46096c302c5a36f',
        notes: 'Кабинет 132, этаж 1, № 132',
        status: 'reserved',
        allowedActions: ['UPDATE', 'DELETE', 'VIEW'],
    },
    4567835: {
        id: 4567835,
        profileId: personId4,
        timeslotId: 51420050,
        physicianId: AIBOLIT4_ID,
        branchId: '5b46096c302c5a36f',
        notes: 'Кабинет 237, этаж 1, № 237',
        status: 'reserved',
        allowedActions: ['UPDATE', 'DELETE', 'VIEW'],
    },

    // -------------------------- personId3
    4569687: {
        id: 4569687,
        profileId: personId3,
        timeslotId: 51420057,
        physicianId: AIBOLIT4_ID,
        branchId: '5b46096c302c5a36f',
        notes: 'Кабинет 237, этаж 1, № 237',
        status: 'reserved',
        allowedActions: ['UPDATE', 'DELETE', 'VIEW'],
    },
    4567936: {
        id: 4567936,
        profileId: personId3,
        timeslotId: 51420056,
        physicianId: '5b3f5fa83884cda40',
        branchId: '5b46096c302c5a36f',
        notes: 'Кабинет 132, этаж 1, № 132',
        status: 'reserved',
        allowedActions: ['UPDATE', 'DELETE', 'VIEW'],
    },
    4567836: {
        id: 4567836,
        profileId: personId3,
        timeslotId: 51420050,
        physicianId: AIBOLIT4_ID,
        branchId: '5b46096c302c5a36f',
        notes: 'Кабинет 237, этаж 1, № 237',
        status: 'reserved',
        allowedActions: ['UPDATE', 'DELETE', 'VIEW'],
    },
};

