// noinspection JSUnusedLocalSymbols

import EStyleSheet from 'react-native-extended-stylesheet';

// eslint-disable-next-line no-unused-vars
const fullBorderW1ULG = {
    borderWidth: 1,
    borderColor: '$ultraLightGray',
};
const borderWithoutBottomW1ULG = {
    borderWidth: 1,
    borderBottomWidth: 0,
    borderColor: '$ultraLightGray',
};
const borderWithoutLeftW1ULG = {
    borderTopColor: '$ultraLightGray',
    borderBottomColor: '$ultraLightGray',
    borderRightColor: '$ultraLightGray',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
};

export const invoiceSingleService = EStyleSheet.create({
    invoiceSingleService: {
        marginBottom: 0,
        marginTop: 0,
        padding: 16,
        paddingLeft: 20,
        flexGrow: 1,
        flexShrink: 1,
    },
    forConfirmCommon: { marginBottom: 12 },
    forNewListCommon: {},
    forReviewedInfoCommon: {
        marginBottom: 12,
        backgroundColor: 'white',
    },
    forReviewedInfoSection: { ...borderWithoutLeftW1ULG },
    forMultiServiceSection: {
        ...borderWithoutBottomW1ULG,
        backgroundColor: 'white',
    },
    forConfirmSection: {
        paddingLeft: 0,
        paddingTop: 0,
        borderBottomColor: '#E9E9E9',
        borderBottomWidth: 1,
    },
    forNewListSection: {
        ...borderWithoutBottomW1ULG,
        backgroundColor: '#FCFCFC',
    },
    greenLeftBorderW3: {
        borderLeftWidth: 3,
        borderLeftColor: '#A3C644',
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },
    redLeftBorderW3: {
        borderLeftWidth: 3,
        borderLeftColor: '$red',
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },
    stopBadge: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 24,
        height: 24,
        marginRight: 24,
        borderRadius: 12,
        backgroundColor: '#DF655C',
    },
    stopBadge_whiteline: {
        width: 16,
        height: 2,
        backgroundColor: 'white',
    },
});

export const invoiceItem = EStyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        margin: 10,
    },
    title: {
        fontSize: 16,
        fontWeight: '600',
    },
    invoiceButton: {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$white',
        paddingVertical: 12,
        height: 42,
    },
    borderWithoutBottom: {
        borderWidth: 1,
        borderBottomWidth: 0,
        borderColor: '$ultraLightGray',
    },
    border: {
        borderWidth: 1,
        borderColor: '$ultraLightGray',
    },
    borderRight: {
        borderRightWidth: 1,
        borderRightColor: '$ultraLightGray',
    },
    borderBottom: {
        borderBottomWidth: 1,
        borderBottomColor: '$ultraLightGray',
    },
    invoiceCard: {
        marginBottom: 0,
        marginTop: 0,
        padding: 16,
        paddingLeft: 20,
        backgroundColor: 'white',
    },
    forNewList: {
        borderWidth: 1,
        borderBottomWidth: 0,
        borderColor: '$ultraLightGray',
    },
    forReviwedList: {
        ...borderWithoutLeftW1ULG,
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
        marginBottom: 12,
    },
    forMultiService: {
        borderWidth: 1,
        borderColor: '$ultraLightGray',
    },
    greenLeftBorder: {
        borderLeftWidth: 4,
        borderLeftColor: '#A3C644',
    },
    redLeftBoredr: {
        borderLeftWidth: 4,
        borderLeftColor: '$red',
    },
    yellowLeftBoredr: {
        borderLeftWidth: 4,
        borderLeftColor: '#FABF55',
    },
    greenBackground: { backgroundColor: '#18B797' },
    redBackground: { backgroundColor: '#DF655C' },
    whiteColor: { color: 'white' },
    redColor: { color: '#DF655C' },
    greenColor: { color: '#18B797' },

    // notifications
    notificationCommon: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 41,
        borderBottomLeftRadius: 3,
        borderBottomRightRadius: 3,
        marginBottom: 24,
    },
    notificationCommonTitle: {
        color: '#FFFFFF',
        fontSize: 14,
    },
    approvedNotification: { backgroundColor: '#18B797' },
    rejectedNotification: { backgroundColor: '#DF655C' },
    partlyNotification: { backgroundColor: '#FABF55' },
    // InvoiceAdaptiveButton
    commonStateStyle: {
        minHeight: 60,
        alignItems: 'center',
        justifyContent: 'center',
    },
    disabledStateStyle: { backgroundColor: '#B7B7B7' },
    approvedStateStyle: { backgroundColor: '#18B797' },
    rejectedStateStyle: { backgroundColor: '#DF655C' },
    partlyStateStyle: { backgroundColor: '#FABF55' },
});
