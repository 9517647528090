import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getServicesList } from '../selectors/services';
import actions from '../actions';
import { getCurrentProgramId } from '../selectors';

export const useServiceListSelector = (assignmentId, noService) => {
    const list = useSelector(getServicesList);
    const currentProgramId = useSelector(getCurrentProgramId);
    const [servicesList, setServicesList] = useState([]);
    useEffect(() => {
        if (!assignmentId || noService) {
            return;
        }
        actions.fetchServicesListByAssignment(assignmentId, undefined, currentProgramId);
    }, [assignmentId, noService, currentProgramId]);

    useEffect(() => {
        setServicesList(list[assignmentId]?.map((item => ({
            ...item,
            description: item?.description?.trim?.(),
        }))));
    }, [list[assignmentId]]);
    return servicesList;
};

