import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getHighlighters } from '../selectors/forms';
import actions from '../actions';
import { React, View } from './react';

export const EXPIRE_HIGHLIGHTER_TIME = 400;

export const ViewHighlight = ({
    style,
    highlightStyle,
    highlightId,
    children,
    expireTime,
    autoRemoveExpired = true,
    onExpire = () => {
        //
    },
    ...props
}) => {
    const list = useSelector(getHighlighters);

    useEffect(() => {
        if (
            expireTime &&
            list.length &&
            list.includes(highlightId)) {
            setTimeout(() => {
                onExpire();
                if (autoRemoveExpired) {
                    actions.removeHighlighter(highlightId);
                }
            }, expireTime);
        }
    }, [list, expireTime, highlightId]);

    return <View {...props}
        style={list.includes(highlightId) ? highlightStyle ?? style : style}>{children}</View>;
};
