import EStyleSheet from 'react-native-extended-stylesheet';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import {
    BigButton,
    Box,
    Button,
    ButtonsGroup,
    CoverableBy,
    FlatList,
    Icon,
    Keyboard,
    Modal,
    NavigationItem,
    Page,
    Panel,
    Prompt,
    React,
    showNotification,
    Subtitle,
    Text,
    TextInput,
    Title,
    TouchableOpacity,
    TouchableWithoutFeedback,
    VBox,
    View,
    withState,
} from '../../common';
import { InitialsAvatar } from '../../combo';
import { parseToDdMmYyyy, testEmailRegex } from '../../utils';
import { COLORS } from '../../common/style';
import { modal } from '../../styles/common';
import actions, { navigate } from '../../actions';
import service from '../../services/user';
import { buttonsStyles } from '../../styles/button';
import { isShowEmailReconfirm } from '../../selectors';
import { getSupportBody } from '../../utils/supportInfo';
import { openURL } from '../../services/device';
import { DISABLE_PROGRAM_CHOOSE } from '../../config';

const deleteRelative = (relative) => {
    const onSubmit = () => (relative.isChild ? service.deleteChild : service.deleteRelative)(relative?.profileId, errorMessage => showNotification(errorMessage));
    Page.showModal(
        <Prompt
            title={relative.isChild ? 'titles.delete_child' : 'titles.delete_relative'}
            subtitle={relative.isChild ? 'titles.delete_child_hint' : 'titles.delete_relative_hint'}
            cancelTitle="buttons.no"
            cancelOptions={{ primary: false }}
            onSubmit={onSubmit}
        />,
    );
};
const addProgram = (relative) => {
    navigate('ChooseProgram', { relative });
};

const menuList = [
    {
        id: 'delete_child',
        handler: (rel) => {
            deleteRelative(rel);
        },
    },
    {
        id: 'edit_child',
        handler: (rel) => {
            if (rel?.isChild) {
                // if (!rel?.name && rel?.isChild) {
                navigate('EditUserPD', { relative: rel });
                return;
            }
            navigate(rel.isChild ? 'EditChild' : 'EditAdultRelative', { relative: rel });
        },
    },
    {
        id: 'add_rel_program',
        handler: (rel) => {
            addProgram(rel);
        },
    },
];

const showRelativesMenu = (relative = {}) => Page.showModal(
    <Modal title={relative.name}>
        <FlatList
            data={DISABLE_PROGRAM_CHOOSE ? menuList?.filter(({ id }) => id !== 'add_rel_program') : menuList}
            renderItem={({ item: { id, handler } }) => (
                <Button
                    title={`buttons.${id}`}
                    styles={modal.modalButton}
                    textStyles={modal.selectModalTitle}
                    action={() => Page.closeModal(() => handler(relative))}
                />
            )}
        />
    </Modal>,
);

export const relativeInfo = (
    {
        name = '', birthday, relation, programIsActive, declineReasonCode, programStartsFrom, coverer, isChild,
    },
    familyCoveredByProgram,
) => (
    <VBox flex>
        {name || !isChild ? (
            <Title.Bigger numberOfLines={1}>{name}</Title.Bigger>
        ) : (
            <Title
                numberOfLines={1}
                style={{
                    fontSize: 16,
                    color: COLORS.ACTION,
                }}>
                {Object.R('titles.fillProfilePDList')}
            </Title>
        )}
        <View style={{ flexDirection: 'row' }}>
            <Subtitle>{[relation, parseToDdMmYyyy(birthday)].filter(Boolean).join(', ')}</Subtitle>
            <CoverableBy
                coverer={coverer}
                containerStyles={{
                    alignSelf: 'flex-end',
                    marginLeft: 12,
                }}
            />
        </View>
        {familyCoveredByProgram && !programIsActive ? (
            <Subtitle color="#DF655C" style={{ marginTop: 8 }} id="titles.notServiced" />
        ) : null}
        {declineReasonCode && declineReasonCode !== 8 ? (
            <Subtitle
                numberOfLines={1}
                color="#DF655C"
                style={{ marginTop: 8 }}
                id={declineReasonCode ? `titles.declineReasonCode_${declineReasonCode}` : 'titles.programNotActiveYet'}
                params={!declineReasonCode && { activated: programStartsFrom }}
            />
        ) : null}
    </VBox>
);

export const renderRelativePanel =
    familyCoveredByProgram => ({
        item,
        item: {
            index,
            name,
            programIsActive,
            coverer,
            withActiveProgram = programIsActive && coverer,
            isVisitsManageable,
            accessLevels,
        } = {},
    }) => {
        const canEditProfile = accessLevels.some(level => level === 'EDIT_PROFILE');
        const hasHandler = withActiveProgram || !canEditProfile;
        const navigateTo = () => {
            if (item?.isChild && (!coverer || coverer === 'undefined')) {
                // if (!item?.fullNameRu && item?.isChild) {
                navigate('EditUserPD', { relative: item });
                return;
            }
            if (canEditProfile) {
                if (coverer && coverer !== 'undefined') {
                    navigate(coverer.includes('bns') ? 'BnsProgram' : 'EpamProgram', {
                        program: item,
                        contractProfileId: item.profileId,
                    });
                }
            } else {
                navigate('RelativeGrants', { relativeId: item.profileId });
            }
        };

        return (
            <Panel centered flex onPress={navigateTo}>
                <InitialsAvatar
                    index={index}
                    name={item ? name : ''}
                    style={{ marginRight: 12 }}
                    withEditBadge
                    grey={!isVisitsManageable}
                />
                {relativeInfo(item, familyCoveredByProgram)}
                {canEditProfile && !withActiveProgram ? (
                    <View style={{ alignSelf: 'flex-start' }}>
                        <Icon.Menu onPress={() => showRelativesMenu(item)} />
                    </View>
                ) : null}
                {hasHandler ? <Icon.Right /> : null}
            </Panel>
        );
    };

export const PersonalEmailInputModal = withState(
    ({
        setState,
        initialEmail = '',
        email = initialEmail,
        inputError,
        checkForIdentity = false,
        identityErrorText = '',
        checkEmail = (mail) => {
            if (checkForIdentity && mail === initialEmail) {
                return identityErrorText;
            }
            return !mail.length || testEmailRegex(mail) ? null : Object.R('error.email_format_error');
        },
        onSubmit,
        onResend,
    }) => {
        const showReconfirm = useSelector(isShowEmailReconfirm);
        const [emailChanged, setEmailChanged] = useState(false);
        return (
            <Modal hideClose>
                <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
                    <View>
                        <Title.Bigger
                            id="titles.yourPersonalEmail"
                            style={{
                                marginTop: 8,
                                marginHorizontal: 12,
                                textAlign: 'center',
                                fontSize: 18,
                                fontFamily: 'SourceSansPro-Semibold',
                            }}
                        />
                        <View style={{ marginHorizontal: 10 }}>
                            <Title
                                id="titles.addPersonalEmailHint"
                                numberOfLines={null}
                                style={{
                                    marginVertical: 20,
                                    marginHorizontal: 2,
                                }}
                            />
                            <Title
                                id="titles.enterActualEmailHint"
                                numberOfLines={null}
                                style={{
                                    marginBottom: 20,
                                    marginHorizontal: 2,
                                }}
                            />
                            <TextInput
                                placeholder="placeholders.email"
                                style={{
                                    marginTop: 12,
                                    fontSize: 16,
                                    color: '#555555',
                                }}
                                onChange={(value) => {
                                    if (email !== value) {
                                        setEmailChanged(true);
                                    }
                                    setState({ email: value });
                                }}
                                invalid={inputError}
                                onBlur={() => setState({ inputError: checkEmail(email) })}
                                value={email}
                                keyboardType="email-address"
                            />
                            {!checkForIdentity && !!email.length && !emailChanged && (
                                <View
                                    style={{
                                        ...warningStyle.error,
                                        padding: 10,
                                        marginTop: 10,
                                        borderWidth: 1,
                                    }}>
                                    <Title
                                        id="titles.emailConfirmationSend"
                                        numberOfLines={null}
                                        style={{
                                            marginTop: 0,
                                            marginHorizontal: 2,
                                            color: COLORS.NEGATIVE,
                                        }}
                                    />
                                    <View
                                        style={{
                                            paddingTop: 10,
                                            alignItems: 'flex-end',
                                            justifyContent: 'space-between',
                                            flexDirection: 'row',
                                        }}>
                                        <TouchableOpacity
                                            onPress={() => {
                                                openURL(`mailto:${Object.R('supportEmail')}${getSupportBody()}`);
                                            }}>
                                            <Title
                                                id="titles.emailConfirmationRetrySupport"
                                                style={{
                                                    marginBottom: 0,
                                                    marginHorizontal: 2,
                                                    ...buttonsStyles.link,
                                                }}
                                            />
                                        </TouchableOpacity>
                                        {!!showReconfirm && (
                                            <TouchableOpacity
                                                onPress={() => {
                                                    if (!showReconfirm) {
                                                        return;
                                                    }
                                                    if (email.length) {
                                                        actions.setShowEmailReconfirm(false);
                                                        !!onResend && onResend(email);
                                                    }
                                                }}>
                                                <Title
                                                    id="titles.emailConfirmationRetry"
                                                    style={{
                                                        marginBottom: 0,
                                                        marginHorizontal: 2,
                                                        ...buttonsStyles.link,
                                                    }}
                                                />
                                            </TouchableOpacity>
                                        )}
                                    </View>
                                    {/* <Link href={() => { */}
                                    {/*     console.log('Click again'); */}
                                    {/* }} */}
                                    {/*     children="titles.emailConfirmationRetry" */}
                                    {/* /> */}
                                </View>
                            )}
                            {inputError && (
                                <View
                                    style={{
                                        backgroundColor: '#F8E6E5',
                                        borderRadius: 4,
                                        marginTop: 14,
                                        marginBottom: 6,
                                        padding: 8,
                                    }}>
                                    <Text
                                        style={{
                                            fontFamily: 'SourceSansPro-Semibold',
                                            color: '#DF655C',
                                        }}>
                                        {inputError}
                                    </Text>
                                </View>
                            )}
                            <ButtonsGroup style={{ paddingHorizontal: 0 }}>
                                <BigButton
                                    title="buttons.cancel"
                                    action={Page.closeModal}
                                    styles={{ backgroundColor: 'transparent' }}
                                    titleStyles={{ color: '#565656' }}
                                />
                                <BigButton
                                    title="buttons.save"
                                    disabledStyle={{ backgroundColor: '#B8BED8' }}
                                    disabled={!email.length || inputError || !emailChanged || initialEmail === email}
                                    action={() => {
                                        const error = checkEmail(email);
                                        if (email.length && !error && !inputError) {
                                            onSubmit(email);
                                            Page.closeModal();
                                        } else if (error) {
                                            setState({ inputError: error });
                                        }
                                    }}
                                />
                            </ButtonsGroup>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </Modal>
        );
    },
    ({ setState }) => ({ setState }),
);

const ProgramsList = ({ data, onProgramsListItem }) => data.map(item => <ProgramsListItem key="item.id" program={item} onProgramsListItem={onProgramsListItem} />);

const ProgramsListItem = ({
    program,
    program: {
        programIsActive, coverer, programStartsFrom: activated, declineReasonCode,
    },
    onProgramsListItem,
}) => (
    <NavigationItem
        onItem={() => {
            onProgramsListItem(program);
        }}
        titleStyle={{}}
        title={
            <VBox>
                <Box centered gap={1}>
                    <CoverableBy coverer={coverer} />
                    <Title.Bigger id={`corporatePrograms.${coverer}`} />
                </Box>
                {!programIsActive && declineReasonCode ? (
                    <Subtitle
                        numberOfLines={1}
                        color="#DF655C"
                        style={{ marginTop: 8 }}
                        id={
                            declineReasonCode
                                ? `titles.declineReasonCode_${declineReasonCode}`
                                : 'titles.programNotActiveYet'
                        }
                        params={!declineReasonCode && { activated }}
                    />
                ) : null}
            </VBox>
        }
    />
);

const PendingProgramPanel = ({ pendingConfirm, onAddProgramRequest, pendingConfirmMail = pendingConfirm.address }) => (
    <NavigationItem
        onItem={onAddProgramRequest}
        titleStyle={{ color: COLORS.MAIN }}
        title={
            <VBox>
                <Box centered gap={1}>
                    <Title.Bigger>{pendingConfirmMail}</Title.Bigger>
                </Box>
                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}>
                    <Icon.Warning />
                    <Subtitle
                        numberOfLines={1}
                        color="#DF655C"
                        style={{
                            fontSize: 14,
                            marginLeft: 11,
                        }}
                        id="titles.weWaitingForConfirm"
                    />
                </View>
            </VBox>
        }
    />
);

export const ProgramsBlock = ({
    programs, pendingConfirm, onAddProgramRequest, onProgramsListItem,
}) => {
    if (!(programs && programs.length) && pendingConfirm) {
        return <PendingProgramPanel pendingConfirm={pendingConfirm} onAddProgramRequest={onAddProgramRequest} />;
    }
    return programs ? (
        <ProgramsList data={programs} onProgramsListItem={onProgramsListItem} />
    ) : (
        <NavigationItem
            onItem={onAddProgramRequest}
            titleStyle={{ color: COLORS.MAIN }}
            title="titles.bind_the_program"
        />
    );
};

const warningStyle = EStyleSheet.create({
    error: {
        backgroundColor: '$lightPink',
        borderWidth: 1,
        borderColor: '$pink',
    },
});
