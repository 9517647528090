import timetables from './data/timetables';
import { arrayToHash, head } from '../../app/utils';
import {
    lastModified,
    tomorrowISOText,
    familyCode,
    accessTokenPassword,
    accessToken1,
    accessToken2,
    profileWithEpam,
    profileAny,
    photoUrl,
    PERSON_BY_PROFILE,
    USER_PHONE_BY_PROFILE,
    today, profileSimple, accessToken3,
    profileWithBns, accessToken4,
    profileEmpty, accessToken5,
    profileUnderage, accessToken6,
    tomorrow,
    personId1, personId2, personId3, personId4,
} from './const.stub';

import { profile as profileE, coverage as coverageE } from './data/profile.epam';
import { profile as profileA, coverage as coverageA } from './data/profile.any';
import { profile as profileB, coverage as coverageB } from './data/profile.noRel';
import { profile as profileC, coverage as coverageC } from './data/profile.bns';
import { profile as profileD, coverage as coverageD } from './data/profile.empty';
import { profile as profileF, coverage as coverageF } from './data/profile.underage';
import { orders } from './data/orders';
import { drugs, drugstores } from './data/drugs';
import { services } from './data/services';
import { serviceProviders } from './data/service_providers';
import refItems from './data/ref_items';
import refItemsCovered from './data/ref_items_covered';
import programs from './data/programs';
import feedbacks from './data/feedbacks';
import physicians from './data/physicians';
import bnsInfo from './data/bns';
import { firebase } from './firebase.mock';

/* eslint-disable */
const startDate = new Date(lastModified + 180000).toISOString();
const endDate = new Date(lastModified + 180000 + 180000).toISOString();

export const EPAM_SSO = {
    'tatsiana_shautsova@epam.com': 'магнолия',
    [`${accessToken1}@epam.com`]: accessTokenPassword,
    [`${accessToken2}@epam.com`]: accessTokenPassword,
    [`${accessToken3}@epam.com`]: accessTokenPassword,
    [`${accessToken4}@epam.com`]: accessTokenPassword,
    [`${accessToken5}@epam.com`]: accessTokenPassword,
    [`${accessToken6}@epam.com`]: accessTokenPassword,
    [accessToken1]: accessTokenPassword,
    [accessToken2]: accessTokenPassword,
    [accessToken3]: accessTokenPassword,
    [accessToken4]: accessTokenPassword,
    [accessToken5]: accessTokenPassword,
    [accessToken6]: accessTokenPassword,
};

let COUNTER = 1;

const dbUpsert = (data, body, id = body.id || body._id) => {
    if (id) {
        Object.assign(data[id], body);
    } else {
        id = COUNTER++;
        data[id] = { ...body, id, _id: id };
    }
    return id;
};

const dbDelete = (data, id) => {
    /* eslint-disable no-param-reassign */
    delete data[id];
    return id;
};

export const db = {
    profiles: {
        [profileWithEpam]: profileE,
        [profileAny]: profileA,
        [profileSimple]: profileB,
        [profileWithBns]: profileC,
        [profileEmpty]: profileD,
        [profileUnderage]: profileF,
    },
    coverage: {
        [profileWithEpam]: coverageE,
        [profileAny]: coverageA,
        [profileSimple]: coverageB,
        [profileWithBns]: coverageC,
        [profileEmpty]: coverageD,
        [profileUnderage]: coverageF,
    },
    photo: {
        [profileWithEpam]: photoUrl,
        [profileAny]: photoUrl,
        [profileSimple]: photoUrl,
        [profileWithBns]: photoUrl,
        [profileEmpty]: profileC,
        [profileUnderage]: photoUrl,
    },
    orders,
    drugs,
    drugstores,
    feedbacks,
    physicians,
};
const timetablesList = timetables(today, tomorrow)
const timeTablesHash = arrayToHash(timetablesList);

const getPhysicianId = (id) => {
    let clinicId = '';
    const _id = Object.values(db.physicians)
        .find(e => e.worksAt
            .find((item) => {
                clinicId = item.clinicId;
                return item.assignmentId === id
            }))._id;

    return { clinicId, _id }
};

const getEnrichedOrdersList = () => {
    const ordersList = Object.values(db.orders);
    const resolveStartDates = timeslotId => timeTablesHash[timeslotId].start;
    const resolveEndDates = timeslotId => timeTablesHash[timeslotId].end;
    return ordersList.map(el => ({
        ...el,
        startDate: resolveStartDates(el.timeslotId),
        endDate: resolveEndDates(el.timeslotId),
    }));
};

export default () => ({

    // --------------------------
    // link to coverer:
    'POST!/api/v2/my/profile/link-to-coverer': ({ path }) => {
        let response = { body: JSON.stringify({}) };
        if (path.slice(-2).some(part => part === 'bns')) {
            const status = path.slice(-1)[0] === '111' ? 200 : 500;
            const errorBody = path.slice(-1)[0] === '111' ? { _id: '5c2e74ad91aca54130b0c80d' } : { type : "aimd:bns-api-problem", code : "BadParameterException", detail : "Неверный номер штрих-кода" };
            response.body = JSON.stringify({ status, ...errorBody });
            response.status = status;
        }
        return response;
    },
    'POST!/api/v2/my/profile/unlink-coverer/epam': ({ path }) => ({ body: JSON.stringify({}) }),

    // --------------------------
    // user info:
    'GET!/api/v2/my/profile': ({ profile }) => ({ body: JSON.stringify(db.profiles[profile]) }),//({ status: '301' }), //
    'POST!/api/v2/my/profile': ({ body }) => ({ body: JSON.stringify(body) }),
    'PATCH!/api/v2/my/profile': ({ profile, body }) => ({ body: JSON.stringify({ ...db.profiles[profile], ...JSON.parse(body) }) }),
    'GET!/api/v2/my/family/shareable': () => ({ body: JSON.stringify([])}),
    'POST!/api/v2/my/family/share': () => ({ body: JSON.stringify({ _id: familyCode}) }),
    'POST!/api/v2/my/family/join': ({ path }) => ({ body: path.slice(-1)[0] === familyCode.toString() ? JSON.stringify({}) : null }),
    'GET!/api/v2/my/coverage': ({ profile }) => ({ body: JSON.stringify(db.coverage[profile]) }),
    'GET!/api/v2/my/profile/photo': ({ profile }) => ({ body: db.photo[profile] || photoUrl }),
    'POST!/api/v2/my/profile/add-child': ({ payload }) => ({ body: true }),
    'POST!/api/v2/my/device-set-profile': ({ path }) => ({ body: (path.slice(-1)[0] === personId2) }),

    // --------------------------
    // login:
    'POST!/adfs/oauth2/authorize': ({ body, headers }) => {
        console.log('++++++++++++', body, headers);
        if (body) {
            const { UserName, Password } = body.split('&').map(s => s.split('=')).reduce((r, [k, v]) => ({ ...r, [k]: decodeURIComponent(v) }), {});
            console.log('++++++++++++', UserName, Password, EPAM_SSO[UserName]);
            if (EPAM_SSO[UserName] === Password) {
                return {
                    status: 302,
                    headers: {
                        'Set-Cookie': `${UserName}`,
                    },
                };
            }
            return { status: 404 };
        }
        return {
            status: headers.Cookie ? 302 : 404,
            headers: {
                Location: `host?code=${headers.Cookie}`,
            },
        };
    },
    'POST!/auth/token/epam-oauth': ({ body }) => ({
        body: JSON.stringify({ access_token: `${body}`, id_token: `${body}_refresh` }),
    }),

    'GET!/api/v2/my/bns/program-details': () => ({
        body: JSON.stringify(bnsInfo),
    }),

    // --------------------------
    // orders
    'GET!/api/v2/my/providers/orders': () => ({
        body: JSON.stringify(getEnrichedOrdersList()),
    }),
    'DELETE!/api/v2/my/providers/orders/*': ({ path }) => {
        dbDelete(db.orders, path.pop());
        firebase.firestore.__notify()
        return {
            body: JSON.stringify(Object.values(db.orders)),
            status: 204,
        };
    },
    'PUT!/api/v2/my/providers/orders/*': ({ path, body }) => {
        const bodyParsed = JSON.parse(body);
        const timeslotId = bodyParsed.timetableId 
        const slot = timeTablesHash[timeslotId]
        
        dbUpsert(db.orders, ({ 
            ...bodyParsed, 
            timeslotId,
            startDate: slot.start,
            endDate: slot.end,
        }), path.slice(-1)[0]);

        firebase.firestore.__notify()
        return {
            body: JSON.stringify({}),
        };
    },
    'POST!/api/v2/my/providers/orders': ({ body, profile }) => {
        const { timetableId, assignmentId, profileId, programId } = JSON.parse(body);
        const { _id: physicianId, clinicId: branchId } = getPhysicianId(assignmentId);
        let covererCode = null;
        if (programId) {
            covererCode = profile === accessToken4 ? 'bns' : 'epam';
        }
        const slot = timeTablesHash[timetableId]
        dbUpsert(db.orders, {
            profileId,
            timeslotId: timetableId,
            startDate: slot.start,
            endDate: slot.end,
            physicianId,
            branchId,
            status: 'reserved',
            covererCode,
        });
        firebase.firestore.__notify()
 
        return {
            body: JSON.stringify({}),
        };
    },
    'GET!/api/v2/my/providers/timetables': ({ params: { assignmentId, dateStart }, profile }) => ({ // dateEnd
        body: JSON.stringify({
            assignmentId,
            onlineBookingAllowed: profile !== accessToken6,
            declineReasons: profile === accessToken6 ? [{
                weight : 70,
                code : 7,
                message : 'Action is not permitted due to user age'
            }] : null,
            timetable: timetables(dateStart).concat(timetables(head(tomorrowISOText, 'T'))),
        }),
    }),

    'GET!/api/v2/my/providers/book-capabilities': ({ params: { assignmentId }, profile }) => {
        const profileId = db.profiles[profile]._id;
        const adults = assignmentId !== '5b517955318e1e6ff' && (assignmentId !== '5bc0561b8f92416fd' || assignmentId === '5c99e973169b9f238') ? [{
            profileId: profileId,
            onlineBookingAllowed: true,
            programs: ((profileId === personId1 || profileId === personId4) && assignmentId === '5bbc6123a519ea9c4') ? ['5c2e74ad91aca54130b0c80d'] : [],
        }] : [];
        const children = assignmentId === '5b517955318e1e6ff'
            || assignmentId === '5b517959320f78a7f'
            || assignmentId === '5bc0561b8f92416fd'
            || assignmentId === '5bbc6123a519ea9c4'
            || assignmentId === '5b51794ce0f569ad5'
            || assignmentId === '5b517950de7bc469c' ? [{
                profileId: '5bd1bd53cff47e000d5d7428',
                onlineBookingAllowed: true,
                programs: assignmentId === '5bbc6123a519ea9c4' ? ['5c2e74ad91aca54130b0c80e'] : [],
            }, {
                profileId: '5bd1bd53cff47e000d5d7429',
                onlineBookingAllowed: true,
                programs: assignmentId === '5bbc6123a519ea9c4' ? ['5c2e74ad91aca54130b0c80e'] : [],
            },
            {
                profileId: '5bd1bd53cff47e000d5d7430',
                onlineBookingAllowed: true,
            }] : [];

        return ({
            body: JSON.stringify([
                ...adults,
                ...children,
            ]),
        });
    },

    // --------------------------
    // feedback
    'GET!/api/v2/my/feedback': ({ params: { itemId } }) => {
        let items = itemId === '5b3f601db72a7d317' ?  Object.values(feedbacks).filter(({ forItemId }) => forItemId === itemId) :  Object.values(feedbacks);
        items = items.map(e => ({ ...e, forItemId: itemId }));

        return {
            body: JSON.stringify({
                start: 0,
                count: items.length,
                totalCount: items.length,
                items,
            }),
        };
    },
    'PUT!/api/v2/my/feedback': ({ body, profile }) => {
        const item = JSON.parse(body);
        item.userProfileId = PERSON_BY_PROFILE[profile];
        item.userName = item.anonymous ? null : db.profiles[profile].fullNameRu;

        dbUpsert(feedbacks, item);
        return {
            body: '{}',
        };
    },
    'DELETE!/api/v2/my/feedback/*': ({ path }) => {
        dbDelete(feedbacks, path.slice(-1)[0]);
        return {
            body: '{}',
            status: 200,
        };
    },

    // --------------------------
    // tabletka
    'GET!/api/v2/public/tabletka/query': ({ params }) => {
        if (params['search.autocomplete']) {
            const opts = params['search.autocomplete'];
            return ({
                body: JSON.stringify(Object.values(db.drugs).filter(e => e.ls_name.toLocaleLowerCase().includes(opts.q.toLocaleLowerCase()))),
            });
        }
        if (params['search.drugs']) {
            const opts = params['search.drugs'];
            return ({
                body: JSON.stringify(Object.values(db.drugs).filter(e => e.ls_name.includes(opts.ls))),
            });
        }
        if (params['search.aptsearch']) {
            const opts = params['search.aptsearch'];
            return ({
                body: JSON.stringify(Object.values(db.drugstores).filter(e => e.LS_NUM === opts.lsnum)),
            });
        }
        return null;
    },
    // --------------------------
    // ums
    'GET!/api/v2/my/ums/program': ({ path }) => ({ body: JSON.stringify(path.slice(-1)[0] === 'program' ? {} : programs[path.slice(-1)[0]]) }),
    'GET!/api/v2/my/ums/ref-items': () => ({ body: JSON.stringify({}) }),
    'GET!/api/v2/public/ums/tree-spec/version': () => ({ body: JSON.stringify({ version: { _id: '5bbd02c57cf5cf2c1cc9b64c', createdAt: '2018-10-09T19:34:29.122Z' } }) }),
    'GET!/api/v2/public/ums/tree-spec/latest': () => ({ body: JSON.stringify(services) }),
    'GET!/api/v2/public/ums/item-providers': () => ({ body: JSON.stringify(serviceProviders) }),
    'GET!/api/v2/public/ums/ref-opts-items': ({ params }) => ({ body: JSON.stringify((params.program ? refItemsCovered : refItems)[params.itemOrOption]) }),
    'GET!/api/v2/my/services': () => ({ body: JSON.stringify({}) }),
    'POST!/api/v2/admin/services/5b64c82fc9e77c000c59ed75/reset': () => ({ body: JSON.stringify({}) }),
    'POST!/api/v2/my/phone-login/init': ({ body }) => { const { phone } = JSON.parse(body); return ({ body: JSON.stringify({ id: `${phone}`, needsHumanCheck: false })});},
    'POST!/api/v2/my/phone-login/confirm-sms/*': ({ path, body: code }) => {
        const phone = `${path[path.length-1]}`
        const hasPhone = Object.values(USER_PHONE_BY_PROFILE).some(e => {
            const credentials = e.split(':');
            return `${credentials[0]}${credentials[1]}` === phone && code === credentials[2];
        });
        return hasPhone ? ({ body: `${phone}`}) : { body: JSON.stringify({
            type: "aimd:my-api-problem",
            code: "INVALID_INPUT",
            title: "Недопустимое входные данные",
            detail: "Код не соответствует",
            status: 500
          }), status: 500 };
        }
});
