// eslint-disable-next-line max-statements
export const extendDrugData = (drug, src) => {
    const result = { ...drug };
    src &&
        Object.entries(src).forEach(([key, value]) => {
            if (!result[key]) {
                result[key] = value;
            }
        });
    if (!result?.minPrice && result?.min_price) {
        result.minPrice = Number(result.min_price);
        if (Number.isNaN(result.minPrice)) {
            result.minPrice = 0;
        }
    }

    if (!result?.maxPrice && result?.max_Price) {
        result.maxPrice = Number(result.max_price);
        if (Number.isNaN(result.maxPrice)) {
            result.maxPrice = 0;
        }
    }

    if (!result?.qty) {
        result.qty = 1;
    }

    if (src?.qty) {
        result.qty = src.qty;
    }

    if (typeof result.selected === 'undefined') {
        result.selected = true;
    }

    if (result?.nlec_name) {
        result.components = result.nlec_name;
    }
    if (result?.firm_name) {
        result.producer = result.firm_name;
    }
    if (result?.country_name) {
        result.country = result.country_name;
    }
    if (result?.tar_name) {
        result.tar = result.tar_name;
    }
    result.requirePrescript = Object.R(
        result.otc_rx === 'OTC' || !result.otc_rx ? 'titles.withoutPrescript' : 'titles.requirePrescript',
    );
    return result;
};
