// noinspection JSUnusedGlobalSymbols

import {
    Box,
    CoverableBy,
    Icon,
    React,
    selectOptions,
    Subtitle,
    Title,
    TouchableOpacity,
    VBox,
    View,
} from '../../common';
import { sortBy } from '../../utils';
import { snippets as s } from './styles';

export const OptionsListItem = ({
    name,
    options,
    selected,
    isLastItem,
    onSelect,
    selectedValuesJSX = sortBy(options.filter(e => selected[e.id]))
        .map(e => <Subtitle key={e.id} style={[s.serviceOptionText, { fontSize: 12 }]}>{e.name}</Subtitle>),
    onPress = () => selectOptions({
        get title() {
            return name[name.length - 1] === '*' ? name.slice(0, name.length - 1) : name;
        },
        subtitle: 'titles.someOptionsAreIncompatible',
        data: sortBy(options.map(e => ({
            id: e.id,
            coverer: e.coverer,
            isLimited: e.isLimited,
            get name() {
                return `${e.name}`;
            },
            selected: !!selected[e.id],
        }))),
        multi: true,
        onSelect,
        itemMarker: ({
            coverer,
            isLimited,
        }) => (<CoverableBy.Dot coverer={coverer} isLimited={isLimited}/>),
    }),
}) => (
    <View>
        <TouchableOpacity style={s.optionsListItemContainer} onPress={onPress}>
            <Box centered justify="space-between" style={s.optionsListItem}>
                <VBox>
                    <Title id={name}/>
                    {selectedValuesJSX}
                </VBox>
                <Icon.Right/>
            </Box>
            <View style={[s.optionsListItemBorder, isLastItem ? null : {
                marginLeft: 20,
                marginRight: 24,
            }]}/>
        </TouchableOpacity>
    </View>
);
