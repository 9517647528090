import { Linking, NativeModules } from 'react-native';
import {
    AppState,
    Button,
    ButtonsGroup,
    Icon,
    List,
    NavigationItem,
    Page,
    Platform,
    React,
    Switch,
    Text,
    TouchableOpacity,
    View,
} from '../../common';
import { AppHeader } from '../../combo';
import { getEnums } from '../../i18n';
import actions from '../../actions';
import { getNotificationsSettings } from '../../selectors';
import { checkPushNotificationsPermission } from '../../services/device';
import { notificationSettings as styles } from './styles';

export const renderListItem = (name, isSelected, onSelect, isDisabled) => (
    <TouchableOpacity style={styles.listItem} disabled={isDisabled} onPress={onSelect}>
        <View style={{
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 10,
        }}>
            <View style={[
                styles.checkbox,
                isSelected && styles.checkboxSelected,
                isDisabled && !isSelected && styles.checkboxDisabled,
                isDisabled && isSelected && styles.checkboxSelectedDisabled,
            ]}
            >
                {isSelected && <Icon name="check" size={14} color="white"/>}
            </View>
            <Text
                style={[isSelected ? styles.selectionText : styles.text, isDisabled && styles.textDisabled]}>{name}</Text>
        </View>
    </TouchableOpacity>
);

export const Footer = ({ onCancel, onApply, disabled }) => (
    <ButtonsGroup style={styles.confirmationButtons}>
        <Button
            disabled={disabled}
            disabledStyle={{ backgroundColor: 'white' }}
            titleStyles={{ color: '#555' }}
            transparent
            action={onCancel}
            title="buttons.cancel"
        />
        <Button disabled={disabled} primary action={onApply} title="buttons.save"/>
    </ButtonsGroup>
);

export const NotificationsSettings = Page.register(
    ({
        navigation,
        setState,
        reminderTimes = getEnums('reminderTime'),
        notificationsSettings = {},
        haveNotificationsPermission,
        doNotNotify = haveNotificationsPermission ? (notificationsSettings.doNotNotify || false) : true,
        selectedRemainderTimes = notificationsSettings.selectedRemainderTimes || ['2hours', '1dayMorning'],
        listItems = reminderTimes.map(({
            id,
            name,
        }) => ({
            id,
            name,
            isSelected: selectedRemainderTimes.some(timeId => timeId === id),
        })),
        onAppStateChange = async (nextAppState) => {
            if (nextAppState === 'active') {
                const havePermission = await checkPushNotificationsPermission();
                setState({ haveNotificationsPermission: havePermission });
                if (!havePermission) {
                    setState({ doNotNotify: true });
                }
            }
        },
        openSettings = () => {
            try {
                if (Platform.OS === 'android') {NativeModules.SettingsModule.openAppNotificationsSettings();} else if (Platform.OS === 'ios') {
                    Linking.openURL(`app-settings:`);
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log('openSettings error: ', e);
            }
        },
        onNotifyChange = (value) => {
            setState({ doNotNotify: value });
            if (!haveNotificationsPermission && !value) {
                openSettings();
            }
        },
        onRemainderTimeSelect = (id) => {
            const index = selectedRemainderTimes.indexOf(id);
            const result = [...selectedRemainderTimes];
            if (index >= 0) {
                result.splice(index, 1);
            } else {
                result.push(id);
            }
            setState({ selectedRemainderTimes: result });
        },
        cancel = () => navigation.goBack(),
        apply = async () => {
            await actions.setNotificationsSettings({
                doNotNotify,
                selectedRemainderTimes,
            });
            actions.syncVisits();
            navigation.goBack();
        },
        footer = <Footer onCancel={cancel} onApply={apply} disabled={!haveNotificationsPermission} />,
        changeListener,
    }) => (
        <Page
            noscroll
            footer={footer}
            hint={haveNotificationsPermission ? 'hint.commonNotificationsSettings' : {
                level: 'error',
                message: 'titles.allowNotificationsPromptShort',
            }}
            onDidMount={() => {
                if (Platform.OS !== 'web') {
                    // eslint-disable-next-line no-param-reassign
                    changeListener = AppState.addEventListener('change', onAppStateChange);
                }
                checkPushNotificationsPermission()
                    .then((havePermission) => {
                        setState({ haveNotificationsPermission: havePermission });
                    });
            }}
            onWillUnmount={() => {
                if (Platform.OS !== 'web' && changeListener) {
                    // AppState.removeEventListener('change', onAppStateChange);
                    changeListener.remove();
                }
            }}
        >
            <NavigationItem
                title="titles.doNotRemind"
                rightIcon={
                    <Switch
                        accessibilityLabel="switch:disable-notifications"
                        // disabled={!haveNotificationsPermission}
                        onTintColor="#6987D6"
                        thumbTintColor="#5880D0"
                        value={doNotNotify}
                        onValueChange={onNotifyChange}
                    />
                }
            />
            <List
                data={listItems}
                renderItem={({
                    item: {
                        id,
                        name,
                        isSelected,
                    },
                }) => renderListItem(name, isSelected, () => onRemainderTimeSelect(id), doNotNotify)}
            />
        </Page>
    ),
    { notificationsSettings: { from: getNotificationsSettings } },
    { header: () => <AppHeader/> },
);
