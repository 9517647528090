import {
    useEffect, useMemo, useRef, useState,
} from 'react';
import { TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { specializationSearchBar } from '../screens/Doctors/style';
import { COLORS } from '../styles/colors';
import { getSuggestions } from '../selectors/forms';
import { sortBy } from '../utils';
import { placeholderTextColor } from './input';
import {
    Icon, Img, InputWrapper, List, Platform, React, SearchBarNative, Text, Title, View,
} from '.';

// eslint-disable-next-line complexity,max-statements
export const SuggestionEdit = ({
    placeholder,
    list: fullList = [],
    onSelect,
    value = '',
    filterFunction = (item, v) => item?.name?.toLowerCase?.()?.includes(v?.toLowerCase?.()),
    style,
    autoSelectFirst,
    autoSelectOne,
    onClear,
    readOnly,
    onChangeText,
    showCloseIcon,
    inputStyle,
    clickEvent,
    onFocus,
    suggestionKey,
    onlyHistory = true,
    sortFn = sortBy,
    firstClickClear = true,
    timeoutOnFocus,
    listSelector,
}) => {
    const list = listSelector ? useSelector(listSelector) : fullList;
    const [text, setText] = useState(value);
    const [suggestionList, setSuggestionList] = useState([]);
    const [isSuggestionShow, setIsSuggestionShow] = useState(false);
    const searchBar = useRef();
    const [selection, setSelection] = useState();
    const suggestionHistory = useSelector(getSuggestions);
    const historyLast = useMemo(
        () => sortFn(sortBy(suggestionHistory?.[suggestionKey] ?? [], '-index').slice(0, 3), 'name'),
        [suggestionHistory, suggestionKey],
    );
    const historyRest = useMemo(
        () => sortFn(sortBy(suggestionHistory?.[suggestionKey] ?? [], '-index').slice(3), 'name'),
        [suggestionHistory, suggestionKey],
    );

    const preparedList = useMemo(() => {
        return [
            ...historyLast,
            // ...sortFn(
            ...[
                ...list.filter(
                    ({ id }) => !historyLast.find(i => i?.id === id) && !historyRest?.find?.(i => i?.id === id),
                ),
                ...(onlyHistory ? [] : historyRest),
            ],
            // 'name',
            // ),
        ];
    }, [list, historyLast, historyRest]);

    useEffect(() => {
        clickEvent && setIsSuggestionShow(false);
    }, [clickEvent]);

    const onEndEditing =
        Platform.OS === 'android'
            ? () => {
                if (!selection?.start) {
                    setSelection({ start: 0 });
                }
            }
            : null;
    const onSelectionChange =
        Platform.OS === 'android'
            ? ({ nativeEvent: { selection: { start, end } } }) => {
                setSelection({
                    start,
                    end,
                });
                text?.length && setIsSuggestionShow(true);
            }
            : () => {
                text?.length && setIsSuggestionShow(true);
            };

    const getSuggestionList = (l, t) => l?.filter(item => filterFunction(item, t));
    useEffect(() => {
        setText(value);
        onSelectionChange({
            nativeEvent: {
                selection: {
                    start: 0,
                    end: 0,
                },
            },
        });
    }, [value]);

    const doChangeTextOrList = () => {
        const suggest = getSuggestionList(preparedList, text);
        setSuggestionList(suggest);
        if (autoSelectOne && suggest?.length === 1 && text === '') {
            setText(suggest?.[0]?.name);
            onSelect(suggest?.[0]);
        }

        if (autoSelectFirst && suggest?.length > 0 && text === '') {
            setText(suggest?.[0]?.name);
        }
    };

    useEffect(() => {
        doChangeTextOrList();
    }, [text, list]);

    const closeHandler = (t) => {
        setTimeout(() => {
            setIsSuggestionShow(false);
        }, 0);
        if (t) {
            setText(t);
            const found = preparedList.find(({ name }) => name?.toLowerCase?.() === t?.toLowerCase?.());
            if (found) {
                onSelect && onSelect(found);
            }
        }
        searchBar?.current?.blur?.();
    };

    const clearHandler = () => {
        // actions.setFormValue('umsServices', []);
        // closeHandler();
        searchBar.current?.blur?.();
        if (firstClickClear) {
            setIsSuggestionShow(false);
            setText('');
            onClear?.('');
            onChangeText?.('');
        } else if (isSuggestionShow && !showCloseIcon) {
            setTimeout(() => {
                setIsSuggestionShow(false);
            }, 0);
        } else if (text) {
            setText('');
            onClear?.('');
            onChangeText?.('');
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setIsSuggestionShow(false);
        }, 0);
    }, []);

    const changeTextHandler = (v) => {
        setText(v);
        onChangeText?.(v);
    };

    return (
        <View>
            <View
                style={[
                    specializationSearchBar.searchBar,
                    style,
                    isSuggestionShow
                        ? {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }
                        : {
                            borderColor: COLORS.EDIT_BORDER,
                            borderBottomLeftRadius: 3,
                            borderBottomRightRadius: 3,
                        },
                ]}>
                <InputWrapper>
                    {readOnly && Platform.OS === 'web' ? (
                        <View
                            style={[
                                specializationSearchBar.container,
                                {
                                    backgroundColor: 'transparent',
                                    borderTopColor: 'transparent',
                                    borderBottomColor: 'transparent',
                                    width: '90%',
                                    padding: 5,
                                    margin: 5,
                                    paddingLeft: 0,
                                    outlineWidth: 0,
                                    flexDirection: 'row',
                                },
                                Platform.OS === 'web' ? { outline: 'none' } : {},
                            ]}>
                            <Icon
                                name="search"
                                size={25}
                                color="#9C9C9C"
                                style={{
                                    marginTop: 5,
                                    marginHorizontal: 5,
                                }}
                            />
                            <Text
                                style={[
                                    specializationSearchBar.input,
                                    {
                                        marginLeft: 5,
                                        outlineWidth: 0,
                                    },
                                    Platform.OS === 'web' ? { outline: 'none' } : {},
                                ]}>
                                {text}
                            </Text>
                        </View>
                    ) : (
                        <SearchBarNative
                            ref={(r) => {
                                searchBar.current = r;
                            }}
                            accessibilityLabel="search"
                            searchIcon={Icon.SearchBarFind}
                            clearIcon={null}
                            placeholder={placeholder ? Object.R(placeholder) : ''}
                            containerStyle={[
                                specializationSearchBar.container,
                                {
                                    backgroundColor: 'transparent',
                                    borderTopColor: 'transparent',
                                    borderBottomColor: 'transparent',
                                    width: '90%',
                                    padding: 0,
                                    margin: 0,
                                    outlineWidth: 0,
                                    // borderWidth: 1,
                                },
                                Platform.OS === 'web' ? { outline: 'none' } : {},
                            ]}
                            inputStyle={[
                                specializationSearchBar.input,
                                {
                                    outline: 'none',
                                    outlineWidth: 0,
                                    marginLeft: 0,
                                },
                                inputStyle,
                            ]}
                            inputContainerStyle={[
                                specializationSearchBar.input,
                                {
                                    paddingVertical: 0,
                                    outlineWidth: 0,
                                },
                                Platform.OS === 'web' ? { outline: 'none' } : {},
                            ]}
                            leftIconContainerStyle={{ width: 30 }}
                            value={text}
                            onCancel={readOnly ? null : clearHandler}
                            {...(Platform.OS === 'web' ? {} : { onClear: clearHandler })}
                            onChangeText={changeTextHandler}
                            underlineColorAndroid="transparent"
                            onFocus={() => {
                                const focusHandler = () => {
                                    changeTextHandler(text);
                                    doChangeTextOrList();
                                    setIsSuggestionShow(true);
                                    onFocus?.();
                                };
                                if (timeoutOnFocus === null || timeoutOnFocus === undefined) {
                                    focusHandler();
                                } else {
                                    setTimeout(() => {
                                        focusHandler();
                                    }, timeoutOnFocus);
                                }
                            }}
                            onEndEditing={onEndEditing}
                            editable={!readOnly}
                            selection={selection}
                            onSelectionChange={onSelectionChange}
                            placeholderTextColor={placeholderTextColor}
                            // onBlur={onBlurHandler}
                        />
                    )}
                </InputWrapper>
                {!!isSuggestionShow && !!showCloseIcon && (
                    <Icon.Up
                        style={
                            // eslint-disable-next-line no-undef
                            Platform.OS === 'web' && !window.isDesktop
                                ? {
                                    marginHorizontal: 0,
                                    width: 20,
                                    marginRight: 16,
                                }
                                : { marginRight: 0 }
                        }
                        onPress={() => {
                            searchBar.current?.blur?.();
                            setTimeout(() => setIsSuggestionShow(false), 0);
                        }}
                    />
                )}
                {((text && text?.length) || isSuggestionShow) && !readOnly ? (
                    <Icon.SearchBarClose
                        iconStyle={
                            // eslint-disable-next-line no-undef
                            Platform.OS === 'web' && !window.isDesktop
                                ? {
                                    marginHorizontal: 0,
                                    width: 20,
                                    marginRight: 16,
                                }
                                : {
                                    marginRight: 8,
                                    marginLeft: 0,
                                }
                        }
                        onPress={clearHandler}
                    />
                ) : null}
            </View>
            {isSuggestionShow ? (
                <List
                    style={[specializationSearchBar.suggestionsList, { zIndex: 10000 }]}
                    count={suggestionList && suggestionList.length}
                    data={suggestionList}
                    nestedScrollEnabled
                    keyboardShouldPersistTaps="handled"
                    renderItem={({
                        item: {
                            id,
                            name,
                            // isPreviousSelection,
                        },
                    }) => (
                        <TouchableOpacity
                            onPress={() => {
                                closeHandler(name);
                            }}>
                            <View
                                style={[
                                    specializationSearchBar.suggestionsItem,
                                    id === suggestionList[suggestionList.length - 1]?.id
                                        ? { borderBottomWidth: 0 }
                                        : {},
                                ]}>
                                {historyLast.find(i => i?.id === id) ? <Img.Time /> : null}
                                <Title
                                    style={[
                                        historyLast.find(i => i?.id === id)
                                            ? specializationSearchBar.previousSuggestionItemText
                                            : specializationSearchBar.suggestionItemText,
                                        { paddingBottom: name === text && Platform.OS === 'android' ? 4 : 0 },
                                    ]}>
                                    {name}
                                </Title>
                            </View>
                        </TouchableOpacity>
                    )}
                    ListEmptyComponent={<View />}
                />
            ) : null}
        </View>
    );
};
