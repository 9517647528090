const maxResults = 3;

export const priorityObj = {
    doctors: 1,
    specializations: 0,
};

export const getMaxCountOfSections = (obj = {}) => {
    const sectionsCount = Object.keys(obj)?.length;
    const fullWeight = sectionsCount * maxResults;
    const fullActualWeight = Object.values(obj)?.reduce(
        (p, c) => (c.length >= maxResults ? p + maxResults : p + c.length),
        0,
    );

    let d = fullWeight - fullActualWeight;

    const countObj = {};

    Object.entries(obj)
        .sort(([, value1], [, value2]) => {
            if (value1.priority < value2.priority) {
                return -1;
            } else if (value1.priority > value2.priority) {
                return 1;
            }
            return 0;
        })
        .forEach(([key, value]) => {
            const maxToRender = value.length < maxResults ? value.length : maxResults;
            if (value.length > maxResults && d) {
                if (value.length >= maxResults + d) {
                    countObj[key] = maxToRender + d;
                } else {
                    const additionalToRender = maxResults + d - value.length + 1;
                    countObj[key] = maxToRender + additionalToRender;
                    d -= additionalToRender;
                }
            } else {
                countObj[key] = maxToRender;
            }
        });

    return countObj;
};

export const getLenghtObj = (data = {}) => {
    const keys = Object.keys(data);
    const result = {};
    keys.forEach((key) => {
        result[key] = {
            priority: priorityObj[key],
            length: data[key]?.length,
        };
    });
    return result;
};
