import { React } from '../../common';
import { ServiceListItem } from './snippets';
import { AnimatedFlatlist } from './AnimatedFlatlist';
import { getHeaderSummary } from './utils';

export const GroupsList = ({
    data,
    onItem,
    currentProgram,
    headerComponents,
    isProgrammSelectorVisible,
    buttonLeft,
    fromSearch,
}) => {
    const headerSummaryGroup = getHeaderSummary(isProgrammSelectorVisible, fromSearch, 'group');
    return (
        <AnimatedFlatlist
            data={data}
            extraData={currentProgram}
            renderItem={({ item }) => <ServiceListItem onItem={onItem} item={item} currentProgram={currentProgram}/>}
            keyExtractor={item => item.id}
            headerSummary={headerSummaryGroup}
            listContentStartPosition={headerSummaryGroup}
            headerComponents={headerComponents}
            buttonLeft={buttonLeft}
        />

    );
};

export const ItemsList = ({
    data,
    onItem,
    currentProgram,
    isProgrammSelectorVisible,
    headerComponents,
    buttonLeft,
    fromSearch,
}) => {
    const headerSummaryItem = getHeaderSummary(isProgrammSelectorVisible, fromSearch, 'item');
    return (
        <AnimatedFlatlist
            data={data}
            extraData={currentProgram}
            renderItem={({ item }) => <ServiceListItem onItem={onItem} item={item} currentProgram={currentProgram}/>}
            keyExtractor={item => item.id}
            headerSummary={headerSummaryItem}
            listContentStartPosition={headerSummaryItem}
            headerComponents={headerComponents}
            buttonLeft={buttonLeft}
        />
    );
};
