export default {
    '5c2e74ad91aca54130b0c80d': {
        programs: [
            { _id: '5c2e74ad91aca54130b0c80d', name: 'Взрослые (Минск)' },
        ],
        providers: [
            {
                _id: '5b46096c302c5a36f',
                name: 'Минск, пр. Независимости, д. 58',
                shortName: 'Лодэ на Независимости',
            },
            {
                _id: '5b4c5eb620ae653c1',
                name: 'Минск, ул. Ф. Скорины, д. 11',
                shortName: 'РЦ "Элеос',
            },
        ],
        providersBranches: [],
        coverage: {
            '5c2e74ad91aca54130b0c80d': {
                '5b46096c302c5a36f': {
                    '5bbd02c57cf5cf2c1cc9b794': {
                        coverage: 1,
                        _id: '5cadfe0e00e7ed995c59a503',
                    },
                    '5bbd02c57cf5cf2c1cc9b799': {
                        coverage: 1,
                        _id: '5cadfe1000e7ed995c59b13d',
                    },
                    '5bdb4a1acc5c16192f4a4c7a': {
                        coverage: 1,
                        _id: '5cadfe0f00e7ed995c59aa1f',
                    },
                    '5bbd02c57cf5cf2c1cc9b788': {
                        coverage: 1,
                        _id: '5cadfe1000e7ed995c59ada1',
                    },
                    '5c8faebcb8e948640bc63395': {
                        coverage: 1,
                        optionCoverage: [{ '5c8b450db8e948640bc314ea': 1 }],
                        _id: '5cadfe0f00e7ed995c59a703',
                    },
                    '5bbd02c57cf5cf2c1cc9ba9c': {
                        coverage: 1,
                        _id: '5cadfe1100e7ed995c59bafd',
                    },
                    '5bbd02c57cf5cf2c1cc9b789': {
                        coverage: 1,
                        _id: '5cadfe1000e7ed995c59ada5',
                    },
                    '5bf268f5ce00ee27d0a7f3a9': {
                        coverage: 1,
                        _id: '5cadfe0f00e7ed995c59aa07',
                    },
                    '5bbd02c57cf5cf2c1cc9b699': {
                        coverage: 1,
                        optionCoverage: [{ '5bbd02c57cf5cf2c1cc9bfae': 1 }],
                        _id: '5cadfe0e00e7ed995c59a355',
                    },
                    '5bbf2447213a0475077ba40b': {
                        coverage: 1,
                        optionCoverage: [
                            { '5bbd02c57cf5cf2c1cc9c070': 1 },
                            { '5bbd02c57cf5cf2c1cc9c070': 1 },
                        ],
                        _id: '5cadfe0e00e7ed995c59a3c1',
                    },
                    '5bc45fc3213a0475077c615e': {
                        coverage: 1,
                        _id: '5cadfe0f00e7ed995c59a9ed',
                    },
                    '5be57e5fcc5c16272617de38': {
                        coverage: 1,
                        optionCoverage: [
                            { '5be57d70cc5c16272617de31': 1 },
                            { '5be57d70cc5c16272617de31': 1 },
                        ],
                        _id: '5cadfe0e00e7ed995c59a399',
                    },
                    '5c3ed003ce00ee3342498709': {
                        coverage: 1,
                        optionCoverage: [
                            { '5bbd02c57cf5cf2c1cc9bd08': 1 },
                            { '5bc4af49213a0475077c646a': 1 },
                            { '5bbd02c57cf5cf2c1cc9bd07': 1 },
                        ],
                        _id: '5cadfe1000e7ed995c59ae59',
                    },
                    '5c891306b37dd526a112b350': {
                        coverage: 1,
                        optionCoverage: [{ '5bd944b7cc5c163725c27d42': 1 }],
                        _id: '5cadfe0f00e7ed995c59aa23',
                    },
                    '5bcda7c3213a045efd0ca919': {
                        coverage: 1,
                        optionCoverage: [{ '5bd944b7cc5c163725c27d42': 1 }],
                        _id: '5cadfe0f00e7ed995c59aaad',
                    },
                    '5bcc90ed213a045efd0c7906': {
                        coverage: 1,
                        _id: '5cadfe0e00e7ed995c59a379',
                    },
                    '5bc72954213a041a29835a6e': {
                        coverage: 1,
                        optionCoverage: [
                            {
                                '5bc477d0213a0475077c622e': 1,
                                '5c9489feb8e948640bc9b151': 1,
                                '5bbd02c57cf5cf2c1cc9c09f': 1,
                                '5bbd02c57cf5cf2c1cc9c098': 1,
                            },
                        ],
                        _id: '5cadfe1100e7ed995c59b9ed',
                    },
                    '5bbd02c57cf5cf2c1cc9b79c': {
                        coverage: 1,
                        _id: '5cadfe1000e7ed995c59b159',
                    },
                    '5bbd02c57cf5cf2c1cc9b79d': {
                        coverage: 1,
                        _id: '5cadfe1000e7ed995c59b15d',
                    },
                    '5bbd02c57cf5cf2c1cc9bbc8': {
                        coverage: 1,
                        optionCoverage: [
                            {
                                '5bbd02c57cf5cf2c1cc9bc21': 1,
                                '5bbd02c57cf5cf2c1cc9bc1e': 1,
                                '5bbd02c57cf5cf2c1cc9bc1f': 1,
                            },
                            {
                                '5bbd02c57cf5cf2c1cc9bc22': 1,
                                '5bbd02c57cf5cf2c1cc9bc1e': 1,
                                '5bbd02c57cf5cf2c1cc9bc1f': 1,
                            },
                        ],
                        _id: '5cadfe0e00e7ed995c59a251',
                    },
                    '5bbd02c57cf5cf2c1cc9baa7': {
                        coverage: 1,
                        _id: '5cadfe1100e7ed995c59baf5',
                    },
                    '5bbd02c57cf5cf2c1cc9baa6': {
                        coverage: 1,
                        _id: '5cadfe1100e7ed995c59baf9',
                    },
                    '5bbd02c57cf5cf2c1cc9bbd6': {
                        coverage: 1,
                        optionCoverage: [
                            {
                                '5c2f16ffce00ee3342456431': 1,
                                '5bbd02c57cf5cf2c1cc9bc21': 1,
                            },
                            {
                                '5c2f16ffce00ee3342456431': 1,
                                '5bbd02c57cf5cf2c1cc9bc22': 1,
                            },
                        ],
                        _id: '5cadfe0f00e7ed995c59a99b',
                    },
                    '5bbd02c57cf5cf2c1cc9b7fd': {
                        coverage: 1,
                        _id: '5cadfe0e00e7ed995c59a4e1',
                    },
                    '5bbd02c57cf5cf2c1cc9b7ff': {
                        coverage: 1,
                        _id: '5cadfe0e00e7ed995c59a4c9',
                    },
                    '5bf2d386ce00ee27d0a7f63e': {
                        coverage: 1,
                        optionCoverage: [{ '5bbd02c57cf5cf2c1cc9bec2': 1 }],
                        _id: '5cadfe1100e7ed995c59ba1f',
                    },
                    '5bbd02c57cf5cf2c1cc9b86f': {
                        coverage: 1,
                        _id: '5cadfe0f00e7ed995c59a8e1',
                    },
                    '5bbd02c57cf5cf2c1cc9b6e8': {
                        coverage: 1,
                        _id: '5cadfe0e00e7ed995c59a3bb',
                    },
                    '5bbd02c57cf5cf2c1cc9bb9d': {
                        coverage: 1,
                        optionCoverage: [{ '5bbf0c2c213a0475077ba3b1': 1 }],
                        _id: '5cadfe1100e7ed995c59bbdd',
                    },
                    '5bbd02c57cf5cf2c1cc9b806': {
                        coverage: 1,
                        _id: '5cadfe0f00e7ed995c59acc7',
                    },
                    '5bbd02c57cf5cf2c1cc9bbc7': {
                        coverage: 1,
                        optionCoverage: [
                            {
                                '5c500b9bce00ee33424e0666': 1,
                            },
                        ],
                        _id: '5cadfe0e00e7ed995c59a252',
                    },
                },
            },
        },
    },
};
