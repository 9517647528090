import { useEffect } from 'react';
import {
    Button, ButtonsGroup, Page, Platform, React, Section, Selector, Switch, Text, View,
} from '../../common';
import { getEnums } from '../../i18n';
import actions from '../../actions';
import { getAcodeConfirmation, getAllowScreenshots } from '../../selectors';
import { isBioProvided, USE_BIO } from '../../services/fingerprintScanner';
import { COLORS } from '../../styles/colors';
import { notificationSettings as styles } from './styles';

export const AccessSettings = Page.register(
    ({
        navigation,
        setState,
        acodeConfirmation,
        data = [],
        selected,
        cancel = () => navigation.goBack(),
        allowScreenshots,
        allowScreen = allowScreenshots,
        apply = async () => {
            await actions.setAcodeConfirmation(selected);
            await actions.setAllowScreenshots(allowScreen);
            navigation.goBack();
        },
        footer = (
            <ButtonsGroup style={styles.confirmationButtons}>
                <Button
                    // disabledStyle={{ backgroundColor: 'white' }}
                    titleStyles={{ color: '#555' }}
                    transparent
                    action={cancel}
                    title="buttons.cancel"
                />
                <Button primary action={apply} title="buttons.save"/>
            </ButtonsGroup>
        ),
    }) => {
        const onSelect = item => setState({ selected: item?.id });
        const loadData = () => {
            isBioProvided()
                .then((bio) => {
                    const enumType = `confirmSettings${USE_BIO ? bio?.biometryType ?? 'Bio' : ''}`;
                    let bioData = getEnums(enumType);
                    if (!bio?.available) {
                        bioData = bioData.map((item, idx) => (!idx ? item
                            : {
                                ...item,
                                subtitle: Object.R(`bio${bio?.biometryType ?? ''}.${bio?.error ?? ''}`),
                                disabled: !!bio?.error,
                            }));
                    }
                    setState({ data: bioData });
                    if (bioData.find(item => item?.id === acodeConfirmation)) {
                        setState({ selected: acodeConfirmation });
                    } else {
                        setState({ selected: bioData?.[0]?.id });
                    }
                })
                .catch(() => {
                });
        };

        useEffect(() => {
            loadData();
        }, []);
        return (
            <Page
                // noscroll
                footer={footer}
                hint="titles.accessSettingsHint"
            >
                <View>
                    <Section style={{ flex: 1 }} title="titles.accessSettingsSectionTitle" capitalize>
                        <Selector radio data={data} selected={selected} onSelect={onSelect}/>
                    </Section>
                    {Platform.OS === 'android' && <>
                        <View style={{
                            flexDirection: 'row',
                            paddingTop: 10,
                        }}>
                            <View style={{ flex: 9 }}>
                                <Text numLines={2}
                                    style={{
                                        // flexWrap: 'wrap',
                                        marginHorizontal: 24.,
                                        fontSize: 16,
                                        color: COLORS.STANDARD_GRAY,
                                    }}>{Object.R('titles.allowScreenshots')}</Text>
                            </View>
                            <View
                                style={{
                                    flex: 1,
                                    marginHorizontal: 24,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Switch value={allowScreen}
                                    style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                                    activeThumbColor="#4770C0"
                                    activeTrackColor="#9FB9FF"
                                    onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                                    thumbTintColor="#5C85DD"
                                    trackColor={{
                                        false: '#CFCFCF',
                                        true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                                    }}
                                    onValueChange={(v) => {
                                        setState({ allowScreen: v });
                                    }}/>
                            </View>
                        </View>
                    </>}
                </View>
            </Page>
        );
    },
    {
        acodeConfirmation: { from: getAcodeConfirmation },
        allowScreenshots: { from: getAllowScreenshots },
    },
);
