import {
    Box, React, Section, SubtitleDark, VBox, View,
} from '../../common';
import { invoiceSingleService as s } from './style';
/* eslint-disable complexity */
export const InvoiceSingleService = (props) => {
    const {
        umsServiceName,
        amount,
        totalCost,
        status,
        forConfirm,
        forNewList,
        forReviewedInfo,
        forMultiService,
    } = props;
    const commonStyles = [
        forConfirm ? s.forConfirmCommon : null,
        forNewList ? s.forNewListCommon : null,
        forReviewedInfo ? s.forReviewedInfoCommon : null,
        forMultiService ? s.forMultiServiceCommon : null,
    ];
    const sectionStyles = [
        forConfirm ? s.forConfirmSection : null,
        forNewList ? s.forNewListSection : null,
        forReviewedInfo && (status === 'APPROVED') ? [s.forReviewedInfoSection, s.greenLeftBorderW3] : null,
        forReviewedInfo && (status === 'REJECTED') ? [s.forReviewedInfoSection, s.redLeftBorderW3] : null,
        forMultiService ? s.forMultiServiceSection : null,
    ];
    return (
        <View style={[{ flexDirection: 'row' }, commonStyles]}>
            {forConfirm ? (<StopBadge/>) : null}
            <Section style={[s.invoiceSingleService, sectionStyles]}>
                <VBox>
                    <SubtitleDark numberOfLines={null}>{umsServiceName}</SubtitleDark>
                    <Box gap={2}>
                        <Box centered>
                            <SubtitleDark id="titles.quantity" bold/>
                            <SubtitleDark>{`${amount} шт.`}</SubtitleDark>
                        </Box>
                        <Box centered>
                            <SubtitleDark id="titles.price" bold/>
                            <SubtitleDark>{`${totalCost.toFixed(2)} руб.`}</SubtitleDark>
                        </Box>
                    </Box>
                </VBox>
            </Section>
        </View>
    );
};
export const StopBadge = () => (
    <View style={s.stopBadge}>
        <View style={s.stopBadge_whiteline}/>
    </View>
);
