import {
    Card, dataFrom, React, Title, TouchableOpacity, View,
} from '../../../common';
import searchDoctorImg from '../../../assets/images/search-doctor.png';
import searchServices from '../../../assets/images/microscope.png';
import searchDrugsImg from '../../../assets/images/medications.png';
import searchClinicImg from '../../../assets/images/medical-center.png';
import { navigate } from '../../../actions';
import { getCurrentLocation } from '../../../selectors';
import { getEnums } from '../../../i18n';
import { home as styles } from '../styles';
import { gotoDoctors } from './utils';

const navigationItemsListExtra = {
    doctors: {
        img: searchDoctorImg,
        onPress: () => gotoDoctors(),
    },
    ums: {
        img: searchServices,
        onPress: () => navigate('UmsMain'),
    },
    drugs: {
        img: searchDrugsImg,
        onPress: () => navigate('SearchDrugs'),
    },
    medcenters: {
        img: searchClinicImg,
        onPress: () => navigate('MedCentersList'),
    },
};

export const Banner = dataFrom(getCurrentLocation, ({
    data: location = {},
    filteredFields = location.banners || ['doctors', 'ums'],
    navigationItemsList = getEnums('navigationItemsList')
        .map(item => Object.assign(item, navigationItemsListExtra[item.id]))
        .filter(el => filteredFields.includes(el.id)),
}) => (
    <View style={{
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
    }}>
        {navigationItemsList.map(({
            name,
            onPress,
            img,
        }, index) => (
            <TouchableOpacity key={name} style={{ flex: 1 }} onPress={onPress} activeOpacity={0.2}>
                <Card
                    wrapperStyle={{ alignItems: 'center' }}
                    containerStyle={[styles.navigationContainer, index === 0 ? { marginRight: 4 } : { marginLeft: 4 }, {
                        borderRadius: 3,
                        elevation: 0,
                        borderColor: '#E0E0E0',
                    }]}
                >
                    <Card.Image source={img} style={styles.image} resizeMode="center"
                        placeholderStyle={{ backgroundColor: 'transparent' }}/>
                    <Title numberOfLines={1} bold style={{ paddingVertical: 10 }}>{name}</Title>
                </Card>
            </TouchableOpacity>
        ))}
    </View>
));
