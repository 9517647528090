/* global window */

/**
 * To be invoked from `import`.
 */
const win = window.location ? window : { location: { href: 'http://native' } };
const {searchParams} = new URL(win.location.href);
if (searchParams.get('e2e')) {
    const setupMock = require('./index');
    setupMock(searchParams.get('profile'));
}
