import { capitalize } from './strings';

export const getShortUserName = (userName) => {
    const parts = String(userName)
        .trim()
        .split(' ');
    return parts.length > 1 ? `${capitalize(parts[1] ?? '')} ${capitalize(parts[0]?.charAt(0) ?? '')}.` : parts[0];
};

export const getShortUserNameWithChecks = (userName) => {
    const parts = String(userName)
        .trim()
        .split(' ');
    if (parts.length < 2) {
        return userName;
    }

    if (parts[1].length <= 2 || parts[1].charAt(2) === '.') {
        return userName;
    }

    return `${parts[1]} ${parts[0].charAt(0)}.`;
};
