import EStyleSheet from 'react-native-extended-stylesheet';

export const doctorsMap = EStyleSheet.create({
    flex1: { flex: 1 },
    scrollPart: {
        borderTopWidth: 1,
        borderColor: '#E9E9E9',
        paddingHorizontal: 0,
        backgroundColor: '#ffffff',
    },
});

// noinspection JSUnusedGlobalSymbols
export const doctorsItem = EStyleSheet.create({
    item: {
        paddingVertical: 10,
        paddingLeft: 30,
        borderBottomWidth: 1,
        borderColor: '#E9E9E9',
    },
    itemText: {
        marginBottom: 5,
        flexWrap: 'wrap',
        flex: 6,
    },
    ntaContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    ntaTitle: { marginLeft: 5 },
});

export const mapBunner = EStyleSheet.create({
    bunner: {
        // borderBottomWidth: 1,
        // borderColor: '#E9E9E9',
        flex: 5,
        position: 'relative',
        zIndex: 1,
        paddingBottom: 16,
        paddingTop: 16,
    },
    infoTitle: {
        paddingRight: 50,
        paddingHorizontal: 30,
    },
    infoContiner: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        paddingHorizontal: 30,
        marginTop: 10,
    },
    scheduleInfo: {
        flex: 7,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    scheduleInfoText: {
        flex: 1,
        marginLeft: 5,
    },
    buttonsContiner: {
        flex: 3,
        flexDirection: 'row',
    },
    routeBtn: {
        width: 45,
        height: 45,
        borderRadius: 99,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '$emerald',
        marginTop: 0,
    },
    callBtn: {
        width: 45,
        height: 45,
        borderRadius: 99,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#5C85DD',
        marginRight: 16,
    },
});
