const countryCenterLat = 53.8409;
const countryCenterLon = 27.74565;

const minLon = 23.15;
const maxLat = 56.17;
const maxLon = 32.8;
const minLat = 51.3;

const useOnlyThisCountry = true;

export const pointInCountry = (lat, lon) => {
    if (!useOnlyThisCountry) {
        return true;
    }
    return lat >= minLat && lat <= maxLat && lon >= minLon && lon <= maxLon;
};

export const averageLocationCountry = () => {
    const delta =
        2 *
            Math.max(
                countryCenterLat - minLat,
                maxLat - countryCenterLat,
                countryCenterLon - minLon,
                maxLon - countryCenterLon,
            ) || Math.exp(Math.log(360) - 15 * Math.LN2);
    return {
        latitude: countryCenterLat,
        longitude: countryCenterLon,
        latitudeDelta: delta,
        longitudeDelta: delta,
    };
};

