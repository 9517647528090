const PHYSICIANS = 'physicians';
const MEDSERVICES = 'medservices';
const CLINICS = 'clinics';

export const SCHEME = {
    CLINICS,
    PHYSICIANS,
    MEDSERVICES,
    LOCATION: `selections.data.location`,
    MEDCENTERS_SEARCH: `medservices.search`,
    MEDCENTERS_SORT_BY: `medservices.sortBy`,
    MEDCENTERS_FILTER: 'medservices.filter',
    MEDCENTERS_FILTER_FAVORITES: 'medservices.filterFavorites',
    PHYSICIANS_DATA: 'physicians.data',
    PHYSICIANS_SEARCH: 'physicians.search',
    PHYSICIANS_FROM_CLINICS_SEARCH: 'physicians.searchFromClinics',
    PHYSICIANS_FROM_VISITS_SEARCH: 'physicians.searchFromVisits',
    PHYSICIANS_FAVORITES_SEARCH: 'physicians.favoritesSearch',
    PHYSICIANS_ADVANCED_SEARCH: 'physicians.advancedSearch',
    PHYSICIANS_CLINICS_ADVANCED_SEARCH: 'physicians.clinicsAdvancedSearch',
    PHYSICIANS_VISITS_ADVANCED_SEARCH: 'physicians.visitsAdvancedSearch',
    PHYSICIANS_SORT_BY: 'physicians.sortBy',
    PHYSICIANS_SORT_BY_CLINIC: 'physicians.sortByClinic',
    PHYSICIANS_SORT_BY_VISIT: 'physicians.sortByVisit',
    PHYSICIANS_FEEDBACK_SORT_BY: 'feedbacks.physiciansSortBy',
    PHYSICIANS_PREFILTER: 'physicians.prefilter',
    PHYSICIANS_PREFILTER_CLINIC: 'physicians.prefilterForClinic',
    PHYSICIANS_PREFILTER_VISIT: 'physicians.prefilterForVisit',
    PHYSICIANS_PREFILTER_SPECIALTY: 'physicians.prefilter.specialty',
    PHYSICIANS_PREFILTER_SPECIALTY_CLINIC: 'physicians.prefilterForClinic.specialty',
    PHYSICIANS_PREFILTER_SPECIALTY_VISIT: 'physicians.prefilterForVisit.specialty',
    PHYSICIANS_PREFILTER_SPECIALTIES: 'physicians.prefilter.specialties',
    PHYSICIANS_PREFILTER_SPECIALTIES_CLINIC: 'physicians.prefilterForClinic.specialties',
    PHYSICIANS_PREFILTER_SPECIALTIES_VISIT: 'physicians.prefilterForVisit.specialties',
    PHYSICIANS_PREFILTER_PERIODS: 'physicians.prefilter.periods',
    PHYSICIANS_PREFILTER_PERIODS_CLINIC: 'physicians.prefilterForClinic.periods',
    PHYSICIANS_PREFILTER_PERIODS_VISIT: 'physicians.prefilterForVisit.periods',
    PHYSICIANS_PREFILTER_WORKS_AT: 'physicians.prefilter.worksAt',
    PHYSICIANS_PREFILTER_WORKS_AT_CLINIC: 'physicians.prefilterForClinic.worksAt',
    PHYSICIANS_PREFILTER_WORKS_AT_VISIT: 'physicians.prefilterForVisit.worksAt',
    PHYSICIANS_PREFILTER_WORKS_AT_GROUP: 'physicians.prefilter.worksAtGroup',
    PHYSICIANS_PREFILTER_WORKS_AT_GROUP_CLINIC: 'physicians.prefilterForClinic.worksAtGroup',
    PHYSICIANS_PREFILTER_WORKS_AT_GROUP_VISIT: 'physicians.prefilterForVisit.worksAtGroup',
    PHYSICIANS_PREFILTER_PROFILES: 'physicians.prefilter.profiles',
    PHYSICIANS_PREFILTER_PROFILES_CLINIC: 'physicians.prefilterForClinic.profiles',
    PHYSICIANS_PREFILTER_PROFILES_VISIT: 'physicians.prefilterForVisit.profiles',
    PHYSICIANS_PREFILTER_FEEDBACKS: 'physicians.prefilter.feedbacks',
    PHYSICIANS_PREFILTER_FEEDBACKS_CLINIC: 'physicians.prefilterForClinic.feedbacks',
    PHYSICIANS_PREFILTER_FEEDBACKS_VISIT: 'physicians.prefilterForVisit.feedbacks',
    PHYSICIANS_PREFILTER_TIMES: 'physicians.prefilter.times',
    PHYSICIANS_PREFILTER_TIMES_CLINIC: 'physicians.prefilterForClinic.times',
    PHYSICIANS_PREFILTER_TIMES_VISIT: 'physicians.prefilterForVisit.times',
    PHYSICIANS_PREFILTER_DATES: 'physicians.prefilter.dates',
    PHYSICIANS_PREFILTER_DATES_CLINIC: 'physicians.prefilterForClinic.dates',
    PHYSICIANS_PREFILTER_DATES_VISIT: 'physicians.prefilterForVisit.dates',
    PHYSICIANS_FILTER: 'physicians.filter',
    PHYSICIANS_FILTER_FOR_CLINIC: 'physicians.filterForClinic',
    PHYSICIANS_FILTER_FOR_VISIT: 'physicians.filterForVisit',
    PHYSICIANS_FILTER_SPECIALTY: 'physicians.filter.specialty',
    PHYSICIANS_FILTER_SPECIALTY_CLINICS: 'physicians.filterForClinic.specialty',
    PHYSICIANS_FILTER_SPECIALTY_VISITS: 'physicians.filterForVisit.specialty',
    PHYSICIANS_FILTER_WORKS_AT: 'physicians.filter.worksAt',
    PHYSICIANS_FILTER_WORKS_AT_CLINIC: 'physicians.filterForClinic.worksAt',
    PHYSICIANS_FILTER_WORKS_AT_VISIT: 'physicians.filterForVisit.worksAt',
    PHYSICIANS_FILTER_WORKS_AT_GROUP: 'physicians.filter.worksAtGroup',
    PHYSICIANS_FILTER_PERIOD: 'physicians.filter.period',
    PHYSICIANS_FILTER_PERIOD_CLINIC: 'physicians.filterForClinic.period',
    PHYSICIANS_FILTER_PERIOD_VISIT: 'physicians.filterForVisit.period',
    PHYSICIANS_FILTER_FAVORITES: 'physicians.filterFavorites',
    PHYSICIANS_FILTER_PROFILES: 'physicians.filter.profiles',
    PHYSICIANS_FILTER_PROFILES_CLINIC: 'physicians.filterForClinic.profiles',
    PHYSICIANS_FILTER_PROFILES_VISIT: 'physicians.filterForVisit.profiles',
    PHYSICIANS_FILTER_FEEDBACKS: 'physicians.filter.feedbacks',
    PHYSICIANS_FILTER_FEEDBACKS_CLINIC: 'physicians.filterForClinic.feedbacks',
    PHYSICIANS_FILTER_FEEDBACKS_VISIT: 'physicians.filterForVisit.feedbacks',
    PHYSICIANS_FILTER_TIME: 'physicians.filter.time',
    PHYSICIANS_FILTER_DATES: 'physicians.filter.dates',
    PHYSICIANS_RECENT_SPECIALTY_REQUESTS: 'physicians.recentSpecialtyRequests',
    PHYSICIANS_RECENT_SPECIALTY_REQUESTS_CLINIC: 'physicians.recentSpecialtyRequestsForClinic',
    PHYSICIANS_RECENT_SPECIALTY_REQUESTS_VISIT: 'physicians.recentSpecialtyRequestsForVisit',
    PHYSICIANS_FROM_SERARCH: 'physicians.fromSearch',
    PHYSICIANS_CLINICS_FROM_SEARCH: 'physicians.clinicsFromSearch',
    PHYSICIANS_VISITS_FROM_SEARCH: 'physicians.visitsFromSearch',
    PHYSICIANS_IS_LOADING: 'physicians.isLoading',
    PAST_VISITS: 'pastVisits',
    VISITS_PREFILTER: 'visits.prefilter',
    VISITS_PREFILTER_VISITORS: 'visits.prefilter.visitors',
    VISITS_PREFILTER_CLINICS: 'visits.prefilter.clinics',
    VISITS_PREFILTER_DOCTORS: 'visits.prefilter.doctors',
    VISITS_PREFILTER_SPECIALITY: 'visits.prefilter.speciality',
    VISITS_PREFILTER_ATTACHMENTS: 'visits.prefilter.byAttachments',
    VISITS_PREFILTER_REPORTS: 'visits.prefilter.byReports',
    VISITS_FILTER: 'visits.filter',
    VISITS_FILTER_VISITORS: 'visits.filter.visitors',
    VISITS_FILTER_CLINICS: 'visits.filter.clinics',
    VISITS_FILTER_DOCTORS: 'visits.filter.doctors',
    VISITS_FILTER_SPECIALITY: 'visits.filter.speciality',
    VISITS_FILTER_ATTACHMENTS: 'visits.filter.byAttachments',
    VISITS_FILTER_REPORTS: 'visits.filter.byReports',
    TESTS_PREFILTER: 'tests.prefilter',
    TESTS_PREFILTER_VISITORS: 'tests.prefilter.visitors',
    TESTS_PREFILTER_CLINICS: 'tests.prefilter.clinics',
    TESTS_PREFILTER_STATUSES: 'tests.prefilter.statuses',
    TESTS_FILTER: 'tests.filter',
    TESTS_FILTER_VISITORS: 'tests.filter.visitors',
    TESTS_FILTER_CLINICS: 'tests.filter.clinics',
    TESTS_FILTER_STATUSES: 'tests.filter.statuses',
    UMS_MEDCENTERS_SORT_BY: 'medservices.medcentersSortBy',
    UMS_MEDCENTERS_COVERED_SORT_BY: 'medservices.medcentersCoveredSortBy',
    UMS_FILTER: 'ums.filter',
    UMS_PREFILTER: 'ums.prefilter',
    UMS_PREFILTER_MEDCENTERS: 'ums.prefilter.medcenters',
    UMS_PREFILTER_WITH_PROGRAMS: 'ums.prefilter.withPrograms',
    UMS_PREFILTER_WITH_ONLINE: 'ums.prefilter.withOnline',
    UMS_PREFILTER_MEDCENTERS_GROUP: 'ums.prefilter.group',
    DRUGSTORES_FILTER: 'drugstores.filter',
    UMS_PHYSICIANS_SORT_BY: 'umsDoctors.sortBy',
};
