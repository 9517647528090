import {
    Alert,
    Button,
    Dimensions,
    Icon,
    Image,
    Img,
    List,
    Page,
    Platform,
    React,
    Section,
    showNotification,
    Subtitle,
    Title,
    View,
    withState,
} from '../../common';
import { getDocuments, getIsFetching, getPrefsLoadingState } from '../../selectors';
import actions, { navigate } from '../../actions';
import {
    checkPhotosLibrary,
    copyImageToDocuments,
    resolveImagePath,
    tryShowImageFromCache,
} from '../../services/device';
import { AppHeader } from '../../combo';
import { showImagePickerModal } from '../../common/Modal';

const imageSize = (Dimensions.get('window').width / 2) - 20;

const EmptyDocumentsListStub = (
    <View style={{
        flex: 1,
        alignItems: 'center',
        paddingHorizontal: 16,
        marginTop: 64,
    }}>
        <Img.EmptyDocs style={{ marginBottom: 36 }} />
        <Title id="titles.noAttachedDocs" numberOfLines={null} bold centered />
        <Subtitle id="titles.noAttachedDocsHint" style={{
            marginTop: 16,
            color: '#AAAAAA',
        }} numberOfLines={null} centered />
    </View>
);

const DocumentsListItem = withState(({
    item: {
        uri,
        path,
        origURL,
        cachePath,
        id,
    },
    item,
    onPress,
    setState,
    errorOccurred,
    retryRender,
}) => (
    <Button action={onPress} styles={{
        alignSelf: 'center',
        marginVertical: 6,
    }}>
        <Image
            key={`${id}-${retryRender ? 1 : 0}`}
            accessibilityLabel="docs-list_image"
            resizeMode="cover"
            style={[{
                alignSelf: 'center',
                width: imageSize,
                height: imageSize,
            }, errorOccurred ? { backgroundColor: 'gray' } : {}]}
            source={{ uri: resolveImagePath(uri, cachePath || path) }}
            onError={() => {
                if (Platform.OS === 'ios' && origURL) {
                    checkPhotosLibrary()
                        .then(async (granted) => {
                            if (granted) {
                                try {
                                    await copyImageToDocuments(origURL, uri);
                                    setState({ retryRender: !retryRender });
                                } catch (err) {
                                    setState({ errorOccurred: true });
                                }
                            } else {
                                setState({ errorOccurred: true });
                            }
                        });
                } else {
                    tryShowImageFromCache(path, item, uri, () => setState({ retryRender: !retryRender }), () => setState({ errorOccurred: true }));
                }
            }}
        />
    </Button>
));

export const Documents = Page.register(
    ({
        documents = [],
        isFetching,
        isLoading,
        getHint = () => (documents.length ? 'titles.filesStoredLocallyHint' : null),
    }) => {
        let isAddingPhoto = false;
        return (
            <Page name="documents" noscroll hint={getHint()}>
                <Section style={{ flex: 1 }} title="titles.myDocuments" capitalize isLoading={isFetching || isLoading}>
                    <List
                        data={documents}
                        numColumns={2}
                        columnWrapperStyle={{ justifyContent: 'space-between' }}
                        ListEmptyComponent={isFetching ? <View /> : EmptyDocumentsListStub}
                        renderItem={({ item }) => (
                            <DocumentsListItem
                                item={item}
                                onPress={() => navigate('DocumentsSlider', {
                                    initialId: item.id,
                                    enableDelete: true,
                                })}
                            />
                        )}
                    />
                </Section>
                <Icon.Add
                    style={{
                        position: 'absolute',
                        bottom: 20,
                        right: 20,
                        width: 45,
                        height: 45,
                        borderRadius: 90,
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderColor: '#18B797',
                        backgroundColor: '#18B797',
                        alignSelf: 'flex-end',
                    }}
                    color="#FFFFFF"
                    onPress={() => {
                        if (!isAddingPhoto) {
                            isAddingPhoto = true;
                            const showImgPicker = (item) => {
                                if (item.error) {
                                    Alert.alert(Object.R('titles.error'), Object.R('titles.errorAddDocuments'));
                                } else {
                                    item.assets?.forEach(el => {
                                        actions.addDocument({
                                            ...el,
                                            exists: true,
                                        });
                                    });
                                    showNotification('titles.localFileAdded');
                                }
                                isAddingPhoto = false;
                            };
                            const onCancel = () => {
                                isAddingPhoto = false;
                            };
                            showImagePickerModal(showImgPicker, onCancel);
                        }

                    }}
                />
            </Page>
        );
    },
    {
        documents: { from: getDocuments },
        isFetching: { from: getIsFetching },
        isLoading: { from: getPrefsLoadingState },
    },
    { header: () => <AppHeader /> },
);

