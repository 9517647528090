/* eslint-disable no-param-reassign,no-use-before-define */
/**
 * Parses string into URL object.
 *
 * @param {string} s string in format: `type:target/path?params#data`
 * @param {object} r optional target object
 * @returns URL object like `{type, target, path, params, data }`
 */
// eslint-disable-next-line max-statements
export function urlParse(s, r = {}, noDecodeFor) {
    if (!s) {
        return r;
    }
    if (typeof s === 'object') {
        return {
            path: [],
            params: {},
            target: '',
            ...s,
        };
    }
    let p;
    // extract type:
    p = s.indexOf(':');
    if (p > -1) {
        r.type = s.slice(0, p);
        s = s.slice(p + 1);
    }
    // extract data:
    p = s.indexOf('#');
    if (p > -1) {
        r.data = decodeValue(s.slice(p + 1));
        s = s.slice(0, p);
    }
    // extract query params:
    p = s.indexOf('?');
    r.params = r.params || {};
    if (p > -1) {
        // eslint-disable-next-line no-restricted-syntax
        for (const param of s.slice(p + 1).split('&')) {
            const [key, value] = param.split('=');
            if (value) {
                if (noDecodeFor && key === noDecodeFor) {
                    r.params[key] = value;
                } else {
                    r.params[key] = decodeValue(value);
                }
            }
        }
        s = s.slice(0, p);
    }
    // target and path:
    // eslint-disable-next-line no-multi-assign
    const path = (r.path = s.split('/').map(decodeURIComponent));
    while (path.length && !r.target) {
        r.target = path.shift();
    }
    return r;
}

/**
 *  Represents an URL object as a string
 *
 * @param {object} r URL object like `{type, target, path, params, data }`
 * @returns string in format `type:target/path?params#data`
 */
export function urlStringify(r) {
    let result = '';
    if (!r) {
        return result;
    }
    if (typeof r === 'string') {
        return r;
    }
    if (r.target) {
        if (r.type) {
            result += `${r.type}://`;
        }
        result += r.target;
    }
    if (r.path) {
        result += `/${Array.isArray(r.path) ? r.path.map(encodeURIComponent).join('/') : r.path}`;
    }
    const { params } = r;
    if (params) {
        const keys = Object.keys(params).filter(key => params[key] != null);
        if (keys.length) {
            result += `?${keys.map(key => `${key}=${encodeValue(params[key])}`).join('&')}`;
        }
    }
    if (r.data) {
        result += `#${encodeValue(r.data)}`;
    }
    return result;
}

const VALUE_MAP = {
    true: true,
    false: false,
    undefined,
};

function decodeValue(val) {
    const value = decodeURIComponent(val);
    if ('{['.indexOf(value[0]) > -1) {
        return JSON.parse(value);
    }
    const num = +value;
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(num)) {
        return num;
    }
    return VALUE_MAP[value] || value;
}

function encodeValue(value) {
    return encodeURIComponent(typeof value === 'object' ? JSON.stringify(value) : `${value}`);
}

// eslint-disable-next-line max-statements
export const deepEquals = (a, b) => {
    if (a === b) {
        return true;
    }

    if (typeof a !== typeof b) {
        return false;
    }

    if (typeof a !== 'object' || a === null || b === null) {
        return a === b;
    }

    if (Array.isArray(a)) {
        if (!Array.isArray(b) || a.length !== b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (!deepEquals(a[i], b[i])) {
                return false;
            }
        }
        return true;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (let i = 0; i < keysA.length; i++) {
        const key = keysA[i];
        if (!keysB.includes(key) || !deepEquals(a[key], b[key])) {
            return false;
        }
    }

    return true;
};
