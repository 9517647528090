import WebView from 'react-native-webview';
import { React, Text } from './react';

export const Log = ({
    value, text, pre = false, display = true,
}) => {
    const getJson = (obj) => {
        try {
            return JSON.stringify(obj, null, 2);
        } catch (e) {
            return 'Error JSON.stringify';
        }
    };
    // eslint-disable-next-line no-console
    console.log(`LOG: ${text}`, value);
    return (
        display &&
        (pre ? (
            <WebView
                source={{ html: getJson(value) }}
                style={{
                    height: 300,
                    paddingVertical: 30,
                    backgroundColor: '#F9F9F9',
                }}
                scalesPageToFit
                textZoom={250}
            />
        ) : (
            <Text>{getJson(value)}</Text>
        ))
    );
};
