import React, { useState } from 'react';
import {
    Checkbox,
    Field,
    Platform,
    Selector,
    SingleNavigation,
    Switch,
    Text,
    TextInput,
    Title,
    TopNotification,
    View,
} from '../../../../common';
import { HyperLink } from '../../../../common/Hyperlink';
import { buttonsStyles } from '../../../../styles/button';
import { testEmailRegex } from '../../../../utils';
import { visitForArr1, visitForArr2 } from './consts';
import { MODE } from '../../../../config';
import { cleanString } from '../../../../common/Agreemants';

export const SwitchWithLabel = ({ caption, onValueChange, value, style = {}, containerStyle = {} }) => {
    return (
        // <View
        //     style={[
        //         {
        //             flex: 1,
        //             flexDirection: 'row',
        //             justifyContent: 'space-between',
        //             marginBottom: 12,
        //             marginTop: 20,
        //             alignItems: 'center',
        //         },
        //         style,
        //     ]}>
        //     <Text style={[input.fieldCaption, { paddingTop: 0, textAlign: 'center', marginBottom: 0 }]}>{caption}</Text>
        //     <Switch.Default value={value} onValueChange={onValueChange} />
        // </View>
        <View style={containerStyle}>
            <SingleNavigation
                title={caption}
                style={[
                    {
                        paddingVertical: Platform.OS === 'ios' ? 8 : 10,
                        marginTop: 20,
                    },
                    style,
                ]}
                textStyle={{ color: '#555555' }}
                rightItem={
                    <Switch
                        accessibilityLabel="switch:doctor-feedbacks"
                        style={{ transform: Platform.OS === 'ios' ? [{ scaleX: 0.8 }, { scaleY: 0.8 }] : [] }}
                        activeThumbColor="#4770C0"
                        activeTrackColor="#9FB9FF"
                        onTintColor={Platform.OS !== 'web' ? '#6987D6' : '#8FB8FF'}
                        thumbTintColor="#5C85DD"
                        trackColor={{
                            false: '#CFCFCF',
                            true: Platform.OS === 'ios' ? '#5C85DD' : '#8FB8FF',
                        }}
                        value={value}
                        onValueChange={onValueChange}
                    />
                }
            />
        </View>
    );
};

export const SwitchWithNotification = ({ hint, caption, value, onValueChange }) => {
    return (
        <>
            <TopNotification level={'info'} hint={hint} containerStyle={{ borderTopWidth: 1 }} />
            <SwitchWithLabel caption={caption} onValueChange={onValueChange} value={value} style={{ marginTop: 6 }} />
        </>
    );
};

const webStyles =
    Platform.OS === 'web'
        ? {
              marginRight: 12,
              marginLeft: 2,

              marginBottom: 20,
              marginTop: 3,
          }
        : {};

export const AgreemantCheckbox = ({ title, selected, onPress, height, checkboxStyle = {} }) => {
    const onSelect = () => onPress(!selected);
    return (
        <View style={{ height: height || 70 }}>
            <Checkbox
                title={
                    <HyperLink
                        title={MODE === 'uat' ? cleanString(title) : title}
                        style={{ linkColor: buttonsStyles.link.color }}
                        linkStyle={{ textDecorationLine: 'underline' }}
                        onClickText={onSelect}
                    />
                }
                style={[
                    {
                        marginRight: 2,
                        marginLeft: 2,
                        marginBottom: 40,
                        marginTop: 3,
                    },
                    webStyles,
                    checkboxStyle,
                ]}
                selected={selected}
                numberOfLines={0}
                onPress={onSelect}
            />
        </View>
    );
};

export const EmailInput = ({ onChange, value, onError, error }) => {
    const checkEmail = (mail) => {
        return !mail.length || testEmailRegex(mail) ? null : Object.R('error.email_format_error');
    };
    return (
        <Field
            title={Object.R('titles.newVisitEmail')}
            required
            style={{
                marginBottom: 0,
                marginTop: 18,
            }}>
            <TextInput
                onChange={onChange}
                value={value}
                placeholder={Object.R('titles.newVisitEmailPlaceholder')}
                onBlur={() => onError(checkEmail(value))}
                invalid={error}
            />
            {!!error && <Text style={{ color: '#DF655C' }}>{error}</Text>}
        </Field>
    );
};

export const PatientSelector = ({ onSelect, selectedItem, filtredFields = [] }) => {
    const [data1, setData1] = useState(
        visitForArr1
            .map((el) => ({
                ...el,
                name: Object.R(el.name),
            }))
            .filter((el) => !filtredFields?.some((s) => el.id === s)),
    );
    const [data2, setData2] = useState(
        visitForArr2
            .map((el) => ({
                ...el,
                name: Object.R(el.name),
            }))
            .filter((el) => !filtredFields?.some((s) => el.id === s)),
    );

    const onSelectValue = (item, isSelected) => {
        onSelect(
            !isSelected
                ? {
                      ...item,
                      selected: !isSelected,
                  }
                : {},
        );
        setData1(
            data1.map((el) => ({
                ...el,
                selected: el.id === item.id && !el.selected,
            })),
        );
        setData2(
            data2.map((el) => ({
                ...el,
                selected: el.id === item.id && !el.selected,
            })),
        );
    };
    const noLine = data1?.length < 2 && data2?.length < 2;
    return (
        <Field
            title={Object.R('titles.thisVisitIsFor').toUpperCase()}
            required
            style={{
                marginBottom: 6,
                marginTop: 8,
            }}>
            <View
                style={{
                    flexDirection: 'row',
                    backgroundColor: 'white',
                    borderColor: 'rgb(224, 224, 224)',
                    borderWidth: 1,
                    borderRadius: 3,
                    marginBottom: 20,
                }}>
                <Selector
                    radio
                    data={data1}
                    onSelect={onSelectValue}
                    noLastLine={data1?.length > 1 || noLine}
                    selected={selectedItem}
                    style={{
                        paddingVertical: 10,
                        paddingHorizontal: 12,
                        backgroundColor: 'white',
                    }}
                />
                <Selector
                    radio
                    data={data2}
                    onSelect={onSelectValue}
                    selected={selectedItem}
                    noLastLine={noLine}
                    style={{
                        paddingVertical: 10,
                        paddingHorizontal: 12,
                        backgroundColor: 'white',
                    }}
                />
            </View>
        </Field>
    );
};

export const VisitInfo = ({ title }) => {
    return (
        // <Field title={Object.R('titles.visitTilte')} required titleStyle={{ paddingTop: 0, paddingLeft: 24 }}>
        <View
            style={{
                // backgroundColor: 'white',
                // borderBottomWidth: 1,
                // borderTopWidth: 1,
                // borderColor: 'rgb(224, 224, 224)',
                // paddingVertical: 12,
                paddingHorizontal: 12,
            }}>
            <Title numberOfLines={null} style={{ marginHorizontal: 12 }}>
                {title}
            </Title>
        </View>
        // </Field>
    );
};
