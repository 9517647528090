import {
    Box, dataFrom, Icon, React, selectOptions, Title, View,
} from '../common';
import actions from '../actions';
import { getGlobalLocations } from '../i18n';
import { getCurrentLocation, getUmsPrefilterMedcenters } from '../selectors';
import { putIntoStore, SCHEME } from '../store';
import { resetDoctorsFilters } from '../screens/Doctors/utils';
import { ALLOWED_CITIES } from '../config';

export const resetMedCentersFilter = () => {
    const { UMS_FILTER, UMS_PREFILTER_MEDCENTERS, UMS_PREFILTER_WITH_PROGRAMS } = SCHEME;
    putIntoStore(UMS_FILTER, {
        medcenters: [],
        withPrograms: false,
    });
    const { medcenters = [] } = getUmsPrefilterMedcenters();
    putIntoStore(
        UMS_PREFILTER_MEDCENTERS,
        medcenters.map(e => ({
            ...e,
            selected: false,
        })),
    );
    putIntoStore(UMS_PREFILTER_WITH_PROGRAMS, false);
};

/**
 * CitySelector combo.
 */
export const CitySelector = dataFrom(
    getCurrentLocation,
    ({
        data: currentLocation,
        locsAr = getGlobalLocations(),
        select = () => {
            let locationData = [];
            if (Array.isArray(locsAr) && locsAr.length) {
                locationData = [...locsAr];
                if (ALLOWED_CITIES?.length) {
                    locationData = locationData.filter(
                        e => ALLOWED_CITIES.includes(e.code) || ALLOWED_CITIES.includes(Number(e.code)),
                    );
                }
                try {
                    locationData[locationData.length - 1].isLast = true;
                } catch (e) {
                    //
                }
            }
            selectOptions({
                title: 'titles.selectYourCity',
                selected: currentLocation.id,
                data: locationData,
                onSelect: (item) => {
                    resetDoctorsFilters();
                    actions.setLocation(item.code);
                    resetMedCentersFilter();
                },
                isWithSearch: true,
                noLastLine: true,
            });
        },
    }) => (
        <Box flex onPress={select} ns="city-selector">
            <Icon.Geo style={{ paddingTop: 2 }} />
            <View style={{ flex: 1 }}>
                <Title
                    style={{
                        color: 'white',
                        marginLeft: 5,
                        borderBottomWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: 'white',
                        alignSelf: 'baseline',
                        flexWrap: 'nowrap',
                    }}
                    numberOfLines={1}>
                    {currentLocation.name}
                </Title>
            </View>
        </Box>
    ),
);
